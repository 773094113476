import { useEffect, useState } from 'react';
import { PENDO_TRACK_EVENTS_ENUM, trackPendoEvent } from '../../../../core';
import { Utility } from '../../../../shared/services';
import {
  ProgramConstants,
  enrollIntoProgram,
  getProgramDetails,
  showDefaultErrorToast,
  showSuccessToast,
  unenrollFromProgram,
} from '../../services/programs.service';

const useProgramDetailsHook = (props) => {
  const programId = props.match.params.id;
  const [dataLoading, setDataLoading] = useState(true);
  const [details, setDetails] = useState({ name: 'Program Title' });
  const [modules, setModules] = useState([]);
  const [rootModule, setRootModule] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchProgramDetails();
  }, []);

  const fetchProgramDetails = async () => {
    try {
      const response = await getProgramDetails(programId);
      if (response?.data?.payload) {
        const details = response.data.payload;
        if (details.forceResync) {
          forceEnroll();
        }
        setDetails({ ...details, ...details.program });
        setModules(Utility.sortByKey(details.program?.learningObjects, 'displayOrder'));
        setRootModule(details.program?.rootLearningObject);
        setDataLoading(false);
      }
    } catch (e) {
      console.log(e);
      showDefaultErrorToast();
    }
  };

  const forceEnroll = async () => {
    try {
      const response = await enrollIntoProgram(programId);
      if (response?.data) {
        setDetails((details) => ({ ...details, enrollmentStatus: 'ENROLLED' }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnroll = async () => {
    try {
      const response = await enrollIntoProgram(programId);

      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.PROGRAM_ENROLLEMENT, {
        id: programId,
        name: details.name,
      });

      if (response?.data) {
        setDetails({ ...details, enrollmentStatus: 'ENROLLED' });
        showSuccessToast('courses.enrolled.msg.success', { name: details.name });
      } else {
        showDefaultErrorToast();
      }
    } catch (e) {
      showDefaultErrorToast();
    }
  };

  const handleUnenroll = async () => {
    try {
      const response = await unenrollFromProgram(programId);

      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.PROGRAM_UNENROLLEMENT, {
        id: programId,
        name: details.name,
      });

      if (response?.data) {
        setDetails({ ...details, enrollmentStatus: 'UNENROLLED' });
        showSuccessToast('courses.unenrolled.msg.success', { name: details.name });
      } else {
        showDefaultErrorToast();
      }
    } catch (e) {
      showDefaultErrorToast();
    }
  };

  const isSingleLaunchProgram = () => {
    return [
      ProgramConstants.TEMPLATE_TYPES.TEMPLATE_SINGLE_COURSE,
      ProgramConstants.TEMPLATE_TYPES.TEMPLATE_MULTIPLE_COURSES_UNIFIED,
    ].includes(details.templateType);
  };

  const onConfirmUnenroll = () => {
    handleUnenroll();
    closeConfirmationDialog();
  };

  const showConfirmationDailog = () => {
    setOpenDialog(true);
  };

  const closeConfirmationDialog = () => {
    setOpenDialog(false);
  };

  const goBack = () => {
    props.history.goBack();
  };

  return {
    programId,
    dataLoading,
    details,
    modules,
    rootModule,
    openDialog,
    goBack,
    handleEnroll,
    showConfirmationDailog,
    closeConfirmationDialog,
    onConfirmUnenroll,
    isSingleLaunchProgram,
  };
};

export default useProgramDetailsHook;
