import React, { useCallback, useMemo, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import styles from './program-listing.style';
import Program from '../program/program';
import { Constant } from '../../../../../shared/services';
import { DocumentTitle } from '../../../../shared';
import { Button as CommonsButton } from '@orijinworks/frontend-commons';
import { ProgramContentFilters, ProgramFilters } from '../../../../../content/programs/components';
import useProgramListingHook from './useProgramListingHook';
import { getCategoryFromName } from '../../../../shared/services/utility/utility';
import TuneIcon from '@mui/icons-material/Tune';

const ProgramListing = (props) => {
  const { classes, intl } = props;
  const programContentFilterRef = useRef();
  const {
    minCharForSearch,
    selectedFilter,
    searchTerm,
    programs,
    dataLoading,
    searchError,
    showLoaderOnFilterChange,
    filteredPrograms,
    sortOption,
    isFilterOpen,
    programForFilters,
    setPrograms,
    setProgramChangeMeta,
    handleFilterChange,
    handleSearch,
    onSearch,
    onKeyDown,
    getTags,
    goToProgramDetails,
    handleSortChange,
    getFilters,
    toggleModal,
  } = useProgramListingHook(props, programContentFilterRef);
  const filters = useCallback(() => getFilters(), [programContentFilterRef.current?.filters]);

  return (
    <>
      <DocumentTitle title="page.title.programs" />
      <ProgramContentFilters
        ref={programContentFilterRef}
        setPrograms={setPrograms}
        setProgramChangeMeta={setProgramChangeMeta}
        // handleFilterChange={filterPrograms}
        isOpen={isFilterOpen}
        toggleModal={toggleModal}
        data={programForFilters}
      />
      <Box className={classes.titleContainer}>
        <Typography variant="h2" gutterBottom className={classes.headerTitle}>
          <FormattedMessage id="programCatalog" />
        </Typography>
        <Divider className={classes.divider} />
      </Box>
      <Grid container sm={12} md={12} spacing={3} className={classes.topBarContainer}>
        {/* BEGIN: SEARCH BAR */}
        <Grid item sm={12} md={6} className={classes.searchBarContainer}>
          <Box width="100%" className={classes.header}>
            <Typography className={classes.descriptionMargin}>
              <FormattedMessage
                id="programs.header.desc"
                values={{
                  span: (...chunks) => <span>{chunks}</span>,
                  br: <br />,
                }}
              />
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={10}>
                <FormControl variant="outlined" fullWidth size="small" className={classes.searchBarWrapper}>
                  <OutlinedInput
                    id="search-programs"
                    placeholder={intl.formatMessage({ id: 'searchPrograms' })}
                    value={searchTerm}
                    onChange={handleSearch}
                    labelWidth={0}
                    fullWidth
                    className={classes.searchBar}
                    onKeyDown={onKeyDown}
                    error={searchError}
                    inputProps={{
                      id: 'search-programs-input',
                      'aria-label': intl.formatMessage({ id: 'searchPrograms' }),
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize="medium" color="primary" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {searchError && (
                  <FormHelperText error id="search-programs-error">
                    <FormattedMessage id="minCharSearchError" values={{ min: minCharForSearch }} />
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={2}>
                <CommonsButton
                  id="search-programs-button"
                  className={classes.searchBtn}
                  color="primary"
                  variant="contained"
                  onClick={onSearch}
                  disabled={searchTerm.length < minCharForSearch}
                  tracking-type={Constant.TRACKING_TYPES.PROGRAM}
                  tracking-id="search-program"
                  aria-label={intl.formatMessage({ id: 'search' })}
                >
                  <FormattedMessage id="search" />
                </CommonsButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* END: SEARCH BAR */}

        {/* BEGIN: FILTERS */}
        <ProgramFilters handleFilterChange={handleFilterChange} selectedFilter={selectedFilter} />
        {/* END: FILTERS */}
      </Grid>
      <Grid container sm={12} md={8} spacing={5}>
        {programForFilters.length > 10 && (
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" margin="30px 20px">
            <Typography variant="h6" className={classes.programsLength}>
              {filteredPrograms?.length} <FormattedMessage id={'programsLength'} />
            </Typography>
            <Box>
              <FormControl className={classes.sortSelect}>
                <Select
                  value={sortOption}
                  className={classes.sortMenu}
                  onChange={handleSortChange}
                  displayEmpty
                  disableUnderline
                  IconComponent={ExpandMoreIcon}
                  inputProps={{ 'aria-label': 'Sort programs' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        borderRadius: '24px',
                      },
                    },
                  }}
                  classes={{
                    root: classes.sortMenuRoot,
                  }}
                >
                  <MenuItem value="" disabled style={{ display: 'none' }}>
                    <FormattedMessage id="sortBy" />
                  </MenuItem>
                  <MenuItem className={classes.dropdownItem} value="titleAZ">
                    <FormattedMessage id="programTitleAZ" />
                  </MenuItem>
                  <MenuItem className={classes.dropdownItem} value="titleZA">
                    <FormattedMessage id="programTitleZA" />
                  </MenuItem>
                  {/* <MenuItem value="duration">
              <FormattedMessage id="programDuration" />
            </MenuItem> */}
                  {/* <MenuItem value="popular"><FormattedMessage id="mostPopular" /></MenuItem>
              <MenuItem value="completed"><FormattedMessage id="mostCompleted" /></MenuItem> */}
                </Select>
              </FormControl>
              <CommonsButton
                id="filter-btn"
                className={classes.filterBtn}
                onClick={toggleModal}
                color="primary"
                variant="outlined"
              >
                Filter &nbsp; <TuneIcon />
              </CommonsButton>
            </Box>
          </Box>
        )}
      </Grid>
      {/* BEGIN: PROGRAM FILTER */}
      {/* BEGIN: PROGRAM FILTER BUTTON */}
      {filters().length > 0 && (
        <Grid id="filter-component" container sm={12} md={8} spacing={5}>
          <Grid item>
            <Box width="100%" display="flex" margin="10px 20px">
              <Box margin="13px">
                <Typography className={classes.filterText}>
                  <FormattedMessage id="filter" />:
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" flexWrap="wrap" spacing={2}>
                {filters().map(({ translationKey, value, parentIndex, childIndex }, index) => (
                  <Grow key={`${parentIndex}-${childIndex}`} in={true} timeout={index * 500}>
                    <Box className={classes.filterChip}>
                      {translationKey ? <FormattedMessage id={translationKey} /> : value}
                      <IconButton
                        id="filter-remove-btn"
                        className={classes.filterRemoveBtn}
                        onClick={() =>
                          programContentFilterRef.current.onFilterChange(parentIndex, childIndex, selectedFilter)
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grow>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* BEGIN: PROGRAM FILTER BUTTON */}
      {/* BEGIN: PROGRAMS LIST */}
      <Grid container sm={12} md={8} spacing={5} className={classes.programsContainer}>
        {(programs.length === 0 || filteredPrograms?.length === 0) && !dataLoading && !showLoaderOnFilterChange && (
          <Box>
            <Typography id="no-programs" className={classes.noResult}>
              <FormattedMessage id={selectedFilter === 'enrolled' ? 'noMyPrograms' : 'noSearchResults'} />
            </Typography>
          </Box>
        )}
        {showLoaderOnFilterChange ? (
          <Box className={classes.loaderContainer}>
            <CircularProgress aria-label="loading" />
          </Box>
        ) : (
          (filteredPrograms?.length ? filteredPrograms : programs).map((program) => (
            <Grid item sm={12} md={12}>
              <Box>
                <Program
                  title={program.bundleName}
                  description={program.descriptionShort}
                  image={program.thumbnailUriPath}
                  altText={program.thumbnailAltText}
                  buttonTitle={intl.formatMessage({ id: 'viewProgram' })}
                  enrolled={program.enrollmentState === 'ENROLLED'}
                  onClick={(e) => {
                    goToProgramDetails(e, program.bundleId);
                  }}
                  programId={program.bundleId}
                  providerLogo={program.resourceProvider?.thumbnailUriPath}
                  providerLogoAltText={program.resourceProvider?.thumbnailAltText}
                  category={program.category ? getCategoryFromName(program.category.name) : null}
                  tag={getTags(program.tag)}
                />
              </Box>
            </Grid>
          ))
        )}
      </Grid>
      {/* END: PROGRAMS LIST */}
    </>
  );
};

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(ProgramListing)));
