import * as store from ".";

const toggleDialog = props => {
  return {
    type: store.TOGGLE_DIALOG,
    props
  };
};

const setRules = rulesCollection => {
  return {
    type: store.SET_RULES,
    rulesCollection
  };
};

const setEarnTokenType = tokenType => {
  return {
    type: store.EARN_TOKEN_TYPE,
    tokenType
  };
};

export { toggleDialog, setRules, setEarnTokenType };
