import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme, withStyles } from '@material-ui/core';
import styles from './career-fit.style';
import { CAREER_DIRECTORY_CONSTANTS } from '../../services/careers.service';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@orijinworks/frontend-commons';

const CareerFit = ({ classes, careerFit }) => {
  const fitDetails = CAREER_DIRECTORY_CONSTANTS.CAREER_FITS[careerFit.toUpperCase()];
  const theme = useTheme();
  const iconProps = {
    witdh: 24,
    height: 24,
    fill: fitDetails.fill(theme),
  };

  return (
    <Box className={classes.root}>
      {fitDetails.icon(iconProps)}
      <Typography variant="overlineMedium" className={[classes.text, classes[`${careerFit}Fit`]].join(' ')}>
        <FormattedMessage id={fitDetails.intlId} />
      </Typography>
    </Box>
  );
};

CareerFit.propTypes = {
  classes: PropTypes.object.isRequired,
  careerFit: PropTypes.string.isRequired,
};

export default withStyles(styles)(CareerFit);
