export default theme => ({
  day: {
    textTransform: "capitalize",
    fontWeight: 600
  },
  daySchedule: {
    ...theme.typography.t14,
    color: "inherit",
    position: "relative",
    zIndex: 2,
    fontWeight: "inherit"
  },
  chipContainer: {
    color: theme.palette.text.sub,
    fontWeight: 500
  },
  scheduleWrapper: {
    "& > div:not(:first-child)": {
      marginTop: 15
    }
  },
  active: {
    ...theme.typography.bold,
    position: "relative",
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      position: "absolute",
      left: -13,
      top: 0,
      zIndex: 1,
      bottom: -1,
      width: "100%",
      maxWidth: "100%",
      borderRadius: 30,
      backgroundColor: theme.palette.primary.grey
    }
  },
  closed: {
    position: "relative",
    color: theme.palette.primary.battleshipGrey
  },
  closeActive: {
    ...theme.typography.bold,
    "&::after": {
      content: '""',
      position: "absolute",
      left: -13,
      top: 0,
      zIndex: 1,
      bottom: -1,
      width: "75%",
      borderRadius: 30,
      backgroundColor: theme.palette.primary.grey
    }
  }
});
