export default (theme) => ({
  root: {
    paddingTop: 30,
    width: '100%',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  description: {
    color: theme.palette.text.sub,
  },
  mainContainer: {
    paddingTop: 20,
  },
  actionBtn: {
    marginTop: 20,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTextBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerActionBox: {
    display: 'flex',
    alignItems: 'end',
  },
});
