export default theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.sub,
    padding: 20,
    position: "relative"
  },
  mainTitle: {
    color: theme.palette.primary.active,
    textTransform: "capitalize",
    fontWeight: 700,
    marginBottom: 20
  },
  subtitle: {
    color: "white",
    [theme.breakpoints.only("md")]: {
      fontSize: 10
    }
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      padding: 20
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px"
    },
    bottom: 0,
    left: 0,
    right: 0
  },
  containedBtn: {
    color: "white",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      fontSize: 12
    },
    [theme.breakpoints.only("md")]: {
      padding: 10,
      fontSize: 12
    }
  },
  outlinedBtn: {
    borderColor: "white",
    color: "white",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      fontSize: 12
    },
    [theme.breakpoints.only("md")]: {
      padding: 10,
      fontSize: 12
    }
  }
});