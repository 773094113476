import React, { Component, Suspense } from 'react';
import { withStyles, Typography, CircularProgress, Collapse, Button } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import styles from './index.styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Constant, Utility } from '../../../../shared';
import Alert from '@material-ui/lab/Alert';
import { AddContact } from '..';

const MessagesContainer = React.lazy(() => import('../messages-container'));
const SendEditor = React.lazy(() => import('../send-editor'));

class MessagesViewer extends Component {
  attorneyBlackBarTimeOut = null;
  scaleIcon = `${Utility.icon}justice-scales-blue.svg`;
  constructor(props) {
    super(props);
    this.state = {
      isShowBlackBarMessage: true,
      isMessageSending: false,
      limitError: true,
      showMessageMonitoredWarning: true,
    };
  }

  componentDidMount() {
    this.hideBlackBarMessage();
  }

  componentWillReceiveProps(nextProps) {
    const nextPropsSelectedContact = nextProps.selectedContact;
    const currentPropsSelectedContact = this.props.selectedContact;
    if (
      nextPropsSelectedContact &&
      currentPropsSelectedContact &&
      nextPropsSelectedContact.contactId !== currentPropsSelectedContact.contactId
    ) {
      this.setState({ isShowBlackBarMessage: true });
      this.hideBlackBarMessage();
    }
  }

  setMessageSending = (isMessageSending) =>
    this.setState({
      isMessageSending,
    });

  setLimitError = (limitError) =>
    this.setState({
      limitError,
    });

  hideBlackBarMessage = () => {
    if (this.attorneyBlackBarTimeOut != null) {
      clearTimeout(this.attorneyBlackBarTimeOut);
    }
    this.attorneyBlackBarTimeOut = setTimeout(() => {
      this.setState({ ...this.state, isShowBlackBarMessage: false });
    }, Constant.BLACK_BAR_TIMEOUT);
  };

  showBlackBarMessage = () => {
    this.setState({
      ...this.state,
      isShowBlackBarMessage: true,
    });
    this.hideBlackBarMessage();
  };

  hideMessageMonitoredWarning = () => {
    this.setState({ showMessageMonitoredWarning: false });
  };

  isActive = (status) => {
    return (
      status.learner === Constant.FNF_RELATIONSHIP_STATUS.ACCEPTED &&
      status.contact === Constant.FNF_RELATIONSHIP_STATUS.ACCEPTED &&
      status.admin === Constant.FNF_RELATIONSHIP_STATUS.ACCEPTED
    );
  };

  render() {
    const { classes, intl, onSend, characterLimit, newMessage, newSelectedContact, announcement, showErrorMsg, settings } =
      this.props;
    const { isMessageSending, limitError } = this.state;
    let { selectedContact } = this.props;
    if (!selectedContact) return null;

    let newSelectedRoleTypes =
      newSelectedContact && newSelectedContact.roleTypes ? newSelectedContact.roleTypes.split(',') : [];
    let selectedContactRoleTypes = selectedContact && selectedContact.roleTypes ? selectedContact.roleTypes.split(',') : [];

    return (
      <div className={classes.chatAreaContainer}>
        <div
          className={classes.chatTopBar}
          style={{
            display: typeof selectedContact.contactName !== 'string' ? 'none' : null,
          }}
        >
          <div className={classes.chatInfo}>
            {selectedContact.isAnnouncement ? (
              <RecordVoiceOverIcon className={classes.chatIcon} />
            ) : selectedContact.roleTypes === 'Attorney' || selectedContactRoleTypes.includes('Attorney') ? (
              <img
                alt="Attorney"
                src={this.scaleIcon}
                onClick={this.showBlackBarMessage}
                className={classes.attorneyIcon}
                style={{ marginRight: 17 }}
              />
            ) : (
              <QuestionAnswerIcon className={classes.chatIcon} />
            )}
            <Typography align="left" variant="h3" className={classes.userName}>
              {selectedContact.contactName}
            </Typography>
            {selectedContact.contactId ? (
              <Typography align="left" variant="span" className={classes.userInfo}>
                {selectedContact.roleName === 'Attorney' || selectedContactRoleTypes.includes('Attorney') ? (
                  <div className={classes.attorneyClientPrivileged}>
                    <FormattedMessage id="attorneyPrivileged" />
                    <img
                      alt="Attorney"
                      src={this.scaleIcon}
                      style={{ width: 16, opacity: 2 }}
                      className={classes.attorneyPriviledgeIcon}
                    />
                  </div>
                ) : (
                  `#${selectedContact.contactId}`
                )}
              </Typography>
            ) : (
              ''
            )}
          </div>
        </div>
        {!(
          (selectedContact.roleName &&
            (selectedContact.roleName === 'Attorney' || selectedContactRoleTypes.includes('Attorney'))) ||
          (newSelectedContact && (newSelectedContact.role === 'Attorney' || newSelectedRoleTypes.includes('Attorney')))
        ) && (
          <Collapse in={this.state.showMessageMonitoredWarning}>
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={this.hideMessageMonitoredWarning}
                  aria-label={intl.formatMessage({ id: 'dismiss' })}
                >
                  <FormattedMessage id="dismiss" />
                </Button>
              }
            >
              <FormattedMessage id="msgWarning" />
            </Alert>
          </Collapse>
        )}
        <Suspense fallback={<CircularProgress />}>
          {newMessage ? (
            <>
              <AddContact
                contacts={this.props.contacts}
                setContacts={this.props.setContacts}
                settings={this.props.settings}
              />
            </>
          ) : (
            <MessagesContainer
              selectedContact={selectedContact}
              announcement={announcement}
              setMessageSending={this.setMessageSending}
              setLimitError={this.setLimitError}
              isMessageSending={isMessageSending}
              learnerId={this.props.learnerId}
              contacts={this.props.contacts}
              onSelectedContact={this.props.onSelectedContact}
              setContacts={(contacts, _selectedContact) => {
                this.props.setContacts(contacts);
                if (_selectedContact) {
                  selectedContact = _selectedContact;
                  this.forceUpdate();
                }
              }}
            />
          )}
          {!newMessage && this.isActive(selectedContact.status) && (
            <SendEditor
              settings={settings}
              newMessage={newMessage}
              onSend={onSend}
              showErrorMsg={showErrorMsg}
              characterLimit={characterLimit}
              setMessageSending={this.setMessageSending}
              isMessageSending={isMessageSending}
              limitError={limitError}
              setLimitError={this.setLimitError}
              contactId={newMessage ? newSelectedContact : selectedContact.contact.personId}
            />
          )}
        </Suspense>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(MessagesViewer));
