import React from 'react';
import ProgramListingV1 from './program-listing-v1';
import ProgramListingV2 from '../../../../v2/content/programs/containers/program-listing/program-listing';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import withUnleash from '../../../../core/components/unleash/withUnleash';

const ProgramListing = (props) => {
  const { unleashProps } = props;
  if (unleashProps.isFlagEnabled()) {
    return <ProgramListingV2 {...props} />;
  } else {
    return <ProgramListingV1 {...props} />;
  }
};

export default withUnleash(ProgramListing, UnleashService.FLAGS.REDESIGN_PROGRAM_CATALOG);
