export default (theme) => ({
  mainFilters: {
    borderRadius: '10px',
    backgroundColor: 'white',
    width: '100%',
    padding: 30,
  },
  mainTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  divider: {
    width: '100%',
    margin: '20px 0px',
    height: 2,
  },
  filterArea: {
    paddingTop: 30,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBar: {
    backgroundColor: theme.palette.colors.inputBackground,
    height: 50,
    '&:focus-within': {
      outline: `2px solid ${theme.palette.primary.active}`,
    },
  },
  searchBtn: {
    textTransform: 'capitalize',
    marginLeft: 15,
    paddingLeft: 35,
    paddingRight: 35,
    height: 50,
  },
  categoryFilter: {
    borderRadius: 5,
    '&:focus-within': {
      outline: `2px solid ${theme.palette.primary.active}`,
    },
  },
  categoryFilterLabel: {
    fontWeight: 700,
    color: theme.palette.colors.textGrey,
    textTransform: 'uppercase',
  },
  categorySelect: {
    minWidth: 300,
    height: 50,
    backgroundColor: theme.palette.colors.inputBackground,
    borderRadius: 5,
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      borderBottom: 'none',
    },
    '&:focus': {
      borderBottom: 'none',
    },
    '&:active': {
      borderBottom: 'none',
    },
  },
  selectRoot: {
    backgroundColor: theme.palette.colors.inputBackground,
    '&:hover': {
      backgroundColor: theme.palette.colors.inputBackground,
    },
    '&:focus': {
      backgroundColor: theme.palette.colors.inputBackground,
    },
  },
  selectOption: {
    color: theme.palette.text.primary,
    padding: 10,
  },
  categorySelectLabel: {
    fontWeight: 700,
    padding: '0px 10px',
  },
  selectMenu: {
    '& option': {
      padding: 10,
      cursor: 'pointer',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: theme.palette.colors.inputBackground,
      },
    },
  },
  clearFiltersBtn: {
    ...theme.typography.t18,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.aqua.extraDeep,
  },
  noBorder: {
    border: 'none',
  },
  bold: {
    fontWeight: 700,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  bolder: {
    fontWeight: 500,
  },
  marginTop20: {
    marginTop: 20,
  },
  mainDescription: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
  },
  zoneSubtitle: {
    display: 'flex',
  },
  learnMoreZone: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
    marginLeft: 5,
  },
  zoneFiltersContainer: {
    margin: '20px 0px',
    alignItems: 'center',
  },
  zoneFilterBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    whiteSpace: 'nowrap',
  },
  zoneDetailsWrapper: {
    backgroundColor: '#F3F6F9',
    padding: 15,
    borderRadius: 5,
    height: '100%',
  },
  outlinedBtnZone1: {
    borderColor: theme.palette.jobZone.colors.zone1,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone1,
  },
  outlinedBtnZone2: {
    borderColor: theme.palette.jobZone.colors.zone2,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone2,
  },
  outlinedBtnZone3: {
    borderColor: theme.palette.jobZone.colors.zone3,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone3,
  },
  outlinedBtnZone4: {
    borderColor: theme.palette.jobZone.colors.zone4,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone4,
  },
  outlinedBtnZone5: {
    borderColor: theme.palette.jobZone.colors.zone5,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone5,
  },
  filledBtnZone1: {
    borderColor: theme.palette.jobZone.colors.zone1,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone1,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone1,
    },
  },
  filledBtnZone2: {
    borderColor: theme.palette.jobZone.colors.zone2,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone2,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone2,
    },
  },
  filledBtnZone3: {
    borderColor: theme.palette.jobZone.colors.zone3,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone3,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone3,
    },
  },
  filledBtnZone4: {
    borderColor: theme.palette.jobZone.colors.zone4,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone4,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone4,
    },
  },
  filledBtnZone5: {
    borderColor: theme.palette.jobZone.colors.zone5,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone5,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone5,
    },
  },
  otherFilters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 10px 0px 10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  otherFiltersContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      alignSelf: 'flex-start',
    },
  },
  viewContent: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      alignSelf: 'flex-end',
    },
  },
  totalCount: {
    fontWeight: 500,
    color: theme.palette.greyScale[110],
    marginRight: 10,
  },
  viewItem: {
    width: 'initial',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.greyScale[110],
    borderRadius: 4,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '& svg': {
      marginRight: 5,
    },
  },
  activeView: {
    color: theme.palette.text.primary,
    backgroundColor: '#E2E9EF',
  },
  list: {
    display: 'flex',
  },
  zoneItem: {
    width: 'initial',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 18,
  },
  activeZone: {
    borderBottom: `2px solid ${theme.palette.text.primary}`,
  },
  zoneInfoBtn: {
    width: 'initial',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.yellow.deep,
    '& svg': {
      marginRight: 5,
    },
  },
});
