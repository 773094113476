import { useEffect, useState } from 'react';
import { getHotlineContacts } from '../../services';
import { useTheme } from '@material-ui/core';

const useHotlineContactsHook = ({ isClosed }) => {
  const [hotlineContacts, setHotlineContacts] = useState([]);
  const theme = useTheme();
  const dialButtonColor = isClosed ? theme.palette.greyScale.grey90 : theme.palette.primary.main;

  const fetchHotlineContacts = async () => {
    const response = await getHotlineContacts();
    if (response?.data) {
      setHotlineContacts(response.data);
    }
  };

  useEffect(() => {
    fetchHotlineContacts();
  }, []);

  return {
    hotlineContacts,
    dialButtonColor,
  };
};
export default useHotlineContactsHook;
