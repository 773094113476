import { Box, Card, Dialog, IconButton, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Schedule } from '../../';
import styles from './play-schedule.style';

const PlaySchedule = (props) => {
  const { classes } = props;
  const intl = useIntl();

  return (
    <Dialog
      classes={{
        paper: classes.dialogRoot,
      }}
      onClose={() => props.setDialogVisibility(false)}
      open={props.isDialogOpen}
    >
      <Card
        data-cy-name="view-play-schedule-dialog"
        classes={{
          root: classes.root,
        }}
      >
        {/* BEGIN: CARD HEADER */}
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardHeader}
        >
          <Typography className={classes.dialogTitle}>
            {props.title ? props.title : <FormattedMessage id="playSchedule" />}
          </Typography>
          <IconButton
            data-cy-name="close-play-btn"
            onClick={() => props.setDialogVisibility(false)}
            className={classes.closeBtn}
            aria-label={intl.formatMessage({ id: 'close' })}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* END: CARD HEADER */}
        {/* BEGIN: CARD CONTENT */}
        <Box component="div" className={classes.cardContent}>
          <Schedule schedule={props.schedule} activeClass={classes.scheduleActive} />
        </Box>
        {/* END: CARD CONTENT */}
      </Card>
    </Dialog>
  );
};

export default withStyles(styles)(PlaySchedule);
