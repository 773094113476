import React from 'react';
import { Breadcrumbs as BreadcrumbsContainer, Button, Typography, withStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useIntl } from 'react-intl';
import useBreadcrumbHook from './useBreadcrumbsHook';
import styles from './breadcrumbs.styles';

/**
 * Component to render the breadcrumbs
 *
 * @component
 * @param {Object} classes - The classes that will be injected by withStyles
 * @param {Object} history - The react-router history object
 */
const Breadcrumbs = ({ classes, history }) => {
  const intl = useIntl();
  const { breadcrumbs, handleItemClick } = useBreadcrumbHook({ history });

  return (
    <BreadcrumbsContainer
      separator={<NavigateNextIcon className={classes.separator} />}
      aria-label="breadcrumb"
      classes={{ root: classes.root }}
      maxItems={3}
    >
      {breadcrumbs.length > 1 &&
        breadcrumbs.map((breadcrumb, index) => {
          const label = intl.formatMessage({ id: breadcrumb.label });
          const isLast = breadcrumbs.length - 1 === index;

          return isLast ? (
            <Button
              id={`breadcrumb-${breadcrumb.label}`}
              key={breadcrumb.path}
              className={[classes.breadcrumbItem, classes.activeBreadcrumb].join(' ')}
              aria-current="page"
              aria-disabled="true"
              tabindex="-1"
            >
              {label}
            </Button>
          ) : (
            <Button
              id={`breadcrumb-${breadcrumb.label}`}
              key={breadcrumb.path}
              aria-label={`Go to ${label}`}
              onClick={() => {
                handleItemClick(breadcrumb.path);
              }}
              className={classes.breadcrumbItem}
              role="link"
            >
              {label}
            </Button>
          );
        })}
    </BreadcrumbsContainer>
  );
};

export default withStyles(styles)(Breadcrumbs);
