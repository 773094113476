import React from 'react';
import ProgramDetailsV1 from './program-details-v1';
import { ProgramDetails as ProgramDetailsV2 } from '../../../../v2/content';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { useDocumentTitleHook } from '../../../../v2/shared';

const ProgramDetails = (props) => {
  const { unleashProps } = props;
  useDocumentTitleHook('page.title.program-details');

  if (unleashProps.isFlagEnabled()) {
    return <ProgramDetailsV2 {...props} />;
  } else {
    return <ProgramDetailsV1 {...props} />;
  }
};

export default withUnleash(ProgramDetails, UnleashService.FLAGS.NEW_PROGRAM_CATALOG_DESIGN);
