import React from "react";

const UpsetFaceIcon = props => {
  const { size, fill, opacity } = props;

  return (
    <svg style={{opacity: opacity}} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 55 55">
      <defs>
        <clipPath id="clip-Upset_Face">
          <rect width="55" height="55"/>
        </clipPath>
      </defs>
      <g id="Upset_Face" data-name="Upset Face" clipPath="url(#clip-Upset_Face)">
        <g id="face-confused-duotone" transform="translate(0.5 -336.328)">
          <path id="Path_92712" data-name="Path 92712" d="M0,27.5A27.5,27.5,0,1,1,27.5,55,27.5,27.5,0,0,1,0,27.5Zm18.38,15a29.21,29.21,0,0,1,18.251-6.4h1.182a1.719,1.719,0,0,0,0-3.437H36.631a32.473,32.473,0,0,0-20.4,7.154l-.118.1a1.542,1.542,0,0,0-.269,2.32,1.559,1.559,0,0,0,2.32.365Zm.569-23.59a3.438,3.438,0,1,0,3.438,3.438A3.434,3.434,0,0,0,18.949,18.906Zm17.188,6.875A3.438,3.438,0,1,0,32.7,22.344,3.441,3.441,0,0,0,36.137,25.781Z" transform="translate(0 336.328)" fill={fill}/>
        </g>
      </g>
    </svg>
  );
};

UpsetFaceIcon.defaultProps = {
  size: 24,
  opacity: 1,
  fill: "inherit"
}

export default UpsetFaceIcon;
