import React from 'react';
import { withStyles, Box, Typography, Button } from '@material-ui/core';

import styles from './introduction.style';
import { FormattedMessage, useIntl } from 'react-intl';

const Introduction = (props) => {
  const { classes, onSkip, onContinue } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.imageContainer}>
        <img
          id="onboarding-intro-img"
          alt=""
          src="https://content.connectedcorrections.com/assets/img/onboarding-intro-bg.png"
          className={classes.image}
        />
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.content}>
          <Typography id="onboarding-intro-title" variant="h3" className={classes.mainTitle}>
            <FormattedMessage id="onboarding.intro.title" />
          </Typography>

          <Typography id="onboarding-intro-desc" className={classes.description}>
            <FormattedMessage id="onboarding.intro.description" />
          </Typography>

          <Typography id="onboarding-intro-learn-more-desc" className={classes.description}>
            <FormattedMessage id="onboarding.intro.learn-more-desc" />
          </Typography>

          <Button
            id="onboarding-intro-continue-btn"
            variant="contained"
            color="primary"
            size="large"
            className={classes.filledButton}
            onClick={onContinue}
            aria-label={intl.formatMessage({ id: 'learnMore' })}
          >
            <FormattedMessage id="learnMore" />
          </Button>

          <Button
            id="onboarding-intro-skip-btn"
            size="large"
            className={classes.textButton}
            onClick={onSkip}
            aria-label={intl.formatMessage({ id: 'skipForNow' })}
          >
            <FormattedMessage id="skipForNow" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Introduction);
