const AUTHORIZED_USERS_KEY = 'authorizedUsers';
const USER_DAT_KEY = 'deviceAccessToken';

/**
 * Function to persist the user device access token.
 *
 * @param {Object} data - The data to persist
 */
export const persistUserDAT = ({ username, DAT, name }) => {
  const authorizedUsers = getAuthorizedUsers();

  if (authorizedUsers[username]) {
    authorizedUsers[username][USER_DAT_KEY] = DAT;
  }

  authorizedUsers[username] = {
    [USER_DAT_KEY]: DAT,
    username,
    name,
  };

  localStorage.setItem(AUTHORIZED_USERS_KEY, JSON.stringify(authorizedUsers));
};

/**
 * Function to get the user device access token.
 *
 * @param {String} username - The username
 * @returns {String} The user device access token
 */
export const getUserDAT = (username) => {
  let userDAT = '';
  const authorizedUsers = getAuthorizedUsers();
  if (authorizedUsers[username]) {
    userDAT = authorizedUsers[username][USER_DAT_KEY];
  }
  return userDAT;
};

/**
 * Function to get the authorized users.
 *
 * @returns {Object} The authorized users
 */
export const getAuthorizedUsers = () => {
  const value = localStorage.getItem(AUTHORIZED_USERS_KEY);
  if (value && isJSON(value)) {
    return JSON.parse(value);
  }
  return {};
};

/**
 * Function to check if the string is a valid JSON.
 *
 * @param {String} string - The string to check
 * @returns {Boolean} The flag to check if the string is a valid JSON
 */
export const isJSON = (string) => {
  let flag = true;
  try {
    JSON.parse(string);
  } catch (e) {
    flag = false;
  }
  return flag;
};
