import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import { Constant } from '../../../shared';
import styles from './footer.style';

const drawerWidth = Constant?.STYLES?.DRAWER_WIDTH;

const Footer = (props) => {
  const { classes } = props;

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        display: 'flex',
        justifyContent: 'space-between',
      }}
      className={classes.root}
    >
      <div style={{ marginTop: 4, marginLeft: '1rem' }}>
        <Link to="privacypolicy" style={{ color: 'white', fontSize: 12, marginRight: '1rem' }}>
          Privacy Policy
        </Link>
        <Link to="useragreement" style={{ color: 'white', fontSize: 12, marginRight: '1rem' }}>
          User Agreement
        </Link>
        <Link to="termsofuse" style={{ color: 'white', fontSize: 12, marginRight: '1rem' }}>
          Terms of Use
        </Link>
        <Link to="copyright" style={{ color: 'white', fontSize: 12, marginRight: '1rem' }}>
          Copyright
        </Link>
      </div>
      <div style={{ marginTop: 4, marginRight: '1rem' }}>
        <small style={{ color: 'white', fontSize: 12 }}>© 2019 American Prison Data Systems, PBC v.12.03.19</small>
      </div>
    </div>
  );
};

export default withStyles(styles)(Footer);
