import { useState } from 'react';

/**
 * Hook to manage the instructions for the Biometric Authentication Registration.
 *
 * @param {func} onContinue - The function to continue to the next step.
 * @returns {object}
 */
const useInstructionsHook = ({ onContinue }) => {
  const LAST_STEP = 3;
  const [step, setStep] = useState(1);

  /**
   * Function to check if the current step is the first step.
   *
   * @returns {boolean} - True if the current step is the first step.
   */
  const isFirstStep = () => {
    return step === 1;
  };

  /**
   * Function to check if the current step is the last step.
   *
   * @returns {boolean} - True if the current step is the last step.
   */
  const isLastStep = () => {
    return step === LAST_STEP;
  };

  /**
   * Function to handle the step change.
   * If the current step is the last step, it will call the onContinue function.
   * Otherwise, it will increment the step.
   */
  const handleStepChange = () => {
    if (isLastStep()) {
      onContinue();
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  return {
    step,
    isFirstStep,
    isLastStep,
    handleStepChange,
  };
};

export default useInstructionsHook;
