import React from 'react';
import { useDocumentTitleHook } from '../../../../shared';
import { Box, CircularProgress, IconButton, Typography, withStyles } from '@material-ui/core';
import { PillButton } from '../../../../../frontend-commons';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useResumePreviewHook from './useResumePreviewHook';
import styles from './resume-preview.style';
import { Permission } from '../../../../../core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { UnleashService } from '../../../../../core/services/unleash/unleash';
import withUnleash from '../../../../../core/components/unleash/withUnleash';

const ResumePreview = ({ classes, unleashProps, ...props }) => {
  const { isLoading, error, resumeData, pageNumber, totalPages, goBack, onDocumentLoadSuccess, onNextPage, onPreviousPage } =
    useResumePreviewHook(props);
  const intl = useIntl();
  useDocumentTitleHook('page.title.resume-preview');

  return (
    <Permission>
      <Box className={classes.root}>
        {!unleashProps.isFlagEnabled() && (
          <Box>
            <PillButton
              variant="contained"
              color="primary"
              onClick={goBack}
              startIcon={<ArrowBackIosIcon />}
              aria-label={intl.formatMessage({ id: 'goBack' })}
            >
              <FormattedMessage id="resume-preview.back-button.text" />
            </PillButton>
          </Box>
        )}

        {(isLoading || error) && (
          <Box className={classes.loadingContainer}>
            {isLoading && (
              <>
                <Typography id="loading-message" variant="h3" className={classes.loading}>
                  <FormattedMessage id="resume-preview.loading.title" />
                </Typography>
                <CircularProgress className={classes.loading} />
              </>
            )}
            {error && (
              <Typography id="error-message" variant="body1" className={classes.error}>
                <FormattedMessage id="resume-preview.error" />
              </Typography>
            )}
          </Box>
        )}

        {resumeData.data && (
          <Box className={classes.resumeContainer}>
            <Box id="resume-toolbar" className={classes.toolbar}>
              <IconButton
                id="resume-toolbar-previous-button"
                className={classes.pageControls}
                aria-label={intl.formatMessage({ id: 'previous' })}
                disabled={pageNumber === 1}
                onClick={onPreviousPage}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Box className={classes.pageInfo}>
                <Box className={classes.selectedPage}>
                  <Typography id="resume-toolbar-current-page" variant="body1" className={classes.bold}>
                    {pageNumber}
                  </Typography>
                </Box>
                <Typography id="resume-toolbar-total-pages" variant="body1" className={classes.bold}>
                  / {totalPages}
                </Typography>
              </Box>
              <IconButton
                id="resume-toolbar-next-button"
                className={classes.pageControls}
                aria-label={intl.formatMessage({ id: 'next' })}
                disabled={pageNumber === totalPages}
                onClick={onNextPage}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            <Box className={classes.pdfContainer}>
              <Document
                file={resumeData}
                renderMode="svg"
                className={classes.pdfDocument}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  className={classes.pdf}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            </Box>
          </Box>
        )}
      </Box>
    </Permission>
  );
};

export default withUnleash(withStyles(styles)(ResumePreview), UnleashService.FLAGS.BREADCRUMB_NAVIGATION);
