import React, { useRef } from 'react';
import { Typography, Box, Button, CardContent, CardMedia, Grid, withStyles } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import styles from './service-carousel.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Constant, Utility } from '../../../../shared/services';
import CustomDot from '../../../home/components/featured-contents/custom-dot';
import { RedirectIcon, KeyIcon } from '../../../../shared';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const responsiveProps = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1250, min: 464 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};

const ServiceCarousel = (props) => {
  const CarouselRef = useRef(null);
  const { classes } = props;
  const intl = useIntl();

  return (
    <Carousel
      id={`service-category-${props.categoryId}-carousel`}
      ref={CarouselRef}
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={15000}
      centerMode={false}
      partialVisible
      className={classes.carousel}
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass={classes.carouselItem}
      keyBoardControl
      minimumTouchDrag={80}
      customButtonGroup={null}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsiveProps}
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
      customDot={<CustomDot />}
      rewind
      rewindWithAnimation
    >
      {props.services.map((service) => (
        <Box
          onClick={() => {
            props.onCardClick(service);
          }}
          onKeyDown={(e) => {
            Utility.handleEnterKey(e, () => {
              props.onCardClick(service);
            });
          }}
          tabIndex={0}
          className={classes.root}
          tracking-type={Constant.TRACKING_TYPES.SERVICE}
          tracking-id={`launch-service-${service.id}`}
          id={`service-carousel-item-${service.id}`}
          title={`Launch ${service.name}`}
          role="link"
          aria-label={intl.formatMessage({ id: 'launchService' }, { service: service.name })}
        >
          <Grid item xs={5} sm={5} className={classes.thumbnailContainer}>
            <CardMedia
              id={`service-carousel-card-image-${service.id}`}
              component="img"
              alt=""
              className={classes.thumbnail}
              image={service.thumbnailUri}
              title={service.name}
            />
            {service.isVideo && <PlayCircleFilledIcon className={classes.playIcon} />}
          </Grid>
          <Grid item xs={7} sm={7} className={classes.content}>
            <CardContent className={classes.cardContent}>
              <Typography id={`service-carousel-card-title-${service.id}`} gutterBottom className={classes.cardTitle}>
                {service.name} &nbsp;
                {service.displayCredential && <KeyIcon size={20} fill="#0F445A" />}
              </Typography>
              <Typography
                id={`service-carousel-card-desc-${service.id}`}
                className={classes.description}
                color="textSecondary"
                component="p"
              >
                {service.description}
              </Typography>
            </CardContent>
            <Box className={classes.actionBox}>
              {service.serviceUrl && !service.isVideo && (
                <Typography
                  aria-hidden="true"
                  data-cy-name="feature-content-url-btn"
                  className={[classes.actionBtn, classes.serviceUrlBtn].join(' ')}
                  color="primary"
                  id={`service-carousel-card-button-${service.id}`}
                  aria-label={intl.formatMessage({ id: 'launchService' }, { service: service.name })}
                >
                  <FormattedMessage id="launch" />
                  {!(props.isAudio || props.isVideo || props.isRelativePath) && <RedirectIcon size={20} />}
                </Typography>
              )}
            </Box>
          </Grid>
        </Box>
      ))}
    </Carousel>
  );
};

export default withStyles(styles)(ServiceCarousel);
