import React from 'react';
import PropTypes from 'prop-types';

const BookmarkRibbonIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      role="img"
      aria-label="Bookmark ribbon icon"
      viewBox="22.0333 10 62.0333 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_10_736)">
        <path d="M84.5737 10H22.5737V90L53.5737 71.1312L84.5737 90V10Z" fill={fill} />
        <path
          d="M48.9337 44.3478L53.5737 40.8278L58.1937 44.3478L56.4337 38.6478L61.0737 34.9678H55.3937L53.5737 29.3478L51.7537 34.9678H46.0737L50.6937 38.6478L48.9337 44.3478ZM69.5737 36.9678C69.5737 28.1278 62.4137 20.9678 53.5737 20.9678C44.7337 20.9678 37.5737 28.1278 37.5737 36.9678C37.5737 41.0278 39.0937 44.7078 41.5737 47.5278V62.9678L53.5737 58.9678L65.5737 62.9678V47.5278C68.0537 44.7078 69.5737 41.0278 69.5737 36.9678ZM53.5737 24.9678C60.1937 24.9678 65.5737 30.3478 65.5737 36.9678C65.5737 43.5878 60.1937 48.9678 53.5737 48.9678C46.9537 48.9678 41.5737 43.5878 41.5737 36.9678C41.5737 30.3478 46.9537 24.9678 53.5737 24.9678Z"
          fill="white"
        />
        <path d="M18.5981 17.9977H22.5737V10L18.5981 17.9977Z" fill={fill} />
        <path d="M18.5981 17.9977H22.5737V10L18.5981 17.9977Z" fill="black" fillOpacity="0.4" />
      </g>
    </svg>
  );
};

BookmarkRibbonIcon.defaultProps = {
  width: 62.0333,
  height: 80,
  fill: '#624CA5',
};

BookmarkRibbonIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default BookmarkRibbonIcon;
