import { Box, Button, Collapse, Grid, MenuItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { FriendsFamilyMessaging } from '../../services';

import styles from './index.styles';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage, injectIntl } from 'react-intl';
class AddContact extends Component {
  state = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    relationship: '',
    errors: {},
    isSubmitted: false,
    isValid: false,
    alertShown: false,
    alert: {
      type: 'success',
      msg: '',
    },
    relationshipCountLimitIsReached: false,
  };
  messagingService = new FriendsFamilyMessaging();

  componentDidMount() {
    this.getLearnerSetting(parseInt(this.props.userDetail.userId));
  }

  getLearnerSetting = async (learnerPersonId) => {
    const _successLog = await this.messagingService.getLearnerSettings(learnerPersonId);
    if (_successLog) {
      const { payload } = _successLog;
      if (payload.relationshipCountLimitIsReached) {
        this.setState({
          alertShown: true,
          alert: {
            type: 'limit_error',
            msg: <FormattedMessage id="msgLimitReached" />,
          },
          relationshipCountLimitIsReached: payload.relationshipCountLimitIsReached,
        });
      }
    }
  };
  /**
   * @param {*} event
   * @desc A global change handler responsible for
   * storing values.
   * @return void
   */
  handleChange = (event) => {
    let { name, value } = event.target;
    if (name === 'phoneNumber') {
      value = value.replace(/[^0-9]/g, '');
      if (value.length > 10) {
        return false;
      }
    }
    const { state } = this;
    state[name] = value;

    this.setState(state, () => {
      this.validateFields();
    });
  };

  /**
   * @param {string} phoneNumber
   * @desc A utility method to check US number validity.
   * @return {boolean} isValid
   */
  isValidUSPhoneNumber(p) {
    const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    const digits = p.replace(/\D/g, '');
    return phoneRe.test(digits);
  }

  /**
   * @desc Wrapper method to check the validity against all the fields.
   * @return void
   */
  validateFields = () => {
    let isValid = true;
    const fields = ['firstName', 'lastName', 'phoneNumber', 'relationship'];
    const errorMsgs = {
      firstName: 'Please enter the first name.',
      lastName: 'Please enter the last name.',
      phoneNumber: 'Please enter a valid US phone number.',
      relationship: 'Please select a relationship.',
    };
    const { state } = this;
    const errors = {};

    // EMPTY FIELD CHECK
    fields.forEach((title) => {
      if (state[title] === '') {
        isValid = false;
        errors[title] = errorMsgs[title];
      }
    });

    // VALIDATING US PHONE NUMBER
    if (!this.isValidUSPhoneNumber(state.phoneNumber)) {
      isValid = false;
      errors['phoneNumber'] = errorMsgs['phoneNumber'];
    }

    this.setState({
      isValid,
      errors,
    });
  };

  /**
   * @param {string} phoneNumberString
   * @desc Formats phone number as per US standards.
   * @return {string} formattedPhoneNumber
   */
  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return this.state.phoneNumber;
  }

  /**
   * @desc Form submit handler that gets fire upon click of submit
   * button.
   * @return {void}
   */
  onSubmit = async () => {
    try {
      const { isValid } = this.state;
      this.validateFields();
      this.setState({
        isSubmitted: true,
      });
      if (!isValid) {
        return;
      }
      const { userDetail } = this.props;
      const { firstName, lastName, relationship, phoneNumber } = this.state;
      const payload = {
        firstName,
        lastName,
        relationship,
        phone: '+1' + phoneNumber,
      };

      const data = await this.messagingService.createRelationship(parseInt(userDetail.userId), payload);
      if (data.status === 'SUCCESS') {
        console.log(data);
        this.props.setContacts((this.props.contacts || []).concat(data.payload));
        this.setState({
          alertShown: true,
          alert: {
            type: 'success',
            msg: 'An invitation has been sent! If the invite is accepted, you can send a message.',
          },
        });
      } else {
        this.setState({
          alertShown: true,
          alert: {
            type: 'error',
            msg: data.payload.errorMessage,
          },
        });
      }

      this.setState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationship: '',
        isSubmitted: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @desc Hides status bar.
   * @return {void}
   */
  hideStatusBar = () => {
    this.setState({
      alertShown: false,
    });
  };

  render() {
    const { classes, intl } = this.props;
    const { isSubmitted, errors, alertShown, alert, relationshipCountLimitIsReached } = this.state;
    return (
      <>
        <Collapse in={alertShown}>
          <Alert
            severity={alert.type === 'limit_error' ? 'error' : alert.type}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={this.hideStatusBar}
                aria-label={intl.formatMessage({ id: 'dismiss' })}
              >
                <FormattedMessage id="dismiss" />
              </Button>
            }
          >
            <b>
              {alert.type === 'success' && <FormattedMessage id="submitted" />}
              {alert.type === 'error' && <FormattedMessage id="failed" />}
              {alert.type === 'limit_error' && <FormattedMessage id="sorry" />}
            </b>
            <br />
            {alert.msg}
          </Alert>
        </Collapse>
        {!relationshipCountLimitIsReached && (
          <Grid container item className={classes.mainWrapper}>
            <Grid md={12}>
              <Box className={classes.mainTitle}>
                <FormattedMessage id="addContact" />
              </Box>
              <Box className={classes.subText}>
                <FormattedMessage id="lovedOne" />
              </Box>
              <Box className={[classes.subText, classes.italicText].join(' ')}>
                <FormattedMessage id="limitContactMsg" values={{ max: this.props.settings.relationshipCountLimit || 5 }} />
              </Box>
              <Box className={classes.formWrapper}>
                <Box className={classes.fieldWrapper}>
                  <TextField
                    id="firstName"
                    error={isSubmitted && errors.hasOwnProperty('firstName')}
                    helperText={isSubmitted ? errors.firstName : ''}
                    onChange={this.handleChange}
                    name="firstName"
                    value={this.state.firstName}
                    className={classes.formField}
                    autoFocus
                    label="First Name"
                    variant="outlined"
                  />
                </Box>
                <Box className={classes.fieldWrapper}>
                  <TextField
                    id="lastName"
                    error={isSubmitted && errors.hasOwnProperty('lastName')}
                    helperText={isSubmitted ? errors.lastName : ''}
                    onChange={this.handleChange}
                    name="lastName"
                    value={this.state.lastName}
                    className={classes.formField}
                    label="Last Name"
                    variant="outlined"
                  />
                </Box>
                <Box className={classes.fieldWrapper} position="relative">
                  <Box className={classes.phoneSideElement}>
                    <img src="/assets/img/usa.jpg" alt="Enter a U.S.A. phone number" className={classes.USAFlag} />1
                  </Box>
                  <TextField
                    id="phoneNumber"
                    error={isSubmitted && errors.hasOwnProperty('phoneNumber')}
                    helperText={isSubmitted ? errors.phoneNumber : ''}
                    onChange={this.handleChange}
                    value={this.formatPhoneNumber(this.state.phoneNumber)}
                    name="phoneNumber"
                    classes={{ root: classes.phone }}
                    InputLabelProps={{ shrink: true }}
                    className={[classes.formField].join(' ')}
                    label="Mobile Phone Number"
                    variant="outlined"
                  />
                </Box>
                <Box className={classes.fieldWrapper}>
                  <TextField
                    id="relationship"
                    error={isSubmitted && errors.hasOwnProperty('relationship')}
                    helperText={isSubmitted ? errors.relationship : ''}
                    className={classes.formField}
                    select
                    onChange={this.handleChange}
                    name="relationship"
                    variant="outlined"
                    label="Relationship"
                    value={this.state.relationship}
                    inputProps={{ id: 'relationship' }}
                  >
                    <MenuItem value={'Parent'}>
                      <FormattedMessage id="parent" />
                    </MenuItem>
                    <MenuItem value={'Child'}>
                      <FormattedMessage id="child" />
                    </MenuItem>
                    <MenuItem value={'Sibling'}>
                      <FormattedMessage id="sibling" />
                    </MenuItem>
                    <MenuItem value={'Friend'}>
                      <FormattedMessage id="friend" />
                    </MenuItem>
                    <MenuItem value={'Spouse'}>
                      <FormattedMessage id="spouse" />
                    </MenuItem>
                    <MenuItem value={'Extended family member'}>
                      <FormattedMessage id="extendedFamMember" />
                    </MenuItem>
                    <MenuItem value={'Other'}>
                      <FormattedMessage id="other" />
                    </MenuItem>
                  </TextField>
                </Box>
              </Box>
              <Box className={classes.formAction}>
                <Button
                  onClick={this.onSubmit}
                  className={classes.formField}
                  variant="contained"
                  color="primary"
                  aria-label={intl.formatMessage({ id: 'submit' })}
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(AddContact)));
