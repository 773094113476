export default (theme) => ({
  cardLayout: {
    width: '100%',
  },
  thumbnailLink: {
    textDecoration: 'none',
    borderRadius: '10px',
    position: 'relative',
    zIndex: 99,
    [theme.breakpoints.up('md')]: {
      maxWidth: 665,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mainBackground: {
    background: 'url(https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Homepage.png)',
    backgroundColor: 'transparent',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
  },
  resultsBackground: {
    background: 'url(https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Results-BG.png)',
    backgroundColor: 'white',
    backgroundSize: '75%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
  },
  whiteBackground: {
    backgroundColor: 'white',
  },
  contentArea: {
    margin: '40px 20px',
    width: '100%',
    position: 'relative',
  },
  card: {
    borderRadius: '10px',
    width: '70%',
  },
  fullWidth: {
    width: '100%',
  },
});
