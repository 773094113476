export default (theme) => ({
  main: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 400,
    backgroundColor: '#212FA3',
    boxShadow: '24',
    padding: '32px',
    textAlign: 'center',
    color: theme.palette.primary.white,
    borderRadius: '10px',
  },
  innerMain: {
    /* margin-top: 60%; */
    justifyContent: 'space-evenly',
    position: 'absolute',
    width: '100%',
    bottom: '32px',
    left: '0',
    display: 'flex',
  },
  description: {
    marginTop: 2,
    fontSize: '1rem',
  },
  chip: {
    marginTop: '5%',
  },
  thirtySecondsLeft: {
    backgroundColor: theme.palette.primary.red,
  },
  oneMinuteLeft: {
    backgroundColor: theme.palette.primary.orange,
  },
  fiveMinutesLeft: {
    backgroundColor: theme.palette.primary.yellow,
  },
  AccountCircleIcon: {
    fontSize: '4rem',
  },
  VolumeUpIcon: {
    color: theme.palette.primary.white,
  },
  CallEndIcon: {
    color: theme.palette.primary.white,
  },
  MicOnOffIcon: {
    color: theme.palette.primary.white,
  },
  VolumeUpIconBtn: {
    backgroundColor: theme.palette.CallingModal.btnBgColor.backgroundColor,
    '&:hover': {
      background: theme.palette.CallingModal.btnBgColor.backgroundColor,
    },
  },
  CallEndIconBtn: {
    backgroundColor: theme.palette.CallingModal.CallEndIconColor.backgroundColor,
    padding: '16px',
    marginTop: '-20px',
    '&:hover': {
      background: theme.palette.CallingModal.CallEndIconColor.backgroundColor,
    },
  },
  MicIconBtn: {
    backgroundColor: theme.palette.CallingModal.btnBgColor.backgroundColor,
    '&:hover': {
      background: theme.palette.CallingModal.btnBgColor.backgroundColor,
    },
  },
  MuiIconBox: { display: 'flex', flexDirection: 'column', gridGap: '5px' },
  CallEndIconBox: { display: 'flex', flexDirection: 'column', gridGap: '20px' },
});
