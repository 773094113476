export default (theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  playButton: {
    padding: 0,
    height: '100%',
  },
  playButtonLabel: {
    height: '100%',
  },
  thumbnail: {
    height: '100%',
    width: 271,
    borderBottomLeftRadius: 16,
  },
  iconContainer: {
    position: 'absolute',
    borderRadius: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  playIcon: {
    color: 'white',
    fontSize: 24,
  },
  title: {
    color: theme.palette.navy.main,
  },
  description: {
    color: theme.palette.greyScale[110],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    gap: 16,
  },
});
