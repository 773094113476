import { Box, Button, Card, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Utility } from '../../../../shared/services';
import styles from './play-achievements.style';

const PlayAchievements = (props) => {
  const utilityService = new Utility();
  const { classes, rulesCollection } = props;
  const intl = useIntl();

  // RETURN NULL IF THERE'S NO META
  if (!props.meta) {
    return null;
  }

  const hasTokensEarned = !!props.meta.taskName;
  const hasMostRecent = !!props.meta.name && rulesCollection.length > 0;

  return (
    <Grid container item>
      {hasTokensEarned && utilityService.checkStatusExists(props.features, 'Incentive_Engine') && (
        <Grid
          data-cy-name="token-earned"
          item
          xs={12}
          md={!hasMostRecent ? 12 : 6}
          className={[classes.achievementBox, classes.box1].join(' ')}
        >
          <Link to={'/achievements'}>
            <Card className={classes.achievementWrapper}>
              {/* BEGIN: CONTENT */}
              <Grid container item alignItems="center" flex={1}>
                <Box component="div">
                  <Typography className={classes.achievementTitle}>
                    <FormattedMessage
                      id="greatJobPlay"
                      values={{
                        name: props.userDetail.userName,
                      }}
                    />
                    <br />
                    <FormattedMessage
                      id="earnedPlay"
                      values={{
                        token: props.meta.tokenNo,
                      }}
                    />
                  </Typography>
                </Box>
              </Grid>
              {/* END: CONTENT */}
              {/* BEGIN: LINK */}
              <Grid container item justify="flex-end">
                <Box component="div">
                  <Button
                    tabIndex={-1}
                    className={classes.actionBtn}
                    color="primary"
                    endIcon={<ChevronRightIcon fontSize="large" />}
                    aria-label={intl.formatMessage({ id: 'achievements' })}
                  >
                    <FormattedMessage id="achievements" />
                  </Button>
                </Box>
              </Grid>
              {/* END: LINK */}
            </Card>
          </Link>
        </Grid>
      )}
      {hasMostRecent && utilityService.checkStatusExists(props.features, 'Incentive_Engine') && (
        <Grid
          data-cy-name="task-completed"
          item
          xs={12}
          md={!hasTokensEarned ? 12 : 6}
          className={[classes.achievementBox, classes.box2].join(' ')}
        >
          <Link to={'/programs'}>
            <Card className={classes.achievementWrapper}>
              {/* BEGIN: CONTENT */}
              <Grid container item alignItems="center" flex={1}>
                <Grid item md={10} component="div">
                  <Typography className={classes.achievementTitle}>
                    <FormattedMessage id="earnPoints" />
                  </Typography>
                </Grid>
              </Grid>
              {/* END: CONTENT */}
              {/* BEGIN: LINK */}
              <Grid container item justify="flex-end">
                <Box component="div">
                  <Button
                    tabIndex={-1}
                    className={classes.actionBtn}
                    color="primary"
                    endIcon={<ChevronRightIcon fontSize="large" />}
                    aria-label={intl.formatMessage({ id: 'programCatalog' })}
                  >
                    <FormattedMessage id="programCatalog" />
                  </Button>
                </Box>
              </Grid>
              {/* END: LINK */}
            </Card>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app, earnToken }) => ({ ...app, ...earnToken });

export default withStyles(styles)(connect(mapStateToProps)(PlayAchievements));
