import { Http } from "../../../../core";
import { Constant } from "../../../../shared/services";

export class PathwayListingService {
  static CACHE_DETAILS_DATA = {};

  /**
   * @name setCacheDetailsData
   * @property SET
   * @desc Setter property to set cache details data.
   * @return {void}
   */
  set setCacheDetailsData(data) {
    PathwayListingService.CACHE_DETAILS_DATA = data;
  }

  /**
   * @name getCacheDetailsData
   * @property GET
   * @desc Getter property to get cache details data.
   * @return {void}
   */
  get getCacheDetailsData() {
    return PathwayListingService.CACHE_DETAILS_DATA;
  }

  /**
   * @name getDashboardAnalytics
   * @desc Fetches analytics from service that will be mapped on
   * dashboard.
   * @return {Promise}
   */
  getDashboardAnalytics = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(
        `/user/accomplishment?days=${Constant.ANALYTICS_NO_OF_DAYS}`
      ).then(_successLog => {
        const { data } = _successLog;
        if (!data) {
          return;
        }

        if (data.tokens === 0 && data.hours === 0 && data.tasks === 0) {
          resolve({});
          return;
        }

        resolve(data);
      });
    });
  };
}
