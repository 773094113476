import React from 'react';
import CallHistoryLogs from '../../components/call-history-logs/call-history-logs';

const CallHistory = () => {
  return (
    <>
      <CallHistoryLogs />
    </>
  );
};

export default CallHistory;
