export default theme => ({
  playRoot: {
    width: "100%"
  },
  playTabs: {
    width: "100%"
  },
  playTabRoot: {
    color: theme.palette.primary.black,
    ...theme.typography.t14,
    ...theme.typography.medium,
    lineHeight: "16px",
    letterSpacing: "1.25px",
    ...theme.typography.medium
  },
  indicator: {
    backgroundColor: theme.palette.primary.themeGreen,
    height: "8px"
  },
  playTabActive: {
    position: "relative",
    "&::after": {
      content: '""',
      top: 0,
      bottom: 0,
      width: "100%",
      background:
        "linear-gradient(180deg, rgba(1, 113, 237, 0.1) 0%, rgba(1, 113, 237, 0.3) 100%)",
      position: "absolute",
      opacity: 0.4
    }
  },
  tabWrapper: {
    flexDirection: "row",
    fontSize: "20px"
  },
  tabLabelIcon: {
      minHeight: "auto",
      '& $tabWrapper > *:first-child': {
          margin: '0 15px'
      }
  }
});
