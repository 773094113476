export default (theme) => ({
  moduleContainer: {
    display: 'flex',
    padding: '16px 0px',
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
  },
  moduleBorder: {
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
    padding: '16px 24px',
  },
  moduleItem: {
    display: 'flex',
  },
  moduleNameContainer: {
    flex: 1,
  },
  moduleText: {
    color: theme.palette.yellow.deep,
    textTransform: 'uppercase',
    marginBottom: 12,
  },
  moduleName: {
    fontWeight: 500,
    fontSize: 22,
    lineHeight: '26.63px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  launchBtn: {
    textTransform: 'capitalize',
    fill: theme.palette.primary.main,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
});
