import { Http } from '../../../../core';

/**
 * @name transformDataBeforeSave
 * @description Transform the data before saving it to the server
 * @param {Object} data - The data to be transformed
 * @returns {Object} - The transformed data
 */
const transformDataBeforeSave = (data, userDetail) => ({
  personalInfo: {
    firstName: data.personalInfo.firstName || userDetail?.firstName || '',
    lastName: data.personalInfo.lastName || userDetail?.lastName || '',
    city: data.personalInfo.location.city,
    state: data.personalInfo.location.state,
    email: data.personalInfo.email,
    phoneNumber: data.personalInfo.phoneNumber,
    aboutMe: data.personalInfo.aboutMeSummary,
  },
  workExperiences: data.workExperiences.map((item) => ({
    title: item.jobTitle,
    company: item.company,
    city: item.location.city,
    state: item.location.state,
    startDateMonth: item.startDate.month || null,
    startDateYear: item.startDate.year,
    endDateMonth: item.endDate?.month || null,
    endDateYear: item.endDate?.year || null,
    stillWorks: item.isCurrentlyWorking,
    jobDescription: item.jobDescription,
  })),
  educations: data.educations.map((item) => ({
    degree: item.degree,
    otherDegree: item.otherDegree,
    school: item.school,
    graduationYear: item.graduationYear || null,
    fieldOfStudy: item.fieldOfStudy,
  })),
  achievements: data.achievements.map((item) => ({
    title: item.name,
    issuingOrganization: item.organization,
    issuedDateMonth: item.issueDate?.month || null,
    issuedDateYear: item.issueDate?.year || null,
    description: item.description,
  })),
  skills: [...data.skills],
});

/**
 * @name transformDataAfterFetch
 * @description Transform the data after fetching it from the server
 * @param {Object} data - The data to be transformed
 * @returns {Object} - The transformed data
 */
const transformDataAfterFetch = (data) => ({
  personalInfo: {
    firstName: data.personalInfo.firstName,
    lastName: data.personalInfo.lastName,
    location: {
      city: data.personalInfo.city || '',
      state: data.personalInfo.state || '',
    },
    email: data.personalInfo.email || '',
    phoneNumber: data.personalInfo.phoneNumber || '',
    aboutMeSummary: data.personalInfo.aboutMe || '',
  },
  workExperiences: data.workExperiences.map((item) => ({
    jobTitle: item.title,
    company: item.company,
    location: { city: item.city, state: item.state },
    startDate: { month: item.startDateMonth || '', year: item.startDateYear },
    endDate: { month: item.endDateMonth || '', year: item.endDateYear || '' },
    isCurrentlyWorking: item.stillWorks,
    jobDescription: item.jobDescription,
  })),
  educations: data.educations.map((item) => ({
    degree: item.degree,
    otherDegree: item.otherDegree || '',
    school: item.school,
    graduationYear: item.graduationYear,
    fieldOfStudy: item.fieldOfStudy || '',
  })),
  achievements: data.achievements.map((item) => ({
    name: item.title,
    organization: item.issuingOrganization,
    issueDate: { month: item.issuedDateMonth || '', year: item.issuedDateYear || '' },
    description: item.description,
  })),
  skills: [...data.skills],
});

/**
 * @name getOptionsData
 * @description Fetch the options data (degrees, states, and skills) from the server
 * @returns {Promise<Object>} - The options data
 */
const getOptionsData = () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/resumes/v1/supports/options')
      .then((_successLog) => {
        if (_successLog?.data?.payload) {
          resolve(_successLog.data.payload);
        }
        resolve({ states: [], degrees: [], skills: [] });
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name getResumeData
 * @description Fetch the resume data from the server
 * @returns {Promise<Object>} - The resume data
 */
const getResumeData = () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/resumes/v1')
      .then((_successLog) => {
        if (_successLog?.data?.payload) {
          resolve(transformDataAfterFetch(_successLog.data.payload));
        }
        resolve(null);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name saveResumeData
 * @description Save the resume data to the server
 * @param {Object} data - The resume data to be saved
 * @returns {Promise}
 */
const saveResumeData = (data, userDetail) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.put('/services/resumes/v1?noLoader=true', transformDataBeforeSave(data, userDetail))
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name convertToUint8Array
 * @description Convert the base64 data to Uint8Array
 * @param {String} data - The base64 data
 * @returns {Uint8Array} - The Uint8Array data
 */
const convertToUint8Array = (data) => {
  const bytesData = atob(data);

  const uint8ArrayData = new Uint8Array(bytesData.length);
  for (let i = 0; i < bytesData.length; i++) {
    uint8ArrayData[i] = bytesData.charCodeAt(i);
  }

  return uint8ArrayData;
};

/**
 * @name getResumeContent
 * @description Fetch the resume content from the server
 * @returns {Promise<Uint8Array>} - The resume content
 */
const getResumeContent = () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/resumes/v1/resume-file?noLoader=true')
      .then((_successLog) => {
        if (_successLog?.data?.payload) {
          resolve(convertToUint8Array(_successLog.data.payload));
        }
        resolve(null);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

export { getOptionsData, getResumeData, saveResumeData, getResumeContent };
