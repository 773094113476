export default (theme) => ({
  root: {},
  header: {
    padding: 30,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  description: {
    color: theme.palette.text.sub,
  },
  mainContainer: {
    paddingTop: 30,
  },
});
