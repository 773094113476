import KeycloakJS from 'keycloak-js';
import { Constant, Utility } from '../../../shared';
import { showHidePopup, showSessionExpirePage, store } from '../../store';
import { removeCookie } from '../../../shared/services/utility/cookie';

export class Keycloak {
  static Keycloak = KeycloakJS({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: 'apds',
    clientId: 'apds-connected-corrections',
    onLoad: 'login-required',
    promiseType: 'native',
    flow: 'implicit',
  });

  /**
   * @name keyCloakEvent
   * @param {object} event Event received from key cloak.
   * @desc Key cloak event handling when fired by service.
   * @return {void}
   */
  keyCloakEvent = (event, error) => {
    switch (event) {
      case Constant.KEYCLOCK.AUTH_SUCCESS:
        Utility.manageLogout('delete');
        break;
      case Constant.KEYCLOCK.TOKEN_EXPIRED:
        /** TODO: will handle token expire event */
        break;
      case Constant.KEYCLOCK.LOGOUT:
        Utility.manageLogout('set');
        removeCookie('session_id');
        store.dispatch(showSessionExpirePage({ openSessionExpire: true }));
        break;
      case Constant.KEYCLOCK.TOKEN_REFRESH_ERROR:
        /** TODO: Will handle logout and refresh errot */
        break;

      case Constant.KEYCLOCK.AUTH_ERROR:
        Keycloak.Keycloak.logout();
        break;

      default:
        break;
    }
  };
}
