import { Box, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as appStore from '../../../core/store/app';
import { Utility, Constant } from '../../services';
import styles from './content-areas-filter.style';

/**
 * @name getContentAreas
 * @desc Fetches content areas.
 * @return {void}
 */
const getContentAreas = (setCollection, setLoading, props) => {
  const utilityService = new Utility();
  utilityService.getContentAreas(props.contentAreas).then((_successLog) => {
    setCollection(_successLog);
    setLoading(false);
    props.setContentAreas(_successLog);
  });
};

const ContentAreasFilter = (props) => {
  const { classes, selectedFilters, hideViewAllBtn } = props;
  const [isLoading, setLoading] = React.useState(true);
  const [contentAreaCollection, setCollection] = React.useState([]);
  const [isMounted, setMount] = React.useState(false);
  const intl = useIntl();

  React.useLayoutEffect(() => {
    if (!isMounted) {
      getContentAreas(setCollection, setLoading, props);
      setMount(true);
    }
  }, [setCollection, isMounted, props]);

  if (isLoading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }
  let updatedContent = [];
  let contentValues = ['Education', 'Rehabilitation', 'Workforce Skills', 'Re-Entry', 'Enrichment'];
  for (let index = 0; index < contentValues.length; index++) {
    updatedContent.push(contentAreaCollection.filter((v) => v.name === contentValues[index])[0]);
  }
  return (
    <Grid container spacing={2}>
      {updatedContent.map((contentArea) => (
        <Grid key={contentArea.id} item>
          <Button
            id={`content-area-filter-${contentArea.name}`}
            variant="text"
            onClick={() => props.setFilters('specific', contentArea.id)}
            startIcon={Constant.CONTENT_AREA[contentArea.id].icons.main}
            className={[
              classes.contentAreaBtn,
              props.isActiveFilter(contentArea.id)
                ? classes[Constant.CONTENT_AREA[contentArea.id].key]
                : classes.disabledContentArea,
            ].join(' ')}
            tracking-type={Constant.TRACKING_TYPES.SERVICE}
            tracking-id={`filter-services-by-content-area-${contentArea.id}`}
            aria-label={intl.formatMessage({ id: contentArea.name.toLowerCase() })}
          >
            <FormattedMessage id={contentArea.name.toLowerCase()} />
          </Button>
        </Grid>
      ))}
      {!!!hideViewAllBtn && (
        <Grid item>
          <Button
            id="content-area-filter-view-all"
            //** Subtracting the TOOLS content area as there is no filter for it */
            variant={selectedFilters.length - 1 >= updatedContent.length ? 'contained' : 'text'}
            onClick={() => props.setFilters('all')}
            className={[classes.contentAreaBtn, classes.viewAllBtn].join(' ')}
            color="primary"
            tracking-type={Constant.TRACKING_TYPES.SERVICE}
          >
            <FormattedMessage id="viewAll" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app }) => app;

const mapDispatchToProps = (dispatch) => {
  return {
    setContentAreas: (collection) => dispatch(appStore.setContentAreas(collection)),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContentAreasFilter));
