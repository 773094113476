export default (theme) => ({
  root: {
    paddingTop: 32,
  },
  separator: {
    color: theme.palette.aqua.deep,
  },
  breadcrumbItem: {
    ...theme.typography.t16,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.aqua.extraDeep,
    padding: 12,
  },
  activeBreadcrumb: {
    color: theme.palette.primary.white,
    borderRadius: 30,
    height: 40,
    backgroundColor: theme.palette.aqua.extraDeep,
    pointerEvents: 'none',
  },
});
