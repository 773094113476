export default (theme) => ({
  tabsMain: {
    fontFamily: 'inherit',
    fontSize: '36px',
    lineHeight: 'inherit',
    color: theme.palette.aqua.extraDeep,
    justifyContent: 'start',
    borderBottom: '2px solid ' + theme.palette.primary.light,
  },
  tab: {
    opacity: '1',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  iconSpacing: {
    marginRight: theme.spacing(1), // Adjust the spacing as needed
  },
  formattedParagraph: {
    fontFamily: 'inherit',
    fontSize: '2rem',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    marginTop: '50px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '80px',
  },
  requestedContactsHeading: {
    marginBottom: '30px',
  },
  addContactForm: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    marginLeft: '70px',
    marginTop: '80px',
    width: '280px',
  },
  drawerHeading: {
    textAlign: 'center',
    maxHeight: '80px',
    marginTop: '50px',
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  name: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  relationship: {
    color: 'blue',
  },
  contactNumber: {
    color: 'grey',
    fontSize: '0.9rem',
  },
  statusPaper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '50px',
    padding: theme.spacing(1, 2),
  },
  approvedStatus: {
    backgroundColor: 'rgba(0, 128, 0, 0.2)',
    border: '1px solid green',
    color: 'green',
  },
  blockedStatus: {
    backgroundColor: 'rgba(128, 128, 128, 0.2)',
    border: '1px solid grey',
    color: 'grey',
  },
  pendingStatus: {
    backgroundColor: 'rgba(255, 255, 0, 0.2)',
    border: '1px solid yellow',
    color: 'black',
  },
  rejectedStatus: {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    border: '1px solid red',
    color: 'red',
  },
  container: {
    border: '1px solid darkgrey',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  nameColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  nameText: {
    display: 'flex',
    alignItems: 'center',
  },
  relationshipText: {
    marginLeft: theme.spacing(1),
  },
  editButton: {
    textAlign: 'right',
  },
  statusColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  contactsGrid: {
    alignItems: 'center',
  },
  parentCard: {
    margin: theme.spacing(2),
  },
  icon: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
  },
  statusText: {
    margin: theme.spacing(0, 0.5),
  },
  paperBox: {
    display: 'flex',
  },
  mainCard: {
    width: '100%',
    borderRadius: '20px',
  },
  tabContainer: {
    backgroundColor: theme.palette.greyScale.grey10,
  },
  tabBody: {
    padding: '24px',
    height: 'auto',
  },
  scheduleRow: {
    padding: '10px 0',
  },
  dayOfWeek: {
    color: theme.palette.cerulean.cerulean20,
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  timeSlot: {
    paddingTop: 2,
  },
});
