import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  fabButton: {
    width: "36px",
    height: "30px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    position: "fixed",
    bottom: "4%",
    right: "4%"
  }
}));
