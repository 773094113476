export default theme => ({
  root: {
    padding: 30
  },
  title: {
    textTransform: "capitalize",
    fontWeight: 700
  },
  description: {},
  actionBtn: {
    marginTop: 30,
    textTransform: "capitalize",
    fontWeight: 700
  }
});
