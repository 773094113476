import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ staticContext = {} }) => {
  staticContext.status = 404;
  return (
    <div style={{ marginTop: "7rem", textAlign: "center" }}>
      <h2>
        <FormattedMessage id="nothingExists" />
      </h2>
      <span>
        <FormattedMessage id="nothingExistsDescription" />
      </span>
    </div>
  );
};
