import React from 'react';
import { withStyles, Box, Typography } from '@material-ui/core';

import styles from './attribution.style';
import { FormattedMessage } from 'react-intl';

const Attribution = (props) => {
  const { classes } = props;

  return (
    <Box className={classes.root}>
      <img
        id="onet-logo"
        className={classes.logo}
        src="https://content.connectedcorrections.com/assets/logo/onet-logo.png"
        alt="O. Net"
      />
      <Box className={classes.textContainer}>
        <Typography id="onet-attribution-text" className={classes.text}>
          <FormattedMessage id="onetAttribution" />
        </Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Attribution);
