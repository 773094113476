export default (theme) => ({
  root: {
    backgroundColor: theme.palette.aqua.extraDeep,
    backgroundImage: 'url("/assets/img/enroll_footer_mask.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 16,
    marginTop: 50,
    height: 176,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    color: 'white',
  },
  actionButton: {
    width: 240,
    marginTop: '12px !important',
  },
});
