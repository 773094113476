const useStatusModalHook = (props) => {
  const STATUS_CLASS_MAPPING = {
    important: 'important',
    rejected: 'requestRejected',
    blocked: 'requestBlocked',
    pending: 'requestSent',
    failed: 'requestFailed',
  };
  function getStatusClassName(status) {
    switch (status) {
      case 'important':
        return STATUS_CLASS_MAPPING.important;
      case 'rejected':
        return STATUS_CLASS_MAPPING.rejected;
      case 'blocked':
        return STATUS_CLASS_MAPPING.blocked;
      case 'pending':
        return STATUS_CLASS_MAPPING.pending;
      case 'failed':
        return STATUS_CLASS_MAPPING.failed;
      default:
        return 'default-class-name';
    }
  }
  return {
    STATUS_CLASS_MAPPING,
    getStatusClassName,
  };
};

export default useStatusModalHook;
