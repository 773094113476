export default (theme) => ({
  root: {
    borderRadius: '10px',
    backgroundColor: 'white',
    width: '100%',
    padding: 20,
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
    marginBottom: 30,
    cursor: 'pointer',
    position: 'relative',
    textTransform: 'none',
    textAlign: 'left',
  },
  bookmarkIcon: {
    position: 'absolute',
    top: 0,
    left: 8,
    height: '100%',
    pointer: 'cursor',
    '& > svg': {
      width: 40,
      height: 40,
    },
  },
  addBookmarkIcon: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  removeBookmarkIcon: {
    fill: theme.palette.primary.main,
  },
  careerContainer: {
    paddingLeft: 40,
  },
  careerTitle: {
    ...theme.typography.t24,
    '-webkit-line-clamp': 3,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-word',
    fontWeight: 700,
    '& svg': {
      '&:first-child': {
        marginLeft: 10,
      },
      marginRight: 5,
    },
  },
  careerFitText: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  careerSubtitle: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
    '-webkit-line-clamp': 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    workBreak: 'break-word',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  zoneBadge: {
    padding: '5px 10px',
    marginRight: 30,
    borderRadius: 5,
    whiteSpace: 'nowrap',
    height: 'fit-content',
  },
  zoneColor1: {
    backgroundColor: theme.palette.jobZone.colors.zone1,
  },
  zoneColor2: {
    backgroundColor: theme.palette.jobZone.colors.zone2,
  },
  zoneColor3: {
    backgroundColor: theme.palette.jobZone.colors.zone3,
  },
  zoneColor4: {
    backgroundColor: theme.palette.jobZone.colors.zone4,
  },
  zoneColor5: {
    backgroundColor: theme.palette.jobZone.colors.zone5,
  },
  careerZone: {
    color: 'white',
    fontWeight: 500,
  },
  medianPayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    marginTop: 5,
  },
  medianPayText: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  medianPayAmount: {
    marginLeft: 5,
    fontWeight: 700,
    color: theme.palette.cerulean.cerulean20,
  },
});
