export default (theme) => ({
  chip: {
    textTransform: 'capitalize',
    padding: '4px 12px',
    margin: 'auto',
    fontWeight: 700,
    border: '1px solid',
    height: 36,
    borderRadius: 10,
    fontSize: 16,
    lineHeight: '19.36px',
  },
  inProgress: {
    backgroundColor: theme.palette.yellow.pale,
    color: theme.palette.yellow.deep,
    fill: theme.palette.primary.active,
    borderColor: theme.palette.primary.active,
  },
  completed: {
    backgroundColor: theme.palette.green.pale,
    color: theme.palette.green.deep,
    fill: theme.palette.green.main,
    borderColor: theme.palette.green.deep,
  },
});
