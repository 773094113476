import React from 'react';
import { withStyles, Box, Select, MenuItem, FormControl } from '@material-ui/core';
import styles from './programStatusSelect.style';
import { FormattedMessage, useIntl } from 'react-intl';
import useProgramStatusHook from './useProgramStatusHook';

const ProgramStatusSelect = (props) => {
  const { classes } = props;
  const { progressState, progressStateOptions, handleProgressStateChange } = useProgramStatusHook(props);
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <FormControl fullWidth variant="outlined">
        <Select
          value={progressState}
          onChange={handleProgressStateChange}
          className={
            progressState === 'COMPLETED'
              ? classes.completedSelect
              : progressState === 'IN_PROGRESS'
              ? classes.inProgressSelect
              : ''
          }
          classes={{
            outlined:
              progressState === 'COMPLETED'
                ? classes.completedOutline
                : progressState === 'IN_PROGRESS'
                ? classes.inProgressOutline
                : '',
            iconOutlined:
              progressState === 'COMPLETED'
                ? classes.completedOutline
                : progressState === 'IN_PROGRESS'
                ? classes.inProgressOutline
                : '',
          }}
          inputProps={{ 'aria-label': intl.formatMessage({ id: 'selectProgressState' }) }}
        >
          {progressStateOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.value === '-1'}>
              <FormattedMessage id={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default withStyles(styles)(ProgramStatusSelect);
