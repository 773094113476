export default (theme) => ({
  root: {
    marginBottom: 20,
  },
  infoNotification: {
    borderRadius: 12,
    borderLeft: `12px solid ${theme.palette.blue.main} !important`,
    border: `1px solid ${theme.palette.colors.cornflowerBlue}`,
    color: theme.palette.primary.black,
    backgroundColor: theme.palette.colors.aliceBlue,
  },
  infoIcon: {
    color: theme.palette.cerulean.main,
  },
  dismissButton: {
    color: theme.palette.cerulean.main,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
});
