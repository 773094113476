import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './empty-inbox.style';
import { FormattedMessage } from 'react-intl';
import useEmptyInboxHook from './useEmptyInboxHook';

const EmptyInbox = (props) => {
  const { getEmptyInboxData } = useEmptyInboxHook(props);
  const { imageUrl, title, subtitle, description } = getEmptyInboxData();
  const { classes } = props;

  return (
    <Box className={classes.root}>
      {imageUrl && <img id="empty-inbox-img" alt="" src={imageUrl} className={classes.image} />}
      {title && (
        <Typography id="empty-inbox-title" variant="h3" className={classes.title}>
          <FormattedMessage id={title} />
        </Typography>
      )}
      {subtitle && (
        <Typography id="empty-inbox-subtitle" variant="h4" className={classes.subtitle}>
          <FormattedMessage id={subtitle} />
        </Typography>
      )}
      {description && (
        <Typography id="empty-inbox-description" className={classes.description}>
          <FormattedMessage id={description} />
        </Typography>
      )}
    </Box>
  );
};

export default withStyles(styles)(EmptyInbox);
