import { useState, useRef } from 'react';

const useVoiceAuthenticationBannerHook = () => {
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const callCenterSchedullingModalRef = useRef(null);

  const openModal = () => {
    callCenterSchedullingModalRef.current.toggleModal();
  };

  const closeModal = () => {
    callCenterSchedullingModalRef.current.toggleModal();
  };

  const handleStatusModalOpen = () => {
    setStatusModalOpen(true);
  };
  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  return {
    statusModalOpen,
    handleStatusModalOpen,
    handleStatusModalClose,
    setStatusModalOpen,
    openModal,
    closeModal,
    callCenterSchedullingModalRef,
  };
};
export default useVoiceAuthenticationBannerHook;
