import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './program-details.style';
import { BackButton } from '../../../../shared/components';
import useProgramDetailsHook from './useProgramDetailsHook';
import { ConfirmUnenrollDialog, EnrollmentToggle, MultiLaunchModules, SingleLaunchModules } from '../../components';
import { FormattedMessage } from 'react-intl';

const ProgramDetails = (props) => {
  const { classes } = props;
  const {
    programId,
    dataLoading,
    details,
    modules,
    rootModule,
    openDialog,
    goBack,
    handleEnroll,
    showConfirmationDailog,
    closeConfirmationDialog,
    onConfirmUnenroll,
    isSingleLaunchProgram,
  } = useProgramDetailsHook(props);

  return (
    <Box className={classes.root}>
      <ConfirmUnenrollDialog
        programId={programId}
        open={openDialog}
        onClose={closeConfirmationDialog}
        onConfirm={onConfirmUnenroll}
      />

      <BackButton onClick={goBack} />

      {!dataLoading && (
        <>
          <Box className={classes.header}>
            <Box className={classes.enrollHeaderMask}>
              <img src={details.thumbnailUri} alt="image not loaded" className={classes.enrollImage} />
            </Box>
            <Box className={classes.headingWrapper}>
              <Typography id="_program-details-title" color="secondary" variant="h4" className={classes.boldText}>
                {details.name}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.detailsContainer}>
            <Box>
              <Typography id="_program-details-description" className={classes.description}>
                {details.description}
              </Typography>
            </Box>

            {modules.length > 0 && (
              <EnrollmentToggle
                programId={programId}
                status={details.enrollmentStatus}
                onEnroll={handleEnroll}
                onUnenroll={showConfirmationDailog}
              />
            )}
          </Box>

          {isSingleLaunchProgram() ? (
            <SingleLaunchModules
              modules={modules}
              rootModule={rootModule}
              enrollmentStatus={details.enrollmentStatus}
              templateType={details.templateType}
            />
          ) : (
            <MultiLaunchModules modules={modules} enrollmentStatus={details.enrollmentStatus} />
          )}
          <Box className={classes.enrollFooterMask}>
            <img src="/assets/img/enroll_footer_mask.png" alt="enroll_footer_mask" />
            <Box className={classes.enrollText}>
              <Typography variant="h6">
                <FormattedMessage id="programEnrollFotter" />
              </Typography>
              {modules.length > 0 && (
                <EnrollmentToggle
                  programId={programId}
                  status={details.enrollmentStatus}
                  onEnroll={handleEnroll}
                  onUnenroll={showConfirmationDailog}
                  footerBtn="footer"
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withStyles(styles)(ProgramDetails);
