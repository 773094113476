export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '0px 16px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingTop: 32,
  },
  loading: {
    color: theme.palette.greyScale[90],
  },
  error: {
    color: theme.palette.greyScale[110],
  },
  resumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 12,
    backgroundColor: theme.palette.greyScale.charcoal,
    maxWidth: 660,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    gap: 12,
    color: theme.palette.primary.white,
  },
  pdfContainer: {
    padding: '12px 24px',
  },
  pdf: {
    '& .react-pdf__Page__svg, & svg': {
      width: '100% !important',
      height: 'auto !important',
    },
  },
  pdfDocument: {
    '& .react-pdf__message': {
      color: theme.palette.primary.white,
    },
  },
  bold: {
    fontWeight: 700,
  },
  selectedPage: {
    padding: '0px 7px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  pageInfo: {
    display: 'flex',
    gap: 4,
  },
  pageControls: {
    color: theme.palette.primary.white,
    '&:disabled': {
      color: theme.palette.primary.white,
    },
  },
});
