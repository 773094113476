import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './confirm-unenroll-dialog.style';
import { Constant } from '../../../../../shared/services';
import { PillButton } from '../../../../../frontend-commons';

const ConfirmUnenrollDialog = (props) => {
  const { classes, open, programId, onClose, onConfirm } = props;
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      <DialogContent className={classes.dialogContent}>
        <Typography id="confirm-unenroll-title" color="secondary" variant="h5" className={classes.title} gutterBottom>
          <FormattedMessage id="confirmUnenrollMsg" />
        </Typography>
        <Box marginTop="36px">
          <PillButton
            id="confirm-unenroll-button"
            variant="contained"
            color="primary"
            size="large"
            className={classes.confirmBtn}
            onClick={onConfirm}
            tracking-type={Constant.TRACKING_TYPES.PROGRAM}
            tracking-id={`unenroll-program-${programId}`}
            aria-label={intl.formatMessage({ id: 'unenrollMe' })}
          >
            <FormattedMessage id="unenrollMe" />
          </PillButton>
          <PillButton
            id="cancel-unenroll-button"
            variant="outlined"
            color="secondary"
            size="large"
            onClick={onClose}
            tracking-type={Constant.TRACKING_TYPES.PROGRAM}
            tracking-id={`cancel-unenroll-program-${programId}`}
            aria-label={intl.formatMessage({ id: 'cancel' })}
          >
            <FormattedMessage id="cancel" />
          </PillButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ConfirmUnenrollDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmUnenrollDialog);
