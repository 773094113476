import React from 'react';

const EyeOpenIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-Open_Eye">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Open_Eye" data-name="Open Eye" clipPath="url(#clip-Open_Eye)">
        <path
          id="eye-regular"
          d="M6.667,40.947A5.226,5.226,0,0,1,12,35.834,5.117,5.117,0,1,1,12,46.06,5.226,5.226,0,0,1,6.667,40.947ZM12,44.142a3.2,3.2,0,1,0,0-6.391H11.88a1.533,1.533,0,0,1,.121.639,2.616,2.616,0,0,1-2.667,2.556,1.724,1.724,0,0,1-.667-.116v.08A3.272,3.272,0,0,0,12,44.142ZM3.976,35.219A11.983,11.983,0,0,1,12,32a11.987,11.987,0,0,1,8.025,3.219A15.485,15.485,0,0,1,23.9,40.456a1.222,1.222,0,0,1,0,.983,16.2,16.2,0,0,1-3.871,5.236A11.979,11.979,0,0,1,12,49.894a11.975,11.975,0,0,1-8.025-3.219A16.268,16.268,0,0,1,.1,41.438a1.228,1.228,0,0,1,0-.983,15.542,15.542,0,0,1,3.873-5.236ZM12,33.917a9.963,9.963,0,0,0-6.663,2.7A13.814,13.814,0,0,0,2.06,40.947a14.229,14.229,0,0,0,3.278,4.326A9.963,9.963,0,0,0,12,47.977a9.963,9.963,0,0,0,6.663-2.7,14.9,14.9,0,0,0,3.279-4.326,14.442,14.442,0,0,0-3.279-4.326A9.963,9.963,0,0,0,12,33.917Z"
          transform="translate(0 -28.947)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

EyeOpenIcon.defaultProps = {
  size: 25,
  fill: 'inherit',
};

export default EyeOpenIcon;
