import * as store from "./";

const initialState = {
  status: []
};

export const playReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.UDPATE_PLAY_STATUS:
      return {
        ...state,
        status: action.status
      };
    default:
      return state;
  }
};
