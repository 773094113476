import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CardMedia, Typography, withStyles } from '@material-ui/core';
import styles from './career-video.style';
import useCareerVideoHook from './useCareerVideoHook';
import { Constant } from '../../../../../shared';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { FormattedMessage, useIntl } from 'react-intl';

const CareerVideo = ({ classes, careerId, handleClick }) => {
  const { showVideo, videoUrl, thumbnailUrl, handleImageError } = useCareerVideoHook({ careerId });
  const intl = useIntl();

  if (!showVideo) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Button
          id="career-video-play-button"
          onClick={() => {
            handleClick(videoUrl);
          }}
          classes={{ label: classes.playButtonLabel }}
          className={classes.playButton}
          tracking-type={Constant.TRACKING_TYPES.CAREER_DIRECTORY}
          tracking-id={`open-career-${careerId}-video`}
          aria-label={intl.formatMessage({ id: 'playVideo' })}
        >
          <CardMedia component="img" alt="" className={classes.thumbnail} image={thumbnailUrl} onError={handleImageError} />
          <Box className={classes.iconContainer}>
            <PlayCircleFilledIcon className={classes.playIcon} />
          </Box>
        </Button>
      </Box>
      <Box className={classes.content}>
        <Typography id="career-video-title" variant="h5" className={classes.title}>
          <FormattedMessage id="learnMore" />
        </Typography>
        <Typography id="career-video-description" variant="body1" className={classes.description}>
          <FormattedMessage id="career-video.description" />
        </Typography>
      </Box>
    </Box>
  );
};

CareerVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  careerId: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(CareerVideo);
