import React from "react";

const BlockIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 92 93">
      <defs>
        <clipPath id="clip-Block">
          <rect width="92" height="93"/>
        </clipPath>
      </defs>
      <g id="Block" clipPath="url(#clip-Block)">
        <g id="octagon-xmark-duotone" transform="translate(-0.066 -0.063)">
          <path id="Path_92718" data-name="Path 92718" d="M183.751,177.791l8.651-8.507a4.175,4.175,0,0,1,5.978,0,3.923,3.923,0,0,1,0,5.978l-8.344,8.489,8.344,8.651a4.227,4.227,0,1,1-5.978,5.978l-8.651-8.344-8.489,8.344a3.923,3.923,0,0,1-5.978,0,4.175,4.175,0,0,1,0-5.978l8.507-8.651-8.507-8.489a4.227,4.227,0,0,1,5.978-5.978Z" transform="translate(-137.624 -137.627)" fill={fill}/>
          <path id="Path_92719" data-name="Path 92719" d="M25.448,3.871A13,13,0,0,1,34.641.063h23.3a13,13,0,0,1,9.193,3.809L88.7,25.445a13,13,0,0,1,3.811,9.193v23.3A13,13,0,0,1,88.7,67.129L67.132,88.694A13,13,0,0,1,57.939,92.5h-23.3a13,13,0,0,1-9.193-3.811L3.874,67.129A13,13,0,0,1,.067,57.936v-23.3a13,13,0,0,1,3.808-9.193Zm6.213,33.765,8.507,8.489-8.507,8.651a4.175,4.175,0,0,0,0,5.978,3.923,3.923,0,0,0,5.978,0l8.651-8.344,8.489,8.344a4.227,4.227,0,1,0,5.978-5.978l-8.344-8.651,8.344-8.489a3.923,3.923,0,0,0,0-5.978,4.175,4.175,0,0,0-5.978,0L46.29,40.164l-8.651-8.507a4.227,4.227,0,1,0-5.978,5.978Z" fill={fill} opacity="0.4"/>
        </g>
      </g>
    </svg>
  );
};

BlockIcon.defaultProps = {
  size: 24,
  fill: "#0f445a"
}

export default BlockIcon;
