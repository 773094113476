import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, CardContent, CardMedia, Grid, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import styles from './program.style';
import { Constant } from '../../../../shared/services/constant/constant';
import { LinkButton } from '@orijinworks/frontend-commons';

const Program = (props) => {
  const {
    classes,
    programId,
    title,
    description,
    image,
    altText,
    buttonTitle,
    enrolled,
    onClick,
    providerLogo,
    providerLogoAltText,
  } = props;
  return (
    <LinkButton
      id={`program-${programId}-container`}
      onClick={onClick}
      className={[classes.root, classes.progCard].join(' ')}
      tracking-type={Constant.TRACKING_TYPES.PROGRAM}
      tracking-id={`view-program-details-${programId}`}
      aria-label={`View ${title}`}
      variant="text"
    >
      <Grid item sm={4} md={4} className={classes.thumbnailContainer}>
        {providerLogo && (
          <Box className={classes.providerLogoContainer}>
            <CardMedia
              id={`program-${programId}-provider-logo`}
              component="img"
              alt={providerLogoAltText}
              className={classes.providerLogo}
              image={providerLogo}
            />
          </Box>
        )}
        <CardMedia
          id={`program-${programId}-thumbnail`}
          component="img"
          alt=""
          className={classes.thumbnail}
          image={image}
          title={altText || title}
        />
      </Grid>
      <Grid item sm={8} md={8} className={classes.content}>
        <CardContent className={classes.cardContent}>
          <Typography id={`program-${programId}`} gutterBottom className={classes.cardTitle}>
            {title}
          </Typography>
          <Typography id="program-desc" className={classes.description} variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
        <Box className={classes.actionBox}>
          <Typography
            data-cy-name="view-program-btn"
            className={[classes.actionBtn, classes.viewProgBtn].join(' ')}
            id={`program-${programId}-view-program-btn`}
          >
            {buttonTitle}
          </Typography>
        </Box>
      </Grid>
      {enrolled && (
        <Box className={classes.banner} id={`program-${programId}-enrolled-program-badge`}>
          <Icon>task_alt</Icon>
          <Typography component="p" className={classes.bannerText}>
            <FormattedMessage id="enrolled" />
          </Typography>
        </Box>
      )}
    </LinkButton>
  );
};

Program.propTypes = {
  programId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
  buttonTitle: PropTypes.element.isRequired,
  enrolled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  providerLogo: PropTypes.string,
  providerLogoAltText: PropTypes.string,
};

export default withStyles(styles)(Program);
