import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import styles from './group-schedule-banner.style';
import { FormattedMessage } from 'react-intl';
const GroupScheduleBanner = ({ classes }) => {
  return (
    <Box className={classes.main}>
      <Box className={classes.headingSection}>
        <NotInterestedIcon className={classes.notIntrestedIcon} />
        <Typography variant="h4" className={classes.heading}>
          <FormattedMessage id="groupScheduleBanner.header" />
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.bannerMessage}>
        <FormattedMessage id="groupScheduleBanner.description" />
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(GroupScheduleBanner);
