import { Rest, UserService } from '../../../../shared/services';

const useLearningModuleHook = () => {
  const _userService = new UserService();

  const launchModule = async (id, name) => {
    if (!id) return;

    _userService.registerAnalyticEvent(UserService.GAEvents.Category.Pathways, 'Clicked', null, name);

    const url = await new Rest().getLaunchUrl(id);
    window.open(url.data, '_blank');

    await new Rest().userInteraction({
      resourceId: id,
      lastActivityDate: Date.now(),
      source: 'program-details',
      courseResourceId: id,
    });
  };

  return {
    launchModule,
  };
};

export default useLearningModuleHook;
