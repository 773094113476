import React from "react";

import { PlayMainService } from "../../../content/play/services";

class TimeRemaining extends React.Component {
  intervalID = null;
  playMainService = new PlayMainService();
  state = {
    formattedTime: null
  };

  componentDidMount() {
    this.intervalID = setInterval(() => {
      const formattedTime = this.playMainService.getScheduleTimeRemaining(
        this.props.schedule,
        this.playMainService.TIME_REMAINING
      );
      this.setState({
        formattedTime
      });
      if (!formattedTime) {
        this.props.updatePlayStatus(
          this.playMainService.NOT_IN_SCHEDULE,
          this.props.tabName
        );
        clearInterval(this.intervalID);
      }
    }, 1000);
  }

  render() {
    return <>{this.state.formattedTime}</>;
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
}

export default TimeRemaining;
