export default (theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '12px 0px',
    },
  },
  similarJobItem: {
    color: theme.palette.aqua.extraDeep,
    textTransform: 'capitalize',
    fontWeight: 700,
    textAlign: 'left',
  },
  attributionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
  },
});
