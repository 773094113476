import { Box, Button, Dialog, Grid, Typography, withStyles } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { connect } from 'react-redux';

import { PlayMainService } from '../../../services';
import styles from './redeem.style';

const Redeem = (props) => {
  const playMainService = new PlayMainService();
  const intl = useIntl();
  const { classes, tabName } = props;
  return (
    <Dialog open={props.isDialogOpen} onClose={() => props.setDialogVisiblity(false)}>
      <Grid data-cy-name="redeem-dialog" container item className={classes.mainDialog}>
        {/* BEGIN: TITLE */}
        <Grid container item direction="column" justify="center" alignItems="center">
          <PlayCircleOutlineIcon className={classes.playIcon} />
          <Typography id="heading" className={classes.dialogTitle}>
            <FormattedMessage
              id="redeemBtn"
              values={{
                tabName: tabName,
              }}
            />
          </Typography>
        </Grid>
        {/* END: TITLE */}
        {/* BEGIN: BODY TEXT */}
        <Grid container item direction="column" justify="center" alignItems="center">
          <Typography className={classes.bodyText}>
            <FormattedMessage
              id="redeemDialogDesc"
              values={{
                tabName: tabName.toLowerCase(),
              }}
            />
          </Typography>
        </Grid>
        {/* END: BODY TEXT */}
        {/* BEGIN: CART */}
        <Grid container item className={classes.cartWrapper}>
          <Grid container item className={classes.keyValuePairText} justify="space-between" alignItems="center">
            <Typography className={classes.darkText}>
              <FormattedMessage id="tokenAvailable" />
            </Typography>
            <Typography id="balance" className={classes.darkTextValue}>
              {props.balance}
            </Typography>
          </Grid>

          <Grid container item className={classes.keyValuePairText} justify="space-between" alignItems="center">
            <Box component="div">
              <Typography className={classes.darkText}>{tabName}</Typography>
              {props.schedule && (
                <Typography id="available-date" className={classes.darkTextSubValue}>
                  <FormattedMessage id="availableUntil" />
                  &nbsp;
                  <Moment format="hh:mmA">
                    {playMainService.getScheduleTimeRemaining(props.schedule, playMainService.MAX_SCHEDULE)}
                  </Moment>
                </Typography>
              )}
            </Box>
            <Typography className={classes.darkTextValue}>{props.price}</Typography>
          </Grid>
        </Grid>
        {/* END: CART */}
        {/* BEGIN: TOTAL */}
        <Grid container item className={classes.totalWrapper}>
          <Grid container item className={classes.keyValuePairText} justify="space-between" alignItems="center">
            <Typography className={classes.darkText}>
              <FormattedMessage id="tokenRemaining" />
            </Typography>
            <Box component="div" alignItems="center" display="flex">
              <StarsIcon className={classes.tokenIcon} />
              <Typography id="balance-remaining" className={classes.tokenValue}>
                {props.balance - props.price}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* END: TOTAL */}
        {/* BEGIN: FOOTER */}
        <Grid container item className={classes.dialogFooter} justify="flex-end">
          <Button
            color="primary"
            data-cy-name="redeem-dialog-cancel"
            onClick={() => {
              props.setDialogVisiblity(false);
            }}
            className={classes.confirmBtn}
            aria-label={intl.formatMessage({ id: 'cancel' })}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            disabled={props.balance - props.price < 0}
            color="primary"
            data-cy-name="redeem-dialog-ok"
            onClick={() => {
              playMainService.redeemFeature(
                tabName,
                props.balance - props.price,
                props.setDialogVisiblity,
                props.updateRedeemState
              );
            }}
            className={classes.confirmBtn}
            aria-label={intl.formatMessage({ id: 'ok' })}
          >
            <FormattedMessage id="ok" />
          </Button>
        </Grid>
        {/* END: FOOTER */}
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = ({ app }) => {
  return app;
};
export default withStyles(styles)(connect(mapStateToProps)(Redeem));
