import React from 'react';
import useTranscriptHook from './useTranscriptHook';
import TranscriptV1 from './transcript-v1';
import { connect } from 'react-redux';
import { useDocumentTitleHook } from '../../../../v2/shared';
import { Loader, Transcript as TranscriptV2 } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';

const Transcript = (props) => {
  const { transcriptData, isLoading, toggleVersion } = useTranscriptHook(props);
  const { userDetail } = props;
  useDocumentTitleHook('page.title.transcript');
  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <TranscriptV2
          transcriptEmptyState={{
            title: 'No Data Available Yet',
            description:
              'Your report is currently empty. As you begin completing content, your progress will be recorded here. It may take up to 24 hours for your report to reflect the latest updates.',
          }}
          literals={{
            v1Title: 'New version Available',
            v1Description:
              "Switch the toggle to view the new version with an updated look and functionality. You can switch back if you'd like later.",
            v2Title: 'Switch to the Old Version',
            v2Description: 'Go back to the previous version of the report any time.',
            transcriptTitle: <FormattedMessage id="page.title.transcript" />,
            learnerDocId: 'Learner Doc Id',
            totalHours: 'Total Hours',
            noData: 'No Data',
            lastUpdated: 'Last Updated',
            footNote:
              '*Total hours is an approximation of time spent on programming based on data available to us from our partner sites.',
            transcriptContentMainTitle: 'Program And Certificates',
            additionalEducation: 'Additional Education',
            programsPlusClasses: 'Programs + Classes',
            percentComplete: '% Complete',
            totalStudyTime: 'Total Study Time',
            mostRecentActivity: 'Recent Activity',
            showMore: 'Show More',
            aboutName: `About ${userDetail?.userName}`,
          }}
          transcriptData={transcriptData}
          TranscriptV1={() => <TranscriptV1 {...props} />}
          onToggleVersionChange={toggleVersion}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ locale }) => ({
  selectedLocale: locale.locale,
});

export default connect(mapStateToProps)(Transcript);
