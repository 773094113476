import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './learning-module.styles';
import { FormattedMessage } from 'react-intl';
import { PillButton } from '../../../../../frontend-commons';
import LaunchIcon from '@material-ui/icons/Launch';
import useLearningModuleHook from '../../../programs/components/learning-module/useLearningModuleHook';
import { Constant } from '../../../../../shared';

/**
 * Component that renders the Learning Module
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const LearningModule = ({ classes, disabled, moduleId, title, contentProgress }) => {
  const { launchModule } = useLearningModuleHook({});

  return (
    <Box className={[classes.root, disabled ? classes.disabledModule : classes.activeModule].join(' ')}>
      <Box className={classes.textArea}>
        <Typography data-testid="learning-module-title" className={classes.title}>
          {title}
        </Typography>
        {contentProgress && (
          <Typography data-testid="learning-module-subtitle" variant="body2" className={classes.subtitle}>
            <FormattedMessage
              id="topicsCompleted"
              values={{ completed: contentProgress.completed ?? 0, total: contentProgress.total ?? 0 }}
            />
          </Typography>
        )}
      </Box>
      {!disabled && (
        <PillButton
          data-testid="launch-button"
          color="primary"
          variant="contained"
          className={classes.launchButton}
          onClick={() => {
            launchModule(moduleId, title);
          }}
          endIcon={<LaunchIcon />}
          aria-label="Launch"
          tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
          tracking-id={`launch-program-unit-${moduleId}`}
        >
          <FormattedMessage id="launch" />
        </PillButton>
      )}
    </Box>
  );
};

LearningModule.defaultProps = {
  disabled: false,
};

LearningModule.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The prop that determines if the module is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The id of the module.
   */
  moduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * The title of the module.
   */
  title: PropTypes.string.isRequired,
  /**
   * The topics of the module.
   */
  contentProgress: PropTypes.shape({
    completed: PropTypes.number,
    total: PropTypes.number,
  }),
};

export default withStyles(styles)(LearningModule);
