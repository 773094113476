import { Constant } from '../../../../../shared/services';

export const TERMS_OF_SERVICE_VERSION = 'V1.0';
export const PRIVACY_POLICY_VERSION = 'V1.0';

/**
 * @name SCRIPTS_URLS
 * @type {string[]}
 * @description Scripts URLs to be loaded for the terms of service and privacy policy.
 */
const SCRIPTS_URLS = [
  `${Constant.CDN_BASE_URL}/assets/theme/learner/english/TERMS_OF_USE_${TERMS_OF_SERVICE_VERSION}.js`,
  `${Constant.CDN_BASE_URL}/assets/theme/learner/spanish/TERMS_OF_USE_${TERMS_OF_SERVICE_VERSION}.js`,
  `${Constant.CDN_BASE_URL}/assets/theme/learner/english/PRIVACY_POLICY_${PRIVACY_POLICY_VERSION}.js`,
  `${Constant.CDN_BASE_URL}/assets/theme/learner/spanish/PRIVACY_POLICY_${PRIVACY_POLICY_VERSION}.js`,
];

/**
 * @name TERMS_OF_SERVICE_TRANSLATIONS
 * @type {Object}
 * @description Translations for the terms of service modal.
 */
export const TERMS_OF_SERVICE_TRANSLATIONS = {
  [Constant.LANGUAGES.ENGLISH.code]: {
    modalTitle: () => window.TERMS_OF_USE_MODAL_HEADING,
    title: () => window.TERMS_OF_USE_HEADING,
    effectiveDate: () => window.TERMS_OF_USE_EFFECTIVE_DATE,
    content: () => window.TERMS_OF_USE,
  },
  [Constant.LANGUAGES.SPANISH.code]: {
    modalTitle: () => window.TERMS_OF_USE_MODAL_HEADING_ES,
    title: () => window.TERMS_OF_USE_HEADING_ES,
    effectiveDate: () => window.TERMS_OF_USE_EFFECTIVE_DATE_ES,
    content: () => window.TERMS_OF_USE_ES,
  },
};

/**
 * @name PRIVACY_POLICY_TRANSLATIONS
 * @type {Object}
 * @description Translations for the privacy policy modal.
 */
export const PRIVACY_POLICY_TRANSLATIONS = {
  [Constant.LANGUAGES.ENGLISH.code]: {
    modalTitle: () => window.PRIVACY_POLICY_MODAL_HEADING,
    title: () => window.PRIVACY_POLICY_HEADING,
    effectiveDate: () => window.PRIVACY_POLICY_EFFECTIVE_DATE,
    content: () => window.PRIVACY_POLICY,
  },
  [Constant.LANGUAGES.SPANISH.code]: {
    modalTitle: () => window.PRIVACY_POLICY_MODAL_HEADING_ES,
    title: () => window.PRIVACY_POLICY_HEADING_ES,
    effectiveDate: () => window.PRIVACY_POLICY_EFFECTIVE_DATE_ES,
    content: () => window.PRIVACY_POLICY_ES,
  },
};

/**
 * Function to load the terms of service scripts.
 *
 * @returns {Promise[]} The promises for the scripts loaded.
 */
export const loadTermsOfServiceScripts = () => {
  const scriptPromises = SCRIPTS_URLS.map((scriptUrl) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  });

  return Promise.all(scriptPromises);
};

/**
 * Function to clean up the scripts.
 */
export const cleanUpScripts = () => {
  SCRIPTS_URLS.forEach((scriptUrl) => {
    const script = document.querySelector(`script[src="${scriptUrl}"]`);
    if (script) {
      document.body.removeChild(script);
    }
  });
};

/**
 * Function to add event listeners to the privacy policy links.
 *
 * @param {Function} handler - The event handler function.
 */
export const addPrivacyPolicyEventListeners = (handler) => {
  const elements = document.querySelectorAll('.privacy-policy-link');
  elements.forEach((element) => element.addEventListener('click', handler));
};

/**
 * Function to remove event listeners from the privacy policy links.
 *
 * @param {Function} handler - The event handler function.
 */
export const removePrivacyPolicyEventListeners = (handler) => {
  const elements = document.querySelectorAll('.privacy-policy-link');
  elements.forEach((element) => element.removeEventListener('click', handler));
};
