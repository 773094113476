import { Box, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import styles from './pdq-results-prose.style';

const PDQResultsProse = (props) => {
  const { classes } = props;
  const level = props.pdqScores ? props.pdqScores.proseScore : 1;
  return (
    <>
      <Typography id="pdq-assessment-p-title" className={classes.title} gutterBottom>
        <FormattedMessage id="pdq-assessment.p" />
      </Typography>
      <Typography id="pdq-assessment-p-description" className={classes.description}>
        <FormattedMessage id="pdq-results.p.description" />
      </Typography>

      <Typography id="pdq-assessment-p-intro" className={[classes.description, classes.paddingTop30].join(' ')} gutterBottom>
        <FormattedMessage
          id="pdq-results.p.intro"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            span: (...chunks) => <span className={classes.textPrimary}>{chunks}</span>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            br: <br />,
            level: level,
          }}
        />
      </Typography>

      <Typography
        id="pdq-assessment-p-current-skills-title"
        className={[classes.subtitle, classes.paddingTop20].join(' ')}
        gutterBottom
      >
        <FormattedMessage id="currrent-skills" />
      </Typography>
      <Typography id="pdq-assessment-p-current-skills-text" className={classes.description}>
        <FormattedMessage
          id={`pdq-results.p.${level}.current-skills`}
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            br: <br />,
          }}
        />
      </Typography>

      <Typography
        id="pdq-assessment-p-skills-to-strengthen-title"
        className={[classes.subtitle, classes.paddingTop20].join(' ')}
        gutterBottom
      >
        <FormattedMessage id="skills-to-strengthen" />
      </Typography>
      <Typography id="pdq-assessment-p-skills-to-strengthen-text" className={classes.description}>
        <FormattedMessage
          id={`pdq-results.p.${level}.skills-to-strengthen`}
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            br: <br />,
          }}
        />
      </Typography>

      <Typography
        id="pdq-assessment-p-skills-to-build-in-future-title"
        className={[classes.subtitle, classes.paddingTop20].join(' ')}
        gutterBottom
      >
        <FormattedMessage id="skills-to-build-in-future" />
      </Typography>
      <Typography id="pdq-assessment-p-skills-to-build-in-future-text" className={classes.description}>
        <FormattedMessage
          id={`pdq-results.p.${level}.skills-to-build-in-future`}
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            br: <br />,
          }}
        />
      </Typography>
    </>
  );
};

const mapStateToProps = ({ pdqAssessment }) => {
  return {
    pdqScores: pdqAssessment.pdqScores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PDQResultsProse));
