export default (theme) => ({
  root: {
    margin: '0px -35px',
  },
  stickToTop: {
    display: 'block',
    position: 'fixed',
    top: '80px',
    zIndex: 9,
    backgroundColor: theme.palette.primary.background,
    width: '100%',
  },
});
