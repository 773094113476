export default (theme) => ({
  contentArea: {
    margin: '20px 20px 10px',
    width: '100%',
    position: 'relative',
  },
  card: {
    borderRadius: 5,
    padding: 15,
    backgroundColor: theme.palette.primary.background,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
  cardLayout: {
    display: 'flex',
    alignItems: 'center',
  },
  thumbnailImageWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    minWidth: 50,
    maxWidth: 50,
    minHeight: 50,
    maxHeight: 50,
    borderRadius: 50,
    '& > svg': {
      fontSize: '60px !important',
    },
  },
  boxTitle: {
    fontWeight: 700,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
  },
  boxDescription: {
    ...theme.typography.t12,
    color: theme.palette.text.sub,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
  },
  actionBtn: {
    ...theme.typography.t18,
    ...theme.typography.bold,
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    borderRadius: 28,
  },
  actionBox: {
    marginLeft: 70,
  },
});
