import React, { Component, Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Collapse, withStyles, CircularProgress, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FriendsFamilyMessaging } from './../../services/';
import style from './message-sidebar.styles';

const ContactItem = React.lazy(() => import('../contact-item'));

class Sidebar extends Component {
  state = {
    activeThread: null,
    expandToggle: true,
    contactsOrderBy: 'recent_message',
  };
  threadList = [
    {
      name: 'messages',
      value: <FormattedMessage id="directMessages" />,
      listName: 'contacts',
      menuItems: {
        recent: 'recent_message',
        name: 'contact_name',
      },
      // icon: (
      //   <svg
      //     xmlns="http://www.w3.org/2000/svg"
      //     width="20"
      //     height="16"
      //     viewBox="0 0 20 16"
      //     fill="inherit"
      //   >
      //     <path
      //       d="M12.9999 5.5C12.9999 2.4625 10.0905 0 6.49988 0C2.90925 0 -0.000125169 2.4625 -0.000125169 5.5C-0.000125169 6.73656 0.488 7.87375 1.30206 8.79375C0.789875 9.8175 0.0761249 10.585 0.0648749 10.5962C-0.00075017 10.6652 -0.0185627 10.7673 0.0198436 10.8573C0.0592499 10.9469 0.143687 11 0.23925 11C1.43769 11 2.4505 10.6525 3.23113 10.2487C4.19363 10.7219 5.30613 11 6.49988 11C10.0905 11 12.9999 8.5375 12.9999 5.5ZM18.7374 13.8656C19.5249 12.9344 19.9999 11.7688 19.9999 10.5C19.9999 7.4625 17.3124 5 13.9999 5C13.9901 5 13.9805 5.00128 13.9707 5.00134C13.9843 5.16563 13.9999 5.33125 13.9999 5.5C13.9999 8.58187 11.5099 11.1625 8.18426 11.8281C8.83426 14.2219 11.1593 16 13.9999 16C15.0527 16 16.0411 15.7498 16.9014 15.3131C17.6624 15.6875 18.628 16 19.7593 16C19.8549 16 19.9393 15.9461 19.9786 15.8561C20.017 15.7661 19.9992 15.664 19.9336 15.595C19.9249 15.5844 19.2468 14.8531 18.7374 13.8656Z"
      //       fill="inherit"
      //     />
      //   </svg>
      // )
    },
  ];
  _service = new FriendsFamilyMessaging();

  async componentDidMount() {
    this.getThreads();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  getThreads = async (noLoader) => {
    const contacts = await this._service.fetchContacts(this.props.learnerId);
    if (contacts.length > 0) {
      this.props.onSelect(contacts[0]);
    }
    this.props.setContacts(contacts);
  };

  render() {
    const { classes, intl, eTag, onSelect, selectedContact } = this.props;

    return (
      <div className={classes.sideBar}>
        <Suspense fallback={<CircularProgress />}>
          {this.threadList.map((thread) => {
            return (
              <Fragment key={thread.name}>
                <Typography className={classes.mainHeading} variant="h2">
                  <FormattedMessage id="contacts" />
                </Typography>
                <>
                  <Collapse in={this.state.expandToggle} timeout="auto" unmountOnExit>
                    <Button
                      fullWidth
                      onClick={this.props.onDirectMessage}
                      className={classes.sideBarItem}
                      aria-label={intl.formatMessage({ id: 'addContact' })}
                    >
                      <p className={classes.messageTag}>
                        <FormattedMessage id="addContact" />
                      </p>
                      <span id="new-msg-btn" className={classes.newMessageIcon}>
                        <AddIcon className={classes.icon} />
                      </span>
                    </Button>

                    {(this.props[thread.listName] || []).map((contact) => (
                      <ContactItem
                        key={contact.relationshipId}
                        contact={contact}
                        eTag={eTag}
                        onSelect={onSelect}
                        selected={selectedContact ? selectedContact.relationshipId === contact.relationshipId : null}
                      />
                    ))}
                  </Collapse>
                </>
              </Fragment>
            );
          })}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return {
    unreadMessagesCount: app.unreadMessagesCount,
    announcementCount: app.announcementCount,
  };
};

export default withStyles(style)(connect(mapStateToProps, null)(injectIntl(Sidebar)));
