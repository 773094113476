export default (theme) => ({
  root: {
    paddingTop: 20,
  },
  mainTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  programsContainer: {
    padding: '20px 0px',
  },
  table: {
    width: '100%',
    borderRadius: 5,
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
    borderCollapse: 'separate',
  },
  tableHeadRow: {
    borderRadius: '5px 5px 0px 0px',
    background: theme.palette.colors.lightBlueGrey,
    borderCollapse: 'separate',
  },
  tableColumnText: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  tableHeaderCell: {
    borderBottom: 'none',
  },
  evenRow: {
    background: 'white',
  },
  oddRow: {
    background: theme.palette.colors.inputBackground,
  },
  tableBodyCell: {
    borderBottom: 'none',
    borderRight: `1px solid ${theme.palette.colors.lightBlueGrey}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableRowText: {
    fontWeight: 700,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  disabledText: {
    color: theme.palette.text.sub,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },
  width80p: {
    width: '50%',
  },
  width20p: {
    width: '20%',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  launchButton: {
    '&$careerPathBtn': {
      ...theme.typography.t18,
      color: theme.palette.primary.active,
      textTransform: 'capitalize',
      fontWeight: 700,
      textDecoration: 'underline',
      marginLeft: 10,
    },
  },
  careerPathBtn: {
    // need this for the class to show up
  },
});
