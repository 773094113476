import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utility } from '../../../../shared/services';

/**
 * Wrapper component to control the visibility of a feature based on the feature flag.
 *
 * @component
 * @param {object} props - Component props
 * @returns {React.Component}
 */
const FeatureControl = ({ featureName, features, children }) => {
  const _utilityService = new Utility();
  const isValid = _utilityService.checkStatusExists(features, featureName);

  if (!isValid) {
    return null;
  }

  return children;
};

FeatureControl.propTypes = {
  /**
   * Name of the feature to be checked.
   */
  featureName: PropTypes.string.isRequired,
  /**
   * List of features available.
   */
  features: PropTypes.array.isRequired,
  /**
   * Children components to be rendered.
   */
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ app }) => ({
  features: app.features,
});

export default connect(mapStateToProps)(FeatureControl);
