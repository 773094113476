export default (theme) => ({
  root: {},
  completedSelect: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  inProgressSelect: {
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
  completedOutline: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  inProgressOutline: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
});
