export default theme => ({
  container: {
    width: "auto",
    margin: "0 -18px",
    height: "calc(100vh - 150px)"
  },
  chatContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: 8,
    paddingTop: 10
  }
});
