export default (theme) => ({
  root: {
    padding: '24px 32px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
    borderRadius: 16,
    color: theme.palette.primary.white,
    background: `linear-gradient(180deg, ${theme.palette.cyan.dark} 0%, ${theme.palette.secondary.main} 100%)`,
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  image: {
    maxWidth: 240,
  },
});
