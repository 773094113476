export default (theme) => ({
  root: {
    maxWidth: '700px',
    padding: '24px',
    alignItems: 'baseline',
    gap: '24px',
    borderRadius: '18px',
    backgroundColor: `${theme.palette.greyScale.grey10} !important`,
  },
  gap: {
    gap: '24px',
  },
  link: {
    color: theme.palette.primary.deep,
    cursor: 'pointer',
  },
  timeLimitRemaining: {
    color: theme.palette.aqua.deep,
    fontWeight: 'bold',
  },
  timeLimitNotRemaining: {
    color: theme.palette.greyScale.grey90,
    fontWeight: 'bold',
  },
  timeRemainingText: {
    textTransform: 'none !important',
  },
});
