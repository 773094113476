export default (theme) => ({
  chip: {
    marginTop: '5%',
  },
  thirtySecondsLeft: {
    backgroundColor: theme.palette.primary.red,
  },
  oneMinuteLeft: {
    backgroundColor: theme.palette.primary.lightOrange,
  },
  fiveMinutesLeft: {
    backgroundColor: theme.palette.primary.lightYellow,
  },
});
