import { useState, useEffect, useCallback } from 'react';
import {
  CAREER_COMPASS_CONSTANTS,
  getProgramDetails,
  isStepProgramProgression,
  isStepMarkedAsInProgress,
  getProgramProgress,
} from '../../services/career-compass.service';

/**
 * Custom hook for the Program Details component
 *
 * @param {integer} props.programId - The program ID
 * @param {object} props.currentStep - The current step
 * @param {string} props.selectedLocale - The selected locale
 * @param {function} props.handleCurrentStepUpdate - The function to update the current step
 * @returns {object} - The hook methods and data
 */
const useProgramDetailsHook = ({ programId, currentStep, selectedLocale, handleCurrentStepUpdate }) => {
  const [programDetails, setProgramDetails] = useState(null);
  const [showCertificate, setShowCertificate] = useState(false);
  const [programProgress, setProgramProgress] = useState(null);

  /**
   * Effect to fetch the program details.
   * It will only fetch the program details if the program ID is not null.
   */
  useEffect(() => {
    const fetchProgramDetails = async (programId) => {
      try {
        const details = await getProgramDetails(programId);
        setProgramDetails(details);

        // Get initial progress value from program details
        const initialProgress = details?.progress;
        setProgramProgress(initialProgress);

        // call getProgramProgress to get latest progress values from D2L
        const updatedProgress = await getProgramProgress(programId);
        if (updatedProgress) {
          setProgramProgress(updatedProgress);
        }
      } catch (err) {
        console.log('Error fetching program details:', err);
      }
    };

    if (programId) {
      fetchProgramDetails(programId);
    }
  }, [programId, selectedLocale]);

  /**
   * Function to check if the certificate is awarded.
   */
  const isCertificateAwarded = useCallback(() => {
    return Boolean(programDetails?.certificateUrl);
  }, [programDetails]);

  /**
   * Function to mark the current step as pending completion.
   */
  const markCurrentStepAsPendingCompletion = useCallback(() => {
    handleCurrentStepUpdate(CAREER_COMPASS_CONSTANTS.STEP_STATE.PENDING_COMPLETION);
  }, []);

  /**
   * Effect to mark the current step as pending completion if the certificate is awarded and the step is in progress.
   */
  useEffect(() => {
    if (isCertificateAwarded() && isStepMarkedAsInProgress(currentStep)) {
      markCurrentStepAsPendingCompletion();
    }
  }, [currentStep, isCertificateAwarded, markCurrentStepAsPendingCompletion]);

  /**
   * Function to check if the program has progress.
   *
   * @returns {boolean} - The program progress status
   */
  const hasProgress = () => {
    const { earnedBadgeCount, totalBadgeCount } = programProgress || {};
    const hasValidEarnedBadgeCount = Number.isInteger(earnedBadgeCount);
    const hasValidTotalBadgeCount = Number.isInteger(totalBadgeCount);

    return hasValidEarnedBadgeCount && hasValidTotalBadgeCount;
  };

  /**
   * Function to get the program progress.
   *
   * @returns {number} - The program progress percentage
   */
  const getProgress = () => {
    const { earnedBadgeCount, totalBadgeCount } = programProgress || {};

    return Math.floor((earnedBadgeCount / totalBadgeCount) * 100);
  };

  /**
   * Function to set the show certificate dialog state to true.
   */
  const showCertificateDialog = () => {
    setShowCertificate(true);
  };

  /**
   * Function to set the show certificate dialog state to false.
   */
  const closeCertificateDialog = () => {
    setShowCertificate(false);
  };

  /**
   * Function to check if the program has content.
   *
   * @returns {boolean} - The program content status
   */
  const hasContent = () => {
    return programDetails?.learningObjects?.length > 0;
  };

  /**
   * Function to check if the program content should be shown.
   *
   * @returns {boolean} - The program content status
   */
  const showProgramContent = () => {
    return !isCertificateAwarded() && hasContent();
  };

  /**
   * Function to handle the pick next program action.
   */
  const onPickNextProgram = async () => {
    handleCurrentStepUpdate(CAREER_COMPASS_CONSTANTS.STEP_STATE.COMPLETED);
  };

  /**
   * Function to check if the pick next program should be shown.
   *
   * @returns {boolean} - The pick next program status
   */
  const showPickNextProgram = () => {
    return isCertificateAwarded() && isStepProgramProgression(currentStep.nextStep);
  };

  return {
    programDetails,
    programProgress,
    showCertificate,
    hasProgress,
    getProgress,
    showCertificateDialog,
    closeCertificateDialog,
    showProgramContent,
    showPickNextProgram,
    onPickNextProgram,
  };
};

export default useProgramDetailsHook;
