import React from 'react';
import { connect } from 'react-redux';
import { SessionExpire } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';

const SessionExpireView = ({ openSessionExpire, handleLogInOut }) => {
  return (
    <>
      {openSessionExpire && (
        <SessionExpire
          errorMessage={<FormattedMessage id="sessionExpireMessage" />}
          logoutText={<FormattedMessage id="signInAgain" />}
          onClick={handleLogInOut}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return state.app;
};

export default connect(mapStateToProps)(SessionExpireView);
