import React from 'react';
const VoiceChatIcon = (props) => {
  const { width, height } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3335 1.3335H2.66683C1.9335 1.3335 1.3335 1.9335 1.3335 2.66683V14.6668L4.00016 12.0002H13.3335C14.0668 12.0002 14.6668 11.4002 14.6668 10.6668V2.66683C14.6668 1.9335 14.0668 1.3335 13.3335 1.3335ZM13.3335 10.6668H4.00016L2.66683 12.0002V2.66683H13.3335V10.6668Z"
        fill="#0F48B8"
      />
      <path d="M8.50016 3.3335H7.50016V10.0002H8.50016V3.3335Z" fill="#0F48B8" />
      <path d="M6.66683 4.66683H5.66683V8.66683H6.66683V4.66683Z" fill="#0F48B8" />
      <path d="M5.00016 6.00016H4.00016V7.3335H5.00016V6.00016Z" fill="#0F48B8" />
      <path d="M10.3335 4.66683H9.3335V8.66683H10.3335V4.66683Z" fill="#0F48B8" />
      <path d="M12.0002 6.00016H11.0002V7.3335H12.0002V6.00016Z" fill="#0F48B8" />
    </svg>
  );
};
VoiceChatIcon.defaultProps = {
  width: '16px',
  height: '16px',
};

export default VoiceChatIcon;
