import { makeStyles } from "@material-ui/core/styles";

import { Constant } from "../../../shared/services";

const drawerWidth = Constant.STYLES.DRAWER_WIDTH;
const drawerWidthFullScreen = Constant.STYLES.DRAWER_WIDTH_FULL_SCREEN;
const drawerCloseWidth = Constant.STYLES.DRAWER_CLOSE_WIDTH;

export default makeStyles(theme => ({
  root: {
    display: "flex"
  },
  notifDropDownBtn: {
    ...theme.typography.t20,
    ...theme.typography.medium
  },
  profileMenuWrapper: {
    maxWidth: 75
  },
  headingWrapper: {
    borderBottom: `8px solid ${theme.palette.primary.themeGreen}`,
    paddingBottom: 5,
    display: "inline-block",
    zIndex: 99
  },
  appBar: {
    backgroundColor: theme.palette.primary.background,
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  appBarFullScreen: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidthFullScreen}px) !important`
    }
  },
  drawerClosed: {
    width: `calc(100% - ${drawerCloseWidth}px)`
  },
  toolbar: {
    background: "#f5f5f5",
    boxShadow: "none",
    color: theme.palette.primary.black,
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 45px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 25px"
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  navMenu: {
    display: "flex",
    alignItems: "center"
  },
  rewardCount: {
    minWidth: 100,
    textAlign: "left",
    paddingLeft: 10
  },

  // REFACTORED STYLING
  mainWrapper: {
    padding: "15px 0px"
  },
  hContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0
  },
  rewardVal: {
    fontSize: theme.typography.t20.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
    marginLeft: 5
  },
  signOutBtn: {
    fontSize: theme.typography.t18.fontSize,
    minWidth: 100,
    textTransform: "capitalize",
    height: 45,
    borderRadius: 25,
    fontWeight: theme.typography.bold.fontWeight
  }
}));
