import { useCallback, useEffect, useState } from 'react';
import {
  getProgramDetails,
  enrollIntoProgram,
  showDefaultErrorToast,
  showSuccessToast,
  unenrollFromProgram,
  ProgramConstants,
} from '../../../../../content/programs/services/programs.service';
import { Utility } from '../../../../../shared/services';
import { PENDO_TRACK_EVENTS_ENUM, trackPendoEvent } from '../../../../../core';

const useProgramDetailsHook = (props) => {
  const programId = props.match.params.id;
  const { selectedLocale, history } = props;
  const [dataLoading, setDataLoading] = useState(true);
  const [details, setDetails] = useState({ name: 'Program Title' });
  const [modules, setModules] = useState([]);
  const [rootModule, setRootModule] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);

  const forceEnroll = useCallback(async () => {
    try {
      const response = await enrollIntoProgram(programId);
      if (response?.data) {
        setDetails((details) => ({ ...details, enrollmentStatus: 'ENROLLED' }));
      }
    } catch (e) {
      console.log(e);
    }
  }, [programId]);

  const fetchProgramDetails = useCallback(async () => {
    try {
      const response = await getProgramDetails(programId);
      if (response?.data?.payload) {
        const details = response.data.payload;
        if (details.forceResync) {
          forceEnroll();
        }
        setDetails({ ...details, ...details.program });
        setModules(Utility.sortByKey(details.program?.learningObjects, 'displayOrder'));
        setRootModule(details.program?.rootLearningObject);
        setDataLoading(false);
      }
    } catch (e) {
      console.log(e);
      showDefaultErrorToast();
    }
  }, [programId, forceEnroll]);

  useEffect(() => {
    fetchProgramDetails();
  }, [selectedLocale, fetchProgramDetails]);

  const handleEnroll = async () => {
    try {
      const response = await enrollIntoProgram(programId);

      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.PROGRAM_ENROLLEMENT, {
        id: programId,
        name: details.name,
      });

      if (response?.data) {
        setDetails({ ...details, enrollmentStatus: 'ENROLLED' });
        showSuccessToast('courses.enrolled.msg.success', { name: details.name });
      } else {
        showDefaultErrorToast();
      }
    } catch (e) {
      showDefaultErrorToast();
    }
  };

  const handleUnenroll = async () => {
    try {
      closeConfirmationDialog();

      const response = await unenrollFromProgram(programId);

      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.PROGRAM_UNENROLLEMENT, {
        id: programId,
        name: details.name,
      });

      if (response?.data) {
        setDetails({ ...details, enrollmentStatus: 'UNENROLLED' });
        showSuccessToast('courses.unenrolled.msg.success', { name: details.name });
      } else {
        showDefaultErrorToast();
      }
    } catch (e) {
      showDefaultErrorToast();
    }
  };

  const isSingleLaunchProgram = () => {
    return [
      ProgramConstants.TEMPLATE_TYPES.TEMPLATE_SINGLE_COURSE,
      ProgramConstants.TEMPLATE_TYPES.TEMPLATE_MULTIPLE_COURSES_UNIFIED,
    ].includes(details.templateType);
  };

  const hasContent = () => {
    return modules.length > 0;
  };

  const showConfirmationDailog = () => {
    setOpenDialog(true);
  };

  const closeConfirmationDialog = () => {
    setOpenDialog(false);
  };

  const goBack = () => {
    history.goBack();
  };

  const showCertificateDialog = () => {
    setShowCertificate(true);
  };

  const closeCertificateDialog = () => {
    setShowCertificate(false);
  };

  return {
    programId,
    details,
    modules,
    rootModule,
    dataLoading,
    openDialog,
    showCertificate,
    handleEnroll,
    handleUnenroll,
    hasContent,
    isSingleLaunchProgram,
    showConfirmationDailog,
    closeConfirmationDialog,
    goBack,
    showCertificateDialog,
    closeCertificateDialog,
  };
};

export default useProgramDetailsHook;
