export default theme => ({
  root: {},
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  image: {
    maxWidth: "80vw",
    maxHeight: "60vh"
  },
  contentContainer: {
    paddingTop: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column"
  },
  content: {
    maxWidth: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column"
  },
  mainTitle: {
    marginBottom: 30,
    color: "white",
    fontWeight: 700
  },
  description: {
    marginBottom: 30,
    color: "white"
  },
  filledButton: {
    marginBottom: 10,
    color: "white",
    textTransform: "capitalize",
    fontWeight: 700,
    width: 300
  },
  textButton: {
    marginBottom: 30,
    color: "white",
    textTransform: "capitalize",
    textDecoration: "underline",
    fontWeight: 700,
    width: 300
  }
});
