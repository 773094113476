import React from 'react';
import { withStyles, Box, Typography, Button, Grid } from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import styles from './instructions.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { AnswerOptions } from '..';
import { Constant } from '../../../../shared';

const Instructions = (props) => {
  const { classes, onClose, onContinue, userDetail } = props;
  const intl = useIntl();

  const ratingOptions = [
    { name: <FormattedMessage id="stronglyLike" />, value: '5' },
    { name: <FormattedMessage id="like" />, value: '4' },
    { name: <FormattedMessage id="unsure" />, value: '3' },
    { name: <FormattedMessage id="dislike" />, value: '2' },
    { name: <FormattedMessage id="stronglyDislike" />, value: '1' },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <Typography id="ip-instructions-title" variant="h1" className={classes.headerTitle}>
            <FormattedMessage id="interestProfiler" />
          </Typography>
          <Typography id="ip-instructions-subtitle" variant="h3" className={classes.headerTitle}>
            <FormattedMessage id="interest-profiler.instructions.title" />
          </Typography>
          <Button variant="contained" color="primary" tabIndex={-1} className={classes.readOnlyButton}>
            <Typography className={[classes.boldText, classes.flexCenteredContent].join(' ')}>
              <AvTimerIcon className={classes.marginRight5} /> 15 mins
            </Typography>
            <Typography className={[classes.boldText, classes.horizontalMargin10].join(' ')}>|</Typography>
            <Typography className={classes.boldText}>30 Questions</Typography>
          </Button>
        </Box>
        <Box className={classes.imgContainer}>
          <img
            className={classes.headerImg}
            alt="Interest Profiler Instructions"
            src={`${Constant.CDN_BASE_URL}/assets/img/ccfe-ip-instructions.svg`}
          />
        </Box>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          <Grid item sm={8}>
            <Typography variant="h3" gutterBottom>
              <FormattedMessage id="greetWelcome" values={{ name: userDetail ? userDetail.firstName : 'Learner' }} />
            </Typography>

            <Typography id="ip-instructions-para1" gutterBottom>
              <FormattedMessage
                id="interest-profiler.instructions.para1"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </Typography>

            <Typography id="ip-instructions-para2" gutterBottom>
              <FormattedMessage
                id="interest-profiler.instructions.para2"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  ul: (...chunks) => <ul>{chunks}</ul>,
                  li: (...chunks) => <li>{chunks}</li>,
                  strong: (...chunks) => <strong>{chunks}</strong>,
                  br: <br />,
                }}
              />
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <AnswerOptions
              options={ratingOptions}
              showSubtitle
              subtitle={<FormattedMessage id="ratingScaleEg" />}
              readOnly
              questionId="sample"
            />
          </Grid>
        </Grid>

        <Box className={classes.actionBox}>
          <Button
            id="ip-instructions-close-btn"
            variant="contained"
            className={classes.closeBtn}
            onClick={onClose}
            size="large"
            tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
            tracking-id="close-interest-profiler"
            aria-label={intl.formatMessage({ id: 'interest-profiler.instructions.button.close' })}
          >
            <FormattedMessage id="interest-profiler.instructions.button.close" />
          </Button>
          <Button
            id="ip-instructions-get-started-btn"
            variant="contained"
            className={classes.continueBtn}
            onClick={onContinue}
            size="large"
            tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
            tracking-id="start-interest-profiler"
            aria-label={intl.formatMessage({ id: 'interest-profiler.instructions.button.continue' })}
          >
            <FormattedMessage id="interest-profiler.instructions.button.continue" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Instructions);
