import { Http } from "../../../core/services/";

export class Logging {
  /**
   * @name getPropertyType
   * @param {string} name
   * @desc Based upon name passed, this method gets appropriate server property.
   * @return {string} serverProperty
   */
  getPropertyType = name => {
    if (name === "USER") {
      return "tabletName";
    } else if (name === "SERIAL") {
      return "serialNumber";
    } else {
      return null;
    }
  };

  /**
   * @name getBrowserVersion
   * @param {string} userAgent
   * @param {string} airWatchString Parsed air watch string
   * @desc Based upon userAgent string browser version is returned.
   * @return {string} browserVersion
   */
  getBrowserVersion = (userAgent, airWatchString) => {
    const isAirWatch = userAgent.toLowerCase().indexOf("airwatch") > -1;
    if (isAirWatch) {
      return airWatchString.substring(
        airWatchString.indexOf("Browser v") + 9,
        airWatchString.indexOf("--")
      );
    } else {
      return navigator.appVersion;
    }
  };

  /**
   * @name parseMetaInfoCollection
   * @param {array} metaInfoCollection
   * @desc Parse's AWB meta information collection and fetch's required data from it.
   * @return {object}
   */
  parseMetaInfoCollection = (metaInfoCollection = []) => {
    const data = {};
    metaInfoCollection.forEach(meta => {
      const metaInfo = meta.split("-");
      if (metaInfo.length > 1) {
        data[this.getPropertyType(metaInfo[0])] = metaInfo.splice(1).join("-");
      }
    });
    return data;
  };

  /**
   * @name logDeviceInfo
   * @param {object} data Payload that needs to be attached with log.
   * @desc Parses data from user-agent and saves via service.
   * @return {Promise}
   */
  logDeviceInfo = data => {
    let payload = {};
    let metaCollection = []
    let browserString = ''
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Model-Lenovo")) {
      // LENO
      const lenovoString = userAgent.substring(
        userAgent.indexOf("(") + 1, 
        userAgent.indexOf(")")
      )
      // META DATA INFORMATION
      const lenovoMetaCollection = lenovoString.split(";").map((item) => {
        item = item.trim()
        item = item.replace(" ", "-")
        item = item.replace("Serial", "SERIAL")
        item = item.replace("Model-Lenovo", "USER")
        return item
      })
      metaCollection = lenovoMetaCollection
      browserString = lenovoString
    } else {
      // AIRWATCH STRING
      const airWatchString = userAgent.substring(
        userAgent.lastIndexOf("(") + 1,
        userAgent.lastIndexOf(")")
      );
      // META DATA INFORMATION
      const airWatchMetaCollection = userAgent
        .substring(userAgent.indexOf("--") + 2, userAgent.lastIndexOf("--"))
        .split("--");
      metaCollection = airWatchMetaCollection
      browserString = airWatchString
    }

    payload = {
      ...this.parseMetaInfoCollection(metaCollection),
      browserVersion: this.getBrowserVersion(userAgent, browserString), // + 9 BECAUSE (Browser v).length = 9
      fullUserAgent: userAgent,
      ...data
    };

    return Http.REQUEST.post("device/tracking/awb", payload).then(
      _successLog => {
        return null;
      },
      _errorLog => {
        console.error(_errorLog);
      }
    );
  };
}
