import React from 'react';
import {
  withStyles,
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import styles from './searchAndFilter.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Utility } from '../../../../shared/services';

const SearchAndFilter = (props) => {
  const _utilityService = new Utility();
  const minCharForSearch = 3;
  const { classes, selectedZone, onZoneChange, searchValue, onSearch, handleSearchChange } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      {/* BEGIN: TITLE */}
      <Box className={classes.titleContainer}>
        <Box>
          <Typography id="saf-main-title" className={[classes.mainTitle, classes.capitalize].join(' ')}>
            <FormattedMessage id="browseCareersByJob" />
          </Typography>
        </Box>
      </Box>
      {/* END: TITLE */}

      {/* BEGIN: DESCRIPTION */}
      <Box className={classes.descriptionContainer}>
        <Typography id="saf-main-desc" className={classes.mainDescription} variant="body2" component="p">
          <FormattedMessage id="browseCareersDesc" />
        </Typography>
      </Box>
      {/* END: DESCRIPTION */}

      {/* BEGIN: ZONE FILTERS */}
      <Grid container sm={12} md={12} lg={12} spacing={2} className={classes.zoneFiltersContainer}>
        <Grid item>
          <Typography className={[classes.mainTitle, classes.uppercase].join(' ')}>
            <FormattedMessage id="jobZone" />
          </Typography>
        </Grid>
        {[1, 2, 3, 4, 5].map((item) => (
          <Grid item key={_utilityService.generateUniqueKey()}>
            <Button
              variant="outlined"
              className={[
                classes.zoneFilterBtn,
                selectedZone >= item ? classes[`filledBtnZone${item}`] : classes[`outlinedBtnZone${item}`],
              ].join(' ')}
              onClick={() => {
                onZoneChange(item);
              }}
              aria-label={intl.formatMessage({ id: 'filertByZone' }, { zone: item })}
            >
              <FormattedMessage id="zone" /> {item}
            </Button>
          </Grid>
        ))}
      </Grid>
      {/* END: ZONE FILTERS */}

      {/* BEGIN: ZONE DETAILS */}
      <Box className={classes.zoneDetailsContainer}>
        <Typography id="saf-selected-zone" gutterBottom variant="h4" color="secondary" className={classes.uppercase}>
          <FormattedMessage id={`jobZone${selectedZone}.title`} />
        </Typography>
        <Typography gutterBottom className={[classes.mainDescription, classes.bolder].join(' ')}>
          <FormattedMessage id={`jobZone${selectedZone}.subtitle`} />
        </Typography>
        <Grid container sm={12} md={12} lg={12} spacing={3} style={{ marginTop: 20 }}>
          <Grid item sm={12} md={4} lg={3}>
            <Box className={classes.zoneDetailsWrapper}>
              <Typography gutterBottom variant="h4" color="secondary" className={classes.uppercase}>
                <FormattedMessage id="experience" />
              </Typography>
              <Typography gutterBottom className={classes.mainDescription}>
                <FormattedMessage id={`jobZone${selectedZone}.experience`} />
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Box className={classes.zoneDetailsWrapper}>
              <Typography gutterBottom variant="h4" color="secondary" className={classes.uppercase}>
                <FormattedMessage id="training" />
              </Typography>
              <Typography gutterBottom className={classes.mainDescription}>
                <FormattedMessage id={`jobZone${selectedZone}.training`} />
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Box className={classes.zoneDetailsWrapper}>
              <Typography gutterBottom variant="h4" color="secondary" className={classes.uppercase}>
                <FormattedMessage id="education" />
              </Typography>
              <Typography gutterBottom className={classes.mainDescription}>
                <FormattedMessage id={`jobZone${selectedZone}.education`} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* END: ZONE DETAILS */}

      <Divider />

      {/* BEGIN: SEARCH BAR */}
      <Grid sm={12} md={8} lg={6}>
        <Typography style={{ marginTop: 30 }} gutterBottom variant="h4" color="secondary" className={classes.uppercase}>
          <FormattedMessage id="refineSearchByKeyword" />
        </Typography>
        <Box className={classes.searchBarContainer}>
          <Box width="100%">
            <FormControl variant="outlined" fullWidth size="small" className={classes.searchBarWrapper}>
              <OutlinedInput
                id="search-careers"
                placeholder="Search careers..."
                value={searchValue}
                onChange={handleSearchChange}
                labelWidth={0}
                fullWidth
                className={classes.searchBar}
                inputProps={{
                  id: 'search-careers-input',
                  'aria-label': intl.formatMessage({ id: 'searchCareers' }),
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="medium" color="primary" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Box>
            <Button
              id="search-careers-button"
              className={classes.searchBtn}
              color="primary"
              variant="contained"
              onClick={onSearch}
              disabled={searchValue.length < minCharForSearch}
              aria-label={intl.formatMessage({ id: 'search' })}
            >
              <FormattedMessage id="search" />
            </Button>
          </Box>
        </Box>
      </Grid>
      {/* END: SEARCH BAR */}
    </Box>
  );
};

export default withStyles(styles)(SearchAndFilter);
