import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Dialog,
  IconButton,
  DialogTitle,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Divider,
} from '@material-ui/core';
import { VisibilityOffOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';

import useStyles from './credentialCard.style';
import { UserService } from '../../../shared';
import { useIntl } from 'react-intl';

const CredentialCard = ({ onClose }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState({});
  const [userCard, setUserCard] = useState({
    userDetail: {},
    userLocations: [],
    userServices: [],
  });
  const userService = new UserService();
  const intl = useIntl();

  const handleClose = () => {
    onClose();
  };

  const handleShowPassword = (key) => {
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  };

  useEffect(() => {
    let isMounter = true;
    userService.getUserCard().then((userData) => {
      if (userData && isMounter) setUserCard(userData);
    });
    return () => {
      isMounter = false;
    };
  }, []);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth maxWidth="xs">
        <Box sx={{ py: 2, px: 3, pb: 0 }}>
          <DialogTitle>
            <div>
              <IconButton aria-label="close" onClick={onClose} className={classes.closeIconBtn}>
                <CloseIcon className={classes.crossIcon} />
              </IconButton>
            </div>
            <Typography variant="h2" color="primary" className={classes.heading}>
              LOGIN CREDENTIALS
            </Typography>
            <Divider variant="middle" className={classes.divider} />
          </DialogTitle>
        </Box>

        <FormControl variant="outlined">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              padding: '16px 40px',
            }}
          >
            {userCard.userServices.map((usData, index) => {
              const name = usData.serviceDisplayName;
              return (
                usData.externalUserName &&
                usData.externalUserPassword &&
                name && (
                  <Box sx={{ width: '100%', marginBottom: '40px' }}>
                    <Typography variant="body2" className={classes.formName}>
                      {name}
                    </Typography>
                    <Box sx={{ mb: 3, width: '100%' }}>
                      <OutlinedInput
                        className={classes.outlinedInput}
                        id="outlined-adornment-password"
                        type="text"
                        value={usData.externalUserName}
                        fullWidth
                        color="primary"
                        inputProps={{ 'aria-label': intl.formatMessage({ id: 'username' }) }}
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword[name] ? 'text' : 'password'}
                        value={usData.externalUserPassword}
                        fullWidth
                        className={classes.outlinedInput}
                        color="primary"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              className={classes.closeIcon}
                              key={name}
                              onClick={() => handleShowPassword(name)}
                            >
                              {showPassword[name] ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ 'aria-label': intl.formatMessage({ id: 'password' }) }}
                      />
                    </Box>
                  </Box>
                )
              );
            })}
          </Box>
        </FormControl>
      </Dialog>
    </div>
  );
};

export default CredentialCard;
