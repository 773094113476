export default (theme) => ({
  sectionMargin: {
    marginTop: 55,
  },
  mainTitle: {
    fontSize: theme.typography.t30.fontSize,
    ...theme.typography.bold,
    lineHeight: '38px',
    width: '100%',
    textTransform: 'capitalize',
    zIndex: 99,
  },
  upperPortionBg: {
    width: '100%',
    height: 220,
    background: 'linear-gradient(252.88deg, #055EC1 0%, #0070EC 86.68%)',
    position: 'absolute',
    marginLeft: -35,
    marginTop: -20,
  },
  welcomeText: {
    background: 'white',
  },
  viewTranscriptBtnContainer: {},
  viewTranscriptBtn: {
    textTransform: 'capitalize',
    fontSize: 20,
    marginTop: 50,
    whiteSpace: 'nowrap',
    marginLeft: 10,
  },
  quickLinksContainer: {
    display: 'flex',
    height: 250,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
    paddingLeft: 20,
  },
  interestProfilerTileContainer: {
    display: 'flex',
    height: 250,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 20,
    },
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    flexDirection: 'column',
    textAlign: 'center',
  },
  modalButton: {
    marginTop: 20,
  },
});
