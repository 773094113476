import { Box, Button, Grid, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';
import { RedeemDialog } from '../dialogs';
import styles from './no-schedule.style';
import { Utility } from '../../../../shared/services';

const NoSchedule = (props) => {
  const utilityService = new Utility();
  const { classes } = props;
  const [isRedeemDialogOpen, setDialogVisiblity] = React.useState(false);
  const intl = useIntl();

  return (
    <Grid container item alignItems="center" justify="center">
      {/* <Grid container item alignItems="center" justify="center">
        <Typography className={classes.title}>
          <FormattedMessage
            id="enjoyText"
            values={{
              tabName: props.tabName
            }}
          />
        </Typography>
      </Grid> */}
      {props.redeem && (
        <Grid id="redeemed-container" container item className={classes.timerWrapper}>
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            data-cy-name="redeemed-container"
            className={classes.redeemedContainer}
          >
            <CheckIcon className={classes.redeemedIcon} />
            <FormattedMessage
              id="redeemed"
              values={{
                tabName: props.tabName,
              }}
            />
          </Grid>
        </Grid>
      )}
      {!props.redeem && props.status.price !== 0 && utilityService.checkStatusExists(props.features, 'Incentive_Engine') && (
        <>
          <Grid id="not-redeem-container" container item alignItems="center" justify="center">
            <Button
              onClick={() => setDialogVisiblity(true)}
              className={classes.redeemBtn}
              aria-label={intl.formatMessage({ id: 'redeemBtn' }, { tabName: props.tabName })}
            >
              <Box component="span">
                <FormattedMessage
                  id="redeemBtn"
                  values={{
                    tabName: props.tabName,
                  }}
                />
              </Box>
              <Box display="flex" alignItems="center" component="span">
                {props.status.price}
                <StarsIcon className={classes.redeemIcon} />
              </Box>
            </Button>
          </Grid>
          <RedeemDialog
            price={props.status.price}
            isDialogOpen={isRedeemDialogOpen}
            setDialogVisiblity={setDialogVisiblity}
            tabName={props.tabName}
            updateRedeemState={props.updateRedeemState}
          />
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app }) => app;

export default withStyles(styles)(connect(mapStateToProps)(NoSchedule));
