export default (theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    height: '100%',
  },
  thumbnailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontFamily: theme.typography.secondaryFont,
    ...theme.typography.t24,
    fontWeight: 700,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    margin: '20px 20px 10px',
    padding: 0,
    fontFamily: theme.typography.secondaryFont,
  },
  thumbnail: {
    borderRadius: 15,
    height: 225,
  },
  playIcon: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.sub,
    fontSize: '5rem',
  },
  opacity: {
    opacity: 0.6,
  },
});
