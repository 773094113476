import { useEffect, useState } from 'react';
import { TranscriptConstants, getTranscriptData } from '../../services/transcript/transcript.service';

const useTranscriptHook = ({ unleashProps, selectedLocale }) => {
  const [version, setVersion] = useState(TranscriptConstants.VERSIONS.V2);
  const [transcriptData, setTranscriptData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (version === TranscriptConstants.VERSIONS.V2) {
      fetchTranscriptData();
    }
  }, [version, selectedLocale]);

  const preProcessTranscriptData = (transcriptResponse) => {
    transcriptResponse.contents = transcriptResponse.contents.filter((content) => content.programs.length > 0);
    return transcriptResponse;
  };

  const fetchTranscriptData = async () => {
    try {
      const response = await getTranscriptData();
      if (response?.data?.payload) {
        const { payload } = response.data;
        setTranscriptData(preProcessTranscriptData(payload));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleVersion = () => {
    try {
      const value =
        version === TranscriptConstants.VERSIONS.V1 ? TranscriptConstants.VERSIONS.V2 : TranscriptConstants.VERSIONS.V1;
      setVersion(value);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    version,
    transcriptData,
    toggleVersion,
    isLoading,
  };
};

export default useTranscriptHook;
