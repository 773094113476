import { Box, Card, Dialog, IconButton, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as earnTokenStore from '../../../core/store/earn-tokens';
import styles from './earn-tokens.style';

/**
 * @name getRuleString
 * @param {object} rule
 * @desc Based upon certain condition string is generated.
 * @return {string} strRule
 */
const getRuleString = (rule) => {
  switch (rule.ruleTypeId) {
    case 1:
      return (
        <FormattedMessage
          id="taskSubmittedRule"
          values={{
            award: rule.award,
          }}
        />
      );
    case 2:
      return (
        <FormattedMessage
          id="earnOverRule"
          values={{
            award: rule.award,
            threshold: parseInt(rule.threshold * 100),
          }}
        />
      );
    default:
      return `No Rule Found`;
  }
};

/**
 * @name getRulesCollection
 * @param {*} props
 * @desc Prepares rules collection based upon condition for
 * single service or all.
 * @return {array}
 */
const getRulesCollection = (props) => {
  if (props.props.serviceId) {
    return (props.rulesCollection || []).filter((rule) => rule.serviceId === props.props.serviceId);
  }
  return props.rulesCollection || [];
};

const EarnTokens = (props) => {
  const { classes } = props;
  const rulesCollection = getRulesCollection(props);
  const intl = useIntl();

  return (
    <Dialog
      classes={{
        paper: [classes.dialogRoot, 'grey-scrollbar'].join(' '),
      }}
      onClose={() => props.toggleEarnTokenDialog(null)}
      open={props.isDialogOpen}
    >
      <Card
        data-cy-name="view-play-schedule-dialog"
        container
        item
        classes={{
          root: classes.root,
        }}
      >
        {/* BEGIN: CARD HEADER */}
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardHeader}
        >
          <Box display="flex" data-cy-name="earn-token-title" component="div">
            <StarsIcon className={classes.dialogIcon} />
            <Typography className={classes.dialogTitle}>
              <FormattedMessage id="earnTokensTitle" />!
            </Typography>
          </Box>
          <IconButton
            data-cy-name="earn-token-close-btn"
            onClick={() => props.toggleEarnTokenDialog(null)}
            className={classes.closeBtn}
            aria-label={intl.formatMessage({ id: 'close' })}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* END: CARD HEADER */}
        {/* BEGIN: CARD CONTENT */}
        <Box component="div" data-cy-name="earn-token-content" className={[classes.cardContent, 'grey-scrollbar'].join(' ')}>
          {props.props.showDialogTitle && (
            <Box component="div">
              <Typography id="dialog-title" className={classes.dialogMainTitle}>
                <FormattedMessage id="howToEarn" />
              </Typography>
            </Box>
          )}

          {/* BEGIN: BOX */}
          {rulesCollection.map((rule) => (
            <Box key={rule.serviceName} component="div" className={classes.serviceWrapper}>
              {/* BEGIN: INTRO */}
              <Box display="flex" component="div">
                <Box component="div">
                  <Typography className={classes.serviceName}>{rule.serviceName}</Typography>
                  {rule.rules.length > 0 && (
                    <Typography className={[classes.serviceRule, classes.grey]}>
                      <FormattedMessage
                        id="subHeadingRule"
                        values={{
                          award: rule.rules[0].award,
                        }}
                      />
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* END: INTRO */}
              {/* BEGIN: RULES */}
              <Box component="div">
                <ul className={classes.rulesList}>
                  {rule.rules.map((_rule) => (
                    <li key={_rule.ruleTypeId}>
                      <Typography className={[classes.serviceRule, classes.black]}>{getRuleString(_rule)}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
              {/* END: RULES */}
            </Box>
          ))}

          {/* END: BOX */}
        </Box>
        {/* END: CARD CONTENT */}
      </Card>
    </Dialog>
  );
};

/**
 * @name mapStateToProps
 * @param {object} earnTokens
 * @desc Maps global redux state to props.
 * @return {object}
 */
const mapStateToProps = ({ earnToken }) => {
  return earnToken;
};

/**
 * @name mapDispatchToProps
 * @param {Function} dispatch
 * @desc Maps global redux state to props.
 * @return {object}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    toggleEarnTokenDialog: (props) => dispatch(earnTokenStore.toggleDialog(props)),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EarnTokens));
