import React from 'react';
import { withStyles, Box, Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './career-paths.style';
import useCareerPathsHook from './useCareerPathsHook';
import { CareerPathsItem } from '../../components';
import { connect } from 'react-redux';
import { useDocumentTitleHook } from '../../../../v2/shared';

const CareerPaths = (props) => {
  const { classes } = props;
  const { careerPaths, goToDetailsPage } = useCareerPathsHook(props);
  useDocumentTitleHook('page.title.career-paths');

  return (
    <Box className={classes.root}>
      {/* BEGIN: HEADER */}
      <Box className={classes.header}>
        <Typography id="career-path-title" variant="h2" gutterBottom className={classes.title}>
          <FormattedMessage id="careerPaths" />
        </Typography>
        <Typography id="career-path-desc" gutterBottom className={classes.description}>
          <FormattedMessage
            id="careerPaths.description"
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              br: <br />,
            }}
          />
        </Typography>
      </Box>
      {/* END: HEADER */}

      {/* BEGIN: CONTENT */}
      {careerPaths?.length > 0 && (
        <Grid id="career-paths-container" container spacing={3} className={classes.mainContainer}>
          {careerPaths.map((careerPath) => (
            <Grid item sm={6} md={6} key={careerPath.id}>
              <CareerPathsItem
                id={careerPath.id}
                title={careerPath.title}
                description={careerPath.descriptionShort}
                thumbnailUri={careerPath.thumbnailUriPath}
                pinned={careerPath.pinned}
                handleClick={goToDetailsPage}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {/* END: CONTENT */}
    </Box>
  );
};

const mapStateToProps = ({ locale }) => {
  return {
    selectedLocale: locale.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerPaths));
