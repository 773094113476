import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import useStyles from './header.style';
import { Constant, Utility } from '../../../shared/services';
import CredentialCard from '../credentiCardModal/credentialCard';
import { Http } from '../../services';
import { setLocale } from '../../store';
import { BrandLogo } from '../../../shared';

function Header(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const intl = useIntl();
  const [lernerCard, setLernerCard] = React.useState(false);
  const utilityService = new Utility();
  const classes = useStyles();
  const { handleLogout, selectedLocale, dispatch, isClosed } = props;
  const title = utilityService.getTitleFromUrl('heading');

  const handLernerCard = () => {
    setLernerCard(!lernerCard);
  };

  const handleLocaleClick = (event) => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const selectLanguage = async (event, lang) => {
    handleMenuClose();
    const data = { preferredLanguage: lang };
    if (!lang || lang === selectedLocale) {
      return;
    }
    try {
      const updatedUser = await Http.REQUEST.put('/user/update', JSON.stringify(data));
      if (updatedUser.data) {
        dispatch(setLocale({ locale: lang }));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // DO NOT REMOVE HEADER ID
  return (
    <Box id="ccfe-main-header" className={classes.root}>
      <Box className={classes.topWrapper}>
        <Box className={classes.container}>
          {/* BEGIN: LOGO */}
          <Box className={classes.topLogoMain}>
            <BrandLogo />
          </Box>
          {/* END: LOGO */}

          {/* BEGIN: TITLE */}
          {title && (
            <Box id="page-title">
              <Typography data-cy-name={`title-${title}`} variant="h1" className={classes.pageTitle}>
                <FormattedMessage id={title} />
              </Typography>
            </Box>
          )}
          {/* END: TITLE */}
        </Box>

        <Box className={classes.container}>
          {/* BEGIN: LANGUAGE */}
          <Box>
            <Button
              id="selected-locale"
              ref={anchorRef}
              className={[classes.capitalize, classes.textSecondary].join(' ')}
              startIcon={<LanguageIcon />}
              endIcon={<ExpandMoreIcon />}
              onClick={handleLocaleClick}
              aria-label={intl.formatMessage({ id: selectedLocale === 'en' ? 'menuLangEnglish' : 'menuLangSpanish' })}
            >
              <Typography>
                {selectedLocale === 'en' ? (
                  <FormattedMessage id="menuLangEnglish" />
                ) : (
                  <FormattedMessage id="menuLangSpanish" />
                )}
              </Typography>
            </Button>
            <Menu
              id="locale-menu"
              anchorEl={anchorRef.current}
              open={open}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={(event) => selectLanguage(event, 'en')}
                tracking-type={Constant.TRACKING_TYPES.LANGUAGE}
                tracking-id="switch-language-english"
                aria-label={intl.formatMessage({ id: 'menuLangEnglish' })}
              >
                <FormattedMessage id="menuLangEnglish" />
              </MenuItem>
              <MenuItem
                onClick={(event) => selectLanguage(event, 'es')}
                tracking-type={Constant.TRACKING_TYPES.LANGUAGE}
                tracking-id="switch-language-spanish"
                aria-label={intl.formatMessage({ id: 'menuLangSpanish' })}
              >
                <FormattedMessage id="menuLangSpanish" />
              </MenuItem>
            </Menu>
          </Box>
          {/* END: LANGUAGE */}

          {/* BEGIN: CredentialCard */}
          {!isClosed && (
            <>
              <Box>
                <Button
                  id="lerner-card-button"
                  onClick={handLernerCard}
                  tracking-type={Constant.TRACKING_TYPES.LOGIN_CREDENTIALS}
                  tracking-id="view-login-credentials"
                  aria-label={intl.formatMessage({ id: 'viewLoginCredentials' })}
                >
                  <img src="/assets/icons/key.png" alt="" className={classes.credentialCardKey} />
                </Button>
              </Box>
              {lernerCard && <CredentialCard onClose={handLernerCard} />}
            </>
          )}
          {/** END: CredentialCard */}

          {/* BEGIN: LOGOUT */}
          <Box className={classes.logoutWrapper}>
            <Button
              id="signout-button"
              color="primary"
              variant="contained"
              className={classes.capitalize}
              onClick={handleLogout}
              startIcon={<ExitToAppIcon />}
              tracking-type={Constant.TRACKING_TYPES.LOGOUT}
              tracking-id="logout"
              aria-label={intl.formatMessage({ id: 'signOut' })}
            >
              <FormattedMessage id="signOut" />
            </Button>
          </Box>
          {/** BEGIN: LOGOUT */}
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

export default connect(mapStateToProps)(Header);
