import React, { Suspense } from 'react';
import { Grid, withStyles, Paper, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import styles from './messaging.style';
import { Permission, setFriendsFamilyUnreadData } from '../../../../core';
import { FriendsFamilyMessaging } from './../../services/';
import { connect } from 'react-redux';
import { DocumentTitle } from '../../../../v2/shared';

const Sidebar = React.lazy(() => import('../../components/message-sidebar'));
const MessagesViewer = React.lazy(() => import('../../components/messages-viewer'));

class Messaging extends React.Component {
  constructor() {
    super();
    this.state = {
      contacts: [],
      selectedContact: null,
      newSelectedContact: null,
      location: {
        locationId: null,
      },
      newMessage: false,
      isAnnouncement: false,
      characterLimit: {
        messagingMinCharacterLimit: 30,
        messagingMaxCharacterLimit: 32000,
        messagingMaxRowLimit: 10,
      },
      threadType: '',
      showErrorMsg: false,
      errorMsg: '',
      eTag: 0,
      settings: null,
    };
  }
  _service = new FriendsFamilyMessaging();

  setSelectedContact = (contact) => {
    const { unreadData } = this.props.friendsAndFamily;
    if (unreadData.relationships) {
      const currentRelationIndex = unreadData.relationships.findIndex(
        (_relationship) => _relationship.relationshipId === contact.relationshipId
      );
      if (currentRelationIndex > -1) {
        unreadData['totalUnreadCount'] =
          unreadData['totalUnreadCount'] - unreadData.relationships[currentRelationIndex].learner.unreadCount;
        unreadData.relationships[currentRelationIndex].learner.unreadCount = 0;
        this.props.setFriendsFamilyUnreadData(unreadData);
      }
    }
    this.setState({
      newSelectedContact: null,
      selectedContact: contact,
      newMessage: false,
    });
  };

  setContacts = async (contacts) => {
    await this.setState({
      contacts: [...contacts],
      eTag: Math.random(),
    });
  };

  setSelectedContactForNew = (contact) => {
    this.setState((prevState) => ({
      newSelectedContact: contact,
      selectedContact: contact || prevState.selectedContact,
    }));
  };

  getCharacterLimit = () => {
    this._service
      .fetchCharacterLimit()
      .then((_successLog) => {
        this.setState({ characterLimit: _successLog });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getCharacterLimit();
  }

  getLearnerSettings = async () => {
    this.setState({
      settings: {},
    });
    try {
      const _successLog = await this._service.getLearnerSettings(this.learnerId);
      this.setState({
        settings: _successLog.payload,
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.learnerId && !this.state.settings) {
      this.getLearnerSettings();
    }
  }

  syncRelationshipList = (list = []) => {
    let { contacts } = this.state;
    contacts = contacts.map((_contact) => {
      const contactInList = list.find((_relationship) => _relationship.relationshipId === _contact.relationshipId);
      if (contactInList) {
        _contact['status'] = contactInList['status'];
      }
      return _contact;
    });

    this.setContacts(contacts);
  };

  get learnerId() {
    const { userDetail } = this.props;
    return parseInt(userDetail.userId);
  }

  onSend = async (text) => {
    try {
      const { payload } = await this._service.postMessage({
        body: text,
        learnerPersonId: this.learnerId,
        contactPersonId: this.state.selectedContact.contact.personId,
      });
      this.setState({
        settings: payload.setting,
      });
      return payload;
    } catch (err) {
      switch (err.response.status) {
        case 429:
          this.setState({
            showErrorMsg: true,
            errorMsg: <FormattedMessage id="messageLimitError" />,
          });
          break;
        default:
          this.setState({
            showErrorMsg: true,
            errorMsg: <FormattedMessage id="sendMessageFailed" />,
          });
          break;
      }
      return null;
    }
  };

  onSearch = async (key) => {
    return await this._service.fetchSearchLearner(key, this.state.location.locationId);
  };

  onDirectMessage = () => {
    this.setState({
      newMessage: true,
      selectedContact: {
        contactId: 0,
        contactName: <FormattedMessage id="newMessage" />,
      },
      newSelectedContact: null,
    });
  };

  onMessageTab = () => {
    this.setState({
      newMessage: false,
      selectedContact: {
        contactId: 0,
        contactName: <FormattedMessage id="directMessages" />,
      },
    });
  };

  handleCloseErrorMsg = () => {
    this.setState({
      showErrorMsg: false,
      errorMsg: '',
    });
  };

  onThreadSelect = (type) => {
    this.setState({
      threadType: type,
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedContact, newSelectedContact, contacts, settings, newMessage } = this.state;

    if (!this.props.userDetail.userId) {
      // USER ID IS REQUIRED IN UNDERLYING LOGICS
      return null;
    }
    return (
      <>
        <DocumentTitle title="page.title.friends-and-family-messaging" />
        <Permission {...this.props}>
          <Suspense fallback={<CircularProgress />}>
            <Snackbar
              open={this.state.showErrorMsg}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              onClose={this.handleCloseErrorMsg}
            >
              <MuiAlert severity="error" elevation={6} variant="filled">
                {this.state.errorMsg}
              </MuiAlert>
            </Snackbar>
            <Grid container className={[classes.container, newMessage ? classes.newMsg : null].join(' ')}>
              <Paper elevation={1} className={[classes.chatContainer, newMessage ? classes.newMsg : null].join(' ')}>
                <Grid item sm={4} md={3}>
                  <Sidebar
                    location={this.state.location}
                    onSelect={this.setSelectedContact}
                    selectedContact={selectedContact}
                    onThreadSelect={this.onThreadSelect}
                    onDirectMessage={this.onDirectMessage}
                    onMessageTab={this.onMessageTab}
                    contacts={contacts}
                    setContacts={this.setContacts}
                    learnerId={this.learnerId}
                    eTag={this.state.eTag}
                  />
                </Grid>
                <Grid item sm={8} md={9}>
                  <MessagesViewer
                    selectedContact={selectedContact}
                    newSelectedContact={newSelectedContact}
                    onSend={this.onSend}
                    showErrorMsg={this.state.showErrorMsg}
                    characterLimit={this.state.characterLimit}
                    newMessage={this.state.newMessage}
                    onSearch={this.onSearch}
                    onSelectedContact={this.setSelectedContact}
                    contacts={contacts}
                    setContacts={this.setContacts}
                    learnerId={this.learnerId}
                    settings={settings}
                    eTag={this.state.eTag}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Suspense>
        </Permission>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFriendsFamilyUnreadData: (unreadData) => dispatch(setFriendsFamilyUnreadData(unreadData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Messaging));
