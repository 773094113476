import { Box, Dialog, DialogContent, IconButton, Typography, withStyles } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styles from './calculator.style';
import { UnleashProvider } from '../../../core';
import { UnleashService } from '../../../core/services/unleash/unleash';

const Calculator = forwardRef(({ classes }, ref) => {
  const [isOpen, setOpen] = React.useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: (isOpen) => setOpen(isOpen),
  }));

  const renderIframe = useMemo(
    () => (
      <Box
        id="calculator-iframe"
        height="420px"
        border="1px solid black"
        component="iframe"
        src="https://www.desmos.com/scientific"
        width="675px"
      />
    ),
    []
  );

  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogContent>
        <Box>
          <Box width="100%" justifyContent="space-between" alignItems="center" display="flex" textTransform="uppercase">
            <Typography id="calculator-title" className={classes.headingFont}>
              Calculator
            </Typography>
            <IconButton id="calculator-close-button" onClick={() => setOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <UnleashProvider
            flagName={UnleashService.FLAGS.EMBED_DESMOS_CALCULATOR}
            enabledRenderFn={() => renderIframe}
            disabledRenderFn={() => (
              <Box
                id="calculator-iframe"
                height="420px"
                border="1px solid black"
                component="iframe"
                src="/calculator/index.html"
                width="100%"
              />
            )}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(styles)(Calculator);
