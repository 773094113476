import React from 'react';
import { withStyles, Box, Typography, Grid, Button, Divider } from '@material-ui/core';

import styles from './careerPathOutline.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { PinIcon } from '../../../../shared/icons';
import { LoaderHOC } from '../../../../shared/components';
import { LinkButton } from '@orijinworks/frontend-commons';

// Commenting out the progress bar work as we are not tracking the progress for now.
// TODO: Uncomment the progress bar work when we start tracking the progress.

const CareerPathOutline = (props) => {
  const {
    classes,
    careerPathId,
    launchCareerProfile,
    title,
    description,
    thumbnailUriPath,
    pinned,
    loaderApiType,
    handlePinClick,
  } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box
        id={`career-path-${careerPathId}-img-overlay`}
        className={classes.imageOverlay}
        style={{ background: `url(${thumbnailUriPath}) center / cover` }}
      />

      <Grid container sm={12} md={12} className={classes.titleWrapper}>
        <Grid item sm={10} md={8} className={classes.titleContainer}>
          <Typography id={`career-path-${careerPathId}-title`} variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} className={classes.descriptionContainer}>
          <Typography id={`career-path-${careerPathId}-description`} className={classes.description}>
            {description}
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} className={classes.actionContainer}>
          <LinkButton
            id={`career-path-${careerPathId}-launch-career-btn`}
            color="primary"
            size="large"
            onClick={launchCareerProfile}
            className={[classes.launchCareerButton, classes.careerPathBtn].join(' ')}
            variant="text"
            aria-label={intl.formatMessage({ id: 'learnMoreAboutThisCareer' })}
          >
            <FormattedMessage id="learnMoreAboutThisCareer" />
          </LinkButton>
        </Grid>
      </Grid>

      <Divider />

      <Grid container sm={12} md={12} className={classes.progressContainer}>
        {/* <Box className={classes.progressArea}>
          <Box className={classes.progressLabelsArea}>
            <Typography id={`career-path-${careerPathId}-progress-label`} className={classes.progressLabel}>
              <FormattedMessage id="programsCompleted" />
            </Typography>
            <Typography id={`career-path-${careerPathId}-progress-count`} color="primary" className={classes.progressCount}>
              <FormattedMessage
                id="mofn"
                values={{
                  m: programsCompleted,
                  n: programsTotal,
                }}
              />
            </Typography>
          </Box>
          <LinearProgress
            id={`career-path-${careerPathId}-progress-bar`}
            variant="determinate"
            value={Math.floor((programsCompleted / programsTotal) * 100)}
            classes={{
              root: classes.progressBarRoot,
              colorPrimary: classes.progressBarColorPrimary,
              bar: classes.progressBar,
            }}
          />
        </Box> */}

        <Button
          id={`career-path-${careerPathId}-pin-to-home-btn`}
          variant="outlined"
          color="secondary"
          size="large"
          className={[classes.pinToHomeBtn, pinned ? classes.pinnedBtn : ''].join(' ')}
          startIcon={
            <LoaderHOC apiKey={careerPathId} type={loaderApiType}>
              <PinIcon />
            </LoaderHOC>
          }
          onClick={handlePinClick}
          aria-label={intl.formatMessage({ id: pinned ? 'unpinCareerPathFromHomepage' : 'pinCareerPathToHomepage' })}
        >
          <FormattedMessage id={pinned ? 'pinnedToHomepage' : 'pinToHomepage'} />
        </Button>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(CareerPathOutline);
