import { useState, useMemo } from 'react';
import { CallDisabledIcon, Constant } from '../../../../shared';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import { AccessTime } from '@material-ui/icons';
import TimerIcon from '@mui/icons-material/Timer';

import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

const useCallCenterSchedulingHook = ({ schedule, isClosed, isCallTimeLimitReached }) => {
  const [showFullSchedule, setShowFullSchedule] = useState(false);

  const getActiveSlot = () => {
    const activeDayIndex = getActiveDayIndex();
    const activeDay = schedule[activeDayIndex];
    return activeDay?.activeSlot?.slot;
  };
  const getActiveDayIndex = () => {
    return schedule.findIndex((schedule) => schedule.activeSlot || schedule.nextActiveSlot);
  };
  const getNextTwoDays = (activeDayIndex) => {
    const nextDayIndex = (activeDayIndex + 1) % schedule.length;
    const nextTwoDaysIndex = (activeDayIndex + 2) % schedule.length;

    if (nextTwoDaysIndex < nextDayIndex) {
      return [...schedule.slice(nextDayIndex), ...schedule.slice(0, nextTwoDaysIndex + 1)];
    } else {
      return schedule.slice(nextDayIndex, nextTwoDaysIndex + 1);
    }
  };

  const getCallCenterStatus = useMemo(() => {
    if (isCallTimeLimitReached) {
      return Constant.CALL_CENTER_STATUS.CALL_TIME_LIMIT_REACHED;
    } else if (isClosed) {
      return Constant.CALL_CENTER_STATUS.CLOSED;
    }
    const activeDayIndex = getActiveDayIndex();
    if (activeDayIndex === -1) {
      return null;
    }
    const activeSchedule = schedule[activeDayIndex];
    const timestamp = activeSchedule?.activeSlot?.slot.end;

    if (!timestamp) {
      return null;
    }

    const endTime = moment.unix(timestamp);
    const currentTime = moment();
    const diffMinutes = endTime.diff(currentTime, 'minutes');

    if (diffMinutes <= Constant.VOICE_CALL_SCHEDULE_CUTOFF_TIME) {
      return Constant.CALL_CENTER_STATUS.CLOSING_SOON;
    }

    return Constant.CALL_CENTER_STATUS.OPEN;
  }, [schedule, isClosed]);

  const callCenterStatusData = useMemo(() => {
    if (!getCallCenterStatus) {
      return null;
    }

    const activeSlot = getActiveSlot();

    switch (getCallCenterStatus) {
      case Constant.CALL_CENTER_STATUS.CLOSED:
        return {
          title: 'call-center-scheduling.closed.title',
          description: 'call-center-scheduling.closed.description',
          slotTitle: 'call-center-scheduling.closed.slotTitle',
          icon: <CallDisabledIcon />,
          cssClass: 'callCenterClosed',
        };
      case Constant.CALL_CENTER_STATUS.OPEN:
        return {
          title: 'call-center-scheduling.open.title',
          description: 'call-center-scheduling.open.description',
          slotTitle: 'call-center-scheduling.open.slotTitle',
          icon: <RingVolumeIcon />,
          cssClass: 'callCenterOpen',
        };
      case Constant.CALL_CENTER_STATUS.CLOSING_SOON:
        const endTime = moment.unix(activeSlot.end).format('hh:mm a');
        return {
          title: 'call-center-scheduling.closing-soon.title',
          description: 'call-center-scheduling.closing-soon.description',
          slotTitle: 'call-center-scheduling.closing-soon.slotTitle',
          icon: <AccessTime />,
          endTime,
          cssClass: 'callCenterClosingSoon',
        };
      case Constant.CALL_CENTER_STATUS.CALL_TIME_LIMIT_REACHED:
        return {
          title: 'call-center-scheduling.call-time-limit-reached.title',
          description: 'call-center-scheduling.call-time-limit-reached.description',
          slotTitle: 'call-center-scheduling.call-time-limit-reached.slotTitle',
          icon: <TimerIcon />,
          cssClass: 'callCenterClosed',
        };
      default:
        return null;
    }
  }, [getCallCenterStatus, schedule]);

  const displayedSchedules = useMemo(() => {
    if (!schedule) {
      return null;
    }

    if (showFullSchedule) {
      return [...schedule];
    }

    const activeDayIndex = getActiveDayIndex();
    const activeDay = schedule[activeDayIndex];
    const nextTwoDays = getNextTwoDays(activeDayIndex);

    return [activeDay, ...nextTwoDays];
  }, [schedule, showFullSchedule]);

  const displayCompleteSchedule = useMemo(() => {
    return schedule;
  }, [schedule]);

  const handleShowFullSchedule = () => {
    setShowFullSchedule((prev) => !prev);
  };

  return {
    showFullSchedule,
    displayedSchedules,
    displayCompleteSchedule,
    handleShowFullSchedule,
    getCallCenterStatus,
    callCenterStatusData,
    getActiveDayIndex,
    getActiveSlot,
    getNextTwoDays,
  };
};

useCallCenterSchedulingHook.propTypes = {
  schedule: PropTypes.array.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default useCallCenterSchedulingHook;
