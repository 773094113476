import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {},
  greetingMain: {
    fontWeight: theme.typography.medium.fontWeight,
    fontSize: theme.typography.t48.fontSize,
    lineHeight: "55px",
    color: theme.palette.primary.black,
    textTransform: "capitalize"
  },
  wordBreak: {
    wordBreak: "break-all"
  },
  greetingSub: {
    fontSize: theme.typography.t24.fontSize,
    color: theme.palette.primary.black
  },
  rewardIcon: {
    color: "white"
  },
  analyticsWrapper: {
    background: theme.palette.primary.darkBlue,
    color: theme.palette.primary.white,
    padding: "40px 50px",
    borderRadius: "5px 0px 0px 5px",
    boxShadow: "0px 2px 15px 3px #02184347",
    [theme.breakpoints.up("md")]: {
      marginRight: -50
    }
  },
  analyticHeading: {
    fontSize: theme.typography.t20.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
    color: theme.palette.primary.white,
    margin: "0 0 35px"
  },
  analyticText: {
    fontSize: theme.typography.t14.fontSize,
    color: theme.palette.primary.white,
    fontWeight: theme.typography.medium.fontWeight,
    lineHeight: "19px"
  },
  analyticValue: {
    fontSize: theme.typography.t48.fontSize,
    color: theme.palette.primary.white,
    fontWeight: theme.typography.medium.fontWeight,
    lineHeight: "37px",
    marginLeft: 10
  },
  borderedGrid: {
    position: "relative"
  },
  leftBorder: {
    left: 0
  },
  rightBorder: {
    right: 0
  },
  border: {
    position: "absolute",
    height: 59,
    width: 4,
    backgroundColor: theme.palette.primary.lightBlue,
    top: -7,
    borderRadius: 3,
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    }
  },
  unsetJustify: {
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "initial !important"
    }
  },
  unsetTextAlign: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "left"
    }
  },
  noGreeting: {
    fontSize: theme.typography.t20.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
    color: theme.palette.primary.white
  }
}));
