import React from 'react';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from './message-threads.style';
import { ScaleUnbalancedIcon, ShieldCheckedIcon } from '../../../../shared/icons';
import { isRoleAttorney } from '../../services/staff-messaging.service';
import useMessageThreadHook from './useMessageThreadHook';
import { useIntl } from 'react-intl';
import { Utility } from '../../../../shared';

const MessageThreads = (props) => {
  const { classes, threads, isThreadSelected, handleThreadClick, isLoading } = props;
  const { loaderRef, getTime, getDate, shouldShowDate, shouldShowMore } = useMessageThreadHook(props);
  const intl = useIntl();

  return (
    <List id="message-threads-list" className={classes.root}>
      {threads?.map((thread) => {
        const time = getTime(thread.lastMessage.datetime);
        const date = getDate(thread.lastMessage.datetime);
        const isSelected = isThreadSelected(thread);

        return (
          <ListItem
            id={`message-thread-${thread.threadId}`}
            key={thread.threadId}
            button
            className={[classes.threadItem, isSelected ? classes.threadSelected : ''].join(' ')}
            onClick={() => {
              handleThreadClick(thread);
            }}
            aria-label={intl.formatMessage({ id: 'selectThread' }, { thread: thread.title })}
          >
            <ListItemIcon id={`message-thread-${thread.threadId}-icon`}>
              <Box className={classes.iconBox}>
                {thread.hasUnreadMessages && (
                  <Box id={`message-thread-${thread.threadId}-red-dot`} className={classes.redDot} />
                )}
                {isRoleAttorney(thread.roleName, thread.roleType) ? <ScaleUnbalancedIcon /> : <ShieldCheckedIcon />}
              </Box>
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.content }}>
              <Typography
                id={`message-thread-${thread.threadId}-title`}
                variant="h2"
                className={[classes.title, classes.truncate].join(' ')}
              >
                {thread.title || ''}
              </Typography>
              {thread.showMessagePreview && (
                <Typography
                  id={`message-thread-${thread.threadId}-preview-text`}
                  className={[classes.previewText, classes.truncate].join(' ')}
                >
                  <div dangerouslySetInnerHTML={Utility.createSafeMarkup(thread.lastMessage?.body || '')} />
                </Typography>
              )}
              {thread.showSenderRecipient && (
                <>
                  <Typography id={`message-thread-${thread.threadId}-sender`} className={[classes.truncate].join(' ')}>
                    From: {thread.sender || ''}
                  </Typography>
                  <Typography
                    id={`message-thread-${thread.threadId}-recipient`}
                    className={[classes.previewText, classes.truncate].join(' ')}
                  >
                    To: {thread.recipient || ''}
                  </Typography>
                </>
              )}
            </ListItemText>
            <ListItemSecondaryAction className={classes.dateContainer}>
              <Typography id={`message-thread-${thread.threadId}-time`} variant="body2" className={classes.text}>
                {time}
              </Typography>
              {shouldShowDate(date) && (
                <Typography id={`message-thread-${thread.threadId}-date`} variant="body2" className={classes.text}>
                  {date}
                </Typography>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}

      <Box height="20px" ref={loaderRef} />
      {(shouldShowMore() || isLoading) && (
        <Box className={classes.loaderContainer}>
          <CircularProgress />
        </Box>
      )}
    </List>
  );
};

export default withStyles(styles)(MessageThreads);
