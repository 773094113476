import moment from "moment";
import { Http } from "../../../../core";
import { Constant } from "../../../../shared/services";

export class AchievementsDashboardService {
  CONTENT_AREAS = {
    "1": {
      bgColor: "#0a827d"
    },
    "4": {
      bgColor: "#8a51dd"
    },
    "6": {
      bgColor: "#3c6a91"
    },
    "2": {
      bgColor: "#737982"
    },
    "5": {
      bgColor: "#f15b4a"
    },
    "9": {
      bgColor: "#2d2d2d"
    }
  };

  /**
   * @name groupByContentAreas
   * @param {object} payload
   * @desc Groups data by content areas for usage of chart.js
   * @return {object} data Data that can be used for chart.js
   */
  groupByContentAreas = payload => {
    const labels = [],
      data = [],
      backgroundColor = [];

    payload.forEach((contentData, index) => {
      labels[index] = Constant.CONTENT_AREA[contentData.content.contentId].name;
      backgroundColor[index] = this.CONTENT_AREAS[
        contentData.content.contentId
      ].bgColor;

      data[index] = 0;

      contentData.hoursOnDate.forEach(graphMeta => {
        data[index] += graphMeta.hours;
      });

      // FIXING TO 2 DECIMAL
      // PLACES ONLY IF ITS FLOAT
      if (data[index].toString().indexOf(".") > -1) {
        data[index] = data[index].toFixed(2);
      }
    });

    return {
      labels,
      data,
      backgroundColor
    };
  };

  /**
   * @name getGroupingFormat
   * @param {number} noOfDays
   * @desc Determines grouping format based upon no. of days.
   * @return {string} dateFormat
   */
  getGroupingFormat = noOfDays => {
    switch (noOfDays) {
      case 7:
        return Constant.CHART_FORMAT.FORMAT_7;

      case 30:
        return Constant.CHART_FORMAT.FORMAT_30;

      case 365:
        return Constant.CHART_FORMAT.FORMAT_365;

      default:
        return Constant.CHART_FORMAT.FORMAT_DEFAULT;
    }
  };

  /**
   * @name checkIfDataExists
   * @param {string} format
   * @desc Checks if data with current format exists or not.
   * @return {boolean}
   */
  checkIfDataExists = (noOfDays, currentValue, payload) => {
    let isExists = false;
    let format = null;
    switch (noOfDays) {
      case 7:
        format = Constant.CHART_FORMAT.CHECKS.FORMAT_7;
        break;
      case 30:
        format = Constant.CHART_FORMAT.CHECKS.FORMAT_30;
        break;
      case 365:
        format = Constant.CHART_FORMAT.CHECKS.FORMAT_365;
        break;
      default:
        format = Constant.CHART_FORMAT.CHECKS.FORMAT_DEFAULT;
        break;
    }

    payload.hoursOnDate.forEach(graphData => {
      const formattedGraphData = moment(graphData.date).format(format);
      const formattedCurrentValue = moment(currentValue).format(format);
      if (formattedGraphData === formattedCurrentValue) {
        isExists = true;
      }
    });
    return isExists;
  };

  /**
   * @name fillChartData
   * @param {number} noOfDays No. of days to which data is required.
   * @param {string} bgColor Color that needs to be filled.
   * @param {array} payload Chart data from server.
   * @desc A generic method to fill data for missing chart values.
   * @return {object} data
   */
  fillChartData = (noOfDays, bgColor, payload) => {
    let subtractVariation = "days",
      count = noOfDays,
      dayDiffMeta = this.getDayDifference(payload);

    if (noOfDays === 0) {
      count = dayDiffMeta.dayDiff;
    } else if (noOfDays > 30) {
      count = 365;
    }
    const data = {
      labels: [],
      data: [],
      backgroundColor: []
    };

    for (let a = count; a > 0; a--) {
      const label = moment(dayDiffMeta.maxValue)
        .subtract(a, subtractVariation)
        .format(this.getGroupingFormat(noOfDays));
      const value = 0;
      data.labels.push(label);
      data.data.push(value);
      data.backgroundColor.push(bgColor);
    }
    return data;
  };

  /**
   * @name getDayDifference
   * @param {object} payload inforgraphic data
   * @desc Calculates difference of days between min and max value.
   * @return {number} dayDifference
   */
  getDayDifference = payload => {
    const moments = [moment()];
    payload.hoursOnDate.forEach(infographData => {
      moments.push(moment(infographData.date));
    });
    const maxValue = moment.max(moments);
    const minValue = moment(moment.min(moments).format("YYYY-MM-01THH:mm:ss"));
    return {
      dayDiff: maxValue.diff(minValue, "days"),
      maxValue,
      minValue
    };
  };

  /**
   * @name groupByDates
   * @param {object} payload
   * @desc Groups entire data by date.
   * @return {object} data Data that can be further used in chart.js integration.
   */
  groupByDates = (payload, groupFormat, noOfDays) => {
    let chartData = {
      labels: [],
      data: [],
      backgroundColor: []
    };

    if (payload.length === 0) {
      return chartData;
    }

    chartData = this.fillChartData(
      noOfDays,
      this.CONTENT_AREAS[payload[0].content.contentId].bgColor,
      payload[0]
    );

    payload.forEach((content, index) => {
      content.hoursOnDate.forEach(contentData => {
        const date = moment(contentData.date).format(groupFormat);
        const labelIndex = chartData.labels.indexOf(date);
        if (labelIndex === -1) {
          chartData.labels.push(date);
          chartData.data.push(contentData.hours.toFixed(2));
          chartData.backgroundColor.push(
            this.CONTENT_AREAS[content.content.contentId].bgColor
          );
        } else {
          chartData.data[labelIndex] += contentData.hours.toFixed(2);
        }
      });
    });

    return chartData;
  };

  /**
   * @name getBundlesData
   * @param {number} noOfDays Data required for no. of days
   * @param {array} contentAreas Types of content area data required
   * @desc Fetches bundles data from API.
   * @return {Promise}
   */
  getBundlesData = (noOfDays, contentAreas) => {
    return new Promise((resolve, reject) => {
      let cloneContentArea = Object.assign([], contentAreas); // USING CLONE VARIABLE TO FIX REF. ISSUE.
      if (cloneContentArea.length > 5) cloneContentArea = [0];

      Http.REQUEST.get(
        `/achievement/completedBundles?days=${noOfDays}&contentAreas=${cloneContentArea.toString()}`
      ).then(
        _successLog => {
          resolve(_successLog.data);
        },
        _errorLog => {
          resolve([]);
        }
      );
    });
  };

  /**
   * @name sumHours
   * @param {array} infographicData
   * @desc Sums hours of inforgraph data.
   * @return {number} title
   */
  sumHours = infographicData => {
    if (!infographicData.data || infographicData.data.data.length === 0)
      return 0;

    return infographicData.data.data.reduce((prev, next) => prev + next);
  };

  /**
   * @name getAchievementMeta
   * @param {number} noOfDays Data required for no. of days
   * @param {array} contentAreas Types of content area data required
   * @desc Fetches achievement meta from API
   * @return {Promise}
   */
  getAchievementMeta = (noOfDays, contentAreas) => {
    let cloneContentArea = Object.assign([], contentAreas); // USING CLONE VARIABLE TO FIX REF. ISSUE.
    if (cloneContentArea.length > 5) cloneContentArea = [0];

    return new Promise((resolve, reject) => {
      Http.REQUEST.get(
        `/achievement?days=${noOfDays}&contentAreas=${cloneContentArea.toString()}`
      ).then(
        _successLog => {
          resolve(_successLog.data);
        },
        _errorLog => {
          resolve({
            hours: 0,
            tasks: 0,
            tokens: 0
          });
        }
      );
    });
  };

  /**
   * @name getCourseCompleted
   * @param {number} noOfDays Data required for no. of days
   * @param {array} contentAreas Types of content area data required
   * @desc Fetches user completed courses from REST API.
   * @return {Promise}
   */
  getCourseCompleted = (noOfDays, contentAreas) => {
    let cloneContentArea = Object.assign([], contentAreas); // USING CLONE VARIABLE TO FIX REF. ISSUE.
    if (cloneContentArea.length > 5) cloneContentArea = [0];

    return new Promise((resolve, reject) => {
      Http.REQUEST.get(
        `/achievement/completedTasks?days=${noOfDays}&contentAreas=${cloneContentArea.toString()}`
      ).then(
        _successLog => {
          resolve(_successLog.data);
        },
        _errorLog => {
          resolve([]);
        }
      );
    });
  };

  /**
   * @name getGraphType
   * @param {number | string} noOfDays
   * @desc Gets graph type depending upon no. of days.
   * @return {number} graphType
   */
  getGraphType = noOfDays => {
    switch (noOfDays) {
      case 7:
      case 30:
        return Constant.GRAPHS.BAR;

      default:
        return Constant.GRAPHS.LINE;
    }
  };

  /**
   * @name getInfographicData
   * @param {number} noOfDays Record required for no. of days.
   * @param {array} contentAreas Data required for content areas.
   * @desc Fetchs data for info graphic view and manipulates based on outcome
   * required.
   * @return {Promise}
   */
  getInfographicData = (noOfDays, contentAreas = []) => {
    return new Promise((resolve, reject) => {
      let response = null;
      let cloneContentArea = Object.assign([], contentAreas); // USING CLONE VARIABLE TO FIX REF. ISSUE.

      if (cloneContentArea.length > 5) cloneContentArea = [0];

      Http.REQUEST.get(
        `/achievement/infograph?days=${noOfDays}&contentArea=${cloneContentArea.toString()}`
      ).then(
        _successLog => {
          if (!_successLog.data) {
            return;
          }
          switch (contentAreas.length) {
            case 1:
              response = this.groupByDates(
                _successLog.data,
                this.getGroupingFormat(noOfDays),
                noOfDays
              );
              response["type"] = this.getGraphType(noOfDays);
              break;

            default:
              response = this.groupByContentAreas(_successLog.data);
              response["type"] = Constant.GRAPHS.BAR;
          }

          resolve(response);
        },
        _errorLog => {
          switch (_errorLog.response && _errorLog.response.status) {
            case 404:
              resolve({
                labels: [],
                data: [],
                backgroundColor: []
              });
              break;

            default:
              reject(_errorLog);
          }
        }
      );
    });
  };
}
