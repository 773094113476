export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    minHeight: 125,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    width: '100%',
  },
  logo: {
    width: 150,
    height: 85,
  },
  selectedLanguage: {
    color: theme.palette.primary.white,
    textTransform: 'capitalize',
  },
});
