export default (theme) => ({
  root: {
    borderRadius: 24,
  },
  dialogContent: {
    padding: '24px !important',
  },
  confirmBtn: {
    marginRight: '15px !important',
  },
  title: {
    textTransform: 'none !important',
  },
});
