import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RestoreIcon from '@material-ui/icons/Restore';
import ChatIcon from '@material-ui/icons/Chat';
import { FolderNav } from '@orijinworks/frontend-commons';

import useVoiceCallHook from './useVoiceCallHook';
import styles from './voice-call.style';
import { ContactsCalling, ContactsRequests, VoiceIdRegistration } from './../../components';
import withMeetingProvider from '../../../../core/components/MeetingProvider/withMeetingProvider';
import { useDocumentTitleHook } from '../../../../v2/shared';
import CallCenterScheduling from '../../components/call-center-scheduling/call-center-scheduling';
import useScheduleWrapperHook from '../../../../shared/components/schedule-wrapper/useScheduleWrapperHook';
import { Constant } from '../../../../shared';
import GroupScheduleBanner from '../../components/group-schedule-banner/group-schedule-banner';
import VoiceAuthenticationBanner from '../../components/voice-authentication-banner/voice-authentication-banner';
import { store } from '../../../../core/store';
import useUnleashHook from '../../../../core/components/unleash/useUnleashHook';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import CallUsageBanner from '../../components/call-history/components/call-usage-banner/call-usage-banner';
import useCallUsageBannerHook from '../../components/call-history/components/call-usage-banner/useCallUsageBannerHook';
import useVoiceIdRegistrationHook from '../../components/voice-id-registration/useVoiceIdRegistrationHook';
import { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Permission } from '../../../../core';
import CallHistory from '../../components/call-history/container/call-history/call-history';

const VoiceCall = (props) => {
  const { classes } = props;
  const { tabActive, TAB_VALUES, handletabActive, approvedContacts, voiceIDRegistrationRequired } = useVoiceCallHook(props);
  const { profileStatus, getLearnerVoiceProfile } = useVoiceIdRegistrationHook();
  const { app } = store.getState();
  // Hooks
  const { isLoading, isClosed, handlePollingInterval, schedule, groupSchedule, isGroupScheduleClosed } =
    useScheduleWrapperHook(Constant.FEATURES_ENUM.VOICE_CALLS_SCHEDULE);
  const {
    maxCallDurationPerCall,
    maxCallDurationPerDay,
    consumedCallDurationPerDay,
    remainingCallDurationPerDay,
    bannerType,
    isLoading: isBannerLoading,
    updateCallUsages,
  } = useCallUsageBannerHook();

  const { isFlagEnabled: isVoiceGroupScheduleEnabled } = useUnleashHook(
    UnleashService.FLAGS.VOICE_GROUP_SCHEDULE,
    app.userDetail.userName,
    app.userDetail.userId
  );

  useDocumentTitleHook('page.title.voice-calls');

  useEffect(() => {
    if (tabActive === TAB_VALUES.CallHistory) {
      updateCallUsages();
    }
    getLearnerVoiceProfile();
    window.scrollTo(0, 0);
  }, [tabActive, profileStatus]);

  const renderVoiceAuthenticationBanner = (showCreateButton) => {
    return (
      <VoiceAuthenticationBanner
        showCreateButton={showCreateButton}
        handletabActive={handletabActive}
        TAB_VALUES={TAB_VALUES}
      />
    );
  };

  // Helper functions
  const renderTabContent = (tabActive) => {
    switch (tabActive) {
      case TAB_VALUES.Contacts:
        return (
          <>
            <ContactsCalling
              approvedContacts={approvedContacts}
              isVoiceAuthenticationEnabled={voiceIDRegistrationRequired}
              isClosed={
                isVoiceGroupScheduleEnabled() ? bannerType === 'CALL_TIME_LIMIT_REACHED' || isGroupScheduleClosed : isClosed
              }
              handletabActive={handletabActive}
              TAB_VALUES={TAB_VALUES}
              handlePollingInterval={handlePollingInterval}
              isVoiceIDRegistrationRequired={
                voiceIDRegistrationRequired && profileStatus !== Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED
              }
            />
          </>
        );
      case TAB_VALUES.Connections:
        return (
          <>
            <ContactsRequests
              handletabActive={handletabActive}
              TAB_VALUES={TAB_VALUES}
              isVoiceIDRegistrationRequired={
                voiceIDRegistrationRequired && profileStatus !== Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED
              }
            />
          </>
        );
      case TAB_VALUES.CallHistory:
        return (
          <>
            {voiceIDRegistrationRequired && profileStatus !== Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED && (
              <Grid item sm={12}>
                <Box mb={3}>{renderVoiceAuthenticationBanner(true)}</Box>
              </Grid>
            )}
            {groupSchedule && groupSchedule.length > 0 && (
              <CallUsageBanner
                maxCallDurationPerCall={maxCallDurationPerCall}
                maxCallDurationPerDay={maxCallDurationPerDay}
                consumedCallDurationPerDay={consumedCallDurationPerDay}
                remainingCallDurationPerDay={remainingCallDurationPerDay}
                isLoading={isBannerLoading}
                bannerType={bannerType}
                tabActive={tabActive}
              />
            )}
            <CallHistory />
          </>
        );
      case TAB_VALUES.VoiceIdRegistration:
        return (
          <>
            {voiceIDRegistrationRequired && profileStatus !== Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED && (
              <Grid item sm={12}>
                <Box mb={3}>{renderVoiceAuthenticationBanner(false)}</Box>
              </Grid>
            )}
            <VoiceIdRegistration handletabActive={handletabActive} TAB_VALUES={TAB_VALUES} />
          </>
        );
      default:
        return null;
    }
  };

  const renderVoiceGroupSchedule = (tabActive, bannerType) => {
    const excludedTabs = new Set([TAB_VALUES.CallHistory, TAB_VALUES.Connections, TAB_VALUES.VoiceIdRegistration]);

    if (excludedTabs.has(tabActive)) return null;

    const isGroupSchedule = isVoiceGroupScheduleEnabled();
    const currentSchedule = isGroupSchedule ? groupSchedule : schedule;
    const currentIsClosed = isGroupSchedule ? isGroupScheduleClosed : isClosed;

    const shouldRenderSchedule =
      (voiceIDRegistrationRequired &&
        currentSchedule.length > 0 &&
        profileStatus === Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED) ||
      (!isLoading && currentSchedule.length > 0 && !voiceIDRegistrationRequired);

    if (!shouldRenderSchedule) return null;

    return (
      <Grid item sm={12} key={isGroupSchedule ? 'groupSchedule' : 'schedule'}>
        <CallCenterScheduling
          schedule={currentSchedule}
          isClosed={currentIsClosed}
          isCallTimeLimitReached={bannerType === 'CALL_TIME_LIMIT_REACHED'}
        />
      </Grid>
    );
  };

  const renderGroupScheduleBanner = () => {
    return <GroupScheduleBanner />;
  };

  const shouldRenderCallingUnavailableBanner = () => {
    return (
      (!groupSchedule || groupSchedule?.length <= 0) &&
      tabActive !== TAB_VALUES.Connections &&
      isVoiceGroupScheduleEnabled() &&
      (profileStatus === Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED || !voiceIDRegistrationRequired)
    );
  };
  const renderSkeletons = () => {
    return Array(5)
      .fill()
      .map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          animation="wave"
          width={'100%'}
          height={100}
          style={{ marginBottom: '16px' }}
        />
      ));
  };

  const renderTabs = () => {
    if (!voiceIDRegistrationRequired) {
      return [
        { label: TAB_VALUES.Contacts, value: TAB_VALUES.Contacts, component: () => null, Icon: PhoneInTalkIcon },
        { label: TAB_VALUES.CallHistory, value: TAB_VALUES.CallHistory, component: () => null, Icon: RestoreIcon },
        { label: TAB_VALUES.Connections, value: TAB_VALUES.Connections, component: () => null, Icon: PersonAddIcon },
      ];
    } else {
      return [
        { label: TAB_VALUES.Contacts, value: TAB_VALUES.Contacts, component: () => null, Icon: PhoneInTalkIcon },
        { label: TAB_VALUES.CallHistory, value: TAB_VALUES.CallHistory, component: () => null, Icon: RestoreIcon },
        { label: TAB_VALUES.Connections, value: TAB_VALUES.Connections, component: () => null, Icon: PersonAddIcon },
        {
          label: TAB_VALUES.VoiceIdRegistration,
          value: TAB_VALUES.VoiceIdRegistration,
          component: () => null,
          Icon: ChatIcon,
        },
      ];
    }
  };

  // Render
  return (
    <Permission {...props}>
      <Box display="flex" flexDirection="column" className={classes.tabContainer}>
        <FolderNav
          defaultActiveTab={tabActive}
          onTabChange={handletabActive}
          color="var(--blue-main)"
          tabFontColor="var(--blue-main)"
          tabs={renderTabs()}
        >
          <Box className={classes.tabBody}>
            {isBannerLoading || isLoading ? (
              renderSkeletons()
            ) : (
              <>
                {shouldRenderCallingUnavailableBanner()
                  ? renderGroupScheduleBanner()
                  : renderVoiceGroupSchedule(tabActive, bannerType)}
                {renderTabContent(tabActive)}
              </>
            )}
          </Box>
        </FolderNav>
      </Box>
    </Permission>
  );
};

export default withMeetingProvider(withStyles(styles)(VoiceCall));
