import React from 'react';
import { withStyles, Box, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { BookmarkIcon, Constant, TranscriptIcon } from '../../../../shared';

import styles from './quick-links.style';
import { FormattedMessage, useIntl } from 'react-intl';

const QuickLinks = (props) => {
  const { classes, openTranscriptModal, openBookmarks } = props;
  const intl = useIntl();

  const linksData = [
    {
      key: 'quick-links-item-see-bookmarks',
      title: <FormattedMessage id="bookmarks" />,
      icon: <BookmarkIcon />,
      onClick: openBookmarks,
      trackingType: Constant.TRACKING_TYPES.CAREER_BOOKMARK,
      trackingId: 'view-bookmarks',
      label: intl.formatMessage({ id: 'seeBookmarks' }),
    },
    {
      key: 'quick-links-item-view-transcript',
      title: <FormattedMessage id="viewTranscript" />,
      icon: <TranscriptIcon />,
      onClick: openTranscriptModal,
      trackingType: Constant.TRACKING_TYPES.PROGRESS_REPORT,
      trackingId: 'view-transcript',
      label: intl.formatMessage({ id: 'viewCompletionReport' }),
    },
  ];

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.mainTitle}>
        <FormattedMessage id="quickLinks" />
      </Typography>

      {linksData.map((item) => (
        <Button
          key={item.key}
          variant="contained"
          className={classes.actionButton}
          startIcon={item.icon}
          size="large"
          fullWidth
          classes={{ endIcon: classes.actionIcon }}
          endIcon={<ChevronRightIcon color="secondary" />}
          onClick={item.onClick}
          tracking-type={item.trackingType}
          tracking-id={item.trackingId}
          aria-label={item.label}
          role="link"
        >
          <Typography variant="h4" color="secondary" className={classes.linkTitle}>
            {item.title}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default withStyles(styles)(QuickLinks);
