import React, { Component, Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Collapse, withStyles, CircularProgress } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import { MessagingService } from './../../services/';
import { Constant } from '../../../../shared/services';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import style from './message-sidebar.styles';
import { AnnouncementIcon, DirectMessageIcon } from '../../../../shared';

const ContactItem = React.lazy(() => import('../contact-item'));
const MenuButton = React.lazy(() => import('../menu-button'));

class Sidebar extends Component {
  state = {
    activeThread: null,
    expandToggle: true,
    contactsOrderBy: 'recent_message',
  };

  contactsPollCntr = 0;

  _service = new MessagingService();

  async componentDidMount() {
    const { contactsOrderBy } = this.state;
    const { isV2FlagEnabled } = this.props;
    const contacts = await this._service.fetchContacts(isV2FlagEnabled, contactsOrderBy);
    if (contacts.length > 0) {
      this.props.onSelect(contacts[0]);
    }
    this.pollGetContacts(Constant.MESSAGE_POLL_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  getThreads = async (noLoader) => {
    const { contactsOrderBy } = this.state;
    const { isV2FlagEnabled } = this.props;
    const contacts = await this._service.fetchContacts(isV2FlagEnabled, contactsOrderBy);
    this.props.setContacts(contacts);
  };

  getContacts = (noLoader) => {
    const { isV2FlagEnabled } = this.props;
    this.contactsPollCntr++;
    this._service.fetchContacts(isV2FlagEnabled).then((_successLog) => {
      this.props.setContacts(_successLog);
      this.contactsPollCntr--;
    });
  };

  sortContacts = async (orderBy) => {
    const { activeThread } = this.state;
    if (activeThread === 'messages') {
      this.setState({ contactsOrderBy: orderBy }, () => {
        this.getThreads();
      });
    }
  };

  pollGetContacts = (interval) => {
    this.getThreads();
    this._interval = setInterval(() => {
      if (this.contactsPollCntr === 0) {
        this.getContacts(true);
      }
    }, interval);
  };

  handleExpandToggle = () => {
    const { expandToggle } = this.state;
    this.setState({ expandToggle: !expandToggle });
  };

  onThreadClick = (threadName) => () => {
    this.setState({
      activeThread: threadName,
    });
    if (threadName === 'announcement') {
      return this.props.onAnnouncement();
    } else return '';
  };
  threadList = [
    {
      name: 'announcement',
      value: <FormattedMessage id="announcement" />,
      icon: <AnnouncementIcon />,
    },
    {
      name: 'messages',
      value: <FormattedMessage id="directMessages" />,
      listName: 'contacts',
      menuItems: {
        recent: 'recent_message',
        name: 'contact_name',
      },
      icon: <DirectMessageIcon />,
    },
  ];

  render() {
    const { classes, onSelect, selectedContact, unreadMessagesCount, announcementCount } = this.props;
    const { activeThread } = this.state;

    return (
      <div className={classes.sideBar}>
        <Suspense fallback={<CircularProgress />}>
          {this.threadList.map((thread) => {
            return (
              <Fragment key={thread.name}>
                <div
                  onClick={this.onThreadClick(thread.name)}
                  className={activeThread === thread.name ? classes.activeClass : classes.sideBarContainer}
                >
                  {thread.name === 'messages' ? (
                    <>
                      <Box
                        display="block"
                        className={`${classes.badge} ${unreadMessagesCount ? classes.badgeColor : ''}`}
                      ></Box>
                      <span className={classes.icon}>{thread.icon}</span>
                      <div className={classes.sideBarItem}>{thread.value}</div>
                      <div className={classes.expandbtn} onClick={this.handleExpandToggle}>
                        {' '}
                        {this.state.expandToggle ? (
                          <ExpandLess className={classes.expandCollapse} />
                        ) : (
                          <ExpandMore className={classes.expandCollapse} />
                        )}
                      </div>
                      <Box marginLeft="auto">
                        <MenuButton
                          handleMenuItemClick={this.sortContacts}
                          icon={<MoreVertIcon />}
                          menuItems={thread.menuItems}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* for announcement */}
                      <Box
                        display="block"
                        className={`${classes.badge} ${announcementCount ? classes.badgeColor : ''}`}
                      ></Box>
                      <span className={classes.icon}>{thread.icon}</span>
                      <div className={classes.sideBarItem}>{thread.value}</div>
                    </>
                  )}
                  <Box marginLeft="auto" className={classes.countStyling}>
                    {thread.name === 'announcement' ? announcementCount || '' : unreadMessagesCount || ''}
                  </Box>
                </div>
                <>
                  <Collapse in={this.state.expandToggle} timeout="auto" unmountOnExit>
                    {thread.name === 'messages' && (
                      <div className={classes.sideBarItem}>
                        <p className={classes.messageTag}>
                          <FormattedMessage id="newMessage" />
                        </p>
                        <span id="new-msg-btn" onClick={this.props.onDirectMessage} className={classes.newMessageIcon}>
                          <AddIcon className={classes.icon} />
                        </span>
                      </div>
                    )}

                    {(this.props[thread.listName] || []).map((contact) => (
                      <ContactItem
                        key={contact.contactId}
                        contact={contact}
                        onSelect={onSelect}
                        selected={selectedContact ? selectedContact.contactId === contact.contactId : null}
                      />
                    ))}
                  </Collapse>
                </>
              </Fragment>
            );
          })}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return {
    unreadMessagesCount: app.unreadMessagesCount,
    announcementCount: app.announcementCount,
  };
};

export default withStyles(style)(connect(mapStateToProps, null)(Sidebar));
