import { useEffect, useState } from 'react';
import { getCareerPaths } from '../../services/career-paths.service';

const useCareerPathsHook = (props) => {
  const [careerPaths, setCareerPaths] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getCareerPaths();
        if (response?.data) {
          const { payload } = response.data;
          if (payload?.length) {
            setCareerPaths(payload);
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [props.selectedLocale]);

  const goToDetailsPage = (id) => {
    props.history.push(`/career-paths/${id}/details`);
  };

  return {
    careerPaths,
    goToDetailsPage,
  };
};

export default useCareerPathsHook;
