import React from 'react';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';

/**
 * @name withMeetingProvider
 * @param {component} WrappedComponent
 * @description - HOC to wrap meeting provider  to a component
 * @returns WrappedComponent with meeting provider
 */

const withMeetingProvider = (WrappedComponent) => {
  const HOC = (props) => {
    return (
      <MeetingProvider>
        <WrappedComponent {...props} />
      </MeetingProvider>
    );
  };

  return HOC;
};

export default withMeetingProvider;
