import React from 'react';

const GraduationCapIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 43.974 28.362">
      <path
        id="graduation-cap-solid"
        d="M21.972,32a5.236,5.236,0,0,0-1.628.26L1.071,38.671a1.485,1.485,0,0,0,0,2.861l3.978,1.323a9.83,9.83,0,0,0-1.766,5.6v1.779a11.3,11.3,0,0,1-1.532,5.114,16.564,16.564,0,0,1-1.546,2.38.955.955,0,0,0-.158.949,1.068,1.068,0,0,0,.77.646l4.4,1.013a1.176,1.176,0,0,0,.852-.127.994.994,0,0,0,.488-.658,15.066,15.066,0,0,0-.144-6.88,13.223,13.223,0,0,0-.928-2.658V48.451A7.991,7.991,0,0,1,7.4,43.292a8.393,8.393,0,0,1,3.38-2.26l10.787-3.905a1.13,1.13,0,0,1,1.422.57.987.987,0,0,1-.618,1.31L11.583,42.912a6.364,6.364,0,0,0-2.212,1.367l10.966,3.646a5.238,5.238,0,0,0,3.257,0l19.279-6.393a1.485,1.485,0,0,0,0-2.861L23.6,32.26A5.236,5.236,0,0,0,21.972,32ZM8.78,55.8c0,2.234,5.909,4.557,13.192,4.557S35.164,58.034,35.164,55.8l-1.051-9.2-9.77,3.253a7.587,7.587,0,0,1-4.741,0L9.831,46.6Z"
        transform="translate(0.016 -32)"
        fill={fill}
      />
    </svg>
  );
};

GraduationCapIcon.defaultProps = {
  width: '43.974',
  height: '28.362',
  fill: 'inherit',
};

export default GraduationCapIcon;
