import React from 'react';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { useDocumentTitleHook } from '../../../../v2/shared';
import CareerProfileV1 from './career-profile-v1';
import { CareerProfile as CareerProfileV2 } from '../../../../v2/content';

const CareerProfile = (props) => {
  const { unleashProps } = props;
  useDocumentTitleHook('page.title.career-profile');

  if (unleashProps.isFlagEnabled()) {
    return <CareerProfileV2 {...props} />;
  } else {
    return <CareerProfileV1 {...props} />;
  }
};

export default withUnleash(CareerProfile, UnleashService.FLAGS.CAREER_VIDEOS_INTEGRATION);
