import { textAlign } from 'styled-system';

export default (theme) => ({
  root: {
    borderRadius: '10px',
    backgroundColor: 'white',
    width: '100%',
    padding: 16,
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
    marginBottom: 12,
    cursor: 'pointer',
    position: 'relative',
    textTransform: 'none',
    textAlign: 'left',
    '& span': {
      justifyContent: 'left',
      display: 'inline-block',
    },
  },
  bookmarkIcon: {
    position: 'absolute',
    top: 0,
    right: 16,
    height: '100%',
    pointer: 'cursor',
    fill: theme.palette.blue.main,
    '& > svg': {
      width: 40,
      height: 40,
    },
  },

  careerTitle: {
    ...theme.typography.t16,
    lineHeight: '24px',
    color: theme.palette.aqua.extraDeep,
    '-webkit-line-clamp': 3,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-word',
    fontWeight: 700,
    '& svg': {
      '&:first-child': {
        marginLeft: 10,
      },
      marginRight: 5,
    },
  },
  careerFitContainer: {
    marginLeft: 'auto',
    alignItems: 'center',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  careerFitText: {
    ...theme.typography.t16,
    fontFamily: 'Rubik',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  careerSubtitle: {
    ...theme.typography.t16,
    color: theme.palette.text.sub,
    '-webkit-line-clamp': 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    workBreak: 'break-word',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 16,
  },
  zoneBadge: {
    padding: '6px 12px',
    marginRight: 12,
    borderRadius: 4,
    width: 81,
    minWidth: 81,
    maxWidth: 81,
  },
  zoneColor1: {
    backgroundColor: theme.palette.jobZone.colors.zone1,
  },
  zoneColor2: {
    backgroundColor: theme.palette.jobZone.colors.zone2,
  },
  zoneColor3: {
    backgroundColor: theme.palette.jobZone.colors.zone3,
  },
  zoneColor4: {
    backgroundColor: theme.palette.jobZone.colors.zone4,
  },
  zoneColor5: {
    backgroundColor: theme.palette.jobZone.colors.zone5,
  },
  careerZone: {
    ...theme.typography.t16,
    color: 'white',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'capitalize',
    textAlign: 'center',
    lineHeight: 1,
  },
  medianPayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    marginTop: 5,
  },
  medianPayText: {
    ...theme.typography.t14,
    fontFamily: 'Rubik',
    color: theme.palette.greyScale[110],
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  medianPayAmount: {
    ...theme.typography.t14,
    fontFamily: 'Rubik',
    marginLeft: 16,
    fontWeight: 500,
    color: theme.palette.cerulean.cerulean20,
  },
});
