import React from 'react';

const PinInWhiteBoxIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 57 56" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 0.763672H56.5V50.2637C56.5 52.749 54.4853 54.7637 52 54.7637H5C2.51472 54.7637 0.5 52.749 0.5 50.2637V0.763672Z"
        fill="white"
        stroke="#DFE6E9"
      />
      <path
        d="M19.5888 18.9288C19.5888 18.0726 20.2806 17.3809 21.1368 17.3809H33.5206C34.3768 17.3809 35.0686 18.0726 35.0686 18.9288C35.0686 19.7851 34.3768 20.4768 33.5206 20.4768H32.0936L32.645 27.6459C34.4204 28.6085 35.8232 30.2194 36.4908 32.2269L36.5391 32.372C36.6988 32.8461 36.6165 33.3637 36.3263 33.7652C36.036 34.1667 35.5668 34.4086 35.0686 34.4086H19.5888C19.0906 34.4086 18.6262 34.1715 18.3311 33.7652C18.036 33.3588 17.9586 32.8412 18.1183 32.372L18.1666 32.2269C18.8342 30.2194 20.237 28.6085 22.0124 27.6459L22.5638 20.4768H21.1368C20.2806 20.4768 19.5888 19.7851 19.5888 18.9288ZM25.7807 35.9565H28.8767V40.6005C28.8767 41.4567 28.1849 42.1484 27.3287 42.1484C26.4725 42.1484 25.7807 41.4567 25.7807 40.6005V35.9565Z"
        fill={fill}
      />
    </svg>
  );
};

PinInWhiteBoxIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default PinInWhiteBoxIcon;
