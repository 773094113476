import React from 'react';
import { Box, Typography, Button, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { VoiceChatIcon } from '../../../../shared';
import styles from './voice-authentication-banner.style';
import { FormattedMessage } from 'react-intl';
import CallCenterSchedullingModal from '../call-center-scheduling-modal/call-center-schedulling-modal';
import useVoiceAuthenticationBannerHook from './useVoiceAuthenticationBannerHook';
import useCallUsageBannerHook from '../../components/call-history/components/call-usage-banner/useCallUsageBannerHook';
import { Constant } from '../../../../shared';
import useScheduleWrapperHook from '../../../../shared/components/schedule-wrapper/useScheduleWrapperHook';

const VoiceAuthenticationBanner = ({ classes, showCreateButton, handletabActive, TAB_VALUES }) => {
  const { openModal, closeModal, callCenterSchedullingModalRef } = useVoiceAuthenticationBannerHook();
  const { groupSchedule, isGroupScheduleClosed } = useScheduleWrapperHook(Constant.FEATURES_ENUM.VOICE_CALLS_SCHEDULE);

  const { bannerType } = useCallUsageBannerHook();

  return (
    <Box className={classes.main}>
      <Box className={classes.headingSection}>
        <VoiceChatIcon width="32px" height="32px" />
        <Typography variant="h6" className={classes.heading}>
          <FormattedMessage id="voiceAuthenticationBanner.header" />
        </Typography>
      </Box>

      <Box className={classes.textContent}>
        <Typography variant="body1" className={classes.bannerMessage}>
          <FormattedMessage id="voiceAuthenticationBanner.description" />
        </Typography>
        <Typography variant="body2" className={classes.additionalMessage}>
          <FormattedMessage id="voiceAuthenticationBanner.additionalInfo" />
          <Link component="button" type="button" className={classes.scheduleLink} onClick={openModal}>
            <FormattedMessage id="voiceAuthenticationBanner.viewSchedule" />
          </Link>
        </Typography>
        {showCreateButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.createVoiceIdButton}
            onClick={(event) => handletabActive(event, TAB_VALUES.VoiceIdRegistration)}
          >
            <FormattedMessage id="voiceAuthenticationBanner.createVoiceId" />
          </Button>
        )}
      </Box>

      <CallCenterSchedullingModal
        ref={callCenterSchedullingModalRef}
        isOpen={false}
        onClose={closeModal}
        contactName={''}
        onContinue={() => {}}
        schedule={groupSchedule}
        isClosed={isGroupScheduleClosed}
        isCallTimeLimitReached={bannerType === 'CALL_TIME_LIMIT_REACHED'}
      />
    </Box>
  );
};

export default withStyles(styles)(VoiceAuthenticationBanner);
