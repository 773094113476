import React, { useEffect } from 'react';
import { CheckmarkIcon } from '../../../../../shared/icons';
import { ProgramConstants } from '../../../../../content/programs/services/programs.service';
import { EllipsisCircleIcon } from '@orijinworks/frontend-commons';

const useProgressHook = ({ progress }) => {
  const defaultProgress = ProgramConstants.LEARNING_MODULE_PROGRESS.IN_PROGRESS;
  progress = progress ?? defaultProgress;

  const [icon, setIcon] = React.useState(<EllipsisCircleIcon size={24} fill="inherit" />);
  const [cssClass, setCssClass] = React.useState('inProgress');

  useEffect(() => {
    if (progress === ProgramConstants.LEARNING_MODULE_PROGRESS.COMPLETED) {
      setIcon(<CheckmarkIcon />);
      setCssClass('completed');
    }
  }, [progress]);

  return {
    progress: progress.toLowerCase(),
    icon,
    cssClass,
  };
};

export default useProgressHook;
