import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import styles from './career-compass.styles';
import { Permission } from '../../../../../core';
import { useDocumentTitleHook } from '../../../../shared';
import useCareerCompassHook from './useCareerCompassHook';
import { IntroductionGuide, ProgramSelection, ProgramDetails } from '../../components';
import { connect } from 'react-redux';

/**
 * Component that renders the Career Compass
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const CareerCompass = ({ classes, history, currentStep, selectedLocale, ...props }) => {
  useDocumentTitleHook('page.title.career-compass');
  const { programId, showIntroductionGuide, showProgramSelection, showProgramDetails, handleCurrentStepUpdate } =
    useCareerCompassHook(currentStep);

  return (
    <Permission {...props}>
      <Box className={classes.root}>
        {showIntroductionGuide() && <IntroductionGuide history={history} onContinue={handleCurrentStepUpdate} />}
        {showProgramSelection() && <ProgramSelection currentStep={currentStep} selectedLocale={selectedLocale} />}
        {showProgramDetails() && (
          <ProgramDetails
            programId={programId}
            currentStep={currentStep}
            selectedLocale={selectedLocale}
            handleCurrentStepUpdate={handleCurrentStepUpdate}
          />
        )}
      </Box>
    </Permission>
  );
};

CareerCompass.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The history object to use for navigation.
   */
  history: PropTypes.object.isRequired,
  /**
   * The current step object.
   */
  currentStep: PropTypes.object,
  /**
   * The selected locale.
   */
  selectedLocale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ locale, careerCompass }) => {
  return {
    currentStep: careerCompass.currentStep,
    selectedLocale: locale.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerCompass));
