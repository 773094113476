import React from 'react';

const CallClosedIcon = (props) => {
  const { size } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <g clip-path="url(#clip0_9366_7238)">
          <rect width="44" height="44" rx="22" fill="#EFF0F4" />
          <path
            d="M16.62 20.79C18.06 23.62 20.38 25.93 23.21 27.38L25.41 25.18C25.68 24.91 26.08 24.82 26.43 24.94C27.55 25.31 28.76 25.51 30 25.51C30.55 25.51 31 25.96 31 26.51V30C31 30.55 30.55 31 30 31C20.61 31 13 23.39 13 14C13 13.45 13.45 13 14 13H17.5C18.05 13 18.5 13.45 18.5 14C18.5 15.25 18.7 16.45 19.07 17.57C19.18 17.92 19.1 18.31 18.82 18.59L16.62 20.79Z"
            fill="#707070"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9366_7238">
          <rect width="44" height="44" rx="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
CallClosedIcon.defaultProps = {
  size: 44,
};

export default CallClosedIcon;
