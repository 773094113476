import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, useTheme, withStyles } from '@material-ui/core';
import styles from './salary-info.style';
import { FormattedMessage } from 'react-intl';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';

const SalaryInfo = ({ classes, isHourlyPay, lowerBound, median, upperBound }) => {
  const theme = useTheme();

  return (
    <>
      <Typography id="salary-info-title" gutterBottom variant="h6" className={classes.title}>
        <FormattedMessage id={isHourlyPay ? 'hourlyRate' : 'annualSalary'} />:
      </Typography>

      <Grid>
        <VictoryChart height={250} domainPadding={25} padding={{ top: 20, bottom: 50, left: 20, right: 20 }}>
          <VictoryBar
            domain={{ y: [2, 8] }}
            data={[
              { x: `$${lowerBound}`, y: 3, fill: theme.palette.blue.pale, width: 50 },
              { x: 'x', y: 5, fill: theme.palette.blue.pale, width: 50 },
              { x: 'y', y: 7, label: `$${median}`, fill: theme.palette.aqua.deep, width: 70 },
              { x: 'z', y: 5, fill: theme.palette.blue.pale, width: 50 },
              { x: `$${upperBound}`, y: 3, fill: theme.palette.blue.pale, width: 50 },
            ]}
            barWidth={({ datum }) => datum.width}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
              labels: { fontWeight: 700, fill: theme.palette.secondary.main },
            }}
          />
          <VictoryAxis
            height={50}
            style={{
              axis: { stroke: theme.palette.blue.greyed, width: '100%' },
              grid: { stroke: () => 0 },
              ticks: { size: 5 },
              tickLabels: { fill: theme.palette.greyScale[90] },
            }}
            tickFormat={(item) => (['x', 'y', 'z'].includes(item) ? ' ' : item)}
          />
        </VictoryChart>
      </Grid>
      <Typography id="salary-info-attribution" variant="body2" className={classes.attribution}>
        <FormattedMessage id="career.jobOutlook.attribution" />
      </Typography>
    </>
  );
};

SalaryInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  isHourlyPay: PropTypes.bool.isRequired,
  lowerBound: PropTypes.string.isRequired,
  median: PropTypes.string.isRequired,
  upperBound: PropTypes.string.isRequired,
};

export default withStyles(styles)(SalaryInfo);
