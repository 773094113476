import React from 'react';
import { withStyles, Box, Typography, Divider } from '@material-ui/core';

import styles from './info-card.style';
import { FormattedMessage } from 'react-intl';
import { MissingData } from '..';

const InfoCard = (props) => {
  const { classes, data, title } = props;

  return (
    <Box className={classes.root}>
      <Box>
        <Typography id={`${title}-title`} className={classes.capitalize} variant="h2">
          <FormattedMessage id={title} />
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      {data && data.length > 0 ? (
        data.map((item) => (
          <>
            <Typography gutterBottom variant="h3" color="secondary" className={classes.capitalize}>
              {item.title.name}
            </Typography>
            {item.element && item.element.length > 0 && (
              <ul>
                {item.element.map((info) => (
                  <li key={info.id}>
                    <Typography className={classes.description}>{info.name}</Typography>
                  </li>
                ))}
              </ul>
            )}
          </>
        ))
      ) : (
        <MissingData />
      )}
    </Box>
  );
};

export default withStyles(styles)(InfoCard);
