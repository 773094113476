import { Box, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './pdq-assessment-tile.style';
import { PDQAssessmentCompleted, PDQAssessmentInProgress, PDQAssessmentNotStarted } from '..';
import { Constant } from '../../../../shared';

const PDQAssessmentTile = (props) => {
  const { classes } = props;
  const isNotStarted = props.status === Constant.PDQ_ENUM.NOT_STARTED;
  const isInProgress = props.status === Constant.PDQ_ENUM.IN_PROGRESS;
  const isSubmitted = props.status === Constant.PDQ_ENUM.SUBMITTED;
  const isCompleted = props.status === Constant.PDQ_ENUM.COMPLETED;
  return (
    <Box
      className={[
        classes.thumbnailLink,
        isNotStarted ? classes.mainBackground : isCompleted ? classes.resultsBackground : classes.whiteBackground,
      ].join(' ')}
      rel="noopener noreferrer"
    >
      <Box data-cy-name="service-card" className={[classes.card, !isNotStarted ? classes.fullWidth : ''].join(' ')}>
        <Box display="flex" className={classes.cardLayout}>
          <Box
            className={classes.contentArea}
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {isNotStarted ? (
              <PDQAssessmentNotStarted {...props} />
            ) : isInProgress || isSubmitted ? (
              <PDQAssessmentInProgress {...props} />
            ) : (
              isCompleted && <PDQAssessmentCompleted {...props} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(PDQAssessmentTile);
