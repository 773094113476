export default (theme) => ({
  root: {
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: 20,
    borderRadius: '10px 10px 0 0',
    backgroundColor: theme.palette.colors.lightBlueGrey,
  },
  headerText: {
    fontWeight: 700,
  },
  contactsContainer: {
    padding: '20px 20px 0px 20px',
  },
  contactsWrapper: {
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  text: {
    color: theme.palette.text.secondary,
    marginRight: 10,
  },
});
