import React from 'react';

const CallEndedIcon = (props) => {
  const { size } = props;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={size} height={size} rx="26" fill="#E32D4E" />
        <path
          d="M34.0665 28.4398L32.1598 26.5331C32.9065 25.5598 33.5598 24.5331 34.1198 23.4398L31.1865 20.5065C30.8131 20.1331 30.7065 19.6131 30.8531 19.1465C31.3465 17.6531 31.6131 16.0531 31.6131 14.3865C31.6131 13.6531 32.2131 13.0531 32.9465 13.0531H37.6131C38.3465 13.0531 38.9465 13.6531 38.9465 14.3865C38.9465 19.6931 37.1198 24.5731 34.0665 28.4398ZM30.3065 32.1865C26.4531 35.2398 21.5731 37.0531 16.2798 37.0531C15.5465 37.0531 14.9465 36.4531 14.9465 35.7198V31.0665C14.9465 30.3331 15.5465 29.7331 16.2798 29.7331C17.9331 29.7331 19.5465 29.4665 21.0398 28.9731C21.5065 28.8131 22.0398 28.9331 22.3998 29.2931L25.3331 32.2265C26.4131 31.6665 27.4398 31.0265 28.3998 30.2798L12.7998 14.6798L14.6931 12.7998L39.1998 37.3198L37.3198 39.1998L30.3065 32.1865Z"
          fill="white"
        />
      </svg>
    </>
  );
};

CallEndedIcon.defaultProps = {
  size: 52,
};

export default CallEndedIcon;
