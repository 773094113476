import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useStyles from './no-greetings.style';

const NoGreetings = (props) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} sm={12} lg={12} className={classes.analyticsWrapper}>
      <Grid item sm={9}>
        <Box>
          <Typography id="no-progress-text" data-cy-name="no-progress-text" className={classes.noGreeting}>
            <FormattedMessage
              id="noProgress"
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                br: <br />,
              }}
            />
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={3}>
        <Box>
          <img className={classes.confettiImg} src="/assets/misc/confetti.png" alt="" />
        </Box>
      </Grid>
    </Grid>
  );
};
export default NoGreetings;
