export default (theme) => ({
  iconWrapper: {
    padding: theme.spacing(2),
    marginLeft: '50px',
  },
  archiveIcon: {
    cursor: 'pointer',
  },
  archiveAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
  },
});
