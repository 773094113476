export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0px',
  },
  line: {
    height: 1,
    backgroundColor: theme.palette.text.secondary,
    flex: 1,
  },
  text: {
    color: theme.palette.text.secondary,
    margin: '0px 10px',
    textTransform: 'capitalize',
  },
});
