import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, IconButton, Typography, withStyles } from '@material-ui/core';
import { CloseIcon } from '@orijinworks/frontend-commons';
import styles from './privacy-policy.styles';
import { PRIVACY_POLICY_TRANSLATIONS } from '../terms-of-service/terms-of-service.helper';

/**
 * Component to display the Privacy Policy modal
 *
 * @component
 * @param {Object} props.classes - the classes object for styling
 * @param {boolean} props.open - the open state of the modal
 * @param {string} props.selectedLanguage - the selected language
 * @param {Function} props.onClose - the function to close the modal
 */
const PrivacyPolicy = ({ classes, open, selectedLanguage, onClose }) => {
  const TRANSLATIONS = PRIVACY_POLICY_TRANSLATIONS[selectedLanguage];

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose} aria-labelledby="Terms of Service" classes={{ paper: classes.root }}>
      <Box className={classes.topBarContainer}>
        <Box className={classes.topBarWrapper}>
          <Typography
            id="privacy-policy-modal-title"
            variant="h1"
            className={classes.modalTitle}
            dangerouslySetInnerHTML={{ __html: TRANSLATIONS.modalTitle() }}
          />
        </Box>
        <IconButton id="privacy-policy-close-icon" className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography
          id="privacy-policy-title"
          variant="h5"
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: TRANSLATIONS.title() }}
        />
        <Box className={classes.content}>
          <Typography
            id="privacy-policy-effective-date"
            className={classes.effectiveDate}
            dangerouslySetInnerHTML={{ __html: TRANSLATIONS.effectiveDate() }}
          />
          <Box id="privacy-policy-content" dangerouslySetInnerHTML={{ __html: TRANSLATIONS.content() }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

PrivacyPolicy.propTypes = {
  /**
   * The classes object for styling
   */
  classes: PropTypes.object.isRequired,
  /**
   * The open state of the modal
   */
  open: PropTypes.bool.isRequired,
  /**
   * The selected language
   */
  selectedLanguage: PropTypes.string.isRequired,
  /**
   * The function to close the modal
   */
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(PrivacyPolicy);
