import React from 'react';
import { Box, Typography, Fab, IconButton } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { withStyles } from '@material-ui/core/styles';
import styles from './voice-recording.style';
import useVoiceRecordingHook from './useVoiceRecordingHook';

const VoiceRecording = ({ classes, setProfileStatus }) => {
  const {
    recordingCountDown,
    isRecordingCountDownStarted,
    startRecordingCountDown,
    formatTime,
    recordingTime,
    isRecording,
    cancelRecording,
    recordingDone,
    submitRecording,
    recordingSubmiting,
    reRecord,
  } = useVoiceRecordingHook(setProfileStatus);

  const renderRecordingFab = () => {
    switch (true) {
      case isRecordingCountDownStarted:
        return (
          <Typography variant="h1" className={classes.voiceRecordingCountDown} data-testid="countdown-text">
            {recordingCountDown}
          </Typography>
        );

      case isRecording:
        return (
          <IconButton onClick={cancelRecording} data-testid="stop-button">
            <StopIcon className={classes.stopRecordingIcon} />
          </IconButton>
        );

      case recordingDone && !recordingSubmiting:
        return (
          <IconButton onClick={submitRecording} data-testid="send-button">
            <SendIcon className={classes.sendIcon} />
          </IconButton>
        );

      case recordingSubmiting:
        return (
          <IconButton disabled data-testid="submit-progress">
            <HourglassEmptyIcon className={classes.hourglassIcon} />
          </IconButton>
        );

      default:
        return <MicIcon className={classes.startRecordingIcon} data-testid="mic-icon" />;
    }
  };

  const renderStatusText = () => {
    switch (true) {
      case isRecordingCountDownStarted:
        return 'Speak after the tone';

      case isRecording:
        return 'Tap to Stop and Restart';

      case recordingDone:
        return 'Submit Voice ID';

      case recordingSubmiting:
        return 'Submitting...';

      default:
        return 'Tap to Record';
    }
  };

  return (
    <Box className={classes.root}>
      {!recordingSubmiting ? (
        <img
          id="voice-recording-vector"
          alt="Voice recording Vector"
          src="/assets/img/voice_recording.png"
          className={classes.voiceRecordingVector}
          data-testid="voice-recording-img"
        />
      ) : (
        <Typography variant="body" className={classes.isRecordingInProgressText}>
          Please wait...Do not log out or close the tablet while your voice recording is saving.
        </Typography>
      )}

      <Box className={classes.voiceRecordingTimerContainer}>
        <Typography
          variant="overline"
          className={isRecording ? classes.voiceRecordingTime : classes.voiceRecordingTimeDone}
          data-testid="recording-time"
        >
          {formatTime(recordingTime)}
        </Typography>
        <Typography variant="overline" className={classes.voiceRecordingTimeDivider}>
          /
        </Typography>
        <Typography variant="overline" className={classes.voiceRecordingTimeTotal} data-testid="total-time">
          {formatTime(30)}
        </Typography>
      </Box>

      <Box className={classes.recordingContainer}>
        <Fab
          color="primary"
          aria-label="record"
          className={isRecording ? classes.recordingStarted : classes.startRecordingButton}
          onClick={startRecordingCountDown}
          disabled={isRecordingCountDownStarted}
          data-testid="record-button"
        >
          {renderRecordingFab()}
        </Fab>

        <IconButton
          aria-label="rerecord"
          size="medium"
          className={classes.refreshButton}
          disabled={!recordingDone}
          onClick={reRecord}
          data-testid="rerecord-button"
        >
          <RefreshIcon className={recordingDone ? classes.refreshIconWhite : classes.refreshIcon} />
        </IconButton>
      </Box>

      <Typography variant="h6" className={classes.refreshText} data-testid="rerecord-text">
        Re-Record
      </Typography>

      <Typography variant="h6" className={classes.tapToRecord} data-testid="tap-to-record">
        {renderStatusText()}
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(VoiceRecording);
