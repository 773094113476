export default (theme) => ({
  root: {
    backgroundColor: 'white',
    marginTop: 32,
    borderRadius: 10,
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  },
  overviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 50px 16px 50px',
    alignItems: 'center',
  },
  modulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 50px 32px 50px',
  },
  launchBtn: {
    whiteSpace: 'nowrap',
  },
  description: {
    whiteSpace: 'break-spaces',
  },
});
