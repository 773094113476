import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import styles from './onboarding.style';
import { Dialog, DialogContent, Grid } from '@material-ui/core';

import { Introduction, SplashScreen } from '../../components';
import { OnboardingService } from '../../services/onboarding.service';
import { Constant } from '../../../../shared';

export class Onboarding extends React.Component {
  _service = new OnboardingService();
  constructor(props) {
    super(props);
    this.state = {
      getStarted: false,
      page: 0,
    };
  }

  onContinue = () => {
    this.setState({
      getStarted: true,
      page: 1,
    });
  };

  onNext = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1,
      };
    });
  };

  onPrev = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: prevState.page - 1,
      };
    });
  };

  getPropValues = () => {
    const { page } = this.state;
    let imageUrl = '',
      title = '',
      description = '';

    switch (page) {
      case 1:
        imageUrl = 'https://content.connectedcorrections.com/assets/img/Key.webp';
        title = 'onboarding.splash.1.title';
        description = 'onboarding.splash.1.description';
        break;
      case 2:
        imageUrl = 'https://content.connectedcorrections.com/assets/img/Working-People.webp';
        title = 'onboarding.splash.2.title';
        description = 'onboarding.splash.2.description';
        break;
      case 3:
        imageUrl = 'https://content.connectedcorrections.com/assets/img/Graduate.webp';
        title = 'onboarding.splash.3.title';
        description = 'onboarding.splash.3.description';
        break;
      case 4:
        imageUrl = 'https://content.connectedcorrections.com/assets/img/Cheering-Group.webp';
        title = 'onboarding.splash.4.title';
        description = 'onboarding.splash.4.description';
        break;
      default:
    }

    return {
      imageUrl,
      title: <FormattedMessage id={title} />,
      description: (
        <FormattedMessage
          id={description}
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    };
  };

  saveOnboardingStatus = async () => {
    this.setOnboardingStatus(Constant.ONBOARDING_STATUS.COMPLETED);
  };

  skipOnboarding = () => {
    this.setOnboardingStatus(Constant.ONBOARDING_STATUS.SKIPPED);
  };

  setOnboardingStatus = async (status) => {
    try {
      await this._service.setOnboardingStatus(status.toLowerCase());
      this.props.hideOnboarding();
    } catch (e) {}
  };

  render() {
    const { classes } = this.props;
    const { getStarted, page } = this.state;
    return (
      <Dialog
        open={true}
        fullScreen
        classes={{
          paper: getStarted ? classes.gradientBackground : classes.fullBackground,
        }}
      >
        <DialogContent classes={{ root: classes.contentRoot }}>
          <img
            id="onboarding-orijin-logo"
            className={classes.logo}
            src={`${Constant.CDN_BASE_URL}/assets/logo/Orijin-former-whitelogo.png`}
            alt="Orijin"
          />
          <Grid container sm={12} md={12} lg={12} className={classes.container}>
            <Grid item sm={12} md={6} lg={6} className={classes.wrapper}>
              {getStarted ? (
                <SplashScreen
                  onNext={page === 4 ? this.saveOnboardingStatus : this.onNext}
                  onPrev={this.onPrev}
                  showPrev={page > 1}
                  prevText={<FormattedMessage id="previous" />}
                  nextText={<FormattedMessage id={page === 4 ? 'letsBegin' : 'next'} />}
                  {...this.getPropValues()}
                  page={page}
                />
              ) : (
                <Introduction onSkip={this.skipOnboarding} onContinue={this.onContinue} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Onboarding));
