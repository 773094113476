export default (theme) => ({
  parentCard: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subtext: {
    marginTop: '1%',
    fontSize: '1rem',
    whiteSpace: 'pre-line',
  },
  voiceAuthenticationBanner: {
    marginTop: '16px',
    marginLeft: '22px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  inputField: {
    margin: theme.spacing(1),
    '& input': {
      borderRadius: '20px',
    },
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    borderRadius: '10px',
  },
  submitedContacts: {
    alignItems: 'center',
  },
  InputLabel: {
    marginLeft: '5px',
  },
  tabCard: {
    width: '-webkit-fill-available',
  },
  btnRadius: {
    borderRadius: '20px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    width: '50%',
  },
  voiceCallButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
    '& button': {
      backgroundColor: theme.palette.aqua.extraDeep,
    },
  },
  voiceCallContent: {
    width: '377px',
    marginTop: '24px',
  },
  title: {
    padding: '10px',
    fontSize: '30px',
    fontWeight: 800,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  content: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.02em',
    textAlign: 'center',
    width: '377px',
    height: '95px',
  },

  noContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4%',
    width: '403px',
    height: '44px',
    justifyContent: 'center',
  },
  InfoIconStyle: {
    color: '#061E44',
  },
  contactRow: {
    marginBottom: 10,
    borderRadius: 10,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.green.pale}`,
    marginRight: 24,
  },
  approvedContactHeader: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '29.05px',
    color: '#0F2C54',
    padding: '24px',
  },
  approvedContactContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    width: '611px',
    height: '88px',
    borderRadius: '16px',
    backgroundColor: '#F0F6FF',
    marginLeft: '18px',
  },
  approvedContactSubtext: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
    width: '256px',
  },
  addContactButton: {
    width: '180px',
    borderRadius: '100px',
    height: '40px',
    backgroundColor: '#0F48B8',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#0F48B8',
    },
  },
  addContactButtonLabel: {
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '19.36px',
    color: '#FFFFFF',
    textTransform: 'none',
  },

  contactsMonitoringText: {
    fontFamily: 'Inter',
    marginLeft: '24px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
  },

  contactContainer: {
    display: 'flex',
    width: '611px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.white,
    border: `0.5px solid ${theme.palette.primary.greyed}`,
    marginLeft: '24px',
    marginTop: '14px',
    padding: '16px',
  },
  contactInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactName: {
    fontFamily: 'Inter',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24.2px',
    color: '#0F2C54',
    marginLeft: '12px',
  },
  contactRelationship: {
    fontFamily: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: '#0078A2',
    marginLeft: '12px',
  },
  contactNumber: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
    marginLeft: '12px',
  },
  dialIconButton: {
    marginLeft: '210px',
  },
  footerContainer: {
    width: '611px',
    display: 'flex',
  },
  ErrorIconContainer: {
    marginTop: '36px',
    marginLeft: '24px',
  },
  ErrorIcon: {
    color: '#0F48B8',
    width: '24px',
    height: '24px',
  },
  ErrorTextContainer: {
    marginLeft: '8px',
    marginTop: '24px',
  },
  ErrorText: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#333333',
    fontStyle: 'italic',
  },
});
