import { Box, Card, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './getting-started.style';

const GettingStarted = (props) => {
  const { classes } = props;
  return (
    <Card className={classes.root}>
      <Box display="flex" className={classes.cardLayout}>
        <Box
          className={classes.contentArea}
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>{props.welcomeText}</Box>
        </Box>
      </Box>
    </Card>
  );
};

export default withStyles(styles)(GettingStarted);
