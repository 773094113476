import { useEffect } from 'react';
import { setMarkSelectedThreadAsRead, store } from '../../../../core';

const useAnnouncementDetailsHook = (announcementDetails) => {
  useEffect(() => {
    if (announcementDetails && announcementDetails.hasUnreadMessages) {
      store.dispatch(setMarkSelectedThreadAsRead(true));
    }
  }, [announcementDetails]);

  return {};
};

export default useAnnouncementDetailsHook;
