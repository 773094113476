const useSideMenuHook = ({ drawer, history, userDetail }) => {
  const getUsername = () => {
    return userDetail?.name || '';
  };

  const getActiveLinkItem = (path) => {
    return window.location.pathname.includes(path);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    if (drawer.open) {
      drawer.handleDrawerToggle();
    }
    history.push(path);
  };

  return {
    getUsername,
    getActiveLinkItem,
    handleClick,
  };
};

export default useSideMenuHook;
