import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './multi-launch-modules.style';
import { LearningModule } from '..';
import { isEnrolled } from '../../services/programs.service';

const MultiLaunchModules = (props) => {
  const { classes, modules, enrollmentStatus } = props;
  const [listEnd, setListEnd] = useState(false);
  const [overflowing, setOverflowing] = useState(false);
  const listRef = useRef();
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const isOverflowing = scrollHeight > clientHeight;
    setOverflowing(isOverflowing);
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setListEnd(true);
    } else {
      setListEnd(false);
    }
  };
  useEffect(() => {
    const { scrollHeight, clientHeight } = listRef.current;
    const isOverflowing = scrollHeight > clientHeight;
    if (isOverflowing) {
      setOverflowing(true);
    }
  }, []);

  return (
    <>
      <Box className={classes.overviewHeader}>
        <Box className={classes.headingWrapper}>
          <Typography id="multi-launch-program-overview-title" color="secondary" variant="h3" className={classes.boldText}>
            <FormattedMessage id="programOverview" />
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Grid
        className={
          isEnrolled(enrollmentStatus) || !overflowing
            ? classes.modulesContainerEnrolled
            : listEnd || !overflowing
            ? classes.modulesContainerEnd
            : classes.modulesContainer
        }
        ref={listRef}
        onScroll={handleScroll}
      >
        {(modules ?? []).map((module, index) => (
          <LearningModule
            key={module.id}
            id={module.id}
            index={index}
            name={module.name}
            progress={module.progress}
            module="course"
            isEnrolled={isEnrolled(enrollmentStatus)}
            showLaunchButton={isEnrolled(enrollmentStatus)}
          />
        ))}
      </Grid>
    </>
  );
};

export default withStyles(styles)(MultiLaunchModules);
