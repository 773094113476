import { useEffect, useState } from 'react';
import { CAREER_COMPASS_CONSTANTS, fetchCurrentStep } from '../../services/career-compass.service';

/**
 * Custom hook to fetch the current step and check if the user is a returning user.
 *
 * @returns {object} - Data loaded and returning user status
 */
const useCareerCompassBannerHook = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isReturningUser, setIsReturningUser] = useState(false);

  /**
   * Effect to fetch the current step and check if the user is a returning user.
   */
  useEffect(() => {
    const getCurrentStep = async () => {
      try {
        const currentStep = await fetchCurrentStep();
        setIsReturningUser(!isCurrentStepIntroduction(currentStep.name));
      } catch (error) {
        console.error('Error fetching current step', error);
      } finally {
        setIsDataLoaded(true);
      }
    };

    getCurrentStep();
  }, []);

  /**
   * Function to check if the current step is introduction.
   *
   * @param {string} currentStep - Current step name
   * @returns {boolean} - True if the current step is introduction
   */
  const isCurrentStepIntroduction = (currentStep) => {
    return currentStep === CAREER_COMPASS_CONSTANTS.STEPS.INTRODUCTION;
  };

  return { isDataLoaded, isReturningUser };
};

export default useCareerCompassBannerHook;
