import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './block-access.styles';
import { AttentionIcon } from '../../../../../shared/icons';

const BlockAccess = ({ classes, title, subtitle, message }) => {
  return (
    <Box className={classes.root}>
      <AttentionIcon />
      <Box className={classes.content}>
        {title && (
          <Typography id="block-access-title" variant="h1" className={classes.text}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography id="block-access-subtitle" variant="h3" className={classes.text}>
            {subtitle}
          </Typography>
        )}
        {message && (
          <Typography id="block-access-message" className={classes.text}>
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

BlockAccess.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles(styles)(BlockAccess);
