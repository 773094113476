import React from 'react';
import {
  withStyles,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  Popover,
  Button,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { Constant } from '../../../../shared/services';

import styles from './demographic.style';

const MenuProps = {
  variant: 'menu',
  getContentAnchorEl: null,
};

const Demographic = (props) => {
  const { classes, mode, name, questions, handleAnswerChange } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverText, setPopoverText] = React.useState('');

  const handlePopoverOpen = (event, text) => {
    setAnchorEl(event.currentTarget);
    setPopoverText(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getAnswerText = (answer, options) => {
    if (Array.isArray(answer) && answer.length > 0) {
      const selectedValues = answer.map((value) => options.find((item) => item.id === value).displayValue).join(', ');
      return selectedValues;
    } else if (Array.isArray(answer) && answer.length === 0) {
      return 'N/A';
    }
    const selectedOption = options.find((option) => option.id === answer);
    return selectedOption ? selectedOption.displayValue : 'N/A';
  };

  const getSelectedValues = (selected, all) => {
    all = [...all, { displayValue: 'Please Select', id: '-1' }];
    const selectedValues = selected.map((value) => all.find((item) => item.id === value).displayValue).join(', ');
    return selectedValues;
  };

  const handleMultiSelect = (questionId, value, maxSelection) => {
    if (value.length > 0) {
      value = value.filter((item) => item !== '-1');
      handleAnswerChange(questionId, value, maxSelection);
    } else {
      handleAnswerChange(questionId, ['-1'], maxSelection);
    }
  };

  const openPopover = Boolean(anchorEl);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography id="demographic-title" color="primary" className={classes.title}>
          <FormattedMessage id="demographicQuestions" />
        </Typography>

        <Popover
          id="demographic-helper-text-popover"
          classes={{
            paper: classes.popoverPaper,
          }}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{popoverText}</Typography>
        </Popover>

        {mode === 'view' ? (
          <>
            <Grid id="demographic-view-container" container spacing={2}>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="h4"> 1. Name </Typography>
                <Typography variant="h4" gutterBottom className={classes.disabled}>
                  {name || 'N/A'}
                </Typography>
              </Grid>
              {questions.map((question, index) => (
                <Grid item xs={6} md={6} lg={6}>
                  <Typography variant="h4">
                    {`${index + 2}. ` + question.question}
                    {question.helpText && (
                      <InfoOutlined
                        className={classes.infoIcon}
                        aria-owns={openPopover ? 'demographic-helper-text-popover' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handlePopoverOpen(e, question.helpText);
                        }}
                      />
                    )}
                  </Typography>
                  <Typography variant="h4" gutterBottom className={classes.disabled}>
                    {getAnswerText(question.answer, question.answerOptions)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Box id="demographic-edit-container" fullWidth>
            <FormControl disabled fullWidth>
              <Typography id="name" variant="h4" gutterBottom className={classes.disabled}>
                1. Name
              </Typography>
              <OutlinedInput value={name} className={classes.disabledInput} inputProps={{ 'aria-labelledby': 'name' }} />
            </FormControl>

            {questions.map((question, index) => (
              <FormControl key={question.id} fullWidth variant="outlined" className={classes.gutterTop}>
                <Typography id={question.question} variant="h4" gutterBottom={!question.helpText}>
                  {`${index + 2}. ` + question.question}
                </Typography>
                {question.helpText && <FormHelperText className={classes.helperText}>{question.helpText}</FormHelperText>}
                {question.questionType === Constant.MY_PROFILE_QUESTION_TYPES.DROPDOWN ? (
                  question.maxSelection > 1 ? (
                    <Select
                      value={question.answer && question.answer.length > 0 ? question.answer : ['-1']}
                      onChange={(e) => {
                        handleMultiSelect(question.id, e.target.value, question.maxSelection);
                      }}
                      multiple
                      renderValue={(selected) => getSelectedValues(selected, question.answerOptions)}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-labelledby': question.question }}
                    >
                      <MenuItem value="-1" disabled>
                        Please Select
                      </MenuItem>
                      {question.answerOptions.map((answerOption) => (
                        <MenuItem key={answerOption.id} value={answerOption.id}>
                          <Checkbox
                            color="primary"
                            checked={question.answer ? question.answer.indexOf(answerOption.id) > -1 : false}
                          />
                          <ListItemText primary={answerOption.displayValue} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      value={question.answer || '-1'}
                      onChange={(e) => {
                        handleAnswerChange(question.id, e.target.value, question.maxSelection);
                      }}
                      inputProps={{ 'aria-labelledby': question.question }}
                    >
                      <MenuItem value="-1" disabled>
                        Please Select
                      </MenuItem>
                      {question.answerOptions.map((answerOption) => (
                        <MenuItem key={answerOption.id} value={answerOption.id}>
                          {answerOption.displayValue}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                ) : (
                  question.questionType === Constant.MY_PROFILE_QUESTION_TYPES.SCALE && (
                    <Grid container spacing={1} className={classes.scaleContainer}>
                      {question.answerOptions.map((answerOption) => (
                        <Grid item key={answerOption.id}>
                          <Button
                            size="large"
                            className={[
                              classes.scaleOption,
                              answerOption.id === question.answer ? classes.activeScaleOption : '',
                            ].join(' ')}
                            onClick={() => {
                              handleAnswerChange(question.id, answerOption.id, question.maxSelection);
                            }}
                            aria-label={answerOption.displayValue}
                          >
                            {answerOption.displayValue}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )
                )}
              </FormControl>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Demographic);
