import { Http, setCareerCompassCurrentStep, store } from '../../../../core';

/**
 * Constants for the Career Compass module
 */
export const CAREER_COMPASS_CONSTANTS = {
  /**
   * @name STEPS
   * @description The steps for the Career Compass
   */
  STEPS: {
    INTRODUCTION: 'INTRODUCTION GUIDE',
    PROGRAM_PROGRESSION: 'PROGRAM PROGRESSION',
  },

  /**
   * @name STEP_STATE
   * @description The step states for the Career Compass
   */
  STEP_STATE: {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_COMPLETION: 'PENDING_COMPLETION',
    COMPLETED: 'COMPLETED',
  },
};

/**
 * Function to check if the given step is the program progression step
 *
 * @param {object} step - The given step
 * @returns {boolean} - The given step program progression status
 */
export const isStepProgramProgression = (step) => {
  return step?.name?.toUpperCase() === CAREER_COMPASS_CONSTANTS.STEPS.PROGRAM_PROGRESSION;
};

/**
 * Function to check if the given step is marked as in progress
 *
 * @param {object} step - The given step
 * @returns {boolean} - The given step in progress status
 */
export const isStepMarkedAsInProgress = (step) => {
  return step?.state === CAREER_COMPASS_CONSTANTS.STEP_STATE.IN_PROGRESS;
};

/**
 * Function to get the current step
 *
 * @param {object} stepData - The step data
 * @returns {object} - The current step
 */
const getCurrentStep = (stepData) => {
  const { currentStep, nextStep } = stepData;
  let step = nextStep ?? currentStep;

  if (isStepProgramProgression(currentStep)) {
    step = currentStep;
    step.nextStep = nextStep;
  }

  return step;
};

/**
 * Function to prepare the current step data
 *
 * @param {Object} stepData - The step data
 * @returns {Object} - The prepared current step data
 */
const prepareCurrentStep = (stepData) => {
  const step = getCurrentStep(stepData) ?? {};
  return {
    id: step.id,
    name: step.name?.toUpperCase(),
    state: step.state,
    title: step.title,
    description: step.description,
    userData: step.userData,
    selectedProgramId: step.userData?.programId,
    isFirstTime: step.firstTime,
    nextStep: step.nextStep,
  };
};

/**
 * Function to fetch the current step.
 * It will fetch the current step and update the store.
 *
 * @returns {Promise}
 */
export const fetchCurrentStep = async () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/career-compass/v1/current-step')
      .then((response) => {
        if (response?.data?.payload) {
          const currentStep = prepareCurrentStep(response.data.payload);
          store.dispatch(setCareerCompassCurrentStep(currentStep));
          resolve(currentStep);
        }

        resolve({});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to update the current step.
 * It will update the current step with the provided data.
 * It will update the store with the new current step.
 *
 * @param {Object} data - The data to update the current step
 * @returns {Promise}
 */
export const updateCurrentStep = async (data) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.put('/services/career-compass/v1/current-step?noLoader=true', data)
      .then((response) => {
        if (response?.data?.payload) {
          const currentStep = prepareCurrentStep(response.data.payload);
          store.dispatch(setCareerCompassCurrentStep(currentStep));
          resolve(currentStep);
        }

        resolve({});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to prepare the programs data
 *
 * @param {Array} programsData - The programs data
 * @param {boolean|undefined} completed - Filter for completed programs
 * @returns {Array} - The prepared programs data
 */
const preparePrograms = (programsData, completed) => {
  return programsData
    .filter((program) => {
      if (completed === undefined) return true;
      return completed === Boolean(program.isCompleted);
    })
    .map((program) => ({
      id: program.id,
      name: program.name,
      title: program.title,
      description: program.description,
      isCompleted: program.isCompleted || false,
    }));
};

/**
 * Function to prepare the programs recommendations data
 *
 * @param {Array} programsRecommendationData - The programs data
 * @returns {Array} - The prepared programs recommendations data
 */
export const prepareProgramsRecommendations = (programsRecommendationData) => {
  return programsRecommendationData
    .filter((program) => !program.isCompleted)
    .map((program) => ({
      id: program.id,
      name: program.name,
      title: program.title,
      thumbnail: program.thumbnail,
      description: program.description,
    }))
    .sort((a, b) => a.id - b.id);
};

/**
 * Function to fetch all available programs.
 * It will fetch the programs from the API and return them.
 *
 * @param {boolean|undefined} completed - Filter for completed programs
 * @returns {Promise<Array>} - The list of programs
 */
export const fetchPrograms = async (completed) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/career-compass/v1/programs')
      .then((response) => {
        if (response?.data?.payload) {
          const programs = preparePrograms(response.data.payload, completed);
          resolve(programs);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to fetch program recommendations.
 * It will fetch the programs recommendations from the API and return them.
 *
 * @returns {Promise<Array>} - The list of programs
 */
export const fetchProgramRecommendations = async () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('services/program-recommendation/v1?noLoader=true')
      .then((response) => {
        if (response?.data?.payload?.length > 0) {
          const programs = prepareProgramsRecommendations(response.data.payload);
          resolve(programs);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name enrollIntoProgram
 * @desc enroll into program
 * @param {String} programId
 * @returns {Promise}
 */
export const enrollIntoProgram = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/${programId}/enroll`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const prepareProgramDetails = (program) => {
  return {
    id: program.id,
    name: program.name,
    description: program.description,
    descriptionShort: program.descriptionShort,
    certificateUrl: program.certificateUrl,
    learningObjects: program.learningObjects,
    rootLearningObject: program.rootLearningObject,
    templateType: program.templateType,
    thumbnailAltText: program.thumbnailAltText,
    thumbnailUri: program.thumbnailUri,
    progress: program.progress,
  };
};

export const getProgramDetails = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/programs/v2/${programId}/details?includeProgress=true&showUnits=true`)
      .then((response) => {
        if (response?.data?.payload?.program) {
          const programDetails = prepareProgramDetails(response.data.payload.program);
          resolve(programDetails);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name getProgramProgress
 * @desc retrieve program progress
 * @param {String} programId
 * @returns {Promise}
 */
export const getProgramProgress = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/v1/${programId}/refresh?noLoader=true`)
      .then((response) => {
        if (response?.data?.payload?.program?.progress) {
          resolve(response.data.payload.program.progress);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
