import React from 'react';
import { withStyles, Box, Button, Typography } from '@material-ui/core';

import styles from './career-item.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilledBookmarkIcon, FlameIcon } from '../../../../../shared/icons';
import { Constant, Utility } from '../../../../../shared/services';
import { CAREER_DIRECTORY_FILTERS } from '../../../../../content/careers/containers/career-directory/constants';

export const getCareerFitIcon = (careerFit) => {
  const iconProps = {
    fill: Constant.MATCHING_CAREERS_FITS[careerFit].color,
    width: 18,
    height: 20,
    style: { transform: 'translateY(2px)' },
  };
  switch (careerFit) {
    case Constant.MATCHING_CAREERS_FITS.Best.id:
      return (
        <>
          <FlameIcon {...iconProps} />
          <FlameIcon {...iconProps} />
          <FlameIcon {...iconProps} />
        </>
      );
    case Constant.MATCHING_CAREERS_FITS.Great.id:
      return (
        <>
          <FlameIcon {...iconProps} />
          <FlameIcon {...iconProps} />
        </>
      );
    case Constant.MATCHING_CAREERS_FITS.Good.id:
      return <FlameIcon {...iconProps} />;
    default:
      return null;
  }
};

const CareerItem = (props) => {
  const {
    classes,
    title,
    subtitle,
    zone,
    medianPay,
    medianPayLabel,
    onItemClick,
    isBookmarked,
    code,
    mainFilter,
    careerFit,
  } = props;
  const intl = useIntl();

  return (
    <Button
      id={`view-career-details-${code}`}
      className={classes.root}
      onClick={onItemClick}
      tracking-type={Constant.TRACKING_TYPES.CAREER_DIRECTORY}
      tracking-id={`view-career-details-${code}`}
      aria-label={intl.formatMessage({ id: 'viewCareerProfile' })}
      role="link"
    >
      {isBookmarked && (
        <Box className={classes.bookmarkIcon}>
          <FilledBookmarkIcon variant="filled" />
        </Box>
      )}
      <Box className={classes.careerContainer}>
        <Box className={classes.titleContainer}>
          {/* BEGIN: TITLE */}
          <Box>
            <Typography id="career-title" className={classes.careerTitle}>
              {title}
            </Typography>
          </Box>
          {/* END: TITLE */}
        </Box>

        <Box className={classes.titleContainer}>
          {/* BEGIN: ZONE BADGE */}

          <Box id="zone-badge" className={[classes.zoneBadge, classes[`zoneColor${zone}`]].join(' ')}>
            <Typography id="career-zone" className={classes.careerZone}>
              <FormattedMessage id="zone" /> {zone}
            </Typography>
          </Box>
          {/* END: ZONE BADGE */}

          {/* BEGIN: MEDIAN PAY */}
          {medianPay && (
            <Box id="career-median-container" className={classes.medianPayContainer}>
              <Box>
                <Typography className={classes.medianPayText}>
                  <FormattedMessage id={medianPayLabel} />:
                </Typography>
              </Box>
              <Box>
                <Typography id="career-median-pay" className={classes.medianPayAmount}>
                  {`$${Utility.numberWithCommas(medianPay)}`}
                </Typography>
              </Box>
            </Box>
          )}
          {mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT && careerFit && (
            <Box id="career-fit-score" className={classes.careerFitContainer}>
              <>
                {getCareerFitIcon(careerFit)}
                <Typography
                  style={{ color: Constant.MATCHING_CAREERS_FITS[careerFit].color }}
                  variant="h4"
                  component="span"
                  className={classes.careerFitText}
                >
                  <FormattedMessage id={Constant.MATCHING_CAREERS_FITS[careerFit].text} />
                </Typography>
              </>
            </Box>
          )}
          {/* END: MEDIAN PAY */}
        </Box>

        {/* BEGIN: SUBTITLE */}
        <Typography id="career-desc" className={classes.careerSubtitle} variant="body2" component="p">
          {subtitle}
        </Typography>
        {/* END: SUBTITLE */}
      </Box>
    </Button>
  );
};

export default withStyles(styles)(CareerItem);
