import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import momentTimeZone from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

import styles from './schedule.style';

const schedule = (props) => {
  const { classes } = props;
  return (
    <Grid style={{ alignItems: 'normal' }} data-cy-name="week-schedule" container item>
      {props.schedule &&
        props.schedule.map((columns, index) => (
          <Grid key={index} item xs={12} sm={6} className={classes.scheduleWrapper}>
            {columns.map((scheduleData, scheduleIndex) => (
              <div key={scheduleIndex}>
                {/* BEGIN: DAY */}
                <Box component="div" className={classes.dayWrapper}>
                  <Box component="div" className={classes.chipContainer}>
                    <Typography variant="h3" color="secondary" id="day-name-container" className={classes.day}>
                      {scheduleData.dayOfWeek.toLowerCase()}
                    </Typography>
                  </Box>

                  {scheduleData.slots.map((slot, slotIndex) => (
                    <div key={slotIndex}>
                      {slot.isClosed ? (
                        <Box
                          id="closed-container"
                          key={slotIndex}
                          component="div"
                          className={[classes.chipContainer, classes.closed].join(' ')}
                        >
                          <Typography className={classes.daySchedule}>
                            <FormattedMessage id="closed" />
                          </Typography>
                        </Box>
                      ) : (
                        <Box key={slotIndex} component="div" className={[classes.chipContainer].join(' ')}>
                          <Typography id="slot-container" className={classes.daySchedule}>
                            <Moment unix tz={momentTimeZone.tz.guess()} format="hh:mmA">
                              {slot.start}
                            </Moment>
                            &nbsp; - &nbsp;
                            <Moment unix format="hh:mmA">
                              {slot.end}
                            </Moment>
                          </Typography>
                        </Box>
                      )}
                    </div>
                  ))}
                </Box>
                {/* END: DAY */}
              </div>
            ))}
          </Grid>
        ))}
    </Grid>
  );
};

export default withStyles(styles)(schedule);
