export default (theme) => ({
    mainWrapper: {
        padding: "20px 47px"
    },
    mainTitle: {
        color: theme.palette.primary.jetBlack,
        ...theme.typography.t22,
        ...theme.typography.bold
    },
    subText: {
        color: theme.palette.primary.jetBlack,
        ...theme.typography.t18,
        marginTop: 20
    },
    formWrapper: {
        margin: "40px 0px"
    },
    formField: {
        minWidth: 300,
        maxWidth: "100%",
        marginBottom: 20
    },
    phoneSideElement: {
        display: "flex",
        position: "absolute",
        top: 17,
        left: 10,
        fontSize: 16,
        marginRight: 8
    },
    USAFlag: {
        width: 30,
        height: 20,
        marginRight: 8
    },
    phone: {
        "& > div": {
            paddingLeft: 50
        }
    },
    italicText: {
        fontStyle: "italic"
    }
});