export default (theme) => ({
  title: {
    ...theme.typography.bold,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily,
    paddingBottom: 10,
    textTransform: 'capitalize',
  },
  description: {
    ...theme.typography.t14,
    ...theme.typography.regular,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '21px',
    width: '70%',
  },
  actionButton: {
    lineHeight: '28px',
    letterSpacing: '1px',
    textTransform: 'capitalize',
    paddingLeft: 35,
    paddingRight: 35,
  },
  actionBox: {
    marginTop: 20,
  },
});
