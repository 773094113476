export default (theme) => ({
  root: {
    borderRadius: '16px 16px 0 0',
    padding: '24px 0 0 24px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Inter',
  },
  whiteBg: {
    background: 'white',
  },
  peachBg: {
    background: theme.palette.primary.peach,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    position: 'relative',
  },
  mainImage: {
    width: 240,
    maxHeight: 161,
    [theme.breakpoints.down('sm')]: {
      width: 160,
      maxHeight: 107,
    },
  },
  mainTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  subtitle: {
    ...theme.typography.t16,
    fontWeight: 700,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  bold: {
    fontWeight: 700,
  },
  description: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  actionBtn: {
    ...theme.typography.t16,
    height: 40,
    width: 'fit-content',
    textTransform: 'capitalize',
    fontWeight: 700,
    marginTop: 16,
    borderRadius: 100,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
      filter: 'brightness(80%)',
      boxShadow: 'none',
    },
    boxShadow: 'none',
  },
  greyedIcon: {
    fill: theme.palette.text.sub,
  },
  iconContainer: {
    position: 'absolute',
    top: '45%',
    left: '45%',
  },
  personalityArea: {
    ...theme.typography.t16,
    color: theme.palette.aqua.extraDeep,
    textTransform: 'uppercase',
    fontWeight: 700,
    textDecoration: 'underline',
    cursor: 'pointer',
    textUnderlineOffset: '8px',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    marginTop: 12,
  },
  retakeInterestProfiler: {
    ...theme.typography.t16,
    fontWeight: 700,
    textDecoration: 'none',
    textTransform: 'capitalize',
    cursor: 'pointer',
    color: theme.palette.aqua.extraDeep,
    padding: '12px 20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notFeeling: {
    ...theme.typography.t14,
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.28px',
    marginRight: 12,
  },
  topBottomMargin: {
    marginBottom: 12,
    marginTop: 12,
  },
  filterArea: {
    display: 'flex',
    marginTop: 10,
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    width: 150,
    border: '1px solid',
    borderColor: theme.palette.colors.borderGrey.light,
    borderRadius: 3,
    padding: 10,
    background: 'white',
    cursor: 'pointer',
  },
  filterText: {
    fontWeight: 700,
    textTransform: 'capitalize',
    marginLeft: 10,
  },
  icon: {
    fill: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioBtn: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.colors.borderGrey.dark,
  },
});
