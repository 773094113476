import moment from 'moment';
import { Http } from '../../../core';
import { UserService } from '../../services';

const DAYSLIST = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

/**
 * @name parseServerDateToCurrentDate
 * @description Parses a server-provided date into the current date format.
 * @param {string} dateTime - The date time provided by the server.
 * @returns {Number} Unix timestamp (in seconds).
 */
const parseServerDateToCurrentDate = (dateTime) => {
  const serverDateTime = moment.utc(dateTime).format('HH:mm:ss');
  return moment(moment().format('YYYY-MM-DD ') + serverDateTime).unix();
};

/**
 * @name refineSlots
 * @description Refines the slots data by parsing the start and end times.
 * @param {Array} slots - The slots data from the server. Each item should have a `startTime`, `endTime`, and `isActive` property.
 * @returns {Array} An array of refined slots data. Each item has a `start` and `end` property,
 * which are the Unix timestamps of the start and end times,
 * and an `isClosed` property, which is true if the original `isActive` property was false.
 */
const refineSlots = (slots) => {
  return slots.map((slot) => ({
    start: parseServerDateToCurrentDate(slot.startTime),
    end: parseServerDateToCurrentDate(slot.endTime),
    isClosed: !slot.isActive,
  }));
};

/**
 * @name refineSchedule
 * @description Refines the schedule data for each day of the week.
 * @param {Array} schedule - The schedule data from the server. Each item should have a `dayOfWeek` property and a `slots` array.
 * @returns {Array} An array of refined schedule data for each day of the week. Each item has a `dayOfWeek` property and a `slots` array.
 * If the original schedule data does not contain any data for a day, the `slots` array for that day will contain a single item with `isClosed: true`.
 */
const refineSchedule = (schedule) => {
  return DAYSLIST.map((day) => {
    let data = { dayOfWeek: day, slots: [{ isClosed: true }] };
    const daySchedule = schedule.find((item) => item.dayOfWeek === day);
    if (daySchedule) {
      data = { ...daySchedule, slots: refineSlots(daySchedule.slots) };
    }
    return data;
  });
};

/**
 * @name fetchAllSchedules
 * @description Fetches all schedules from the server.
 * @param {Object} options - The options for fetching schedules.
 * @param {boolean} options.showLoader - Whether to show a loader while fetching schedules.
 * If false, the "?noLoader=true" query parameter is added to the request.
 * @returns {Promise<Array>} A promise that resolves to an array of schedules.
 * If the request is successful but the response does not contain any data, the promise resolves to an empty array.
 * If the request fails, the promise is rejected with the error.
 */
const fetchAllSchedules = ({ showLoader }) => {
  return new Promise((resolve, reject) => {
    const queryParams = !showLoader ? '?noLoader=true' : '';
    Http.REQUEST.get(`/feature/location/schedule${queryParams}`)
      .then((_successLog) => {
        if (_successLog?.data) {
          resolve(_successLog.data);
        }
        resolve([]);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name isSameDay
 * @description Checks if the provided slot day is the same as the current day.
 * @param {moment} currentTime - The current time as a moment object.
 * @param {string} slotDay - The day of the schedule slot, as a string.
 * @returns {boolean} Returns true if the provided slot day is the same as the current day, and false otherwise.
 */
const isSameDay = (currentTime, slotDay) => {
  return currentTime.format('dddd').toUpperCase() === slotDay;
};

/**
 * @name prepareSchedule
 * @description Prepares the schedule data for use in the application.
 * @param {Array} schedule - The refined schedule data.
 * @returns {Array} The prepared schedule data that is ready to be used in the application.
 */
const prepareSchedule = (schedule) => {
  const currentTime = moment(UserService.userDateTime);
  let nextActiveSlot;
  let activeSlot;

  return schedule.map((item) => {
    const isCurrentDay = isSameDay(currentTime, item.dayOfWeek);

    item.slots = item.slots.map((slot) => {
      if (!slot.isClosed) {
        const startTime = moment.unix(slot.start);
        const endTime = moment.unix(slot.end);
        const currentDayIndex = DAYSLIST.findIndex((day) => isSameDay(currentTime, day));
        const slotDayIndex = DAYSLIST.findIndex((day) => day === item.dayOfWeek);

        slot.isActive = currentTime.isBetween(startTime, endTime) && isCurrentDay;
        if (slot.isActive) {
          activeSlot = { day: item.dayOfWeek, slot };
          item.activeSlot = activeSlot;
        }

        if (
          !nextActiveSlot &&
          ((slotDayIndex === currentDayIndex && currentTime.isBefore(startTime)) || slotDayIndex > currentDayIndex)
        ) {
          nextActiveSlot = { day: item.dayOfWeek, slot };
          item.nextActiveSlot = nextActiveSlot;
        }
      }
      return slot;
    });
    if (isCurrentDay && item.slots.length > 0 && item.slots[0].isClosed) {
      item.slots[0].isActive = true;
      item.activeSlot = { day: item.dayOfWeek, slot: item.slots[0], isClosed: true };
    } else if (isCurrentDay && !activeSlot) {
      item.slots.unshift({
        isClosed: true,
        isActive: true,
      });
      item.activeSlot = { day: item.dayOfWeek, slot: item.slots[0], isClosed: true };
    }

    return item;
  });
};

const fetchAllGroupSchedules = ({ showLoader }) => {
  return new Promise((resolve, reject) => {
    const queryParams = !showLoader ? '?noLoader=true' : '';
    Http.REQUEST.get(`/voice/schedule${queryParams}`)
      .then((_successLog) => {
        if (_successLog?.data) {
          resolve(_successLog.data);
        }
        resolve([]);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

const refineGroupSchedule = (groupSchedule) => {
  const dayMap = {
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
    7: 'SUNDAY',
  };

  return DAYSLIST.map((day) => {
    const dayNumber = Object.keys(dayMap).find((key) => dayMap[key] === day);
    const daySchedule = groupSchedule.find((item) => item.dayOfWeek === Number(dayNumber));
    let data = { dayOfWeek: day, slots: [{ isClosed: true }] };

    if (daySchedule) {
      data = { ...daySchedule, dayOfWeek: day, slots: refineSlots(daySchedule.slots) };
    } else {
      data = { dayOfWeek: day, slots: [{ isClosed: true }] };
    }

    return data;
  });
};

export { fetchAllSchedules, refineSchedule, prepareSchedule, fetchAllGroupSchedules, refineGroupSchedule };
