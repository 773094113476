export default (theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
});
