import { useState, useEffect } from 'react';

const useTimerHook = (initialElapsedTime, isTimerFlagEnabled) => {
  const [elapsedTime, setElapsedTime] = useState(initialElapsedTime * 60 * 1000);
  const [stopTimer, setStopTimer] = useState(false);
  useEffect(() => {
    let interval;

    if (isTimerFlagEnabled && !stopTimer) {
      interval = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime - 1000);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerFlagEnabled, stopTimer]);

  useEffect(() => {
    if (elapsedTime <= 0) {
      setStopTimer(true);
    }
  }, [elapsedTime]);

  const reset = () => {
    setElapsedTime(0);
  };

  const formatTime = (time) => {
    const totalSeconds = Math.ceil(time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${pad(minutes)}:${pad(seconds)}`;
  };
  const lastNSecondsLeft = (n) => {
    const totalSeconds = Math.ceil(elapsedTime / 1000);
    return totalSeconds <= n;
  };
  const pad = (value) => {
    return String(value).padStart(2, '0');
  };

  return {
    elapsedTime,
    reset,
    formatTime,
    lastNSecondsLeft,
  };
};

export default useTimerHook;
