import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme, withStyles } from '@material-ui/core';
import styles from './face-validation-status.styles';
import { Constant } from '../../../../../../../shared/services';
import { WarningIcon } from '../../../../../../../shared/icons';
import { PillButton } from '../../../../../../../frontend-commons';
import { FormattedMessage } from 'react-intl';
import useFaceValidationStatusHook from './useFaceValidationStatusHook';
import { CheckCircleIcon } from '@orijinworks/frontend-commons';

/**
 * Component to render the face validation status.
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const FaceValidationStatus = (props) => {
  const { classes, message, showSteps } = props;
  const theme = useTheme();
  const { STEPS, stepCompleted, stepFailed, isStatusFailed, handleClick } = useFaceValidationStatusHook(props);

  return (
    <>
      <Box className={[classes.statusContainer, isStatusFailed() ? classes.statusFailed : classes.statusSuccess].join(' ')}>
        <Box className={[classes.statusWrapper, isStatusFailed() ? classes.borderFailed : classes.borderSuccess].join(' ')}>
          {isStatusFailed() ? <WarningIcon fill={theme.alert.error} /> : <CheckCircleIcon color="primary" />}
          <Typography id="face-validation-status-message" className={classes.statusMessage}>
            <FormattedMessage id={message} />
          </Typography>
        </Box>
      </Box>
      <Box className={classes.imageFrame}>
        {showSteps && (
          <Box id="face-validation-status-steps" className={classes.steps}>
            {STEPS.map((step, index) => (
              <>
                {stepCompleted(index) && (
                  <img
                    id={`face-validation-status-tick-mark-${index}`}
                    key={step}
                    alt=""
                    className={classes.stepNumber}
                    src={`${Constant.CDN_BASE_URL}/assets/icons/tick-mark.png`}
                  />
                )}
                {stepFailed(index) && (
                  <img
                    id={`face-validation-status-cross-mark-${index}`}
                    key={step}
                    alt=""
                    className={classes.stepNumber}
                    src={`${Constant.CDN_BASE_URL}/assets/icons/cross-mark.png`}
                  />
                )}
                {!stepCompleted(index) && !stepFailed(index) && (
                  <Box id={`face-validation-status-step-number-${index}`} key={step} className={classes.stepNumber}>
                    {step}
                  </Box>
                )}
              </>
            ))}
          </Box>
        )}
      </Box>
      <Box className={classes.actionContainer}>
        <PillButton
          id="face-validation-status-continue-button"
          color="primary"
          variant="contained"
          size="large"
          className={classes.actionButton}
          onClick={handleClick}
        >
          <FormattedMessage id={isStatusFailed() ? 'retakePicture' : 'continue'} />
        </PillButton>
      </Box>
    </>
  );
};

FaceValidationStatus.defaultProps = {
  showSteps: true,
};

FaceValidationStatus.propTypes = {
  /**
   * Material-UI classes object to style the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The status of the face validation. Either 'success' or 'failed'.
   */
  status: PropTypes.string.isRequired,
  /**
   * The message to display in the status.
   */
  message: PropTypes.string.isRequired,
  /**
   * The number of images processed.
   */
  imagesProcessed: PropTypes.number.isRequired,
  /**
   * The onContinue callback function.
   */
  onContinue: PropTypes.func.isRequired,
  /**
   * The onRetake callback function.
   */
  onRetake: PropTypes.func.isRequired,
  /**
   * The showSteps flag. If true, the steps will be shown.
   */
  showSteps: PropTypes.bool,
};

export default withStyles(styles)(FaceValidationStatus);
