import React from 'react';
import { Box, Button, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './enrollment-toggle.style';
import { Constant } from '../../../../shared/services';
import { isEnrolled } from '../../services/programs.service';

const EnrollmentToggle = (props) => {
  const { classes, programId, status, onEnroll, onUnenroll, footerBtn } = props;
  const intl = useIntl();

  return (
    <Box className={classes.enrollBtnContainer}>
      {isEnrolled(status) ? (
        <Button
          id="_program-details-unenroll-button"
          variant="outlined"
          color={footerBtn ? 'secondary' : 'primary'}
          className={classes.buttonClass}
          onClick={onUnenroll}
          tracking-type={Constant.TRACKING_TYPES.PROGRAM}
          tracking-id={`open-unenroll-dialog-${programId}`}
          aria-label={intl.formatMessage({ id: 'unenroll' })}
        >
          <FormattedMessage id="unenroll" />
        </Button>
      ) : (
        <Button
          id="_program-details-enroll-button"
          variant="contained"
          color={footerBtn ? 'secondary' : 'primary'}
          className={classes.buttonClass}
          onClick={onEnroll}
          tracking-type={Constant.TRACKING_TYPES.PROGRAM}
          tracking-id={`enroll-program-${programId}`}
          aria-label={intl.formatMessage({ id: 'enroll' })}
        >
          <FormattedMessage id="enroll" />
        </Button>
      )}
    </Box>
  );
};

export default withStyles(styles)(EnrollmentToggle);
