export default theme => ({
  root: {
    margin: "15px 0px"
  },
  notifDropDownBtn: {
    ...theme.typography.t18,
    ...theme.typography.bold
  },
  notifDropdown: {
    right: 0,
    left: "initial"
  }
});
