import React from 'react';

const BookOpenCoverIcon = (props) => {
  const { size, fill, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={className} viewBox="0 0 28.116 22.412">
      <path
        id="book-open-cover-regular"
        d="M22.493,16.573V4.061l-7.38,1.081v13ZM13,18.146v-13L5.623,4.061V16.573Zm9.99-16.29A1.4,1.4,0,0,1,24.6,3.244v13.9a1.41,1.41,0,0,1-1.111,1.375L14.647,20.4a2.778,2.778,0,0,1-1.173,0L4.63,18.515A1.406,1.406,0,0,1,3.514,17.14V3.244A1.4,1.4,0,0,1,5.122,1.856l8.936,1.309ZM1.683,2.8l.448.088a3.223,3.223,0,0,0-.022.356V19.718l11.677,2.394a1.42,1.42,0,0,0,.545,0l11.677-2.394V3.244a3.1,3.1,0,0,0-.022-.356l.448-.088a1.4,1.4,0,0,1,1.683,1.375V20.294a1.4,1.4,0,0,1-1.125,1.375L14.752,24.182a3.462,3.462,0,0,1-1.388,0L1.125,21.669A1.4,1.4,0,0,1,0,20.294V4.176A1.4,1.4,0,0,1,1.683,2.8Z"
        transform="translate(0 -1.84)"
        fill={fill}
      />
    </svg>
  );
};

BookOpenCoverIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
  className: '',
};

export default BookOpenCoverIcon;
