export default (theme) => ({
  dialogContent: {
    padding: '30px !important',
  },
  boldText: {
    fontWeight: 700,
  },
  dialogBtn: {
    padding: '12px 36px',
  },
  confirmBtn: {
    marginRight: 15,
    textTransform: 'capitalize',
  },
  cancelBtn: {
    marginLeft: 15,
    textTransform: 'capitalize',
  },
});
