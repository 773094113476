export default (theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    border: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    height: '100%',
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    flexDirection: 'column',
    textAlign: 'center',
  },
  modalTitle: {
    fontWeight: 700,
  },
  modalImage: {
    maxWidth: 300,
    marginBottom: 20,
  },
  modalButton: {
    textTransform: 'capitalize',
    marginTop: 20,
  },
});
