import React from 'react';

const InprogressSpinnerIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1725_7302)">
        <path
          d="M10.5 2C10.5 1.17031 11.1703 0.5 12 0.5C18.6281 0.5 24 5.87188 24 12.5C24 14.6844 23.4141 16.7375 22.3922 18.5C21.9797 19.2172 21.0609 19.4609 20.3438 19.0484C19.6266 18.6359 19.3828 17.7172 19.7953 17C20.5594 15.6781 21 14.1406 21 12.5C21 7.53125 16.9688 3.5 12 3.5C11.1703 3.5 10.5 2.82969 10.5 2Z"
          fill={fill}
        />
        <path
          opacity="0.4"
          d="M12 3.5C7.03125 3.5 3 7.53125 3 12.5C3 17.4688 7.03125 21.5 12 21.5C15.2859 21.5 18.1547 19.7422 19.7297 17.1125L19.7344 17.1172C19.4109 17.8156 19.6641 18.6547 20.3437 19.0484C21.0609 19.4656 21.9797 19.2172 22.3922 18.5C22.4016 18.4859 22.4109 18.4719 22.4156 18.4578C20.3484 22.0672 16.4578 24.5 12 24.5C5.37187 24.5 0 19.1281 0 12.5C0 5.87188 5.37187 0.5 12 0.5C11.1703 0.5 10.5 1.17031 10.5 2C10.5 2.82969 11.1703 3.5 12 3.5Z"
          fill="#707070"
        />
      </g>
      <defs>
        <clipPath id="clip0_1725_7302">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

InprogressSpinnerIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default InprogressSpinnerIcon;
