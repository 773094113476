import * as store from ".";

const initialState = {
  isDialogOpen: false,
  rulesCollection: null,
  earnTokenType: null,
  props: {}
};

export const earnTokensReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.TOGGLE_DIALOG:
      return {
        ...state,
        isDialogOpen: !state.isDialogOpen,
        props: {
          ...state.props,
          ...action.props
        }
      };
    case store.SET_RULES:
      return {
        ...state,
        rulesCollection: action.rulesCollection
      };
    case store.EARN_TOKEN_TYPE:
      return {
        ...state,
        EARN_TOKEN_TYPE: !action.earnTokenType
      };

    default:
      return state;
  }
};
