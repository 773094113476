import { Typography, Box, CardContent, CardMedia, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './documentry-card.style';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { Constant } from '../../../../shared/services';

const DocumentryCard = (props) => {
  const { classes } = props;
  return (
    <Box className={classes.root}>
      <Grid
        onClick={props.onCardClick}
        item
        xs={3}
        sm={3}
        className={classes.thumbnailContainer}
        tracking-type={Constant.TRACKING_TYPES.SERVICE}
        tracking-id={`open-${props.serviceId}-video`}
        id="documentry-card-container"
      >
        <CardMedia
          id="documentry-card-image"
          component="img"
          alt={props.title}
          className={[classes.thumbnail].join(' ')}
          image={props.image}
          title={props.title}
        />
        <PlayCircleFilledIcon className={classes.playIcon} />
      </Grid>
      <Grid item xs={9} sm={9} className={classes.content}>
        <CardContent className={classes.cardContent}>
          <Typography id="documentry-card-title" gutterBottom className={classes.cardTitle}>
            {props.title}
          </Typography>
          <Typography id="documentry-card-desc">{props.description}</Typography>
        </CardContent>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(DocumentryCard);
