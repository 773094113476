export default (theme) => ({
  overviewHeader: {
    marginTop: 50,
    padding: '0px 50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headingWrapper: {
    paddingBottom: 5,
    display: 'inline-block',
    zIndex: 99,
    marginBottom: 25,
    fontWeight: 700,
  },
  modulesContainerEnrolled: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
  },
  modulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
    maskImage: `linear-gradient(to bottom, black 30%, transparent 120%)`,
    WebkitMaskImage: `linear-gradient(to bottom, black 30%, transparent 120%)`,
    overflow: 'auto',
    maxHeight: '800px',
  },
  modulesContainerEnd: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
    overflow: 'auto',
    maxHeight: '800px',
  },
  boldText: {
    fontWeight: 700,
  },
});
