import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './limit-reached-warning.style';
import { Alert } from '@material-ui/lab';

const LimitReachedWarning = ({ classes, message }) => {
  return (
    <Alert severity="warning" elevation={0} className={classes.root}>
      {message}
    </Alert>
  );
};

export default withStyles(styles)(LimitReachedWarning);
