import React from 'react';

const SwitchBackIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 30 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.51724 12C0.676437 12 0 11.3313 0 10.5V1.5C0 0.66875 0.676437 0 1.51724 0C2.35805 0 3.03448 0.66875 3.03448 1.5V6.50625L4.29885 5.0375C6.8908 1.9625 10.804 0 15.1724 0C22.9925 0 29.3333 6.26875 29.3333 14C29.3333 21.7313 22.9925 28 15.1724 28C11.9862 28 9.04023 26.9563 6.67586 25.2C6.00575 24.7 5.87299 23.7625 6.37241 23.1C6.87184 22.4375 7.82644 22.3062 8.49655 22.8C10.3552 24.1812 12.6626 25 15.1724 25C21.3172 25 26.2989 20.075 26.2989 14C26.2989 7.925 21.3172 3 15.1724 3C11.7397 3 8.66724 4.5375 6.62529 6.9625L6.61897 6.975L4.87414 9H10.6207C11.4615 9 12.1379 9.66875 12.1379 10.5C12.1379 11.3313 11.4615 12 10.6207 12H1.51724Z"
        fill={fill}
      />
    </svg>
  );
};

SwitchBackIcon.defaultProps = {
  width: '30',
  height: '28',
  fill: 'inherit',
};

export default SwitchBackIcon;
