import { useEffect, useMemo, useState } from 'react';
import { fetchFileContentAsUint8Array, fetchJsonData } from '../../../../shared/services';

const useCertificateDialogHook = ({ certificateUrl }) => {
  const [animationData, setAnimationData] = useState(null);
  const [pdfContent, setPdfContent] = useState(null);

  // preloading the background image for smooth transition
  useEffect(() => {
    const img = new Image();
    img.src = 'https://content.connectedcorrections.com/assets/img/Sign-in_Background.png';
  }, []);

  // fetch animation data
  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const data = await fetchJsonData('https://content.connectedcorrections.com/assets/animations/celebration.json');
        setAnimationData(data);
      } catch (error) {
        console.error('Error fetching animation data:', error);
      }
    };

    fetchAnimationData();
  }, []);

  // fetch PDF content from pre-signed URL
  useEffect(() => {
    const fetchPdfContent = async () => {
      try {
        const response = await fetchFileContentAsUint8Array(certificateUrl);
        setPdfContent(response);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdfContent();
  }, [certificateUrl]);

  const pdfData = useMemo(() => ({ data: pdfContent }), [pdfContent]);

  return {
    animationData,
    pdfData,
  };
};

export default useCertificateDialogHook;
