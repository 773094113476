export default (theme) => ({
  root: {
    color: theme.palette.primary.darkRed,
    display: 'flex',
    textAlign: 'center',
    width: '75%',
    alignSelf: 'center',
    background: 'transparent',
    '& svg': {
      fill: theme.palette.primary.darkRed,
    },
  },
});
