import React from 'react';
import { withStyles, Box, Typography, Grid, Button } from '@material-ui/core';

import styles from './interest-profiler-tile.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Constant } from '../../../../shared/services';

const InterestProfilerTile = (props) => {
  const { classes, isInterestProfilerTaken, onLaunch, onViewResults } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.mainTitle}>
        <FormattedMessage id="findYourNextCareer.title" />
      </Typography>

      <Typography variant={isInterestProfilerTaken ? 'body2' : 'body1'} className={classes.subtitle}>
        <FormattedMessage id={`findYourNextCareer.subtitle.${isInterestProfilerTaken ? 'retake' : 'default'}`} />
      </Typography>

      <Grid container spacing={2} className={classes.actionContainer}>
        <Grid item sm={6}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.containedBtn}
            onClick={isInterestProfilerTaken ? onViewResults : onLaunch}
            tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
            tracking-id={isInterestProfilerTaken ? 'view-interest-profiler-results' : 'launch-interest-profiler'}
            aria-label={intl.formatMessage({ id: isInterestProfilerTaken ? 'viewResults' : 'launch' })}
            role="link"
          >
            <FormattedMessage id={isInterestProfilerTaken ? 'viewResults' : 'launch'} />
          </Button>
        </Grid>
        {isInterestProfilerTaken && (
          <Grid item sm={6}>
            <Button
              fullWidth
              variant="outlined"
              className={classes.outlinedBtn}
              onClick={onLaunch}
              tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
              tracking-id="retake-interest-profiler"
              aria-label={intl.formatMessage({ id: 'retake' })}
              role="link"
            >
              <FormattedMessage id="retake" />
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(InterestProfilerTile);
