import { useState, useEffect, useRef, useCallback } from 'react';
import { submitVoiceSample, ContactRequestConstants } from '../../../../services';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { store, isEncoderRegistered } from '../../../../../../core';
import { Constant } from '../../../../../../shared';
const useVoiceRecordingHook = (setProfileStatus) => {
  const [recordingCountDown, setRecordingCountDown] = useState(3);
  const [isRecordingCountDownStarted, setIsRecordingCountDownStarted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDone, setRecordingDone] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0); // in seconds
  const [recordingSubmiting, setRecordingSubmiting] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioChunksRef = useRef([]);
  const mediaStreamRef = useRef(null); // To store media stream
  const { voiceCall } = store.getState();
  const { isRegistered } = voiceCall;
  useEffect(() => {
    const registerEncoder = async () => {
      try {
        await register(await connect());
        store.dispatch(isEncoderRegistered(true));
      } catch (error) {
        console.error('Error registering encoder:', error);
      }
    };
    if (!isRegistered) {
      registerEncoder();
    }
  }, [isRegistered]); // Empty dependency array to run only on initial load (or refresh)

  const handleStartRecording = useCallback(async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaStreamRef.current = stream; // Store the stream to stop it later
        const recorder = new MediaRecorder(stream, {
          mimeType: 'audio/wav',
        });
        setMediaRecorder(recorder);
        audioChunksRef.current = []; // Clear previous chunks

        recorder.ondataavailable = (e) => {
          audioChunksRef.current.push(e.data);
        };

        recorder.onstop = () => {
          console.log('Recording stopped');
          revokeMicrophoneAccess(); // Stop the stream after recording
          setMediaRecorder(null);
        };

        recorder.start();
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
      });
  }, []);

  const handleStopRecording = useCallback(() => {
    if (mediaRecorder) {
      console.log('Stopping recording');
      mediaRecorder.stop();
      setMediaRecorder(null);
    }
  }, [mediaRecorder]);

  const playAudioTone = useCallback(() => {
    const audio = new Audio('/assets/misc/beep.WAV');
    console.log('Playing audio tone', audio);
    audio.play();
  }, []);

  useEffect(() => {
    if (recordingCountDown === 0) {
      setIsRecordingCountDownStarted(false);
      setIsRecording(true);
      setRecordingCountDown(null);
      playAudioTone();
      handleStartRecording();
      return;
    }

    if (isRecordingCountDownStarted && recordingCountDown > 0) {
      const timer = setTimeout(() => {
        setRecordingCountDown(recordingCountDown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [recordingCountDown, isRecordingCountDownStarted, handleStartRecording, playAudioTone]); // Add handleStartRecording as a dependency

  useEffect(() => {
    if (isRecording && recordingTime < ContactRequestConstants.VOICE_ID_REGISTRATION_RECORDING_TIME_LIMIT) {
      const timer = setTimeout(() => {
        setRecordingTime(recordingTime + 1);
      }, 1000);

      return () => clearTimeout(timer);
    }

    if (recordingTime === ContactRequestConstants.VOICE_ID_REGISTRATION_RECORDING_TIME_LIMIT) {
      handleStopRecording(); // Call the memoized handleStopRecording
      setIsRecording(false);
      setRecordingDone(true);
    }
  }, [recordingTime, isRecording, handleStopRecording]); // handleStopRecording is a dependency

  const revokeMicrophoneAccess = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null; // Clear the media stream reference
    }
  };

  const reRecord = () => {
    setIsRecording(false);
    setRecordingDone(false);
    setRecordingCountDown(3);
    setRecordingTime(0);
    setIsRecordingCountDownStarted(true);
    audioChunksRef.current = []; // Clear audio chunks
    setRecordingSubmiting(false);
  };

  const startRecordingCountDown = () => {
    if (recordingCountDown === null || recordingCountDown === 0) {
      return;
    }
    setIsRecordingCountDownStarted(true);
    setRecordingCountDown(3);
  };

  const cancelRecording = () => {
    setIsRecording(false);
    setRecordingTime(0);
    setRecordingCountDown(3);
    audioChunksRef.current = []; // Clear audio chunks
    handleStopRecording(); // Call the memoized handleStopRecording
  };

  const submitRecording = async () => {
    if (audioChunksRef.current.length === 0) {
      console.error('No audio data to submit');
      return;
    }
    setRecordingDone(false);
    setRecordingSubmiting(true);
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });

    const formData = new FormData();
    formData.append('audioFile', audioBlob, 'recording.wav'); // Append audio file

    try {
      const response = await submitVoiceSample(formData);
      if (response && response.data && response.data.voiceProfileId) {
        console.log('Recording submitted successfully:', response);
        setRecordingSubmiting(false);
        setProfileStatus(Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED);
      }
    } catch (error) {
      console.error('Error submitting the recording:', error);
      setProfileStatus(Constant.VOICE_PROFILE_ID_STATUS.RERECORD_AUDIO);
    }
    setRecordingTime(0);
    setRecordingDone(false);
    setRecordingCountDown(3);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    return `${paddedMinutes}:${paddedSeconds}`;
  };

  return {
    recordingCountDown,
    isRecordingCountDownStarted,
    startRecordingCountDown,
    recordingTime,
    formatTime,
    isRecording,
    cancelRecording,
    recordingDone,
    submitRecording,
    recordingSubmiting,
    reRecord,
  };
};

export default useVoiceRecordingHook;
