export default (theme) => ({
  root: {
    margin: '20px 0px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconBox: {
    fill: theme.palette.primary.sub,
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  divider: {
    width: '100%',
    margin: '10px 0px',
    height: 1,
    backgroundColor: theme.palette.primary.sub,
  },
  descriptionContainer: {},
  description: {
    color: theme.palette.text.sub,
  },
  servicesContainer: {
    paddingTop: 30,
  },
  filledBackground: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'rgba(59, 146, 140, 0.1)',
  },
  whiteBackground: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'white',
  },
  showAllContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  showAllButton: {
    ...theme.typography.t20,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
});
