export default (theme) => ({
  root: {
    paddingTop: 24,
    minWidth: 665,
    minHeight: 325,
    width: '100%',
  },
  container: {
    borderRadius: 21,
    width: '100%',
    height: '100%',
    background: theme.palette.gradient['occ-gradient'],
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    position: 'relative',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  description: {
    width: '65%',
  },
  actionButton: {
    fontSize: 22,
    padding: 12,
    gap: 12,
    background: `${theme.palette.primary.white}99`,
    borderRadius: 8,
    textTransform: 'capitalize',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 32,
    left: 32,
    right: 32,
    '&:hover': {
      background: theme.palette.primary.white,
    },
  },
  endIcon: {
    width: 40,
    height: 40,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: theme.palette.aqua.deep,
    color: theme.palette.primary.white,
  },
  overlayImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    maxWidth: 675,
    maxHeight: 300,
    width: 'auto',
    height: 'auto',
    borderRadius: 21,
  },
});
