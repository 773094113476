export default (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    gap: 24,
    padding: '50px 0',
  },
  faceIcon: {
    width: 75,
    height: 75,
  },
  description: {
    whiteSpace: 'pre-line',
  },
  demoVideoThumbnail: {
    width: '100%',
    maxWidth: 480,
    cursor: 'pointer',
    margin: '50px 0',
    borderRadius: 8,
  },
  whyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.white,
    padding: '25px 50px',
    textAlign: 'left',
  },
  continueButton: {
    textTransform: 'capitalize',
    width: 300,
  },
});
