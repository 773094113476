import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Grid, withStyles } from '@material-ui/core';
import styles from './career-profile.style';
import useCareerProfileHook from './useCareerProfileHook';
import { BackButton, Utility } from '../../../../../shared';
import { Attribution } from '../../../../../content/careers/components';
import { CareerOutline, ProfileSection, SalaryInfo } from '../../components';
import { FormattedMessage } from 'react-intl';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

const CareerProfile = (props) => {
  const { classes } = props;
  const {
    careerId,
    outline,
    jobOutlook,
    personality,
    abilities,
    knowledge,
    skillsAndTechnology,
    similarJobs,
    isDataLoading,
    videoPlayerProps,
    goBack,
    handleBookmarkAdd,
    handleBookmarkRemove,
    launchCareerProfile,
    handleVideoModalOpen,
    handleVideoModalClose,
  } = useCareerProfileHook(props);

  return (
    <Box>
      <BackButton onClick={goBack} />

      <ModalVideo
        channel="custom"
        url={videoPlayerProps.videoUrl}
        autoplay
        isOpen={videoPlayerProps.isOpen}
        onClose={handleVideoModalClose}
      />

      <Grid container className={classes.container}>
        <CareerOutline
          id={careerId}
          {...outline}
          onPlayVideo={handleVideoModalOpen}
          onAddBookmark={handleBookmarkAdd}
          onRemoveBookmark={handleBookmarkRemove}
        />

        <ProfileSection
          title={<FormattedMessage id="jobOutlook" />}
          badge={jobOutlook.category}
          outline={jobOutlook.description}
          isDataAvailable={!Utility.isNullOrEmpty(jobOutlook)}
          isDataLoading={isDataLoading}
        >
          {!Utility.isNullOrEmpty(jobOutlook.salary) && <SalaryInfo {...jobOutlook.salary} />}
        </ProfileSection>
      </Grid>

      <Grid container className={classes.container}>
        <ProfileSection
          title={<FormattedMessage id="abilities" />}
          keyPoints={abilities}
          isDataAvailable={!Utility.isNullOrEmpty(abilities)}
          isDataLoading={isDataLoading}
        />
        <ProfileSection
          title={<FormattedMessage id="knowledge" />}
          keyPoints={knowledge}
          isDataAvailable={!Utility.isNullOrEmpty(knowledge)}
          isDataLoading={isDataLoading}
        />
        <ProfileSection
          title={<FormattedMessage id="personality" />}
          outline={personality.description}
          keyPoints={personality.keyPoints}
          isDataAvailable={!Utility.isNullOrEmpty(personality)}
          isDataLoading={isDataLoading}
        />
      </Grid>

      <Grid container className={classes.container}>
        <ProfileSection
          title={<FormattedMessage id="skillsAndTech" />}
          keyPoints={skillsAndTechnology}
          wrapperGridProps={{ sm: 12, md: 8 }}
          isDataAvailable={!Utility.isNullOrEmpty(skillsAndTechnology)}
          isDataLoading={isDataLoading}
        />
        <ProfileSection
          title={<FormattedMessage id="similarJobs" />}
          isDataAvailable={!Utility.isNullOrEmpty(similarJobs)}
          isDataLoading={isDataLoading}
        >
          <Grid container spacing={3}>
            {similarJobs.map((job) => (
              <Grid key={job.id} item sm={12} md={12} lg={12}>
                <Button
                  id={`similar-job-${job.id}`}
                  onClick={(e) => {
                    launchCareerProfile(e, job.id);
                  }}
                  className={classes.similarJobItem}
                  aria-label={`View ${job.name} profile`}
                >
                  {job.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </ProfileSection>
      </Grid>

      <Grid container>
        <Grid item sm={8} md={6} className={classes.attributionContainer}>
          <Attribution />
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ locale }) => {
  return {
    selectedLocale: locale.locale,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CareerProfile));
