export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    padding: '24px 32px',
  },
  dataContainer: {
    marginTop: 30,
    width: '100%',
  },
  noData: {
    marginLeft: 20,
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    margin: '30px 0px',
  },
  attributionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  filtersArea: {
    width: '100%',
    marginTop: 30,
  },
  fullWidth: {
    width: '100%',
  },
  dialogPaper: {
    borderRadius: 10,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.sub,
    color: 'white',
    padding: 35,
  },
  title: {
    ...theme.typography.t30,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  closeDialogBtn: {
    color: 'white',
  },
});
