import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { PlayAreaTop } from '..';
import { Utility } from '../../../../shared/services';
import { PlayMainService } from '../../services';
import styles from './games.style';

const getUrlFromAttributes = (attributes) => {
  const attribute = attributes.filter((attribute) => attribute.attributeName === 'Game Url');
  if (attribute.length) {
    return attribute[0].attributeValue || '';
  }
  return '';
};

const Games = (props) => {
  const playMainService = new PlayMainService();
  const { classes } = props;
  const utilityService = new Utility();
  const { attributes } = props;
  const [isMounted, setMounted] = React.useState(false);
  React.useLayoutEffect(() => {
    if (!isMounted) {
      utilityService.postMessageOnIframeResource('game-resource-frame');
      setMounted(true);
    }
  }, [isMounted, utilityService]);
  return (
    <Grid container item>
      <PlayAreaTop
        redeem={props.redeem}
        schedule={props.schedule}
        status={props.status.games}
        tabName="Games"
        updatePlayStatus={props.updatePlayStatus}
        updateRedeemState={props.updateRedeemState}
      />
      {(props.status.games.type === playMainService.ON ||
        props.status.games.type === playMainService.IN_SCHEDULE ||
        props.status.games.type === playMainService.NO_SCHEDULE_DEFINED) && (
        <iframe
          id="game-resource-frame"
          title="games-resource-frame"
          className={[
            classes.framedElement,
            !props.redeem &&
            props.status.games.price !== 0 &&
            utilityService.checkStatusExists(props.features, 'Incentive_Engine')
              ? classes.disabledFrame
              : null,
          ].join(' ')}
          src={getUrlFromAttributes(attributes)}
          allowFullScreen
          width="100%"
          height="1000px"
        ></iframe>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app }) => app;

export default withStyles(styles)(connect(mapStateToProps)(Games));
