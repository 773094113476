import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as earnTokenStore from '../../../../core/store/earn-tokens';
import { Constant, Rest, Utility } from '../../../../shared';
import useStyles from './details-top.style';
import { toggleToast } from '../../../../core';

function PathwayDetailTopSection(props) {
  const utilityService = new Utility();
  const { history, pathwayDetails, isScrollDown, showToast } = props;
  const classes = useStyles();
  const goBack = () => {
    history.goBack();
  };

  const getTitleElement = () => {
    return (
      <Typography data-cy-name={`title-${props.pathwayDetails.id}`} variant="h2">
        {pathwayDetails.name || ''}
      </Typography>
    );
  };

  const getBackButton = (classes) => {
    return (
      <Button
        className={`${classes.backButtonStyling} ${!isScrollDown ? classes.backButtonMargin : ''}`}
        onClick={(e) => {
          goBack();
        }}
      >
        <ChevronLeftIcon className={classes.backButtonIcon} />
        <FormattedMessage id="back" />
      </Button>
    );
  };

  const getLaunchButton = (classes) => {
    return (
      <Button
        onClick={async () => {
          try {
            const url = await new Rest().getLaunchUrl(pathwayDetails.id);
            if (url.data) {
              window.open(url.data, '_blank');
              return;
            }
            showToast({
              variant: 'error',
              message: 'Failed to fetch URL.',
              isOpen: true,
              showCancelButton: true,
            });
          } catch (e) {
            console.log(e);
            showToast({
              variant: 'error',
              message: 'Failed to fetch URL.',
              isOpen: true,
              showCancelButton: true,
            });
          }
        }}
        className={[
          `${classes.backButtonStyling} ${!isScrollDown ? classes.backButtonMargin : classes.launchButtonScrolled}`,
          classes.launchBtn,
        ].join(' ')}
      >
        <FormattedMessage id="launch" />
      </Button>
    );
  };

  const getProgressBarElement = () => {
    if (!pathwayDetails.progress || pathwayDetails.progress.totalNoOfItem === 0) {
      return null;
    }
    return (
      <Box data-cy-name={`progress-${props.pathwayDetails.id}`} component="div" className={classes.progressBarWrapper}>
        <Box component="div" textAlign="right">
          <Typography className={classes.subText}>
            <FormattedMessage
              id="progressText"
              values={{
                completedItems: pathwayDetails.progress ? pathwayDetails.progress.completedItem : 0,
                totalItems: pathwayDetails.progress ? pathwayDetails.progress.totalNoOfItem : 0,
              }}
            />
          </Typography>
        </Box>

        <LinearProgress variant="determinate" value={props.pathwayDetails.percentageCompleted} />
      </Box>
    );
  };

  return (
    <Box component="div" className={[classes.root, isScrollDown ? classes.collapsedBar : null].join(' ')}>
      {!isScrollDown ? (
        <Grid container>
          <Grid item sm={6}>
            <Box className={classes.containerStyling} textAlign="left">
              {Constant.CONTENT_AREA[props.pathwayDetails.contentAreaId] && (
                <Chip
                  style={
                    Constant.CONTENT_AREA[props.pathwayDetails.contentAreaId]
                      ? Constant.CONTENT_AREA[props.pathwayDetails.contentAreaId.toString()].chip
                      : null
                  }
                  icon={Constant.CONTENT_AREA[props.pathwayDetails.contentAreaId].icons.icon}
                  label={Constant.CONTENT_AREA[props.pathwayDetails.contentAreaId].name}
                />
              )}
            </Box>
          </Grid>

          <Grid item sm={6}>
            {/*  right spacing*/}
          </Grid>

          <Grid item sm={6}>
            <Grid className={classes.containerStyling} alignItems="flex-end">
              {getTitleElement()}
            </Grid>

            <Grid className={classes.containerStyling} textAlign="right">
              {getProgressBarElement()}
            </Grid>

            <Grid container alignItems="center" className={classes.containerStyling}>
              {getBackButton(classes)} &nbsp;
              {getLaunchButton(classes)}
            </Grid>
          </Grid>

          <Grid item sm={6}>
            <Box component="span" display="block" textAlign="left">
              <Typography variant="h6">{pathwayDetails.description || ''}</Typography>
            </Box>

            {pathwayDetails.assignmentDate ? (
              <Box component="span" display="block" textAlign="left">
                <Box component="span" className={classes.assignedText}>
                  <FormattedMessage id="assigned" />:{' '}
                </Box>
                <Moment
                  id="assignedDate"
                  format=" MMM DD, YYYY"
                  className={[classes.dateText, classes.assignedText].join(' ')}
                >
                  {pathwayDetails.assignmentDate}
                </Moment>
              </Box>
            ) : (
              ''
            )}

            {pathwayDetails.startDate ? (
              <Box component="span" display="block" textAlign="left">
                <Box component="span" className={classes.assignedText}>
                  <FormattedMessage id="started" />:{' '}
                </Box>
                <Moment id="startDate" format=" MMM DD, YYYY" className={[classes.dateText, classes.assignedText].join(' ')}>
                  {pathwayDetails.startDate}
                </Moment>
              </Box>
            ) : (
              ''
            )}

            {pathwayDetails.completionDate ? (
              <Box component="span" display="block" textAlign="left">
                <Box component="span" className={classes.assignedText}>
                  <FormattedMessage id="completed" />:{' '}
                </Box>
                <Moment
                  id="completedDate"
                  format=" MMM DD, YYYY"
                  className={[classes.dateText, classes.assignedText].join(' ')}
                >
                  {pathwayDetails.completionDate}
                </Moment>
              </Box>
            ) : (
              ''
            )}

            {(props.rulesCollection || []).find((rule) => rule.serviceId === pathwayDetails.serviceId) &&
              utilityService.checkStatusExists(props.features, 'Incentive_Engine') && (
                <Button
                  onClick={() =>
                    props.toggleEarnTokenDialog({
                      serviceId: pathwayDetails.serviceId,
                    })
                  }
                  className={classes.earnTokenButton}
                >
                  <Grid container item alignItems="center">
                    <Box display="flex" alignItems="center" component="div">
                      <StarsIcon className={classes.earnTokenIcon} />
                    </Box>
                    <Box component="div">
                      <Typography align="left" className={classes.earnTokenTitle}>
                        <FormattedMessage id="earnTokensTitle" />
                      </Typography>
                      <Typography className={classes.earnTokenSubTitle}>
                        <FormattedMessage id="viewHowToGetTokens" />
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              )}
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid alignItems="center" container item sm={3} md={2}>
            <Box component="div" className={classes.scrolledBackWrapper}>
              {getBackButton(classes)}
              {getLaunchButton(classes)}
            </Box>
          </Grid>
          <Grid item sm={9} md={10}>
            <Box component="div">{getTitleElement()}</Box>
            <Grid sm={10} component="div">
              {getProgressBarElement()}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

const mapStateToProps = ({ app, earnToken }) => {
  return {
    ...app,
    ...earnToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEarnTokenDialog: (props) => dispatch(earnTokenStore.toggleDialog(props)),
    showToast: (data) => dispatch(toggleToast(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwayDetailTopSection);
