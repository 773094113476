export default (theme) => ({
  root: {
    height: 'calc(100dvh - 165px)',
  },
  fullHeight: {
    height: '100%',
  },
  hideElement: {
    display: 'none',
  },
  container: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  backButtonContainer: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  header: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
});
