import { useCallback, useEffect, useRef, useState } from 'react';
import { AUTH_CONSTANTS } from '../../../../../auth/constants/auth-constants';
import { loadAnimationData } from './capture-image.helper';
import { Webcam } from '../../../../services/webcam/webcam.service';

/**
 * Hook to capture the image.
 *
 * @param {func} onProcessImage - The function to process the image.
 * @param {number} imageProcessingCount - The number of images to process.
 * @param {func} props.onContinue - The function to continue to the next step.
 * @returns {object}
 */
const useCaptureImageHook = ({ onProcessImage, imageProcessingCount, ...props }) => {
  const webcamRef = useRef(null);

  const [currentPosition, setCurrentPosition] = useState(AUTH_CONSTANTS.FACE_POSITION.FRONT);
  const [animationData, setAnimationData] = useState({});
  const [capturedImage, setCapturedImage] = useState(null);
  const [timerCount, setTimerCount] = useState(AUTH_CONSTANTS.CAPTURE_IMAGE_TIMER);
  const [startCaptureTimer, setStartCaptureTimer] = useState(false);
  const [webcamInitialized, setWebcamInitialized] = useState(false);
  const [webcamError, setWebcamError] = useState('');
  const [imagesProcessed, setImagesProcessed] = useState([]);
  const [faceValidationStatus, setFaceValidationStatus] = useState(null);
  const [faceValidationError, setFaceValidationError] = useState('');

  /**
   * Effect to initialize the webcam.
   */
  useEffect(() => {
    const initializeWebcam = () => {
      const webcamElement = document.getElementById('webcam');
      const canvasElement = document.getElementById('canvas');
      const snapSoundElement = document.getElementById('snapSound');

      if (webcamElement && canvasElement && snapSoundElement) {
        webcamElement.width = webcamElement.scrollWidth;
        webcamElement.height = webcamElement.scrollHeight;

        webcamRef.current = new Webcam(webcamElement, 'user', canvasElement, snapSoundElement);

        webcamRef.current
          .start()
          .then((result) => {
            setWebcamInitialized(true);
          })
          .catch((err) => {
            handleWebcamError(err.name);
          });
      }
    };

    initializeWebcam();
  }, []);

  /**
   * Function to handle the webcam error.
   *
   * @param {string} error - The error name.
   */
  const handleWebcamError = (error) => {
    if (error === AUTH_CONSTANTS.WEBCAM_ERROR.NOT_FOUND) {
      setWebcamError('biometric-authentication.webcam-error.camera-not-found');
    } else if (error === AUTH_CONSTANTS.WEBCAM_ERROR.NOT_ALLOWED) {
      setWebcamError('biometric-authentication.webcam-error.camera-permission-denied');
    } else {
      setWebcamError('biometric-authentication.webcam-error.something-went-wrong');
    }
  };

  /**
   * Effect to fetch the animation data.
   */
  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const data = await loadAnimationData();
        setAnimationData(data);
      } catch (error) {
        console.error('Error fetching animation data:', error);
      }
    };

    fetchAnimationData();
  }, []);

  /**
   * Function to handle the start capture event.
   */
  const handleStartCapture = () => {
    setStartCaptureTimer(true);
  };

  /**
   * Effect to start the capture timer.
   */
  useEffect(() => {
    if (startCaptureTimer) {
      const timer = setInterval(() => {
        setTimerCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [startCaptureTimer]);

  /**
   * Function to capture the image and process it.
   */
  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const image = webcamRef.current.snap();
      setCapturedImage(image);

      const data = { image, position: currentPosition };
      onProcessImage(data, (result, error) => {
        setFaceValidationStatus(
          error ? AUTH_CONSTANTS.FACE_VALIDATION_STATUS.FAILED : AUTH_CONSTANTS.FACE_VALIDATION_STATUS.SUCCESS
        );
        setFaceValidationError(error);

        if (result) {
          setImagesProcessed((prevImages) => [...prevImages, image]);
          setCurrentPosition(result.nextPosition);
        }
      });
    }
  }, [onProcessImage, currentPosition]);

  /**
   * Effect to capture the image when the timer count reaches 0.
   */
  useEffect(() => {
    if (timerCount === 0) {
      setStartCaptureTimer(false);
      setTimerCount(AUTH_CONSTANTS.CAPTURE_IMAGE_TIMER);
      captureImage();
    }
  }, [timerCount, captureImage]);

  /**
   * Function to continue to the next step.
   * It will only continue when all the images are processed.
   */
  const onContinue = () => {
    if (imagesProcessed.length === imageProcessingCount) {
      props.onContinue(imagesProcessed);
    }
    setFaceValidationStatus(null);
    setCapturedImage(null);
  };

  /**
   * Function to retake the image.
   */
  const onRetake = () => {
    setFaceValidationStatus(null);
    setFaceValidationError('');
    setCapturedImage(null);
  };

  return {
    currentPosition,
    animationData,
    capturedImage,
    timerCount,
    startCaptureTimer,
    webcamInitialized,
    webcamError,
    faceValidationStatus,
    faceValidationError,
    imagesProcessed,
    handleStartCapture,
    onContinue,
    onRetake,
  };
};

export default useCaptureImageHook;
