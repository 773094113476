export default theme => ({
  sectionGap: {
    marginTop: 20
  },
  sectionSmallGap: {
    marginTop: 10
  },
  mainWrapper: {
    transition: "all 0.2s"
  },
  bottomThreshold: {
    marginBottom: 340
  },
  achievementTitleWrapper: {
    // marginTop: 15
  }
});
