import React from 'react';
import { Box, Button, Grid, Hidden, withStyles, withWidth } from '@material-ui/core';
import styles from './staff-messaging.style';
import { ConversationView, MessageSidebar, NotificationBanner } from '../../components';
import useStaffMessagingHook from './useStaffMessagingHook';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

const StaffMessaging = ({ classes, ...props }) => {
  const {
    dataLoading,
    showComposeMessage,
    showConversation,
    toggleComposeMessage,
    showConversationView,
    resetConversationView,
    isActiveModeMessages,
    isActiveModeAnnouncements,
    shouldHideSidebar,
    shouldHideConversation,
  } = useStaffMessagingHook({ ...props });
  const intl = useIntl();

  return (
    <Box>
      <Box component={'span'} id="messaging-event" />
      {!dataLoading && (
        <Box className={classes.root}>
          <Grid id="staff-messaging-main-container" container className={classes.container}>
            {showConversationView() && (
              <Grid item sm={12} className={classes.header}>
                {isActiveModeMessages() && <NotificationBanner />}
                <Hidden mdUp>
                  <Box className={classes.backButtonContainer}>
                    <Button
                      id="back-to-threads-view"
                      className={classes.backButton}
                      startIcon={<ArrowBackIosIcon />}
                      onClick={resetConversationView}
                      color="secondary"
                      aria-label={intl.formatMessage({
                        id: isActiveModeAnnouncements() ? 'backToAnnouncements' : 'backToMessagesInbox',
                      })}
                    >
                      <FormattedMessage id={isActiveModeAnnouncements() ? 'backToAnnouncements' : 'backToMessagesInbox'} />
                    </Button>
                  </Box>
                </Hidden>
              </Grid>
            )}

            <Grid item container spacing={2} className={classes.fullHeight}>
              <Grid
                id="staff-messaging-sidebar-container"
                item
                sm={12}
                md={5}
                className={[classes.fullHeight, shouldHideSidebar() ? classes.hideElement : ''].join(' ')}
              >
                <MessageSidebar
                  openComposeMessageView={() => {
                    toggleComposeMessage(true);
                  }}
                  resetConversationView={resetConversationView}
                  isActiveModeMessages={isActiveModeMessages}
                  isActiveModeAnnouncements={isActiveModeAnnouncements}
                />
              </Grid>
              <Grid
                id="staff-messaging-conversation-container"
                item
                sm={12}
                md={7}
                className={[classes.fullHeight, shouldHideConversation() ? classes.hideElement : ''].join(' ')}
              >
                <ConversationView
                  isComposing={showComposeMessage}
                  showConversation={showConversation}
                  showConversationView={showConversationView}
                  isActiveModeMessages={isActiveModeMessages}
                  isActiveModeAnnouncements={isActiveModeAnnouncements}
                  hideMe={shouldHideConversation()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    staffMessaging: state.staffMessaging,
  };
};

export default connect(mapStateToProps)(withWidth()(withStyles(styles)(StaffMessaging)));
