export default (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: 10,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '20px 20px 0px 20px',
  },
  tabsList: {
    display: 'flex',
    borderBottom: '2px solid',
    borderColor: `${theme.palette.primary.light} !important`,
    paddingBottom: 0,
  },
  tabItem: {
    width: 'initial',
    height: 40,
    fontWeight: 'bold',
    fontSize: 20,
    marginRight: 10,
    marginBottom: -2,
    opacity: 0.5,
    paddingBottom: 25,
    whiteSpace: 'nowrap',
  },
  activeItem: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderBottom: '2px solid',
    opacity: 1,
  },
  icon: {
    fill: theme.palette.secondary.main,
    minWidth: 30,
    position: 'relative',
  },
  body: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
  newMessageButton: {
    textTransform: 'uppercase',
    fontWeight: 700,
    backgroundColor: theme.palette.aqua.extraDeep,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.primary.light}`,
    padding: 20,
  },
  redDot: {
    position: 'absolute',
    top: '-10px',
    right: 0,
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.red,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
