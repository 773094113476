import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 999,
    position: 'fixed',
    top: 0,
    width: '100%',
    background: 'white',
    height: 80,
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.167,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  topLogoMain: {
    margin: '15px 20px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  logoutWrapper: {
    margin: '0px 20px',
    whiteSpace: 'nowrap',
    '& > button': {
      backgroundColor: theme.palette.aqua.extraDeep,
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  textSecondary: {
    color: theme.palette.greyScale[110],
  },
  credentialCardKey: {
    margin: '0px 20px',
    width: '24px',
    height: '24px',
  },
}));
