import moment from 'moment';
import { Utility, Constant } from '../../../shared';
import { Http } from '../';

export class Tracker {
  static data = []; // TRACKER DATA
  static requestId = 0;
  static RouteEnum = {
    entertain: {
      entertain: '/entertain',
      video: '/entertain/video',
      radio: '/entertain/radio',
      game: '/entertain/games',
    },
    pathways: {
      pathway: '/pathway',
      pathway_detail: '/pathway-details/',
    },
    explore: {
      explore: '/explore',
    },
    achievement: {
      achievement: '/achievements',
    },
    form: {
      form: '/forms',
    },
    message: {
      message: '/messaging',
      announcement: '/messaging/announcement',
    },
  };
  static TIME_TRACKED = {
    Pathways: 0,
    Explore: 0,
    Achievements: 0,
    Forms: 0,
    Video: 0,
    Radio: 0,
    Games: 0,
    Messaging: 0,
  };
  moduleNames = {
    Pathway: 'Pathways',
    Explore: 'Explore',
    Forms: 'Forms',
    Achievements: 'Achievements',
    Messaging: 'Messaging',
    Entertainment: {
      root: 'Entertainment',
      video: 'Video',
      games: 'Games',
      radio: 'Radio',
    },
  };

  /**
   *
   * @param {boolean} activateTimeout
   * @desc Activate's timeout based upon flag.
   * @return {void}
   */
  constructor(activateTimeout = false) {
    if (activateTimeout) {
      this.activateTimeout();
    }
  }

  /**
   * @name getSeconds
   * @param {object} trackerNode
   * @desc Retrives seconds from tracker node.
   * @return {number} seconds
   */
  getSeconds = (trackerNode) => {
    let { startTime, endTime } = trackerNode;
    startTime = moment.parseZone(startTime);
    if (endTime !== null) {
      endTime = moment.parseZone(endTime);
    } else {
      endTime = moment.utc();
    }
    const momentDuration = moment.duration(endTime.diff(startTime));
    return Math.floor(momentDuration.asSeconds());
  };

  /**
   * @name getPathwaysData
   * @param {object} data
   * @param {number} resourceId
   * @desc Prepares pathways data as per server requirements.
   * @return {object}
   */
  getPathwaysData = (data) => {
    let requestPayload = [];
    const splitRoute = data.resourceName.split('/');
    const isChildResource = splitRoute.length > 2;
    if (isChildResource) {
      // IF ITS PATHWAYS-DETAILS/<ID>
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          splitRoute[3],
          decodeURI(splitRoute[2]),
          '',
          this.moduleNames.Pathway,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    } else {
      // IF ITS PATHWAYS
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          '',
          `${this.moduleNames.Pathway}-Page`,
          '',
          this.moduleNames.Pathway,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    }
    return requestPayload;
  };

  /**
   * @name getExploreData
   * @param {object} data
   * @desc Prepares pathways data as per server requirements.
   * @return {object}
   */
  getExploreData = (data) => {
    return data.tracker.map((trackerNode) =>
      this.getRequestObject(
        trackerNode.requestId,
        Utility.USER_DATA.preferred_username,
        '',
        `${this.moduleNames.Explore}-Page`,
        '',
        this.moduleNames.Explore,
        trackerNode.startTime,
        this.getSeconds(trackerNode)
      )
    );
  };

  /**
   * @name getAchievementsData
   * @param {object} data
   * @desc Prepares pathways data as per server requirements.
   * @return {object}
   */
  getAchievementsData = (data) => {
    return data.tracker.map((trackerNode) =>
      this.getRequestObject(
        trackerNode.requestId,
        Utility.USER_DATA.preferred_username,
        '',
        `${this.moduleNames.Achievements}-Page`,
        '',
        this.moduleNames.Achievements,
        trackerNode.startTime,
        this.getSeconds(trackerNode)
      )
    );
  };

  /**
   * @name getFormsData
   * @param {object} data
   * @desc Prepares pathways data as per server requirements.
   * @return {object}
   */
  getFormsData = (data) => {
    let requestPayload = [];
    const splitRoute = data.resourceName.split('/');
    const isChildResource = splitRoute.length > 2;
    if (isChildResource) {
      // IF ITS FORM/<ID>
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          splitRoute[3],
          splitRoute[2],
          '',
          this.moduleNames.Forms,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    } else {
      // IF ITS PATHWAYS
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          '',
          `${this.moduleNames.Forms}-Page`,
          '',
          this.moduleNames.Forms,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    }
    return requestPayload;
  };

  /**
   * @name getRequestObject
   * @param {number} requestId
   * @param {string} userName
   * @param {string} resourceExternalId
   * @param {string} resourceName
   * @param {string} rootResourceExternalId
   * @param {string} rootResourceName
   * @param {string} activityDateTime
   * @param {string} timespentInSecsAdditional
   * @desc Prepare's request payload object for server.
   * @return {object}
   */
  getRequestObject = (
    requestId,
    userName,
    resourceExternalId = '',
    resourceName,
    rootResourceExternalId,
    rootResourceName,
    activityDateTime,
    timespentInSecsAdditional
  ) => ({
    requestId,
    userName,
    resourceExternalId,
    resourceName,
    rootResourceExternalId,
    rootResourceName,
    activityDateTime,
    timespentInSecsAdditional,
  });

  /**
   * @name getEntertainmentData
   * @param {object} data
   * @desc Prepares entertainment data as per server requirements.
   * @return {object}
   */
  getEntertainmentData = (data) => {
    let requestPayload = [];
    const splitRoute = data.resourceName.split('/');
    const isChildResource = splitRoute.length > 2;
    if (isChildResource) {
      // IF ITS FORM/<ID>
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          '',
          splitRoute[2],
          '',
          this.moduleNames.Entertainment.root,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    } else {
      // IF ITS PATHWAYS
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          '',
          `${this.moduleNames.Entertainment.root}-Page`,
          '',
          this.moduleNames.Entertainment.root,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    }
    return requestPayload;
  };

  /**
   * @name getMessageData
   * @param {object} data
   * @desc Prepares message data as per server requirements.
   * @return {object}
   */
  getMessageData = (data) => {
    let requestPayload = [];
    const splitRoute = data.resourceName.split('/');
    const isChildResource = splitRoute.length > 2;
    if (isChildResource) {
      // IF ITS FORM/<ID>
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          splitRoute[3],
          splitRoute[2],
          '',
          this.moduleNames.Messaging,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    } else {
      // IF ITS PATHWAYS
      requestPayload = data.tracker.map((trackerNode) =>
        this.getRequestObject(
          trackerNode.requestId,
          Utility.USER_DATA.preferred_username,
          '',
          `${this.moduleNames.Messaging}-Page`,
          '',
          this.moduleNames.Messaging,
          trackerNode.startTime,
          this.getSeconds(trackerNode)
        )
      );
    }
    return requestPayload;
  };

  fakeData = (data) => {
    return Promise.resolve({
      statusCode: 0,
      statusMessage: 'Ok',
      errorList: [],
    });
  };

  /**
   * @name recordTrackedData
   * @param {array} requestPayload
   * @param {array} errorRequestIds
   * @desc Records tracked data.
   * @return {void}
   */
  recordTrackedData = (recordedData, errorRequestIds) => {
    recordedData.forEach((data) => {
      if (errorRequestIds.indexOf(data.requestId) > -1) {
        // RETURNING SINCE TIME
        // IS NOT RECORDED IN DB
        return;
      }

      if (data.rootResourceName === this.moduleNames.Entertainment.root) {
        //ENTERTAINMENT DEPT HANDLING
        if (data.resourceName.toLowerCase() === this.moduleNames.Entertainment.video.toLowerCase()) {
          // VIDEO
          Tracker.TIME_TRACKED.Video += data.timespentInSecsAdditional;
        } else if (data.resourceName.toLowerCase() === this.moduleNames.Entertainment.radio.toLowerCase()) {
          // RADIO
          Tracker.TIME_TRACKED.Radio += data.timespentInSecsAdditional;
        } else if (data.resourceName.toLowerCase() === this.moduleNames.Entertainment.games.toLowerCase()) {
          // GAMES
          Tracker.TIME_TRACKED.Games += data.timespentInSecsAdditional;
        }
      } else {
        Tracker.TIME_TRACKED[data.rootResourceName] += data.timespentInSecsAdditional;
      }
    });
  };

  /**
   * @name cleanUpSyncedRequests
   * @param {array} errorRequestIds
   * @param {array} requestPayload Payload used for update (for clean-up purpose)
   * @desc Remove's all synced requests except for those which has error
   * so that they can be synced later-on.
   * @return {void}
   */
  cleanUpSyncedRequests = (errorRequestIds, requestPayload) => {
    const updatedData = [];
    this.recordTrackedData(requestPayload, errorRequestIds);
    Tracker.data.forEach((data) => {
      const errorTrackers = [];
      data.tracker.forEach((trackerNode) => {
        if (errorRequestIds.indexOf(trackerNode.requestId) > -1) {
          errorTrackers.push(trackerNode);
        }
      });

      if (errorTrackers.length > 0) {
        // ONLY PUSHING ERRORNOUS DATA
        data['tracker'] = errorTrackers;
        updatedData.push(data);
      } else if (!this.isMinimumTimeAchieved(data)) {
        updatedData.push(data);
      } else if (data.isActive && this.thresholdNotAchieved(data.resourceName)) {
        // GETTING ACTIVE NODE
        // TO CONTINUE SYNCING
        data['tracker'] = data.tracker
          .filter((trackerNode) => trackerNode.isActive)
          .map((trackerNode) => {
            // UPDATING START TIME TO CURRENT
            trackerNode['startTime'] = moment.utc().format();
            return trackerNode;
          });
        updatedData.push(data);
      }
    });
    Tracker.data = updatedData;
  };

  /**
   * @name isMinimumTimeAchieved
   * @param {object} data
   * @desc Checks if minimum time to log is achieved or not.
   * @return {boolean}
   */
  isMinimumTimeAchieved = (data) => {
    let secondsDiff = 0;
    data.tracker.forEach((tracker) => {
      const startTime = moment.utc(tracker.startTime);
      let endTime = null;
      if (tracker.endTime) {
        endTime = moment.utc(tracker.endTime);
      } else {
        endTime = moment.utc();
      }
      const duration = moment.duration(endTime.diff(startTime));
      secondsDiff += duration.asSeconds();
    });
    secondsDiff = Math.ceil(secondsDiff);
    const { resourceName } = data;
    if (resourceName.indexOf(Tracker.RouteEnum.pathways.pathway) > -1 && secondsDiff >= Constant.TRACKER_MIN_TIME.Pathway) {
      return true;
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.explore.explore) > -1 &&
      secondsDiff >= Constant.TRACKER_MIN_TIME.Explore
    ) {
      return true;
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.achievement.achievement) > -1 &&
      secondsDiff >= Constant.TRACKER_MIN_TIME.Achievements
    ) {
      return true;
    } else if (resourceName.indexOf(Tracker.RouteEnum.entertain.entertain) > -1) {
      // ENTERTAINMENT DEPT. HANDLING
      if (resourceName.indexOf(Tracker.RouteEnum.entertain.video) > -1 && secondsDiff >= Constant.TRACKER_MIN_TIME.Video) {
        // VIDEO
        return true;
      } else if (
        resourceName.indexOf(Tracker.RouteEnum.entertain.radio) > -1 &&
        secondsDiff >= Constant.TRACKER_MIN_TIME.Radio
      ) {
        // RADIO
        return true;
      } else if (
        resourceName.indexOf(Tracker.RouteEnum.entertain.game) > -1 &&
        secondsDiff >= Constant.TRACKER_MIN_TIME.Games
      ) {
        // GAMES
        return true;
      }
    } else if (resourceName.indexOf(Tracker.RouteEnum.form.form) > -1 && secondsDiff >= Constant.TRACKER_MIN_TIME.Forms) {
      return true;
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.message.message) > -1 &&
      secondsDiff >= Constant.TRACKER_MIN_TIME.Messaging
    ) {
      return true;
    } else {
      return false;
    }
    return false;
  };

  /**
   * @name initiateServerRequest
   * @param {boolean} showLoader
   * @desc Initiates server request.
   * Prepare's request payload with nested wrapper methods.
   * @return {void}
   */
  initiateServerRequest = (showLoader = false, unloadRequest = false) => {
    return new Promise((resolve, reject) => {
      const requestPayload = [];
      Tracker.data.forEach((data, index) => {
        const resourceName = data.resourceName;
        if (this.isMinimumTimeAchieved(data)) {
          if (resourceName.indexOf(Tracker.RouteEnum.pathways.pathway) > -1) {
            requestPayload.push(...this.getPathwaysData(data, index));
          } else if (resourceName.indexOf(Tracker.RouteEnum.explore.explore) > -1) {
            requestPayload.push(...this.getExploreData(data));
          } else if (resourceName.indexOf(Tracker.RouteEnum.achievement.achievement) > -1) {
            requestPayload.push(...this.getAchievementsData(data));
          } else if (resourceName.indexOf(Tracker.RouteEnum.entertain.entertain) > -1) {
            requestPayload.push(...this.getEntertainmentData(data));
          } else if (resourceName.indexOf(Tracker.RouteEnum.form.form) > -1) {
            requestPayload.push(...this.getFormsData(data));
          } else if (resourceName.indexOf(Tracker.RouteEnum.message.message) > -1) {
            requestPayload.push(...this.getMessageData(data));
          } else {
            console.log('No Route Matched');
          }
        }
      });
      let queryParam = '?';
      if (!showLoader) {
        queryParam += 'noLoader=true';
      }
      if (requestPayload.length > 0) {
        if (unloadRequest) {
          Http.fetchRequest(`/timeontask`, { timeOnTasks: requestPayload });
        } else {
          Http.REQUEST.post(`/timeontask${queryParam}`, {
            timeOnTasks: requestPayload,
          }).then(
            ({ data }) => {
              this.cleanUpSyncedRequests(
                data.errorList.map((_errorLog) => _errorLog.requestId),
                requestPayload
              );
              resolve(true);
            },
            (_errorLog) => {
              resolve(true);
            }
          );
        }
      } else {
        resolve(true);
      }
    });
  };

  /**
   * @name activateTimeout
   * @desc Activate's timeout based.
   * Implementation works on every threshold.
   * @return {void}
   */
  activateTimeout = () => {
    setInterval(() => {
      this.initiateServerRequest();
    }, Constant.TRACKER_THRESHOLD.Interval);
  };

  /**
   * @name endLastSession
   * @param {datetime} endTime
   * @desc End's last session based upon isActive field.
   * @return {void}
   */
  endLastSession = (endTime = moment.utc().format()) => {
    // PASS BY REFERENCE
    const trackerCollection = Tracker.data;
    const activeSessionIndex = trackerCollection.findIndex((node) => node.isActive);
    if (activeSessionIndex > -1) {
      const activeTimeSlotIndex = trackerCollection[activeSessionIndex].tracker.findIndex(
        (trackerNode) => trackerNode.isActive
      );
      // DISABLING ACTIVE SESSIONS
      trackerCollection[activeSessionIndex].isActive = false;
      if (activeTimeSlotIndex > -1) {
        trackerCollection[activeSessionIndex].tracker[activeTimeSlotIndex].isActive = false;
        trackerCollection[activeSessionIndex].tracker[activeTimeSlotIndex].endTime = endTime;
      }
    }
  };

  /**
   * @name createNewSession
   * @param {string} resourceName
   * @param {datetime} startTime (optional)
   * @desc Create's new session in tracker node.
   * @return {void}
   */
  createNewSession = (resourceName, startTime) => {
    const trackerCollection = Tracker.data; // PASSING BY REF.
    const existingNodeIndex = trackerCollection.findIndex((node) => node.resourceName === resourceName);
    Tracker.requestId = Tracker.requestId + 1;
    if (existingNodeIndex > -1) {
      // IF EXISTING NODE IS PRESENT
      trackerCollection[existingNodeIndex].isActive = true;
      trackerCollection[existingNodeIndex].tracker.push({
        requestId: Tracker.requestId,
        startTime,
        endTime: null,
        isActive: true,
      });
    } else {
      // IF EXISTING NODE IS NOT PRESENT
      trackerCollection.push({
        resourceName,
        isActive: true,
        tracker: [
          {
            requestId: Tracker.requestId,
            startTime,
            endTime: null,
            isActive: true,
          },
        ],
      });
    }
  };

  /**
   * @name isNotRedaundantSession
   * @param {string} resourceName
   * @desc Checks if entry is redaundant or not.
   * @return {boolean}
   */
  isNotRedaundantSession = (resourceName) => {
    const trackerCollection = Tracker.data;
    return trackerCollection.every((trackerNode) => trackerNode.resourceName !== resourceName || !trackerNode.isActive);
  };

  /**
   * @name thresholdNotAchieved
   * @param {string} resourceName
   * @desc Checks if tracking threshold is achieved or not.
   * @return {boolean}
   */
  thresholdNotAchieved = (resourceName) => {
    const timeTracked = Tracker.TIME_TRACKED;
    if (
      resourceName.indexOf(Tracker.RouteEnum.pathways.pathway) > -1 &&
      timeTracked.Pathways < Constant.TRACKER_THRESHOLD.Pathway
    ) {
      return true;
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.explore.explore) > -1 &&
      timeTracked.Explore < Constant.TRACKER_THRESHOLD.Explore
    ) {
      return true;
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.achievement.achievement) > -1 &&
      timeTracked.Achievements < Constant.TRACKER_THRESHOLD.Achievements
    ) {
      return true;
    } else if (resourceName.indexOf(Tracker.RouteEnum.entertain.entertain) > -1) {
      // ENTERTAINMENT DEPT. HANDLING
      if (
        resourceName.indexOf(Tracker.RouteEnum.entertain.video) > -1 &&
        timeTracked.Video < Constant.TRACKER_THRESHOLD.Video
      ) {
        // VIDEO
        return true;
      } else if (
        resourceName.indexOf(Tracker.RouteEnum.entertain.radio) > -1 &&
        timeTracked.Radio < Constant.TRACKER_THRESHOLD.Radio
      ) {
        // RADIO
        return true;
      } else if (
        resourceName.indexOf(Tracker.RouteEnum.entertain.game) > -1 &&
        timeTracked.Games < Constant.TRACKER_THRESHOLD.Games
      ) {
        // GAMES
        return true;
      }
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.form.form) > -1 &&
      timeTracked.Forms < Constant.TRACKER_THRESHOLD.Forms
    ) {
      return true;
    } else if (
      resourceName.indexOf(Tracker.RouteEnum.message.message) > -1 &&
      timeTracked.Messaging < Constant.TRACKER_THRESHOLD.Messaging
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * @name append
   * @param {string} resourceName
   * @param {datetime} startTime
   * @desc Append's tracker object into collection.
   * @return {void}
   */
  append = (resourceName, startTime = moment.utc().format()) => {
    if (this.isNotRedaundantSession(resourceName)) {
      // ENDING EXISTING SESSION (IF ANY)
      this.endLastSession(startTime);

      // CREATING NEW SESSION ONLY IF ITS
      // UNDER THRESHOLD
      if (this.thresholdNotAchieved(resourceName)) {
        this.createNewSession(resourceName, startTime);
      }
    }
  };
}
