export default (theme) => ({
  root: {
    background: 'url(https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Books.png)',
    backgroundColor: theme.palette.colors.paleBlue,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 300,
    backgroundPosition: 'bottom right',
    [theme.breakpoints.down('md')]: {
      backgroundSize: '60%',
    },
  },
});
