export default (theme) => ({
  root: {},
  headingWrapper: {
    display: 'inline-block',
    zIndex: 99,
    fontWeight: 700,
    position: 'absolute',
    background: 'white',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    padding: '10px 15px 0px 15px',
    bottom: '-11%',
    left: 35,
  },
  backButtonContainer: {
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backButton: {
    color: theme.palette.primary.secondary,
    textDecoration: 'underline',
  },
  enrollBtnContainer: {
    marginTop: 50,
    display: 'flex',
  },
  enrollBtn: {
    width: '200px',
  },
  unenrollBtn: {
    width: '200px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    padding: '35px 50px 50px 50px',
    backgroundColor: 'white',
    borderRadius: 10,
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  },
  courseContainer: {
    margin: '15px 0px',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '25px 50px',
    alignItems: 'center',
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  courseNameContainer: {
    flex: 1,
  },
  activeText: {
    color: theme.palette.primary.active,
  },
  coursesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  coursesHeader: {
    marginTop: 50,
    padding: '0px 50px',
  },
  dialogContent: {
    padding: '30px !important',
  },
  confirmBtn: {
    marginRight: 15,
    textTransform: 'capitalize',
  },
  cancelBtn: {
    marginLeft: 15,
    textTransform: 'capitalize',
  },
  description: {
    whiteSpace: 'break-spaces',
  },
  dialogBtn: {
    padding: '12px 36px',
  },
  launchBtn: {
    width: 100,
    textTransform: 'capitalize',
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  boldText: {
    fontWeight: 700,
  },
  enrollFooterMask: {
    backgroundColor: theme.palette.aqua.deep,
    borderRadius: '16px',
    marginTop: 50,
    position: 'relative',
    height: 250,
  },
  enrollText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  enrollHeaderMask: {
    height: '91px',
    width: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  enrollImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});
