import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './pdq-assessment-results.style';
import PDQResultsDocument from '../../components/pdq-results-document/pdq-results-document';
import PDQResultsIntro from '../../components/pdq-results-intro/pdq-results-intro';
import PDQResultsProse from '../../components/pdq-results-prose/pdq-results-prose';
import PDQResultsQuantitative from '../../components/pdq-results-quantitative/pdq-results-quantitative';
import TopBanner from '../../components/top-banner/top-banner';
import BackButton from '../../../../shared/components/back-button/back-button';
import { DocumentTitle } from '../../../../v2/shared';
import { Permission } from '../../../../core';

export class PDQAssessmentResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'introduction',
    };

    this.TABS = [
      {
        label: props.intl.formatMessage({ id: 'introduction' }),
        value: 'introduction',
      },
      {
        label: props.intl.formatMessage({ id: 'pdq-assessment.p' }),
        value: 'prose',
      },
      {
        label: props.intl.formatMessage({ id: 'pdq-assessment.d' }),
        value: 'document',
      },
      {
        label: props.intl.formatMessage({ id: 'pdq-assessment.q' }),
        value: 'quantitative',
      },
    ];
  }

  handleTabChange = (value) => {
    this.setState({ selectedTab: value }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  handlePrevious = () => {
    switch (this.state.selectedTab) {
      case 'prose':
        this.handleTabChange('introduction');
        break;
      case 'document':
        this.handleTabChange('prose');
        break;
      case 'quantitative':
        this.handleTabChange('document');
        break;
      default:
    }
  };

  handleNext = () => {
    switch (this.state.selectedTab) {
      case 'introduction':
        this.handleTabChange('prose');
        break;
      case 'prose':
        this.handleTabChange('document');
        break;
      case 'document':
        this.handleTabChange('quantitative');
        break;
      default:
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, intl } = this.props;
    const proseLevel = this.props.pdqScores ? this.props.pdqScores.proseScore : 1;
    const documentLevel = this.props.pdqScores ? this.props.pdqScores.documentScore : 1;
    const quantitativeLevel = this.props.pdqScores ? this.props.pdqScores.quantitativeScore : 1;
    const dateStarted =
      this.props.pdqScores && this.props.pdqScores.assessmentStartedDate
        ? new Date(this.props.pdqScores.assessmentStartedDate).toLocaleDateString()
        : '';
    const dateCompleted =
      this.props.pdqScores && this.props.pdqScores.assessmentCompletedDate
        ? new Date(this.props.pdqScores.assessmentCompletedDate).toLocaleDateString()
        : '';
    return (
      <>
        <DocumentTitle title="page.title.milestone-mapper-results" />
        <Permission>
          <BackButton onClick={this.goBack} />

          <Box className={classes.root}>
            {this.state.selectedTab === 'introduction' && (
              <TopBanner>
                <Box className={classes.header}>
                  <Box className={classes.headerContent}>
                    <Typography variant="h3" className={classes.subtitle}>
                      <FormattedMessage id="milestoneMapper" />
                    </Typography>
                    <Typography variant="h1" className={classes.title} gutterBottom>
                      <FormattedMessage id="results" />
                    </Typography>
                    <Box className={classes.dateContainer}>
                      <Typography color="secondary" className={classes.dateLabel}>
                        <FormattedMessage id="dateStarted" />
                      </Typography>
                      <Typography className={classes.dateValue}>{dateStarted}</Typography>
                    </Box>
                    <Box className={classes.dateContainer}>
                      <Typography color="secondary" className={classes.dateLabel}>
                        <FormattedMessage id="dateCompleted" />
                      </Typography>
                      <Typography className={classes.dateValue}>{dateCompleted}</Typography>
                    </Box>
                  </Box>
                </Box>
              </TopBanner>
            )}

            {this.state.selectedTab !== 'introduction' && (
              <Box
                className={[
                  classes.topBanner,
                  this.state.selectedTab === 'prose'
                    ? classes[`level${proseLevel}Banner`]
                    : this.state.selectedTab === 'document'
                    ? classes[`level${documentLevel}Banner`]
                    : this.state.selectedTab === 'quantitative' && classes[`level${quantitativeLevel}Banner`],
                ].join(' ')}
              >
                <Typography
                  variant="h3"
                  className={[
                    classes.subtitle,
                    this.state.selectedTab === 'prose'
                      ? classes[`level${proseLevel}DarkColor`]
                      : this.state.selectedTab === 'document'
                      ? classes[`level${documentLevel}DarkColor`]
                      : this.state.selectedTab === 'quantitative' && classes[`level${quantitativeLevel}DarkColor`],
                  ].join(' ')}
                >
                  <FormattedMessage
                    id={
                      this.state.selectedTab === 'prose'
                        ? 'pdq-assessment.p'
                        : this.state.selectedTab === 'document'
                        ? 'pdq-assessment.d'
                        : this.state.selectedTab === 'quantitative' && 'pdq-assessment.q'
                    }
                  />
                </Typography>
                <Typography
                  variant="h1"
                  className={[
                    classes.title,
                    this.state.selectedTab === 'prose'
                      ? classes[`level${proseLevel}MainColor`]
                      : this.state.selectedTab === 'document'
                      ? classes[`level${documentLevel}MainColor`]
                      : this.state.selectedTab === 'quantitative' && classes[`level${quantitativeLevel}MainColor`],
                  ].join(' ')}
                  gutterBottom
                >
                  <FormattedMessage id="level" />{' '}
                  {this.state.selectedTab === 'prose'
                    ? proseLevel
                    : this.state.selectedTab === 'document'
                    ? documentLevel
                    : this.state.selectedTab === 'quantitative' && quantitativeLevel}
                </Typography>
              </Box>
            )}

            <Box className={classes.body}>
              {/* BEGIN: TABS */}
              <List className={classes.tabsContainer} id="pdq-assessment-results-tabs">
                {this.TABS.map((tab) => (
                  <ListItem
                    key={tab.value}
                    button
                    id={`pdq-results-tab-${tab.value}`}
                    data-cy-name={`pdq-results-tab-${tab.value}`}
                    onClick={() => this.handleTabChange(tab.value)}
                    className={[classes.tabItem, this.state.selectedTab === tab.value ? classes.activeTab : null].join(' ')}
                    aria-label={tab.label}
                  >
                    {tab.label}
                  </ListItem>
                ))}
              </List>
              {/* END: TABS */}

              {/* BEGIN: TAB CONTENT */}
              <Box className={classes.tabContent}>
                {this.state.selectedTab === 'introduction' ? (
                  <PDQResultsIntro />
                ) : this.state.selectedTab === 'prose' ? (
                  <PDQResultsProse />
                ) : this.state.selectedTab === 'document' ? (
                  <PDQResultsDocument />
                ) : (
                  this.state.selectedTab === 'quantitative' && <PDQResultsQuantitative />
                )}
              </Box>
              {/* END: TAB CONTENT */}

              {/* BEGIN: FOOTER */}
              <Box className={classes.footer}>
                {this.state.selectedTab !== 'introduction' && (
                  <Box className={classes.footerContentLeft}>
                    <Typography className={classes.footerSubtitle}>
                      <FormattedMessage id="previousSection" />
                    </Typography>
                    <Button
                      id="pdq-results-previous-section-button"
                      color="secondary"
                      startIcon={<ChevronLeft className={classes.largeIcon} />}
                      className={classes.footerTitle}
                      onClick={this.handlePrevious}
                      aria-label={intl.formatMessage({
                        id:
                          this.state.selectedTab === 'prose'
                            ? 'introduction'
                            : this.state.selectedTab === 'document'
                            ? 'pdq-assessment.p'
                            : 'pdq-assessment.d',
                      })}
                    >
                      <FormattedMessage
                        id={
                          this.state.selectedTab === 'prose'
                            ? 'introduction'
                            : this.state.selectedTab === 'document'
                            ? 'pdq-assessment.p'
                            : this.state.selectedTab === 'quantitative' && 'pdq-assessment.d'
                        }
                      />
                    </Button>
                  </Box>
                )}
                {this.state.selectedTab !== 'quantitative' && (
                  <Box className={classes.footerContentRight}>
                    <Typography className={classes.footerSubtitle}>
                      <FormattedMessage id="nextSection" />
                    </Typography>
                    <Button
                      id="pdq-results-next-section-button"
                      color="secondary"
                      endIcon={<ChevronRight className={classes.largeIcon} />}
                      className={classes.footerTitle}
                      onClick={this.handleNext}
                      aria-label={intl.formatMessage({
                        id:
                          this.state.selectedTab === 'introduction'
                            ? 'pdq-assessment.p'
                            : this.state.selectedTab === 'prose'
                            ? 'pdq-assessment.d'
                            : 'pdq-assessment.q',
                      })}
                    >
                      <FormattedMessage
                        id={
                          this.state.selectedTab === 'introduction'
                            ? 'pdq-assessment.p'
                            : this.state.selectedTab === 'prose'
                            ? 'pdq-assessment.d'
                            : this.state.selectedTab === 'document' && 'pdq-assessment.q'
                        }
                      />
                    </Button>
                  </Box>
                )}
              </Box>
              {/* END: FOOTER */}
            </Box>
          </Box>
        </Permission>
      </>
    );
  }
}

const mapStateToProps = ({ pdqAssessment }) => {
  return {
    pdqScores: pdqAssessment.pdqScores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PDQAssessmentResults)));
