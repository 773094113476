import React from 'react';

const ScaleUnbalancedIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.85214 1.95C3.29787 1.775 3.00095 1.20938 3.1857 0.684375C3.37045 0.159375 3.96761 -0.121875 4.52187 0.053125L8.24995 1.23125C8.70524 0.49375 9.55313 0 10.5198 0C11.978 0 13.1591 1.11875 13.1591 2.5C13.1591 2.59375 13.1525 2.68438 13.1427 2.775L17.1875 4.05313C17.7417 4.22813 18.0386 4.79375 17.8539 5.31875C17.6691 5.84375 17.072 6.125 16.5177 5.95L12.0506 4.5375C11.9022 4.6375 11.7438 4.72187 11.5755 4.79375V15C11.5755 15.5531 11.1038 16 10.5198 16H4.18535C3.6014 16 3.12961 15.5531 3.12961 15C3.12961 14.4469 3.6014 14 4.18535 14H9.46405V4.79063C8.77123 4.50313 8.23676 3.94688 8.00581 3.25938L3.85214 1.94688V1.95ZM6.57397 9L4.18535 5.11875L1.79674 9H6.57397ZM4.18535 12C2.11016 12 0.384689 10.9375 0.028377 9.53438C-0.0574019 9.19063 0.0613689 8.8375 0.249423 8.53125L3.39025 3.43125C3.55521 3.1625 3.85873 3 4.18535 3C4.51197 3 4.8155 3.16563 4.98046 3.43125L8.12128 8.53125C8.30934 8.8375 8.42811 9.19063 8.34233 9.53438C7.98602 10.9375 6.26054 12 4.18535 12ZM16.8146 9.11875L14.426 13H19.2066L16.8146 9.11875ZM20.9716 13.5344C20.6153 14.9375 18.8898 16 16.8146 16C14.7395 16 13.014 14.9375 12.6577 13.5344C12.5719 13.1906 12.6907 12.8375 12.8787 12.5312L16.0195 7.43125C16.1845 7.1625 16.488 7 16.8146 7C17.1413 7 17.4448 7.16563 17.6098 7.43125L20.7506 12.5312C20.9386 12.8375 21.0574 13.1906 20.9716 13.5344Z"
        fill={fill}
      />
    </svg>
  );
};

ScaleUnbalancedIcon.defaultProps = {
  width: 21,
  height: 16,
  fill: 'inherit',
};

export default ScaleUnbalancedIcon;
