import React, { forwardRef } from 'react';
import { Modal } from '@orijinworks/frontend-commons';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import CallCenterScheduleGrid from './components/call-center-schedule-grid.js';
import styles from './call-center-schedulling-modal.style'; // Import the styles
import useCallCenterSchedulingHook from '../call-center-scheduling/useCallCenterSchedulingHook.js';

const CallCenterSchedullingModal = forwardRef(
  ({ isOpen, onClose, classes, schedule, isClosed, isCallTimeLimitReached }, ref) => {
    const { displayCompleteSchedule } = useCallCenterSchedulingHook({
      schedule,
      isClosed,
      isCallTimeLimitReached,
    });
    return (
      <Modal
        ref={ref}
        open={isOpen}
        onClose={onClose}
        heading="Call Center Schedule"
        styles={{
          mainWrapperStyles: {
            height: 'auto',
            width: 'auto',
            minWidth: '700px',
            maxWidth: '7000px',
            zIndex: 9999,
            backdropFilter: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
        showFooter={false}
        modalType={{
          type: 'normal',
          status: 'info',
        }}
      >
        {schedule && schedule?.length > 0 ? (
          <CallCenterScheduleGrid classes={classes} scheduleRecords={displayCompleteSchedule} />
        ) : (
          <Box className={classes.modalContent}>
            <Typography variant="h6" className={classes.modalTitle}>
              <FormattedMessage id="groupScheduleBanner.header" defaultMessage="Call Center Schedule" />
            </Typography>
            <Typography variant="body2" className={classes.modalDescription}>
              <FormattedMessage id="groupScheduleBanner.description" />
            </Typography>
          </Box>
        )}
      </Modal>
    );
  }
);

export default withStyles(styles)(CallCenterSchedullingModal);
