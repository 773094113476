// English
import en from "./en.json";
// Spanish
import es from "./es.json";
// Arabic
import ar from "./ar.json";
// French
import fr from "./fr.json";
//Portugese
import pt from "./pt.json";
// Mandarin
import zh from "./zh.json";

export default { en, es, ar, fr, pt, zh };
