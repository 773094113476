import React from 'react';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { BookmarksV2 } from '../../../../v2/content';
import BookmarksV1 from './bookmarks-v1';

const Bookmarks = (props) => {
  const { unleashProps } = props;

  if (unleashProps.isFlagEnabled()) {
    return <BookmarksV2 {...props} />;
  }

  return <BookmarksV1 {...props} />;
};

export default withUnleash(Bookmarks, UnleashService.FLAGS.REFACTOR_BOOKMARKS_MODULE);
