import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { Carousel } from "../../../../shared/components";
import styles from "./most-recent.style";

const getNumberOfItems = () => {
  const { innerWidth } = window;
  if (innerWidth > 960 && innerWidth < 1280) {
    return 3;
  } else if (innerWidth > 600 && innerWidth < 960) {
    return 2;
  } else {
    return 4;
  }
};

const MostRecent = props => {
  const [mostRecentPageNo, changePageNo] = React.useState(1);
  return (
    <Carousel
      onForward={() => {
        if (
          props.mostRecent.data.length >
          mostRecentPageNo * getNumberOfItems()
        )
          changePageNo(mostRecentPageNo + 1);
      }}
      onBack={() => {
        if (mostRecentPageNo > 1) changePageNo(mostRecentPageNo - 1);
      }}
      itemCount={props.mostRecent.data.length}
      title="Most Recent"
      contentWrapperId="most-recent-content-wrapper"
      showNextNavigator
      showBackNavigator
      id="9999"
    >
      {/* BEGIN: CARD */}
      {props.mostRecent.data}
      {/* END: CARD */}
    </Carousel>
  );
};

export default withStyles(styles)(MostRecent);
