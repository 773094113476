import React from 'react';
import { Chip, withStyles } from '@material-ui/core';
import styles from './timer.style';
import TimerIcon from '@material-ui/icons/Timer';
import useTimerHook from './useTimerHook';
function Timer({ classes, initialElapsedTime, isTimerFlagEnabled }) {
  const { elapsedTime, formatTime, lastNSecondsLeft } = useTimerHook(initialElapsedTime, isTimerFlagEnabled);
  const thirtySecondsLeft = lastNSecondsLeft(30);
  const oneMinuteLeft = lastNSecondsLeft(60);
  const fiveMinutesLeft = lastNSecondsLeft(300);
  const chipClasses = [
    classes.chip,
    thirtySecondsLeft
      ? classes.thirtySecondsLeft
      : oneMinuteLeft
      ? classes.oneMinuteLeft
      : fiveMinutesLeft
      ? classes.fiveMinutesLeft
      : '',
  ].join(' ');
  return (
    <Chip
      id="timer"
      avatar={<TimerIcon />}
      label={formatTime(elapsedTime)}
      size="small"
      variant="default"
      className={chipClasses}
    />
  );
}
export default withStyles(styles)(Timer);
