import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

const CallCenterGrid = ({ scheduleRecords, classes, handleShowFullSchedule, showFullSchedule, hideFullScheduleOption }) => {
  const renderTimeSlot = (slot) => (
    <Typography>
      {moment.unix(slot.start).format('hh:mm a')} &nbsp;-&nbsp; {moment.unix(slot.end).format('hh:mm a')}
    </Typography>
  );

  if (!scheduleRecords) {
    return null;
  }

  return (
    <>
      <Grid container>
        {scheduleRecords.map(({ dayOfWeek, slots }) => (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={dayOfWeek} className={classes.scheduleRow}>
            <Typography variant="body1" className={classes.dayOfWeek}>
              {moment(dayOfWeek, 'dddd').format('dddd')}
            </Typography>
            {slots && slots.some((slot) => !slot.isClosed) ? (
              slots.map((slot, slotIndex) => (
                <Grid item xs={12} key={`${dayOfWeek}-${slot.start}-${slot.end}`}>
                  <Box className={classes.timeSlot}>{slot.start && slot.end && renderTimeSlot(slot)}</Box>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Box className={classes.timeSlot}>
                  <Typography>N/A</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>

      {!hideFullScheduleOption && (
        <Grid item xs={12} className={classes.seeFullScheduleSection}>
          <Typography variant="body1" onClick={handleShowFullSchedule} className={classes.seeFullSchedule}>
            <FormattedMessage id="seeFullSchedule" />
            {showFullSchedule ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </Typography>
        </Grid>
      )}
    </>
  );
};

CallCenterGrid.propTypes = {
  scheduleRecords: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string.isRequired,
      slots: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.number.isRequired,
          end: PropTypes.number.isRequired,
          isActive: PropTypes.bool.isRequired,
          isClosed: PropTypes.bool.isRequired,
        })
      ),
    })
  ),
  classes: PropTypes.object.isRequired,
  handleShowFullSchedule: PropTypes.func.isRequired,
  showFullSchedule: PropTypes.bool.isRequired,
};
export default CallCenterGrid;
