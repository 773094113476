export default (theme) => ({
  root: {
    width: '100%',
    paddingTop: 30,
  },
  programsContainer: {
    padding: '25px 0px',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainTitle: {
    fontFamily: theme.typography.secondaryFont,
    ...theme.typography.bold,
    letterSpacing: '-0.03em',
  },
  programCatalogBtn: {
    '&$viewProgramCatalogBtn': {
      color: theme.palette.primary.main,
      padding: '6px 8px',
      textDecoration: 'underline',
      textTransform: 'uppercase',
    },
  },
  viewProgramCatalogBtn: {
    // need this for the class to show up
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    margin: '25px 0px',
  },
});
