export default (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative',
    background: theme.palette.secondary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
  },
  titleContainer: {
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.white,
    margin: 0,
    marginLeft: 15,
  },
  description: {
    fontSize: 20,
    color: theme.palette.primary.white,
  },
  content: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    height: '50%',
    marginTop: 20,
  },
  container: {
    margin: 'auto',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    border: `5px solid ${theme.palette.primary.white}`,
    objectFit: 'cover',
  },
  imageAngle: {
    position: 'absolute',
    left: '5%',
    top: '5%',
    width: '10%',
  },
  carouselArrow: {
    position: 'absolute',
    zIndex: 100,
    top: '40%',
    backgroundColor: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  previousButton: {
    left: '10px',
  },
  nextButton: {
    right: '10px',
  },
  tickMarks: {
    position: 'absolute',
    bottom: '6px',
    paddingRight: '6px',
    width: '100%',
    textAlign: 'right',
  },
  tickMark: {
    marginRight: '1%',
    width: '5%',
  },
  continueButton: {
    width: 354,
    height: 73,
    margin: '40px auto 0px auto !important',
    display: 'block !important',
    textTransform: 'capitalize !important',
    minHeight: 50,
    fontSize: '24px !important',
  },
});
