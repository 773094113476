import { Keycloak, setDeviceGroup, setIpAddress, store } from '../../../../core';
import { AUTH_CONSTANTS } from '../constants/auth-constants';

/**
 * Variable to store the useOktaAuth0 flag
 */
let useOktaAuth0 = false;

/**
 * Variable to store the auth0 client
 */
let auth0Client = null;

/**
 * Function to get the access token.
 * It returns the access token based on the useOktaAuth0 flag.
 * If the useOktaAuth0 flag is true, it returns the auth0 access token.
 * If the useOktaAuth0 flag is false, it returns the Keycloak access token.
 *
 * @returns {string} The access token.
 */
export const getAccessToken = async () => {
  if (useOktaAuth0) {
    return await auth0Client.getAccessTokenSilently();
  }

  return Keycloak.Keycloak.token;
};

/**
 * Function to set the useOktaAuth0 flag
 *
 * @param {boolean} value - The value to set the useOktaAuth0 flag
 */
export const setUseOktaAuth0 = (value) => {
  useOktaAuth0 = value;
};

/**
 * Function to get the useOktaAuth0 flag
 *
 * @returns {boolean} The useOktaAuth0 flag
 */
export const getUseOktaAuth0 = () => {
  return useOktaAuth0;
};

/**
 * Function to handle the authentication based on the provider.
 * If the useOktaAuth0 flag is true, it returns the auth0 response.
 * If the useOktaAuth0 flag is false, it returns the Keycloak response.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 * @returns
 */
export const handleAuthentication = (keycloak, auth0) => {
  return new Promise(async (resolve, reject) => {
    const response = {
      isAuthenticated: false,
      isAuthorized: false,
      user: {
        name: '',
        preferred_username: '',
        locationId: '',
        sessionId: '',
        auth_time: '',
      },
      redirectToLogin: () => {},
    };

    if (useOktaAuth0) {
      auth0Client = auth0;
      response.isAuthenticated = auth0.isAuthenticated;

      if (auth0.isAuthenticated) {
        response.isAuthorized = validateRole(auth0.user.user_roles, AUTH_CONSTANTS.AUTH0_ROLES);
        response.user = auth0.user;
        response.user.auth_time = auth0.user.user_auth_time;
      }
      response.redirectToLogin = auth0.loginWithRedirect;
      return resolve(response);
    }

    if (keycloak) {
      response.isAuthenticated = keycloak.authenticated;
      if (keycloak.authenticated) {
        response.isAuthorized = validateRole(keycloak.realmAccess.roles, AUTH_CONSTANTS.KEYCLOAK_ROLES);
        response.user = keycloak.tokenParsed;
        response.user.sessionId = keycloak.sessionId;
      }
      response.redirectToLogin = keycloak.login;
    }

    resolve(response);
  });
};

/**
 * Function to handle the logout based on the provider.
 * If the useOktaAuth0 flag is true, it calls the auth0 logout function.
 * If the useOktaAuth0 flag is false, it calls the Keycloak logout function.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 */
export const handleLogout = (keycloak, auth0 = auth0Client) => {
  if (useOktaAuth0) {
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  } else {
    keycloak.logout({ redirectUri: `${window.location.origin}/home` });
  }
};

/**
 * Function to check if the user is authenticated based on the provider.
 * If the useOktaAuth0 flag is true, it returns the auth0 isAuthenticated flag.
 * If the useOktaAuth0 flag is false, it returns the Keycloak authenticated flag.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 * @returns {boolean} The isAuthenticated flag
 */
export const isAuthenticated = (keycloak, auth0) => {
  if (useOktaAuth0) {
    return auth0.isAuthenticated;
  }
  return keycloak.authenticated;
};

/**
 * Function to validate the role based on the ROLES_CONSTANTS.
 * If the role is ADMIN, it returns false.
 * If the role is LEARNER, it returns true.
 *
 * @param {Array} roles - The roles array
 * @param {Array} ROLES_CONSTANTS - The roles constants
 * @returns {boolean} The authorize flag
 */
const validateRole = (roles = [], ROLES_CONSTANTS) => {
  let authorize = false;
  for (let i = 0; i < roles.length; i++) {
    if (ROLES_CONSTANTS.ADMIN === roles[i]) {
      authorize = false;
      break;
    }

    if (ROLES_CONSTANTS.LEARNER === roles[i]) {
      authorize = true;
    }
  }

  return authorize;
};

/**
 * Function to check if the user has access denied.
 *
 * @param {Object} location - The location object
 * @returns {boolean} The access denied flag
 */
export const checkIfAccessDenied = (location) => {
  const hasAccessDeniedHash = location.hash.includes('access_denied');
  const hasAccessDeniedSearch = location.search.includes('access_denied');

  return hasAccessDeniedHash || hasAccessDeniedSearch;
};

/**
 * Function to fetch the user IP address.
 * If the IP address is already available in the store, it returns the IP address from the store.
 * If the IP address is not available in the store, it fetches the IP address from the ipify API.
 *
 * @returns {Promise} The user IP address promise
 */
export const fetchUserIp = () => {
  return new Promise(async (resolve, reject) => {
    const {
      app: { ipAddress },
    } = store.getState();

    if (ipAddress) {
      return resolve(ipAddress);
    }

    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      store.dispatch(setIpAddress(data.ip));
      resolve(data.ip);
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * Function to fetch the device group.
 * If the device group is already available in the store, it returns the device group from the store.
 * If the device group is not available in the store, it fetches the device group from the esper API.
 *
 * @returns {Promise} The device group promise
 */
export const fetchDeviceGroup = () => {
  return new Promise(async (resolve, reject) => {
    const {
      app: { deviceGroup, deviceName },
    } = store.getState();

    if (deviceGroup) {
      return resolve({ deviceGroup, deviceName });
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/esper/device-group`);
      const data = await response.json();
      if (data?.payload) {
        const deviceGroup = data.payload.deviceGroup;
        const deviceName = data.payload.deviceName;
        store.dispatch(setDeviceGroup(deviceGroup, deviceName));
        resolve({ deviceGroup, deviceName });
      } else {
        resolve({});
      }
    } catch (err) {
      reject(err);
    }
  });
};
