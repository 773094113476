export default (theme) => ({
  root: {
    padding: '24px 32px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterItem: {
    width: 'initial',
    padding: '12px 20px',
    color: theme.palette.aqua.deep,
    '& svg': {
      fill: theme.palette.aqua.deep,
      stroke: theme.palette.aqua.deep,
    },
    '&:focus': {
      color: theme.palette.primary.main,
      '& svg': {
        fill: theme.palette.primary.main,
        stroke: theme.palette.primary.main,
      },
    },
  },
  activeFilter: {
    color: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
    },
  },
  filterItemText: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    color: theme.palette.aqua.extraDeep,
  },
  icon: {
    minWidth: 30,
  },
});
