import { useEffect, useState } from 'react';
import { UserService } from '../../../../shared/services';
import { StaffMessagingConstants } from '../../services/staff-messaging.service';
import { setSelectedContact, setSelectedThread, setUnreadMessagesCountLimitReached, store } from '../../../../core';
import { isWidthDown } from '@material-ui/core';

const useStaffMessagingHook = (props) => {
  const { staffMessaging, width } = props;
  const { selectedThread, activeCommunicationMode } = staffMessaging;
  const [dataLoading, setDataLoading] = useState(true);
  const [showConversation, setShowConversation] = useState(false);
  const [showComposeMessage, setShowComposeMessage] = useState(false);
  const isTablet = isWidthDown('sm', width);

  useEffect(() => {
    fetchRelevantData();
  }, []);

  useEffect(() => {
    if (selectedThread) {
      setShowConversation(true);
      setShowComposeMessage(false);
    }
  }, [selectedThread]);

  const fetchRelevantData = async () => {
    try {
      if (!Boolean(UserService.userServerTimestamp)) {
        await new UserService().getUserTimeMeta();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  };

  const toggleComposeMessage = (value) => {
    setShowComposeMessage(value);
    if (value) {
      setShowConversation(false);
      resetStoreState();
    }
  };

  const showConversationView = () => {
    return showConversation || showComposeMessage;
  };

  const resetConversationView = () => {
    setShowConversation(false);
    setShowComposeMessage(false);
    resetStoreState();
  };

  const resetStoreState = () => {
    store.dispatch(setSelectedThread(null));
    store.dispatch(setSelectedContact(null));
    store.dispatch(setUnreadMessagesCountLimitReached(false));
  };

  const isActiveModeMessages = () => {
    return StaffMessagingConstants.COMMUNICATION_MODES.MESSAGES === activeCommunicationMode;
  };

  const isActiveModeAnnouncements = () => {
    return StaffMessagingConstants.COMMUNICATION_MODES.ANNOUNCEMENTS === activeCommunicationMode;
  };

  const shouldHideSidebar = () => {
    return isTablet && showConversationView();
  };

  const shouldHideConversation = () => {
    return isTablet && !showConversationView();
  };

  return {
    dataLoading,
    showComposeMessage,
    showConversation,
    toggleComposeMessage,
    showConversationView,
    resetConversationView,
    isActiveModeMessages,
    isActiveModeAnnouncements,
    shouldHideSidebar,
    shouldHideConversation,
  };
};

export default useStaffMessagingHook;
