import { Http } from '../../../core';
import { Utility, UserService, Constant } from '../../../shared';

export class FriendsFamilyMessaging {
  _utilityService = new Utility();
  /**
   * @name fetchContacts
   * @desc Fetches contacts
   * @return {Promise}
   */
  fetchContacts = (personId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(`/services/messages/friends-family/v1/learner/relationships/${personId}?noLoader=true`)
        .then((_successLog) => {
          const { data } = _successLog;
          const { payload } = data;
          if (!data) {
            resolve([]);
            return;
          }

          resolve(payload.relationships);
        })
        .catch((e) => console.log(e));
    });
  };

  /**
   * createRelationship
   * @param {number} learnerId Learner ID whose creating relationship
   * @param {object} payload request payload
   * @desc Initiate's request to create relationship ID.
   * @return {Promise}
   */
  createRelationship = (learnerId, payload) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.post(`/services/messages/friends-family/v1/learner/relationships/${learnerId}`, payload)
        .then((_successLog) => {
          const { data } = _successLog;
          resolve(data);
        })
        .catch((_errorLog) => {
          const { response } = _errorLog;
          resolve(response.data);
        });
    });
  };

  /**
   * @name preprocessMessages
   * @param {array} messages
   * @desc pre-processes messages.
   * @return {array}
   */
  preprocessMessages = (messages) => {
    return messages.map((msg) => {
      const learnerTimezoneName = this._utilityService.getTimezoneName(
        UserService.userServerTimestamp.timeZone
      ).timezoneName;
      msg['createdDate'] = this._utilityService.parseTimeToNewZone(
        msg.createdDate,
        learnerTimezoneName,
        'MMM D YYYY hh:mm A'
      );
      if (!!msg['readDate']) {
        msg['readDate'] = this._utilityService.parseTimeToNewZone(msg.readDate, learnerTimezoneName, 'MMM D YYYY hh:mm A');
      }
      return msg;
    });
  };

  /**
   * @name fetchMessages
   * @desc Fetches Messages
   * @return {Promise}
   */
  fetchMessages = ({ threadKey, beforeId, limit, afterId, signal }, learnerPersonId, contactPersonId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(
        `/services/messages/friends-family/v1/learner/messages/${learnerPersonId}/${contactPersonId}?${
          limit ? `pageSize=${limit}` : ''
        }${beforeId ? `&beforeId=${beforeId}` : ''}${afterId ? `&afterId=${afterId}` : ''}&noLoader=true`,
        {
          cancelToken: signal,
        }
      )
        .then((_successLog) => {
          const { data } = _successLog;
          const { payload } = data;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(this.preprocessMessages(payload));
        })
        .catch((err) => {
          console.log(err);
          if (Http.axios.isCancel(err)) {
            console.log('Request Cancelled', err.message);
          }
        });
    });
  };

  updateRelationshipStatus = (learnerPersonId, contactPersonId, status) => {
    const requestPayload = {
      learnerPersonId,
      contactPersonId,
      status,
    };
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.put(`/services/messages/friends-family/v1/learner/relationships`, requestPayload)
        .then((_successLog) => {
          const { data } = _successLog;
          resolve(data);
        })
        .catch((_errorLog) => {
          resolve(null);
          console.log('ERROR', _errorLog);
        });
    });
  };

  getLearnerSettings = (learnerPersonId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(`/services/messages/friends-family/v1/learner/settings/${learnerPersonId}`)
        .then((_successLog) => {
          const { data } = _successLog;
          resolve(data);
        })
        .catch((_errorLog) => {
          console.log('ERROR', _errorLog);
          resolve(null);
        });
    });
  };

  markMessagesRead = (learnerPersonId, contactPersonId, messageId, messages) => {
    return new Promise((resolve, reject) => {
      const unreadMessages = messages
        .filter(
          (_message) =>
            !_message.readDate &&
            _message.messageType === Constant.MESSAGE_TYPES.MESSAGE &&
            _message.senderPersonId !== learnerPersonId
        )
        .reverse();
      if (unreadMessages.length === 0) {
        resolve(null);
      } else {
        messageId = unreadMessages[0].messageId;
        Http.HERMES_REQUEST.put(
          `/services/messages/friends-family/v1/learner/messages/${learnerPersonId}/${contactPersonId}/${messageId}?noLoader=true`
        )
          .then((_successLog) => {
            const { data } = _successLog;

            if (!data) {
              resolve([]);
              return;
            }

            resolve(data);
          })
          .catch((_errorLog) => {
            reject(_errorLog);
          });
      }
    });
  };

  fetchCharacterLimit = () => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(`/messages/location/characterslimit?noLoader=true`)
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        })
        .catch((_errorLog) => {
          reject(_errorLog);
        });
    });
  };

  postMessage = (payload) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.post(`/services/messages/friends-family/v1/learner/messages?noLoader=true`, payload)
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        })
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  /**
   * @name removeMessage
   * @desc remove Message
   * @return {Promise}
   */
  removeMessage = (messageId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.delete(`/messages?message_id=${messageId}`)
        .then((_successLog) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  };
}
