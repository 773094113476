export default (theme) => ({
  contentRoot: {
    margin: '0px !important',
    padding: '0px !important',
  },
  fullBackground: {
    background: theme.palette.primary.sub,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    position: 'relative',
    [theme.breakpoints.between(1195, 1205)]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  gradientBackground: {
    background: `linear-gradient(185deg, ${theme.palette.primary.sub} calc(50% - 1px), white calc(50% + 1px))`,
  },
  logo: {
    position: 'absolute',
    top: 50,
    left: 50,
    height: 65,
    zIndex: 9,
  },
});
