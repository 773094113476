import React from 'react';

const LockIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22.938 26.214">
      <path
        id="lock-keyhole-solid"
        d="M11.469,3.277a4.1,4.1,0,0,1,4.1,4.1V9.83H7.373V7.373A4.1,4.1,0,0,1,11.469,3.277ZM4.1,7.373V9.83H3.277A3.28,3.28,0,0,0,0,13.107v9.83a3.28,3.28,0,0,0,3.277,3.277H19.661a3.28,3.28,0,0,0,3.277-3.277v-9.83A3.28,3.28,0,0,0,19.661,9.83h-.819V7.373a7.373,7.373,0,1,0-14.745,0Zm9.011,9.011v3.277a1.638,1.638,0,0,1-3.277,0V16.384a1.638,1.638,0,0,1,3.277,0Z"
        fill={fill}
      />
    </svg>
  );
};

LockIcon.defaultProps = {
  width: 22.938,
  height: 26.214,
  fill: 'inherit',
};

export default LockIcon;
