import * as store from "./";

const initialState = {
  answersString: "",
  recommendedJobZone: ""
};

export const interestProfilerReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.INTEREST_PROFILER_SET_ANSWERS_STRING:
      return {
        ...state,
        answersString: action.string
      };
    case store.SET_RECOMMENDED_JOB_ZONE:
      return {
        ...state,
        recommendedJobZone: action.jobZone
      }
    default:
      return state;
  }
};
