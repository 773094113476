import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import styles from './auth-guards-header.styles';
import { connect } from 'react-redux';
import { Constant } from '../../../../../shared/services';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage, useIntl } from 'react-intl';
import useAuthGuardsHeaderHook from './useAuthGuardsHeaderHook';

/**
 * Component to render the header for the AuthGuards.
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const AuthGuardsHeader = (props) => {
  const { classes } = props;
  const anchorRef = useRef(null);
  const intl = useIntl();
  const { openLanguageMenu, isLanguageSelected, handleMenuOpen, handleMenuClose, handleLanguageSelect } =
    useAuthGuardsHeaderHook(props);

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <img
          id="auth-guards-header-logo"
          className={classes.logo}
          src={`${Constant.CDN_BASE_URL}/assets/logo/Orijin-former-whitelogo.png`}
          alt="Orijin"
        />
        <Box>
          <Button
            id="select-language"
            ref={anchorRef}
            className={classes.selectedLanguage}
            startIcon={<LanguageIcon />}
            endIcon={<ExpandMoreIcon />}
            onClick={handleMenuOpen}
            aria-label={intl.formatMessage({
              id: isLanguageSelected(Constant.LANGUAGES.ENGLISH.code) ? 'menuLangEnglish' : 'menuLangSpanish',
            })}
          >
            <FormattedMessage
              id={isLanguageSelected(Constant.LANGUAGES.ENGLISH.code) ? 'menuLangEnglish' : 'menuLangSpanish'}
            />
          </Button>
          <Menu
            id="locale-menu"
            anchorEl={anchorRef.current}
            getContentAnchorEl={null}
            open={openLanguageMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleMenuClose}
          >
            <MenuItem
              id="switch-language-english"
              onClick={(event) => handleLanguageSelect(event, Constant.LANGUAGES.ENGLISH.code)}
              tracking-type={Constant.TRACKING_TYPES.LANGUAGE}
              tracking-id="switch-language-english"
              aria-label={intl.formatMessage({ id: 'menuLangEnglish' })}
            >
              <FormattedMessage id="menuLangEnglish" />
            </MenuItem>
            <MenuItem
              id="switch-language-spanish"
              onClick={(event) => handleLanguageSelect(event, Constant.LANGUAGES.SPANISH.code)}
              tracking-type={Constant.TRACKING_TYPES.LANGUAGE}
              tracking-id="switch-language-spanish"
              aria-label={intl.formatMessage({ id: 'menuLangSpanish' })}
            >
              <FormattedMessage id="menuLangSpanish" />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

AuthGuardsHeader.propTypes = {
  /**
   * Material-UI classes object.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The selected language from the redux store.
   */
  selectedLanguage: PropTypes.string.isRequired,
  /**
   * The dispatch function from the redux store.
   */
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ locale }) => ({
  selectedLanguage: locale.locale,
});

export default connect(mapStateToProps)(withStyles(styles)(AuthGuardsHeader));
