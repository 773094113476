import React from 'react';

const ShieldCheckedIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6142 0.13099C11.4217 0.0451689 11.2154 0 11 0C10.7846 0 10.5783 0.0451689 10.3858 0.13099L1.75543 3.73998C0.747103 4.16006 -0.00456248 5.14022 2.08472e-05 6.32365C0.0229375 10.8044 1.89293 19.0026 9.79 22.729C10.5554 23.0903 11.4446 23.0903 12.21 22.729C20.1071 19.0026 21.9771 10.8044 22 6.32365C22.0046 5.14022 21.2529 4.16006 20.2446 3.73998L11.6142 0.13099ZM16.1792 9.4403L10.3125 15.2219C9.88167 15.6465 9.185 15.6465 8.75875 15.2219L5.82543 12.3311C5.39459 11.9065 5.39459 11.22 5.82543 10.7999C6.25626 10.3798 6.95292 10.3753 7.37917 10.7999L9.53334 12.9228L14.6208 7.90456C15.0517 7.47997 15.7483 7.47997 16.1746 7.90456C16.6008 8.32914 16.6054 9.01571 16.1746 9.43578L16.1792 9.4403Z"
        fill={fill}
      />
    </svg>
  );
};

ShieldCheckedIcon.defaultProps = {
  width: 22,
  height: 23,
  fill: 'inherit',
};

export default ShieldCheckedIcon;
