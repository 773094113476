import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ThumbnailCard } from '../../../../shared';
import { Constant } from '../../../../../shared/services';

/**
 * Component to display tools in Explore page
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {React.Component} The ExploreTools component.
 */
const ExploreTools = ({ tools, onItemClick, willOpenLink }) => (
  <Grid container spacing={2}>
    {tools.map((tool) => (
      <Grid item sm={12} key={tool.id}>
        <ThumbnailCard
          title={tool.name}
          description={tool.description}
          thumbnailType="icon"
          icon={tool.icon}
          autoChangeOrientation={false}
          orientation="horizontal"
          trackingType={Constant.TRACKING_TYPES.TOOL}
          trackingId={`launch-tool-${tool.id}`}
          onClick={() => onItemClick(tool)}
          accessibilityProps={willOpenLink(tool) ? { role: 'link', rel: 'noopener noreferrer' } : {}}
        />
      </Grid>
    ))}
  </Grid>
);

ExploreTools.propTypes = {
  /**
   * The tools to display
   */
  tools: PropTypes.array.isRequired,
  /**
   * The function to call when a tool is clicked
   */
  onItemClick: PropTypes.func.isRequired,
  /**
   * The function to check if the tool will open a link
   */
  willOpenLink: PropTypes.func.isRequired,
};

export default ExploreTools;
