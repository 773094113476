export default theme => ({
  root: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30
  },
  headerTitle: {
    fontWeight: 700
  },
  closeBtn: {
    textDecoration: "underline",
    fontWeight: 500
  },
  filtersContainer: {
    marginTop: 30
  },
  careersContainer: {
    marginTop: 30
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  attributionContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 25
  }
});
