import React from 'react';
import { withStyles, Box, Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import styles from './question-item.style';
import AnswerOptions from '../answer-options/answer-options';

const QuestionItem = (props) => {
  const { classes, index, text, image_url, answerOptions, selectedValue, onAnswerSelect } = props;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography id="ip-questionnaire-title" variant="h2" className={classes.headerText} gutterBottom>
          <FormattedMessage id="interest-profiler.questionnaire.title" />
        </Typography>
        <Typography variant="h4" id="ip-questionnaire-description" className={classes.whiteText}>
          <FormattedMessage id="interest-profiler.questionnaire.para1" />
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.body}>
        <Grid item sm={12}>
          <Box className={classes.imgContainer}>
            <img className={classes.questionImg} alt={`Question ${index}`} src={image_url} />
          </Box>
        </Grid>
        <Grid item sm={12} className={classes.answerContainer}>
          <Typography id="ip-question-title" variant="h3">
            {index}. {text}
          </Typography>
          <AnswerOptions
            options={answerOptions && answerOptions.length > 0 ? [...answerOptions].reverse() : []}
            selectedValue={selectedValue}
            onOptionClick={onAnswerSelect}
            questionId={index}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(QuestionItem);
