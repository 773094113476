import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './single-launch-modules.style';
import { LearningModule, ProgressChip } from '..';
import { RedirectIcon } from '../../../../../shared/icons';
import { Constant } from '../../../../../shared/services';
import useLearningModuleHook from '../learning-module/useLearningModuleHook';
import { PillButton } from '../../../../../frontend-commons';
import { ProgramConstants } from '../../../../../content/programs/services/programs.service';
import useSingleLaunchModuleHook from './useSingleLaunchModuleHook';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';

const SingleLaunchModules = (props) => {
  const { classes, modules, rootModule, templateType, isEnrolled } = props;
  const { hasProgress, getProgress, isTemplateMultipleCoursesUnified } = useSingleLaunchModuleHook(props);
  const { launchModule } = useLearningModuleHook(props);
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.overviewHeader}>
        <Box>
          <Typography id="single-launch-program-overview-title" color="secondary" variant="h4">
            <FormattedMessage id="programOverview" />
          </Typography>
          {hasProgress() && (
            <Box marginTop="12px">
              <ProgressChip progress={getProgress()} />
            </Box>
          )}
        </Box>
        {isEnrolled && (
          <Box>
            <PillButton
              id="single-launch-program-launch-btn"
              className={classes.launchBtn}
              color="primary"
              size="large"
              onClick={() => {
                launchModule(rootModule?.id, rootModule?.name);
              }}
              tracking-type={Constant.TRACKING_TYPES.COURSE}
              tracking-id={`launch-course-${rootModule?.id}`}
              endIcon={<RedirectIcon size={16} fill="white" />}
              variant="contained"
              aria-label={intl.formatMessage({ id: 'launchProgram' })}
            >
              <FormattedMessage id="launchProgram" />
            </PillButton>
          </Box>
        )}
      </Box>
      <Grid className={classes.modulesContainer}>
        {(modules ?? []).map((module, index) => (
          <LearningModule
            key={module.id}
            id={module.id}
            index={index}
            name={module.name}
            progress={module.progress}
            module={templateType === ProgramConstants.TEMPLATE_TYPES.TEMPLATE_SINGLE_COURSE ? 'topic' : 'course'}
            isEnrolled={isEnrolled}
            showLaunchButton={false}
            showProgress={isTemplateMultipleCoursesUnified()}
          />
        ))}
      </Grid>
    </Box>
  );
};

SingleLaunchModules.propTypes = {
  modules: PropTypes.array,
  rootModule: PropTypes.object,
  templateType: PropTypes.string.isRequired,
  isEnrolled: PropTypes.bool.isRequired,
};

export default withUnleash(withStyles(styles)(SingleLaunchModules), UnleashService.FLAGS.PROGRESS_TRACKING_USING_AWARDS_API);
