const SET_ACTIVE_COMMUNICATION_MODE = 'SET_ACTIVE_COMMUNICATION_MODE';
const SET_CONTACTS = 'SET_CONTACTS';
const SET_SELECTED_THREAD = 'SET_SELECTED_THREAD';
const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';
const SET_FORCE_RELOAD_MESSAGE_THREADS = 'SET_FORCE_RELOAD_MESSAGE_THREADS';
const SET_MARK_SELECTED_THREAD_AS_READ = 'SET_MARK_SELECTED_THREAD_AS_READ';
const SET_FETCH_NEW_MESSAGES = 'SET_FETCH_NEW_MESSAGES';
const SET_UNREAD_MESSAGES_COUNT_LIMIT_REACHED = 'SET_UNREAD_MESSAGES_COUNT_LIMIT_REACHED';

export {
  SET_ACTIVE_COMMUNICATION_MODE,
  SET_CONTACTS,
  SET_SELECTED_THREAD,
  SET_SELECTED_CONTACT,
  SET_FORCE_RELOAD_MESSAGE_THREADS,
  SET_MARK_SELECTED_THREAD_AS_READ,
  SET_FETCH_NEW_MESSAGES,
  SET_UNREAD_MESSAGES_COUNT_LIMIT_REACHED,
};
