import { Http } from '../../../../core';
import { Utility } from '../../../../shared/services';

/**
 * Constants for the featured content
 */
class FEATURED_CONTENT_CONSTANTS {
  /**
   * Carousel autoplay speed
   */
  static CAROUSEL_AUTOPLAY_SPEED = 5000;

  /**
   * Carousel responsive properties
   */
  static CAROUSEL_RESPONSIVE_PROPS = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };
}

/**
 * Function to transform the data after fetching the featured contents
 *
 * @param {array} data - list of featured contents
 * @returns {array} - transformed data sorted by slide
 */
const transformDataAfterFetch = (data) => {
  const transformedData = data.map((item) => ({
    id: item.id,
    title: item.title,
    description: item.description,
    thumbnail: item.thumbnailUri,
    video: item.videoUri,
    launchUrl: item.launchUrl,
    slide: item.displayOrder,
  }));

  return Utility.sortByKey(transformedData, 'slide');
};

/**
 * Function to fetch the featured contents
 *
 * @returns {Promise}
 */
const getFeaturedContents = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get('services/featured-content/v2?noLoader=true')
      .then((_successLog) => {
        if (_successLog?.data?.payload?.length > 0) {
          resolve(transformDataAfterFetch(_successLog.data.payload));
        }
        resolve([]);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

export { FEATURED_CONTENT_CONSTANTS, getFeaturedContents };
