import React from 'react';
import { Button, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './hotline-contacts.style';
import SosIcon from '@mui/icons-material/Sos';
import { DialIcon, CallClosedIcon } from '../../../../shared';
import useContactsCallingHook from '../contacts-calling/useContactsCallingHook';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import CallingModal from '../calling-modal/calling-modal';
import StatusModal from '../status-modal/status-modal';
import { ContactRequestConstants } from '../../services';
import PropTypes from 'prop-types';

const HotlineContactsCalling = (props) => {
  const { classes, isClosed, handlePollingInterval, hotlineContacts } = props;
  const meetingManager = useMeetingManager();
  const intl = useIntl();
  const {
    isCallingModalOpen,
    selectedContact,
    transactionId,
    meetingId,
    callStatus,
    setCallStatus,
    timePerCall,
    isCallinProgress,
    setisCallingModalOpen,
    onCallClick,
    isErrorModalOpen,
    handleErroModalClose,
    handleErroModalOpen,
  } = useContactsCallingHook(meetingManager, handlePollingInterval);

  return (
    <>
      <Grid container className={classes.hotlineSection}>
        <Grid item sm={12}>
          <Typography id="hotline-contacts-heading" variant="h2" className={classes.heading}>
            <FormattedMessage id="essentialContacts" />
          </Typography>
          <Typography id="hotline-contacts-subtext" variant="body1" className={classes.subtext}>
            <FormattedMessage id="essentialContacts.description" />
          </Typography>
          {hotlineContacts?.map((contact) => (
            <Card key={contact.id} className={classes.contactRow}>
              <CardContent>
                <Grid container spacing={2} alignItems="center" className={classes.hotlineContacts}>
                  <Grid item id="hotline-contacts-sosIcon">
                    <SosIcon className={classes.sosIcon} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="name" variant="h6" className={classes.contactName}>
                      {contact.name}
                    </Typography>
                    <Typography variant="body2" className={classes.contactNumber}>
                      <FormattedMessage id="contact" />: {contact.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      id="call-button"
                      disabled={isClosed}
                      className={classes.dialButton}
                      onClick={() => {
                        onCallClick(contact);
                      }}
                      aria-label={intl.formatMessage({ id: 'contactsCalling.callButtonText' })}
                    >
                      {isClosed ? <CallClosedIcon /> : <DialIcon size={44} fill="#0F48B8" />}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>

      <CallingModal
        isCallingModalOpen={isCallingModalOpen}
        setisCallingModalOpen={setisCallingModalOpen}
        setonClose={() => {}}
        selectedContact={selectedContact}
        transactionId={transactionId}
        meetingId={meetingId}
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        timePerCall={timePerCall}
        isCallinProgress={isCallinProgress}
      />

      <StatusModal
        status={ContactRequestConstants.CONTACT_CONNECTION_STATUSES.FAILED}
        description={''}
        handleStatusModalOpen={handleErroModalOpen}
        handleStatusModalClose={handleErroModalClose}
        isStatusModalOpen={isErrorModalOpen}
      />
    </>
  );
};

HotlineContactsCalling.propTypes = {
  isClosed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handlePollingInterval: PropTypes.func.isRequired,
};
export default withStyles(styles)(HotlineContactsCalling);
