import React from 'react';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import MessagingV1 from './messaging';
import { StaffMessaging as MessagingV2 } from '../../../staff-messaging';
import { useDocumentTitleHook } from '../../../../v2/shared';

const MessagingVersionWrapper = (props) => {
  const { isFlagEnabled } = props.unleashProps;
  useDocumentTitleHook('page.title.staff-messaging');

  if (isFlagEnabled()) {
    return <MessagingV2 />;
  }

  return <MessagingV1 {...props} />;
};

export default withUnleash(MessagingVersionWrapper, UnleashService.FLAGS.STAFF_MESSAGING_V2);
