import { Grid, withStyles, CircularProgress } from '@material-ui/core';
import React, { Component, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PlayMainService } from '../../services';
import styles from './play-main.style';
import { Constant, Utility } from '../../../../shared';
import Closed from '../../components/closed/closed';
import { DocumentTitle } from '../../../../v2/shared';
import { RemainingTokens } from '../../components/';
import { UnleashProvider } from '../../../../core';
import { UnleashService } from '../../../../core/services';

const Achievements = React.lazy(() => import('../../components/play-achievements/play-achievements'));
const PlayTabs = React.lazy(() => import('../../components/play-tabs/play-tabs'));
const Redemptions = React.lazy(() => import('../../components/past-redemptions/past-redemptions'));

class PlayMain extends Component {
  _playMainService = new PlayMainService();
  _utilityService = new Utility();
  pollIntervalRef = null;
  pollCntr = 0;
  state = {
    playMeta: null,
    status: null || this.props.status,
    playSchedule: null,
    videoSchedule: null,
    gamesSchedule: null,
    pastRedemptions: [],
    redeem: {},
    attributes: [],
  };

  /**
   * @name updateRedeemState
   * @param {object} redeem
   * @desc Updates redeem state in local state.
   * @return {void}
   */
  updateRedeemState = (redeem) => {
    this.setState(
      {
        redeem: {
          ...this.state.redeem,
          ...redeem,
        },
      },
      () => {}
    );
  };

  /**
   * @name updatePlayStatus
   * @param {object} status
   * @param {string} tabName Type of key needs to be updated.
   * @desc Updates play status in local state in order to
   *        perform operation on current user.
   * @return {void}
   */
  updatePlayStatus = (status, tabName) => {
    let stateKey = null;
    const areaState = this.state.status[tabName.toLowerCase()];
    areaState['type'] = status;
    this.getPlayStatus(false);
    this.setState({
      status: {
        ...this.state.status,
        [stateKey]: {
          ...areaState,
        },
      },
    });
  };

  /**
   * @name getCurrentDayRedemptions
   * @desc Fetches current day redemptions.
   * @return {void}
   */
  getCurrentDayRedemptions = () => {
    if (this.state.status && Object.keys(this.state.redeem).length === 0) {
      this.setState(
        {
          redeem: {
            preventCalls: true,
          },
        },
        () => {
          this._playMainService.getCurerntDayRedemptions().then((_successLog) => {
            this.setState(_successLog);
          });
        }
      );
    }
  };

  componentDidUpdate(prevProps) {
    this.getCurrentDayRedemptions();
    this.featureUpdateCheck(prevProps);
  }

  /**
   * @name featureUpdateCheck
   * @desc A method to check if features is updated or not
   * @return {void}
   */
  featureUpdateCheck = (prevProps) => {
    if (prevProps.app.features.length !== this.props.app.features.length) {
      this.getPlayStatus();
    }
  };

  componentDidMount() {
    this.pollAPI(Constant.POLL_CONFIG.ENTERTAINMENT_POLL_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.pollIntervalRef);
    this._playMainService.destroyInterval();
  }

  /**
   * @name getSchedule
   * @param {DateTime} userDateTime
   * @desc Fetches schedule from play-main-service.
   * @return {void}
   */
  getSchedule = (userDateTime) => {
    this._playMainService.getAllSchedule(userDateTime).then((_successLog, _errorLog) => {
      this.setState(_successLog);
    });
  };

  /**
   * @name pollAPI
   * @param {number} interval Interval after which poll API needs to be triggered.
   * @return {void}
   */
  pollAPI = (interval) => {
    this.getPlayStatus(true);
    this.getPastRedemptions();
    this.getAttributes(true);
    this.startAPIPolling(Constant.POLL_CONFIG.ENTERTAINMENT_POLL_INTERVAL);
  };

  /**
   * @param {*} interval
   * @desc A wrapper method to start the polling mechanism.
   */

  startAPIPolling = (interval) => {
    console.warn(`API will be polled after every ${interval} milliseconds.`);
    this.pollIntervalRef = setInterval(() => {
      if (this.pollCntr === 0) {
        this.getPlayStatus();
        this.getPastRedemptions();
        this.getAttributes();
      }
    }, interval);
  };

  /**
   * @name getPlayStatus
   * @param {boolean} hasLoader Flag to show loader or not
   * @desc Status that tells which area to show and which not, play actual
   * meta is being altered in service.
   * @return {Promise}
   */
  getPlayStatus = (hasLoader = false) => {
    let queryString = '';
    if (!hasLoader) {
      queryString = '?noLoader=true';
    }
    this.pollCntr++;
    this._playMainService.getPlayStatus(queryString, this.props.history).then(
      (_successLog) => {
        if (_successLog.status.play.type !== this._playMainService.OFF && !this.state.playMeta) {
          this.getPlayMeta();
        }

        this.setState(_successLog);
        this.pollCntr--;
      },
      (_errorLog) => {
        this.pollCntr--;
      }
    );
  };

  /**
   * @name getPlayMeta
   * @desc Fetches attributes from Play service.
   * @return {void}
   */
  getAttributes = (hasLoader = false) => {
    this.pollCntr++;
    let queryString = '';
    if (!hasLoader) {
      queryString = '?noLoader=true';
    }
    this._playMainService.getAttributes(queryString).then((_successLog) => {
      this.setState({
        attributes: _successLog,
      });
      this.pollCntr--;
    });
  };

  /**
   * @name getPlayMeta
   * @desc Fetches play meta information from play-main-service.
   * @return {void}
   */
  getPlayMeta = () => {
    this.pollCntr++;
    this._playMainService.getPlayMeta().then((_successLog) => {
      this.setState({
        playMeta: _successLog,
      });
      this.pollCntr--;
    });
  };

  /**
   * @name getPastRedemptions
   * @desc Fetches past redemptions from play-main-service.
   * @return {void}
   */
  getPastRedemptions = () => {
    this.pollCntr++;
    this._playMainService.getPastRedemptions().then((_successLog) => {
      this.setState({
        pastRedemptions: _successLog,
      });
      this.pollCntr--;
    });
  };

  /**
   * @name preRenderChecks
   * @desc A wrapper method to check pre-render state of
   * screen.
   * @return {JSX}
   */
  preRenderChecks = () => {
    let mainText = null,
      subText = null,
      viewScheduleButton = false;

    if (!this.state.status) {
      return null;
    }

    // IF PLAY IS OFF
    // THEN SHOW DIFF. SCREEN
    if (
      this.state.status.play.type === this._playMainService.OFF ||
      this.state.status.play.type === this._playMainService.NOT_IN_SCHEDULE
    ) {
      if (this.state.status.play.type === this._playMainService.OFF) {
        mainText = <FormattedMessage id="closedMainText" />;
        subText = null;
        viewScheduleButton = false;
      }

      if (this.state.status.play.type === this._playMainService.NOT_IN_SCHEDULE) {
        mainText = <FormattedMessage id="closedMainText" />;
        subText = <FormattedMessage id="checkReopening" />;
        viewScheduleButton = false;
      }

      return (
        <>
          <UnleashProvider
            enabledRenderFn={() =>
              this._utilityService.checkStatusExists(this.props.app.features, 'Incentive_Engine') && <RemainingTokens />
            }
            flagName={UnleashService.FLAGS.REMAINING_TOKEN_BALANCE}
          />
          <Closed
            align="center"
            mainText={mainText}
            subText={subText}
            viewScheduleButton={viewScheduleButton}
            schedule={this.state.playSchedule}
          />
        </>
      );
    }

    return false;
  };

  render() {
    const { classes } = this.props;
    if (!navigator.onLine) {
      return (
        <Closed
          align="center"
          mainText={<FormattedMessage id="networkIssue" />}
          subText={<FormattedMessage id="networkIssueMsg" />}
        />
      );
    }

    const preRenderElement = this.preRenderChecks();
    // CHECKING BOOLEAN WITH *FALSE*
    // BECAUSE NULL IS EXPECTED AND NEEDS
    // TO BE FURTHER RETURNED.
    if (preRenderElement !== false) {
      return preRenderElement;
    }

    return (
      <Grid container item className={classes.playMain}>
        <DocumentTitle title="page.title.entertainment" />
        <Suspense fallback={<CircularProgress />}>
          {this._utilityService.checkStatusExists(this.props.app.features, 'Incentive_Engine') && (
            <>
              <Achievements meta={this.state.playMeta} />
              <Redemptions pastRedemptions={this.state.pastRedemptions} />
            </>
          )}
          <PlayTabs updateRedeemState={this.updateRedeemState} updatePlayStatus={this.updatePlayStatus} {...this.state} />
        </Suspense>
      </Grid>
    );
  }
}

const mapStateToProps = ({ app, play }) => {
  return {
    app,
    play,
  };
};
export default withStyles(styles)(connect(mapStateToProps)(PlayMain));
