import * as store from './';

const initialState = {
  pdqScores: {},
  pdqAssessmentStatus: null,
};

export const PDQAssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.SET_PDQ_SCORES:
      return {
        ...state,
        pdqScores: action.data,
      };
    case store.SET_PDQ_ASSESSMENT_STATUS:
      return {
        ...state,
        pdqAssessmentStatus: action.status,
      };
    default:
      return state;
  }
};
