import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import styles from './introduction.styles';
import { Constant } from '../../../../../../../shared/services';
import { FormattedMessage } from 'react-intl';
import { CheckCircleIcon } from '@orijinworks/frontend-commons';
import AuthGuardsHeader from '../../../header/auth-guards-header';

/**
 * Component to render the introduction for the Biometric Authentication Registration.
 *
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const Introduction = ({ classes, username, onPlayVideo, onContinue }) => {
  return (
    <>
      <AuthGuardsHeader />

      <Grid container>
        <Grid item sm={1} />
        <Grid item sm={10} className={classes.container}>
          <img
            id="biometric-auth-intro-face-icon"
            className={classes.faceIcon}
            alt=""
            src={`${Constant.CDN_BASE_URL}/assets/icons/face-viewfinder-solid.svg`}
          />
          <Typography id="biometric-auth-intro-title" color="secondary" variant="h1">
            <FormattedMessage id="welcomeName" values={{ name: username }} />
          </Typography>
          <Typography id="biometric-auth-intro-description" color="secondary" className={classes.description}>
            <FormattedMessage id="biometric-authentication.description" />
          </Typography>
          <img
            id="biometric-auth-intro-video-thumbnail"
            onClick={onPlayVideo}
            className={classes.demoVideoThumbnail}
            alt=""
            src="https://content.connectedcorrections.com/assets/thumbnails/biometric-authentication.png"
          />
          <Box className={classes.whyContainer}>
            <Typography id="biometric-auth-intro-why-title" color="secondary" variant="h2">
              <FormattedMessage id="biometric-authentication.why-important" />
            </Typography>
            <List id="biometric-auth-intro-why-points">
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="biometric-authentication.why-important.point1" />} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="biometric-authentication.why-important.point2" />} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="biometric-authentication.why-important.point3" />} />
              </ListItem>
            </List>
          </Box>
          <Button
            id="biometric-auth-intro-continue-button"
            color="primary"
            variant="contained"
            size="large"
            className={classes.continueButton}
            onClick={onContinue}
          >
            <FormattedMessage id="letsGetStarted" />
          </Button>
        </Grid>
        <Grid item sm={1} />
      </Grid>
    </>
  );
};

Introduction.propTypes = {
  /**
   * Material-UI classes object to style the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The username of the user.
   */
  username: PropTypes.string.isRequired,
  /**
   * The onPlayVideo function.
   */
  onPlayVideo: PropTypes.func.isRequired,
  /**
   * The onContinue function.
   */
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(Introduction);
