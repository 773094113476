import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchIcon from '@material-ui/icons/Search';

import styles from './program-listing.style';
import Program from '../../components/program/program';
import { Http } from '../../../../core';
import ProgramFilters from '../../components/program-filters/program-filters';
import { Constant, Utility } from '../../../../shared/services';
import { connect } from 'react-redux';
import { DocumentTitle } from '../../../../v2/shared';

export class ProgramListing extends React.Component {
  _utilityService = new Utility();
  constructor(props) {
    super(props);
    this.state = {
      minCharForSearch: 3,
      selectedFilter: 'all',
      searchTerm: '',
      programs: [],
      stopInfiniteScroll: false,
      nextPage: 1,
      dataLoading: true,
      searchError: false,
      showLoaderOnFilterChange: false,
      handleObserverCalled: false,
    };
    this.loaderRef = React.createRef();
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleObserver.bind(this));
    this.observer.observe(this.loaderRef);
    this.getProgramsList(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.testSuite) {
      return;
    }
    const loaderElem = this.loaderRef.getBoundingClientRect();
    if (window.innerHeight > loaderElem.y && !this.timerSet) {
      this.timerSet = true;
      const timer = setTimeout(() => {
        if (!this.state.handleObserverCalled) {
          this.getProgramsList();
        }
        this.timerSet = false;
        clearTimeout(timer);
      }, 3000);
    }
    if (this.props.selectedLocale !== prevProps.selectedLocale) {
      this.getProgramsList(true);
    }
  }

  getProgramsList = async (firstPage, noLoader) => {
    try {
      if (firstPage || !this.state.stopInfiniteScroll) {
        const pageNumber = firstPage ? 1 : this.state.nextPage;
        let path = `/programs/list?pageNumber=${pageNumber}`;
        if (this.state.selectedFilter === 'enrolled') {
          path += `&enrolledOnly=true`;
        }
        if (this.state.searchTerm) {
          path += `&searchKey=${this.state.searchTerm}`;
        }
        if (pageNumber > 1 || noLoader) {
          path += '&noLoader=true';
        }
        const programsList = await Http.REQUEST.get(path);
        if (!programsList || !programsList.data || programsList.data.length === 0) {
          this.setState({
            stopInfiniteScroll: true,
            programs: pageNumber === 1 ? [] : this.state.programs,
            dataLoading: false,
            showLoaderOnFilterChange: false,
          });
          return;
        }

        this.setState((prevState) => {
          return {
            ...prevState,
            programs: firstPage ? programsList.data : [...prevState.programs, ...programsList.data],
            nextPage: pageNumber + 1,
            dataLoading: false,
            showLoaderOnFilterChange: false,
            stopInfiniteScroll: firstPage ? false : prevState.stopInfiniteScroll,
            handleObserverCalled: firstPage ? false : prevState.handleObserverCalled,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleObserver = (entities) => {
    let handleObserverCalled = this.state.handleObserverCalled;
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.getProgramsList();
      handleObserverCalled = true;
    }
    this.setState({ prevY: y, handleObserverCalled });
  };

  handleFilterChange = (value) => {
    this.setState(
      {
        programs: [],
        selectedFilter: value,
        showLoaderOnFilterChange: true,
      },
      () => {
        this.getProgramsList(true, true);
      }
    );
  };

  handleSearch = (event) => {
    const value = event.target.value;
    this.setState(
      {
        searchTerm: value.trimStart(),
        searchError: false,
        dataLoading: !value,
      },
      () => {
        if (!value) {
          this.getProgramsList(true);
        }
      }
    );
  };

  onSearch = () => {
    this.setState({ dataLoading: true, searchTerm: this.state.searchTerm.trim() });
    this.getProgramsList(true);
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (this.state.searchTerm.length >= this.state.minCharForSearch) {
        this.onSearch();
      } else {
        this.setState({ searchError: true });
      }
    }
  };

  goToProgramDetails = (e, bundleId) => {
    e.stopPropagation();
    this.props.history.push(`/programs/${bundleId}/details`);
  };

  render() {
    const { classes, intl } = this.props;
    const {
      searchTerm,
      stopInfiniteScroll,
      dataLoading,
      searchError,
      minCharForSearch,
      selectedFilter,
      showLoaderOnFilterChange,
    } = this.state;

    const programs = this._utilityService.removeDuplicateObj(this.state.programs);

    return (
      <>
        <DocumentTitle title="page.title.programs" />
        <Box className={classes.header}>
          <Box className={classes.titleContainer}>
            <Typography variant="h2" gutterBottom className={classes.headerTitle}>
              <FormattedMessage id="programCatalog" />
            </Typography>
            <Typography>
              <FormattedMessage
                id="programs.header.desc"
                values={{
                  span: (...chunks) => <span>{chunks}</span>,
                  br: <br />,
                }}
              />
            </Typography>
          </Box>
          <img className={classes.headerImg} alt="" src="https://content.connectedcorrections.com/assets/img/Programs.png" />
        </Box>
        <Grid container sm={12} md={12} spacing={3} className={classes.topBarContainer}>
          {/* BEGIN: SEARCH BAR */}
          <Grid item sm={12} md={12} className={classes.alignRightContainer}>
            <Grid item sm={12} md={6} className={classes.searchBarContainer}>
              <Box width="100%">
                <FormControl variant="outlined" fullWidth size="small" className={classes.searchBarWrapper}>
                  <OutlinedInput
                    id="search-programs"
                    placeholder="Search programs..."
                    value={searchTerm}
                    onChange={this.handleSearch}
                    labelWidth={0}
                    fullWidth
                    className={classes.searchBar}
                    onKeyDown={this.onKeyDown}
                    error={searchError}
                    inputProps={{
                      id: 'search-programs-input',
                      'aria-label': intl.formatMessage({ id: 'searchPrograms' }),
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize="medium" color="primary" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {searchError && (
                  <FormHelperText error id="search-programs-error">
                    <FormattedMessage id="minCharSearchError" values={{ min: minCharForSearch }} />
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Button
                  id="search-programs-button"
                  className={classes.searchBtn}
                  color="secondary"
                  variant="contained"
                  onClick={this.onSearch}
                  disabled={searchTerm.length < minCharForSearch}
                  tracking-type={Constant.TRACKING_TYPES.PROGRAM}
                  tracking-id="search-program"
                  aria-label={intl.formatMessage({ id: 'search' })}
                >
                  <FormattedMessage id="search" />
                </Button>
              </Box>
            </Grid>
          </Grid>
          {/* END: SEARCH BAR */}

          {/* BEGIN: FILTERS */}
          <ProgramFilters handleFilterChange={this.handleFilterChange} selectedFilter={selectedFilter} />
          {/* END: FILTERS */}
        </Grid>

        {/* BEGIN: PROGRAMS LIST */}
        <Grid container sm={12} md={8} spacing={5} className={classes.programsContainer}>
          {programs.length === 0 && !dataLoading && !showLoaderOnFilterChange && (
            <Box>
              <Typography className={classes.noResult}>
                <FormattedMessage id={selectedFilter === 'enrolled' ? 'noMyPrograms' : 'noSearchResults'} />
              </Typography>
            </Box>
          )}
          {showLoaderOnFilterChange ? (
            <Box className={classes.loaderContainer}>
              <CircularProgress aria-label="loading" />
            </Box>
          ) : (
            programs.map((program) => (
              <Grid item sm={12} md={12} key={`${program.bundleId}`}>
                <Box>
                  <Program
                    title={program.bundleName}
                    description={program.descriptionShort}
                    image={program.thumbnailUriPath}
                    altText={program.thumbnailAltText}
                    buttonTitle={intl.formatMessage({ id: 'viewProgram' })}
                    enrolled={program.enrollmentState === 'ENROLLED'}
                    onClick={(e) => {
                      this.goToProgramDetails(e, program.bundleId);
                    }}
                    programId={program.bundleId}
                    providerLogo={program.resourceProvider?.thumbnailUriPath}
                    providerLogoAltText={program.resourceProvider?.thumbnailAltText}
                  />
                </Box>
              </Grid>
            ))
          )}
          <Grid item sm={12} md={12}>
            <Box ref={(ref) => (this.loaderRef = ref)} className={classes.loaderContainer}>
              {!stopInfiniteScroll && !dataLoading && !showLoaderOnFilterChange && <CircularProgress aria-label="loading" />}
            </Box>
          </Grid>
        </Grid>
        {/* END: PROGRAMS LIST */}
      </>
    );
  }
}

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProgramListing)));
