import { useCallback, useEffect, useState } from 'react';
import { CAREER_DIRECTORY_CONSTANTS, isVideoAvailable } from '../../services/careers.service';

const useCareerVideoHook = ({ careerId }) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoUrl = `${CAREER_DIRECTORY_CONSTANTS.CAREER_VIDEOS_PATH}/${careerId}.mp4`;
  const thumbnail = `${CAREER_DIRECTORY_CONSTANTS.CAREER_VIDEOS_PATH}/${careerId}.webp`;
  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnail);

  const checkIfVideoExists = useCallback(async () => {
    try {
      const exists = await isVideoAvailable(videoUrl);
      setShowVideo(exists);
    } catch (e) {
      setShowVideo(false);
    }
  }, [videoUrl]);

  useEffect(() => {
    checkIfVideoExists();
  }, [checkIfVideoExists]);

  useEffect(() => {
    setThumbnailUrl(thumbnail);
  }, [thumbnail]);

  const handleImageError = (e) => {
    setThumbnailUrl(CAREER_DIRECTORY_CONSTANTS.DEFAULT_VIDEO_THUMBNAIL);
  };

  return {
    showVideo,
    videoUrl,
    thumbnailUrl,
    handleImageError,
  };
};

export default useCareerVideoHook;
