import { Http } from '../../../core';
import { Constant, Utility } from '../../../shared/services';

export class ExploreService {
  utilityService = new Utility();
  /**
   * @name getExploreCollection
   * @param {string} searchKeywork
   * @param {array} contentAreas
   * @param {string} sortType
   * @param {number} pageNo
   * @desc Fetches explore collection based upon paginated
   * params.
   * @return {Promise}
   */
  getExploreCollection = (searchKeyword, contentAreas, sortType, pageNo) => {
    return new Promise((resolve, reject) => {
      this.utilityService.debounceMethod().then((_successState) => {
        Http.REQUEST.get(
          `/explore/list?noLoader=true&serviceName=${searchKeyword}&contentAreaKeys=${contentAreas.join(
            ','
          )}&pageNo=${pageNo}&size=${Constant.PAGINATED_ITEM}&sortBy=${sortType}`
        ).then((_successLog) => {
          resolve(_successLog.data);
        });
      });
    });
  };

  /**
   * @name getAllServices
   * @desc Fetches all categories and services.
   * @return {Promise}
   */
  getAllServices = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/explore/categories/services`)
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getServiceCredentials
   * @param {string} id
   * @desc Fetches service credentials by service id.
   * @return {Promise}
   */
  getServiceCredentials = (id) => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/service/${id}/credentials?noLoader=true`)
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getServicesByCategoryId
   * @param {string} id
   * @desc Fetches services by category id.
   * @return {Promise}
   */
  getServicesByCategoryId = (id) => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/explore/categories/${id}/services`)
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getServiceLaunchUrl
   * @param {string} serviceId
   * @desc Fetches explore collection based upon paginated
   * params.
   * @return {Promise}
   */
  getServiceLaunchUrl = (serviceId) => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/explore/launch/${serviceId}`)
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
