export default theme => ({
  hasScheduleWrapper: {
    padding: "0px 30px"
  },
  playAreaRoot: {
    padding: "0px 20px",
    margin: "40px 0px"
  },
  padded: {
    padding: "0px 40px"
  },
  [theme.breakpoints.down("md")]: {
    scheduleContainer: {
      padding: 20
    }
  }
});
