export default (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:focus-within': {
      outline: `2px solid ${theme.palette.primary.active}`,
    },
  },
  root: {
    width: 85,
    height: 37,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(48px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 30,
    height: 30,
    margin: '2px 0px 2px 4px',
  },
  track: {
    borderRadius: 37 / 2,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.2,
    transition: theme.transitions.create(['opacity', 'background-color']),
  },
  checked: {},
  focusVisible: {},
  label: {
    position: 'absolute',
    zIndex: 2,
    fontWeight: 700,
    color: 'white',
  },
  labelChecked: {
    left: 15,
  },
  labelUnchecked: {
    right: 15,
  },
});
