import React from 'react';
import { Box, Button, CircularProgress, IconButton, Typography, withStyles } from '@material-ui/core';
import styles from './conversation-history.style';
import { connect } from 'react-redux';
import useConversationHistoryHook from './useConversationHistoryHook';
import { formatTime } from '../../../../shared/services/utility/datetime.utility';
import { EyeClosedIcon, EyeOpenIcon, TextSeparator, Utility } from '../../../../shared';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { FormattedMessage, useIntl } from 'react-intl';

const ConversationHistory = ({ classes, ...props }) => {
  const {
    messages,
    showLoader,
    lastElementRef,
    messagesContainerRef,
    toggleMessageDetails,
    handleScroll,
    showMessageDetails,
    isMessageFromContact,
    isMessageTypeDateSeparator,
    toggleMessageVisibility,
  } = useConversationHistoryHook({ ...props });
  const { isFlagEnabled } = props.unleashProps;
  const intl = useIntl();

  return (
    <Box ref={messagesContainerRef} tabIndex={0} onScroll={handleScroll} className={classes.root}>
      {showLoader && (
        <Box className={classes.loaderContainer}>
          <CircularProgress />
        </Box>
      )}
      {messages?.map((message) => {
        const isMessageReceived = isMessageFromContact(message);
        const showDetails = showMessageDetails(message.messageId);

        return isMessageTypeDateSeparator(message) ? (
          <TextSeparator key={message.messageId} text={message.body} />
        ) : (
          <Box
            key={message.messageId}
            id={`message-${message.messageId}-container`}
            className={[
              classes.messageContainer,
              isMessageReceived ? classes.alignContentLeft : classes.alignContentRight,
            ].join(' ')}
          >
            <Box className={classes.messageWrapper}>
              {showDetails && (
                <Typography
                  id={`message-${message.messageId}-time`}
                  variant="body2"
                  className={[classes.datetime, isMessageReceived ? classes.alignSelfRight : classes.alignSelfLeft].join(
                    ' '
                  )}
                >
                  {formatTime(message.datetime)}
                </Typography>
              )}
              <Box
                id={`message-${message.messageId}-box`}
                className={[classes.messageBox, isMessageReceived ? classes.directionRowReverse : ''].join(' ')}
              >
                <Button
                  id={`message-${message.messageId}`}
                  onClick={() => {
                    toggleMessageDetails(message.messageId);
                  }}
                  className={[classes.message, isMessageReceived ? classes.receivedMessage : classes.sentMessage].join(' ')}
                  aria-label={intl.formatMessage({ id: 'toggleMessageDetails' })}
                >
                  {message.isHidden ? (
                    <FormattedMessage id="messageHidden" />
                  ) : (
                    <div dangerouslySetInnerHTML={Utility.createSafeMarkup(message.body)} />
                  )}
                </Button>
                {showDetails && isFlagEnabled() && (
                  <Box
                    id={`message-${message.messageId}-visibility-toggle-box`}
                    className={[classes.showHideBox, isMessageReceived ? classes.marginRight : classes.marginLeft].join(' ')}
                  >
                    <IconButton
                      id={`message-${message.messageId}-visibility-toggle`}
                      size="small"
                      className={classes.toggleShowHideButton}
                      onClick={() => {
                        toggleMessageVisibility(message.messageId);
                      }}
                      aria-label={intl.formatMessage({ id: message.isHidden ? 'unhideMessage' : 'hideMessage' })}
                    >
                      {message.isHidden ? <EyeClosedIcon size={20} /> : <EyeOpenIcon size={20} />}
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
      <Box ref={lastElementRef} />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  staffMessaging: state.staffMessaging,
});

export default connect(mapStateToProps)(
  withUnleash(withStyles(styles)(ConversationHistory), UnleashService.FLAGS.TOGGLE_MESSAGE_VISIBILITY)
);
