import React from 'react';
import { withStyles, Box, Typography, Dialog, DialogContent, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import styles from './same-answers-modal.style';

const SameAnswersModal = (props) => {
  const { classes, isOpen, onClose } = props;
  const intl = useIntl();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Box className={classes.root}>
          <Typography id="ip-sam-title" variant="h3" gutterBottom className={classes.title}>
            <FormattedMessage id="interest-profiler.same-answers-modal.title" />
          </Typography>
          <Typography id="ip-sam-description" className={classes.description}>
            <FormattedMessage id="interest-profiler.same-answers-modal.description" />
          </Typography>
          <Button
            id="ip-sam-action-btn"
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            onClick={onClose}
            aria-label={intl.formatMessage({ id: 'okThanks' })}
          >
            <FormattedMessage id="okThanks" />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(SameAnswersModal);
