import React from 'react';
import { Typography, Grid, Box, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './program-recommendation.styles';
import { withStyles } from '@material-ui/core/styles';
import RecommendationCard from '../../../../shared/components/recommendation-card/recommendation-card';
import PropTypes from 'prop-types';
import useProgramRecommendationHook from './useProgramRecommendationHook';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const ProgramRecommendation = ({ classes, selectedLocale }) => {
  const {
    programRecommendations,
    shouldShowViewMore,
    shouldDisplayNoRecommendations,
    toggleShowAllItems,
    showAllItems,
    getDisplayedRecommendations, // Add this
  } = useProgramRecommendationHook(selectedLocale);

  const intl = useIntl();

  return (
    <>
      <Typography id="program-recommendation-head" variant="h2" color="secondary" className={classes.header} align="center">
        <FormattedMessage id="career-compass.program-recommendations.heading" />
      </Typography>

      {shouldDisplayNoRecommendations() ? (
        <Typography variant="body1" className={classes.noRecommendations}>
          <FormattedMessage id="career-compass.program-recommendations.no-recommendations" />
        </Typography>
      ) : (
        <>
          <Typography id="program-recommendation-subhead" variant="h6" color="secondary" className={classes.subheader}>
            <FormattedMessage id="career-compass.program-recommendations.subheading" />
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {getDisplayedRecommendations().map((program) => (
              <Grid item xs={12} key={program.id} className={classes.card}>
                <RecommendationCard
                  title={program.title}
                  subtitle={program.name} // not showing on UI
                  description={program.description}
                  thumbnailUrl={program.thumbnail}
                  autoChangeOrientation={false}
                  showArrow={false}
                  orientation="horizontal"
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {shouldShowViewMore() && (
        <Box className={classes.viewMoreContainer}>
          <Button
            data-testid="view-more-button"
            color="primary"
            className={classes.viewMoreButton}
            onClick={toggleShowAllItems}
            endIcon={showAllItems ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            aria-label={intl.formatMessage({ id: showAllItems ? 'viewLess' : 'viewMore' })}
          >
            <FormattedMessage id={showAllItems ? 'viewLess' : 'viewMore'} />
          </Button>
        </Box>
      )}
    </>
  );
};

ProgramRecommendation.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLocale: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProgramRecommendation);
