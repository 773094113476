import * as store from './';

const initialState = {
  isRegistered: false,
};

export const voiceCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.IS_ENCODER_REGISTERED:
      return {
        ...state,
        isRegistered: action.isRegistered,
      };
    default:
      return state;
  }
};
