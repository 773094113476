export default (theme) => ({
  root: {
    color: theme.palette.primary.white,
    width: '100vw',
    height: '100vh',
    background: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  header: {
    position: 'absolute',
    top: 'calc(40% - 300px)',
  },
  cameraFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'url(https://content.connectedcorrections.com/assets/img/camera-frame.png)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: '50% 40%',
    transform: 'scale(1, 0.75)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '40%',
    maxWidth: 350,
    gap: 24,
  },
  title: {
    textTransform: 'uppercase',
  },
  continueButton: {
    textTransform: 'capitalize',
    width: 150,
  },
});
