export default (theme) => ({
  '@global': {
    '& #main-container': {
      height: '100%',
    },
  },
  root: {
    padding: '0',
    margin: '24px 24px 24px 101px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    height: 'calc(100vh - 136px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  mainContent: {
    padding: '32px 24px 24px',
  },
  image: {
    width: '100%',
    height: '253px',
    objectFit: 'cover',
    borderRadius: '8px 8px 0 0',
  },
  chip: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.32px',
    borderRadius: '8px',
    backgroundColor: theme.palette.aqua.extraDeep,
    textTransform: 'capitalize',
  },
  title: {
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    color: theme.palette.greyScale[110],
    margin: '24px 0 !important',
  },
  description: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.32px',
    color: theme.palette.greyScale[110],
    marginBottom: '16px !important',
  },
  boldText: {
    fontWeight: '500 !important',
  },
  alignRight: {
    textAlign: 'right',
  },
  progressBarText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  progressBarRoot: {
    height: 8,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.grey,
  },
  progressBarColorPrimary: {
    backgroundColor: theme.palette.primary.grey,
  },
  progressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.green.main,
  },

  certificateSection: {
    marginTop: '24px',
  },
  certificateLabel: {
    color: theme.palette.greyScale[110],
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.32px',
    marginBottom: '-12px',
  },
  certificate: {
    textAlign: 'center',
    '& h4': {
      color: 'white',
      textAlign: 'center',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.32px',
    },
    '& p': {
      fontWeight: 400,
    },
    '& button': {
      margin: 'auto',
    },
    '& > div': {
      display: 'block',
    },
  },
});
