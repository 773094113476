import React from "react";

const PathwaysIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M21.847 4.702l1.25 7.09-2.744-1.92c-2.077 3.057-4.16 4.693-6.25 4.909-3.283.34-3.952-1.727-5.532-1.727-1.054 0-2.611 1.779-4.672 5.337l-2.899-2c2.717-4.464 5.17-6.696 7.36-6.696 3.283 0 3.805 2.031 5.288 1.87.938-.104 2.209-1.342 3.813-3.717l-.203-.144-2.502-1.752 7.09-1.25z"
        fill={fill}
      />
    </svg>
  );
};

PathwaysIcon.defaultProps = {
  size: 24,
  fill: "white"
};

export default PathwaysIcon;
