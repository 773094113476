export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '75px 30px 30px 30px',
  },
  image: {
    maxWidth: 350,
    marginBottom: 25,
  },
  title: {
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: 15,
  },
  subtitle: {
    fontWeight: 400,
    marginBottom: 15,
  },
  description: {
    color: theme.palette.text.sub,
    maxWidth: 400,
  },
});
