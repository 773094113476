export default (theme) => ({
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 4,
  },
  headerTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  headerImg: {
    maxWidth: 300,
    maxHeight: 250,
  },
  tabBar: {
    backgroundColor: 'white',
    width: 'fit-content',
    border: '1px solid rgba(0,0,0,0.23)',
  },
  tabIndicator: {
    display: 'none',
  },
  tabSelected: {
    backgroundColor: '#9ACAFF',
  },
  tabs: {
    minHeight: 35,
  },
  tab: {
    minHeight: 35,
  },
  searchBar: {
    backgroundColor: 'white',
    borderRadius: 10,
  },
  programsContainer: {
    margin: '10px 0px',
  },
  topBarContainer: {
    margin: '10px 12px',
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchBarWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBtn: {
    border: '1px solid rgba(0,0,0,0.26)',
    textTransform: 'capitalize',
    marginLeft: 15,
    paddingLeft: 35,
    paddingRight: 35,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noResult: {
    padding: 20,
  },
  divider: {
    background: theme.palette.greyScale[60],
    opacity: 1,
    height: 1,
    margin: '0.5rem 0',
    border: 0,
  },
  titleContainer: {
    padding: 30,
    paddingBottom: 0,
  },
  descriptionMargin: {
    marginBottom: '18px',
  },
  programsLength: {
    paddingLeft: 20,
  },
  ribbonIcon: {
    width: '15px !important',
    height: '15px !important',
    margin: '0px 4px',
  },
});
