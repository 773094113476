import { Box, withStyles, CircularProgress } from '@material-ui/core';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import { Http, Permission } from '../../../../core';
import { Utility } from '../../../../shared';
import styles from './pathway-details.style';
import { PathwayListingService } from '../../services';

const DetailsListing = React.lazy(() => import('../../components/details-listing/details-listing'));
const DetailsTop = React.lazy(() => import('../../components/details-top/details-top'));

class PathwayDetails extends React.Component {
  scrollableElement = null;
  utilityService = new Utility();
  pathwayService = new PathwayListingService();

  constructor() {
    super();
    this.state = {
      pathwayDetails: {},
      isMounted: false,
      isScrollDown: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (this.state.isMounted) {
      return;
    }
    this.initRoute();
    this.getPathWayDetails(this.props.match.params.id);
  }

  componentDidUpdate() {
    this.initRoute();
  }

  /**
   * @name initRoute
   * @desc Initializes route based upon a condition.
   * @return {void}
   */
  initRoute = () => {
    const routeHasPermission = this.utilityService.checkStatusExists(
      this.props.features,
      this.utilityService.getTitleFromUrl('permission')
    );

    if (routeHasPermission && Object.keys(this.state.pathwayDetails).length > 0) {
      if (!this.scrollableElement && !this.state.isLoading) {
        this.scrollableElement = document.querySelector('#detail-list-wrapper');
        if (this.scrollableElement) {
          this.scrollableElement.addEventListener('scroll', this.handleScroll, {
            passive: true,
          });
        }
      }
    }
  };

  componentWillUnmount() {
    this.pathwayService.setCacheDetailsData = {};
    if (this.scrollableElement) {
      this.scrollableElement.removeEventListener('scroll', this.handleScroll);
    }
  }

  async getPathWayDetails(id) {
    const tempContentData = this.pathwayService.getCacheDetailsData;
    this.setState({
      pathwayDetails: tempContentData,
      isLoading: true,
    });
    try {
      const hasContentData = Object.keys(tempContentData).length > 0;
      const queryParams = `?${hasContentData ? 'noLoader=true' : null}`;
      const pathwayDetails = await Http.REQUEST.get(`/resource/${id}${queryParams}`);
      if (pathwayDetails.data && pathwayDetails.data.children && pathwayDetails.data.children.length) {
        this.getProgress(pathwayDetails.data);
      }
      this.setState({
        ...this.state,
        pathwayDetails: pathwayDetails.data,
        isMounted: true,
        isLoading: false,
      });
    } catch (error) {
      console.log({ pathwayDetails: error });
    }
  }

  getGradableAndActionableChild(pathwayItems) {
    let totalNoOfItem = 0;
    let completedItem = 0;

    if (pathwayItems.tags && pathwayItems.tags.length) {
      totalNoOfItem = pathwayItems.tags.filter((tag) => tag === 'GRADABLE' || tag === 'ACTIONABLE').length ? 1 : 0;
      completedItem =
        pathwayItems.tags.filter((tag) => tag === 'GRADABLE' || tag === 'ACTIONABLE').length && pathwayItems.isCompleted
          ? 1
          : 0;
    }

    return { totalNoOfItem, completedItem };
  }

  getProgress(pathwayResource) {
    if (pathwayResource.children && pathwayResource.children.length) {
      const actualProgress = this.getGradableAndActionableChild(pathwayResource);
      pathwayResource.children.forEach((resourceChild) => {
        const progress = this.getProgress(resourceChild);
        actualProgress.totalNoOfItem += progress.totalNoOfItem;
        actualProgress.completedItem += progress.completedItem;
      });
      pathwayResource.progress = actualProgress;
      this.calculateProgress(pathwayResource);
      return pathwayResource.progress;
    }

    pathwayResource.progress = this.getGradableAndActionableChild(pathwayResource);
    this.calculateProgress(pathwayResource);
    return pathwayResource.progress;
  }

  calculateProgress(pathwayResource) {
    pathwayResource.percentageCompleted = Math.floor(
      (pathwayResource.progress.totalNoOfItem === 0
        ? 0
        : pathwayResource.progress.completedItem / pathwayResource.progress.totalNoOfItem) * 100
    );
  }

  handleScroll(e) {
    const scrollY = e.target.scrollTop;
    const elementScrollHeight = this.scrollableElement.scrollHeight;
    const screenScrollHeight = window.innerHeight;
    if (scrollY > 0 && elementScrollHeight > screenScrollHeight) {
      this.setState({ ...this.state, isScrollDown: true });
      return;
    }
    this.setState({ ...this.state, isScrollDown: false });
  }

  render() {
    const { classes } = this.props;
    if (Object.keys(this.state.pathwayDetails).length === 0) {
      return null;
    }
    return (
      <Permission>
        <Box component="div" display="flex" className={classes.root}>
          <Suspense fallback={<CircularProgress />}>
            <DetailsTop isScrollDown={this.state.isScrollDown} pathwayDetails={this.state.pathwayDetails} {...this.props} />
            <DetailsListing
              isLoading={this.state.isLoading}
              isActive={this.state.pathwayDetails.active}
              listingItems={this.state.pathwayDetails.children || null}
              courseId={this.state.pathwayDetails.id || null}
            />

            {/* <DetailsBottom
              scrollableElement={this.scrollableElement}
              isScrollDown={this.state.isScrollDown}
            /> */}
          </Suspense>
        </Box>
      </Permission>
    );
  }
}

const mapStateToProps = ({ app }) => app;

export default withStyles(styles)(connect(mapStateToProps)(PathwayDetails));
