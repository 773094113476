/**
 * @name fetchJsonData
 * @description Fetch JSON data from URL
 * @param {String} url
 * @returns {Promise<Object>}
 */
const fetchJsonData = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @name fetchFileContentAsUint8Array
 * @description Fetch file content as Uint8Array from URL
 * @param {String} url
 * @returns {Promise<Uint8Array>}
 */
const fetchFileContentAsUint8Array = (url) => {
  return new Promise(async (resolve, reject) => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        resolve(new Uint8Array(arrayBuffer));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { fetchJsonData, fetchFileContentAsUint8Array };
