import { useEffect } from 'react';
import { setNewTheme, store } from '../../../../core';

const useThemeSwitcherHook = ({ useNewTheme, unleashProps }) => {
  const isFlagEnabled = unleashProps.isFlagEnabled();

  useEffect(() => {
    if (isFlagEnabled !== useNewTheme) {
      store.dispatch(setNewTheme(isFlagEnabled));
    }
  }, [isFlagEnabled, useNewTheme]);
};

export default useThemeSwitcherHook;
