export default (theme) => ({
  root: {
    backgroundImage: 'url("https://content.connectedcorrections.com/assets/img/Sign-in_Background.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content: {
    padding: '0px 12px',
    position: 'relative',
  },
  closeIconContainer: {
    padding: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    color: theme.palette.primary.white,
    zIndex: 9,
  },
  additionalContent: {
    padding: '32px 32px 50px 32px',
  },
  textContainer: {
    padding: '0px 48px 16px 48px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.primary.white,
    gap: 12,
  },
  animation: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  certificate: {
    '& .react-pdf__Page__svg, & svg': {
      width: '100% !important',
      height: 'auto !important',
      borderRadius: 16,
    },
  },
  certificateDocument: {
    '& .react-pdf__message': {
      color: theme.palette.primary.white,
    },
  },
});
