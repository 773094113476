import { Constant } from '../../../../shared/services';

const useEmptyInboxHook = (props) => {
  const { contactsCount, isActiveModeMessages, isActiveModeAnnouncements } = props;

  const getEmptyInboxData = () => {
    let data = {
      imageUrl: '',
      title: '',
      subtitle: '',
      description: '',
    };
    if (isActiveModeMessages()) {
      data = getNoMessagesData();
    } else if (isActiveModeAnnouncements()) {
      data = getNoAnnouncementsData();
    }
    return data;
  };

  const getNoMessagesData = () => {
    if (contactsCount === 0) {
      return {
        imageUrl: `${Constant.CDN_BASE_URL}/assets/img/no-contacts.png`,
        title: 'staff-messaging.no-contacts.title',
        subtitle: 'staff-messaging.no-contacts.subtitle',
        description: 'staff-messaging.no-contacts.description',
      };
    } else {
      return {
        imageUrl: `${Constant.CDN_BASE_URL}/assets/img/empty-inbox.png`,
        title: 'staff-messaging.empty-inbox.title',
        subtitle: '',
        description: 'staff-messaging.empty-inbox.description',
      };
    }
  };

  const getNoAnnouncementsData = () => {
    return {
      imageUrl: `${Constant.CDN_BASE_URL}/assets/img/no-announcements.png`,
      title: 'staff-messaging.no-announcements.title',
      subtitle: '',
      description: 'staff-messaging.no-announcements.description',
    };
  };

  return {
    getEmptyInboxData,
  };
};

export default useEmptyInboxHook;
