import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Constant, Utility } from '../../../../../shared/services';

const useResumeBuilderTileHook = ({ categoryId, features }) => {
  const _utility = new Utility();
  const history = useHistory();

  const isFeatureEnabled = () => {
    return _utility.checkStatusExists(features, Constant.FEATURES_ENUM.RESUME_BUILDER);
  };

  const isCategoryWorkforceSkills = () => {
    return categoryId === Constant.SERVICE_CATEGORIES_ENUM.WORKFORCE_SKILLS;
  };

  const showTile = () => {
    return isFeatureEnabled() && isCategoryWorkforceSkills();
  };

  const launchResumeBuilder = () => {
    history.push('/resume-builder');
  };

  return {
    showTile,
    launchResumeBuilder,
  };
};

export default useResumeBuilderTileHook;
