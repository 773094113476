export default (theme) => ({
  root: {
    width: '100%',
  },
  header: {
    padding: 30,
    backgroundColor: theme.palette.text.primary,
    borderRadius: '10px 10px 0px 0px',
    borderBottom: '1px solid',
    borderColor: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
  },
  numberBox: {
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    backgroundColor: theme.palette.colors.grey,
    borderRadius: 5,
  },
  body: {
    padding: 30,
    borderRadius: '0px 0px 10px 10px',
  },
  headerText: {
    fontWeight: 700,
    color: 'white',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 450,
    maxHeight: 450,
  },
  questionImg: {
    maxWidth: '100%',
    maxHeight: 450,
  },
  answerContainer: {
    paddingLeft: '30px !important',
    paddingRight: '30px !important',
  },
  whiteText: {
    color: 'white',
  },
});
