import * as store from "./";

const setAnswersString = string => {
  return {
    type: store.INTEREST_PROFILER_SET_ANSWERS_STRING,
    string
  };
};

const setRecommendedJobZone = jobZone => {
  return {
    type: store.SET_RECOMMENDED_JOB_ZONE,
    jobZone
  }
}

export { setAnswersString, setRecommendedJobZone };
