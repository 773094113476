export default theme => ({
  sectionMargin: {
    marginTop: 55
  },
  assignedChip: {
    ...theme.typography.medium,
    letterSpacing: "0.75px",
    ...theme.typography.t14,
    padding: "5px 10px"
  },
  pathwayListingWrapper: {
    paddingBottom: 25
  },
  cardHeading: {
    ...theme.typography.t16,
    ...theme.typography.medium,
    marginTop: 10,
    marginBottom: 10
  },
  roundButton: {
    width: "initial",
    border: `1px solid ${theme.palette.primary.main}`,
    ...theme.typography.t14,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: -30
  },
  roundButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  unassignedTextClass: {
    textTransform: "uppercase",
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: 1.5,
    color: "#c00013"
  }
});
