export default (theme) => ({
  content: {
    height: '75vh',
    maxHeight: '600px',
    position: 'relative',
    padding: '0px !important',
  },
  thumbnail: {
    width: '100%',
    height: 'calc(100% - 90px)',
    objectFit: 'contain',
  },
  playerContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    zIndex: 9,
  },
});
