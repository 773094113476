import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.background,
  },
  mainContainer: {
    overflow: 'hidden',
    width: '100%',
    padding: '0px 35px 0px 100px',
    marginBottom: 20,
    zIndex: 99,
    position: 'relative',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    padding: '32px 0px 0px',
    minHeight: 'calc(100vh - 20px)',
    transition: 'all 1s',
    maxWidth: '100%',
  },
  layoutWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  minThresold: {
    minHeight: 120,
  },
  mainWrapper: {
    paddingTop: 80,
  },
  skipLink: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
}));
