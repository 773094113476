import React from 'react';

const WarningIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4523 0.639844C13.3055 -0.213281 14.6891 -0.213281 15.5477 0.639844L27.3602 12.4523C28.2133 13.3055 28.2133 14.6891 27.3602 15.5477L15.5477 27.3602C14.6945 28.2133 13.3109 28.2133 12.4523 27.3602L0.639844 15.5477C-0.213281 14.6945 -0.213281 13.3109 0.639844 12.4523L12.4523 0.639844ZM14 7C13.2727 7 12.6875 7.58516 12.6875 8.3125V14.4375C12.6875 15.1648 13.2727 15.75 14 15.75C14.7273 15.75 15.3125 15.1648 15.3125 14.4375V8.3125C15.3125 7.58516 14.7273 7 14 7ZM15.75 19.25C15.75 18.282 14.968 17.5 14 17.5C13.032 17.5 12.25 18.282 12.25 19.25C12.25 20.218 13.032 21 14 21C14.968 21 15.75 20.218 15.75 19.25Z"
        fill={fill}
      />
    </svg>
  );
};

WarningIcon.defaultProps = {
  size: '28',
  fill: 'inherit',
};

export default WarningIcon;
