export default (theme) => ({
  title: {
    ...theme.typography.t30,
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  description: {
    color: theme.palette.greyScale[110],
    '& ul': {
      listStyle: 'none',
      '& > li::before': {
        content: '"\\2022"',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1.3rem',
        marginLeft: '-1rem',
        fontSize: 35,
        verticalAlign: 'middle',
        lineHeight: 1,
        marginBottom: 5,
      },
    },
  },
  scoresContainer: {
    padding: '30px 0',
  },
  subtitle: {
    ...theme.typography.t20,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  paddingTop30: {
    paddingTop: 30,
  },
  paddingTop20: {
    paddingTop: 20,
  },
});
