export default (theme) => ({
  root: {
    padding: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
  datetime: {
    color: theme.palette.text.secondary,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  title: {
    ...theme.typography.t22,
    fontWeight: 700,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  body: {
    color: theme.palette.primary.black,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
});
