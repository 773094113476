export default (theme) => ({
  callCenterSection: {
    margin: '30px 30px 0px 30px !important',
    borderRadius: '10px !important',
    background: theme.palette.greyScale.grey03,
    padding: '20px !important',
    width: '62%',
  },
  callCenterHeaderSection: {
    display: 'block',
  },

  callCenterTitle: {
    fontWeight: 800,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'flex-start',
  },
  callCenterOpen: {
    color: theme.palette.green.main,
  },
  callCenterClosed: {
    color: theme.palette.greyScale[110],
  },
  callCenterClosingSoon: {
    color: theme.palette.orange.main,
  },
  callCenterHeaderDescription: {
    paddingRight: 10,
    paddingTop: 10,
    color: theme.palette.greyScale[110],
    marginLeft: '32px',
  },
  callCenterBodySection: {
    borderTop: `1px solid ${theme.palette.greyScale.lavenderGrey}`,
    width: '100%',
    paddingRight: 20,
  },
  callCenterScheduleDetails: {
    paddingTop: 10,
  },

  availableTimes: {
    display: 'flex',
    fontWeight: 700,
    color: theme.palette.navy.main,
    marginTop: 10,
  },
  scheduleRow: {
    padding: '10px 0',
  },
  dayOfWeek: {
    color: theme.palette.cerulean.cerulean20,
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  seeFullScheduleSection: {
    paddingTop: 20,
  },
  seeFullSchedule: {
    textTransform: 'capitalize',
    color: theme.palette.cerulean.cerulean20,
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },

  timeSlot: {
    paddingTop: 2,
  },
});
