import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

let slideCntr = 0;

const getNoOfItems = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1070) {
    return 4;
  }
  return 2;
};

const ListenScrollEnds = (itemCount, contentWrapperId) => {
  const [showBackForBtns, setScrollEnd] = React.useState([false, itemCount > getNoOfItems() ? true : false]);
  React.useLayoutEffect(() => {
    const updateScroll = (e) => {
      if (e) {
        slideCntr = e.target.scrollLeft;
        if (e.target.scrollLeft + e.target.offsetWidth >= e.target.scrollWidth) {
          setScrollEnd([true, false]);
          return;
        } else if (e.target.scrollLeft > 0) {
          setScrollEnd([true, true]);
          return;
        }
        setScrollEnd([false, e.target.scrollWidth > e.target.clientWidth]);
      }
    };
    const element = document.querySelector(`#${contentWrapperId}`);
    if (element) {
      element.addEventListener('scroll', updateScroll);
    }
    updateScroll();
    return () => window.removeEventListener('scroll', updateScroll);
  }, [itemCount, contentWrapperId]);
  return showBackForBtns;
};

function scrollBy(duration, elem, type) {
  const startTime = performance.now();
  function step() {
    let normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;
    if (type === 'forward') {
      slideCntr += 10;
    } else if (type === 'back') {
      slideCntr -= 10;
    }
    elem.scrollLeft = slideCntr;
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

const carousel = (props) => {
  const { classes } = props;
  let showScrollBtns = [true, true];
  if (!props.isTestable) {
    showScrollBtns = ListenScrollEnds(props.itemCount, props.contentWrapperId);
  }
  return (
    <>
      <Grid className={classes.carouselHead} container item sm={12} direction="row">
        <Grid>
          <Typography data-cy-name={`grid-${props.id}`} variant="h4" className={classes.carouselViewText}>
            {props.title}
          </Typography>
        </Grid>
        &nbsp;&nbsp;
        {props.contentTypes}
      </Grid>
      <Grid item sm={12}>
        <Grid className={classes.carouselWrapper}>
          {
            <Button
              color="primary"
              id="back-button"
              disabled={!props.showBackNavigator || !showScrollBtns[0]}
              onClick={() => {
                const element = document.querySelector(`#${props.contentWrapperId}`);
                scrollBy(1000, element, 'back');
              }}
              className={[classes.carouselNavigator, classes.carouselNavigatorLeft].join(' ')}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Button>
          }

          {
            <Button
              id="forward-button"
              disabled={!props.showNextNavigator || !showScrollBtns[1]}
              onClick={() => {
                const element = document.querySelector(`#${props.contentWrapperId}`);
                scrollBy(1000, element, 'forward');
              }}
              className={[classes.carouselNavigator, classes.carouselNavigatorRight].join(' ')}
              color="primary"
            >
              <KeyboardArrowRightIcon fontSize="large" />
            </Button>
          }
          <Grid id={props.contentWrapperId} className={[classes.contentWrapper, 'no-scroll'].join(' ')}>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(carousel);
