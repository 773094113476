import React from 'react';

const CallErrorIcon = (props) => {
  const { size } = props;

  return (
    <svg
      role="img"
      aria-label="Icon if the call is failed"
      width={size}
      height={size}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="26" fill="#E32D4E" />
      <path
        d="M26 12.6667C18.64 12.6667 12.6666 18.64 12.6666 26C12.6666 33.36 18.64 39.3334 26 39.3334C33.36 39.3334 39.3333 33.36 39.3333 26C39.3333 18.64 33.36 12.6667 26 12.6667ZM27.3333 32.6667H24.6666V30H27.3333V32.6667ZM27.3333 27.3334H24.6666V19.3334H27.3333V27.3334Z"
        fill="white"
      />
    </svg>
  );
};
CallErrorIcon.defaultProps = {
  size: 52,
};
export default CallErrorIcon;
