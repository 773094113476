export default theme => ({
  educationChip: {
    "background-color": "#cbe3fd",
    border: "1px solid #aec8e1",
    color: "#3f75a6"
  },
  reEntryChips: {
    "background-color": "#f4eefc",
    border: "1px solid #e4d5f7",
    color: "#b489ea"
  }
});
