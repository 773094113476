export default theme => ({
  cardContent: {
    padding: "10px 10px 0px"
  },
  card: {
    padding: "5px 10px",
    cursor: "pointer",
    transition: "all 0.5s",
    opacity: 0,
    transform: "rotateY(100deg)",
    height: "100%",
    width: 220,
    borderRadius: 8,
    boxShadow: theme.palette.boxShadow,
    backgroundColor: theme.palette.primary.white
  },
  fullWidth: {
    width: "100%"
  },
  cardActionWrapper: {
    minHeight: 59,
    margin: -10,
    marginTop: 10,
    padding: 0
  },
  launchBtn: {
    ...theme.typography.bold,
    ...theme.typography.t18,
    color: theme.palette.primary.main,
    width: "100%",
    height: 59,
    "&:hover": {
      transition: "all 0.2s",
      color: theme.palette.primary.main,
      opacity: 0.8
    }
  },
  showCard: {
    transform: "initial",
    opacity: 1
  },
  carouselCardReward: {
    color: theme.palette.primary.black,
    fontSize: theme.typography.t18.fontSize,
    fontWeight: theme.typography.medium.fontWeight
  },
  cardTextContainer: {
    marginBottom: 0,
    minHeight: 113
  },
  textWrapper: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "-webkit-box-orient": "vertical"
  },
  cardHeading: {
    fontSize: theme.typography.t24.fontSize,
    color: theme.palette.primary.black,
    fontWeight: theme.typography.medium.fontWeight,
    whiteSpace: "initial"
  },
  cardSubheading: {
    color: "black",
    fontSize: theme.typography.t12.fontSize,
    whiteSpace: "normal",
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
  cardActionBtn: {
    fontSize: theme.typography.t18.fontSize,
    textTransform: "capitalize",
    fontWeight: theme.typography.bold.fontWeight,
    letterSpacing: "1px",
    minHeight: 59
  },
  cardHead: {},
  subProgressText: {
    color: theme.palette.primary.battleshipGrey,
    fontSize: theme.typography.t12.fontSize
  }
});
