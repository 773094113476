import React from 'react';
import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './learning-module.style';
import { RedirectIcon } from '../../../../shared/icons';
import { Constant } from '../../../../shared/services';
import useLearningModuleHook from './useLearningModuleHook';
import { ProgressChip } from '..';

const LearningModule = (props) => {
  const { classes, isEnrolled, module, index, name, id, progress, showLaunchButton, handleListClicked } = props;
  const { launchModule } = useLearningModuleHook();
  const intl = useIntl();

  return (
    <Grid className={classes.moduleContainer}>
      <Grid sm={6} md={8} lg={9} xl={10} className={classes.moduleItem}>
        <Box className={classes.moduleNameContainer}>
          <Typography id="learning-module-subtitle" className={classes.moduleText} gutterBottom>
            {`${module} ${index + 1}:`}
          </Typography>
          <Typography id="learning-module-title" variant="h3" onClick={handleListClicked}>
            {name}
          </Typography>
        </Box>
      </Grid>
      <Grid sm={7} md={5} lg={4} xl={2} className={classes.actionContainer}>
        {isEnrolled && <ProgressChip progress={progress} />}
        {showLaunchButton && (
          <Button
            id="launch-learning-module-btn"
            className={[classes.launchBtn, !isEnrolled ? classes.disableButton : ''].join(' ')}
            color="primary"
            onClick={() => {
              launchModule(id, name);
            }}
            tracking-type={Constant.TRACKING_TYPES.COURSE}
            tracking-id={`launch-course-${id}`}
            endIcon={<RedirectIcon size={15} fill="white" />}
            variant="contained"
            aria-label={intl.formatMessage({ id: 'launch' })}
          >
            <FormattedMessage id="launch" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LearningModule);
