export default theme => ({
  titleContainer: {
    padding: "25px 25px 10px 25px"
  },
  actionsContainer: {
    justifyContent: "flex-start",
    padding: "10px 25px 25px 25px"
  },
  actionBtn: {
    padding: "10px 35px",
    textTransform: "capitalize"
  },
  title: {
    fontWeight: 700
  }
});
