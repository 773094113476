export default (theme) => ({
  header: {
    display: 'flex',
    marginTop: 20,
    paddingBottom: 12,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  subheader: {
    fontWeight: 400,
    color: theme.palette.greyScale[110],
    textTransform: 'none',
  },
  card: {
    margin: '16px 0',
  },
  viewMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 32,
    padding: 16,
  },
  viewMoreButton: {
    textTransform: 'capitalize',
  },
  noRecommendations: {
    display: 'flex',
    height: '100px',
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
  },
});
