import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {},
  analyticsWrapper: {
    marginBottom: 20,
    background: theme.palette.primary.darkBlue,
    color: theme.palette.primary.white,
    padding: "10px 25px",
    borderRadius: "5px 0px 0px 5px",
    boxShadow: "0px 2px 15px 3px #02184347",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginRight: -50
    }
  },
  noGreeting: {
    fontSize: theme.typography.t20.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
    color: theme.palette.primary.white
  },

  confettiImg: {
    maxWidth: 273,
    position: "absolute",
    top: 0,
    right: -50
  }
}));
