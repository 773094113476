import * as store from './';

const initialState = {
  currentStep: null,
};

export const careerCompassReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.SET_CAREER_COMPASS_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      };
    default:
      return state;
  }
};
