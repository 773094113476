import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Modal, Typography, ModalBody as OriginalModalBody } from '@orijinworks/frontend-commons';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ListItemIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'; // Import withStyles
import styles from './archive-contact-modal.style'; // Import styles from separate file
import useArchiveContactsModalHook from './useArchiveContactsModalHook';
import PropTypes from 'prop-types';
import { CheckmarkIcon } from '../../../../shared';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ContactRequestConstants } from '../../services';
import { Box, Chip } from '@material-ui/core';
import { ListItemSecondaryAction } from '@mui/material';

const ArchiveContactsModal = ({ classes, contacts, maxContactQuota, consumedContactQuota, open, onArchive }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED:
        return (
          <CheckmarkIcon
            className={[classes.icon, classes[`${ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED}Icon`]].join(' ')}
            size={18}
            fill="green"
          />
        );
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.PENDING:
        return (
          <RadioButtonUncheckedIcon
            className={[classes.icon, classes[`${ContactRequestConstants.CONTACT_REQUEST_STATUSES.PENDING}Icon`]].join(' ')}
          />
        );
      default:
        return null;
    }
  };

  const { selectedContacts, handleContactSelection, handleArchive } = useArchiveContactsModalHook({
    classes,
    contacts,
    maxContactQuota,
    consumedContactQuota,
    open,
    onArchive,
  });

  const exceededContactCount = Math.abs(maxContactQuota - consumedContactQuota);
  return (
    <Modal
      heading="Select Contacts to Archive"
      Icon={<ArchiveIcon />}
      open={open}
      cancelButtonProps={{ style: { display: 'none' } }}
      continueButtonProps={{ disabled: selectedContacts.length < exceededContactCount }}
      showCrossIcon={false}
      onContinue={handleArchive}
      actionBtnText="Archive Contact"
      styles={{
        mainWrapperStyles: {
          height: 748,
        },
      }}
    >
      <Typography id="contact-selection-body" variant="body1" className={classes.modalBody}>
        The max number of contacts was recently updated by an admin to a limit of {maxContactQuota} contacts. Please select{' '}
        <b>{exceededContactCount} contact(s)</b> to archive.
      </Typography>

      <div className={classes.contactScrollSection}>
        <List id="contact-list">
          {contacts.map((contact) => (
            <ListItem key={contact.id} id="contact-selection-item" className={classes.listItem}>
              <ListItemIcon id="contact-selection-icon">
                <Checkbox
                  id="contact-checkbox"
                  className={classes.checkbox}
                  checked={selectedContacts.indexOf(contact.id) !== -1}
                  onChange={handleContactSelection(contact.id)}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" component="span">
                    {contact.firstName} {contact.lastName}
                  </Typography>
                }
                secondary={
                  <Typography variant="body3" component="span">
                    {contact.phoneNumber}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Chip
                  label={
                    <Box className={classes.paperBox}>
                      {getStatusIcon(contact.status.toLowerCase())}
                      <Typography variant="body2" className={classes.statusText}>
                        {contact.status.toUpperCase() === 'INACTIVE' ? 'ARCHIVED' : contact.status.toUpperCase()}
                      </Typography>
                    </Box>
                  }
                  className={`${classes[`${contact.status.toLowerCase()}`]}`}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Modal>
  );
};

ArchiveContactsModal.propTypes = {
  contacts: PropTypes.array.isRequired,
  maxContactQuota: PropTypes.number.isRequired,
  consumedContactQuota: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onArchive: PropTypes.func.isRequired,
};

export default withStyles(styles)(ArchiveContactsModal);
