export default (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: '0px 12px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px',
    },
  },
  container: {
    borderRadius: 16,
    background: 'white',
    minHeight: 350,
    height: '100%',
    border: `1px solid ${theme.palette.greyScale[60]}`,
    position: 'relative',
  },
  bgOverlay: {
    background: 'url(https://content.connectedcorrections.com/assets/img/ccfe-career-profile-bg.jpg) no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '200%',
    width: '100%',
    height: 91,
    borderRadius: '12px 12px 0px 0px',
  },
  bookmarkBanner: {
    position: 'absolute',
    top: -5,
    right: 50,
    zIndex: 1,
  },
  details: {
    padding: '0px 24px',
  },
  titleContainer: {
    padding: '14px 20px',
    maxWidth: '80%',
    width: 'fit-content',
    borderRadius: '16px 16px 0px 0px',
    marginTop: -30,
    display: 'flex',
    background: 'white',
    alignItems: 'center',
    gap: 16,
  },
  zoneBadge: {
    padding: '6px 12px',
    borderRadius: 4,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  zoneColor1: {
    backgroundColor: theme.palette.jobZone.colors.zone1,
  },
  zoneColor2: {
    backgroundColor: theme.palette.jobZone.colors.zone2,
  },
  zoneColor3: {
    backgroundColor: theme.palette.jobZone.colors.zone3,
  },
  zoneColor4: {
    backgroundColor: theme.palette.jobZone.colors.zone4,
  },
  zoneColor5: {
    backgroundColor: theme.palette.jobZone.colors.zone5,
  },
  careerZone: {
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
  },
  descriptionContainer: {
    padding: '12px 0px',
  },
  description: {
    color: theme.palette.greyScale[110],
    whiteSpace: 'break-spaces',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  bookmarkContainer: {
    padding: '24px 0px 12px 0px',
  },
  bookmarkButton: {
    backgroundColor: `${theme.palette.aqua.extraDeep} !important`,
    fill: 'white',
  },
  salaryInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
    padding: '24px 0px 16px 0px',
  },
  salarySection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  label: {
    color: theme.palette.navy.main,
  },
  salaryValue: {
    color: theme.palette.aqua.deep,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  tasksContainer: {
    padding: '16px 0px',
  },
  list: {
    listStyleType: 'disc',
    listStylePosition: 'inside',
  },
  listItem: {
    display: 'list-item',
    color: theme.palette.greyScale[110],
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  alsoCalledContainer: {
    padding: '16px 0px 24px 0px',
  },
});
