import React from "react";

const HappyFaceIcon = props => {
  const { size, fill, opacity } = props;

  return (
    <svg style={{opacity: opacity}} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 55 55">
      <defs>
        <clipPath id="clip-Happy_Face">
          <rect width="55" height="55"/>
        </clipPath>
      </defs>
      <g id="Happy_Face" data-name="Happy Face" clipPath="url(#clip-Happy_Face)">
        <path id="face-laugh-beam-solid" d="M27.5,55A27.5,27.5,0,1,0,0,27.5,27.5,27.5,0,0,0,27.5,55ZM10.914,33.73a2.277,2.277,0,0,1,2.331-2.793h28.51a2.277,2.277,0,0,1,2.331,2.793,17.189,17.189,0,0,1-33.172,0ZM23.375,22.859h0l-.021-.021a1.04,1.04,0,0,1-.075-.1c-.064-.086-.172-.215-.3-.365a12.987,12.987,0,0,0-1.1-1.106,5.032,5.032,0,0,0-2.976-1.5,5.032,5.032,0,0,0-2.976,1.5,12.987,12.987,0,0,0-1.1,1.106c-.129.15-.236.279-.3.365-.032.043-.064.075-.075.1l-.021.021h0a.851.851,0,0,1-.956.3.868.868,0,0,1-.591-.816A8.956,8.956,0,0,1,14.674,17.1a5.591,5.591,0,0,1,4.232-2.492A5.591,5.591,0,0,1,23.139,17.1a8.956,8.956,0,0,1,1.783,5.242.865.865,0,0,1-.591.816.841.841,0,0,1-.956-.3h0Zm17.188,0h0l-.021-.021a1.04,1.04,0,0,1-.075-.1c-.064-.086-.172-.215-.3-.365a12.987,12.987,0,0,0-1.1-1.106,5.032,5.032,0,0,0-2.976-1.5,5.032,5.032,0,0,0-2.976,1.5,12.987,12.987,0,0,0-1.1,1.106c-.129.15-.236.279-.3.365-.032.043-.064.075-.075.1l-.021.021h0a.851.851,0,0,1-.956.3.869.869,0,0,1-.591-.816A8.956,8.956,0,0,1,31.861,17.1a5.591,5.591,0,0,1,4.232-2.492A5.591,5.591,0,0,1,40.326,17.1a8.956,8.956,0,0,1,1.783,5.242.865.865,0,0,1-.591.816.841.841,0,0,1-.956-.3h0Z" fill={fill}/>
      </g>
    </svg>
  );
};

HappyFaceIcon.defaultProps = {
  size: 24,
  opacity: 1,
  fill: "inherit"
}

export default HappyFaceIcon;
