import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Icon, Typography, withStyles } from '@material-ui/core';
import styles from './career-compass-banner.styles';
import { FeatureControl } from '../../../../core';
import { Constant } from '../../../../../shared';
import ExploreIcon from '@material-ui/icons/Explore';
import { FormattedMessage, useIntl } from 'react-intl';
import useCareerCompassBannerHook from './useCareerCompassBannerHook';
import { Link } from 'react-router-dom';

/**
 * Component to display Career Compass Banner.
 * It is wrapped with FeatureControl to check if the feature is enabled or not.
 *
 * @component
 * @param {object} props - Component props
 * @returns {React.Component}
 */
const CareerCompassBanner = ({ classes }) => {
  const intl = useIntl();
  const { isDataLoaded, isReturningUser } = useCareerCompassBannerHook();

  return (
    <FeatureControl featureName={Constant.FEATURES_ENUM.CAREER_COMPASS}>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <img
            src={`${Constant.CDN_BASE_URL}/assets/img/career-compass-banner-bg.png`}
            alt=""
            className={classes.overlayImage}
            data-testid="career-compass-banner-overlay"
          />
          <Typography variant="h3" color="secondary" className={classes.title} data-testid="career-compass-banner-title">
            <ExploreIcon /> <FormattedMessage id="career-compass.banner.title" />
          </Typography>
          <Typography color="secondary" className={classes.description} data-testid="career-compass-banner-description">
            <FormattedMessage id="career-compass.banner.description" />
          </Typography>
          {isDataLoaded && (
            <Link to="/career-compass">
              <Button
                color="secondary"
                className={classes.actionButton}
                tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                tracking-id="open-career-compass-introduction-guide"
                aria-label={intl.formatMessage({
                  id: `career-compass.banner.action.${isReturningUser ? 'returning' : 'new'}-user`,
                })}
                role="link"
                endIcon={<Icon>shortcut</Icon>}
                classes={{ endIcon: classes.endIcon }}
                data-testid="career-compass-banner-action"
              >
                <FormattedMessage id={`career-compass.banner.action.${isReturningUser ? 'returning' : 'new'}-user`} />
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </FeatureControl>
  );
};

CareerCompassBanner.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CareerCompassBanner);
