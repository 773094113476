export default theme => ({
  title: {
    ...theme.typography.bold,
    ...theme.typography.t48,
    color: theme.palette.primary.main,
    lineHeight: "63px",
    letterSpacing: 0,
    marginBottom: 40
  },
  redeemedIcon: {
    color: theme.palette.primary.lipstick
  },
  redeemedContainer: {
    marginTop: 40
  },
  redeemText: {
    ...theme.typography.bold,
    ...theme.typography.t18,
    letterSpacing: "1px",
    color: theme.palette.primary.black,
    marginLeft: 15
  },
  timerWrapper: {
    marginBottom: 40
  },
  root: {
    margin: "40px 0px"
  },
  redeemBtn: {
    ...theme.typography.bold,
    ...theme.typography.t18,
    textTransform: "capitalize",
    width: 329,
    maxWidth: "100%",
    padding: "0px 30px",
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "space-between",
    height: 48,
    backgroundColor: theme.palette.primary.lipstick,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.lipstick
    }
  },

  timerText: {
    ...theme.typography.bold,
    ...theme.typography.t34,
    color: theme.palette.primary.scarlet,
    lineHeight: "45px",
    letterSpacing: "1.25px"
  },
  timerHeading: {
    ...theme.typography.medium,
    ...theme.typography.t10,
    lineHeight: "16px",
    letterSpacing: "1.5px",
    color: theme.palette.primary.jetBlack
  },
  redeemIcon: {
    marginLeft: 5
  }
});
