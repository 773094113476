export default theme => ({
  container: {
    width: "auto",
    margin: "0 -18px",
    height: "calc(100vh - 150px)"
  },
  chatContainer: {
    display: "flex",
    width: "100%",
    height: "calc(100vh - 150px)",
    borderRadius: 8,
  },
  newMsg: {
    minHeight: "calc(100vh - 150px)",
    height: "auto"
  }
});
