export default (theme) => ({
  root: {},
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15vh',
    height: '60vh',
  },
  image: {
    maxWidth: '80vw',
    maxHeight: '60vh',
    // minHeight: "850px"
  },
  contentContainer: {
    paddingTop: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  content: {
    maxWidth: 600,
    height: 400,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  mainTitle: {
    marginBottom: 30,
    fontWeight: 700,
  },
  description: {
    marginBottom: 30,
  },
  nextButton: {
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 700,
    width: 175,
  },
  prevButton: {
    textTransform: 'capitalize',
    fontWeight: 700,
    width: 175,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
    width: '100%',
    maxWidth: 600,
  },
  ellipsisContainer: {
    marginBottom: 35,
    display: 'flex',
  },
  filledEllipse: {
    height: 20,
    width: 20,
    background: theme.palette.primary.main,
    borderRadius: 10,
    margin: 5,
  },
  emptyEllipse: {
    height: 20,
    width: 20,
    background: '#E4EFF5',
    borderRadius: 10,
    margin: 5,
  },
});
