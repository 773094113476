import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  closeIconBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    padding: 0,
  },
  closeIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    svg: {
      backgroundColor: theme.palette.primary.background,
      border: '1px solid #DFE6E',
      borderRadius: '5px',
      marginBottom: '-5px',
      height: '60px',
      fontSize: '24px',
    },
  },
  crossIcon: { height: '22px', width: '22px', color: theme.palette.secondary.main },
  heading: {
    marginTop: '24px',
    top: '74px',
    left: '53px',
    width: '355px',
    height: '61px',
    testAlign: 'left',
    opacity: 1,
  },
  divider: {
    width: '152px',
    marginLeft: '0',
    border: '1px solid #DFE6E9',
    marginTop: '-15px',
  },
  formName: {
    fontSize: '18px',
    fontWeight: '500',
    marginLeft: '8px',
  },
  outlinedInput: {
    backgroundColor: theme.palette.primary.background,
    border: '1px solid #DFE6E',
    borderRadius: '5px',
    marginBottom: '-5px',
    height: '60px',
    fontSize: '24px',
  },
}));
