import { Box, Grid, withStyles } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import { NotificationDropdown } from "../../../../shared/components";
import styles from "./past-redemptions.style";

const pastRedemptions = props => {
  const { classes } = props;
  return (
    <Grid container justify="flex-end" item>
      <Box
        component="div"
        classes={{
          root: classes.root
        }}
      >
        <NotificationDropdown
          title={
            <FormattedMessage
              id="pastRedemptionTitle"
              values={{ count: props.pastRedemptions.length }}
            />
          }
          collection={props.pastRedemptions || []}
          maxHeight={"45vh"}
          className={[classes.notifDropdown, classes.notifDropDownBtn].join(
            " "
          )}
          cyId={"past-redemptions"}
        >
          <FormattedMessage id="pastRedemptions" />
        </NotificationDropdown>
      </Box>
    </Grid>
  );
};

export default withStyles(styles)(pastRedemptions);
