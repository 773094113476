import { useState } from 'react';

const useComposeMessageHeaderHook = (props) => {
  const { staffMessaging, onContactSelect } = props;
  const { contacts } = staffMessaging;
  const [selectedContact, setSelectedContact] = useState(null);

  const handleContactChange = (event, value) => {
    setSelectedContact(value);
    onContactSelect(value);
  };

  return {
    contacts,
    selectedContact,
    handleContactChange,
  };
};

export default useComposeMessageHeaderHook;
