import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlag, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { Auth0Provider } from '@auth0/auth0-react';
import KeycloakProvider from './keycloak/keycloak-provider';
import { fetchDeviceGroup, fetchUserIp, setUseOktaAuth0 } from '../utils';
import { Loader } from '@orijinworks/frontend-commons';

/**
 * Component to switch between Auth0 and Keycloak providers
 *
 * @component
 * @param {node} props.children - The children components
 */
const AuthProvider = ({ children }) => {
  const { flagsReady } = useFlagsStatus();
  const updateContext = useUnleashContext();
  const useOktaAuth0 = useFlag(UnleashService.FLAGS.USE_OKTA_AUTH0);
  const [isUnleashContextUpdated, setIsUnleashContextUpdated] = useState(false);

  /**
   * Effect to set the user IP address in the Unleash context
   */
  useEffect(() => {
    const setUserIpContext = async () => {
      try {
        const promises = [fetchUserIp(), fetchDeviceGroup()];
        const [ipAddress, { deviceGroup, deviceName }] = await Promise.all(promises);
        await updateContext({
          ipAddress,
          deviceGroupName: deviceGroup?.name,
          deviceGroupPath: deviceGroup?.path,
          deviceName,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setIsUnleashContextUpdated(true);
      }
    };

    setUserIpContext();
  }, []);

  /**
   * Effect to set the useOktaAuth0 flag
   */
  useEffect(() => {
    setUseOktaAuth0(useOktaAuth0);
  }, [useOktaAuth0]);

  /**
   * Return null if the flags are not ready
   */
  if (!flagsReady || !isUnleashContextUpdated) {
    return <Loader isLoading />;
  }

  /**
   * Return the Auth0Provider if the useOktaAuth0 flag is true.
   * Return the KeycloakProvider if the useOktaAuth0 flag is false.
   */
  if (useOktaAuth0) {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        useRefreshTokens
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return <KeycloakProvider>{children}</KeycloakProvider>;
  }
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
