import { useEffect, useMemo, useState } from 'react';
import { Constant, UserService, Utility } from '../../../../../shared/services';
import moment from 'moment';
import { getFeaturedContents } from '../../services/featured-contents.service';

/**
 * Custom hook to handle the business logic of the featured contents
 *
 * @param {object} props - hook props
 * @param {array} props.features - list of features
 * @param {string} props.username - username of the user
 * @param {object} props.history - history object from react-router-dom
 * @returns {boolean} isFeatureEnabled - flag to check if the feature is enabled
 * @returns {array} contents - list of featured contents
 * @returns {object} videoPlayerProps - video player props
 * @returns {function} handleLaunch - function to handle the launch of the content
 * @returns {function} handleVideoPlayerOpen - function to open the video player
 * @returns {function} handleVideoPlayerClose - function to close the video player
 */
const useFeaturedContentsHook = ({ features, username, history }) => {
  const userService = useMemo(() => new UserService(), []);
  const utilityService = useMemo(() => new Utility(), []);

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [contents, setContents] = useState([]);
  const [videoPlayerProps, setVideoPlayerProps] = useState({ isOpen: false, url: '', title: '', startTime: null });

  /**
   * Function to fetch the featured contents
   */
  const fetchFeaturedContents = async () => {
    try {
      const data = await getFeaturedContents();
      setContents(data);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Effect to check if the feature is enabled and fetch the featured contents
   */
  useEffect(() => {
    const isEnabled = utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.FEATURED_CONTENT);
    setIsFeatureEnabled(isEnabled);
    if (isEnabled) {
      fetchFeaturedContents();
    }
  }, [features, utilityService]);

  /**
   * Function to handle the launch of the content
   *
   * @param {string} url - launch url of the content
   */
  const handleLaunch = (url) => {
    if (utilityService.isRelativePath(url)) {
      const path = utilityService.extractRelativePath(url);
      history.push(path);
    } else {
      window.open(url, '_blank');
    }
  };

  /**
   * Function to open the video player
   *
   * @param {object} content - content object
   */
  const handleVideoPlayerOpen = (content) => {
    setVideoPlayerProps({ isOpen: true, url: content.video, title: content.title, startTime: moment() });
    userService.registerAnalyticEvent(content.title, userService.GAEvents.CLICKED, null, username);
  };

  /**
   * Function to close the video player
   */
  const handleVideoPlayerClose = () => {
    const { title, startTime } = videoPlayerProps;
    const userTimeSpentInSecs = moment.duration(moment().diff(startTime)).asSeconds();
    setVideoPlayerProps({ isOpen: false, url: '', title: '', startTime: null });
    userService.registerAnalyticEvent(title, userService.GAEvents.WATCHED, Math.floor(userTimeSpentInSecs), username);
  };

  return {
    isFeatureEnabled,
    contents,
    videoPlayerProps,
    handleLaunch,
    handleVideoPlayerOpen,
    handleVideoPlayerClose,
  };
};

export default useFeaturedContentsHook;
