import { Box, Card, Dialog, IconButton, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { store, showHideLoader } from '../../../core/store';
import CloseIcon from '@material-ui/icons/Close';

import styles from './forms.style';
import { Constant } from '../../services';
import { useIntl } from 'react-intl';

const FormsDialog = (props) => {
  const { classes } = props;
  const [isFullScreen, setFullScreen] = useState(false);
  const intl = useIntl();

  const closeModal = () => {
    props.onClose();
    setTimeout(() => {
      setFullScreen(false);
    }, 1000);
  };

  React.useEffect(() => {
    if (props.isDialogOpen) {
      // adding message event listner to recieve on form submit event
      const eventHandler = (event) => {
        // passing formId to iframe to get onsubmit event
        if (event.data === 'FORM_IS_READY') {
          const formIframe = document.getElementById('iframe');
          if (formIframe) {
            formIframe.contentWindow.postMessage(
              JSON.stringify({
                type: 'formdata',
                formId: props.formData.externalId,
              }),
              '*'
            );
          }
        }

        if (event.origin.startsWith('https://apds.tfaforms.net')) {
          if (event.data === 'submit-button-pressed') {
            store.dispatch(showHideLoader({ isLoading: true }));
            // this is to cater error scenario
            setTimeout(() => {
              store.dispatch(showHideLoader({ isLoading: false }));
            }, 1000);
          }
        }
      };
      window.addEventListener('message', eventHandler);

      return () => {
        window.removeEventListener('message', eventHandler);
      };
    }
  });

  React.useLayoutEffect(() => {
    if (props.isDialogOpen) {
      window.addEventListener('message', (event) => {
        if (event.origin.startsWith('https://www.tfaforms.com')) {
          store.dispatch(showHideLoader({ isLoading: true }));
          // this is to cater error scenario
          setTimeout(() => {
            store.dispatch(showHideLoader({ isLoading: false }));
            closeModal();
          }, 1000);
        }
      });
      const onMesssageHandler = (event) => {
        if (event.data === 'timeout') {
          window.removeEventListener('message', onMesssageHandler);
          props.checkRequiredForms();
          store.dispatch(showHideLoader({ isLoading: false }));
          closeModal();
        }
      };
      window.addEventListener('message', onMesssageHandler);

      return () => {
        window.removeEventListener('message', onMesssageHandler);
      };
    }
  });

  return (
    <Dialog
      fullScreen={isFullScreen}
      classes={{
        paper: [classes.dialogRoot, 'grey-scrollbar'].join(' '),
      }}
      onClose={() => closeModal()}
      fullWidth
      maxWidth={'80%'}
      open={props.isDialogOpen}
    >
      <Card
        data-cy-name="view-play-schedule-dialog"
        classes={{
          root: isFullScreen ? classes.rootFullSize : classes.root,
        }}
      >
        {/* BEGIN: CARD HEADER */}
        <Box
          id="formModalheader"
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardHeader}
        >
          <Box display="flex" component="div">
            {/* <IconButton
              onClick={() => closeModal()}
              className={classes.closeBtn}
              id="closeModal"
            >
              <ArrowBackIcon />
            </IconButton> */}

            <Typography className={classes.dialogTitle}>{props.formData && props.formData.formName}</Typography>
          </Box>
          <IconButton
            onClick={() => closeModal()}
            className={classes.closeBtn}
            tracking-type={Constant.TRACKING_TYPES.FORM}
            tracking-id={`close-form-${props.formData ? props.formData.formId : ''}`}
            aria-label={intl.formatMessage({ id: 'close' })}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* END: CARD HEADER */}
        {/* END: CARD HEADER */}
        {/* BEGIN: CARD CONTENT */}
        <Box component="div" className={[classes.cardContent].join(' ')}>
          <Box className={`${isFullScreen ? classes.iframeContainerFullScreen : classes.iframeContainer}`} component="div">
            <iframe
              title="form-frame"
              id="iframe"
              className={isFullScreen ? classes.iframeStylingFullScreen : classes.iframeStyling}
              src={
                props.formData &&
                `${props.formData.formUrl}?person_key=${props.userDetail.userName}&form_key=${props.formData.formId}&tfa_1=${props.userDetail.userName}&tfa_2=${props.formData.formId}`
              }
            ></iframe>
          </Box>

          {/* BEGIN: BOX */}

          {/* END: BOX */}
        </Box>
        {/* END: CARD CONTENT */}
      </Card>
    </Dialog>
  );
};

export default withStyles(styles)(FormsDialog);
