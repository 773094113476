import React from 'react';
import { withStyles } from '@material-ui/core';
import { Constant } from '../../services';
import styles from './brand-logo.style';

const BrandLogo = (props) => {
  const { classes } = props;

  return (
    <img
      id="app-brand-logo"
      alt="Orijin, Formerly A.P.D.S."
      src={`${Constant.CDN_BASE_URL}/assets/logo/Orijin-blue.png`}
      className={classes.logo}
    />
  );
};

export default withStyles(styles)(BrandLogo);
