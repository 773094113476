import { borderRadius, fontSize, fontWeight } from 'styled-system';

export default (theme) => ({
  // BEGIN: root styles
  root: {
    display: 'flex',
    gap: 16,
    borderRadius: 16,
    border: `1px solid ${theme.palette.greyScale[60]}`,
    background: theme.palette.primary.white,
    textAlign: 'left',
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  rootVertical: {
    flexDirection: 'column',
  },
  rootAutoOrientation: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  // END: root styles

  // BEGIN: thumbnail-type=icon styles
  iconContainerHorizontal: {
    padding: '24px 0px 24px 16px',
  },
  iconContainerVertical: {
    padding: '32px 16px 0px 16px',
    width: '100%',
  },
  iconContainerAutoOrientation: {
    padding: '24px 0px 24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '32px 16px 0px 16px',
      width: '100%',
    },
  },
  iconBox: {
    width: 56,
    height: 56,
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.greyScale[30],
    border: `1px solid ${theme.palette.primary.greyed}`,
  },
  icon: {
    color: theme.palette.aqua.extraDeep,
  },

  // END: thumbnail-type=icon styles

  // BEGIN: thumbnail-type=image/video styles
  thumbnailContainerHorizontal: {
    width: 295,
    height: '100%',
    borderRadius: '16px 0 0 16px',
    position: 'relative',
  },
  thumbnailContainerVertical: {
    width: '100%',
    height: 100,
    borderRadius: '16px 16px 0 0',
    position: 'relative',
  },
  thumbnailContainerAutoOrientation: {
    width: 115,
    height: '100%',
    borderRadius: '16px 0 0 16px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: 100,
      borderRadius: '16px 16px 0 0',
    },
  },
  thumbnailHorizontal: {
    width: 295,
    height: '100%',
    borderRadius: '16px 0 0 16px',
  },
  thumbnailVertical: {
    width: '100%',
    height: '100%',
    borderRadius: '16px 16px 0 0',
  },
  thumbnailAutoOrientation: {
    width: 115,
    height: '100%',
    borderRadius: '16px 0 0 16px',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      borderRadius: '16px 16px 0 0',
    },
  },
  playIcon: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
    fontSize: 56,
  },
  // END: thumbnail-type=image/video styles

  // BEGIN: content styles
  content: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  contentHorizontal: {
    padding: '24px 16px 24px 0px',
  },
  contentVertical: {
    padding: '16px 16px 32px 16px',
  },
  contentAutoOrientation: {
    padding: '24px 16px 24px 0px',
    [theme.breakpoints.up('md')]: {
      padding: '16px 16px 32px 16px',
    },
  },
  // END: content styles

  // BEGIN: action-area styles
  actionArea: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionAreaVertical: {
    display: 'none',
  },
  actionAreaAutoOrientation: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // END: action-area styles

  // BEGIN: remaining styles
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  title: {
    color: theme.palette.blue.primary,
  },
  subtitle: {
    color: theme.palette.cerulean.cerulean20,
  },
  badge: {
    // backgroundColor: theme.palette.primary.purpleDeep,
    color: theme.palette.primary.white,
    fontWeight: 700,
    fontSize: theme.typography.t16.fontSize,
    width: '100%',
    maxWidth: '120px',
    justifyContent: 'center',
    borderRadius: '8px',
    [theme.breakpoints.up('sm')]: {
      width: '100px',
    },
    [theme.breakpoints.up('md')]: {
      width: '120px',
    },
  },

  description: {
    color: theme.palette.greyScale[90],
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },

  // END: remaining styles
});
