import { Constant } from '../../../../../../../shared/services';
import { fetchJsonData } from '../../../../../../shared/services';
import { AUTH_CONSTANTS } from '../../../../../auth/constants/auth-constants';

/**
 * @name ANIMATION_FILE_NAMES
 * @description Animation file names for face position
 */
const ANIMATION_FILE_NAMES = [
  'face-front-copy.json',
  'face-left-copy.json',
  'face-right-copy.json',
  'face-up-copy.json',
  'face-down-copy.json',
];

/**
 * Function to load animation data
 *
 * @returns {Promise}
 */
export const loadAnimationData = () => {
  return new Promise((resolve, reject) => {
    const promises = ANIMATION_FILE_NAMES.map((fileName) =>
      fetchJsonData(`${Constant.CDN_BASE_URL}/assets/animations/${fileName}`)
    );

    Promise.all(promises)
      .then((animationData) => {
        resolve({
          [AUTH_CONSTANTS.FACE_POSITION.FRONT]: animationData[0],
          [AUTH_CONSTANTS.FACE_POSITION.LEFT]: animationData[1],
          [AUTH_CONSTANTS.FACE_POSITION.RIGHT]: animationData[2],
          [AUTH_CONSTANTS.FACE_POSITION.UP]: animationData[3],
          [AUTH_CONSTANTS.FACE_POSITION.DOWN]: animationData[4],
        });
      })
      .catch(reject);
  });
};
