import React from 'react';

const ContactRequestBlockedIcon = (props) => {
  const { size, fill } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 44 44" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2361_8861)">
        <path
          d="M22 44C27.8348 44 33.4305 41.6822 37.5564 37.5564C41.6822 33.4305 44 27.8348 44 22C44 16.1652 41.6822 10.5695 37.5564 6.44365C33.4305 2.31785 27.8348 0 22 0C16.1652 0 10.5695 2.31785 6.44365 6.44365C2.31785 10.5695 0 16.1652 0 22C0 27.8348 2.31785 33.4305 6.44365 37.5564C10.5695 41.6822 16.1652 44 22 44Z"
          fill="#B1BCCD"
        />
        <path
          d="M17.5211 18.57L8.50693 9.55581C7.64006 10.6479 7.1218 12.033 7.1218 13.5385C7.1218 17.0831 9.99387 19.9551 13.5385 19.9551C15.0439 19.9551 16.429 19.4369 17.5211 18.57ZM18.5669 17.5242C19.4369 16.429 19.9551 15.0439 19.9551 13.5385C19.9551 9.99387 17.0831 7.1218 13.5385 7.1218C12.033 7.1218 10.6479 7.64006 9.55581 8.50693L18.57 17.5211L18.5669 17.5242ZM5.64103 13.5385C5.64103 9.17683 9.17683 5.64103 13.5385 5.64103C17.9001 5.64103 21.4359 9.17683 21.4359 13.5385C21.4359 17.9001 17.9001 21.4359 13.5385 21.4359C9.17683 21.4359 5.64103 17.9001 5.64103 13.5385Z"
          fill="#B1BCCD"
        />
        <path
          d="M27.405 28.8285L15.1715 16.595C13.995 18.0771 13.2917 19.9569 13.2917 22C13.2917 26.8105 17.1895 30.7083 22 30.7083C24.0431 30.7083 25.9229 30.005 27.405 28.8285ZM28.8243 27.4092C30.005 25.9229 30.7083 24.0431 30.7083 22C30.7083 17.1895 26.8105 13.2917 22 13.2917C19.9569 13.2917 18.0771 13.995 16.595 15.1715L28.8285 27.405L28.8243 27.4092ZM11.282 22C11.282 16.0806 16.0806 11.282 22 11.282C27.9194 11.282 32.7179 16.0806 32.7179 22C32.7179 27.9194 27.9194 32.7179 22 32.7179C16.0806 32.7179 11.282 27.9194 11.282 22Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2361_8861">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
ContactRequestBlockedIcon.defaultProps = {
  size: 24,
  fill: '#FFFFFF',
};
export default ContactRequestBlockedIcon;
