import React from 'react';
import { withStyles, Box, Typography, Grid, Divider, Button, List, ListItem } from '@material-ui/core';

import styles from './career-details.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { BookmarkIcon, FilledBookmarkIcon } from '../../../../shared/icons';
import { Utility, Constant } from '../../../../shared/services';
import { LoaderHOC } from '../../../../shared/components';

const CareerDetails = (props) => {
  const _utilityService = new Utility();
  const {
    classes,
    title,
    description,
    jobZone,
    salary,
    qualification,
    tasks,
    alsoCalled,
    isBookmarked,
    addBookmark,
    removeBookmark,
    code,
  } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      {isBookmarked && (
        <Box className={classes.bookmarkIcon}>
          <FilledBookmarkIcon />
        </Box>
      )}

      <Box className={classes.bgOverlay} />
      <Grid container sm={12} md={12}>
        <Grid item sm={10} md={8} className={classes.detailsWrapper}>
          <Box className={classes.titleContainer}>
            {/* BEGIN: TITLE */}
            <Box>
              <Typography variant="h1" id="career-title" className={classes.careerTitle}>
                {title}
              </Typography>
            </Box>
            {/* END: TITLE */}

            {/* BEGIN: ZONE BADGE */}
            <Box className={[classes.zoneBadge, classes[`zoneColor${jobZone}`]].join(' ')}>
              <Typography id="career-zone" className={classes.careerZone}>
                <FormattedMessage id="zone" /> {jobZone}
              </Typography>
            </Box>
            {/* END: ZONE BADGE */}
          </Box>
        </Grid>

        {/* BEGIN: DESCRIPTION */}
        <Box className={classes.descriptionContainer}>
          <Typography id="career-desc" className={classes.careerDescription} variant="body2" component="p">
            {description}
          </Typography>
        </Box>
        {/* END: DESCRIPTION */}

        <Divider className={classes.divider} />

        <Grid container className={classes.detailsContainer}>
          <Grid item sm={12} md={8} className={classes.tasksContainer}>
            <Box display="flex" flexDirection="row">
              {/* BEGIN: SALARY INFO */}
              {!!salary && salary > 0 && (
                <Box className={classes.salaryInfo}>
                  <Typography gutterBottom variant="h2" color="secondary" className={classes.capitalize}>
                    <FormattedMessage id="salary" />
                  </Typography>
                  <Typography
                    id="salary-info"
                    gutterBottom
                    variant="h3"
                    color="secondary"
                    className={[classes.activeText, classes.requirement, classes.capitalize].join(' ')}
                  >
                    {`$${Utility.numberWithCommas(salary)}`}
                  </Typography>
                </Box>
              )}
              {/* END: SALARY INFO */}

              {/* BEGIN: EDUCATION INFO */}
              {qualification && (
                <Box className={classes.educationInfo}>
                  <Typography gutterBottom variant="h2" color="secondary" className={classes.capitalize}>
                    <FormattedMessage id="requiredDegree" />
                  </Typography>
                  <Typography
                    id="education-info"
                    gutterBottom
                    color="secondary"
                    className={[classes.requirement, classes.capitalize].join(' ')}
                  >
                    {qualification}
                  </Typography>
                </Box>
              )}
              {/* END: EDUCATION INFO */}
            </Box>

            {/* BEGIN: RESPONSIBILITIES */}
            {tasks && tasks.length > 0 && (
              <Box id="career-tasks-list" className={classes.tasksWrapper}>
                <Typography gutterBottom variant="h3" color="secondary" className={classes.capitalize}>
                  <FormattedMessage id="topJobResponsibilities" />
                </Typography>
                <ul>
                  {tasks.map((task, index) => (
                    <li key={_utilityService.generateUniqueKey()} className={classes.careerDescription}>
                      <Typography>{task}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
            {/* END: RESPONSIBILITIES */}
          </Grid>

          <Grid item sm={12} md={4} className={classes.columnContainer}>
            {/* BEGIN: ALSO CALLED */}
            {alsoCalled && alsoCalled.length > 0 && (
              <Grid item>
                <Box id="also-called-info">
                  <Typography gutterBottom variant="h4" color="secondary" className={classes.capitalize}>
                    <FormattedMessage id="alsoCalled" />
                  </Typography>

                  <ul>
                    {alsoCalled.map((name, index) => (
                      <li key={_utilityService.generateUniqueKey()} color="secondary" className={classes.alsoCalledTitle}>
                        {name}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
            )}
            {/* END: ALSO CALLED */}

            <Grid item>
              <Button
                id="bookmark-career-btn"
                color="secondary"
                className={[isBookmarked ? classes.removeBookmarkBtn : classes.addBookmarkBtn, classes.bookmarkBtn].join(
                  ' '
                )}
                startIcon={
                  <LoaderHOC apiKey={code}>
                    <BookmarkIcon />
                  </LoaderHOC>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  isBookmarked ? removeBookmark() : addBookmark();
                }}
                tracking-type={Constant.TRACKING_TYPES.CAREER_BOOKMARK}
                tracking-id={isBookmarked ? `remove-bookmark-${code}` : `bookmark-career-${code}`}
                aria-label={intl.formatMessage({ id: isBookmarked ? 'career.bookmark.remove' : 'career.bookmark.add' })}
              >
                <FormattedMessage id={isBookmarked ? 'career.bookmark.remove' : 'career.bookmark.add'} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(CareerDetails);
