import { Button, Box, Typography, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './pdq-assessment-in-progress.style';
import { Constant } from '../../../../shared/services';

const PDQAssessmentInProgress = (props) => {
  const { classes } = props;
  return (
    <Box className={classes.root}>
      <Box
        className={[classes.inProgressContent, props.status === Constant.PDQ_ENUM.IN_PROGRESS ? classes.maxWidth : ''].join(
          ' '
        )}
      >
        <Box className={classes.inProgressContentBody}>
          <Box className={classes.iconBox}>
            <img
              className={classes.iconImg}
              alt=""
              src="https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Pile-Of-Books.png"
            />
          </Box>
          <Box className={classes.inProgressTextArea}>
            <Typography id="pdq-inprogress-title" variant="h3" color="secondary" className={classes.title}>
              {props.title}
            </Typography>
            <Typography id="pdq-inprogress-description" className={classes.description} component="p">
              {props.description}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {props.status === Constant.PDQ_ENUM.IN_PROGRESS && (
          <Button
            id="open-pdq-assement"
            onClick={props.launchAssessment}
            color="primary"
            className={classes.actionButton}
            variant="contained"
            tracking-type={Constant.TRACKING_TYPES.PDQ}
            tracking-id="open-pdq-assement"
            aria-label={props.action1Text}
          >
            {props.action1Text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(PDQAssessmentInProgress);
