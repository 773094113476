import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  StaffMessagingConstants,
  fetchMessages,
  hideMessage,
  markMessagesAsRead,
  unhideMessage,
} from '../../services/staff-messaging.service';
import { formatDate, generateUniqueNumericId } from '../../../../shared/services/utility/datetime.utility';
import {
  setFetchNewMessages,
  setForceReloadMessageThreads,
  setMarkSelectedThreadAsRead,
  setUnreadMessagesCountLimitReached,
  store,
} from '../../../../core';
import { Constant } from '../../../../shared/services';
import { FormattedMessage } from 'react-intl';
import { MessagingService } from '../../../messaging';

const useConversationHistoryHook = (props) => {
  const { staffMessaging } = props;
  const { selectedThread, fetchNewMessages, forceReloadMessageThreads } = staffMessaging;
  const [originalMessages, setOriginalMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [detailsVisibleMessages, setDetailsVisibleMessages] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [shouldLoadMoreMessages, setShouldLoadMoreMessages] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const lastElementRef = useRef(null);
  const messagesContainerRef = useRef(null);
  let prevScrollHeight = useRef(0);
  const originalMessagesRef = useRef(originalMessages);

  const threadId = selectedThread?.threadId;
  const contactId = selectedThread?.contactId;
  const unreadMessageLimit =
    selectedThread?.unreadMessageCountLimit || StaffMessagingConstants.DEFAULT_UNREAD_MESSAGE_COUNT_LIMIT;

  const updateMessages = useCallback((original, formatted, scrollToLastMessage = false) => {
    setOriginalMessages(original);
    setMessages(formatted);
    if (scrollToLastMessage) {
      scrollToLastElement();
    }
  }, []);

  const markMessagesAsReadIfRequired = useCallback(
    async (newMessages) => {
      try {
        if (newMessages.length > 0) {
          const lastMessage = newMessages[newMessages.length - 1];
          await markMessagesAsRead(threadId, lastMessage.messageId);
          store.dispatch(setMarkSelectedThreadAsRead(true));
        }
      } catch (e) {
        console.log(e);
      }
    },
    [threadId]
  );

  const isMessageFromContact = useCallback(
    (message) => {
      return message.senderId === contactId;
    },
    [contactId]
  );

  const checkIfUnreadMessagesCountLimitReached = useCallback(
    (newMessages) => {
      const lastNMessages = newMessages.slice(-unreadMessageLimit);
      if (lastNMessages.length === unreadMessageLimit) {
        const hasMessageFromContact = lastNMessages.some(isMessageFromContact);
        if (hasMessageFromContact) {
          store.dispatch(setUnreadMessagesCountLimitReached(false));
        } else {
          store.dispatch(setUnreadMessagesCountLimitReached(true));
        }
      }
    },
    [isMessageFromContact]
  );

  const retrieveMessages = useCallback(async () => {
    try {
      const newMessages = await fetchMessages(threadId);

      // A workaround to fetch more messsages if the count is less than the limit.
      // Need to do this because of the API's weird behavior.
      // TODO: Remove this once the API is fixed.
      if (newMessages.length > 0 && newMessages.length < StaffMessagingConstants.MESSAGE_FETCH_LIMIT) {
        const moreMessages = await fetchMessages(threadId, newMessages[0].messageId);
        newMessages.unshift(...moreMessages);
      }

      checkIfUnreadMessagesCountLimitReached(newMessages);
      updateMessages(newMessages, formatMessages(newMessages), true);
      markMessagesAsReadIfRequired(newMessages);
      setInitialDataLoaded(true);

      // If the count is less than the limit, then we don't need to load more messages.
      if (newMessages.length < StaffMessagingConstants.MESSAGE_FETCH_LIMIT) {
        setShouldLoadMoreMessages(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoader(false);
    }
  }, [threadId, updateMessages, markMessagesAsReadIfRequired, checkIfUnreadMessagesCountLimitReached]);

  useEffect(() => {
    if (selectedThread) {
      resetState();
      retrieveMessages();
      store.dispatch(setUnreadMessagesCountLimitReached(false));
    }
  }, [selectedThread]);

  useEffect(() => {
    originalMessagesRef.current = originalMessages;
  }, [originalMessages]);

  const formatWithNewMessageSeparator = useCallback(
    (newMessages, addNewMessageSeparator) => {
      const latestOriginalMessages = originalMessagesRef.current;
      let formattedMessages = formatMessages([...latestOriginalMessages, ...newMessages]);
      if (addNewMessageSeparator) {
        const isSepartorAlreadyPresent = messages.some((message) => message.messageId === 'newMessageSeparator');
        if (isSepartorAlreadyPresent) {
          formattedMessages = [...messages, ...newMessages];
        } else {
          let newMessageSeparator = {
            messageId: 'newMessageSeparator',
            body: <FormattedMessage id="newMessage" />,
            type: StaffMessagingConstants.MESSAGE_TYPES.DATE_SEPARATOR,
          };
          formattedMessages = [...latestOriginalMessages, newMessageSeparator, ...newMessages];
        }
      }
      return formattedMessages;
    },
    [originalMessages, messages]
  );

  const retrieveNewMessages = useCallback(
    async (addNewMessageSeparator = false, detail) => {
      try {
        const latestOriginalMessages = originalMessagesRef.current;
        if (latestOriginalMessages.length > 0) {
          const lastMessage = latestOriginalMessages[latestOriginalMessages.length - 1];
          const afterId = lastMessage.messageId;
          if (!detail || detail.threadId === selectedThread.threadId) {
            await setNewMessages(addNewMessageSeparator, afterId, latestOriginalMessages, detail);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      originalMessages,
      threadId,
      updateMessages,
      formatWithNewMessageSeparator,
      isMessageFromContact,
      checkIfUnreadMessagesCountLimitReached,
    ]
  );

  const fetchMessageOrGetAsync = (detail, threadId, afterId) => {
    if (detail) {
      const { body, threadId } = detail;
      return Promise.resolve([
        {
          ...body,
          createdDate: new Date().toISOString(),
          recipientPersonId: body.recipientPersonId,
          senderId: body.senderPersonId,
          body: body.clearBody,
          messageId: body.messageId,
          threadId: threadId,
          recordStatus: true,
        },
      ]);
    }

    return fetchMessages(threadId, null, afterId);
  };

  const setNewMessages = async (addNewMessageSeparator, afterId, latestOriginalMessages, detail) => {
    const newMessages = await fetchMessageOrGetAsync(detail, threadId, afterId);
    if (newMessages.length > 0) {
      const allMessages = [...latestOriginalMessages, ...newMessages];
      checkIfUnreadMessagesCountLimitReached(allMessages);
      let formattedMessages = formatWithNewMessageSeparator(newMessages, addNewMessageSeparator);
      const lastMessageFromContact = isMessageFromContact(newMessages[newMessages.length - 1]);
      updateMessages(allMessages, formattedMessages, !lastMessageFromContact);
    }
  };

  // Retrieve new messages when the fetchNewMessages flag is set to true.
  // This flag is set to true when the user sends a new message.
  useEffect(() => {
    if (fetchNewMessages) {
      retrieveNewMessages();
      store.dispatch(setFetchNewMessages(false));
    }
  }, [fetchNewMessages, retrieveNewMessages]);

  useEffect(() => {
    if (Constant.POLL_CONFIG.USE_POLLING) {
      const pollingInterval = setInterval(() => {
        retrieveNewMessages(true);
      }, Constant.MESSAGE_POLL_INTERVAL);

      return () => {
        clearInterval(pollingInterval);
      };
    } else {
      onNewMessageEvent();
      return () => {
        document
          .querySelector(`#${MessagingService.MESSAGE_ELEMENT}`)
          .removeEventListener(MessagingService.NEW_MSG_EVENT, newMessageHandler, false);
      };
    }
  }, [selectedThread]);

  const formatMessages = (msgs) => {
    const messagesMap = msgs.reduce((acc, message) => {
      let dateKey = formatDate(message.datetime, 'MMMM DD, YYYY');
      acc[dateKey] = acc[dateKey] ? [...acc[dateKey], message] : [message];
      return acc;
    }, {});
    return Object.keys(messagesMap).reduce((acc, dateKey) => {
      let dateSeparator = {
        messageId: generateUniqueNumericId(dateKey),
        body: dateKey,
        type: StaffMessagingConstants.MESSAGE_TYPES.DATE_SEPARATOR,
      };
      let messages = messagesMap[dateKey].map((message) => ({
        ...message,
        type: StaffMessagingConstants.MESSAGE_TYPES.MESSAGE,
      }));
      return [...acc, dateSeparator, ...messages];
    }, []);
  };

  const resetState = () => {
    setOriginalMessages([]);
    setMessages([]);
    setInitialDataLoaded(false);
    setShouldLoadMoreMessages(true);
    setShowLoader(true);
    setDetailsVisibleMessages([]);
    prevScrollHeight = { current: 0 };
  };

  const handleScroll = () => {
    const container = messagesContainerRef.current;
    const currentScrollHeight = container.scrollHeight;
    if (container.scrollTop === 0 && shouldLoadMoreMessages && initialDataLoaded) {
      setShowLoader(true);
      loadMoreMessages(originalMessages, () => {
        container.scrollTop = container.scrollTop + (currentScrollHeight - prevScrollHeight.current);
        prevScrollHeight.current = currentScrollHeight;
      });
    } else if (currentScrollHeight <= container.scrollTop + container.clientHeight) {
      const isNewMessageSepartorPresent = messages.some((message) => message.messageId === 'newMessageSeparator');
      if (isNewMessageSepartorPresent) {
        // mark messages as read when the user scrolls to the bottom of the container.
        markMessagesAsReadIfRequired(originalMessages);
      }
    }
  };

  const onNewMessageEvent = () => {
    const messageElement = document.querySelector(`#${MessagingService.MESSAGE_ELEMENT}`);
    if (messageElement) {
      messageElement.addEventListener(MessagingService.NEW_MSG_EVENT, newMessageHandler, false);
    }
  };

  const newMessageHandler = ({ detail }) => {
    retrieveNewMessages(true, detail);
  };

  const loadMoreMessages = async (allMessages, cb) => {
    try {
      if (allMessages.length > 0) {
        const firstMessage = allMessages[0];
        const beforeId = firstMessage.messageId;
        const newMessages = await fetchMessages(threadId, beforeId);
        if (newMessages?.length > 0) {
          updateMessages([...newMessages, ...allMessages], [...formatMessages(newMessages), ...messages]);
        } else {
          setShouldLoadMoreMessages(false);
        }
        if (cb) cb();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoader(false);
    }
  };

  const scrollToLastElement = () => {
    if (lastElementRef.current && lastElementRef.current.scrollIntoView) {
      lastElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  const toggleMessageDetails = (messageId) => {
    const index = detailsVisibleMessages.indexOf(messageId);
    if (index > -1) {
      detailsVisibleMessages.splice(index, 1);
    } else {
      detailsVisibleMessages.push(messageId);
    }
    setDetailsVisibleMessages([...detailsVisibleMessages]);
  };

  const showMessageDetails = (messageId) => {
    return detailsVisibleMessages.includes(messageId);
  };

  const isMessageTypeDateSeparator = (message) => {
    return message.type === StaffMessagingConstants.MESSAGE_TYPES.DATE_SEPARATOR;
  };

  const toggleMessageVisibility = async (messageId) => {
    try {
      const index = originalMessages.findIndex((message) => message.messageId === messageId);
      if (index > -1) {
        const isHidden = originalMessages[index].isHidden;
        isHidden ? await unhideMessage(messageId) : await hideMessage(messageId);
        originalMessages[index].isHidden = !isHidden;
        updateMessages([...originalMessages], [...formatMessages(originalMessages)]);
        toggleMessageDetails(messageId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    messages,
    lastElementRef,
    messagesContainerRef,
    showLoader,
    showMessageDetails,
    isMessageFromContact,
    toggleMessageDetails,
    isMessageTypeDateSeparator,
    handleScroll,
    toggleMessageVisibility,
  };
};

export default useConversationHistoryHook;
