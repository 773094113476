export default theme => ({
    root: {
        borderRadius: "10px",
        backgroundColor: "white",
        width: "100%",
        padding: 30,
        boxShadow: "0px 4px 8px 2px rgba(142, 142, 142, 0.1)",
        marginTop: 30
    },
    capitalize: {
        textTransform: "capitalize"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    bolder: {
        fontWeight: 500
    },
    titleContainer: {
        marginBottom: 10
    },
    mainTitle: {
        ...theme.typography.t24,
        fontWeight: 700
    },
    descriptionContainer: {},
    mainDescription: {
        color: theme.palette.text.sub,
        fontSize: "1rem"
    },
    zoneFiltersContainer: {
        margin: "20px 0px",
        alignItems: "center"
    },
    zoneFilterBtn: {
        textTransform: "capitalize",
        fontWeight: 700,
        [theme.breakpoints.up("md")]: {
            paddingLeft: 30,
            paddingRight: 30
        },
        whiteSpace: "nowrap"
    },
    zoneDetailsContainer: {
        marginBottom: 30
    },
    zoneDetailsWrapper: {
        backgroundColor: "#F3F6F9",
        padding: 15,
        borderRadius: 5,
        height: "100%"
    },
    searchBar: {
        backgroundColor: "#F3F6F9",
        borderColor: "#F3F6F9"
    },
    searchBarContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    searchBarWrapper: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    searchBtn: {
        border: "1px solid rgba(0,0,0,0.26)",
        textTransform: "capitalize",
        marginLeft: 15,
        paddingLeft: 35,
        paddingRight: 35
    },
    outlinedBtnZone1: {
        borderColor: theme.palette.jobZone.colors.zone1,
        borderWidth: 2,
        color: theme.palette.jobZone.colors.zone1
    },
    outlinedBtnZone2: {
        borderColor: theme.palette.jobZone.colors.zone2,
        borderWidth: 2,
        color: theme.palette.jobZone.colors.zone2
    },
    outlinedBtnZone3: {
        borderColor: theme.palette.jobZone.colors.zone3,
        borderWidth: 2,
        color: theme.palette.jobZone.colors.zone3
    },
    outlinedBtnZone4: {
        borderColor: theme.palette.jobZone.colors.zone4,
        borderWidth: 2,
        color: theme.palette.jobZone.colors.zone4
    },
    outlinedBtnZone5: {
        borderColor: theme.palette.jobZone.colors.zone5,
        borderWidth: 2,
        color: theme.palette.jobZone.colors.zone5
    },
    filledBtnZone1: {
        borderColor: theme.palette.jobZone.colors.zone1,
        borderWidth: 2,
        backgroundColor: theme.palette.jobZone.colors.zone1,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.jobZone.colors.zone1
        }
    },
    filledBtnZone2: {
        borderColor: theme.palette.jobZone.colors.zone2,
        borderWidth: 2,
        backgroundColor: theme.palette.jobZone.colors.zone2,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.jobZone.colors.zone2
        }
    },
    filledBtnZone3: {
        borderColor: theme.palette.jobZone.colors.zone3,
        borderWidth: 2,
        backgroundColor: theme.palette.jobZone.colors.zone3,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.jobZone.colors.zone3
        }
    },
    filledBtnZone4: {
        borderColor: theme.palette.jobZone.colors.zone4,
        borderWidth: 2,
        backgroundColor: theme.palette.jobZone.colors.zone4,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.jobZone.colors.zone4
        }
    },
    filledBtnZone5: {
        borderColor: theme.palette.jobZone.colors.zone5,
        borderWidth: 2,
        backgroundColor: theme.palette.jobZone.colors.zone5,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.jobZone.colors.zone5
        }
    }
});
  