import React from "react";
import { connect } from "react-redux";

import { Closed } from "../../../shared/components";
import { Utility } from "../../../shared/services";
import { FormattedMessage } from "react-intl";

const permissions = props => {
  const utilityService = new Utility();
  const isValidForFacility = utilityService.checkStatusExists(
    props.features,
    utilityService.getTitleFromUrl("permission")
  );

  const isValidForLearner = utilityService.checkStatusExists(
    props.features,
    utilityService.getTitleFromUrl("learner_permission")
  );

  const isValid = isValidForFacility && isValidForLearner;

  if (props.featureLoading) {
    return null;
  }

  if (!navigator.onLine) {
    return (
      <Closed
        align="center"
        mainText={<FormattedMessage id="networkIssue" />}
        subText={<FormattedMessage id="networkIssueMsg" />}
      />
    );
  }

  return (
    <>
      {isValid || props.testSuite ? (
        props.children
      ) : (
        <Closed
          align="center"
          mainText={
            <FormattedMessage
              id="closedState"
              values={{
                moduleName: utilityService.getTitleFromUrl("heading")
              }}
            />
          }
          subText={
            <FormattedMessage
              id="closedStateSubText"
              values={{
                moduleName: utilityService.getTitleFromUrl("heading")
              }}
            />
          }
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }) => app;

export default connect(mapStateToProps, null)(permissions);
