import { useEffect, useState } from 'react';
import {
  loadTermsOfServiceScripts,
  cleanUpScripts,
  addPrivacyPolicyEventListeners,
  removePrivacyPolicyEventListeners,
  TERMS_OF_SERVICE_VERSION,
} from './terms-of-service.helper';
import { setLocale } from '../../../../../core';
import { persistTermsAndService } from '../../services/auth-guards.service';
import { AUTH_CONSTANTS } from '../../../auth/constants/auth-constants';

/**
 * Custom hook which contains the logic for the Terms of Service component.
 *
 * @param {Function} props.dispatch - The dispatch function from the redux store
 * @param {string} props.selectedLanguage - The selected language from the redux store
 * @param {Function} props.onComplete - The function to call when the user accepts the terms of service
 * @param {Function} props.onError - The function to call when the user closes the terms of service dialog
 * @returns {Object} The object containing the hook's public methods and properties
 */
const useTermsOfServiceHook = ({ dispatch, selectedLanguage, onComplete, onError }) => {
  const [open, setOpen] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [accepted, setAccepted] = useState(false);

  /**
   * Effect to load the terms of service scripts dynamically and set the open state to true.
   */
  useEffect(() => {
    loadTermsOfServiceScripts().then(() => {
      setOpen(true);
    });

    return () => {
      cleanUpScripts();
    };
  }, []);

  /**
   * Effect to add event listeners to the privacy policy links.
   */
  useEffect(() => {
    if (!open) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          addPrivacyPolicyEventListeners(handlePrivacyPolicyModalOpen);
        }
      });
    });

    setTimeout(() => {
      addPrivacyPolicyEventListeners(handlePrivacyPolicyModalOpen);
      const target = document.querySelector('#terms-of-service-content');
      observer.observe(target, { attributes: true, childList: true, subtree: true });
    }, 0);

    return () => {
      observer.disconnect();
      removePrivacyPolicyEventListeners(handlePrivacyPolicyModalOpen);
    };
  }, [open]);

  /**
   * Function to open the privacy policy modal.
   */
  const handlePrivacyPolicyModalOpen = () => {
    setOpenPrivacyPolicy(true);
  };

  /**
   * Function to close the privacy policy modal.
   */
  const handlePrivacyPolicyModalClose = () => {
    setOpenPrivacyPolicy(false);
  };

  /**
   * Function to handle the language change.
   *
   * @param {String} language - The language to change to
   */
  const handleLanguageChange = (language) => {
    if (isLanguageSelected(language)) {
      return;
    }
    dispatch(setLocale({ locale: language }));
  };

  /**
   * Function to check if the language is selected.
   *
   * @param {String} language - The language to check
   * @returns
   */
  const isLanguageSelected = (language) => {
    return selectedLanguage === language;
  };

  /**
   * Function to handle the change in the checkbox.
   *
   * @param {Object} event - The event object
   */
  const handleChange = (event) => {
    setAccepted(event.target.checked);
  };

  /**
   * Function to handle the close of the dialog.
   *
   * @param {Object} event - The event object
   * @param {String} reason - The reason for closing the dialog
   */
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }

    onError('User closed the Terms of Service dialog');
  };

  /**
   * Function to handle the completion of the terms of service.
   */
  const handleComplete = async () => {
    try {
      await persistTermsAndService({
        preferredLanguage: selectedLanguage,
        version: TERMS_OF_SERVICE_VERSION,
      });
      onComplete();
    } catch (error) {
      onError(error?.message || 'Error completing the Terms of Service');
    }
  };

  return {
    open,
    openPrivacyPolicy,
    accepted,
    handlePrivacyPolicyModalClose,
    handleLanguageChange,
    isLanguageSelected,
    handleChange,
    handleClose,
    handleComplete,
  };
};

export default useTermsOfServiceHook;
