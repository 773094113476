import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import styles from './conversation-view.style';
import { AnnouncementDetails, ComposeMessageHeader, ConversationHeader, ConversationHistory } from '..';
import { MessageEditor } from '../../../../shared';
import useConversationViewHook from './useConversationViewHook';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LimitReachedWarning from '../limit-reached-warning/limit-reached-warning';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import withUnleash from '../../../../core/components/unleash/withUnleash';

const ConversationView = (props) => {
  const { classes, isComposing, showConversation, showConversationView, isActiveModeMessages, isActiveModeAnnouncements } =
    props;
  const {
    characterLimit,
    messageSendLimitReached,
    messageSendLimit,
    handleContactSelect,
    shouldDisableSendButton,
    onSend,
    showConversationHistory,
  } = useConversationViewHook(props);
  const { isFlagEnabled } = props.unleashProps;

  return (
    <Box className={classes.root}>
      {showConversationView() && (
        <>
          {isComposing ? (
            <ComposeMessageHeader onContactSelect={handleContactSelect} />
          ) : (
            showConversation && (
              <ConversationHeader
                showIcon={isActiveModeMessages()}
                showSubtitle={isActiveModeAnnouncements()}
                isActiveModeAnnouncements={isActiveModeAnnouncements}
              />
            )
          )}
          {isActiveModeAnnouncements() ? (
            <AnnouncementDetails />
          ) : showConversationHistory() ? (
            <ConversationHistory />
          ) : (
            <Box className={classes.body} />
          )}
          {isActiveModeMessages() && (
            <Box className={classes.footer}>
              {messageSendLimitReached && (
                <LimitReachedWarning
                  message={<FormattedMessage id="maxMessageLimitMessageAlert" values={{ count: messageSendLimit }} />}
                />
              )}
              <MessageEditor
                characterLimit={characterLimit}
                onSend={onSend}
                disableSendButton={shouldDisableSendButton()}
                placeholder="staff-messaging.send-editor.placeholder"
                disableEditor={messageSendLimitReached}
                showBlurTextOption={isFlagEnabled()}
              />
            </Box>
          )}
        </>
      )}
      {!showConversationView() && (
        <Box id="select-thread-message" className={classes.centerContainer}>
          <FormattedMessage id={`staff-messaging.${isActiveModeAnnouncements() ? 'announcements.' : ''}select-thread`} />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  staffMessaging: state.staffMessaging,
});

export default connect(mapStateToProps)(
  withUnleash(withStyles(styles)(ConversationView), UnleashService.FLAGS.TOGGLE_MESSAGE_VISIBILITY)
);
