export default theme => ({
  root: {
    width: 382,
    maxWidth: "100%",
    borderRadius: 4,
    background: "none"
  },
  dialogRoot: {
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: "none"
  },
  dialogMainTitle: {
    ...theme.typography.t24,
    lineHeight: "24px",
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: 10
  },
  dialogIcon: {
    color: "white",
    marginRight: 15
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "0px 20px",
    height: 56
  },
  dialogTitle: {
    ...theme.typography.medium,
    ...theme.typography.t20,
    color: theme.palette.primary.white,
    lineHeight: "26px",
    letterSpacing: "0.15px"
  },
  closeBtn: {
    color: theme.palette.primary.white,
    opacity: 0.75
  },
  cardContent: {
    backgroundColor: theme.palette.primary.white,
    padding: "25px 15px",
    maxHeight: "90vh",
    overflow: "auto"
  },
  scheduleActive: {
    ...theme.typography.bold,
    position: "relative",
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      position: "absolute",
      left: -13,
      top: 0,
      zIndex: 1,
      bottom: -1,
      width: "100%",
      borderRadius: 30,
      backgroundColor: theme.palette.primary.grey
    }
  },
  roundBoxImg: {
    width: 40,
    height: 40,
    backgroundColor: "#80808047",
    borderRadius: "100%",
    marginRight: 15
  },
  serviceName: {
    ...theme.typography.medium,
    ...theme.typography.t20,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  serviceRule: {
    ...theme.typography.t14,
    letterSpacing: "0.25px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  serviceWrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    marginBottom: 10,
    padding: "0px 20px",
    "&:last-child": {
      borderBottom: "none"
    }
  },
  black: {
    color: theme.palette.primary.jetBlack
  },
  rulesList: {
    paddingLeft: 25,
    marginTop: 15,
    listStyle: "none",
    "& > li": {
      marginTop: 10,
      position: "relative"
    },
    "& > li::before": {
      content: '"\u2022"',
      color: theme.palette.primary.main,
      fontWeight: "bold",
      position: "absolute",
      width: "1em",
      marginLeft: "-1em"
    }
  }
});
