import { useState, useEffect, useRef } from 'react';
import {
  submitContactRequest,
  getAllContactRequests,
  ContactRequestConstants,
  bulkUpdateContactStatus,
} from '../../services';
import { Utility } from '../../../../shared/services/utility/utility';
const useContactsRequestsHook = (props) => {
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [contact, setContact] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    relationshipType: '',
    preferredLanguage: '',
    errors: {},
    isSubmitted: false,
  });
  const [contactRequests, setContactRequests] = useState([]);
  const [contactName, setContactName] = useState('');
  const [contactId, setContactId] = useState([]);
  const [reqStatus, setReqStatus] = useState('');
  const [contactRequestLimitReached, setContactRequestLimitReached] = useState(false);
  const archiveUnArchiveContactModalRef = useRef(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarType, setSnackbarType] = useState('success');
  useEffect(() => {
    getContactRequests();
  }, []);

  const archiveContacts = async () => {
    try {
      setIsLoading(true);
      await bulkUpdateContactStatus(contactId, ContactRequestConstants.CONTACT_REQUEST_STATUSES.INACTIVE.toUpperCase());
      await getContactRequests();
      closeModal();
      setSnackbarMessage('Contact has been archived successfully');
      setIsSnackbarOpen(true);
      setSnackbarType('success');
    } catch (error) {
      console.error('Error in archiving contacts', error);
      setSnackbarMessage('Error in archiving contacts');
      setIsSnackbarOpen(true);
      setSnackbarType('error');
    } finally {
      setIsLoading(false);
    }
  };

  const unArchiveContacts = async (contactId) => {
    try {
      setIsLoading(true);
      await bulkUpdateContactStatus([contactId], ContactRequestConstants.CONTACT_REQUEST_STATUSES.ACTIVE.toUpperCase());
      await getContactRequests();
      setIsSnackbarOpen(true);
      setSnackbarMessage('Action successfull');
      setSnackbarType('success');
    } catch (error) {
      setSnackbarType('error');
      setSnackbarMessage('Contact Limit exceeded.');
      setIsSnackbarOpen(true);
    }
  };

  const openModal = (name, id) => {
    setContactName(name);
    setContactId([id]);
    archiveUnArchiveContactModalRef.current.toggleModal();
  };

  const closeModal = () => {
    setContactName('');
    setContactId([]);
    archiveUnArchiveContactModalRef.current.toggleModal();
  };

  const getContactRequests = async () => {
    const response = await getAllContactRequests();
    if (response?.data?.content) {
      setContactRequests(response.data.content);
      setContactRequestLimitReached(response.data.limitExceeded);
    }
  };

  const handleSnackbarToggle = () => {
    setIsSnackbarOpen(!isSnackbarOpen);
  };
  const handleStatusModalOpen = () => {
    setStatusModalOpen(true);
  };
  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
  };
  const handleContactChange = (event) => {
    const { name, value } = event.target;
    if (value.length > 12) return;
    setContact({
      ...contact,
      [name]: value,
    });
  };
  const validateFields = () => {
    let isValid = true;
    const fields = ['firstName', 'lastName', 'phoneNumber', 'relationshipType'];
    const errorMsgs = {
      firstName: 'Please enter the first name.',
      lastName: 'Please enter the last name.',
      phoneNumber: 'Please enter a valid US phone number.',
      relationshipType: 'Please select a relationship.',
    };
    const errors = {};

    // EMPTY FIELD CHECK
    fields.forEach((title) => {
      if (!contact[title]) {
        isValid = false;
        errors[title] = errorMsgs[title];
      }
    });

    // VALIDATING US PHONE NUMBER
    if (!isValidUSPhoneNumber(contact.phoneNumber)) {
      isValid = false;
      errors['phoneNumber'] = errorMsgs['phoneNumber'];
    }
    return { errors, isValid };
  };

  const isValidUSPhoneNumber = (p) => {
    const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    const digits = p.replace(/\D/g, '');
    return phoneRe.test(digits);
  };

  const handleSubmitContact = async () => {
    try {
      const { isValid, errors } = validateFields();

      if (!isValid) {
        setContact({
          ...contact,
          isSubmitted: true,
          errors,
        });

        return false;
      }

      const { firstName, lastName, phoneNumber, relationshipType, preferredLanguage } = contact;

      const payload = {
        firstName,
        lastName,
        phoneNumber: '+1' + phoneNumber,
        relationshipType,
        preferredLanguage: preferredLanguage ? preferredLanguage : 'en-US', // Added for default
      };

      const response = await submitContactRequest(payload);

      if (response?.data) {
        getContactRequests();
        setReqStatus(response?.data?.status);
        setContact({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          relationshipType: '',
          preferredLanguage: '',
          errors: {},
          isSubmitted: false,
        });
      }

      if (response?.data?.status) {
        setStatusModalOpen(true);
      }
    } catch (error) {
      setStatusModalOpen(true);
      if (Utility.parseError(error).toLowerCase().includes('blocked')) {
        setReqStatus(ContactRequestConstants.CONTACT_REQUEST_STATUSES.BLOCKED);
      } else {
        setReqStatus(ContactRequestConstants.CONTACT_REQUEST_STATUSES.REJECTED);
      }
      // Optionally, update the state to reflect the error
      setContact({
        ...contact,
        isSubmitted: true,
        errors: {
          error: Utility.parseError(error),
        },
      });
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return contact.phoneNumber;
  }

  return {
    contact,
    statusModalOpen,
    contactRequests,
    contactRequestLimitReached,
    handleStatusModalOpen,
    handleStatusModalClose,
    handleSubmitContact,
    handleContactChange,
    formatPhoneNumber,
    setStatusModalOpen,
    reqStatus,
    openModal,
    closeModal,
    archiveUnArchiveContactModalRef,
    isSnackbarOpen,
    handleSnackbarToggle,
    snackbarMessage,
    snackbarType,
    isLoading,
    contactName,
    archiveContacts,
    unArchiveContacts,
    contactId,
  };
};
export default useContactsRequestsHook;
