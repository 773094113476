import axios from 'axios';

export class AuthGuardsHttp {
  accessToken = null;

  static REQUEST = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  constructor(token) {
    this.accessToken = token;

    AuthGuardsHttp.REQUEST.interceptors.request.use(this.requestHandler);
    AuthGuardsHttp.REQUEST.interceptors.response.use(this.successHandler, this.errorHandler);
  }

  requestHandler = (request) => {
    request.headers['Content-Type'] = 'application/json';
    request.headers['Authorization'] = `Bearer ${this.accessToken}`;
    return request;
  };

  successHandler = (response) => {
    // Use this to handle response data
    return response;
  };

  errorHandler = (error) => {
    // Use this to handle response errors
    return Promise.reject({ ...error });
  };
}
