import * as store from './';

const setActiveCommunicationMode = (mode) => {
  return {
    type: store.SET_ACTIVE_COMMUNICATION_MODE,
    mode,
  };
};

const setContacts = (contacts) => {
  return {
    type: store.SET_CONTACTS,
    contacts,
  };
};

const setSelectedThread = (thread) => {
  return {
    type: store.SET_SELECTED_THREAD,
    thread,
  };
};

const setSelectedContact = (contact) => {
  return {
    type: store.SET_SELECTED_CONTACT,
    contact,
  };
};

const setForceReloadMessageThreads = (forceReload) => {
  return {
    type: store.SET_FORCE_RELOAD_MESSAGE_THREADS,
    forceReload,
  };
};

const setMarkSelectedThreadAsRead = (markAsRead) => {
  return {
    type: store.SET_MARK_SELECTED_THREAD_AS_READ,
    markAsRead,
  };
};

const setFetchNewMessages = (fetchNewMessages) => {
  return {
    type: store.SET_FETCH_NEW_MESSAGES,
    fetchNewMessages,
  };
};

const setUnreadMessagesCountLimitReached = (limitReached) => {
  return {
    type: store.SET_UNREAD_MESSAGES_COUNT_LIMIT_REACHED,
    limitReached,
  };
};

export {
  setActiveCommunicationMode,
  setContacts,
  setSelectedThread,
  setSelectedContact,
  setForceReloadMessageThreads,
  setMarkSelectedThreadAsRead,
  setFetchNewMessages,
  setUnreadMessagesCountLimitReached,
};
