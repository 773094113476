import React from 'react';

const PinIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 19 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.58883 1.66516C1.58883 0.808938 2.28058 0.117188 3.1368 0.117188H15.5206C16.3768 0.117188 17.0686 0.808938 17.0686 1.66516C17.0686 2.52138 16.3768 3.21313 15.5206 3.21313H14.0936L14.645 10.3822C16.4204 11.3448 17.8232 12.9557 18.4908 14.9632L18.5391 15.1083C18.6988 15.5824 18.6165 16.1 18.3263 16.5015C18.036 16.903 17.5668 17.1449 17.0686 17.1449H1.58883C1.09057 17.1449 0.62618 16.9079 0.331098 16.5015C0.0360155 16.0952 -0.0413832 15.5776 0.118252 15.1083L0.166626 14.9632C0.834189 12.9557 2.23704 11.3448 4.01237 10.3822L4.56384 3.21313H3.1368C2.28058 3.21313 1.58883 2.52138 1.58883 1.66516ZM7.78072 18.6929H10.8767V23.3368C10.8767 24.193 10.1849 24.8848 9.32869 24.8848C8.47247 24.8848 7.78072 24.193 7.78072 23.3368V18.6929Z"
        fill={fill}
      />
    </svg>
  );
};

PinIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default PinIcon;
