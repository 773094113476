import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, useTheme, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './learning-module.style';
import { RedirectIcon } from '../../../../../shared/icons';
import { Constant } from '../../../../../shared/services';
import useLearningModuleHook from './useLearningModuleHook';
import { ProgressChip } from '..';
import { Typography as CustomTypography } from '@orijinworks/frontend-commons';
import { PillButton } from '../../../../../frontend-commons';

const LearningModule = (props) => {
  const { classes, showBorder, module, index, name, id, progress, showLaunchButton } = props;
  const { launchModule, shouldShowProgressOrLaunch, hasProgress } = useLearningModuleHook(props);
  const theme = useTheme();

  return (
    <Grid
      id="learning-module-container"
      spacing={2}
      className={[classes.moduleContainer, showBorder ? classes.moduleBorder : ''].join(' ')}
    >
      <Grid
        sm={shouldShowProgressOrLaunch() ? 6 : 12}
        md={shouldShowProgressOrLaunch() ? 8 : 12}
        lg={shouldShowProgressOrLaunch() ? 9 : 12}
        xl={shouldShowProgressOrLaunch() ? 10 : 12}
        className={classes.moduleItem}
      >
        <Box className={classes.moduleNameContainer}>
          <CustomTypography id="learning-module-subtitle" variant="overlineMedium" className={classes.moduleText}>
            {`${module} ${index + 1}:`}
          </CustomTypography>
          <Typography id="learning-module-title" className={classes.moduleName}>
            {name}
          </Typography>
        </Box>
      </Grid>
      {shouldShowProgressOrLaunch() && (
        <Grid sm={6} md={4} lg={3} xl={2} className={classes.actionContainer}>
          {hasProgress() && <ProgressChip progress={progress} />}
          {showLaunchButton && (
            <PillButton
              id="launch-btn"
              variant={hasProgress() ? 'contained' : 'outlined'}
              color="primary"
              size="large"
              className={classes.launchBtn}
              onClick={() => launchModule(id, name)}
              tracking-type={Constant.TRACKING_TYPES.COURSE}
              tracking-id={`launch-course-${id}`}
              endIcon={
                <RedirectIcon size={15} fill={hasProgress() ? theme.palette.primary.white : theme.palette.primary.main} />
              }
            >
              <FormattedMessage id="launch" />
            </PillButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

LearningModule.defaultProps = {
  showBorder: false,
  showProgress: false,
  showLaunchButton: false,
};

LearningModule.propTypes = {
  showBorder: PropTypes.bool,
  showProgress: PropTypes.bool,
  showLaunchButton: PropTypes.bool,
  module: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  progress: PropTypes.string,
};

export default withStyles(styles)(LearningModule);
