import React from "react";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Utility } from "../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: "relative"
  },

  loaderContainer: {
    position: "absolute",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    background: theme.palette.primary.main,
    opacity: 0.5,
    borderRadius: "15px",
    zIndex: 10
  },
  absolutePosition: {
    position: "absolute"
  }
}));

const LoaderHOC = ({
  isLoading,
  pendingAPIs,
  apiKey,
  type,
  size,
  position,
  style,
  children
}) => {
  const classes = useStyles();
  const _utilityService = new Utility();

  if (
    isLoading ||
    (pendingAPIs && pendingAPIs[_utilityService.getAPIKey(apiKey, type)])
  ) {
    return (
      <>
        {style && <Box style={style}></Box>}
        <CircularProgress
          size={size}
          className={position === "absolute" ? classes.absolutePosition : ""}
        />
      </>
    );
  } else {
    return children;
  }
};

LoaderHOC.defaultProps = {
  isLoading: false,
  apiKey: "",
  size: 24,
  position: "",
  style: null
};

const mapStateToProps = ({ app, pendingAPI = {} }) => {
  return {
    pendingAPIs: pendingAPI.pendingAPIs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderHOC);
