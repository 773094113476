import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { PlayAreaTop } from '..';
import { Utility } from '../../../../shared/services';
import { PlayMainService } from '../../services';
import styles from './radio.style';

const getUrlFromAttributes = (attributes) => {
  const attribute = attributes.filter((attribute) => attribute.attributeName === 'Radio Url');
  if (attribute.length) {
    return attribute[0].attributeValue || '';
  }
  return '';
};

const radio = (props) => {
  const { classes } = props;
  const playMainService = new PlayMainService();
  const utilityService = new Utility();
  const { attributes } = props;

  return (
    <Grid container item>
      <PlayAreaTop
        redeem={props.redeem}
        schedule={props.schedule}
        status={props.status.radio}
        tabName="Radio"
        updatePlayStatus={props.updatePlayStatus}
        updateRedeemState={props.updateRedeemState}
      />
      {(props.status.radio.type === playMainService.ON ||
        props.status.radio.type === playMainService.IN_SCHEDULE ||
        props.status.radio.type === playMainService.NO_SCHEDULE_DEFINED) && (
        <iframe
          title="radio-resource-frame"
          className={[
            classes.framedElement,
            !props.redeem &&
            props.status.radio.price !== 0 &&
            utilityService.checkStatusExists(props.features, 'Incentive_Engine')
              ? classes.disabledFrame
              : null,
          ].join(' ')}
          src={getUrlFromAttributes(attributes)}
          allowFullScreen
          width="100%"
          height="1000px"
        ></iframe>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app }) => app;

export default withStyles(styles)(connect(mapStateToProps)(radio));
