import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './footer-banner.style';
import { FormattedMessage } from 'react-intl';
import { PillButton } from '../../../../../frontend-commons';
import { Constant } from '../../../../../shared/services';

const FooterBanner = (props) => {
  const { classes, programId, onEnroll } = props;

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          <FormattedMessage id="programEnrollFotter" />
        </Typography>
        <PillButton
          id="footer-enroll-button"
          variant="contained"
          color="secondary"
          size="large"
          onClick={onEnroll}
          className={classes.actionButton}
          tracking-type={Constant.TRACKING_TYPES.PROGRAM}
          tracking-id={`enroll-program-${programId}`}
        >
          <FormattedMessage id="enroll" />
        </PillButton>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FooterBanner);
