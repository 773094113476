import { Http } from '../../../core';

export class InterestProfilerService {
  /**
   * @name getAnswerString
   * @desc Fetches the answer string of interest profiler questionaire
   * @return {Promise}
   */
  getAnswersString = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/interest-profiler/v1/answers`)
        .then((_successLog) => {
          let answersString = '';
          if (_successLog && _successLog.data) {
            const { payload } = _successLog.data;
            if (payload && payload.answers) {
              answersString = payload.answers;
            }
          }
          resolve(answersString);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getQuestionnaire
   * @desc Fetches the interest profiler questionaire
   * @return {Promise}
   */
  getQuestionnaire = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/interest-profiler/v2/questions-30?start=1&end=30`)
        .then((_successLog) => {
          let data = { answer_options: [], questions: [] };
          if (_successLog && _successLog.data) {
            const { payload } = _successLog.data;
            const { answer_options, question } = payload;
            if (answer_options && answer_options.answer_option && answer_options.answer_option.length > 0) {
              data.answer_options = answer_options.answer_option;
            }
            if (question && question.length > 0) {
              data.questions = question;
            }
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name saveQuestionnaireResponse
   * @desc Save the answer string of interest profiler questionaire
   * @return {Promise}
   */
  saveQuestionnaireResponse = (answersString) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.post(`/services/interest-profiler/v1/answers`, {
        answers: answersString,
      })
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getInterestProfilerResults
   * @desc Fetches the interest profiler results
   * @return {Promise}
   */
  getInterestProfilerResults = (answersString) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/interest-profiler/v1/results?answers=${answersString}`)
        .then((_successLog) => {
          let data;
          if (_successLog && _successLog.data) {
            const { payload } = _successLog.data;
            data = payload;
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getMatchingCareers
   * @desc Fetches the interest profiler matching careers
   * @return {Promise}
   */
  getMatchingCareers = (answersString, zone, start, end) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(
        `/services/interest-profiler/v1/careers?answers=${answersString}&start=${start}&end=${end}&job_zone=${zone}`
      )
        .then((_successLog) => {
          let data;
          if (_successLog && _successLog.data) {
            const { payload } = _successLog.data;
            data = payload;
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getJobZoneQuestionnaire
   * @desc Fetches the job zone questionnaire
   * @return {Promise}
   */
  getJobZoneQuestionnaire = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/interest-profiler/v1/job-zone/questions`)
        .then((_successLog) => {
          let data;
          if (_successLog && _successLog.data) {
            const { payload } = _successLog.data;
            data = payload;
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name saveJobZoneQuestionnaireAnswers
   * @desc Save the jop zone questionnaire answers
   * @return {Promise}
   */
  saveJobZoneQuestionnaireAnswers = (answersString) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.post(`/services/interest-profiler/v1/job-zone/answers`, {
        answers: answersString,
      })
        .then((_successLog) => {
          let data;
          if (_successLog && _successLog.data) {
            const { payload } = _successLog.data;
            data = payload;
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
