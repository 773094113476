export default (theme) => ({
  mainBox: {
    width: '100%',
  },
  parentCard: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subtext: {
    marginTop: '1%',
    fontSize: '1rem',
    whiteSpace: 'pre-line',
  },
  voiceCallImg: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  inputField: {
    margin: theme.spacing(1),
    '& input': {
      borderRadius: '20px',
    },
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    borderRadius: '10px',
  },
  submitedContacts: {
    alignItems: 'center',
  },
  InputLabel: {
    marginLeft: '5px',
  },
  tabCard: {
    width: '-webkit-fill-available',
  },
  btnRadius: {
    borderRadius: '20px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    width: '50%',
  },
  mainContainerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px',
  },
  formattedParagraph: {
    fontFamily: 'inherit',
    fontSize: '2rem',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    marginTop: '50px',
    whiteSpace: 'pre-line',
  },
  boldText: {
    fontWeight: 'bolder',
    textAlign: 'center',
    padding: '10px',
  },

  noContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4%',
  },

  approvedContactHeader: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '29.05px',
    color: '#0F2C54',
    padding: '24px',
  },
  approvedContactContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    width: '611px',
    height: '88px',
    borderRadius: '16px',
    backgroundColor: '#F0F6FF',
    marginLeft: '18px',
  },
  addContactButton: {
    width: '180px',
    borderRadius: '100px',
    height: '40px',
    backgroundColor: '#0F48B8',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#0F48B8',
    },
  },
  addContactButtonLabel: {
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '19.36px',
    color: '#FFFFFF',
  },

  contactsMonitoringText: {
    fontFamily: 'Inter',
    marginLeft: '24px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
  },

  contactContainer: {
    display: 'flex',
    width: '611px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.white,
    border: `0.5px solid ${theme.palette.primary.greyed}`,
    marginLeft: '24px',
    marginTop: '14px',
    padding: '16px',
  },
  contactInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactName: {
    fontFamily: 'Inter',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24.2px',
    color: '#0F2C54',
    marginLeft: '12px',
  },
  contactRelationship: {
    fontFamily: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: '#0078A2',
    marginLeft: '12px',
  },
  contactNumber: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
    marginLeft: '12px',
  },
  dialIconButton: {
    marginLeft: '210px',
  },
});
