import { combineReducers } from 'redux';
import { appReducer } from './app';
import { playReducer } from './play';
import { earnTokensReducer } from './earn-tokens';
import { interestProfilerReducer } from './interest-profiler';
import { pendingAPIReducer } from './pending-APIs';
import { PDQAssessmentReducer } from './pdq-assessment';
import { staffMessagingReducer } from './staff-messaging';
import { careerCompassReducer } from './career-compass';
import { voiceCallReducer } from './voice-call';
export default combineReducers({
  app: appReducer,
  locale: appReducer,
  pendingAPI: pendingAPIReducer,
  play: playReducer,
  earnToken: earnTokensReducer,
  interestProfiler: interestProfilerReducer,
  pdqAssessment: PDQAssessmentReducer,
  staffMessaging: staffMessagingReducer,
  careerCompass: careerCompassReducer,
  voiceCall: voiceCallReducer,
});
