import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ThumbnailCard } from '../../../../shared';
import { useIntl } from 'react-intl';
import { Constant } from '../../../../../shared/services';
import { withStyles } from '@material-ui/core';
import styles from './explore-carousel.styles';
import CustomDot from './custom-dot';

/**
 * Object containing the responsive properties for the carousel
 */
const responsiveProps = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1250, min: 464 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};

/**
 * Component to render the Explore page carousel
 *
 * @param {object} props - The props of the component
 * @returns {React.Component} The ExploreCarousel component
 */
const ExploreCarousel = ({ classes, services, handleServiceClick, willOpenLink }) => {
  const intl = useIntl();

  return (
    <Carousel
      className={classes.carousel}
      itemClass={classes.carouselItem}
      responsive={responsiveProps}
      arrows={false}
      autoPlay
      autoPlaySpeed={15000}
      showDots
      customDot={<CustomDot classes={classes} />}
      rewind
      rewindWithAnimation
    >
      {services.map((service) => (
        <ThumbnailCard
          key={service.id}
          title={service.name}
          subtitle={service.displayCredential ? intl.formatMessage({ id: 'sign-in-required' }) : ''}
          description={service.description}
          thumbnailUrl={service.thumbnailUri}
          thumbnailType={service.isVideo ? 'video' : 'image'}
          autoChangeOrientation={false}
          orientation="horizontal"
          showArrow={false}
          trackingType={Constant.TRACKING_TYPES.SERVICE}
          trackingId={`launch-service-${service.id}`}
          onClick={() => handleServiceClick(service)}
          accessibilityProps={willOpenLink(service) ? { role: 'link', rel: 'noopener noreferrer' } : {}}
        />
      ))}
    </Carousel>
  );
};

ExploreCarousel.propTypes = {
  /**
   * The classes that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The services to display in the carousel
   */
  services: PropTypes.array.isRequired,
  /**
   * The function to call when a service is clicked
   */
  handleServiceClick: PropTypes.func.isRequired,
  /**
   * The function to check if the service will open a link
   */
  willOpenLink: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExploreCarousel);
