import { useContext, useEffect } from 'react';
import { BreadcrumbsContext } from '../breadcrumbs-context';
import { BREADCRUMBS_ITEMS, getMatchedRoute, getMatchedRouteIndex } from './breadcrumbs.helper';

/**
 * Custom hook to handle the breadcrumbs
 *
 * @param {Object} history - The react-router history object
 * @returns {Object} - The breadcrumbs and the handleItemClick function
 * @returns {Array} breadcrumbs - The breadcrumbs array
 * @returns {Function} handleItemClick - The function to handle the breadcrumb item click
 */
const useBreadcrumbHook = ({ history }) => {
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  const currentPath = history.location?.pathname || '';

  /**
   * Effect to update the breadcrumbs
   */
  useEffect(() => {
    const currentRoute = getMatchedRoute(BREADCRUMBS_ITEMS, currentPath);
    if (currentRoute) {
      currentRoute.path = currentPath;
      setBreadcrumbs((prevData) => {
        const routeExists = getMatchedRoute(prevData, currentPath);
        const index = getMatchedRouteIndex(prevData, currentPath);
        let item = routeExists ? prevData.slice(0, index + 1) : [...prevData, currentRoute];
        if (currentRoute.isPrimary) {
          item = [currentRoute];
        }
        return item;
      });
    } else {
      setBreadcrumbs([]);
    }
  }, [currentPath, setBreadcrumbs]);

  /**
   * Function to handle the breadcrumb item click
   *
   * @param {String} path - The path to navigate
   */
  const handleItemClick = (path) => {
    history.push(path);
  };

  return { breadcrumbs, handleItemClick };
};

export default useBreadcrumbHook;
