export default (theme) => ({
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '2px solid',
    borderColor: '#DFE6E9 !important',
    paddingBottom: 0,
    padding: '20px 0px',
  },
  filterItem: {
    width: 'initial',
    '&:focus': {
      color: theme.palette.aqua.deep,
      '& svg': {
        fill: theme.palette.aqua.deep,
        stroke: theme.palette.aqua.deep,
      },
    },
  },
  activeFilter: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  filterItemText: {
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  icon: {
    fill: theme.palette.primary.sub,
    minWidth: 30,
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
});
