export default (theme) => ({
  main: {
    width: '100%',
    padding: 16,
    backgroundColor: `${theme?.palette?.primary?.mistyRose} !important`,
    alignItems: 'center',
  },
  headingSection: {
    display: 'flex',
    alignItems: 'center',
  },
  notIntrestedIcon: {
    color: theme?.palette?.orange?.deep,
  },
  heading: {
    fontWeight: 'bold',
    marginLeft: '8px',
    color: theme?.palette?.orange?.deep,
  },

  bannerMessage: {
    marginLeft: '32px',
    color: theme?.palette?.greyScale?.charcoal,
  },
});
