import React from "react";
import { withStyles } from "@material-ui/core";
import moment from "moment-timezone";
import styles from "./index.styles";

function MsgEvent(props) {
  const { classes, text, date, timezoneName} = props;
  return (
    <div className={classes.dateSection}>
      <div className={classes.line} />
      <span className={classes.date}>{text} {moment(date).format("LT")} {timezoneName}</span>
      <div className={classes.line} />
    </div>
  );
}

export default withStyles(styles)(MsgEvent);
