import React from 'react';
import { Box } from '@material-ui/core';
import { ResumeBuilder as ResumeTool } from '@orijinworks/frontend-commons';
import { useIntl } from 'react-intl';
import useResumeBuilderHook from './useResumeBuilderHook';
import { useDocumentTitleHook } from '../../../../shared';
import { Permission } from '../../../../../core';
import { connect } from 'react-redux';

const ResumeBuilder = (props) => {
  const intl = useIntl();
  const { resumeData, statesOptions, degreeOptions, skillsOptions, onSave, onPreview } = useResumeBuilderHook(props);
  useDocumentTitleHook('page.title.resume-builder');

  return (
    <Permission>
      <Box>
        {resumeData && (
          <ResumeTool
            personalInfo={resumeData.personalInfo}
            workExperiences={resumeData.workExperiences}
            educations={resumeData.educations}
            achievements={resumeData.achievements}
            skills={resumeData.skills}
            stateOptions={statesOptions}
            degreeOptions={degreeOptions}
            skillsOptions={skillsOptions}
            onSave={onSave}
            showIntroductionComponent
            introductionProps={{
              title: intl.formatMessage({ id: 'resume-builder.introduction.title' }),
              description: intl.formatMessage({ id: 'resume-builder.introduction.description' }),
              instructions: intl.formatMessage({ id: 'resume-builder.introduction.instructions' }),
            }}
            showPreviewComponent
            previewProps={{
              title: intl.formatMessage({ id: 'resume-builder.preview.title' }),
              description: intl.formatMessage({ id: 'resume-builder.preview.description' }),
              buttonText: intl.formatMessage({ id: 'resume-builder.preview.button-text' }),
              onPreview,
            }}
            snackbarPosition={{ vertical: 'bottom', horizontal: 'center' }}
            allowReordering
          />
        )}
      </Box>
    </Permission>
  );
};

const mapStateToProps = ({ app }) => ({
  userDetail: app.userDetail,
});

export default connect(mapStateToProps)(ResumeBuilder);
