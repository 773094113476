export default theme => ({
  carouselHead: {
    marginBottom: 20
  },
  carouselWrapper: {
    position: "relative",
    paddingBottom: 15
  },
  carouselNavigator: {
    position: "absolute",
    top: 90,
    width: 60,
    minWidth: 60
  },
  carouselNavigatorLeft: {
    left: -50
  },
  carouselNavigatorRight: {
    right: -43,
    [theme.breakpoints.down("md")]: {
      right: -50
    }
  },
  contentWrapper: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    paddingBottom: 5,
    [theme.breakpoints.down("md")]: {
      overflow: "auto"
    },
    whiteSpace: "nowrap",
    "& > div": {
      display: "inline-block",
      maxWidth: 220,
      margin: "1px 0px",
      marginRight: 15,
      verticalAlign: "middle"
    }
  }
});
