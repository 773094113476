export default (theme) => ({
  message: {
    display: 'flex',
    position: 'relative',
    minHeight: 37,
    alignItems: 'center',
    padding: '10px 40px',
    textTransform: 'none',
    textAlign: 'left',
    justifyContent: 'flex-start',
    '&:hover': {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(1, 113, 237, 0.3) 5.3%, rgba(1, 113, 237, 0.1) 100%)',
        opacity: 0.4,
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      },
    },
  },
  block: {
    color: '#f45448',
  },
  sidebarMsgWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 250,
  },
  messageSelected: {
    borderRight: `4px solid ${theme.palette.primary.themeGreen}`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg, rgba(1, 113, 237, 0.3) 5.3%, rgba(1, 113, 237, 0.1) 100%)',
      opacity: 0.4,
      transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    },
  },
  attornayIcon: {
    right: '12px',
    color: '#21458c',
    opacity: 0.6,
  },
  unreadCount: {
    color: '#737982',
    marginRight: 18,
    fontWeight: 'bold',
    alignItems: 'right',
    marginLeft: 'auto',
  },
  messageText: {
    fontSize: 15,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    letterSpacing: 0.5,
    marginTop: '1px',
    minWidth: 116,
    // marginBottom: 1,
  },
  messageTextHighlight: {
    fontSize: 15,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    marginBottom: 1,
  },
});
