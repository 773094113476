export default (theme) => ({
  root: {
    textDecoration: 'none',
    borderRadius: '10px',
    position: 'relative',
    textTransform: 'none',
    textAlign: 'left',
    width: '100%',
    '& > span': {
      display: 'block',
    },
    '&$itemWrapper': {
      display: 'block',
    },
  },
  itemWrapper: {
    display: 'block',
  },
  card: {
    borderRadius: '10px',
  },
  thumbnailImage: {
    backgroundColor: 'rgba(59, 146, 140, 0.1)',
    maxWidth: '100%',
    height: 85,
  },
  cardLayout: {
    width: '100%',
    minHeight: 275,
    [theme.breakpoints.down('sm')]: {
      minHeight: 325,
    },
  },
  contentArea: {
    padding: 20,
    width: '100%',
    position: 'relative',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  boxTitle: {
    ...theme.typography.t24,
    letterSpacing: '0px',
    lineHeight: '32px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    fontWeight: 700,
  },
  boxDescription: {
    color: theme.palette.text.sub,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    overflow: 'hidden',
  },
  actionBtn: {
    borderRadius: 100,
    padding: '12px 20px',
    '&$careerPathLearnMoreBtn': {
      ...theme.typography.bold,
      ...theme.typography.t20,
      textDecoration: 'none',
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
    },
  },
  careerPathLearnMoreBtn: {
    // need this for the class to show up
  },
  actionBox: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    width: 'fit-content',
  },
  pinnedIcon: {
    fill: theme.palette.primary.main,
    position: 'absolute',
    top: -2,
    right: 30,
    cursor: 'pointer',
    '& > svg': {
      width: 50,
      height: 50,
    },
  },
});
