import { Box, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useStyles from './breadcrumb.style';
import { Utility } from '../../../shared/services';

function NavBar(props) {
  const utilityService = new Utility();
  const { handleLogout, isRequiredFormExistOnLogin } = props;
  const classes = useStyles();
  const title = utilityService.getTitleFromUrl('heading');
  const intl = useIntl();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {title && (
        <Box color={utilityService.isModuleActivated('home') ? 'white' : null} className={classes.headingWrapper}>
          <Typography data-cy-name={`title-${title}`} color="black" variant="h3">
            <FormattedMessage id={title} />
          </Typography>
        </Box>
      )}
      {isRequiredFormExistOnLogin && (
        <Button
          color="primary"
          className={classes.signOutBtn}
          onClick={handleLogout}
          aria-label={intl.formatMessage({ id: 'signOut' })}
        >
          <FormattedMessage id="signOut" />
        </Button>
      )}
    </Box>
  );
}

const mapStateToProps = ({ app }) => {
  return app;
};

export default connect(mapStateToProps)(NavBar);
