import React from 'react';

const FilmIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 37.058 32.425">
      <path
        id="film-simple-solid"
        d="M27.793,32H9.264v3.474a1.161,1.161,0,0,1-1.158,1.158H5.79a1.161,1.161,0,0,1-1.158-1.158V32A4.636,4.636,0,0,0,0,36.632V59.793a4.636,4.636,0,0,0,4.632,4.632V60.951A1.161,1.161,0,0,1,5.79,59.793H8.106a1.161,1.161,0,0,1,1.158,1.158v3.474H27.793V60.951a1.161,1.161,0,0,1,1.158-1.158h2.316a1.161,1.161,0,0,1,1.158,1.158v3.474a4.636,4.636,0,0,0,4.632-4.632V36.632A4.636,4.636,0,0,0,32.425,32v3.474a1.161,1.161,0,0,1-1.158,1.158H28.951a1.161,1.161,0,0,1-1.158-1.158ZM4.632,42.422A1.161,1.161,0,0,1,5.79,41.264H8.106a1.161,1.161,0,0,1,1.158,1.158v2.316A1.161,1.161,0,0,1,8.106,45.9H5.79a1.161,1.161,0,0,1-1.158-1.158Zm24.319-1.158h2.316a1.161,1.161,0,0,1,1.158,1.158v2.316A1.161,1.161,0,0,1,31.267,45.9H28.951a1.161,1.161,0,0,1-1.158-1.158V42.422A1.161,1.161,0,0,1,28.951,41.264ZM4.632,51.687A1.161,1.161,0,0,1,5.79,50.529H8.106a1.161,1.161,0,0,1,1.158,1.158V54a1.161,1.161,0,0,1-1.158,1.158H5.79A1.161,1.161,0,0,1,4.632,54Zm24.319-1.158h2.316a1.161,1.161,0,0,1,1.158,1.158V54a1.161,1.161,0,0,1-1.158,1.158H28.951A1.161,1.161,0,0,1,27.793,54V51.687A1.161,1.161,0,0,1,28.951,50.529Z"
        transform="translate(0 -32)"
        fill={fill}
      />
    </svg>
  );
};

FilmIcon.defaultProps = {
  width: '37.058',
  height: '32.425',
  fill: 'inherit',
};

export default FilmIcon;
