import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Http, Permission, toggleToast } from '../../../../core';
import { Activity, Constant, Rest, UserService, Utility } from '../../../../shared';
import { AssignedBundle, MostRecent, NoBundle } from '../../components';
import { PathwayListingService } from '../../services';
import styles from './pathway-listing.style';
import { injectIntl } from 'react-intl';

export class PathwayListing extends React.Component {
  _service = new PathwayListingService();
  _utilityService = new Utility();
  userService = new UserService();
  state = {
    showUnassignedBundles: false,
    mostRecent: {
      data: null,
      isLoading: false,
    },
    bundle: {
      data: null,
      isLoading: false,
    },
    screenCords: {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    },
    greetings: {
      data: {},
      isLoading: false,
    },
  };

  componentDidMount() {
    // FETCHING LIST ITEMS
    this.getListingData();

    this.listenScreenResize();
  }

  /**
   * @name updateScreenCords
   * @desc Updates screen cords from window innerWidth & innerHeight
   * @return {void}
   */
  updateScreenCords = () => {
    this.setState({
      screenCords: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  /**
   * @name listenScreenResize
   * @desc Sets state data based upon screen resize.
   * @return {void}
   */
  listenScreenResize = () => {
    window.addEventListener('resize', this.updateScreenCords);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenCords);
  }

  /**
   * @name getListingData
   * @desc Purpose of this method is to encapsulate all listing
   * mechanism under single method hood.
   * @return {void}
   */
  getListingData = () => {
    if (this.props.userDetail) {
      if (!this.state.bundle.data && !this.state.bundle.isLoading) {
        this.getResourceList();
      }
      if (!this.state.mostRecent.data && !this.state.mostRecent.isLoading) {
        this.getMostRecent();
      }
    }
  };

  componentDidUpdate() {
    // FETCHING LIST ITEMS
    this.getListingData();
  }

  /**
   * @name getContentAreas
   * @param {array} collectionOfBundle
   * @desc Prepare content areas based on collection of bundle passed to it.
   * @return {JSX[]}
   */
  getContentAreas = (collectionOfBundle) => {
    const { classes } = this.props;
    const indexedContentAreas = {};
    const chipsCollection = [];
    collectionOfBundle.forEach((bundle, index) => {
      // IF NOT HAVE BUNDLE AREA ID THEN INDEX IT
      // AND PUSH ELEMENT TO STACK
      if (!indexedContentAreas.hasOwnProperty(bundle.contentAreaId)) {
        indexedContentAreas[bundle.contentAreaId] = true;
        chipsCollection.push(
          <Chip
            key={index}
            className={classes.assignedChip}
            style={
              Constant.CONTENT_AREA[bundle.contentAreaId]
                ? Constant.CONTENT_AREA[bundle.contentAreaId.toString()].chip
                : null
            }
            icon={Constant.CONTENT_AREA[bundle.contentAreaId].icons.white}
            label={
              Constant.CONTENT_AREA[bundle.contentAreaId]
                ? Constant.CONTENT_AREA[bundle.contentAreaId].getTranslatedName(this.props.intl)
                : null
            }
          />
        );
      }
    });
    return chipsCollection;
  };

  /**
   * @name getResourceList
   * @desc Fetches resource list from service.
   * @return {void}
   */
  getResourceList = async () => {
    try {
      this.setState({
        bundle: {
          data: null,
          isLoading: true,
        },
      });
      const resourceList = await Http.REQUEST.get('/resource/list');
      if (!resourceList || !resourceList.data) {
        this.setState({
          bundle: {
            data: null,
            isLoading: false,
          },
        });
        return;
      }
      const { history, classes, showToast } = this.props;
      this.setState({
        bundle: {
          isLoading: false,
          data: resourceList.data
            .filter((resource) => !resource.hiddenInPathways)
            .map((bundle) => {
              bundle['contentArea'] = this.getContentAreas(bundle.children);
              bundle['children'] = bundle['children'].map((activity, index) => (
                <Grid key={index} item sm={6} md={4} lg={3}>
                  <Activity
                    classes={{
                      cardHeading: classes.cardHeading,
                    }}
                    onClick={async () => {
                      this.userService.registerAnalyticEvent(
                        UserService.GAEvents.Category.Pathways,
                        'Clicked',
                        this.props.userDetail.preferred_username,
                        activity.name
                      );
                      const clickTileToLaunch = this._utilityService.checkStatusExists(
                        this.props.features,
                        'Click_Tile_To_Launch'
                      );
                      if (clickTileToLaunch) {
                        const url = await new Rest().getLaunchUrl(activity.id);
                        window.open(url.data, '_blank');
                      } else {
                        this._service.setCacheDetailsData = {
                          ...activity,
                          progress: {
                            completedItem: activity.completedScore,
                            totalNoOfItem: activity.totalScore,
                          },
                          percentageCompleted: (activity.completedScore / activity.totalScore) * 100,
                        };
                        window.scrollTo(0, 0);
                        history.push(`/pathway-detail/${activity.id}`);
                      }
                    }}
                    launchHandler={async () => {
                      try {
                        this.userService.registerAnalyticEvent(
                          UserService.GAEvents.Category.Pathways,
                          'Clicked',
                          this.props.userDetail.preferred_username,
                          activity.name
                        );
                        const url = await new Rest().getLaunchUrl(activity.id);
                        if (url.data) {
                          window.open(url.data, '_blank');
                          return;
                        }
                        showToast({
                          variant: 'error',
                          message: 'Failed to fetch URL.',
                          isOpen: true,
                          showCancelButton: true,
                        });
                      } catch (e) {
                        console.log(e);
                        showToast({
                          variant: 'error',
                          message: 'Failed to fetch URL.',
                          isOpen: true,
                          showCancelButton: true,
                        });
                      }
                    }}
                    meta={{
                      Progress: {
                        totalScore: activity.totalScore,
                        completedScore: activity.completedScore,
                      },
                      Category: Constant.CONTENT_AREA[activity.contentAreaId.toString()] ? (
                        Constant.CONTENT_AREA[activity.contentAreaId.toString()].icons.round
                      ) : (
                        <p>No Icon</p>
                      ),
                      MainText: activity.name,
                      ParentId: bundle.id,
                      id: activity.id,
                    }}
                  />
                </Grid>
              ));
              return bundle;
            }),
        },
      });
    } catch (e) {}
  };

  /**
   * @name toggleUnassignedBundles
   * @desc Toggles unassigned bundles.
   * @return {void}
   */
  toggleUnassignedBundles = () => {
    const showUnassignedBundles = !this.state.showUnassignedBundles;
    this.setState({
      showUnassignedBundles,
    });
  };

  /**
   * @name getMostRecent
   * @desc Fetches most recent data from service.
   * @return {void}
   */
  getMostRecent = async () => {
    this.setState({
      mostRecent: {
        data: null,
        isLoading: true,
      },
    });
    const { showToast } = this.props;

    const mostRecentCollection = await Http.REQUEST.get('/resource/content/recent');
    if (!mostRecentCollection || !mostRecentCollection.data) {
      this.setState({
        mostRecent: {
          data: null,
          isLoading: false,
        },
      });
      return;
    }

    const { classes } = this.props;

    this.setState({
      mostRecent: {
        data: mostRecentCollection.data.map((mostRecent, index) => (
          <Grid key={index}>
            <Activity
              classes={{
                cardHeading: classes.cardHeading,
              }}
              launchHandler={async () => {
                try {
                  this.userService.registerAnalyticEvent(
                    UserService.GAEvents.Category.Pathways,
                    'Clicked',
                    this.props.userDetail.preferred_username,
                    mostRecent.name
                  );
                  const url = await new Rest().getLaunchUrl(mostRecent.id);
                  if (url.data) {
                    window.open(url.data, '_blank');
                    return;
                  }
                  showToast({
                    variant: 'error',
                    message: 'Failed to fetch URL.',
                    isOpen: true,
                    showCancelButton: true,
                  });
                } catch (e) {
                  console.log(e);
                  showToast({
                    variant: 'error',
                    message: 'Failed to fetch URL.',
                    isOpen: true,
                    showCancelButton: true,
                  });
                }
              }}
              meta={{
                Category: Constant.CONTENT_AREA[mostRecent.contentAreaId.toString()] ? (
                  Constant.CONTENT_AREA[mostRecent.contentAreaId.toString()].icons.round
                ) : (
                  <p>No Icon</p>
                ),
                MainText: mostRecent.name,
                SubText: mostRecent.description,
                ParentId: 9999, // Using only for cypress unique identification
                id: mostRecent.id,
              }}
            />
          </Grid>
        )),
        isLoading: false,
      },
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container item className={classes.pathwayListingWrapper}>
        <Permission testSuite={this.props.testSuite}>
          {/* BEGIN: MOST RECENT */}
          {this.state.mostRecent.data && this.state.mostRecent.data.length > 0 && (
            <Grid container item id="most-recent-wrapper" className={classes.sectionMargin}>
              <MostRecent {...this.state} />
            </Grid>
          )}
          {/* END: MOST RECENT */}
          {/* BEGIN: PREPARE FOR YOUR GED */}
          {!this.state.bundle.isLoading &&
            (this.state.bundle.data && this.state.bundle.data.length > 0 ? (
              this.state.bundle.data.map((bundle, index) =>
                bundle.active || this.state.showUnassignedBundles ? (
                  <Grid key={index} container item className={classes.sectionMargin}>
                    <AssignedBundle {...bundle} unassignedTextClass={classes.unassignedTextClass} />
                  </Grid>
                ) : null
              )
            ) : (
              <NoBundle />
            ))}
          {/* END: PREPARE FOR YOUR GED */}
        </Permission>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (data) => dispatch(toggleToast(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(injectIntl(PathwayListing))));
