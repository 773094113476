export default (theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  content: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25% !important',
    backgroundColor: theme.palette.primary.white,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  closeIcon: {
    color: theme.palette.primary.white,
  },
});
