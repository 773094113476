import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './multi-launch-modules.style';
import LearningModule from '../learning-module/learning-module';

const MultiLaunchModules = (props) => {
  const { classes, modules, isEnrolled } = props;

  return (
    <>
      <Box className={classes.overviewHeader}>
        <Box className={classes.headingWrapper}>
          <Typography id="multi-launch-program-overview-title" color="secondary" variant="h4">
            <FormattedMessage id="programOverview" />
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Grid className={classes.modulesContainer}>
        {(modules ?? []).map((module, index) => (
          <LearningModule
            key={module.id}
            id={module.id}
            index={index}
            name={module.name}
            progress={module.progress}
            module="course"
            showBorder={true}
            showProgress={isEnrolled}
            showLaunchButton={isEnrolled}
          />
        ))}
      </Grid>
    </>
  );
};

MultiLaunchModules.propTypes = {
  modules: PropTypes.array,
  isEnrolled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(MultiLaunchModules);
