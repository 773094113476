import { ListItem, Grid, withStyles, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import { Constant } from '../../../../shared/services';

import styles from './explore-filter.style';
import { useIntl } from 'react-intl';

const ExploreFilter = (props) => {
  const { classes, categories, activeNavLink, setActiveNavLink } = props;
  const intl = useIntl();

  return (
    <Grid container className={classes.filterWrapper} id="explore-filter-list">
      {categories.map((category) => {
        category = { ...Constant.SERVICE_CATEGORY[category.id], ...category };
        return (
          <Grid item>
            <ListItem
              key={`service-category-${category.id}`}
              button
              data-cy-name={`service-category-${category.id}-filter`}
              id={`service-category-${category.id}-filter`}
              onClick={() => {
                setActiveNavLink(category.id, true);
              }}
              className={[classes.filterItem, category.id === activeNavLink ? classes.activeFilter : ''].join(' ')}
              tracking-type={Constant.TRACKING_TYPES.SERVICE}
              tracking-id={`scroll-to-service-category-${category.id}`}
              aria-label={intl.formatMessage({ id: 'scrollToCategoryServices' }, { category: category.name })}
            >
              <ListItemIcon className={[classes.icon, category.id === activeNavLink ? classes.activeIcon : ''].join(' ')}>
                {category.iconWithProps({ width: 18, height: 18, size: 18 })}
              </ListItemIcon>
              <ListItemText primary={<Typography className={classes.filterItemText}>{category.name}</Typography>} />
            </ListItem>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withStyles(styles)(ExploreFilter);
