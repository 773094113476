import React from 'react';

const UserIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25.546 29.196">
      <path
        id="user-solid"
        d="M12.773,14.6a7.3,7.3,0,1,0-7.3-7.3A7.3,7.3,0,0,0,12.773,14.6Zm-2.606,2.737A10.165,10.165,0,0,0,0,27.5,1.694,1.694,0,0,0,1.694,29.2H23.853A1.694,1.694,0,0,0,25.546,27.5,10.165,10.165,0,0,0,15.379,17.335Z"
        fill={fill}
      />
    </svg>
  );
};

UserIcon.defaultProps = {
  width: '25.546',
  height: '29.196',
  fill: 'inherit',
};

export default UserIcon;
