import { Grid, Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { Carousel } from "../../../../shared/components";
import styles from "./styles";
import { FormattedMessage } from "react-intl";

const AssignedBundle = props => {
  return (
    <Carousel
      contentTypes={<Grid>{props.contentArea}</Grid>}
      showNextNavigator
      itemCount={props.children.length}
      showBackNavigator
      id={props.id}
      contentWrapperId={`scoller-wrapper-${props.id}`}
      title={
        props.active ? (
          props.name
        ) : (
          <Typography>
            {props.name} -{" "}
            <Box component="span" className={props.unassignedTextClass}>
              <FormattedMessage id="unassigned" />
            </Box>
          </Typography>
        )
      }
    >
      {/* BEGIN: CARD */}
      {props.children}
      {/* END: CARD */}
    </Carousel>
  );
};

export default withStyles(styles)(AssignedBundle);
