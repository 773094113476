import { Box, Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import styles from './recommended-content.styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { RecommendedContentIcon } from '../../../../shared';

const RecommendedContent = ({ classes, description, recommendedContent, onLaunchUrl }) => {
  const { title, description: recommendedDescription, resource_key: resourceId } = recommendedContent;
  const intl = useIntl();

  return (
    <Box className={classes.mainRecommendationWrapper} onClick={() => onLaunchUrl(resourceId)}>
      <Box className={classes.topContent}>
        <Typography variant="h4">
          <FormattedMessage id="resultsRecommendation" />
        </Typography>
        <Typography className={classes.subHeading}>{description}</Typography>
      </Box>
      <Card className={classes.card}>
        <Box component="div" className={classes.visualPart}>
          <RecommendedContentIcon />
        </Box>
        <Box component="div" className={classes.contentPart}>
          <Box>
            <Typography className={classes.mainTitle}>{title}</Typography>
            <Typography className={classes.description}>{recommendedDescription}</Typography>
          </Box>
          <Button className={classes.actionBtn} color="primary" aria-label={intl.formatMessage({ id: 'launch' })}>
            <FormattedMessage id="launch" />
            <ChevronRight />
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(RecommendedContent);
