import { useCallback, useEffect, useState } from 'react';
import { addBookmark, getCareerDetails, removeBookmark } from '../../services/careers.service';
import { useIntl } from 'react-intl';

const useCareerProfileHook = (props) => {
  let careerId = props.match.params.id;
  const { history, selectedLocale } = props;
  const intl = useIntl();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [outline, setOutline] = useState({});
  const [jobOutlook, setJobOutlook] = useState({});
  const [personality, setPersonality] = useState({});
  const [abilities, setAbilities] = useState([]);
  const [knowledge, setKnowledge] = useState([]);
  const [skillsAndTechnology, setSkillsAndTechnology] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);
  const [videoPlayerProps, setVideoPlayerProps] = useState({ isOpen: false, videoUrl: '' });

  const fetchCareerDetails = useCallback(
    async (intlObj) => {
      try {
        const details = await getCareerDetails(careerId, intlObj);
        setOutline(details.outline);
        setJobOutlook(details.jobOutlook);
        setPersonality(details.personality);
        setAbilities(details.abilities);
        setKnowledge(details.knowledge);
        setSkillsAndTechnology(details.skillAndTechnology);
        setSimilarJobs(details.similarJobs);
      } catch (e) {
        console.log(e);
      } finally {
        setIsDataLoading(false);
      }
    },
    [careerId]
  );

  useEffect(() => {
    fetchCareerDetails(intl);
  }, [selectedLocale, intl, fetchCareerDetails]);

  const handleBookmarkAdd = async () => {
    try {
      const response = await addBookmark(careerId);
      if (response) {
        setOutline({ ...outline, isBookmarked: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBookmarkRemove = async () => {
    try {
      const response = await removeBookmark(careerId);
      if (response) {
        setOutline({ ...outline, isBookmarked: false });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const launchCareerProfile = (e, id) => {
    e.stopPropagation();
    history.push(`/careers/${encodeURIComponent(id)}/profile`);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleVideoModalOpen = (url) => {
    setVideoPlayerProps({ isOpen: true, videoUrl: url });
  };

  const handleVideoModalClose = () => {
    setVideoPlayerProps({ isOpen: false, videoUrl: '' });
  };

  return {
    careerId,
    outline,
    jobOutlook,
    personality,
    abilities,
    knowledge,
    skillsAndTechnology,
    similarJobs,
    isDataLoading,
    videoPlayerProps,
    goBack,
    handleBookmarkAdd,
    handleBookmarkRemove,
    launchCareerProfile,
    handleVideoModalOpen,
    handleVideoModalClose,
  };
};

export default useCareerProfileHook;
