import { AUTH_CONSTANTS } from '../../../../../auth/constants/auth-constants';

/**
 * Hook to manage the Face Validation Status.
 *
 * @param {number} imagesProcessed - The number of images processed.
 * @param {string} status - The status of the face validation.
 * @param {func} onContinue - The function to continue to the next step.
 * @param {func} onRetake - The function to retake the images.
 * @returns {object}
 */
const useFaceValidationStatusHook = ({ imagesProcessed, status, onContinue, onRetake }) => {
  const STEPS = [1, 2, 3, 4, 5];

  /**
   * Function to check if the step is completed.
   *
   * @param {number} index - The index of the step.
   * @returns {boolean} - True if the step is completed.
   */
  const stepCompleted = (index) => {
    return index < imagesProcessed;
  };

  /**
   * Function to check if the step is failed.
   *
   * @param {number} index - The index of the step.
   * @returns {boolean} - True if the step is failed.
   */
  const stepFailed = (index) => {
    return index === imagesProcessed && isStatusFailed();
  };

  /**
   * Function to check if the status is failed.
   *
   * @returns {boolean} - True if the status is failed.
   */
  const isStatusFailed = () => {
    return status === AUTH_CONSTANTS.FACE_VALIDATION_STATUS.FAILED;
  };

  /**
   * Function to handle the click event.
   * If the status is failed, it will call the onRetake function.
   * Otherwise, it will call the onContinue function.
   */
  const handleClick = () => {
    if (isStatusFailed()) {
      onRetake();
    } else {
      onContinue();
    }
  };

  return {
    STEPS,
    stepCompleted,
    stepFailed,
    isStatusFailed,
    handleClick,
  };
};

export default useFaceValidationStatusHook;
