export default (theme) => ({
  root: {
    borderRadius: 10,
    marginTop: 24,
    backgroundColor: theme.palette.aqua.extraDeep,
    backgroundImage: 'url("/assets/img/enroll_footer_mask.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
  },
  image: {
    borderRadius: '10px 0px 0px 10px',
    width: 265,
  },
  content: {
    padding: 24,
    borderRadius: '0px 10px 10px 0px',
    gap: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  title: {
    color: theme.palette.navy.main,
  },
  description: {
    color: theme.palette.primary.white,
    fontWeight: 700,
  },
  viewButton: {
    maxWidth: 360,
  },
});
