import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  backButtonStyling: {
    background: "#21458c",
    borderRadius: 120,
    color: "white",
    width: "125px",
    height: "48px",
    textTransform: "capitalize",
    boxShadow: "0px 1px 3px #9E9E9E",
    letterSpacing: "1.2px",
    fontSize: theme.typography.t18.fontSize,
    "&:hover": {
      background: "#21458c"
    }
  },
  launchBtn: {
    marginLeft: 10
  },
  launchButtonScrolled: {
    marginLeft: 0,
    marginTop: 10,
    display: "block"
  },
  earnTokenIcon: {
    ...theme.typography.t47,
    marginRight: 15
  },
  earnTokenTitle: {
    ...theme.typography.t20,
    ...theme.typography.medium,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
    color: theme.palette.primary.white
  },
  earnTokenSubTitle: {
    ...theme.typography.t14,
    textAlign: "left",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(255, 255, 255, 0.87)"
  },
  earnTokenButton: {
    width: 291,
    height: 68,
    color: theme.palette.primary.white,
    marginTop: 15,
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.darkBlue,
    "&:hover": {
      backgroundColor: theme.palette.primary.darkBlue
    }
  },
  assignedText: {
    fontSize: theme.typography.t10.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
    textTransform: "capitalize"
  },
  dateText: {
    textTransform: "uppercase"
  },
  backButtonIcon: {
    marginLeft: -25,
    marginRight: 12
  },
  textStyle: {
    fontWeight: 450
  },
  backButtonMargin: { marginTop: "46px" },
  containerStyling: {
    marginRight: "75px",
    marginBottom: 20
  },
  descriptionStyling: {
    height: "125px"
  },
  subText: {
    color: theme.palette.primary.battleshipGrey,
    fontSize: theme.typography.t12.fontSize
  },
  progressBarWrapper: {
    marginTop: 20
  },
  scrolledBackWrapper: {
    width: "100%",
    button: {
      width: "100%"
    }
  },
  collapsedBar: {
    minHeight: "150px !important",
    opacity: 0
  },
  root: {
    opacity: 1,
    minHeight: 300,
    width: "100%",
    padding: "0 50px",
    overflow: "auto",
    transition: "all 1s",
    background: theme.palette.primary.background,
    "> div": {
      background: theme.palette.primary.background
    }
  },
  container: {
    display: "flex"
  },
  paper: {
    margin: theme.spacing(1)
  },
  educationChip: {
    "background-color": "#cbe3fd",
    border: "1px solid #aec8e1",
    color: "#3f75a6"
  }
}));
