export default (theme) => ({
  root: {
    width: '100%',
    padding: 35,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.colors.borderGrey.dark}`,
    padding: '25px 35px',
  },
  mainTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  jobZonesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.colors.borderGrey.dark}`,
    paddingRight: 35,
  },
  jobZoneDetailsContainer: {
    paddingLeft: 35,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: 700,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  bolder: {
    fontWeight: 500,
  },
  mainDescription: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
  },
  zoneSubtitle: {
    display: 'flex',
  },
  content: {
    marginTop: 25,
  },
  zoneDetailsWrapper: {
    backgroundColor: '#F3F6F9',
    padding: 15,
    borderRadius: 5,
    height: '100%',
  },
  marginTop20: {
    marginTop: 20,
  },
  zoneBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
    margin: '5px 0px',
    width: 100,
  },
  outlinedBtnZone1: {
    borderColor: theme.palette.jobZone.colors.zone1,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone1,
  },
  outlinedBtnZone2: {
    borderColor: theme.palette.jobZone.colors.zone2,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone2,
  },
  outlinedBtnZone3: {
    borderColor: theme.palette.jobZone.colors.zone3,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone3,
  },
  outlinedBtnZone4: {
    borderColor: theme.palette.jobZone.colors.zone4,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone4,
  },
  outlinedBtnZone5: {
    borderColor: theme.palette.jobZone.colors.zone5,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone5,
  },
  filledBtnZone1: {
    borderColor: theme.palette.jobZone.colors.zone1,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone1,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone1,
    },
  },
  filledBtnZone2: {
    borderColor: theme.palette.jobZone.colors.zone2,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone2,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone2,
    },
  },
  filledBtnZone3: {
    borderColor: theme.palette.jobZone.colors.zone3,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone3,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone3,
    },
  },
  filledBtnZone4: {
    borderColor: theme.palette.jobZone.colors.zone4,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone4,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone4,
    },
  },
  filledBtnZone5: {
    borderColor: theme.palette.jobZone.colors.zone5,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone5,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone5,
    },
  },
});
