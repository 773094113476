export default (theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
    backgroundColor: theme.palette.primary.white,
    height: '100%',
    width: '100%',
    padding: 20,
    minHeight: 275,
    maxHeight: 350,
    borderRadius: 10,
  },
  carousel: {
    width: '100%',
    padding: 0,
  },
  carouselItem: {
    display: 'flex',
    padding: '0 20px',
    marginBottom: 30,
  },
  thumbnailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px 10px 20px',
  },
  cardTitle: {
    ...theme.typography.t24,
    letterSpacing: '0px',
    lineHeight: '32px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    fontWeight: 700,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    padding: 0,
    fontFamily: theme.typography.secondaryFont,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  actionBtn: {
    borderRadius: 100,
    padding: '12px 20px',
    '&$serviceUrlBtn': {
      ...theme.typography.t20,
      ...theme.typography.bold,
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '& svg': {
      marginLeft: 8,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
    },
  },
  serviceUrlBtn: {
    // need this for the class to show up
  },
  actionBox: { width: 'fit-content' },
  playIcon: {
    backgroundColor: 'white',
    borderRadius: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main1,
    fontSize: '5rem',
  },
  opacity: {
    opacity: 0.6,
  },
  description: {
    color: theme.palette.text.sub,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    overflow: 'hidden',
  },
});
