import React from 'react';

const EnvelopeSolidIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.17276 0.575684C1.12822 0.575684 0.280762 1.41553 0.280762 2.45068C0.280762 3.04053 0.560619 3.59521 1.03756 3.95068L9.6146 10.3257C10.0639 10.6577 10.6788 10.6577 11.1282 10.3257L19.7052 3.95068C20.1822 3.59521 20.462 3.04053 20.462 2.45068C20.462 1.41553 19.6146 0.575684 18.57 0.575684H2.17276ZM0.280762 4.95069V13.0757C0.280762 14.4546 1.41202 15.5757 2.80342 15.5757H17.9394C19.3308 15.5757 20.462 14.4546 20.462 13.0757V4.95069L11.885 11.3257C10.9863 11.9937 9.7565 11.9937 8.8578 11.3257L0.280762 4.95069Z"
        fill={fill}
      />
    </svg>
  );
};

EnvelopeSolidIcon.defaultProps = {
  width: 21,
  height: 16,
  fill: 'inherit',
};

export default EnvelopeSolidIcon;
