import React from 'react';
import PropTypes from 'prop-types';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { Constant } from '../../../../../../../shared/services';
import useBiometricAuthRegistrationHook from './useBiometricAuthRegistrationHook';
import Introduction from '../introduction/introduction';
import Instructions from '../instructions/instructions';
import CaptureImage from '../capture-image/capture-image';
import ReviewImages from '../review-images/review-images';
import { AUTH_CONSTANTS } from '../../../../../auth/constants/auth-constants';
import { Loader } from '@orijinworks/frontend-commons';

/**
 * Component to handle the Biometric Authentication Registration.
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const BiometricAuthRegistration = ({ username, onComplete }) => {
  const {
    playVideo,
    isLoading,
    imagesProcessed,
    nextStep,
    handleVideoPlay,
    handleVideoClose,
    showIntroduction,
    showInstructions,
    showCaptureImage,
    showReviewImages,
    processCapturedImage,
    handleRegistrationComplete,
  } = useBiometricAuthRegistrationHook();

  return (
    <>
      <ModalVideo
        channel="custom"
        isOpen={playVideo}
        url={`${Constant.CDN_BASE_URL}/assets/videos/Biometric_Authentication_Final_2022.mp4`}
        autoPlay
        onClose={handleVideoClose}
      />

      <Loader isLoading={isLoading} />

      {showIntroduction() && <Introduction username={username} onPlayVideo={handleVideoPlay} onContinue={nextStep} />}

      {showInstructions() && <Instructions onContinue={nextStep} />}

      {showCaptureImage() && (
        <CaptureImage
          onProcessImage={processCapturedImage}
          onContinue={handleRegistrationComplete}
          imageProcessingCount={AUTH_CONSTANTS.FACE_REGISTRATION_IMAGE_COUNT}
          showStepsOnStatus
        />
      )}

      {showReviewImages() && <ReviewImages images={imagesProcessed} onContinue={onComplete} />}
    </>
  );
};

BiometricAuthRegistration.propTypes = {
  /**
   * The username of the user.
   */
  username: PropTypes.string.isRequired,
  /**
   * The onComplete function.
   */
  onComplete: PropTypes.func.isRequired,
};

export default BiometricAuthRegistration;
