export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    ...theme.typography.bold,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily,
    paddingBottom: 10,
    textTransform: 'capitalize',
  },
  description: {
    ...theme.typography.t14,
    ...theme.typography.regular,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '21px',
  },
  actionButton: {
    lineHeight: '28px',
    letterSpacing: '1px',
    textTransform: 'capitalize',
    paddingLeft: 35,
    paddingRight: 35,
  },
  inProgressContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  maxWidth: {
    maxWidth: 450,
  },
  inProgressContentBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconBox: {
    background: theme.palette.colors.lightGray,
    borderRadius: 8,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    width: 150,
  },
  iconImg: {
    width: 60,
  },
  inProgressTextArea: {
    paddingLeft: 15,
  },
});
