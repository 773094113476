export default (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: 10,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  body: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
  },
});
