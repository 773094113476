import { useState, useEffect } from 'react';
import {
  CAREER_COMPASS_CONSTANTS,
  fetchCurrentStep,
  fetchPrograms,
  enrollIntoProgram,
} from '../../services/career-compass.service';
import useCareerCompassHook from '../../containers/career-compass/useCareerCompassHook';

const useProgramSelectionHook = (currentStep, selectedLocale) => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [stepTitle, setStepTitle] = useState('title');
  const [stepDescription, setStepDescription] = useState('description');
  const [programs, setPrograms] = useState([]);
  const [hasSelectedProgram, setHasSelectedProgram] = useState(false);
  const { handleCurrentStepUpdate } = useCareerCompassHook(currentStep);
  const [isFirstProgram, setIsFirstProgram] = useState(true);

  useEffect(() => {
    if (selectedProgram == null && programs.length > 0) {
      setSelectedProgram(programs[0].id);
    }
  }, [programs]);

  const handleProgramSelect = (programId) => {
    setSelectedProgram(programId);
  };

  /**
   * Effect to fetch the current step and check if the user is a returning user.
   */
  useEffect(() => {
    const getCurrentStep = async () => {
      try {
        const currentStep = await fetchCurrentStep();
        setStepTitle(currentStep.title);
        setStepDescription(currentStep.description);
        setIsFirstProgram(currentStep.isFirstTime);
        if (currentStep.selectedProgramId && currentStep.state !== CAREER_COMPASS_CONSTANTS.STEP_STATE.COMPLETED) {
          setSelectedProgram(parseInt(currentStep.selectedProgramId));
          setHasSelectedProgram(true);
        }
      } catch (error) {
        console.error('Error fetching current step', error);
      }
    };

    getCurrentStep();
  }, [selectedLocale]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedPrograms = await fetchPrograms(false);
        setPrograms(fetchedPrograms);
      } catch (error) {
        console.error('Error fetching programs', error);
      }
    };

    fetchData();
  }, [selectedLocale]);

  const handleEnrollAndNavigate = async (programId, programName) => {
    try {
      await enrollIntoProgram(programId);
      await handleCurrentStepUpdate(CAREER_COMPASS_CONSTANTS.STEP_STATE.IN_PROGRESS, {
        programId: programId,
        programName: programName,
      });
    } catch (error) {
      console.error('Error enrolling into program:', error);
    }
  };

  return {
    selectedProgram,
    handleProgramSelect,
    stepTitle,
    stepDescription,
    handleEnrollAndNavigate,
    programs,
    hasSelectedProgram,
    isFirstProgram,
  };
};

export default useProgramSelectionHook;
