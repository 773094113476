export default (theme) => ({
  hidden: {
    display: 'none',
  },
  container: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  capturedImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  webcam: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    background: 'black',
  },
  animationContainer: {
    position: 'absolute',
    top: 'calc(40% - 300px)',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  animationText: {
    color: theme.palette.primary.white,
    textAlign: 'center',
  },
  timerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  timerIcon: {
    position: 'relative',
    top: 'calc(40% - 300px)',
    marginLeft: 'auto',
    marginRight: 150,
    display: 'block',
    width: 80,
  },
  timerCount: {
    textShadow: '0 2px 10px rgba(0, 0, 0, 0.5)',
    fontSize: 330,
    fontWeight: 300,
    letterSpacing: '7.48px',
    color: theme.palette.primary.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cameraFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'url(https://content.connectedcorrections.com/assets/img/camera-frame.png)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: '50% 40%',
    transform: 'scale(1, 0.75)',
  },
  cameraFilter: {
    background: theme.palette.primary.white,
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    height: '60vh',
    width: '70vw',
    opacity: 0.05,
  },
  startButtonContainer: {
    position: 'absolute',
    bottom: 'calc(40% - 150px)',
    left: 0,
    width: '100%',
  },
  startButton: {
    width: 354,
    height: 73,
    margin: '40px auto 0px auto !important',
    display: 'flex !important',
    textTransform: 'capitalize !important',
    minHeight: 50,
    fontSize: '24px !important',
  },
  instructionContainer: {
    position: 'absolute',
    bottom: 'calc(40% - 300px)',
    maxWidth: 600,
    textAlign: 'center',
  },
  instructions: {
    fontSize: 20,
    letterSpacing: '0.59px',
    color: theme.palette.primary.white,
    transform: 'translate(-50%, -50%)',
    margin: 0,
  },
  errorMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 26,
    letterSpacing: '0.59px',
    textAlign: 'center',
  },
});
