import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import StarsIcon from '@material-ui/icons/Stars';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import styles from './styles';

const Activity = (props) => {
  const { classes } = props;
  const [cardShown, showCard] = React.useState(false);
  const intl = useIntl();

  React.useEffect(() => {
    setTimeout(() => {
      showCard(true);
    }, 500);
  });

  return (
    <Box
      data-cy-name={`activity-${props.meta.ParentId}-${props.meta.id}`}
      className={[classes.card, cardShown ? classes.showCard : null, props.fullWidth ? classes.fullWidth : null].join(' ')}
    >
      <CardContent onClick={props.onClick} className={classes.cardContent}>
        <Grid container alignItems="center" item className={classes.cardHead}>
          <Grid item sm={6}>
            {props.meta.Category}
          </Grid>
          <Grid item sm={6} container direction="row" alignItems="center" justify="flex-end">
            {/* BEGIN: REWARD */}
            {props.meta.Reward && (
              <>
                <StarsIcon fontSize="small" />
                <Typography className={classes.carouselCardReward} align="center" color="primary">
                  {props.meta.Reward}
                </Typography>
              </>
            )}
            {/* END: REWARD */}
          </Grid>
        </Grid>
        <Grid className={classes.cardTextContainer}>
          {props.meta.MainText && (
            <>
              <Grid item sm={12}>
                <Box component="div" className={classes.textWrapper}>
                  <Typography className={[classes.cardHeading, props.classes.cardHeading].join(' ')}>
                    {props.meta.MainText}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}

          {props.meta.SubText && (
            <>
              <Grid item sm={12}>
                <Box component="div">
                  <Typography className={classes.cardSubheading}>{props.meta.SubText}</Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActionWrapper}>
        {props.meta.date && <Box padding="0px 20px">{props.meta.date}</Box>}
        {props.launchHandler && (
          <Button
            onClick={props.launchHandler}
            className={classes.launchBtn}
            aria-label={intl.formatMessage({ id: 'launch' })}
          >
            <FormattedMessage id="launch" />
          </Button>
        )}
      </CardActions>
    </Box>
  );
};

export default withStyles(styles)(withRouter(Activity));
