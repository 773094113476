import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './content-carousel.styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FEATURED_CONTENT_CONSTANTS } from '../../services/featured-contents.service';
import CustomDot from '../custom-dot/custom-dot';
import HorizontalCard from '../../../../../shared/components/horizontal-card/horizontal-card';

/**
 * Component to display the content carousel
 *
 * @param {object} props - component props
 * @param {object} props.classes - classes that will be injected by withStyles HOC
 * @param {array} props.contents - list of featured contents
 * @param {function} props.handleContentClick - function to handle the click on the content
 * @param {function} props.handleVideoPlayerOpen - function to open the video player
 * @returns {React.Component} - ContentCarousel component
 */
const ContentCarousel = ({ classes, contents, handleContentClick, handleVideoPlayerOpen }) => (
  <Carousel
    className={classes.carousel}
    itemClass={classes.carouselItem}
    responsive={FEATURED_CONTENT_CONSTANTS.CAROUSEL_RESPONSIVE_PROPS}
    partialVisbile
    arrows={false}
    autoPlay={false}
    autoPlaySpeed={FEATURED_CONTENT_CONSTANTS.CAROUSEL_AUTOPLAY_SPEED}
    showDots
    customDot={<CustomDot classes={classes} />}
  >
    {contents.map((content) => (
      <HorizontalCard
        key={content.id}
        title={content.title}
        description={content.description}
        image={content.thumbnail}
        video={content.video}
        actionUrl={content.launchUrl}
        buttonTitle="launch"
        onCardClick={() => {
          handleContentClick(content.launchUrl);
        }}
        openVideo={handleVideoPlayerOpen}
      />
    ))}
  </Carousel>
);

ContentCarousel.propTypes = {
  /**
   * Classes that will be injected by withStyles HOC
   */
  classes: PropTypes.object.isRequired,
  /**
   * List of featured contents
   */
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      thumbnail: PropTypes.string,
      video: PropTypes.string,
      launchUrl: PropTypes.string,
      slide: PropTypes.number,
    })
  ).isRequired,
  /**
   * Function to handle the click on the content
   */
  handleContentClick: PropTypes.func.isRequired,
  /**
   * Function to open the video player
   */
  handleVideoPlayerOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContentCarousel);
