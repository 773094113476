import { useRef } from 'react';
import { Utility } from '../../services';

const useToggleSwitchHook = (props) => {
  const containerRef = useRef(null);

  const handleSwitchFocus = () => {
    containerRef.current.focus();
  };

  const handleKeyPress = (e) => {
    Utility.handleEnterKey(e, props.onChange);
  };

  return {
    containerRef,
    handleSwitchFocus,
    handleKeyPress,
  };
};

export default useToggleSwitchHook;
