import React from 'react';
import { withStyles, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import styles from './careerPathCategory.style';
import { LinkButton } from '@orijinworks/frontend-commons';
import { FormattedMessage, useIntl } from 'react-intl';

const CareerPathCategory = (props) => {
  const { classes, title, programs, launchProgram } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Typography id="career-path-category-title" variant="h3" className={classes.mainTitle}>
        {title}
      </Typography>
      {programs?.length > 0 && (
        <Box id="career-path-category-programs-container" className={classes.programsContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell className={[classes.tableHeaderCell, classes.width80p].join(' ')}>
                  <Typography variant="h4" className={classes.tableColumnText}>
                    <FormattedMessage id="program" />
                  </Typography>
                </TableCell>
                <TableCell className={[classes.tableHeaderCell, classes.width20p].join(' ')}>
                  <Typography variant="h4" className={classes.tableColumnText}>
                    <FormattedMessage id="enrollment" />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programs.map((program, index) => (
                <TableRow key={program.id} className={index % 2 === 0 ? classes.evenRow : classes.oddRow}>
                  <TableCell className={[classes.tableBodyCell, classes.width80p].join(' ')}>
                    <Box className={classes.buttonContainer}>
                      <Typography className={classes.tableRowText}>{program.title}</Typography>
                      <LinkButton
                        id={`career-path-launch-program-${program.id}-btn`}
                        size="large"
                        className={[classes.launchButton, classes.careerPathBtn].join(' ')}
                        onClick={() => {
                          launchProgram(program.id);
                        }}
                        variant="text"
                        aria-label={intl.formatMessage({ id: 'launchProgram' })}
                      >
                        <FormattedMessage id="launch" />
                      </LinkButton>
                    </Box>
                  </TableCell>
                  <TableCell className={[classes.tableBodyCell, classes.width20p].join(' ')}>
                    <Typography
                      id={`career-path-program-${program.id}-enrollment-status`}
                      className={[
                        classes.tableRowText,
                        program.enrollmentState === 'ENROLLED' ? '' : classes.disabledText,
                      ].join(' ')}
                    >
                      {program.enrollmentState === 'ENROLLED' ? (
                        <FormattedMessage id="enrolled" />
                      ) : (
                        <FormattedMessage id="notEnrolled" />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(CareerPathCategory);
