export default theme => ({
	root: {
		display: "flex",
		justifyContent: "center",
		height: "calc(100% - 100px)"
	},
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		textAlign: "center"
	},
	iconContainer: {
		fill: theme.palette.primary.sub
	}
});