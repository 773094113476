import { useState } from 'react';
import { Constant } from '../../../../../../../shared/services';

/**
 * Hook to handle the review images.
 *
 * @param {array} images - The images to review
 * @returns {object}
 */
const useReviewImagesHook = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const FACE_ANGLE_IMAGES = ['face-front.png', 'face-left.png', 'face-right.png', 'face-up.png', 'face-down.png'];

  /**
   * Function to get the current image.
   *
   * @returns {string} - The current image
   */
  const getCurrentImage = () => {
    return images[currentImageIndex];
  };

  /**
   * Function to get the current image angle.
   *
   * @returns {string} - The current image angle
   */
  const getCurrentImageAngle = () => {
    return `${Constant.CDN_BASE_URL}/assets/img/${FACE_ANGLE_IMAGES[currentImageIndex]}`;
  };

  /**
   * Function to move to the next image.
   */
  const nextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prev) => prev + 1);
    }
  };

  /**
   * Function to move to the previous image.
   */
  const previousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prev) => prev - 1);
    }
  };

  /**
   * Function to disable the previous button.
   *
   * @returns {boolean} - The flag to disable the previous button
   */
  const disablePreviousButton = () => {
    return currentImageIndex === 0;
  };

  /**
   * Function to disable the next button.
   *
   * @returns {boolean} - The flag to disable the next button
   */
  const disableNextButton = () => {
    return currentImageIndex === images.length - 1;
  };

  return {
    getCurrentImage,
    getCurrentImageAngle,
    nextImage,
    previousImage,
    disablePreviousButton,
    disableNextButton,
  };
};

export default useReviewImagesHook;
