import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import styles from './pdq-assessment-intro.style';
import { Constant } from '../../../../shared/services';
import { BookOpenCoverIcon } from '../../../../shared/icons';
import TopBanner from '../../components/top-banner/top-banner';
import { PDQAssessmentService } from '../../services/pdq-assessment.service';
import { DocumentTitle } from '../../../../v2/shared';
import { Permission } from '../../../../core';

export class PDQAssessmentIntro extends React.Component {
  _pdqService = new PDQAssessmentService();
  constructor(props) {
    super(props);
    this.state = {
      openInfoModal: false,
    };
  }

  onContinue = () => {
    this._pdqService.launchPDQAssessment(() => {
      this.setState({ openInfoModal: true });
    });
  };

  onCancel = () => {
    this.props.history.push('/home');
  };

  handleInfoModalClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      this.setState({ openInfoModal: false });
    }
  };

  redirectToHomepage = () => {
    this.props.history.push('/home');
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <>
        <DocumentTitle title="page.title.milestone-mapper" />
        <Permission>
          <Box className={classes.root}>
            <Dialog open={this.state.openInfoModal} onClose={this.handleInfoModalClose}>
              <Box className={classes.modalContent}>
                <Typography gutterBottom>
                  <FormattedMessage id="pdq-assessment.relaunch.info-message.text.info-page" />
                </Typography>
                <Button
                  color="primary"
                  onClick={this.redirectToHomepage}
                  className={classes.modalButton}
                  aria-label={intl.formatMessage({ id: 'clickHereToGoBackToHome' })}
                >
                  <FormattedMessage id="clickHereToGoBackToHome" />
                </Button>
              </Box>
            </Dialog>

            <TopBanner>
              <Box className={classes.header}>
                <Box className={classes.headerContent}>
                  <Typography variant="h1" className={classes.title} gutterBottom>
                    <FormattedMessage id="milestoneMapper" />
                  </Typography>
                  <Button variant="contained" tabIndex={-1} className={classes.readOnlyButton}>
                    <Typography className={[classes.boldText, classes.flexCenteredContent].join(' ')}>
                      <AvTimerIcon className={classes.marginRight5} /> 15 mins
                    </Typography>
                    <Typography className={[classes.boldText, classes.horizontalMargin10].join(' ')}>|</Typography>
                    <Typography className={[classes.boldText, classes.flexCenteredContent, classes.iconFill].join(' ')}>
                      <BookOpenCoverIcon className={classes.marginRight5} /> 33 Questions
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </TopBanner>

            <Box className={classes.body}>
              <Typography id="pdq-assessment-intro" gutterBottom>
                <FormattedMessage id="pdq-assessment.intro" />
              </Typography>

              <Typography id="pdq-assessment-what-does-it-do" gutterBottom className={classes.sectionTitle}>
                <FormattedMessage id="pdq-assessment.what-does-it-do" />
              </Typography>

              <Typography id="pdq-assessment-what-does-it-do-text" gutterBottom>
                <FormattedMessage
                  id="pdq-assessment.what-does-it-do.text"
                  values={{
                    p: (...chunks) => <p>{chunks}</p>,
                    ul: (...chunks) => <ul>{chunks}</ul>,
                    li: (...chunks) => <li>{chunks}</li>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    br: <br />,
                  }}
                />
              </Typography>

              <Box className={classes.actionBox}>
                <Button
                  id="pdq-assessment-cancel-btn"
                  variant="contained"
                  className={classes.closeBtn}
                  onClick={this.onCancel}
                  size="large"
                  tracking-type={Constant.TRACKING_TYPES.PDQ}
                  tracking-id="cancel-pdq-assement"
                  aria-label={intl.formatMessage({ id: 'pdq-assessment.btn.cancel' })}
                >
                  <FormattedMessage id="pdq-assessment.btn.cancel" />
                </Button>
                <Button
                  id="pdq-assessment-start-btn"
                  variant="contained"
                  className={classes.continueBtn}
                  onClick={this.onContinue}
                  size="large"
                  tracking-type={Constant.TRACKING_TYPES.PDQ}
                  tracking-id="open-pdq-assement"
                  aria-label={intl.formatMessage({ id: 'pdq-assessment.btn.start' })}
                >
                  <FormattedMessage id="pdq-assessment.btn.start" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Permission>
      </>
    );
  }
}

export default withStyles(styles)(injectIntl(PDQAssessmentIntro));
