import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react';

import { Closed } from '../../../../shared/components';
import { PlayScheduleDialog } from '../dialogs';
import styles from './closed.style';
import { FormattedMessage, useIntl } from 'react-intl';

const PlayClosed = (props) => {
  const { classes } = props;
  const [isDialogOpen, openDialog] = React.useState(false);
  const intl = useIntl();

  return (
    <Grid
      container
      item
      classes={{
        root: [classes.root, props.align === 'center' ? classes.center : null].join(' '),
      }}
    >
      <Closed {...props} />
      {/* BEGIN: FOOTER */}
      {props.viewScheduleButton && (
        <Grid container className={classes.closeFooter} direction="column" item>
          <Button
            onClick={() => openDialog(true)}
            className={classes.playScheduleBtn}
            data-cy-name="play-button"
            aria-label={intl.formatMessage({ id: 'viewSchedule' })}
          >
            {props.viewScheduleBtnText ? props.viewScheduleBtnText : <FormattedMessage id="viewPlaySchedule" />}
          </Button>
          {props.handleLogout && (
            <Button
              onClick={props.handleLogout}
              className={classes.playScheduleBtn}
              aria-label={intl.formatMessage({ id: 'menuSignout' })}
            >
              <FormattedMessage id="menuSignout" />
            </Button>
          )}
        </Grid>
      )}
      {/* END: FOOTER */}
      <PlayScheduleDialog
        setDialogVisibility={openDialog}
        isDialogOpen={isDialogOpen}
        schedule={props.schedule}
        title={props.title}
      />
    </Grid>
  );
};

export default withStyles(styles)(PlayClosed);
