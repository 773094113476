import React from 'react';

const LoudSpeakerIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7754 1.32591C19.7754 0.821999 19.468 0.364968 18.995 0.169655C18.522 -0.0256574 17.982 0.0837176 17.6193 0.439186L15.9008 2.14622C14.0088 4.02122 11.4428 5.07591 8.76637 5.07591H8.42345H7.16212H3.37813C1.98672 5.07591 0.855469 6.197 0.855469 7.57591V11.3259C0.855469 12.7048 1.98672 13.8259 3.37813 13.8259V18.8259C3.37813 19.5173 3.94178 20.0759 4.63946 20.0759H7.16212C7.85979 20.0759 8.42345 19.5173 8.42345 18.8259V13.8259H8.76637C11.4428 13.8259 14.0088 14.8806 15.9008 16.7556L17.6193 18.4587C17.982 18.8181 18.522 18.9236 18.995 18.7282C19.468 18.5329 19.7754 18.0798 19.7754 17.572V11.8103C20.5086 11.4665 21.0367 10.5407 21.0367 9.4509C21.0367 8.36106 20.5086 7.43528 19.7754 7.09153V1.32591ZM17.2528 4.322V9.4509V14.5798C14.9351 12.49 11.9118 11.3259 8.76637 11.3259H8.42345V7.57591H8.76637C11.9118 7.57591 14.9351 6.41184 17.2528 4.322Z"
        fill={fill}
      />
    </svg>
  );
};

LoudSpeakerIcon.defaultProps = {
  width: 22,
  height: 21,
  fill: 'inherit',
};

export default LoudSpeakerIcon;
