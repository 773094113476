import React from "react";

const SadFaceIcon = props => {
  const { size, fill, opacity } = props;

  return (
    <svg style={{opacity: opacity}} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 55 55">
      <defs>
        <clipPath id="clip-Sad_Face">
          <rect width="55" height="55"/>
        </clipPath>
      </defs>
      <g id="Sad_Face" data-name="Sad Face" clipPath="url(#clip-Sad_Face)">
        <path id="Path_92722" data-name="Path 92722" d="M0,27.531A27.531,27.531,0,1,1,27.531,55.062,27.529,27.529,0,0,1,0,27.531ZM17.132,41.8a10.8,10.8,0,0,1,10.4-7.388,10.8,10.8,0,0,1,10.4,7.388,1.721,1.721,0,0,0,3.291-1.011,14.409,14.409,0,0,0-27.381,0A1.721,1.721,0,0,0,17.132,41.8Zm1.839-22.875a3.441,3.441,0,1,0,3.441,3.441A3.438,3.438,0,0,0,18.971,18.928ZM36.178,25.81a3.441,3.441,0,1,0-3.441-3.441A3.445,3.445,0,0,0,36.178,25.81Z" fill={fill}/>
      </g>
    </svg>
  );
};

SadFaceIcon.defaultProps = {
  size: 24,
  opacity: 1,
  fill: "inherit"
}

export default SadFaceIcon;
