import React, { useState, useEffect, useCallback } from 'react';
import { BiometricAuthentication, BlockAccess, TermsOfService } from '../components';
import { jwtDecode } from 'jwt-decode';
import { AuthGuardsHttp } from '../services/auth-guards-http/auth-guards-http';
import { AUTH_CONSTANTS } from '../../auth/constants/auth-constants';
import { Loader } from '@orijinworks/frontend-commons';
import { persistUserDAT } from '../services/user-device-pairing/user-device-pairing.service';
import { FormattedMessage } from 'react-intl';
import { fetchAuthGuardsList } from '../services/auth-guards.service';
import { ThemeSwitcher } from '../../components';

/**
 * Map of auth guards components.
 */
const AUTH_GUARDS_COMPONENTS_MAP = {
  TERMS_OF_SERVICE_GUARD: TermsOfService,
  BIOMETRIC_AUTHENTICATION_GUARD: BiometricAuthentication,
};

/**
 * Controller for the AuthGuards component.
 * Responsible for rendering the auth guards components in sequence.
 *
 * @component
 * @param {Object} props - The props of the component
 * @param {Object} props.history - The history object
 */
const AuthGuardsController = ({ history }) => {
  const [authGuardsHttpInitialized, setAuthGuardsHttpInitialized] = useState(false);
  const [authPayload, setAuthPayload] = useState({});
  const [authGuards, setAuthGuards] = useState([]);
  const [currentAuthGuardIndex, setCurrentAuthGuardIndex] = useState(0);

  /**
   * Effect to extract the auth payload from the URL and initialize the AuthGuardsHttp.
   */
  useEffect(() => {
    const extractAndSetAuthPayload = () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('session_token');

        const tokenPayload = jwtDecode(token);
        setAuthPayload({
          stateParam: urlParams.get('state'),
          continueUri: tokenPayload.continue_uri,
          username: tokenPayload.preferred_username,
          name: tokenPayload.name,
          token,
        });

        new AuthGuardsHttp(token);
        setAuthGuardsHttpInitialized(true);
      } catch (error) {
        history.push('/home');
      }
    };

    extractAndSetAuthPayload();
  }, [history]);

  /**
   * Function to check if the action is to block access.
   *
   * @param {String} action - The action to check
   * @returns {Boolean} The flag to block access
   */
  const shouldBlockAccess = (action) => {
    return AUTH_CONSTANTS.AUTH_GUARDS_ACTION.BLOCK_ACCESS === action;
  };

  /**
   * Function to persist the user DAT if required.
   *
   * @param {String} action - The action to check
   * @param {String} userDAT - The user DAT to persist
   */
  const persistUserDATIfRequired = useCallback(
    (authGuard) => {
      if (
        AUTH_CONSTANTS.AUTH_GUARDS_LIST.USER_DEVICE_PAIRING === authGuard.guard &&
        AUTH_CONSTANTS.AUTH_GUARDS_ACTION.PERSIST === authGuard.action
      ) {
        persistUserDAT({ username: authPayload.username, DAT: authGuard.payload.userDAT, name: authPayload.name });
      }
    },
    [authPayload.username, authPayload.name]
  );

  /**
   * Function to prepare the auth guards.
   *
   * @param {Array} authGuardsResponse - The auth guards response
   * @returns {Array} The array of auth guards components
   */
  const prepareAuthGuards = useCallback(
    (authGuardsResponse) => {
      let guards = [];
      authGuardsResponse.forEach((item) => {
        if (shouldBlockAccess(item.action)) {
          guards = [
            {
              Component: BlockAccess,
              props: prepareBlockAccessProps(item.guard),
            },
          ];
        } else {
          persistUserDATIfRequired(item);

          const Component = AUTH_GUARDS_COMPONENTS_MAP[item.guard];
          if (Component) {
            guards.push({ Component, props: { username: authPayload.username, action: item.action } });
          }
        }
      });
      return guards;
    },
    [persistUserDATIfRequired, authPayload.username]
  );

  /**
   * Function to redirect back to Auth0 with the result.
   *
   * @param {String} status - The status of the auth guards
   * @param {String} message - The message to send back to Auth0
   */
  const redirectBackToAuth0Action = useCallback(
    (status, message) => {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${authPayload.continueUri}?state=${authPayload.stateParam}&redirect_uri=${window.location.origin}`;

      const payloadInput = document.createElement('input');
      payloadInput.type = 'hidden';
      payloadInput.name = 'payload';
      payloadInput.value = JSON.stringify({ status, message });

      form.appendChild(payloadInput);
      document.body.appendChild(form);
      form.submit();
    },
    [authPayload.continueUri, authPayload.stateParam]
  );

  /**
   * Function to fetch the auth guards from the server.
   */
  const fetchAuthGuards = useCallback(async () => {
    try {
      const response = await fetchAuthGuardsList(authPayload.username);
      const guards = prepareAuthGuards(response);
      if (guards.length > 0) {
        setAuthGuards(guards);
      } else {
        redirectBackToAuth0Action(AUTH_CONSTANTS.AUTH_GUARDS_RESULT.GRANT_ACCESS, 'Success!');
      }
    } catch (error) {
      const message = error?.message ?? 'Error fetching auth guards';
      redirectBackToAuth0Action(AUTH_CONSTANTS.AUTH_GUARDS_RESULT.DENY_ACCESS, message);
    }
  }, [prepareAuthGuards, authPayload.username, redirectBackToAuth0Action]);

  /**
   * Effect to fetch the auth guards from the server.
   */
  useEffect(() => {
    if (authGuardsHttpInitialized) {
      fetchAuthGuards();
    }
  }, [authGuardsHttpInitialized, fetchAuthGuards]);

  /**
   * Function to prepare the props for the BlockAccess component.
   *
   * @param {String} guard - The guard to prepare the props for
   * @returns {Object} The props for the BlockAccess component
   */
  const prepareBlockAccessProps = (guard) => {
    let props = {};

    switch (guard) {
      case AUTH_CONSTANTS.AUTH_GUARDS_LIST.NETWORK_GUARD:
        props = {
          title: <FormattedMessage id="block-access.title.network" />,
          subtitle: <FormattedMessage id="block-access.subtitle.network" />,
          message: <FormattedMessage id="block-access.message.network" />,
        };
        break;
      case AUTH_CONSTANTS.AUTH_GUARDS_LIST.LEARNER_PORTAL_ACCESS:
        props = {
          subtitle: <FormattedMessage id="block-access.subtitle.learner-portal-access" />,
          message: <FormattedMessage id="block-access.message.learner-portal-access" />,
        };
        break;
      case AUTH_CONSTANTS.AUTH_GUARDS_LIST.USER_DEVICE_PAIRING:
        props = {
          subtitle: <FormattedMessage id="block-access.subtitle.user-device-pairing" />,
          message: <FormattedMessage id="block-access.message.user-device-pairing" />,
        };
        break;
      default:
    }

    return props;
  };

  /**
   * Function to handle the completion of the auth guard.
   * Increments the current auth guard index to render the next auth guard.
   * If all auth guards are completed, redirects back to Auth0 with the result.
   */
  const handleComplete = () => {
    if (currentAuthGuardIndex === authGuards.length - 1) {
      redirectBackToAuth0Action(AUTH_CONSTANTS.AUTH_GUARDS_RESULT.GRANT_ACCESS, 'Success!');
    }
    setCurrentAuthGuardIndex((prevIndex) => prevIndex + 1);
  };

  /**
   * Function to handle the error in the auth guard.
   * Redirects back to Auth0 with the result.
   *
   * @param {String} error - The error message
   */
  const handleError = (error) => {
    redirectBackToAuth0Action(AUTH_CONSTANTS.AUTH_GUARDS_RESULT.DENY_ACCESS, error);
  };

  /**
   * Function to render the auth guard component.
   */
  const renderAuthGuardComponent = () => {
    if (currentAuthGuardIndex < authGuards.length) {
      const AuthGuard = authGuards[currentAuthGuardIndex];
      return <AuthGuard.Component onComplete={handleComplete} onError={handleError} {...AuthGuard.props} />;
    }
    return <Loader />;
  };

  return (
    <>
      <ThemeSwitcher />
      {renderAuthGuardComponent()}
    </>
  );
};

export default AuthGuardsController;
