import React from 'react';
import {
  AchievementsDashboard,
  Copyrights,
  ExploreList,
  FormList,
  ProgramListing,
  ProgramDetails,
  PlayMain,
  PrivacyPolicy,
  Terms,
  UserAgreement,
  Messaging,
  Home,
  CareerDirectory,
  CareerProfile,
  Bookmarks,
  Transcript,
  InterestProfilerQuestionnaire,
  MyProfile,
  PDQAssessmentIntro,
  PDQAssessmentResults,
  CareerPaths,
  CareerPathDetails,
  VoiceCall,
} from '../../../content';
//INDIVIDUALLY DEFINING AS ITS NOT GLOBALLY EXPORTED DUE
// TO THE FACT THAT THIS COMPONENT IS COPIED FROM
// MESSAGING AND CAUSING DUPLICATE ASSIGNMENT ISSUES
// REASON: MVP TIME CONSTRAINT
import { FriendsFamilyMessaging } from '../../../content/friends-family/';
import { Utility, NotFound } from '../../../shared';
import { ResumeBuilder, ResumePreview } from '../../../v2/content';
import LoginChangeLanguage from '../../../v2/core/components/login/loginChangeLanguage';
import { lazy } from 'react';

const ClaimAccount = lazy(() => import('../../../v2/content/login/claim-account'));
const AuthGuardsController = React.lazy(() =>
  import('../../../v2/core').then((module) => ({ default: module.AuthGuardsController }))
);
const CareerCompass = React.lazy(() =>
  import('../../../v2/content/career-compass').then((module) => ({ default: module.CareerCompass }))
);

export class Routes {
  utilityService = new Utility();
  /**
   * @name privateRoutes
   * @param {array} featureStatusCollection Collection of features.
   * @return {JSX[]}
   */
  privateRoutes = (featureStatusCollection) => {
    const routes = [
      {
        path: '/home',
        component: Home,
        status: true,
      },
      {
        path: '/bookmarks',
        component: Bookmarks,
        status: true,
      },
      {
        path: '/interest-profiler',
        component: InterestProfilerQuestionnaire,
      },
      {
        path: '/programs/:id/details',
        component: ProgramDetails,
        status: true,
        hideTitle: true,
      },
      {
        path: '/programs',
        component: ProgramListing,
        status: true,
      },
      {
        path: '/careers/:id/profile',
        component: CareerProfile,
        status: true,
      },
      {
        path: '/careers',
        component: CareerDirectory,
        status: true,
      },
      {
        path: '/achievements',
        component: AchievementsDashboard,
        status: true,
      },
      {
        path: '/transcript',
        component: Transcript,
        status: true,
      },
      {
        path: '/explore',
        component: ExploreList,
        status: true,
      },
      {
        path: '/entertain',
        component: PlayMain,
        status: this.utilityService.checkStatusExists(featureStatusCollection, 'Play'),
      },
      {
        path: '/forms',
        component: FormList,
        status: this.utilityService.checkStatusExists(featureStatusCollection, 'forms'),
      },
      {
        path: '/messaging',
        component: Messaging,
      },
      {
        path: '/friends-family',
        component: FriendsFamilyMessaging,
      },
      {
        path: '/my-profile',
        component: MyProfile,
      },
      {
        path: '/pdq-assessment/intro',
        component: PDQAssessmentIntro,
      },
      {
        path: '/pdq-assessment/results',
        component: PDQAssessmentResults,
      },
      {
        path: '/career-paths/:id/details',
        component: CareerPathDetails,
      },
      {
        path: '/career-paths',
        component: CareerPaths,
      },
      {
        path: '/voice-call',
        component: VoiceCall,
        status: this.utilityService.checkStatusExists(featureStatusCollection, 'Voice_Calls'),
      },
      {
        path: '/resume-builder',
        component: ResumeBuilder,
        status: true,
      },
      {
        path: '/resume-preview',
        component: ResumePreview,
        status: true,
      },
      {
        path: '/resume-builder',
        component: ResumeBuilder,
        status: true,
      },
      {
        path: '/resume-preview',
        component: ResumePreview,
        status: true,
      },
      {
        path: '/career-compass',
        component: CareerCompass,
        status: true,
      },
      {
        path: '/404',
        component: NotFound,
      },
    ];

    return routes;
  };

  /**
   * @name publicRoutes
   * @property GET
   * @return {JSX[]}
   */
  get publicRoutes() {
    return [
      {
        path: '/useragreement',
        component: UserAgreement,
      },
      {
        path: '/termsofuse',
        component: Terms,
      },
      {
        path: '/copyright',
        component: Copyrights,
      },
      {
        path: '/privacypolicy',
        component: PrivacyPolicy,
      },
      {
        path: '/claimaccount',
        component: ClaimAccount,
      },
      {
        path: '/login/change-language/:locale',
        component: LoginChangeLanguage,
      },
      {
        path: '/auth-guards',
        component: AuthGuardsController,
      },
    ];
  }

  /**
   * @name getRoutes
   * @param {string} type Type of routes required.
   * @param {array} featureStatusCollection Collection of features. Only required for private routes.
   * @desc Returns routes based upon type passed to it.
   * @return {JSX[]}
   */
  getRoutes = (type, featureCollection) => {
    switch (type) {
      case 'private':
        return this.privateRoutes(featureCollection);

      case 'public':
        return this.publicRoutes;

      default:
        return null;
    }
  };
}
