export default theme => ({
  mainDialog: {
    width: 293,
    maxWidth: "100%",
    padding: 20
  },
  playIcon: {
    color: theme.palette.primary.battleshipGrey,
    marginBottom: 5
  },
  dialogTitle: {
    ...theme.typography.t16,
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "24px",
    letterSpacing: "0.15px"
  },
  bodyText: {
    ...theme.typography.t14,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "10px 0px"
  },
  darkText: {
    ...theme.typography.bold,
    ...theme.typography.t14,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: theme.palette.primary.jetBlack
  },
  darkTextValue: {
    ...theme.typography.t14,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: theme.palette.primary.jetBlack
  },
  keyValuePairText: {
    margin: "10px 0px"
  },
  darkTextSubValue: {
    ...theme.typography.t12,
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: theme.palette.primary.jetBlack
  },
  cartWrapper: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
    margin: "15px 0px"
  },
  tokenValue: {
    ...theme.typography.medium,
    ...theme.typography.t18,
    color: theme.palette.primary.black,
    lineHeight: "28px",
    letterSpacing: "0.5px"
  },
  tokenIcon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.black
  },
  tokenText: {
    ...theme.typography.t18,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: theme.palette.primary.black
  },
  dialogFooter: {
    marginTop: 60
  },
  confirmBtn: {
    textTransform: "capitalize",
    ...theme.typography.t18,
    ...theme.typography.bold,
    lineHeight: "16px",
    letterSpacing: "1px"
  }
});
