import React from 'react';
import { Box, Button, Dialog, DialogContent, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './confirm-unenroll-dialog.style';
import { Constant } from '../../../../shared/services';

const ConfirmUnenrollDialog = (props) => {
  const { classes, open, programId, onClose, onConfirm } = props;
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <Typography id="confirm-unenroll-title" color="secondary" variant="h3" className={classes.boldText} gutterBottom>
          <FormattedMessage id="confirmUnenrollMsg" />
        </Typography>
        <Box marginTop="20px">
          <Button
            id="confirm-unenroll-button"
            variant="contained"
            color="primary"
            size="large"
            className={[classes.dialogBtn, classes.confirmBtn].join(' ')}
            onClick={onConfirm}
            tracking-type={Constant.TRACKING_TYPES.PROGRAM}
            tracking-id={`unenroll-program-${programId}`}
            aria-label={intl.formatMessage({ id: 'yesUnenrollMe' })}
          >
            <FormattedMessage id="yesUnenrollMe" />
          </Button>
          <Button
            id="cancel-unenroll-button"
            variant="outlined"
            color="primary"
            size="large"
            className={[classes.dialogBtn, classes.cancelBtn].join(' ')}
            onClick={onClose}
            tracking-type={Constant.TRACKING_TYPES.PROGRAM}
            tracking-id={`cancel-unenroll-program-${programId}`}
            aria-label={intl.formatMessage({ id: 'noGoBack' })}
          >
            <FormattedMessage id="noGoBack" />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmUnenrollDialog);
