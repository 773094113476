import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { TimeRemaining } from '../../../../shared/containers';
import { RedeemDialog } from '../dialogs';
import styles from './has-schedule.style';
import { Utility } from '../../../../shared/services';

const HasSchedule = (props) => {
  const utilityService = new Utility();
  const { classes, tabName } = props;
  const [isRedeemDialogOpen, setDialogVisiblity] = React.useState(false);
  const intl = useIntl();

  return (
    <Grid container item>
      <Grid container item className={[props.className].join(' ')}>
        {/* <Grid container item>
          <Typography data-cy-name="content-title" className={classes.title}>
            <FormattedMessage
              id="enjoyText"
              values={{
                tabName: (
                  <FormattedMessage
                    id={`${props.tabName.toLowerCase()}Lower`}
                  />
                )
              }}
            />
          </Typography>
        </Grid> */}
        <Grid data-cy-name="timer-schedule" container item className={classes.timerWrapper}>
          <Grid container item>
            <Typography className={classes.timerHeading}>
              <FormattedMessage id="timeRemaning" />
            </Typography>
          </Grid>
          <Grid container item>
            <Typography className={classes.timerText}>
              <TimeRemaining {...props} />
            </Typography>
          </Grid>
          {props.redeem && (
            <Grid
              id="redeemed-container"
              container
              data-cy-name="redeemed-container"
              item
              className={classes.redeemedContainer}
            >
              <CheckIcon className={classes.redeemedIcon} />
              <Typography className={classes.redeemText}>
                <FormattedMessage
                  id="redeemed"
                  values={{
                    tabName: props.tabName,
                  }}
                />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!props.redeem && props.status.price !== 0 && utilityService.checkStatusExists(props.features, 'Incentive_Engine') && (
        <>
          <Grid id="not-redeem-container" container item>
            <Button
              data-cy-name="redeem-button"
              onClick={() => setDialogVisiblity(true)}
              className={classes.redeemBtn}
              aria-label={intl.formatMessage({ id: 'redeemBtn' }, { tabName: props.tabName })}
            >
              <Box component="span">
                <FormattedMessage
                  id="redeemBtn"
                  values={{
                    tabName: props.tabName,
                  }}
                />
              </Box>
              <Box display="flex" alignItems="center" component="span">
                {props.status.price}
                <StarsIcon className={classes.redeemIcon} />
              </Box>
            </Button>
          </Grid>
          <RedeemDialog
            schedule={props.schedule}
            price={props.status.price}
            isDialogOpen={isRedeemDialogOpen}
            setDialogVisiblity={setDialogVisiblity}
            tabName={tabName}
            updateRedeemState={props.updateRedeemState}
          />
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app }) => app;

export default withStyles(styles)(connect(mapStateToProps)(HasSchedule));
