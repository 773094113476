import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import styles from './review-images.styles';
import { CheckCircleIcon } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
import useReviewImagesHook from './useReviewImagesHook';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Constant } from '../../../../../../../shared/services';
import { PillButton } from '../../../../../../../frontend-commons';

/**
 * Component to render the biometric images for review.
 *
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const ReviewImages = ({ classes, onContinue, ...props }) => {
  const { getCurrentImage, getCurrentImageAngle, nextImage, previousImage, disablePreviousButton, disableNextButton } =
    useReviewImagesHook(props);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <CheckCircleIcon fontSize="large" color="primary" />
          <Typography id="review-images-title" variant="h1" className={classes.title}>
            <FormattedMessage id="great" />!
          </Typography>
        </Box>
        <Typography id="review-images-description" className={classes.description}>
          <FormattedMessage id="biometric-authentication.registration.success" />
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.container}>
          <img id="review-images-current-image" alt="" src={getCurrentImage()} className={classes.image} />
          <img id="review-images-current-image-angle" alt="" src={getCurrentImageAngle()} className={classes.imageAngle} />
          <IconButton
            id="review-images-previous-button"
            className={[classes.carouselArrow, classes.previousButton].join(' ')}
            onClick={previousImage}
            disabled={disablePreviousButton()}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <IconButton
            id="review-images-next-button"
            className={[classes.carouselArrow, classes.nextButton].join(' ')}
            onClick={nextImage}
            disabled={disableNextButton()}
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
          <Box className={classes.tickMarks}>
            <img
              id="review-images-tick-mark"
              alt=""
              className={classes.tickMark}
              src={`${Constant.CDN_BASE_URL}/assets/icons/tick-mark.png`}
            />
            <img
              id="review-images-tick-mark"
              alt=""
              className={classes.tickMark}
              src={`${Constant.CDN_BASE_URL}/assets/icons/tick-mark.png`}
            />
            <img
              id="review-images-tick-mark"
              alt=""
              className={classes.tickMark}
              src={`${Constant.CDN_BASE_URL}/assets/icons/tick-mark.png`}
            />
            <img
              id="review-images-tick-mark"
              alt=""
              className={classes.tickMark}
              src={`${Constant.CDN_BASE_URL}/assets/icons/tick-mark.png`}
            />
            <img
              id="review-images-tick-mark"
              alt=""
              className={classes.tickMark}
              src={`${Constant.CDN_BASE_URL}/assets/icons/tick-mark.png`}
            />
          </Box>
        </Box>
      </Box>
      <PillButton
        id="review-images-continue-button"
        color="primary"
        variant="contained"
        size="large"
        className={classes.continueButton}
        onClick={onContinue}
      >
        <FormattedMessage id="continue" />
      </PillButton>
    </Box>
  );
};

ReviewImages.propTypes = {
  /**
   * Material-UI classes object to style the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The images to review.
   */
  images: PropTypes.array.isRequired,
  /**
   * The onContinue function.
   */
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReviewImages);
