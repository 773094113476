import React from "react";

const CheckmarkIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Green_Checkmark">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Green_Checkmark" data-name="Green Checkmark" clipPath="url(#clip-Green_Checkmark)">
        <path id="circle-check-solid" d="M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24ZM17.3,9.8l-6,6a1.12,1.12,0,0,1-1.589,0l-3-3A1.124,1.124,0,0,1,8.3,11.208l2.2,2.2L15.7,8.2a1.124,1.124,0,0,1,1.589,1.589Z" fill={fill}/>
      </g>
    </svg>
  );
};

CheckmarkIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default CheckmarkIcon;
