import React from 'react';
import { Grid, Box, Icon, Typography, withStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import styles from './tools.style';
import { Constant, Utility } from '../../../../shared/services';
import { useIntl } from 'react-intl';

const Tools = (props) => {
  const { classes } = props;
  const intl = useIntl();

  const collection = props.collection.sort((a, b) => {
    if (a.position < b.position) return -1;
    if (a.position > b.position) return 1;
    return 0;
  });
  return (
    <Grid container sm={12} md={8} spacing={2}>
      {(collection || []).map((service) => {
        return (
          <Grid
            key={`${service.id}`}
            sm={12}
            md={6}
            item
            tabIndex={0}
            onClick={() => {
              props.handleLaunchClick(service);
            }}
            onKeyDown={(e) => {
              Utility.handleEnterKey(e, () => {
                props.handleLaunchClick(service);
              });
            }}
            tracking-type={Constant.TRACKING_TYPES.TOOL}
            tracking-id={`launch-tool-${service.id}`}
            id={`service-tool-${service.id}`}
            role="link"
            aria-label={intl.formatMessage({ id: 'launchService' }, { service: service.name })}
          >
            <Box data-cy-name="service-card" className={classes.card}>
              <Grid container sm={12} md={12} spacing={1} className={classes.cardLayout}>
                <Grid item sm={2} md={2}>
                  {service.icon && (
                    <Icon fontSize="large" color="primary">
                      {service.icon}
                    </Icon>
                  )}
                </Grid>
                <Grid item sm={8} md={8}>
                  <Box display="flex" flex={1} flexDirection="column" className={classes.contentBox}>
                    <Typography
                      id={`service-tool-${service.id}-name`}
                      variant="h3"
                      color="secondary"
                      className={classes.boxTitle}
                    >
                      {service.name}
                    </Typography>
                    <Typography id={`service-tool-${service.id}-description`} className={classes.boxDescription}>
                      {service.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={2} md={2}>
                  <ChevronRightIcon id="career-end-icon" color="secondary" fontSize="large" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withStyles(styles)(Tools);
