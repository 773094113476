import { Button, Box, Typography, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './pdq-assessment-not-started.style';
import { Constant } from '../../../../shared/services';

const PDQAssessmentNotStarted = (props) => {
  const { classes } = props;
  return (
    <>
      <Box>
        <Typography id="pdq-not-started-title" variant="h3" color="secondary" className={classes.title}>
          {props.title}
        </Typography>
        <Typography id="pdq-not-started-description" className={classes.description} component="p">
          {props.description}
        </Typography>
      </Box>
      <Box className={classes.actionBox}>
        <Button
          id="view-pdq-assement-instructions"
          onClick={props.showInstructions}
          color="secondary"
          className={classes.actionButton}
          variant="contained"
          tracking-type={Constant.TRACKING_TYPES.PDQ}
          tracking-id="view-pdq-assement-instructions"
          aria-label={props.action1Text}
          role="link"
        >
          {props.action1Text}
        </Button>
      </Box>
    </>
  );
};

export default withStyles(styles)(PDQAssessmentNotStarted);
