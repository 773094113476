import React from 'react';

const ThumbsDownSelectedIcon = (props) => {
  const { width, height, innerFill, outerFill, style } = props;

  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 32">
      <defs>
        <clipPath id="clip-Thumbs_Down_Selected">
          <rect width="31" height="32" />
        </clipPath>
      </defs>
      <g id="Thumbs_Down_Selected" data-name="Thumbs Down – Selected" clipPath="url(#clip-Thumbs_Down_Selected)">
        <g id="thumbs-down-duotone" transform="translate(0 2.801)">
          <path
            id="Path_92815"
            data-name="Path 92815"
            d="M5.657,32.04H1.886A1.875,1.875,0,0,0,0,33.873V47.019a1.841,1.841,0,0,0,1.886,1.833H5.657a1.875,1.875,0,0,0,1.886-1.833V33.925A1.884,1.884,0,0,0,5.657,32.04Z"
            transform="translate(0 -32.038)"
            fill={innerFill}
          />
          <path
            id="Path_92816"
            data-name="Path 92816"
            d="M178.108,44.268a2.813,2.813,0,0,0-1.558-4.678,2.844,2.844,0,0,0-1.539-4.192,2.124,2.124,0,0,0,.074-.571A2.828,2.828,0,0,0,172.257,32H168.8a9.427,9.427,0,0,0-5.655,1.884l-2.012,1.508A2.806,2.806,0,0,0,160,37.6l.006,9.428h0a2.826,2.826,0,0,0,1.055,2.2l.961.768c4.825,3.913,2.657,8.4,6.375,8.4a2.848,2.848,0,0,0,2.915-2.835,14.387,14.387,0,0,0-2.034-5.658h8.636a2.822,2.822,0,0,0,.194-5.637Z"
            transform="translate(-150.572 -32)"
            fill={outerFill}
          />
        </g>
      </g>
    </svg>
  );
};

ThumbsDownSelectedIcon.defaultProps = {
  width: 28,
  height: 32,
  innerFill: '#21605c',
  outerFill: '#3b928c',
  style: {},
};

export default ThumbsDownSelectedIcon;
