import React from 'react';
import useUnleashHook from './useUnleashHook';
import { connect } from 'react-redux';

/**
 * @name withUnleash
 * @param {component} WrappedComponent
 * @param {string} flagName
 * @description - HOC to attach unleash props to a component
 * @returns WrappedComponent with unleash props
 */

const withUnleash = (WrappedComponent, flagName) => {
  const mapStateToProps = ({ app }) => ({
    userDetail: app.userDetail,
  });

  const mapDispatchToProps = (dispatch) => ({});

  const HOC = (props) => {
    const { isFlagEnabled, getClient, getVariant, getFlagsStatus, getUnleashContextSetter } = useUnleashHook(
      flagName,
      props.userDetail.userName,
      props.userDetail.userId
    );
    return (
      <WrappedComponent
        {...props}
        unleashProps={{
          isFlagEnabled,
          getClient,
          getVariant,
          getFlagsStatus,
          getUnleashContextSetter,
        }}
      />
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withUnleash;
