import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, List, ListItem, Typography, withStyles } from '@material-ui/core';
import styles from './profile-section.style';
import { MissingData } from '../../../../../content/careers/components';
import { Utility } from '../../../../../shared/services';

const ProfileSection = ({
  classes,
  wrapperGridProps,
  title,
  badge,
  outline,
  keyPoints,
  isDataAvailable,
  isDataLoading,
  children,
}) => (
  <Grid item {...wrapperGridProps} className={classes.root}>
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography id="profile-section-title" variant="h3" className={classes.title}>
          {title}
        </Typography>
        {!Utility.isNullOrEmpty(badge) && (
          <Box className={classes.badge}>
            <Typography id="profile-section-badge" variant="body1" className={classes.badgeText}>
              {badge}
            </Typography>
          </Box>
        )}
      </Box>

      <Divider className={classes.divider} />

      {isDataAvailable ? (
        <>
          {!Utility.isNullOrEmpty(outline) && (
            <Box className={classes.sectionPadding}>
              <Typography id="profile-section-outline" variant="body1" className={classes.outlineText}>
                {outline}
              </Typography>
            </Box>
          )}

          {!Utility.isNullOrEmpty(keyPoints) &&
            keyPoints.map((item) => (
              <Box key={item.id} className={classes.sectionPadding}>
                <Typography id="profile-section-key-points-name" variant="h6" className={classes.keyPointName}>
                  {item.name}
                </Typography>
                <List id="profile-section-key-points-list" dense className={classes.keyPointsList}>
                  {item.points.map((point) => (
                    <ListItem key={point.id} dense className={classes.keyPointsListItem}>
                      {point.name}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}

          {children}
        </>
      ) : (
        !isDataLoading && <MissingData />
      )}
    </Box>
  </Grid>
);

ProfileSection.defaultProps = {
  wrapperGridProps: {
    sm: 12,
    md: 4,
  },
};

ProfileSection.propTypes = {
  classes: PropTypes.object.isRequired,
  wrapperGridProps: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  badge: PropTypes.string,
  outline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  keyPoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
  isDataAvailable: PropTypes.bool.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(ProfileSection);
