export default theme => ({
  achievementTitle: {
    ...theme.typography.t24,
    color: theme.palette.primary.black,
    lineHeight: "36px",
    letterSpacing: 0,
    marginBottom: 10,
    marginTop: 5
  },
  achievementBox: {
    "& *": {
      textDecoration: "none"
    }
  },
  chip: {
    ...theme.typography.medium,
    ...theme.typography.t14,
    lineHeight: "20px",
    maxWidth: "100%",
    letterSpacing: "0.75px"
  },
  chipWrapper: {
    maxWidth: "90%"
  },
  achievementWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "5px 25px 15px"
  },
  actionBtn: {
    ...theme.typography.bold,
    color: theme.palette.primary.main,
    letterSpacing: "1px",
    lineHeight: "16px",
    textTransform: "capitalize",
    ...theme.typography.t18
  },
  [theme.breakpoints.down("sm")]: {
    box1: {
      marginBottom: 10
    }
  },
  [theme.breakpoints.up("md")]: {
    box1: {
      paddingRight: 7.5
    },
    box2: {
      paddingLeft: 7.5
    }
  }
});
