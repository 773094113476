import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  confirmBtn: {
    margin: "2rem",
    background: theme.palette.primary.main,
    color: "white",
    width: 230,
    height: 42,
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  errorBox: {
    height: "100vh",
    overflow: "hidden"
  }
}));
