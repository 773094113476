import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = (theme) => ({
  root: {
    display: 'inline-block',
    width: 15,
    minWidth: 15,
    height: 15,
    borderRadius: 3,
    opacity: 1,
    boxShadow: 'none',
    transition: 'background .5s',
    padding: 0,
    margin: 0,
    marginRight: 6,
    outline: 0,
    cursor: 'pointer',
    background: '#A5B4BB',
  },
  active: {
    background: theme.palette.secondary.main,
  },
});

const CustomDot = ({ onClick, ...rest }) => {
  const { active, classes } = rest;
  return <Button className={[classes.root, active ? classes.active : ''].join(' ')} onClick={() => onClick()} />;
};

export default withStyles(styles)(CustomDot);
