export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '24px 32px',
  },
  filledBackground: {
    backgroundColor: theme.palette.aqua.pale,
  },
  header: {
    display: 'flex',
    gap: 16,
    paddingBottom: 12,
    fill: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  showAllContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  showAllButton: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
});
