import { Box, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './pdq-results-intro.style';
import PDQScores from '../pdq-scores/pdq-scores';

const PDQResultsIntro = (props) => {
  const { classes } = props;
  return (
    <>
      <Typography id="pdq-results-intro-title" className={classes.title} gutterBottom>
        <FormattedMessage id="pdq-results.intro.title" />
      </Typography>
      <Typography id="pdq-results-intro-description" className={classes.description}>
        <FormattedMessage id="pdq-results.intro.description" />
      </Typography>

      <Box className={classes.scoresContainer}>
        <PDQScores />
      </Box>

      <Typography id="pdq-results-intro-report-explains-title" className={classes.subtitle} gutterBottom>
        <FormattedMessage id="pdq-results.intro.report-explains.title" />
      </Typography>
      <Typography id="pdq-results-intro-report-explains-text" className={classes.description}>
        <FormattedMessage
          id="pdq-results.intro.report-explains.text"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            br: <br />,
          }}
        />
      </Typography>
    </>
  );
};

export default withStyles(styles)(PDQResultsIntro);
