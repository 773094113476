import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Icon, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import useCallCenterSchedulingHook from './useCallCenterSchedulingHook';
import PropTypes from 'prop-types';
import ScheduleGrid from './components/call-center-grid.js';
import styles from './call-center-scheduling.style.js';
/**
 * Represents the CallCenterScheduling component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.schedule - The schedule data.
 * @param {boolean} props.isClosed - Indicates if the call center is closed.
 * @param {Object} props.classes - The CSS classes for styling the component.
 * @returns {JSX.Element} The rendered CallCenterScheduling component.
 */
const CallCenterScheduling = ({ schedule, isClosed, isCallTimeLimitReached, classes }) => {
  const { displayedSchedules, handleShowFullSchedule, showFullSchedule, callCenterStatusData } = useCallCenterSchedulingHook(
    { schedule, isClosed, isCallTimeLimitReached }
  );

  if (!displayedSchedules || !callCenterStatusData) {
    return null;
  }

  return (
    <Grid container>
      <Accordion elevation={0} defaultExpanded className={classes.callCenterSection}>
        <AccordionSummary
          expandIcon={
            <Icon fontSize="large" color="secondary" className="material-icons-outlined">
              expand_circle_down
            </Icon>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box className={classes.callCenterHeaderSection}>
            <Typography variant="h5" className={`${classes.callCenterTitle} ${classes[callCenterStatusData.cssClass]}`}>
              {callCenterStatusData.icon} &nbsp;
              <FormattedMessage id={callCenterStatusData.title} />
            </Typography>

            <Typography variant="body1" className={classes.callCenterHeaderDescription}>
              <FormattedMessage id={callCenterStatusData.description} /> {callCenterStatusData.endTime}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.callCenterBodySection}>
            <Typography variant="h6" className={classes.availableTimes}>
              <FormattedMessage id={callCenterStatusData.slotTitle} />
            </Typography>
            <ScheduleGrid
              classes={classes}
              scheduleRecords={displayedSchedules}
              showFullSchedule={showFullSchedule}
              handleShowFullSchedule={handleShowFullSchedule}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

CallCenterScheduling.propTypes = {
  schedule: PropTypes.array.isRequired,
  isClosed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  bannerType: PropTypes.string.isRequired,
};

export default withStyles(styles)(CallCenterScheduling);
