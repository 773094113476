export default theme => ({
	root: {
		marginBottom: 25,
		display: "flex",
		justifyContent: "flex-end"
	},
	backBtn: {
		textDecoration: "underline",
		fontWeight: 700
	}
});
  