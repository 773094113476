import React from 'react';

const PaperPlaneIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 26 23" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.69807 0.165998C1.02294 -0.560852 -0.628114 1.23942 0.23833 2.84716L3.57414 9.04223C3.78593 9.44176 4.18065 9.7065 4.62831 9.76427L13.1002 10.8233C13.2639 10.8425 13.389 10.9821 13.389 11.1458C13.389 11.3094 13.2639 11.449 13.1002 11.4683L4.62831 12.5273C4.18065 12.585 3.78593 12.8546 3.57414 13.2493L0.23833 19.454C-0.628114 21.0617 1.02294 22.862 2.69807 22.1352L23.9693 12.9172C25.5144 12.2481 25.5144 10.0531 23.9693 9.38399L2.69807 0.165998Z"
        fill={fill}
      />
    </svg>
  );
};

PaperPlaneIcon.defaultProps = {
  width: '26',
  height: '23',
  fill: 'inherit',
};

export default PaperPlaneIcon;
