import React from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import styles from './back-to-top.style';
import { useIntl } from 'react-intl';

const BackToTop = (props) => {
  const { classes } = props;
  const [scrollTop, setScrollTop] = React.useState(0);
  const intl = useIntl();

  React.useEffect(() => {
    const scrollTopHandler = (event) => {
      setScrollTop(props.element.scrollY);
    };

    props.element.addEventListener('scroll', scrollTopHandler);

    return () => props.element.removeEventListener('scroll', scrollTopHandler);
  }, [props.element]);

  return (
    <IconButton
      onClick={() => {
        props.element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }}
      className={[classes.topBtn, scrollTop > 0 ? classes.showBtn : null].join(' ')}
      aria-label={intl.formatMessage({ id: 'backToTop' })}
    >
      <ArrowUpwardIcon className={classes.arrowUpIcon} />
    </IconButton>
  );
};

export default withStyles(styles)(BackToTop);
