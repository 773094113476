export default theme => ({
  contentWrapper: {
    minHeight: 155,
    maxWidth: "100%",
    width: 450
  },
  loaderContainer: {
    alignItems: "center",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    display: "flex",
    zIndex: 8000,
    justifyContent: "center",
    background: "black",
    opacity: 0.7,
    top: 0,
    left: 0
  },
  brandLogo: {
    display: "block",
    maxWidth: "100%",
    width: 135,
    margin: "0 auto 60px",
    "& p": {
      background: "black",
      opacity: 0.9,
      top: 19
    }
  },
  progressBar: {
    backgroundColor: theme.palette.primary.pastelBlueBg,
    "& div": {
      backgroundColor: theme.palette.primary.pastelBlue
    }
  }
});
