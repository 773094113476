export default (theme) => ({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: '10px',
  },
  card: {
    borderRadius: '10px',
  },
  iconContainer: {
    width: 90,
    height: 75,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(59, 146, 140, 0.1)',
    marginTop: 20,
    marginLeft: 20,
  },
  cardLayout: {
    width: '100%',
    minHeight: 275,
    [theme.breakpoints.down('sm')]: {
      minHeight: 325,
    },
  },
  contentArea: {
    padding: 20,
    width: '100%',
    position: 'relative',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  boxTitle: {
    ...theme.typography.t24,
    letterSpacing: '0px',
    lineHeight: '32px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    fontWeight: 700,
  },
  boxDescription: {
    color: theme.palette.text.sub,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    overflow: 'hidden',
  },
  actionBox: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    width: 'fit-content',
  },
  actionBtn: {
    borderRadius: 100,
    padding: '12px 20px',
    '&.service-url-btn': {
      ...theme.typography.t20,
      ...theme.typography.bold,
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '& svg': {
      marginLeft: 8,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
    },
  },
});
