export default (theme) => ({
  root: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  topBarContainer: {
    borderRadius: '10px 10px 0px 0px',
    margin: '-4px -4px 0px',
    background: 'var(--primary-deep)',
    padding: 5,
    position: 'relative',
  },
  topBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 35,
  },
  logoContainer: {
    marginRight: 30,
  },
  logo: {
    width: 150,
    height: 85,
  },
  modalTitle: {
    color: 'white',
    fontSize: 32,
    fontFamily: 'var(--font-main)',
    fontWeight: 800,
    textTransform: 'capitalize',
    marginBottom: '0.5rem',
  },
  effectiveDate: {
    height: 16,
    fontFamily: 'Rubik',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '1.5px',
    color: 'white',
    marginBottom: '1rem',
  },
  closeButton: {
    position: 'absolute',
    top: '30px',
    right: '15px',
    height: 45,
    width: 45,
    color: 'white',
  },
  languageList: {
    display: 'flex',
    paddingTop: 15,
    color: 'var(--grey-90)',
  },
  languageItem: {
    padding: '5px 15px',
    textTransform: 'capitalize',
  },
  languageActive: {
    color: 'var(--cerulean-main)',
  },
  title: {
    padding: 30,
    textAlign: 'center',
    color: 'var(--primary-deep)',
    fontSize: 20,
    fontFamily: 'var(--font-main)',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  content: {
    padding: '0px 25px',
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '0px 0px 10px 10px',
    padding: '20px 45px',
    background: 'var(--grey-10)',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  submitButton: {
    fontFamily: 'var(--font-main)',
    fontSize: 18,
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    color: '#7f848e',
    background: 'transparent',
    border: 'none',
    padding: '10px 30px',
    margin: 0,
    borderRadius: 100,
    textTransform: 'capitalize',
  },
  submitButtonEnabled: {
    color: 'white',
    background: 'var(--blue-main)',
    '&:hover': {
      background: 'var(--blue-main)',
    },
  },
  checkboxLabel: {
    fontFamily: 'var(--font-main)',
    fontSize: 16,
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    color: 'var(--primary-deep)',
  },
  checkedIcon: {
    color: 'var(--blue-main)',
  },
});
