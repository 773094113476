import React from 'react';

const FileFilledIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3014_5822)">
        <path
          d="M0 3.875C0 1.7377 1.71901 0 3.83333 0H13.4167V7.75C13.4167 8.82168 14.2732 9.6875 15.3333 9.6875H23V27.125C23 29.2623 21.281 31 19.1667 31H3.83333C1.71901 31 0 29.2623 0 27.125V3.875ZM23 7.75H15.3333V0L23 7.75Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3014_5822">
          <rect width="23" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

FileFilledIcon.defaultProps = {
  width: 23,
  height: 31,
  fill: 'inherit',
};

export default FileFilledIcon;
