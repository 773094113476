import React from 'react';
import { AttorneyIcon, MedicalIcon } from '../../../../shared/icons';
import { VerifiedUserIcon } from '@orijinworks/frontend-commons';

const ContactRelationshipIcons = ({ relationshipType }) => {
  const relationshipTypeToIcon = {
    ATTORNEY: AttorneyIcon,
    MEDICAL: MedicalIcon,
    GENERAL_PRIVILEGED_CONTACT: (props) => <VerifiedUserIcon {...props} sx={{ fontSize: '52px' }} />,
  };

  const SelectedIcon = relationshipTypeToIcon[relationshipType] || null;

  return <>{SelectedIcon && <SelectedIcon />}</>;
};

export default ContactRelationshipIcons;
