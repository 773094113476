export default (theme) => ({
  root: {
    borderRadius: 10,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
  },
  header: {
    borderRadius: '10px 10px 0px 0px',
    borderBottom: '1px solid',
    borderColor: theme.palette.text.primary,
    backgroundColor: theme.palette.text.primary,
    padding: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontWeight: 700,
    color: 'white',
    marginBottom: 15,
  },
  headerImg: {
    maxHeight: 250,
    maxWidth: 250,
  },
  readOnlyButton: {
    pointerEvents: 'none',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 275,
    textTransform: 'capitalize',
  },
  boldText: {
    fontWeight: 700,
  },
  flexCenteredContent: {
    display: 'flex',
    alignItems: 'center',
  },
  marginRight5: {
    marginRight: 5,
  },
  body: {
    padding: '50px 30px',
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 50,
  },
  closeBtn: {
    color: theme.palette.text.primary,
    border: '1px solid #D6E5ED',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    fontWeight: 500,
    width: 175,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.active,
    color: 'white',
    marginLeft: 20,
    textTransform: 'capitalize',
    fontWeight: 500,
    width: 175,
    '&:hover': {
      backgroundColor: theme.palette.primary.active,
    },
  },
  horizontalMargin10: {
    margin: '0px 20px',
  },
});
