import { ProgramConstants } from '../../../../../content/programs/services/programs.service';

const useSingleLaunchModuleHook = (props) => {
  const { modules, isEnrolled, rootModule, templateType, unleashProps } = props;
  const isFlagEnabled = unleashProps.isFlagEnabled();

  const isTemplateSingleCourse = () => {
    return templateType === ProgramConstants.TEMPLATE_TYPES.TEMPLATE_SINGLE_COURSE;
  };

  const isTemplateMultipleCoursesUnified = () => {
    return templateType === ProgramConstants.TEMPLATE_TYPES.TEMPLATE_MULTIPLE_COURSES_UNIFIED;
  };

  const isInProgress = () => {
    if (isFlagEnabled) {
      return rootModule?.progress === ProgramConstants.LEARNING_MODULE_PROGRESS.IN_PROGRESS;
    }
    return modules.some((module) => module.progress === ProgramConstants.LEARNING_MODULE_PROGRESS.IN_PROGRESS);
  };

  const isCompleted = () => {
    if (isFlagEnabled) {
      return rootModule?.progress === ProgramConstants.LEARNING_MODULE_PROGRESS.COMPLETED;
    }
    return modules.every((module) => module.progress === ProgramConstants.LEARNING_MODULE_PROGRESS.COMPLETED);
  };

  const hasProgress = () => {
    return isEnrolled && isTemplateSingleCourse() && (isInProgress() || isCompleted());
  };

  const getProgress = () => {
    let progress = ProgramConstants.LEARNING_MODULE_PROGRESS.IN_PROGRESS;
    if (isCompleted()) {
      progress = ProgramConstants.LEARNING_MODULE_PROGRESS.COMPLETED;
    }
    return progress;
  };

  return {
    hasProgress,
    getProgress,
    isTemplateMultipleCoursesUnified,
  };
};

export default useSingleLaunchModuleHook;
