import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import styles from './instructions.styles';
import { FormattedMessage } from 'react-intl';
import useInstructionsHook from './useInstructionsHook';

/**
 * Component to render the instructions for the Biometric Authentication Registration.
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const Instructions = ({ classes, ...props }) => {
  const { step, isFirstStep, isLastStep, handleStepChange } = useInstructionsHook(props);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography id="instructions-main-title" variant="h1">
          <FormattedMessage id="biometric-authentication.instructions.main-title" />
        </Typography>
      </Box>
      <Box id="instructions-camera-frame" className={classes.cameraFrame} />
      <Box className={classes.content}>
        <Typography id="instructions-title" variant="h2" className={classes.title}>
          <FormattedMessage id={`biometric-authentication.instructions.step${step}.title`} />
        </Typography>
        <Typography id="instructions-description">
          <FormattedMessage id={`biometric-authentication.instructions.step${step}.description`} />
        </Typography>
        <Button
          id="instructions-continue-button"
          color="primary"
          variant="contained"
          size="large"
          className={classes.continueButton}
          onClick={handleStepChange}
        >
          {isFirstStep() && <FormattedMessage id="continue" />}
          {isLastStep() && <FormattedMessage id="start" />}
          {!isFirstStep() && !isLastStep() && <FormattedMessage id="next" />}
        </Button>
      </Box>
    </Box>
  );
};

Instructions.propTypes = {
  /**
   * Material-UI classes object to style the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The function to continue to the next step.
   */
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(Instructions);
