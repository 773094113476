import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './announcement-details.style';
import { connect } from 'react-redux';
import { formatDate, formatTime } from '../../../../shared/services/utility/datetime.utility';
import useAnnouncementDetailsHook from './useAnnouncementDetailsHook';
import { Utility } from '../../../../shared';

const AnnouncementDetails = ({ classes, announcementDetails }) => {
  const datetime = announcementDetails?.lastMessage?.datetime;
  const body = announcementDetails?.lastMessage?.body;
  useAnnouncementDetailsHook(announcementDetails);

  return (
    <Box className={classes.root}>
      <Typography id="announcement-details-datetime" className={classes.datetime}>
        {datetime ? `${formatDate(datetime, 'MMM DD, YYYY')} ${formatTime(datetime)}` : ''}
      </Typography>
      {announcementDetails?.announcementTitle && (
        <Typography id="announcement-details-title" className={classes.title}>
          {announcementDetails.announcementTitle}
        </Typography>
      )}
      <Typography id="announcement-details-body" className={classes.body}>
        <div dangerouslySetInnerHTML={Utility.createSafeMarkup(body)} />
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  announcementDetails: state.staffMessaging.selectedThread,
});

export default connect(mapStateToProps)(withStyles(styles)(AnnouncementDetails));
