/**
 * Array of breadcrumbs items
 *
 * @constant
 * @field {boolean} isPrimary - Indicates if the item refers to a main page
 * @field {string} path - The path of the item
 * @field {RegExp} pathRegex - The regular expression to match the path
 * @field {string} label - The label of the item
 */
const BREADCRUMBS_ITEMS = [
  {
    isPrimary: true,
    path: '/home',
    pathRegex: /\/home$/,
    label: 'page.title.home',
  },
  {
    isPrimary: false,
    path: '/bookmarks',
    pathRegex: /\/bookmarks$/,
    label: 'page.title.bookmarks',
  },
  {
    isPrimary: false,
    path: '/interest-profiler',
    pathRegex: /\/interest-profiler$/,
    label: 'page.title.interest-profiler',
  },
  {
    isPrimary: false,
    path: '/programs/:id/details',
    pathRegex: /\/programs\/([\d])+\/details$/,
    label: 'page.title.program-details',
  },
  {
    isPrimary: true,
    path: '/programs',
    pathRegex: /\/programs$/,
    label: 'page.title.programs',
  },
  {
    isPrimary: false,
    path: '/careers/:id/profile',
    pathRegex: /\/careers\/(.)+\/profile$/,
    label: 'page.title.career-profile',
  },
  {
    isPrimary: true,
    path: '/careers',
    pathRegex: /\/careers$/,
    label: 'page.title.career-directory',
  },
  {
    isPrimary: false,
    path: '/transcript',
    pathRegex: /\/transcript$/,
    label: 'page.title.transcript',
  },
  {
    isPrimary: true,
    path: '/explore',
    pathRegex: /\/explore$/,
    label: 'page.title.explore',
  },
  {
    isPrimary: true,
    path: '/entertain',
    pathRegex: /\/entertain$/,
    label: 'page.title.entertainment',
  },
  {
    isPrimary: true,
    path: '/forms',
    pathRegex: /\/forms$/,
    label: 'page.title.forms',
  },
  {
    isPrimary: true,
    path: '/messaging',
    pathRegex: /\/messaging$/,
    label: 'page.title.staff-messaging',
  },
  {
    isPrimary: true,
    path: '/friends-family',
    pathRegex: /\/friends-family$/,
    label: 'page.title.friends-and-family-messaging',
  },
  {
    isPrimary: true,
    path: '/my-profile',
    pathRegex: /\/my-profile$/,
    label: 'page.title.my-profile',
  },
  {
    isPrimary: false,
    path: '/pdq-assessment/intro',
    pathRegex: /\/pdq-assessment\/intro$/,
    label: 'page.title.milestone-mapper',
  },
  {
    isPrimary: false,
    path: '/pdq-assessment/results',
    pathRegex: /\/pdq-assessment\/results$/,
    label: 'page.title.milestone-mapper-results',
  },
  {
    isPrimary: false,
    path: '/career-paths/:id/details',
    pathRegex: /\/career-paths\/([\d])+\/details$/,
    label: 'page.title.career-path-details',
  },
  {
    isPrimary: true,
    path: '/career-paths',
    pathRegex: /\/career-paths$/,
    label: 'page.title.career-paths',
  },
  {
    isPrimary: true,
    path: '/voice-call',
    pathRegex: /\/voice-call$/,
    label: 'page.title.voice-calls',
  },
  {
    isPrimary: false,
    path: '/resume-builder',
    pathRegex: /\/resume-builder$/,
    label: 'page.title.resume-builder',
  },
  {
    isPrimary: false,
    path: '/resume-preview',
    pathRegex: /\/resume-preview$/,
    label: 'page.title.resume-preview',
  },
];

/**
 * Function to get the matched route
 *
 * @param {Array} data - The array of routes
 * @param {String} pathname - The pathname to match
 * @returns {Object} - The matched route
 */
const getMatchedRoute = (data, pathname) => {
  return data.find((item) => item.pathRegex.test(pathname));
};

/**
 * Function to get the matched route index
 *
 * @param {Array} data - The array of routes
 * @param {String} pathname - The pathname to match
 * @returns {Number} - The matched route index
 */
const getMatchedRouteIndex = (data, pathname) => {
  return data.findIndex((item) => item.pathRegex.test(pathname));
};

export { BREADCRUMBS_ITEMS, getMatchedRoute, getMatchedRouteIndex };
