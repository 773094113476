import { useCallback, useState } from 'react';
import { AUTH_CONSTANTS } from '../../../auth/constants/auth-constants';
import { validateFace } from '../../services/auth-guards.service';

/**
 * Hook to handle the Biometric Authentication.
 *
 * @param {string} action - The action to perform. Either 'register' or 'authenticate'
 * @param {function} onComplete - The onComplete function
 * @returns {object}
 */
const useBiometricAuthenticationHook = ({ action, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function to check if the action is to register.
   *
   * @returns {boolean} - The result
   */
  const shouldRegister = () => {
    return AUTH_CONSTANTS.AUTH_GUARDS_ACTION.REGISTER === action;
  };

  /**
   * Function to check if the action is to authenticate.
   *
   * @returns {boolean} - The result
   */
  const shouldAuthenticate = () => {
    return AUTH_CONSTANTS.AUTH_GUARDS_ACTION.AUTHENTICATE === action;
  };

  /**
   * Function to authenticate the user.
   * @param {string} image - The image to authenticate
   * @param {function} cb - The callback function
   */
  const authenticate = useCallback(
    async (data, cb) => {
      try {
        setIsLoading(true);
        await validateFace(data, AUTH_CONSTANTS.AUTH_GUARDS_ACTION.AUTHENTICATE);
        onComplete();
      } catch (error) {
        cb(null, error);
      } finally {
        setIsLoading(false);
      }
    },
    [onComplete]
  );

  return {
    isLoading,
    shouldRegister,
    shouldAuthenticate,
    authenticate,
  };
};

export default useBiometricAuthenticationHook;
