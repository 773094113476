import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import StarsIcon from '@material-ui/icons/Stars';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import { List as ListLoader } from 'react-content-loader';
import DoneIcon from '@material-ui/icons/Done';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import { SnackBarToast } from '../';
import { Rest, UserService, Utility } from '../../../../shared';
import useStyles from './details-listing.style';

function PathwayDetailListing(props) {
  const [showHideListing, setShowHideListing] = React.useState({});
  const [hideCompletedItems, setHideCompletedItems] = React.useState(false);
  const [showCompletedOnly, setShowCompletedOnly] = React.useState(false);
  const userService = new UserService();
  const classes = useStyles();
  const showHideListingItems = (itemId) => {
    const showHideLists = { ...showHideListing };
    showHideLists[itemId] = !showHideLists[itemId];
    setShowHideListing(showHideLists);
  };
  const expandAndCollapse = (isExpand) => {
    let showHideLists = {};
    showHideLists = expandAndCollapseAllcollapseAll(props.listingItems, isExpand, showHideLists);
    setShowHideListing(showHideLists);
  };
  /**
   *
   * @param {detail List} pathwayDetails
   * @param {boolean} isExpand
   * @param {initial Object} showHideListing
   *
   * Apply recursive function for all child for expand collapse
   */
  const expandAndCollapseAllcollapseAll = (pathwayDetails, isExpand, showHideListing) => {
    for (let i = 0; i < pathwayDetails.length; i++) {
      showHideListing[pathwayDetails[i].id] = isExpand;
      if (pathwayDetails[i].children) {
        showHideListing = expandAndCollapseAllcollapseAll(pathwayDetails[i].children, isExpand, showHideListing);
      }
    }
    return showHideListing;
  };

  const isAllItemsCompleted = (pathwayList) => {
    return pathwayList.length === pathwayList.filter((pathway) => pathway.isCompleted === true).length;
  };

  const isChildCourseCompleted = (items) => {
    let flag = false;
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].isCompleted) {
          flag = true;
          break;
        }
        const isAnyChildComplete = isChildCourseCompleted(items[i].children);
        if (isAnyChildComplete) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  };

  const getListing = (pathwayList, classes) => {
    const isCourseCompleted = pathwayList.isCompleted;
    const isChildExist = pathwayList.children && pathwayList.children.length;

    if (showCompletedOnly && !isCourseCompleted && (!isChildExist || !isChildCourseCompleted(pathwayList.children))) {
      return null;
    }

    let allItemsCompleted = false;
    if (isChildExist) {
      allItemsCompleted = isAllItemsCompleted(pathwayList.children);
    }
    const isItemDisplay = showHideListing[pathwayList.id];
    return (
      <Box component="div" className={classes.nested}>
        <List className={[classes.detailsList].join(' ')}>
          <ListItem data-cy-name={`listitem-${pathwayList.id}`} key={pathwayList.id}>
            <ListItemText>
              <Grid container spacing={3}>
                {/*
                      List Items Content
                    */}
                <Grid item xs={4} className={classes.courseName}>
                  {!isChildExist ? (
                    isCourseCompleted ? (
                      <Grid container spacing={3}>
                        <Grid item alignItems="center" container xs={1} className={classes.courseName}>
                          <img src={`${Utility.icon}action-ic-check-circle.png`} alt="Completed" />
                        </Grid>
                        <Grid item container xs={9} className={classes.courseName}>
                          {pathwayList.name}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid item alignItems="center" container xs={1} className={classes.courseName}>
                          {/* Spacing */}
                        </Grid>
                        <Grid item container xs={9} className={classes.courseName}>
                          {pathwayList.name}
                        </Grid>
                      </Grid>
                    )
                  ) : (
                    pathwayList.name
                  )}
                </Grid>

                {(pathwayList.tags || []).some((tag) => tag === 'GRADABLE' || tag === 'ACTIONABLE') ? (
                  <Grid container item xs={3} alignItems="center" className={classes.iconWrapper}>
                    {pathwayList.tokens ? (
                      <>
                        <Typography>+{pathwayList.tokens}</Typography>
                        <StarsIcon className={classes.icon} fontSize="small" />
                      </>
                    ) : null}
                  </Grid>
                ) : (
                  ''
                )}
                {/*
                        Progress Bar in items
                    */}
                <Grid item xs={4}>
                  {isChildExist ? (
                    <LinearProgress color="secondary" variant="determinate" value={pathwayList.percentageCompleted} />
                  ) : isCourseCompleted ? (
                    <Box component="span" display="block" textAlign="left">
                      <Box component="p" margin={0} className={classes.assignedText}>
                        {pathwayList.completedScore} / {pathwayList.totalScore}
                        {/* PIPE WILL ONLY APPEAR  */}
                        {/* WHEN THERE'S LETTER GRADE */}
                        {pathwayList.letterGrade && ' | '}
                        {pathwayList.letterGrade}
                      </Box>
                      <Box component="span" className={classes.assignedText}>
                        <FormattedMessage id="completed" />:{' '}
                      </Box>
                      <Moment format=" MMM DD, YYYY" className={[classes.assignedText, classes.dateText].join(' ')}>
                        {pathwayList.completionDate}
                      </Moment>
                    </Box>
                  ) : (
                    pathwayList.startDate && (
                      <Box component="span" display="block" textAlign="left">
                        <Box component="span" className={classes.assignedText}>
                          <FormattedMessage id="started" />:{' '}
                        </Box>
                        <Moment format=" MMM DD, YYYY" className={[classes.assignedText, classes.dateText].join(' ')}>
                          {pathwayList.startDate}
                        </Moment>
                      </Box>
                    )
                  )}
                </Grid>
              </Grid>
            </ListItemText>
            {/*
                    Expand Collapse On Each Item
                */}
            <ListItemIcon>
              <LinearProgress color="secondary" variant="determinate" value={pathwayList.percentageCompleted} />
              {(pathwayList.tags || []).some((tag) => tag === 'GRADABLE' || tag === 'ACTIONABLE') ? (
                <Button
                  disabled={!props.isActive}
                  onClick={async () => {
                    if (!props.isActive) {
                      return;
                    }
                    userService.registerAnalyticEvent(
                      UserService.GAEvents.Category.Pathways,
                      'Clicked',
                      null,
                      pathwayList.name
                    );
                    const url = await new Rest().getLaunchUrl(pathwayList.id);
                    window.open(url.data, '_blank');
                    await new Rest().userInteraction({
                      resourceId: pathwayList.id,
                      lastActivityDate: Date.now(),
                      source: 'pathway-detail',
                      courseResourceId: props.courseId,
                    });
                  }}
                  color="primary"
                  className={classes.cardActionBtn}
                >
                  <FormattedMessage id="launch" />
                </Button>
              ) : (
                ''
              )}
              {isChildExist && (!allItemsCompleted || !hideCompletedItems) ? (
                <Fab
                  className={classes.fabButton}
                  onClick={(e) => {
                    showHideListingItems(pathwayList.id);
                  }}
                >
                  {isItemDisplay ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                </Fab>
              ) : (
                ''
              )}
            </ListItemIcon>
          </ListItem>
          <Divider className={classes.divider} />
          {
            /**
             * Apply recursive call if more child exist
             */
            isItemDisplay && isChildExist
              ? pathwayList.children.map((item) => {
                  return getListing(item, classes);
                })
              : ''
          }
        </List>
      </Box>
    );
  };
  return (
    <Grid className={classes.root} id="detail-list-wrapper">
      {!props.isLoading && props.isActive === false ? <SnackBarToast isCourseActive={false} /> : null}
      {(props.listingItems && props.listingItems.length) || props.isLoading ? (
        <Grid item xs={12} className={classes.gridLayout}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader className={classes.listHeader} component="div" id="nested-list-subheader">
                <Grid container>
                  <Grid item lg={2}>
                    {/* Spacing */}
                  </Grid>
                  <Grid item xs={12} lg={10} container spacing={1}>
                    {/**
                     *  Collapse Buttons
                     */}
                    <Grid className={classes.listHeaderSection} container justify="flex-end" spacing={1} item xs={12}>
                      <Box
                        onClick={() => {
                          expandAndCollapse(false);
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <img src="/assets/misc/unfold-less.png" alt="" className={classes.topBarImg} />
                        <Typography className={classes.topBarText}>
                          <FormattedMessage id="collapseAll" />
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => {
                          expandAndCollapse(true);
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <img src="/assets/misc/unfold-more.png" alt="" className={classes.topBarImg} />
                        <Typography className={classes.topBarText}>
                          <FormattedMessage id="expandAll" />
                        </Typography>
                      </Box>

                      <Box
                        onClick={() => {
                          expandAndCollapse(true);
                          setShowCompletedOnly(!showCompletedOnly);
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <Typography className={classes.topBarText}>
                          {showCompletedOnly && (
                            <>
                              <AllInclusiveIcon className={classes.topBarImg} />
                              &nbsp;
                              <FormattedMessage id="showAll" />
                            </>
                          )}
                          {!showCompletedOnly && (
                            <>
                              <DoneIcon className={classes.topBarImg} />
                              &nbsp;
                              <FormattedMessage id="showCompletedOnly" />
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </ListSubheader>
            }
          >
            {props.isLoading && (
              <Box component="div" className={classes.loaderWrapper}>
                <ListLoader />
              </Box>
            )}
            {/**
              Listing
            */}
            {!props.isLoading &&
              props.listingItems.map((item) => {
                return getListing(item, classes);
              })}
          </List>
        </Grid>
      ) : (
        <Typography className={classes.notFound} variant="p">
          <FormattedMessage id="noItemsFound" />
        </Typography>
      )}
    </Grid>
  );
}
export default PathwayDetailListing;
