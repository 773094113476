export default (theme) => ({
  root: {},
  categoriesContainer: {
    marginTop: theme.spacing(4),
    background: 'white',
    borderRadius: 12,
    padding: 45,
    width: '100%',
  },
});
