import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip } from '@material-ui/core';
import styles from './progress-chip.style';
import useProgressHook from './useProgressHook';

const ProgressChip = (props) => {
  const { classes } = props;
  const { progress, icon, cssClass } = useProgressHook(props);

  return <Chip className={[classes.chip, classes[cssClass]].join(' ')} label={progress} avatar={icon} />;
};

ProgressChip.propTypes = {
  progress: PropTypes.string,
};

export default withStyles(styles)(ProgressChip);
