import React, { useEffect } from 'react';
import { CheckmarkIcon, InprogressSpinnerIcon, RemoveCircleIcon } from '../../../../shared/icons';
import { ProgramConstants } from '../../services/programs.service';

const useProgressHook = ({ progress }) => {
  const defaultProgress = ProgramConstants.LEARNING_MODULE_PROGRESS.NOT_STARTED;
  progress = progress ?? defaultProgress;

  const [icon, setIcon] = React.useState(<RemoveCircleIcon />);
  const [cssClass, setCssClass] = React.useState('notStarted');

  useEffect(() => {
    if (progress === ProgramConstants.LEARNING_MODULE_PROGRESS.COMPLETED) {
      setIcon(<CheckmarkIcon />);
      setCssClass('completed');
    } else if (progress === ProgramConstants.LEARNING_MODULE_PROGRESS.IN_PROGRESS) {
      setIcon(<InprogressSpinnerIcon />);
      setCssClass('inProgress');
    }
  }, [progress]);

  return {
    progress,
    icon,
    cssClass,
  };
};

export default useProgressHook;
