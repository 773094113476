import { Box, Card, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './careerPathItem.style';
import { Constant } from '../../../../shared/services';
import { PinInWhiteBoxIcon } from '../../../../shared';
import { LinkButton } from '@orijinworks/frontend-commons';

const CareerPathItem = (props) => {
  const { classes, handleClick, id, title, description, thumbnailUri, pinned } = props;
  const intl = useIntl();

  return (
    <LinkButton
      id={`career-path-item-${id}-card`}
      className={[classes.root, classes.itemWrapper].join(' ')}
      onClick={() => {
        handleClick(id);
      }}
      tracking-type={Constant.TRACKING_TYPES.CAREER_PATHS}
      tracking-id={`view-career-path-${id}-details`}
      variant="text"
      aria-label={intl.formatMessage({ id: 'viewCareerPathDetails' })}
    >
      {pinned && (
        <Box className={classes.pinnedIcon}>
          <PinInWhiteBoxIcon />
        </Box>
      )}
      <Card className={classes.card}>
        <Box
          id="career-path-image"
          className={classes.thumbnailImage}
          style={{
            background: thumbnailUri ? `url("${thumbnailUri}") center / cover` : '',
          }}
        />
        <Box display="flex" className={classes.cardLayout}>
          <Box className={classes.contentArea} component="div" display="flex" flexDirection="column">
            <Box marginBottom="10px" className={classes.centerContainer}>
              <Typography id="career-path-title" variant="h3" color="secondary" className={classes.boxTitle}>
                {title}
              </Typography>
            </Box>
            <Typography id="career-path-description" className={classes.boxDescription}>
              {description}
            </Typography>

            <Box className={classes.actionBox}>
              <Typography
                className={[classes.actionBtn, classes.careerPathLearnMoreBtn].join(' ')}
                id="career-path-learn-more-btn"
                color="primary"
              >
                <FormattedMessage id="learnMore" />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </LinkButton>
  );
};

export default withStyles(styles)(CareerPathItem);
