import { EditorState, Modifier, RichUtils } from 'draft-js';
import { useRef, useState } from 'react';

const useMessageEditorHook = (props) => {
  const { disableSendButton, characterLimit, onSend, showBlurTextOption } = props;
  const { min: minCharLimit, max: maxCharLimit } = characterLimit;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [charLimitError, setCharLimitError] = useState(true);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [blurText, setBlurText] = useState(false);
  const editorRef = useRef(null);

  const handleEditorChange = (newEditorState) => {
    const charLength = getPlainText(newEditorState).length;
    setEditorState(newEditorState);
    if (charLength < minCharLimit || charLength >= maxCharLimit) {
      setCharLimitError(true);
    } else {
      setCharLimitError(false);
    }
  };

  const getPlainText = (state) => {
    const contentState = state.getCurrentContent();
    return contentState.getPlainText();
  };

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      handleEditorChange(newState);
      return true;
    }
    return false;
  };

  const handleReturn = (e) => {
    const newState = RichUtils.insertSoftNewline(editorState);
    handleEditorChange(newState);
    return 'handled';
  };

  const handleBeforeInput = (chars = '', state) => {
    const currentText = getPlainText(state);
    if (currentText.length + chars.length <= maxCharLimit) {
      return;
    }
    return 'handled';
  };

  const handlePastedText = (text, html, state) => {
    const currentText = getPlainText(state);

    // Calculate the remaining space in the editor
    const remainingSpace = maxCharLimit - currentText.length;

    // If the pasted text exceeds the remaining space, truncate it
    const truncatedText = text.slice(0, remainingSpace);

    // Modify the content state to include the truncated text
    const newContentState = Modifier.insertText(state.getCurrentContent(), state.getSelection(), truncatedText);

    // Set the new editor state with the modified content
    handleEditorChange(EditorState.push(state, newContentState, 'insert-characters'));

    return 'handled';
  };

  const shouldDisableEditor = () => {
    return isMessageSending;
  };

  const shouldDisableSendButton = () => {
    return isMessageSending || charLimitError || disableSendButton;
  };

  const focusEditor = () => {
    if (editorRef?.current) {
      editorRef.current.focus();
    }
  };

  const clearState = () => {
    setEditorState(EditorState.createEmpty());
    focusEditor();
  };

  const handleSendButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsMessageSending(true);
    const content = getPlainText(editorState);
    onSend(content, () => {
      clearState();
      setIsMessageSending(false);
    });
  };

  const toggleBlur = () => {
    setBlurText(!blurText);
  };

  const showBlurToggle = () => {
    let flag = false;
    if (showBlurTextOption) {
      const inputText = getPlainText(editorState);
      flag = inputText.length > 0;
    }
    return flag;
  };

  return {
    editorState,
    minCharLimit,
    maxCharLimit,
    charLimitError,
    isMessageSending,
    blurText,
    editorRef,
    handleEditorChange,
    handleKeyCommand,
    handleReturn,
    handleBeforeInput,
    handlePastedText,
    shouldDisableEditor,
    shouldDisableSendButton,
    handleSendButtonClick,
    focusEditor,
    toggleBlur,
    showBlurToggle,
  };
};

export default useMessageEditorHook;
