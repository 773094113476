import React, { useEffect } from 'react';
import {
  withStyles,
  Box,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  IconButton,
  Button,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import styles from './zone.details.style';
import CloseIcon from '@material-ui/icons/Close';
import { Constant } from '../../../../shared/services';

const ZoneDetails = (props) => {
  const { classes, isOpen } = props;
  const intl = useIntl();

  const [selectedZone, setSelectedZone] = React.useState(props.selectedZone);

  useEffect(() => {
    setSelectedZone(props.selectedZone);
  }, [props.selectedZone]);

  const onClose = () => {
    props.onClose();
    setSelectedZone(props.selectedZone);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle disableTypography className={classes.titleContainer}>
        <Typography variant="h3" className={classes.mainTitle}>
          <FormattedMessage id="job-zone-detai.main-title" />
        </Typography>
        <IconButton color="secondary" onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container sm={12} className={classes.root}>
          <Grid item className={classes.jobZonesContainer}>
            {Constant.CAREER_ZONES.map((zone) => (
              <Button
                key={zone.value}
                variant="outlined"
                className={[
                  classes.zoneBtn,
                  selectedZone === zone.value
                    ? classes[`filledBtnZone${zone.value}`]
                    : classes[`outlinedBtnZone${zone.value}`],
                ].join(' ')}
                onClick={() => {
                  setSelectedZone(zone.value);
                }}
                aria-label={intl.formatMessage({ id: 'selectZone' }, { zone: zone.value })}
              >
                <FormattedMessage id="zone" /> {zone.value}
              </Button>
            ))}
          </Grid>
          <Grid item sm={9} md={10} className={classes.jobZoneDetailsContainer}>
            <Typography id="zone-details-title" gutterBottom variant="h4" color="secondary" className={classes.mainTitle}>
              <FormattedMessage id={`jobZone${selectedZone}.title`} />
            </Typography>
            <Typography
              id="zone-details-description"
              gutterBottom
              className={[classes.bolder, classes.zoneSubtitle].join(' ')}
            >
              <FormattedMessage id={`jobZone${selectedZone}.subtitle`} />
            </Typography>
            <Typography
              id="zone-details-experience-description"
              className={[classes.mainDescription, classes.marginTop20].join(' ')}
            >
              <FormattedMessage id={`jobZone${selectedZone}.experience`} />
            </Typography>
            <Typography
              id="zone-details-education-description"
              className={[classes.mainDescription, classes.marginTop20].join(' ')}
            >
              <FormattedMessage id={`jobZone${selectedZone}.education`} />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ZoneDetails);
