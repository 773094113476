import React from 'react';

const AccountCircleIcon = (props) => {
  const { width, height } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="26" fill="#EAF2F4" />
      <path
        d="M26 12.6667C18.64 12.6667 12.6666 18.6401 12.6666 26.0001C12.6666 33.3601 18.64 39.3334 26 39.3334C33.36 39.3334 39.3333 33.3601 39.3333 26.0001C39.3333 18.6401 33.36 12.6667 26 12.6667ZM26 18.0001C28.5733 18.0001 30.6666 20.0934 30.6666 22.6667C30.6666 25.2401 28.5733 27.3334 26 27.3334C23.4266 27.3334 21.3333 25.2401 21.3333 22.6667C21.3333 20.0934 23.4266 18.0001 26 18.0001ZM26 36.6667C23.2933 36.6667 20.0933 35.5734 17.8133 32.8267C20.0666 31.0667 22.9066 30.0001 26 30.0001C29.0933 30.0001 31.9333 31.0667 34.1866 32.8267C31.9066 35.5734 28.7066 36.6667 26 36.6667Z"
        fill="#061E44"
      />
    </svg>
  );
};

AccountCircleIcon.defaultProps = {
  width: '52',
  height: '52',
  fill: 'inherit',
};

export default AccountCircleIcon;
