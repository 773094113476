import React from 'react';

const OpenFolderIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 43 33.446">
      <path
        id="folder-open-solid"
        d="M6.622,46.319,0,57.667V36.778A4.783,4.783,0,0,1,4.778,32H13.55a4.78,4.78,0,0,1,3.382,1.4l1.978,1.978a4.78,4.78,0,0,0,3.382,1.4h8.765a4.783,4.783,0,0,1,4.778,4.778v2.389H10.751a4.777,4.777,0,0,0-4.129,2.374Zm2.061,1.2a2.391,2.391,0,0,1,2.068-1.187H40.614a2.394,2.394,0,0,1,2.061,3.6L34.313,64.267a2.379,2.379,0,0,1-2.061,1.18H2.389a2.394,2.394,0,0,1-2.061-3.6L8.69,47.514Z"
        transform="translate(0 -32)"
        fill={fill}
      />
    </svg>
  );
};

OpenFolderIcon.defaultProps = {
  width: '43',
  height: '33.446',
  fill: 'inherit',
};

export default OpenFolderIcon;
