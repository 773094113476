export default theme => ({
  warningIcon: {
    color: theme.palette.primary.scarlet,
    fontSize: 40
  },
  title: {
    ...theme.typography.bold,
    ...theme.typography.t48,
    color: theme.palette.primary.battleshipGrey,
    marginLeft: 10
  },
  bodyText: {
    ...theme.typography.t34,
    color: theme.palette.primary.jetBlack,
    letterSpacing: "0.25px",
    lineHeight: "45px",
    width: 645,
    maxWidth: "100%"
  },
  center: {
    "& div": {
      justifyContent: "center",
      alignItems: "center"
    }
  },

  closeBody: {
    marginTop: 30
  },
  closeFooter: {
    marginTop: 60
  },
  noGap: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  },
  playScheduleBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    width: 282,
    maxWidth: "100%",
    height: 48,
    borderRadius: 28,
    ...theme.typography.bold,
    ...theme.typography.t18,
    letterSpacing: "1.61px",
    lineHeight: "16px",
    textTransform: "capitalize",
    margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
});
