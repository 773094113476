import React from 'react';
import { Permission } from '../../../../../core';
import { CategoryDetails, ExploreFilter, ExploreHeader } from '../../components';
import useExploreListHook from './useExploreListHook';
import { Box, withStyles } from '@material-ui/core';
import styles from './explore-list.styles';
import { AudioModal, BeingFreePlayer, Calculator } from '../../../../../shared';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { ServiceCredentials } from '../../../../../content/explore/components';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';
import { connect } from 'react-redux';

/**
 * Component that renders the list of explore page services and categories.
 *
 * @component
 * @param {object} props.classes - The classes that will be injected by withStyles.
 * @param {object} props.history - The history object that will be used to navigate to other pages.
 * @returns {React.Component} The ExploreList component.
 */
const ExploreList = ({ classes, history, unleashProps, locale, isLoading }) => {
  const {
    categories,
    audioPlayerProps,
    videoPlayerProps,
    beingFreePlayerProps,
    serviceCredentialsProps,
    calculatorRef,
    isActiveCategory,
    handleActiveCategory,
    handleServiceClick,
    handleAudioPlayerModalClose,
    handleVideoPlayerModalClose,
    handleBeingFreePlayerModalClose,
    handleServiceCredentialsModalClose,
    willOpenLink,
    getServicesByCategoryId,
  } = useExploreListHook({ classes, history, unleashProps, isLoading, locale });

  return (
    <Permission>
      {/* BEGIN: MODALS */}
      <Calculator ref={calculatorRef} />

      <BeingFreePlayer isOpen={beingFreePlayerProps.isOpen} handleClose={handleBeingFreePlayerModalClose} />

      <AudioModal {...audioPlayerProps} handleClose={handleAudioPlayerModalClose} />

      <ModalVideo
        channel="custom"
        isOpen={videoPlayerProps.isOpen}
        url={videoPlayerProps.videoUrl}
        autoPlay
        onClose={handleVideoPlayerModalClose}
      />

      <ServiceCredentials
        {...serviceCredentialsProps}
        onClose={handleServiceCredentialsModalClose}
        onLaunch={handleServiceClick}
      />
      {/* END: MODALS */}

      <Box className={classes.root}>
        <ExploreHeader />

        <Box id="explore-anchor-links">
          <ExploreFilter
            categories={categories}
            isActiveCategory={isActiveCategory}
            onCategoryClick={handleActiveCategory}
          />
        </Box>

        <Box id="services-container">
          {categories.map((category) => (
            <CategoryDetails
              key={category.id}
              categoryId={category.id}
              categoryName={category.name}
              services={category.services}
              total={category.total}
              handleActiveCategory={handleActiveCategory}
              handleServiceClick={handleServiceClick}
              willOpenLink={willOpenLink}
              onShowAll={() => getServicesByCategoryId(category.id)}
            />
          ))}
        </Box>
      </Box>
    </Permission>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    isLoading: app.isAppLevelApiLoading,
    locale: app.locale,
  };
};
export default connect(
  mapStateToProps,
  null
)(withUnleash(withStyles(styles)(ExploreList), UnleashService.FLAGS.TRANSLATE_EXPLORE));
