import React from "react";

import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { FormattedMessage, injectIntl } from "react-intl";

import styles from "./menu-button.styles";

function MenuButton({ classes, icon, menuItems, handleMenuItemClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = value => () => {
    handleMenuItemClick(value);
    handleMenuClose();
  };

  return (
    <div>
      <IconButton
        className={classes.iconButton}
        aria-haspopup="true"
        onClick={handleMenuClick}
        color="inherit"
      >
        {icon}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "bottom" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem style={{ color: "gray", width: "150px" }}>
          <FormattedMessage id="sortBy" />
        </MenuItem>
        {Object.entries(menuItems).map(([key, value]) => (
          <MenuItem
            key={`${key}-${value}`}
            onClick={onMenuItemClick(value)}
            style={{ color: "black" }}
          >
            <FormattedMessage id={key} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default withStyles(styles)(injectIntl(MenuButton));
