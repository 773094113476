import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './capture-image.styles';
import { Constant } from '../../../../../../../shared/services';
import Lottie from 'react-lottie';
import useCaptureImageHook from './useCaptureImageHook';
import { FormattedMessage } from 'react-intl';
import { PillButton } from '../../../../../../../frontend-commons';
import FaceValidationStatus from '../face-validation-status/face-validation-status';

/**
 * Component to capture the image for the biometric authentication.
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const CaptureImage = ({ classes, instruction, startButtonText, showStepsOnStatus, ...props }) => {
  const {
    currentPosition,
    animationData,
    capturedImage,
    timerCount,
    startCaptureTimer,
    webcamInitialized,
    webcamError,
    faceValidationStatus,
    faceValidationError,
    imagesProcessed,
    handleStartCapture,
    onContinue,
    onRetake,
  } = useCaptureImageHook(props);

  return (
    <Box>
      {!webcamError && (
        <>
          <canvas id="canvas" className={classes.hidden} />
          <audio id="snapSound" src={`${Constant.CDN_BASE_URL}/assets/audios/capture_image_snap.wav`} preload="auto" />
          <Box className={classes.container}>
            {capturedImage && <img id="capturedImage" className={classes.capturedImage} src={capturedImage} alt="" />}
            <video id="webcam" className={[classes.webcam, capturedImage ? classes.hidden : ''].join(' ')} />

            {faceValidationStatus && (
              <FaceValidationStatus
                status={faceValidationStatus}
                message={faceValidationError || 'success'}
                imagesProcessed={imagesProcessed.length}
                onContinue={onContinue}
                onRetake={onRetake}
                showSteps={showStepsOnStatus}
              />
            )}

            {!faceValidationStatus && (
              <>
                <Box className={classes.animationContainer}>
                  <Typography id="animation-instruction" variant="h1" className={classes.animationText}>
                    {instruction || (
                      <FormattedMessage id={`biometric-authentication.capture-image.instruction.${currentPosition}`} />
                    )}
                  </Typography>
                  <Lottie
                    height={80}
                    width={80}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData[currentPosition] || null,
                    }}
                    isClickToPauseDisabled
                  />
                </Box>

                <Box className={classes.timerContainer}>
                  <img
                    id="timer-image"
                    alt=""
                    className={classes.timerIcon}
                    src={`${Constant.CDN_BASE_URL}/assets/icons/timer.png`}
                  />
                </Box>

                {startCaptureTimer && (
                  <>
                    <Box id="timer-count" className={classes.timerCount}>
                      {timerCount}
                    </Box>
                    <Box id="camera-filter" className={classes.cameraFilter} />
                  </>
                )}

                <Box id="camera-frame" className={classes.cameraFrame} />

                <Box className={classes.startButtonContainer}>
                  <PillButton
                    id="start-button"
                    color="primary"
                    variant="contained"
                    size="large"
                    className={classes.startButton}
                    disabled={!webcamInitialized}
                    onClick={handleStartCapture}
                  >
                    {startButtonText || <FormattedMessage id="start" />}
                  </PillButton>
                </Box>

                {!startCaptureTimer && (
                  <Box className={classes.instructionContainer}>
                    <Typography id="capture-image-instructions" component="span" className={classes.instructions}>
                      <FormattedMessage id="biometric-authentication.capture-image.instructions" />
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      )}

      {webcamError && (
        <Typography id="webcam-error-message" className={classes.errorMessage}>
          <FormattedMessage id={webcamError} />
        </Typography>
      )}
    </Box>
  );
};

CaptureImage.defaultProps = {
  imageProcessingCount: 1,
  showStepsOnStatus: false,
};

CaptureImage.propTypes = {
  /**
   * Material-UI classes object to style the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The function to process the image.
   */
  onProcessImage: PropTypes.func.isRequired,
  /**
   * The function to continue to the next step.
   */
  onContinue: PropTypes.func,
  /**
   * The number of images to process. Default is 1.
   */
  imageProcessingCount: PropTypes.number,
  /**
   * The instruction to display.
   */
  instruction: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * The text to display on the start button.
   */
  startButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The showStepsOnStatus flag. If true, the steps will be shown on the status.
   */
  showStepsOnStatus: PropTypes.bool,
};

export default withStyles(styles)(CaptureImage);
