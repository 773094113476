import React from 'react';
import { Box, withStyles, Badge, Button } from '@material-ui/core';
import styles from './contact-item.styles';
import { Constant } from '../../../../shared';
import { connect } from 'react-redux';
import BlockIcon from '@material-ui/icons/Block';
import { useIntl } from 'react-intl';

const getUnreadCount = (unreadCount, relationshipId, fnfUnreadList) => {
  if (fnfUnreadList) {
    const currentRelation = fnfUnreadList.find((_relationship) => _relationship.relationshipId === relationshipId);
    if (currentRelation) {
      return currentRelation.learner.unreadCount;
    }
  }
  return unreadCount;
};

const ContactItem = (props) => {
  const { contact, selected, classes } = props;
  const data = contact.contact;
  const { learner } = contact;
  const intl = useIntl();

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: '12px',
      top: '20px',
      backgroundColor: '#db0028',
      color: theme.palette.primary.white,
    },
  }))(Badge);
  const BadgeSpacing = withStyles((theme) => ({
    badge: {
      right: '12px',
      top: '20px',
      color: theme.palette.primary.white,
    },
  }))(Badge);

  const onSelected = () => {
    props.onSelect(props.contact);
  };
  return (
    <>
      <Button
        className={[
          classes.message,
          getUnreadCount(learner.unreadCount, contact.relationshipId, props.friendsAndFamily.relationships) && !selected
            ? classes.messageHighlight
            : '',
        ].join(' ')}
        onClick={onSelected}
        fullWidth
        aria-label={intl.formatMessage({ id: 'selectContact' })}
      >
        {selected && <Box component="div" className={classes.messageSelected} />}
        {
          <div className={classes.sidebarMsgWrapper}>
            <div
              style={{
                marginRight: '20px',
                marginBottom: '-13px',
                marginTop: '-30px',
              }}
            >
              {getUnreadCount(learner.unreadCount, contact.relationshipId, props.friendsAndFamily.relationships) ? (
                <StyledBadge variant="dot"></StyledBadge>
              ) : (
                <BadgeSpacing />
              )}
              {getUnreadCount(learner.unreadCount, contact.relationshipId, props.friendsAndFamily.relationships) ? (
                <div id="contactname" className={classes.messageTextHighlight}>
                  {data.name ? data.name : data.phone}
                </div>
              ) : (
                <div className={classes.messageText}>{data.name ? data.name : data.phone}</div>
              )}
            </div>

            {getUnreadCount(learner.unreadCount, contact.relationshipId, props.friendsAndFamily.relationships) ? (
              <>
                <div id="unreadcount" className={classes.unreadCount}>
                  {getUnreadCount(learner.unreadCount, contact.relationshipId, props.friendsAndFamily.relationships)}
                </div>
              </>
            ) : (
              <>
                <div>
                  <p className={classes.unreadCount}></p>
                </div>
              </>
            )}

            {contact.status.learner === Constant.FNF_RELATIONSHIP_STATUS.BLOCKED && <BlockIcon className={classes.block} />}
          </div>
        }
      </Button>
    </>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    friendsAndFamily: app.friendsAndFamily.unreadData,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ContactItem));
