import { Http } from '../../../../core';

/**
 * Function that transforms the data from the API.
 *
 * @param {array} data - The data from the API.
 * @returns {array} The transformed data.
 */
const transformData = (data) => {
  return data
    .filter((item) => item.services?.length > 0)
    .map((item) => {
      return {
        ...item,
        name: item.title,
        services: item.services.map((service) => {
          if (service.metadata && typeof service.metadata === 'object') {
            service = { ...service, ...service.metadata };
          }
          return service;
        }),
      };
    });
};

/**
 * Function that fetches all categories with services.
 *
 * @returns {Promise} The promise object of the API call that resolves the categories with services.
 */
const fetchAllCategoriesWithServices = async () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/explore/categories/services`)
      .then((_successLog) => {
        if (_successLog?.data?.categories?.length > 0) {
          resolve(transformData(_successLog.data.categories));
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function that fetches services by category ID.
 *
 * @param {number} id - The category ID.
 * @returns {Promise} The promise object of the API call that resolves the services.
 */
const fetchServicesByCategoryId = (id) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/explore/categories/${id}/services`)
      .then((_successLog) => {
        if (_successLog?.data?.categories?.length > 0) {
          resolve(transformData(_successLog.data.categories)[0]);
        }
        resolve(null);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function that fetches the service launch URL.
 *
 * @param {number} id - The service ID.
 * @returns {Promise} The promise object of the API call that resolves the service launch URL.
 */
const getServiceLaunchUrl = (id) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/explore/launch/${id}`)
      .then((_successLog) => {
        if (_successLog?.data) {
          resolve(_successLog.data);
        }
        resolve(null);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const provisionEngenAccount = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/services/Engen/accounts`)
      .then((_successLog) => {
        if (_successLog?.data) {
          resolve(_successLog.data);
        }
        resolve(null);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { fetchAllCategoriesWithServices, fetchServicesByCategoryId, getServiceLaunchUrl, provisionEngenAccount };
