import { Http } from '../../../core';

export class OnboardingService {
  /**
   * @name setOnboardingStatus
   * @param {boolean} status
   * @desc set onboarding status
   * @return {Promise}
   */
  setOnboardingStatus = (status) => {
    return new Promise((resolve, reject) => {
      if (!status) {
        reject(new Error('Status is not defined.'));
      }
      Http.REQUEST.post(`/student/onboarding/${status}`).then((_successLog) => {
        resolve(_successLog);
      });
    });
  };
}
