import { Box, Typography, LinearProgress, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import styles from './pdq-scores.style';
import { FormattedMessage, useIntl } from 'react-intl';

const PDQScores = (props) => {
  const { classes } = props;
  const intl = useIntl();

  return (
    <Box className={classes.scoresContainer}>
      {/* P SCORE PROGRESS BAR */}
      <Box className={classes.scoreProgressArea}>
        <Box className={classes.progressLabelsContainer}>
          <Typography color="secondary" className={classes.progressLabel}>
            <FormattedMessage id="pdq-assessment.p" />
          </Typography>
          <Typography color="primary" className={classes.progressLabel}>
            <FormattedMessage id="level" /> {props.pdqScores ? props.pdqScores.proseScore : 1}
          </Typography>
        </Box>
        <LinearProgress
          id="pdq-assessment-p-score-bar"
          variant="determinate"
          value={props.pdqScores ? Math.floor((props.pdqScores.proseScore / props.pdqScores.proseMaxScore) * 100) : 33}
          color="primary"
          classes={{
            root: classes.progressBarRoot,
          }}
          aria-label={intl.formatMessage({ id: 'proseScoreProgress' })}
        />
      </Box>

      {/* D SCORE PROGRESS BAR */}
      <Box className={classes.scoreProgressArea}>
        <Box className={classes.progressLabelsContainer}>
          <Typography color="secondary" className={classes.progressLabel}>
            <FormattedMessage id="pdq-assessment.d" />
          </Typography>
          <Typography color="primary" className={classes.progressLabel}>
            <FormattedMessage id="level" /> {props.pdqScores ? props.pdqScores.documentScore : 1}
          </Typography>
        </Box>
        <LinearProgress
          id="pdq-assessment-d-score-bar"
          variant="determinate"
          value={props.pdqScores ? Math.floor((props.pdqScores.documentScore / props.pdqScores.documentMaxScore) * 100) : 33}
          color="primary"
          classes={{
            root: classes.progressBarRoot,
          }}
          aria-label={intl.formatMessage({ id: 'documentScoreProgress' })}
        />
      </Box>

      {/* Q SCORE PROGRESS BAR */}
      <Box className={classes.scoreProgressArea}>
        <Box className={classes.progressLabelsContainer}>
          <Typography color="secondary" className={classes.progressLabel}>
            <FormattedMessage id="pdq-assessment.q" />
          </Typography>
          <Typography color="primary" className={classes.progressLabel}>
            <FormattedMessage id="level" /> {props.pdqScores ? props.pdqScores.quantitativeScore : 1}
          </Typography>
        </Box>
        <LinearProgress
          id="pdq-assessment-q-score-bar"
          variant="determinate"
          value={
            props.pdqScores
              ? Math.floor((props.pdqScores.quantitativeScore / props.pdqScores.quantitativeMaxScore) * 100)
              : 33
          }
          color="primary"
          classes={{
            root: classes.progressBarRoot,
          }}
          aria-label={intl.formatMessage({ id: 'quantitativeScoreProgress' })}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ pdqAssessment }) => {
  return {
    pdqScores: pdqAssessment.pdqScores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PDQScores));
