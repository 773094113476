export default (theme) => ({
  root: {},
  attributionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
  },
  card: {
    borderRadius: 10,
    width: '100%',
    padding: '30px 0px',
  },
  cardHeader: {
    borderRadius: '10px 10px 0px 0px',
    borderBottom: '1px solid #E2E9EF',
    backgroundColor: '#E2E9EF',
    padding: 30,
  },
  headerTitle: {
    fontWeight: 700,
  },
  cardBody: {
    padding: '50px 30px',
  },
  questionBox: {
    marginTop: 50,
    borderRadius: 10,
    backgroundColor: 'white',
    border: '1px solid #DFE6E9',
    '&:focus-within': {
      outline: 'none',
    },
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 30,
    paddingTop: 0,
  },
  divider: {
    margin: '30px 0px',
    height: 2,
    backgroundColor: '#D6E5ED',
  },
  backBtn: {
    color: theme.palette.text.primary,
    border: '1px solid #D6E5ED',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    fontWeight: 500,
    minWidth: 175,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  nextBtn: {
    backgroundColor: theme.palette.primary.active,
    color: 'white',
    marginLeft: 20,
    textTransform: 'capitalize',
    fontWeight: 500,
    minWidth: 175,
    '&:hover': {
      backgroundColor: theme.palette.primary.active,
    },
  },
  progressArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  progressLabelsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  progressLabel: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  progressBarRoot: {
    height: 10,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.active,
  },
  progressBarColorPrimary: {
    backgroundColor: 'white',
  },
  progressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.active,
  },
  footer: {
    borderTop: '1px solid',
    borderColor: theme.palette.colors.grey,
    marginTop: 50,
    padding: 30,
    width: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  jobZoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  jobZoneLoaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    padding: 30,
    flexDirection: 'column',
    width: 500,
    height: 250,
    borderRadius: 5,
  },
  jobZoneLoader: {
    marginBottom: 20,
  },
  jobZoneQuestionnaireContent: {
    display: 'flex',
    backgroundColor: 'white',
    width: 800,
    borderRadius: 5,
  },
  bold: {
    fontWeight: 700,
  },
  semiBold: {
    fontWeight: 500,
  },
  successIcon: {
    marginBottom: 20,
    fill: theme.palette.primary.main,
  },
  personalityContainer: {
    borderRadius: 10,
  },
  personalityHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.sub,
    color: 'white',
    padding: 35,
    borderRadius: '10px 10px 0px 0px',
  },
  personalityResultsTitle: {
    ...theme.typography.t30,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  personalityContent: {
    background: 'white',
    borderRadius: '0px 0px 10px 10px',
  },
  personalityActionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 30,
  },
  personalityActionContent: {
    maxWidth: 400,
  },
  personalityActionTitle: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  personalityDescription: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  personalityActionBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
    margin: '20px 0px',
  },
});
