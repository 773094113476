import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FilledInput,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EyeClosedIcon, EyeOpenIcon } from '../../../../shared';
import { ExploreService } from '../../services/explore.service';
import styles from './service-credentials.style';

const ServiceCredentials = (props) => {
  const _exploreService = new ExploreService();

  const [credentials, setCredentials] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const intl = useIntl();
  const { classes } = props;

  useEffect(() => {
    if (props.serviceId) {
      setCredentials(null);
      _exploreService.getServiceCredentials(props.serviceId).then((credentials) => {
        setCredentials(credentials);
      });
    }
  }, [props.serviceId]);

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton color="secondary" onClick={props.onClose} aria-label={intl.formatMessage({ id: 'close' })}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          <FormattedMessage id="service-credentials.title" />
        </Typography>

        <Typography className={classes.description}>
          <FormattedMessage id="service-credentials.description" />
        </Typography>

        <Typography variant="h4" gutterBottom className={[classes.title, classes.serviceName].join(' ')}>
          {props.serviceName}
        </Typography>

        {credentials ? (
          <>
            <Box className={classes.inputBox}>
              <Typography id="username" className={classes.label}>
                <FormattedMessage id="username" />
              </Typography>
              <FilledInput
                value={credentials.username}
                disabled
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                aria-labelledby="username"
              />
            </Box>
            <Box className={classes.inputBox}>
              <Typography id="password" className={classes.label}>
                <FormattedMessage id="password" />
              </Typography>
              <FilledInput
                value={credentials.password}
                disabled
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeOpenIcon size={32} fill="#8E9EA0" /> : <EyeClosedIcon size={32} fill="#8E9EA0" />}
                  </IconButton>
                }
                aria-labelledby="password"
              />
            </Box>
          </>
        ) : (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        )}

        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.launchButton}
          onClick={() => {
            props.onClose();
            props.onLaunch(props.service);
          }}
          aria-label={intl.formatMessage({ id: 'launchService' }, { service: props.serviceName })}
        >
          <FormattedMessage id="service-credentials.launch-button-text" />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ServiceCredentials);
