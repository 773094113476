export default theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    logo: {
        width: 52,
        height: 52
    },
    textContainer: {
        position: "relative",
        padding: 20,
        backgroundColor: "white",
        borderRadius: 10,
        marginLeft: 15,
        '&::before': {
            content: "''",
            position: "absolute",
	        width: 0,
	        height: 0,
            left: "-12px",
	        right: "auto",  
            top: "calc(50% - 12px)",
	        bottom: "auto",
	        border: "12px solid transparent",
            borderLeft: "none",
            borderRightColor: "white"
        }
    },
    text: {
        fontSize: 12,
        color: theme.palette.text.sub
    }
});
  