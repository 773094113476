import React, { useEffect, useRef, useState } from 'react';
import { withStyles, Box, Typography, Divider, Button, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import * as InterestProfilerStore from '../../../../core/store/interest-profiler';
import styles from './job-zone-questionnaire.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { InterestProfilerService } from '../../services/interest-profiler.service';
import { CheckmarkIcon, Constant } from '../../../../shared';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

const JobZoneQuestionnaire = (props) => {
  const _service = new InterestProfilerService();
  const { classes } = props;
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(['', '']);
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    fetchQuestionnaire();
  }, [props.selectedLocale]);

  const fetchQuestionnaire = async () => {
    try {
      const data = await _service.getJobZoneQuestionnaire();
      console.log(data);
      if (data && data.questions && data.questions.length > 0) {
        setQuestions(data.questions);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setAnswer = (value) => {
    let data = [...answers];
    data[page - 1] = value;
    setAnswers(data);
  };

  const saveAnswers = async () => {
    try {
      const answersString = answers.join('');
      const response = await _service.saveJobZoneQuestionnaireAnswers(answersString);
      if (response && response.jobZone) {
        props.setRecommendedJobZone(response.jobZone);
        setPage(1);
        setAnswers(['', '']);
        props.onSave();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const answersCount = answers.filter((item) => !!item).length;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography id="jz-questionnaire-title" className={classes.mainTitle}>
          <FormattedMessage id="job-zone.questionnaire.title" /> {page}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box ref={containerRef} tabIndex={0} className={classes.container}>
        {questions.length > 0 && (
          <>
            <Typography
              id="jz-questionnaire-question"
              variant="h4"
              color="secondary"
              className={[classes.description, classes.question].join(' ')}
            >
              {`${questions[page - 1].index}. ${questions[page - 1].text}`}
            </Typography>
            {questions[page - 1].options.map((option) => (
              <Button
                key={option.index}
                className={[classes.optionBox, answers[page - 1] === option.value ? classes.selectedOption : ''].join(' ')}
                onClick={() => {
                  setAnswer(option.value);
                }}
                endIcon={
                  answers[page - 1] === option.value ? (
                    <CheckmarkIcon fill="#3B928C" />
                  ) : (
                    <Box className={classes.radioBtn} />
                  )
                }
                aria-label={option.text}
              >
                <Typography className={classes.optionText}>{option.text}</Typography>
              </Button>
            ))}
            <Box className={classes.actionBox}>
              <Button
                id="jz-questionnaire-back-btn"
                size="large"
                variant="contained"
                className={classes.backBtn}
                startIcon={<ArrowBack fontSize="large" />}
                onClick={() => {
                  setPage(1);
                }}
                disabled={page === 1}
                aria-label={intl.formatMessage({ id: 'goBack' })}
              >
                <FormattedMessage id="goBack" />
              </Button>

              <Box className={classes.progressArea}>
                <LinearProgress
                  id="job-zone-questionnaire-progress"
                  variant="determinate"
                  value={Math.floor((answersCount * 100) / questions.length)}
                  classes={{
                    root: classes.progressBarRoot,
                    colorPrimary: classes.progressBarColorPrimary,
                    bar: classes.progressBar,
                  }}
                  aria-label={intl.formatMessage({ id: 'questionnaireProgress' })}
                />

                <Box className={classes.progressLabelsContainer}>
                  <Typography className={classes.progressLabel}>
                    <FormattedMessage
                      id="pageMOfN"
                      values={{
                        m: page,
                        n: 2,
                      }}
                    />
                  </Typography>
                </Box>
              </Box>

              <Button
                id="jz-questionnaire-next-btn"
                size="large"
                variant="contained"
                className={classes.nextBtn}
                endIcon={<ArrowForward fontSize="large" />}
                disabled={!!!answers[page - 1]}
                onClick={() => {
                  if (page === 1) {
                    setPage(2);
                    containerRef.current.focus();
                  } else {
                    saveAnswers();
                  }
                }}
                tracking-type={Constant.TRACKING_TYPES.JOB_ZONE_QUESTIONAIRRE}
                tracking-id={page === 2 ? 'submit-job-zone-questionairre' : ''}
                aria-label={intl.formatMessage({ id: page === 2 ? 'submit' : 'goNext' })}
              >
                <FormattedMessage id={page === 2 ? 'submit' : 'goNext'} />
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ app, locale, interestProfiler }) => {
  return {
    selectedLocale: locale.locale,
    app,
    interestProfiler,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRecommendedJobZone: (jobZone) => dispatch(InterestProfilerStore.setRecommendedJobZone(jobZone)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(JobZoneQuestionnaire));
