export default (theme) => ({
  root: {
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px',
  },
  header: {
    padding: '20px 0px',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  iconBox: {
    fill: theme.palette.secondary.main,
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    position: 'relative',
  },
  content: {
    width: 'calc(100% - 90px)',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  truncate: {
    '-webkit-line-clamp': 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-word',
  },
  text: {
    color: theme.palette.text.sub,
    marginTop: 5,
  },
});
