import React from 'react';
import { Button, Collapse, withStyles } from '@material-ui/core';
import styles from './notification-banner.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import useNotificationBannerHook from './useNotificationBannerHook';

const NotificationBanner = (props) => {
  const { classes } = props;
  const { showNotification, hideNotification } = useNotificationBannerHook();
  const intl = useIntl();

  return (
    <Collapse in={showNotification} className={classes.root}>
      <Alert
        severity="info"
        classes={{ standardInfo: classes.infoNotification }}
        icon={<WarningIcon className={classes.infoIcon} />}
        action={
          <Button
            id="dismiss-notification-button"
            color="inherit"
            onClick={hideNotification}
            className={classes.dismissButton}
            aria-label={intl.formatMessage({ id: 'dismiss' })}
          >
            <FormattedMessage id="dismiss" />
          </Button>
        }
      >
        <FormattedMessage id="staff-messaging.warning.monitoring" />
      </Alert>
    </Collapse>
  );
};

export default withStyles(styles)(NotificationBanner);
