import React from "react";

const UnsureFaceIcon = props => {
  const { size, fill, opacity } = props;

  return (
    <svg style={{opacity: opacity}} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 55 55">
      <defs>
        <clipPath id="clip-Unsure_Face">
          <rect width="55" height="55"/>
        </clipPath>
      </defs>
      <g id="Unsure_Face" data-name="Unsure Face" clipPath="url(#clip-Unsure_Face)">
        <path id="face-thinking-solid" d="M55,27.5A27.5,27.5,0,0,1,27.5,55a26.956,26.956,0,0,1-3.373-.2,8.79,8.79,0,0,0,1.053-2.02l2.256-6.2,3.846-1.4a6.027,6.027,0,0,0,3.953-6.026,6.88,6.88,0,0,0-4.211-5.973L17.854,27.64a1.721,1.721,0,1,0-1.343,3.169l8.852,3.728-9.894,3.6v-.322a6.016,6.016,0,0,0-12.031,0V40.82A27.5,27.5,0,1,1,55,27.5ZM22.387,18.906a3.438,3.438,0,1,0-3.437,3.438A3.434,3.434,0,0,0,22.387,18.906Zm13.75,5.156A3.438,3.438,0,1,0,32.7,20.625,3.434,3.434,0,0,0,36.137,24.063ZM15.361,13.095a6.878,6.878,0,0,1,7.885.752l1.418,1.214a1.722,1.722,0,1,0,2.245-2.61l-1.418-1.214a10.319,10.319,0,0,0-11.838-1.128l-.763.43a1.717,1.717,0,1,0,1.7,2.986l.763-.43Zm-3.33,24.718v5.221L28.338,37.1A2.583,2.583,0,1,1,30.1,41.959L24.75,43.9l-2.8,7.7A5.149,5.149,0,0,1,17.1,55h-5.07a5.158,5.158,0,0,1-5.156-5.156V37.813a2.578,2.578,0,0,1,5.156,0Z" fill={fill}/>
      </g>
    </svg>
  );
};

UnsureFaceIcon.defaultProps = {
  size: 24,
  opacity: 1,
  fill: "inherit"
}

export default UnsureFaceIcon;
