import React from 'react';
import PropTypes from 'prop-types';
import { Keycloak } from '../../../../../core';
import { KeycloakProvider as KeycloakAuthProvider } from '@react-keycloak/web';

/**
 * Component to provide the Keycloak authentication provider
 *
 * @component
 * @param {node} props.children - The children components
 */
const KeycloakProvider = ({ children }) => {
  const keyCloak = new Keycloak();

  return (
    <KeycloakAuthProvider keycloak={Keycloak.Keycloak} onEvent={keyCloak.keyCloakEvent}>
      {children}
    </KeycloakAuthProvider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KeycloakProvider;
