export default (theme) => ({
  statusContainer: {
    position: 'absolute',
    top: 'calc(40% - 30vh - 75px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusFailed: {
    color: theme.alert.error,
  },
  statusSuccess: {
    color: theme.palette.primary.main,
  },
  statusWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    background: 'white',
    height: 75,
    maxWidth: '70vw',
    borderRadius: 10,
    border: '3px solid',
  },
  borderFailed: {
    borderColor: theme.alert.error,
  },
  borderSuccess: {
    borderColor: theme.palette.primary.main,
  },
  statusMessage: {
    fontSize: 15,
    letterSpacing: '0.59px',
    textAlign: 'center',
    margin: 0,
    marginLeft: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageFrame: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    height: '60vh',
    width: '70vw',
    border: `10px solid ${theme.palette.primary.white}`,
    outline: '100vw solid hsla(0, 0%, 100%, 0.3)',
    WebkitBackgroundClip: 'padding-box',
    backgroundClip: 'padding-box',
  },
  steps: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
  },
  stepNumber: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.greyScale[100],
    borderRadius: 25,
    margin: 10,
    textAlign: 'center',
    color: theme.palette.primary.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionContainer: {
    position: 'absolute',
    bottom: '11vh',
    left: 0,
    width: '100%',
  },
  actionButton: {
    width: 354,
    height: 73,
    margin: '40px auto 0px auto !important',
    display: 'block !important',
    textTransform: 'capitalize !important',
    minHeight: 50,
    fontSize: '24px !important',
  },
});
