import { connect } from 'react-redux';
import useThemeSwitcherHook from './useThemeSwitcherHook';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';

const ThemeSwitcher = ({ useNewTheme, unleashProps }) => {
  useThemeSwitcherHook({ useNewTheme, unleashProps });

  /**
   * Returning null because we want to control the theme switch via the
   * unleash feature flag directly and don't want to show the switch on the UI.
   */
  return null;
};

const mapStateToProps = ({ app }) => ({
  useNewTheme: app.useNewTheme,
});

export default connect(mapStateToProps)(withUnleash(ThemeSwitcher, UnleashService.FLAGS.LEARNER_PORTAL_REBRANDING));
