export default (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: '0px 12px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px',
    },
  },
  container: {
    borderRadius: 16,
    background: 'white',
    padding: 24,
    minHeight: 350,
    height: '100%',
    border: `1px solid ${theme.palette.greyScale[60]}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.navy.main,
  },
  divider: {
    backgroundColor: theme.palette.greyScale[60],
    margin: '12px 0px',
  },
  badge: {
    borderRadius: 5,
    padding: '9px 14px',
    backgroundColor: theme.palette.primary.pale,
  },
  badgeText: {
    color: theme.palette.navy.main,
    textTransform: 'uppercase',
  },
  sectionPadding: {
    padding: '6px 0px',
  },
  outlineText: {
    color: theme.palette.greyScale[90],
  },
  keyPointsList: {
    listStyleType: 'disc',
    listStylePosition: 'inside',
  },
  keyPointsListItem: {
    display: 'list-item',
    color: theme.palette.greyScale[110],
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
});
