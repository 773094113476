export default (theme) => ({
  root: {
    borderRadius: 12,
    background: 'white',
    position: 'relative',
  },
  bookmarkIcon: {
    fill: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    right: 50,
    cursor: 'pointer',
    '& > svg': {
      width: 50,
      height: 50,
    },
  },
  bgOverlay: {
    background: 'url(https://content.connectedcorrections.com/assets/img/ccfe-career-profile-bg.jpg) no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '200%',
    width: '100%',
    height: 150,
    borderRadius: '12px 12px 0px 0px',
  },
  detailsWrapper: {
    padding: 25,
  },
  careerTitle: {
    ...theme.typography.h1,
  },
  careerDescription: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
  },
  descriptionContainer: {
    padding: '0px 40px 20px 40px',
    marginTop: -20,
  },
  titleContainer: {
    display: 'flex',
    marginTop: -80,
    padding: 20,
    background: 'white',
    width: 'fit-content',
    borderRadius: '12px 12px 0px 0px',
  },
  detailsContainer: {
    marginTop: 10,
  },
  tasksContainer: {
    padding: '20px 40px',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  educationInfo: {
    marginLeft: 20,
  },
  salaryInfo: {},
  divider: {
    width: '100%',
  },
  activeText: {
    color: theme.palette.primary.active,
  },
  tasksWrapper: {
    marginTop: 10,
  },
  alsoCalledTitle: {
    ...theme.typography.t16,
    fontWeight: 400,
    lineHeight: 1.5,
    listStyleType: 'disc',
  },
  zoneBadge: {
    padding: '5px 10px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 15px',
    },
    borderRadius: 5,
    whiteSpace: 'nowrap',
    height: 'fit-content',
  },
  zoneColor1: {
    backgroundColor: theme.palette.jobZone.colors.zone1,
  },
  zoneColor2: {
    backgroundColor: theme.palette.jobZone.colors.zone2,
  },
  zoneColor3: {
    backgroundColor: theme.palette.jobZone.colors.zone3,
  },
  zoneColor4: {
    backgroundColor: theme.palette.jobZone.colors.zone4,
  },
  zoneColor5: {
    backgroundColor: theme.palette.jobZone.colors.zone5,
  },
  careerZone: {
    color: 'white',
    fontWeight: 500,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 40px',
  },
  bookmarkBtn: {
    padding: '10px 20px',
    fill: theme.palette.primary.main,
    textTransform: 'capitalize',
    border: '1px solid #DFE6E9',
    borderRadius: 5,
    marginTop: 10,
  },
  addBookmarkBtn: {
    backgroundColor: 'white',
  },
  removeBookmarkBtn: {
    backgroundColor: '#4E9D9721',
  },
  requirement: {
    ...theme.typography.t22,
    color: theme.palette.aqua.deep,
    fontWeight: 800,
  },
});
