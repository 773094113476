import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './certificate-dialog.style';
import { FormattedMessage } from 'react-intl';
import Lottie from 'react-lottie';
import useCertificateDialogHook from './useCertificateDialogHook';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CertificateDialog = ({ open, onClose, certificateUrl, classes }) => {
  const { animationData, pdfData } = useCertificateDialogHook({ certificateUrl });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.root,
      }}
      TransitionComponent={Transition}
      fullScreen
      aria-labelledby="certificate-dialog-title"
      aria-describedby="certificate-dialog-description"
    >
      <DialogContent className={classes.content}>
        {/* BEGIN: TITLE */}
        <DialogTitle className={classes.closeIconContainer} disableTypography>
          <IconButton id="close-certificate-dialog" onClick={onClose} className={classes.closeIcon} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* END: TITLE */}

        {/* BEGIN: ANIMATION */}
        <Box className={classes.animation}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
            }}
            isClickToPauseDisabled
          />
        </Box>
        {/* END: ANIMATION */}

        <Grid container>
          {/* BEGIN: CERTIFICATE */}
          <Grid item sm={12} md={6}>
            <Box>
              <Document file={pdfData} renderMode="svg" className={classes.certificateDocument}>
                <Page pageNumber={1} className={classes.certificate} renderAnnotationLayer={false} renderTextLayer={false} />
              </Document>
            </Box>
          </Grid>
          {/* END: CERTIFICATE */}

          {/* BEGIN: ADDITIONAL CONTENT */}
          <Grid item sm={12} md={6}>
            <Box className={classes.additionalContent}>
              <Box className={classes.textContainer}>
                <Typography id="certificate-dialog-title" variant="h2">
                  <FormattedMessage
                    id="program-certificate.dialog.title"
                    values={{
                      emoji: (
                        <span role="img" aria-label="Celebration">
                          🎉
                        </span>
                      ),
                    }}
                  />
                </Typography>
                <Typography id="certificate-dialog-description" variant="body1">
                  <FormattedMessage
                    id="program-certificate.dialog.description"
                    values={{
                      span: (...chunks) => <span>{chunks}</span>,
                      br: <br />,
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* END: ADDITIONAL CONTENT */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

CertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  certificateUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CertificateDialog);
