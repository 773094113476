export default (theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    padding: '20px 20px 10px 20px',
    width: '100%',
  },
  mainTitle: {
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 700,
    marginBottom: 20,
  },
  actionButton: {
    padding: '5px 15px',
    borderRadius: 5,
    margin: '10px 0px',
    backgroundColor: 'white',
    fill: theme.palette.primary.main,
    height: 55,
  },
  linkTitle: {
    fontWeight: 500,
    textTransform: 'capitalize',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  actionIcon: {
    '& > svg': {
      fontSize: '35px !important',
    },
  },
});
