import React from 'react';

const ChatIcon = (props) => {
  const { fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M2.4 2.40042C1.07625 2.40042 0 3.47667 0 4.80042V12.0004C0 13.3242 1.07625 14.4004 2.4 14.4004H3.6V16.2004C3.6 16.4292 3.7275 16.6354 3.93 16.7367C4.1325 16.8379 4.37625 16.8154 4.56 16.6804L7.60125 14.4004H13.2C14.5238 14.4004 15.6 13.3242 15.6 12.0004V4.80042C15.6 3.47667 14.5238 2.40042 13.2 2.40042H2.4ZM13.2 15.6004H9.6V16.8004C9.6 18.1242 10.6763 19.2004 12 19.2004H16.3988L19.44 21.4804C19.62 21.6154 19.8638 21.6379 20.07 21.5367C20.2763 21.4354 20.4 21.2292 20.4 21.0004V19.2004H21.6C22.9237 19.2004 24 18.1242 24 16.8004V9.60042C24 8.27667 22.9237 7.20042 21.6 7.20042H16.8V12.0004C16.8 13.9879 15.1875 15.6004 13.2 15.6004Z" />
    </svg>
  );
};

ChatIcon.defaultProps = {
  fill: 'inherit',
};

export default ChatIcon;
