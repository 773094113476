import React from 'react';
import { withStyles, Box, Typography, Divider, Grid } from '@material-ui/core';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';

import styles from './job-outlook.style';
import { FormattedMessage } from 'react-intl';
import { Utility } from '../../../../shared/services';
import { MissingData } from '..';

const JobOutlook = (props) => {
  const { classes, data } = props;
  let values = {};
  let barData = [];
  let lowerBound, median, upperBound;
  let overAttribute = {};
  let isHourlyPay = false;

  const isValueValid = (salary, field) => {
    return salary[field] && typeof salary[field] === 'number' && !isNaN(salary[field]);
  };

  if (data && data.salary) {
    if (isValueValid(data.salary, 'annual_10th_percentile')) {
      lowerBound = data.salary.annual_10th_percentile;
    } else if (isValueValid(data.salary, 'annual_10th_percentile_over')) {
      lowerBound = data.salary.annual_10th_percentile_over;
      overAttribute.lower = true;
    }

    if (isValueValid(data.salary, 'annual_median')) {
      median = data.salary.annual_median;
    } else if (isValueValid(data.salary, 'annual_median_over')) {
      median = data.salary.annual_median_over;
      overAttribute.median = true;
    }

    if (isValueValid(data.salary, 'annual_90th_percentile')) {
      upperBound = data.salary.annual_90th_percentile;
    } else if (isValueValid(data.salary, 'annual_90th_percentile_over')) {
      upperBound = data.salary.annual_90th_percentile_over;
      overAttribute.upper = true;
    }

    // HOURLY
    if (!(lowerBound && median && upperBound)) {
      if (isValueValid(data.salary, 'hourly_10th_percentile')) {
        lowerBound = data.salary.hourly_10th_percentile;
      } else if (isValueValid(data.salary, 'hourly_10th_percentile_over')) {
        lowerBound = data.salary.hourly_10th_percentile_over;
        overAttribute.lower = true;
      }

      if (isValueValid(data.salary, 'hourly_median')) {
        median = data.salary.hourly_median;
      } else if (isValueValid(data.salary, 'hourly_median_over')) {
        median = data.salary.hourly_median_over;
        overAttribute.median = true;
      }

      if (isValueValid(data.salary, 'hourly_90th_percentile')) {
        upperBound = data.salary.hourly_90th_percentile;
      } else if (isValueValid(data.salary, 'hourly_90th_percentile_over')) {
        upperBound = data.salary.hourly_90th_percentile_over;
        overAttribute.upper = true;
      }

      if (lowerBound && median && upperBound) {
        isHourlyPay = true;
      }
    }
  }

  if (lowerBound && median && upperBound) {
    values = {
      min: Utility.numberWithCommas(lowerBound),
      mid: Utility.numberWithCommas(median),
      max: Utility.numberWithCommas(upperBound),
    };
    barData = [
      { x: `$${values.min}${overAttribute.lower ? '+' : ''}`, y: 3, fill: '#E2E9EF', width: 50 },
      { x: 'x', y: 5, fill: '#E2E9EF', width: 50 },
      { x: 'y', y: 7, label: `$${values.mid}${overAttribute.median ? '+' : ''}`, fill: '#4E9D97', width: 70 },
      { x: 'z', y: 5, fill: '#E2E9EF', width: 50 },
      { x: `$${values.max}${overAttribute.upper ? '+' : ''}`, y: 3, fill: '#E2E9EF', width: 50 },
    ];
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Box>
          <Typography variant="h2" id={`job-outlook-title`} className={classes.capitalize}>
            <FormattedMessage id="jobOutlook" />
          </Typography>
        </Box>
        {data && data.outlook && data.outlook.category && (
          <Box className={classes.categoryBadge}>
            <Typography id="job-category" color="secondary" className={classes.capitalize}>
              {data.outlook.category}
            </Typography>
          </Box>
        )}
      </Box>

      <Divider className={classes.divider} />

      {data ? (
        <>
          {data.outlook && (
            <Box>
              <Typography id="job-category-desc" className={classes.categoryDesc}>
                {data.outlook.description}
              </Typography>
            </Box>
          )}
          {barData.length > 0 && (
            <>
              <Typography id="salary-info" gutterBottom variant="h3" color="secondary" className={classes.capitalize}>
                <FormattedMessage id={isHourlyPay ? 'hourlyRate' : 'annualSalary'} />:
              </Typography>

              <Grid id="job-outlook-graph" container>
                <VictoryChart domainPadding={25} aria-label={`$${lowerBound} to $${upperBound}`}>
                  <VictoryBar
                    height={200}
                    domain={{ y: [2, 8] }}
                    data={barData}
                    barWidth={({ datum }) => datum.width}
                    style={{
                      data: {
                        fill: ({ datum }) => datum.fill,
                      },
                      labels: { fontWeight: 700, fill: '#0F445A' },
                    }}
                  />
                  <VictoryAxis
                    height={50}
                    style={{
                      axis: { stroke: '#B0BABC', width: '100%' },
                      grid: { stroke: () => 0 },
                      ticks: { size: 0 },
                      tickLabels: { fill: '#7E8995' },
                    }}
                    tickFormat={(item) => (['x', 'y', 'z'].includes(item) ? ' ' : item)}
                  />
                </VictoryChart>
              </Grid>
              <Typography id="job-outlook-attribution" className={classes.description}>
                <FormattedMessage id="career.jobOutlook.attribution" />:
              </Typography>
            </>
          )}
        </>
      ) : (
        <MissingData />
      )}
    </Box>
  );
};

export default withStyles(styles)(JobOutlook);
