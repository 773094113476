export default (theme) => ({
  root: {
    zIndex: 9999,
  },
  reloadBtn: {
    textTransform: 'capitalize',
    marginLeft: 15,
  },
  btnSuccess: {
    color: '#4E9D97',
  },
  btnError: {
    color: theme.alert.error,
  },
  alertAction: {
    display: 'none',
  },
  alertDataWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  alertRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  errorNotification: {
    backgroundColor: theme.palette.primary.background,
    color: theme.alert.error,
    border: `1px solid ${theme.alert.error}`,
  },
  successNotification: {
    backgroundColor: theme.palette.primary.background,
    color: '#4E9D97',
    border: '1px solid #4E9D97',
  },
  undoButton: {
    marginLeft: 15,
    color: '#4E9D97',
    borderColor: '#4E9D97',
  },
  titleContainer: {
    margin: '0px 15px',
  },
  title: {
    textTransform: 'capitalize',
  },
  message: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
});
