import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, CircularProgress, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { TranscriptService } from '../../services';

import styles from './transcript-v1.style';
import { Loader } from '@orijinworks/frontend-commons';

const ProgressReport = React.lazy(() => import('../../components/achievements-transcript/report'));

export class TranscriptV1 extends React.Component {
  _service = new TranscriptService();
  state = {
    transcript: {
      data: null,
      isLoading: true,
      modalOpen: true,
    },
  };

  componentDidMount() {
    this.fetchTranscript();
  }

  fetchTranscript = async () => {
    try {
      const response = await this._service.fetchUserTranscript();
      this.setState({
        transcript: {
          data: response,
          isLoading: false,
          modalOpen: true,
        },
      });
    } catch (error) {
      this.setState({
        transcript: {
          data: {},
          isLoading: false,
        },
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state.transcript;
    return (
      <>
        {isLoading && <Loader isLoading={isLoading} />}
        {!isLoading && (
          <Box className={classes.paper}>
            <Box width="100%">
              <Suspense fallback={<CircularProgress />}>
                <ProgressReport data={this.state.transcript.data} userDetail={this.props.userDetail} />
              </Suspense>
            </Box>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

export default connect(mapStateToProps)(withRouter(injectIntl(withStyles(styles)(TranscriptV1))));
