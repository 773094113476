import { useCallback, useEffect, useRef, useState } from 'react';
import {
  fetchAllCategoriesWithServices,
  fetchServicesByCategoryId,
  getServiceLaunchUrl,
  provisionEngenAccount,
} from '../../services/explore.service';
import { Constant, UserService, Utility } from '../../../../../shared';
import { PENDO_TRACK_EVENTS_ENUM, store, toggleToast, trackPendoEvent } from '../../../../../core';

/**
 * Custom hook that handles the logic for the ExploreList component.
 *
 * @param {object} props.classes - The classes that will be injected by withStyles.
 * @param {object} props.history - The history object that will be used to navigate to other pages.
 *
 * @returns {array} categories - The list of explore page categories.
 * @returns {object} serviceCredentialsProps - The service credentials modal props.
 * @returns {object} audioPlayerProps - The audio player modal props.
 * @returns {object} videoPlayerProps - The video player modal props.
 * @returns {object} beingFreePlayerProps - The being free player modal props.
 * @returns {object} calculatorRef - The calculator modal ref.
 * @returns {function} isActiveCategory - The function that checks if the category is active.
 * @returns {function} handleActiveCategory - The function that handles the active category change.
 * @returns {function} handleServiceClick - The function that handles the service click.
 * @returns {function} handleServiceCredentialsModalClose - The function that closes the service credentials modal.
 * @returns {function} handleAudioPlayerModalClose - The function that closes the audio player modal.
 * @returns {function} handleVideoPlayerModalClose - The function that closes the video player modal.
 * @returns {function} handleBeingFreePlayerModalClose - The function that closes the being free player modal.
 * @returns {function} willOpenLink - The function that checks if the service will open a link.
 * @returns {function} getServicesByCategoryId - The function that fetches the services by category id.
 */
const useExploreListHook = ({ classes, history, unleashProps, isLoading, locale }) => {
  const userService = new UserService();
  const utilityService = new Utility();
  const [cacheLocale, setCacheLocale] = useState(locale);
  const calculatorRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [serviceCredentialsProps, setServiceCredentialsProps] = useState({
    isOpen: false,
    serviceId: '',
    serviceName: '',
    service: null,
  });
  const [audioPlayerProps, setAudioPlayerProps] = useState({ isOpen: false, title: '', thumbnailUrl: '', audioUrl: '' });
  const [videoPlayerProps, setVideoPlayerProps] = useState({ isOpen: false, videoUrl: '' });
  const [beingFreePlayerProps, setBeingFreePlayerProps] = useState({ isOpen: false });
  /**
   * Function that handles the scroll event and sticks the anchor links to the top.
   */
  const handleScroll = useCallback(() => {
    const anchorLinkElement = document.getElementById('explore-anchor-links');
    const header = document.getElementById('ccfe-main-header');
    const servicesContainer = document.getElementById('services-container');
    if (anchorLinkElement && header && servicesContainer) {
      const servicesContainerPosition = servicesContainer.getBoundingClientRect().y;
      const headerHeight = header.offsetHeight;
      const anchorLinkElementHeight = anchorLinkElement.offsetHeight;
      if (servicesContainerPosition <= headerHeight + anchorLinkElementHeight) {
        anchorLinkElement.classList.add(classes.stickToTop);
      } else {
        anchorLinkElement.classList.remove(classes.stickToTop);
      }
    }
  }, [classes.stickToTop]);

  /**
   * useEffect hook that fetches the categories data.
   */
  useEffect(() => {
    if (!isLoading && unleashProps.isFlagEnabled()) {
      getCategoriesData();
    }
  }, [isLoading, locale]);

  // TODO: Remove below useEffect hook as part of feature flag cleanup
  useEffect(() => {
    if (!unleashProps.isFlagEnabled()) {
      getCategoriesData();
    }
  }, []);
  /**
   * useEffect hook that adds and removes the scroll event listener.
   */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  /**
   * useEffect hook that focuses the active category.
   */
  useEffect(() => {
    const element = document.getElementById(`explore-filter-${activeCategory}`);
    if (element) {
      element.focus();
    }

    return () => {
      if (element) {
        element.blur();
      }
    };
  }, [activeCategory]);

  /**
   * Function that fetches the categories data.
   */
  const getCategoriesData = async () => {
    try {
      const data = await fetchAllCategoriesWithServices();
      setCategories(data);
      setActiveCategory(data[0]?.id);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Function that fetches the services by category id.
   *
   * @param {number} categoryId - The category id.
   */
  const getServicesByCategoryId = async (categoryId) => {
    try {
      const data = await fetchServicesByCategoryId(categoryId);
      setCategories((prevCategories) =>
        prevCategories.map((category) => (category.id === categoryId ? data || category : category))
      );
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Function that checks if the category is active.
   *
   * @param {number} id - The category id.
   * @returns {boolean} - The result of the check.
   */
  const isActiveCategory = (id) => id === activeCategory;

  /**
   * Function that checks if the service is a calculator.
   *
   * @param {string} serviceName - The service name.
   * @returns {boolean} - The result of the check.
   */
  const isCalculator = (serviceName) => serviceName === Constant.EXPLORE_SERVICES.CALCULATOR;

  /**
   * Function that checks if the service is an education.
   *
   * @param {string} serviceName - The service name.
   * @returns {boolean} - The result of the check.
   */
  const isEngen = (serviceName) => serviceName === Constant.EXPLORE_SERVICES.ENGEN;

  /**
   * Function that checks if the service is being free.
   *
   * @param {string} serviceName - The service name.
   * @returns {boolean} - The result of the check.
   */
  const isBeingFree = (serviceName) => serviceName === Constant.EXPLORE_SERVICES.BEING_FREE;

  /**
   * Function that scrolls to the element.
   *
   * @param {number} id - The service id.
   */
  const scrollToElement = (id) => {
    const element = document.getElementById(`service-category-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  };

  /**
   * Function that handles the active category change.
   *
   * @param {number} id - The category id.
   * @param {boolean} scroll - The flag that indicates if the scroll should be performed.
   */
  const handleActiveCategory = useCallback((id, scroll) => {
    setActiveCategory(id);
    if (scroll) {
      scrollToElement(id);
    }
  }, []);

  /**
   * Function that handles the service credentials modal open.
   *
   * @param {object} service - The service object.
   */
  const handleServiceCredentialsModalOpen = (service) => {
    setServiceCredentialsProps({
      isOpen: true,
      serviceId: service.id,
      serviceName: service.serviceName,
      service,
    });
  };

  /**
   * Function that handles the service credentials modal close.
   */
  const handleServiceCredentialsModalClose = () => {
    setServiceCredentialsProps({
      isOpen: false,
      serviceId: '',
      serviceName: '',
      service: null,
    });
  };

  /**
   * Function that handles the audio player modal open.
   * @param {object} service - The service object.
   */
  const handleAudioPlayerModalOpen = (service) => {
    setAudioPlayerProps({
      isOpen: true,
      title: service.serviceName,
      thumbnailUrl: service.thumbnailUri,
      audioUrl: service.serviceUrl,
    });
  };

  /**
   * Function that handles the audio player modal close.
   */
  const handleAudioPlayerModalClose = () => {
    setAudioPlayerProps({
      isOpen: false,
      title: '',
      thumbnailUrl: '',
      audioUrl: '',
    });
  };

  /**
   * Function that handles the video player modal open.
   *
   * @param {object} service - The service object.
   */
  const handleVideoPlayerModalOpen = (service) => {
    setVideoPlayerProps({ isOpen: true, videoUrl: service.serviceUrl });
  };

  /**
   * Function that handles the video player modal close.
   */
  const handleVideoPlayerModalClose = () => {
    setVideoPlayerProps({ isOpen: false, videoUrl: '' });
  };

  /**
   * Function that handles the being free player modal open.
   */
  const handleBeingFreePlayerModalOpen = () => {
    setBeingFreePlayerProps({ isOpen: true });
  };

  /**
   * Function that handles the being free player modal close.
   */
  const handleBeingFreePlayerModalClose = () => {
    setBeingFreePlayerProps({ isOpen: false });
  };

  const handleDynamicProvisionalServices = async (service, fallbackMethod) => {
    try {
      const { serviceName, serviceUrl } = service;
      if (isEngen(serviceName)) {
        const response = await provisionEngenAccount();
        if (response?.payload?.status === 200) {
          utilityService.launchURLHandler(serviceName, () => {
            return Promise.resolve({ data: serviceUrl });
          });
        } else {
          store.dispatch(
            toggleToast({
              variant: 'error',
              message: 'error.msg.default',
              title: 'sorry',
              isOpen: true,
              showCancelButton: true,
            })
          );
        }
        return;
      }
      fallbackMethod(service);
    } catch (error) {
      store.dispatch(
        toggleToast({
          variant: 'error',
          message: 'error.msg.default',
          title: 'sorry',
          isOpen: true,
          showCancelButton: true,
        })
      );
    }
  };

  /**
   * Function that handles the service launch.
   *
   * @param {object} service - The service object.
   */
  const handleServiceLaunch = async (service) => {
    try {
      const url = await getServiceLaunchUrl(service.id);
      if (!url) {
        store.dispatch(
          toggleToast({
            variant: 'error',
            message: 'error.msg.default',
            title: 'sorry',
            isOpen: true,
            showCancelButton: true,
          })
        );
      }

      if (utilityService.isRelativePath(url)) {
        const path = utilityService.extractRelativePath(url);
        history.push(path);
      } else {
        utilityService.launchURLHandler(service.serviceName, () => {
          return Promise.resolve({ data: url });
        });
      }
    } catch (e) {
      store.dispatch(
        toggleToast({
          variant: 'error',
          message: 'error.msg.default',
          title: 'sorry',
          isOpen: true,
          showCancelButton: true,
        })
      );
    }
  };

  /**
   * Function that handles the service click.
   *
   * @param {object} service - The service object.
   */
  const handleServiceClick = (service) => {
    let TRACK_EVENT;
    if (service.dynamicProvisioning) {
      TRACK_EVENT = PENDO_TRACK_EVENTS_ENUM.PARTNER_SITE_LAUNCHED;
      userService.registerAnalyticEvent(UserService.GAEvents.Category.Explore, 'Clicked', null, service.serviceName);
      handleDynamicProvisionalServices(service, handleServiceLaunch);
    } else if (service.displayCredential && !serviceCredentialsProps.isOpen) {
      handleServiceCredentialsModalOpen(service);
    } else if (service.isAudio) {
      TRACK_EVENT = PENDO_TRACK_EVENTS_ENUM.AUDIO_PLAYER_LAUNCHED;
      handleAudioPlayerModalOpen(service);
    } else if (service.isVideo) {
      TRACK_EVENT = PENDO_TRACK_EVENTS_ENUM.VIDEO_PLAYER_LAUNCHED;
      if (isBeingFree(service.serviceName)) {
        handleBeingFreePlayerModalOpen();
      } else {
        handleVideoPlayerModalOpen(service);
      }
    } else if (isCalculator(service.serviceName)) {
      if (calculatorRef.current) {
        TRACK_EVENT = PENDO_TRACK_EVENTS_ENUM.CALCULATOR_LAUNCHED;
        calculatorRef.current.setOpen(true);
        userService.registerAnalyticEvent(UserService.GAEvents.Category.Explore, 'Clicked', null, service.serviceName);
      }
    } else {
      TRACK_EVENT = PENDO_TRACK_EVENTS_ENUM.PARTNER_SITE_LAUNCHED;
      userService.registerAnalyticEvent(UserService.GAEvents.Category.Explore, 'Clicked', null, service.serviceName);
      handleServiceLaunch(service);
    }

    if (TRACK_EVENT) {
      trackPendoEvent(TRACK_EVENT, {
        id: service.id,
        name: service.serviceName,
      });
    }
  };

  /**
   * Function that checks if the service will open a link.
   *
   * @param {object} service - The service object.
   * @returns {boolean} - The result of the check.
   */
  const willOpenLink = (service) => {
    return !(service.displayCredential || service.isAudio || service.isVideo || isCalculator(service.serviceName));
  };

  return {
    categories,
    serviceCredentialsProps,
    audioPlayerProps,
    videoPlayerProps,
    beingFreePlayerProps,
    calculatorRef,
    isActiveCategory,
    handleActiveCategory,
    handleServiceClick,
    handleServiceCredentialsModalClose,
    handleAudioPlayerModalClose,
    handleVideoPlayerModalClose,
    handleBeingFreePlayerModalClose,
    willOpenLink,
    getServicesByCategoryId,
  };
};

export default useExploreListHook;
