import React from 'react';
import { withStyles, Box, Typography, Button } from '@material-ui/core';

import styles from './answer-option.style';
import { HappyFaceIcon, SadFaceIcon, SmilingFaceIcon, UnsureFaceIcon, UpsetFaceIcon } from '../../../../shared/icons';
import { Constant } from '../../../../shared/services';

const AnswerOptions = (props) => {
  const { classes, options, selectedValue, readOnly, onOptionClick, showSubtitle, subtitle } = props;

  const getIcon = (value) => {
    switch (value) {
      case '1':
        return <SadFaceIcon />;
      case '2':
        return <UpsetFaceIcon />;
      case '3':
        return <UnsureFaceIcon />;
      case '4':
        return <SmilingFaceIcon />;
      case '5':
        return <HappyFaceIcon />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.root}>
      {showSubtitle && (
        <Typography variant="h4" className={classes.title} gutterBottom>
          {subtitle}
        </Typography>
      )}
      {options.map((item) => (
        <Button
          key={item.name}
          tabIndex={readOnly ? -1 : 0}
          className={[
            classes.ratingBox,
            readOnly ? classes.readOnly : '',
            selectedValue === item.value ? classes.activeRating : '',
          ].join(' ')}
          onClick={() => {
            onOptionClick(item.value);
          }}
          startIcon={getIcon(item.value)}
          tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
          tracking-id={`select-interest-profiler-question-${props.questionId}-option-${item.value}`}
          aria-label={item.name}
        >
          <Typography className={classes.ratingText}>{item.name}</Typography>
        </Button>
      ))}
    </Box>
  );
};

AnswerOptions.defaultProps = {
  readOnly: false,
  selectedValue: '-1',
  options: [],
  onClick: () => {},
  showSubtitle: false,
  subtitle: '',
};

export default withStyles(styles)(AnswerOptions);
