import React from 'react';
import { withStyles, Snackbar, IconButton, Box, Typography, Button, Slide } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ReplayIcon from '@material-ui/icons/Replay';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import styles from './toast-notification.style';
import { FormattedMessage, useIntl } from 'react-intl';

const ToastNotification = (props) => {
  const {
    classes,
    variant,
    isOpen,
    onClose,
    message,
    messageValues,
    title,
    titleValues,
    showUndoButton,
    undoButtonProps,
    showReloadButton,
    showCancelButton,
    autoHideDuration,
  } = props;
  const intl = useIntl();

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      key={`top, center`}
      onClose={onClose}
      className={classes.root}
      autoHideDuration={autoHideDuration}
      TransitionComponent={(props) => <Slide {...props} direction="bottom" />}
    >
      <MuiAlert
        classes={{
          root: classes.alertRoot,
          action: classes.alertAction,
          filledError: classes.errorNotification,
          filledSuccess: classes.successNotification,
        }}
        onClose={onClose}
        severity={variant}
        elevation={6}
        variant="filled"
        iconMapping={{
          error: <ErrorIcon fontSize="large" />,
          success: <CheckCircleIcon fontSize="large" />,
        }}
      >
        <Box className={classes.alertDataWrapper}>
          <Box className={classes.titleContainer}>
            {title && (
              <Typography variant="h6" className={classes.title}>
                <FormattedMessage id={title} values={titleValues} />
              </Typography>
            )}
            <Typography className={classes.message}>
              <FormattedMessage id={message} values={messageValues} />
            </Typography>
          </Box>
          {showUndoButton && (
            <Button
              variant="outlined"
              className={classes.undoButton}
              onClick={(e) => {
                e.stopPropagation();
                undoButtonProps.onClick(e);
                onClose(e);
              }}
              aria-label={undoButtonProps.title}
            >
              {undoButtonProps.title}
            </Button>
          )}
          {showReloadButton ? (
            <IconButton
              size="small"
              className={[
                classes.reloadBtn,
                variant === 'success' ? classes.btnSuccess : variant === 'error' ? classes.btnError : '',
              ].join(' ')}
              onClick={() => window.location.reload()}
              aria-label={intl.formatMessage({ id: 'retry' })}
            >
              <ReplayIcon fontSize="small" />
            </IconButton>
          ) : (
            showCancelButton && (
              <IconButton
                size="small"
                className={[
                  classes.reloadBtn,
                  variant === 'success' ? classes.btnSuccess : variant === 'error' ? classes.btnError : '',
                ].join(' ')}
                onClick={onClose}
                aria-label={intl.formatMessage({ id: 'cancel' })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          )}
        </Box>
      </MuiAlert>
    </Snackbar>
  );
};

ToastNotification.defaultProps = {
  variant: 'success',
  autoHideDuration: 5000,
};

export default withStyles(styles)(ToastNotification);
