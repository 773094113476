import { MessagingService } from '../../../../content';
import { Constant } from '../../../../shared';

export const redirectToUrl = (url) => {
  window.location.href = url;
};

export const isPublicRoute = (routes) => {
  let currentRoute = window.location.pathname;

  // update currentRoute for language routes
  const isLanguageRoute = /\/login\/change-language\/([\w])+$/.test(currentRoute);
  if (isLanguageRoute) currentRoute = '/login/change-language/:locale';

  return routes.getRoutes('public').some((route) => route.path === currentRoute);
};

export const emitMessageEvent = (data) => {
  const event = new CustomEvent(MessagingService.NEW_MSG_EVENT, { detail: data });
  const messageElement = document.querySelector(`#${MessagingService.MESSAGE_ELEMENT}`);
  if (messageElement) {
    messageElement.dispatchEvent(event);
  }
};

export const refineTags = (str) => {
  if (!str) return str;
  let tag = str.split('_').join(' ');
  return tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase();
};

export const getCategoryFromName = (name) => {
  const lowerCaseName = name.toLowerCase();

  const category = Constant.CATEGORY_MAP.find((cat) => cat.keywords.some((keyword) => lowerCaseName.includes(keyword)));

  return category || null;
};
