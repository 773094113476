export default (theme) => ({
  root: {
    borderRadius: 10,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 30,
    height: '100%',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  topBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 300,
  },
  level1Banner: {
    background: 'url(https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Level1-BG.png)',
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  level2Banner: {
    background: 'url(https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Level2-BG.png)',
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  level3Banner: {
    background: 'url(https://content.connectedcorrections.com/assets/thumbnails/pdq/PDQ-Level3-BG.png)',
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  level1MainColor: {
    color: theme.palette.pdq.level1.main,
  },
  level1DarkColor: {
    color: theme.palette.pdq.level1.dark,
  },
  level2MainColor: {
    color: theme.palette.pdq.level2.main,
  },
  level2DarkColor: {
    color: theme.palette.pdq.level2.dark,
  },
  level3MainColor: {
    color: theme.palette.pdq.level3.main,
  },
  level3DarkColor: {
    color: theme.palette.pdq.level3.dark,
  },
  title: {
    textTransform: 'uppercase',
    fontFamily: 'Oswald',
    ...theme.typography.t60,
  },
  subtitle: {
    textTransform: 'uppercase',
    color: theme.palette.colors.grey,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateLabel: {
    ...theme.typography.t14,
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  dateValue: {
    ...theme.typography.t14,
    color: theme.palette.colors.grey,
    fontWeight: 500,
  },
  body: {
    padding: '50px 30px',
  },
  tabsContainer: {
    display: 'flex',
    borderBottom: '2px solid',
    borderColor: '#DFE6E9 !important',
    paddingBottom: 0,
  },
  tabItem: {
    width: 'initial',
    height: 40,
    fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    marginRight: 10,
    marginBottom: -2,
    whiteSpace: 'nowrap',
  },
  activeTab: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderBottom: '2px solid',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderRadius: 5,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 50,
    width: '100%',
  },
  footerContentLeft: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  footerContentRight: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginLeft: 'auto',
  },
  footerTitle: {
    ...theme.typography.t20,
    fontWeight: 700,
    textTransform: 'capitalize',
    padding: 0,
  },
  footerSubtitle: {
    ...theme.typography.t16,
    color: theme.palette.colors.grey,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  largeIcon: {
    fontSize: '2rem !important',
  },
  tabContent: {
    paddingTop: 50,
    maxWidth: 650,
  },
});
