import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';

import styles from './bookmarks.style';
import { CareersService } from '../../../careers/services/careers.service';
import { BackButton, Constant } from '../../../../shared';
import { CareerItem } from '../../../careers/components';
import { toggleToast } from '../../../../core';

export class Bookmarks extends React.Component {
  _service = new CareersService();
  constructor(props) {
    super(props);
    this.state = {
      careers: [],
      page: 0,
      pageSize: 10,
      total: 0,
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.getBookmarkedCareers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedLocale !== prevProps.selectedLocale) {
      this.getBookmarkedCareers();
    }
  }

  getBookmarkedCareers = async (cb) => {
    try {
      const { page, pageSize } = this.state;
      const data = await this._service.getBookmarkedCareers(page * pageSize, pageSize);
      if (cb) cb();
      if (data && data.payload) {
        const { careers, total } = data.payload;
        if (careers && careers.length >= 0) {
          this.setState({ careers, total });
        }
      }
    } catch (e) {
      console.log(e);
      if (cb) cb();
    }
  };

  handlePageChange = (e, value) => {
    this.setState({ page: value - 1 }, () => {
      this.getBookmarkedCareers();
      this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  getJobZone = (career) => {
    let zone = 1;
    if (career.education && career.education.job_zone) {
      zone = career.education.job_zone;
    }
    return zone;
  };

  getMedianPay = (career) => {
    let pay = 30000;
    if (career.job_outlook && career.job_outlook.salary && career.job_outlook.salary.annual_median) {
      pay = career.job_outlook.salary.annual_median;
    }
    return pay;
  };

  goBack = (e) => {
    e.stopPropagation();
    this.props.history.goBack();
  };

  goToCareerDirectory = (e) => {
    e.stopPropagation();
    this.props.history.push('/careers');
  };

  onItemClick = (e, id) => {
    e.stopPropagation();
    this.props.history.push(`/careers/${encodeURIComponent(id)}/profile`);
  };

  bookmarkCareer = async (id) => {
    try {
      const response = await this._service.addBookmark(id);
      if (response) {
        this.getBookmarkedCareers();
      }
    } catch (e) {
      console.log(e);
    }
  };

  removeBookmarkedCareer = async (id) => {
    this.props.toggleToast({
      ...this.props.app.toast,
      isOpen: false,
    });
    try {
      const response = await this._service.removeBookmark(id);
      if (response) {
        const careers = [...this.state.careers];
        const index = careers.findIndex((item) => item.code === id);
        if (index >= 0) {
          this.getBookmarkedCareers(() => {
            this.props.toggleToast({
              variant: 'success',
              message: 'career.bookmark.remove.msg.success',
              messageValues: { name: careers[index].title },
              title: 'success',
              autoHideDuration: 10000,
              isOpen: true,
              showCancelButton: true,
              showUndoButton: true,
              undoButtonProps: {
                title: <FormattedMessage id="undo" />,
                onClick: () => {
                  this.bookmarkCareer(careers[index].code);
                },
              },
            });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { careers, total, page, pageSize } = this.state;

    return (
      <Box className={classes.root}>
        <BackButton onClick={this.goBack} />

        <Grid sm={6} className={classes.textContainer}>
          <Typography className={classes.text}>
            <FormattedMessage id="bookmarks.text.description" />
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Box>
          <Typography
            id="bookmarked-careers-title"
            variant="h3"
            color="secondary"
            gutterBottom
            className={classes.mainTitle}
          >
            <FormattedMessage id="careers" />
          </Typography>
          {careers.length > 0 ? (
            <Box width="100%" marginTop="30px">
              <Box ref={this.scrollRef} />
              {careers.map((career) => (
                <CareerItem
                  key={career.code}
                  title={career.title}
                  subtitle={career.what_they_do}
                  zone={this.getJobZone(career)}
                  medianPay={this.getMedianPay(career)}
                  showZoneBadge={true}
                  showMedianPay={false}
                  onItemClick={(e) => {
                    this.onItemClick(e, career.code);
                  }}
                  isBookmarked={true}
                  removeBookmark={() => {
                    this.removeBookmarkedCareer(career.code);
                  }}
                  code={career.code}
                />
              ))}
            </Box>
          ) : (
            <>
              <Grid sm={6} className={classes.textContainer}>
                <Typography className={classes.text}>
                  <FormattedMessage id="bookmarks.text.noCareers" />
                </Typography>
              </Grid>
              <Button
                color="secondary"
                className={classes.actionBtn}
                onClick={this.goToCareerDirectory}
                tracking-type={Constant.TRACKING_TYPES.CAREER_DIRECTORY}
                tracking-id="view-careers"
              >
                <FormattedMessage id="goToCareerDirectory" />.
              </Button>
            </>
          )}
        </Box>

        <Grid container className={classes.footer}>
          <Grid item sm={12} md={12} lg={4} />
          <Grid item sm={12} md={12} lg={4} className={classes.paginationContainer}>
            {total > pageSize && (
              <Pagination
                count={Math.ceil(total / pageSize)}
                page={page + 1}
                siblingCount={0}
                onChange={this.handlePageChange}
                shape="rounded"
                color="secondary"
                size="large"
              />
            )}
          </Grid>
          <Grid container item sm={12} md={12} lg={4} />
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleToast: (toastData) => dispatch(toggleToast(toastData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Bookmarks));
