import { Http } from '../../../core';
import { Utility, UserService } from '../../../shared';

export class MessagingService {
  _utilityService = new Utility();

  static MESSAGE_ELEMENT = 'messaging-event';
  static SOCKET_DEBOUNCE_INTERVAL = 2000;
  static NEW_MSG_EVENT = 'new_staff_message';

  /**
   * @name fetchContacts
   * @desc Fetches contacts
   * @return {Promise}
   */
  fetchContacts = (isFlagEnabled, orderBy = 'recent_message') => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(
        `${isFlagEnabled ? '/v2' : ''}/threads?order_by=${orderBy}&sort_by=${
          orderBy === 'recent_message' ? 'desc' : 'asc'
        }&noLoader=true`
      )
        .then((_successLog) => {
          const { data } = _successLog;
          if (!data.messageThreads) {
            resolve([]);
            return;
          }

          resolve(data.messageThreads);
        })
        .catch((e) => console.log(e));
    });
  };

  /**
   * @name preprocessMessages
   * @param {array} messages
   * @desc pre-processes messages.
   * @return {array}
   */
  preprocessMessages = (messages) => {
    return messages.map((msg) => {
      const learnerTimezoneName = this._utilityService.getTimezoneName(
        UserService.userServerTimestamp.timeZone
      ).timezoneName;
      msg['createdDate'] = this._utilityService.parseTimeToNewZone(
        msg.createdDate,
        learnerTimezoneName,
        'MMM D YYYY hh:mm A'
      );
      if (!!msg['readDate']) {
        msg['readDate'] = this._utilityService.parseTimeToNewZone(msg.readDate, learnerTimezoneName, 'MMM D YYYY hh:mm A');
      }
      return msg;
    });
  };

  /**
   * @name fetchMessages
   * @desc Fetches Messages
   * @return {Promise}
   */
  fetchMessages = ({ threadKey, beforeId, limit, afterId, signal }) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(
        `/messages?thread_key=${threadKey}${beforeId ? `&before_id=${beforeId}` : ''}${
          afterId ? `&after_id=${afterId}` : ''
        }${limit ? `&limit=${limit}` : ''}&noLoader=true`,
        {
          cancelToken: signal,
        }
      )
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(this.preprocessMessages(data));
        })
        .catch((err) => {
          console.log(err);
          if (Http.axios.isCancel(err)) {
            console.log('Request Cancelled', err.message);
          }
        });
    });
  };

  markMessagesRead = (threadKey, recentMessageId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.put(`/messages?thread_key=${threadKey}&recent_message_id=${recentMessageId}&noLoader=true`).then(
        (_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        }
      );
    });
  };

  fetchCharacterLimit = () => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(`/messages/location/characterslimit?noLoader=true`)
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        })
        .catch((e) => console.log(e));
    });
  };

  postMessage = (payload) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.post('/messages?noLoader=true', payload)
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        })
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  fetchSearchLearner = (searchkey) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(`/contacts/search?search_key=${searchkey}&noLoader=true`)
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        })
        .catch((e) => console.log(e));
    });
  };

  /**
   * @name removeMessage
   * @desc remove Message
   * @return {Promise}
   */
  removeMessage = (messageId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.delete(`/messages?message_id=${messageId}`)
        .then((_successLog) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  };
}
