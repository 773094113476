import React from 'react';
import { Http } from '../../../../core';
import { Constant, Utility } from '../../../../shared/services/';
import { FlameIcon } from '../../../../shared/icons/';

class CAREER_DIRECTORY_CONSTANTS {
  static CAREER_VIDEOS_PATH = `${Constant.CDN_BASE_URL}/assets/career-videos/480p`;
  static DEFAULT_VIDEO_THUMBNAIL = `${this.CAREER_VIDEOS_PATH}/career_default.webp`;

  /**
   * Object containing career fit categories with their corresponding properties.
   * Each category includes:
   * - intlId: The internationalization id for the category.
   * - icon: The icon component for the category.
   * - fill: The fill color for the category.
   */
  static CAREER_FITS = {
    BEST: {
      intlId: 'bestFit',
      icon: (props) => (
        <>
          <FlameIcon {...props} />
          <FlameIcon {...props} />
          <FlameIcon {...props} />
        </>
      ),
      fill: (theme) => theme.palette.orange.main,
    },
    GREAT: {
      intlId: 'greatFit',
      icon: (props) => (
        <>
          <FlameIcon {...props} />
          <FlameIcon {...props} />
        </>
      ),
      fill: (theme) => theme.palette.green.main,
    },
    GOOD: {
      intlId: 'goodFit',
      icon: (props) => <FlameIcon {...props} />,
      fill: (theme) => theme.palette.yellow.main,
    },
  };
}

/**
 * @name formatSalaryFigure
 * @description Format salary figure
 * @param {Number} value
 * @param {Number} overValue
 * @returns {String}
 */
const formatSalaryFigure = (value, overValue) => {
  let figure;
  if (value && !isNaN(value)) {
    figure = Utility.numberWithCommas(value);
  } else if (overValue && !isNaN(overValue)) {
    figure = `${Utility.numberWithCommas(overValue)}+`;
  }
  return figure;
};

/**
 * @name prepareSalaryData
 * @description Prepare salary data
 * @param {Object} salary
 * @returns {Object}
 */
const prepareSalaryData = (salary) => {
  const {
    annual_10th_percentile,
    annual_median,
    annual_90th_percentile,
    annual_10th_percentile_over,
    annual_median_over,
    annual_90th_percentile_over,
    hourly_10th_percentile,
    hourly_median,
    hourly_90th_percentile,
    hourly_10th_percentile_over,
    hourly_median_over,
    hourly_90th_percentile_over,
  } = salary;

  let salaryData = {};
  let isHourlyPay = false;
  let lowerBound, median, upperBound;

  // ANNUAL
  lowerBound = formatSalaryFigure(annual_10th_percentile, annual_10th_percentile_over);
  median = formatSalaryFigure(annual_median, annual_median_over);
  upperBound = formatSalaryFigure(annual_90th_percentile, annual_90th_percentile_over);

  // HOURLY
  if (!(lowerBound && median && upperBound)) {
    lowerBound = formatSalaryFigure(hourly_10th_percentile, hourly_10th_percentile_over);
    median = formatSalaryFigure(hourly_median, hourly_median_over);
    upperBound = formatSalaryFigure(hourly_90th_percentile, hourly_90th_percentile_over);
    isHourlyPay = true;
  }

  if (lowerBound && median && upperBound) {
    salaryData = {
      lowerBound,
      median,
      upperBound,
      isHourlyPay,
    };
  }

  return salaryData;
};

/**
 * @name prepareJobOutlookData
 * @description Prepare job outlook data
 * @param {Object} jobOutlook
 * @returns {Object}
 */
const prepareJobOutlookData = (jobOutlook) => {
  return {
    category: jobOutlook?.outlook?.category,
    description: jobOutlook?.outlook?.description,
    salary: prepareSalaryData(jobOutlook?.salary || {}),
  };
};

/**
 * @name preparePersonalityData
 * @description Prepare personality data
 * @param {Object} personality
 * @param {Object} intl
 * @returns {Object}
 */
const preparePersonalityData = (personality, intl) => {
  let data = {};

  if (personality) {
    data = {
      description: personality.top_interest?.description,
      keyPoints: [
        {
          id: 'theyDoWellAt',
          name: intl.formatMessage({ id: 'theyDoWellAt' }),
          points: personality.work_styles?.element?.map((element) => ({ id: element.id, name: element.name })) || [],
        },
      ],
    };
  }
  return data;
};

/**
 * @name prepareSkillsAndTechnologyData
 * @description Prepare skills and technology data
 * @param {Array} skills
 * @param {Array} technology
 * @param {Object} intl
 * @returns {Array}
 */
const prepareSkillsAndTechnologyData = (skills, technology, intl) => {
  const data = [...prepareKeyPointsData(skills || [])];

  if (technology) {
    data.push({
      id: 'technology',
      name: intl.formatMessage({ id: 'career.technology.subtitle' }),
      points: technology.map((element, index) => ({ id: `tech-${index}`, name: element?.title?.name || '' })) || [],
    });
  }
  return data;
};

/**
 * @name prepareKeyPointsData
 * @description Prepare key points data
 * @param {Array} data
 * @returns {Array}
 */
const prepareKeyPointsData = (data) => {
  return data?.map((item) => ({
    id: item.title?.id,
    name: item.title?.name,
    points: item.element?.map((element) => ({ id: element.id, name: element.name })) || [],
  }));
};

/**
 * @name prepareSimilarJobsData
 * @description Prepare similar jobs data
 * @param {Object} similarJobs
 * @returns {Array}
 */
const prepareSimilarJobsData = (similarJobs) => {
  return similarJobs?.careers?.career?.map((job) => ({
    id: job.code,
    name: job.title,
  }));
};

/**
 * @name extractSalary
 * @description Extract salary
 * @param {Object} salary
 * @returns {String}
 */
const extractSalary = (salary) => {
  const { annual_median, annual_median_over, hourly_median, hourly_median_over } = salary;
  let value = formatSalaryFigure(annual_median, annual_median_over);
  if (!value) {
    value = formatSalaryFigure(hourly_median, hourly_median_over);
  }
  return value;
};

/**
 * @name extractQualification
 * @description Extract qualification
 * @param {Object} education
 * @returns {String}
 */
const extractQualification = (education) => {
  const { education_usually_needed } = education;
  const category = education_usually_needed?.category;
  return category?.[category.length - 1];
};

/**
 * @name transformCareerDetails
 * @description Transform career details
 * @param {Object} careerDetails
 * @param {Object} intl
 * @returns {Object}
 */
const transformCareerDetails = (careerDetails, intl) => {
  const {
    job_outlook,
    personality,
    abilities,
    knowledge,
    skills,
    technology,
    explore_more,
    title,
    fit,
    what_they_do,
    education,
    in_bookmarks,
    on_the_job,
    also_called,
  } = careerDetails;

  return {
    outline: {
      title,
      description: what_they_do,
      zone: education?.job_zone,
      isBookmarked: in_bookmarks,
      careerFit: fit?.toLowerCase(),
      salary: extractSalary(job_outlook?.salary || {}),
      qualification: extractQualification(education || {}),
      tasks: on_the_job?.task?.map((task, index) => ({ id: index, name: task })),
      alsoCalled: also_called?.title?.map((name, index) => ({ id: index, name })),
    },
    jobOutlook: prepareJobOutlookData(job_outlook),
    personality: preparePersonalityData(personality, intl),
    abilities: prepareKeyPointsData(abilities),
    knowledge: prepareKeyPointsData(knowledge),
    skillAndTechnology: prepareSkillsAndTechnologyData(skills, technology, intl),
    similarJobs: prepareSimilarJobsData(explore_more),
  };
};

/**
 * @name getCareerDetails
 * @description Get career details
 * @param {String} careerId
 * @param {Object} intl
 * @returns {Promise}
 */
const getCareerDetails = (careerId, intl) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get(`/services/career-directory/v1/${Constant.ROUTES.CAREERS}/${careerId}/`)
      .then((_successLog) => {
        if (_successLog?.data?.payload) {
          resolve(transformCareerDetails(_successLog.data.payload, intl));
        }
        resolve({
          outline: {},
          jobOutlook: {},
          personality: {},
          abilities: [],
          knowledge: [],
          skillAndTechnology: [],
          similarJobs: [],
        });
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name addBookmark
 * @param {String} careerId
 * @desc bookmark career using the career id.
 * @return {Promise}
 */
const addBookmark = (careerId) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.put(
      `/services/career-directory/v1/${Constant.ROUTES.BOOKMARKS}?noLoader=true`,
      {
        careerId,
      },
      {
        pendingApiKey: `${Constant.ROUTES.BOOKMARKS}/${careerId}`,
      }
    )
      .then((_successLog) => {
        resolve(_successLog.data);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name removeBookmark
 * @param {String} careerId
 * @desc remove bookmarked career using the career id.
 * @return {Promise}
 */
const removeBookmark = (careerId) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.delete(`/services/career-directory/v1/${Constant.ROUTES.BOOKMARKS}/${careerId}/?noLoader=true`, {
      pendingApiKey: `${Constant.ROUTES.BOOKMARKS}/${careerId}`,
    })
      .then((_successLog) => {
        resolve(_successLog.data);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name isVideoAvailable
 * @description Check if video is available
 * @param {String} url
 * @returns {Promise}
 */
const isVideoAvailable = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType?.startsWith('video')) {
          resolve(true);
        }
      }
      resolve(false);
    } catch (error) {
      reject(error);
    }
  });
};

export { CAREER_DIRECTORY_CONSTANTS, getCareerDetails, isVideoAvailable, addBookmark, removeBookmark };
