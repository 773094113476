import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './styles';

const logo = (props) => {
  const { classes } = props;

  return <img src="/assets/logo/Orijin-blue.png" className={[classes.logo, props.className].join(' ')} alt="Orijin" />;
};

export default withStyles(styles)(logo);
