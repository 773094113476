import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, ClickAwayListener, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import withUnleash from '../../../core/components/unleash/withUnleash';
import { SideMenu as SideMenuV2 } from '../../../v2/core/components';
import { SideMenu, Header, UnleashProvider } from '../../';
import { UnleashService } from '../../services/unleash/unleash';
import useStyles from './layout.style';
import { Loader } from '../../../shared/components';
import { Breadcrumbs } from '../../../v2/core/components';

function Layout(props) {
  const classes = useStyles();
  const { unleashProps } = props;
  const intl = useIntl();
  const [drawerOpen, setDrawerToggle] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerToggle(!drawerOpen);
  };
  const handleClickAway = () => {
    if (drawerOpen) {
      setDrawerToggle(false);
    }
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid className={classes.layoutWrapper}>
        <a href="#main-application" className={classes.skipLink}>
          {intl.formatMessage({ id: 'skipToMainContent' })}
        </a>
        <Header {...props} />
        <Box className={classes.mainWrapper}>
          {!props.isRequiredFormExist && (
            <Box component="div">
              <ClickAwayListener onClickAway={handleClickAway}>
                {unleashProps.isFlagEnabled() ? (
                  <SideMenuV2
                    drawer={{
                      open: drawerOpen,
                      handleDrawerToggle: handleDrawerToggle,
                    }}
                    {...props}
                  />
                ) : (
                  <SideMenu
                    drawer={{
                      open: drawerOpen,
                      handleDrawerToggle: handleDrawerToggle,
                    }}
                    {...props}
                  />
                )}
              </ClickAwayListener>
            </Box>
          )}
          <Box className={classes.mainContainer} component="div" id="main-container">
            <Grid container direction="column">
              <Loader />
              <UnleashProvider
                flagName={UnleashService.FLAGS.BREADCRUMB_NAVIGATION}
                enabledRenderFn={() => <Breadcrumbs {...props} />}
              />
              <Box component="main" id="main-application" className={classes.content} tabindex="0">
                {props.children}
              </Box>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </div>
  );
}

export default withUnleash(Layout, UnleashService.FLAGS.LEFT_NAV_UPDATE);
