import React from 'react';
import { withStyles, Badge } from '@material-ui/core';
import styles from './contact-item.styles';

import JusticeScalesBlue from '../../../../shared/icons/justice-scales-blue/justice-scales-blue.js';

const ContactItem = (props) => {
  const { contact, selected, classes } = props;

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: '12px',
      top: '20px',
      backgroundColor: '#db0028',
      color: theme.palette.primary.white,
    },
  }))(Badge);
  const BadgeSpacing = withStyles((theme) => ({
    badge: {
      right: '12px',
      top: '20px',
      color: theme.palette.primary.white,
    },
  }))(Badge);

  const onSelected = () => {
    props.onSelect(props.contact);
  };
  const roleType = contact && contact.roleTypes ? contact.roleTypes.split(',') : [];
  return (
    <>
      <div
        className={[
          classes.message,
          contact.unreadMessageCount && !selected ? classes.messageHighlight : '',
          selected ? classes.messageSelected : '',
        ].join(' ')}
        onClick={onSelected}
      >
        {
          <div className={classes.sidebarMsgWrapper}>
            {contact.roleName === 'Attorney' || roleType.includes('Attorney') ? (
              <div className={classes.attorneyParent}>
                <JusticeScalesBlue className={classes.attorneyIcon} />
              </div>
            ) : (
              <div
                style={{
                  marginBottom: '-9px',
                  width: '33px',
                }}
              >
                <span className={classes.attornayIcon}>&nbsp;</span>
              </div>
            )}
            <div
              style={{
                marginRight: '20px',
                marginBottom: '-13px',
                marginTop: '-30px',
              }}
            >
              {contact.unreadMessageCount ? <StyledBadge variant="dot"></StyledBadge> : <BadgeSpacing />}
              {contact.unreadMessageCount ? (
                <div id="contactname" className={classes.messageTextHighlight}>
                  {contact.contactName}
                </div>
              ) : (
                <div className={classes.messageText}>{contact.contactName}</div>
              )}
            </div>

            {contact.unreadMessageCount ? (
              <>
                <div id="unreadcount" className={classes.unreadCount}>
                  {contact.unreadMessageCount}
                </div>
              </>
            ) : (
              <>
                <div>
                  <p className={classes.unreadCount}></p>
                </div>
              </>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default withStyles(styles)(ContactItem);
