import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, Divider, Typography } from '@material-ui/core';

import styles from './my-profile.style';
import { Demographic } from '../../components';
import Personality from '../../components/personality/personality';
import { MyProfileService } from '../../services/my-profile.service';
import { Constant, LoaderHOC } from '../../../../shared';
import { DocumentTitle } from '../../../../v2/shared';

export class MyProfile extends React.Component {
  _service = new MyProfileService();
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      demographic: [],
      personality: [],
      answers: [],
      newAnswers: [],
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  fetchQuestions = async () => {
    try {
      const response = await this._service.getQuestions();
      if (response && response.data) {
        const { payload } = response.data;
        if (payload && payload.questions && payload.questions.length > 0) {
          const questionWithAnswers = this.setAnswers(payload.questions, payload.answers);
          const demographic = questionWithAnswers.filter(
            (question) => question.section === Constant.MY_PROFILE_QUESTION_SECTIONS.DEMOGRAPHIC
          );
          const personality = questionWithAnswers.filter(
            (question) => question.section === Constant.MY_PROFILE_QUESTION_SECTIONS.PERSONALITY
          );
          this.setState({ demographic, personality, answers: payload.answers });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  getUsersFullName = () => {
    const { userDetail } = this.props;
    return `${userDetail.firstName || ''} ${userDetail.lastName || ''}`;
  };

  handleModeChange = (mode) => {
    this.setState({ mode });
  };

  handleDemographicAnswerChange = (questionId, answer, maxSelection) => {
    const { demographic } = this.state;
    this.setState({
      demographic: this.getUpdatedQuestions(demographic, questionId, answer, maxSelection),
      newAnswers: this.putNewAnswers(questionId, answer, maxSelection),
    });
  };

  handlePersonalityAnswerChange = (questionId, answer, maxSelection) => {
    const { personality } = this.state;
    this.setState({
      personality: this.getUpdatedQuestions(personality, questionId, answer, maxSelection),
      newAnswers: this.putNewAnswers(questionId, answer, maxSelection),
    });
  };

  getUpdatedQuestions = (questions, id, answer, maxSelection) => {
    const index = questions.findIndex((question) => question.id === id);
    if ((Array.isArray(answer) && answer.length <= maxSelection) || !Array.isArray(answer)) {
      questions[index].answer = answer;
    }
    return [...questions];
  };

  putNewAnswers = (questionId, answerIds, maxSelection) => {
    const answer = { questionId, answerOptionIds: answerIds };
    const { newAnswers } = this.state;
    if ((Array.isArray(answerIds) && answerIds.length <= maxSelection) || !Array.isArray(answerIds)) {
      const index = newAnswers.findIndex((newAnswer) => newAnswer.questionId === questionId);
      if (index > -1) {
        newAnswers[index] = answer;
      } else {
        newAnswers.push(answer);
      }
    }
    return [...newAnswers];
  };

  setAnswers = (questions, answers) => {
    return questions.map((question) => {
      if (answers.length === 0) {
        delete question.answer;
      } else {
        const answer = answers.find((answer) => answer.questionId === question.id);
        if (answer && answer.answerOptionIds) {
          question.answer = question.maxSelection > 1 ? answer.answerOptionIds : answer.answerOptionIds[0];
        }
      }
      return question;
    });
  };

  onCancel = () => {
    this.setState(
      (prevState) => ({
        demographic: this.setAnswers(prevState.demographic, prevState.answers),
        personality: this.setAnswers(prevState.personality, prevState.answers),
        newAnswers: [],
        mode: 'view',
      }),
      () => {
        window.scrollTo(0, 0);
      }
    );
  };

  saveAnswers = () => {
    const { newAnswers } = this.state;
    if (newAnswers.length > 0) {
      this._service.saveAnswers({
        answers: newAnswers.map((answer) => {
          if (Array.isArray(answer.answerOptionIds)) {
            return answer;
          }
          return { ...answer, answerOptionIds: [answer.answerOptionIds] };
        }),
      });
    }
    this.setState({ mode: 'view', newAnswers: [] }, () => {
      window.scrollTo(0, 0);
    });
  };

  render() {
    const { classes, intl } = this.props;
    const { mode, demographic, personality } = this.state;

    return (
      <Box className={classes.root}>
        <DocumentTitle title="page.title.my-profile" />

        {/* BEGIN: HEADER */}
        <Box className={classes.header}>
          <Typography id="my-profile-title" variant="h2" gutterBottom className={classes.title}>
            <FormattedMessage id="myProfile.title" />
          </Typography>
          <Typography id="my-profile-desc" gutterBottom className={classes.description}>
            <FormattedMessage
              id="myProfile.description"
              values={{
                p: (...chunks) => <p>{chunks}</p>,
              }}
            />
          </Typography>
        </Box>
        {/* END: HEADER */}

        <Box className={classes.content}>
          {mode === 'view' && (
            <Button
              id="my-profile-edit-button"
              variant="contained"
              color="primary"
              className={classes.editButton}
              onClick={() => {
                this.handleModeChange('edit');
              }}
              aria-label={intl.formatMessage({ id: 'edit' })}
            >
              <FormattedMessage id="edit" />
            </Button>
          )}

          <Demographic
            name={this.getUsersFullName()}
            questions={demographic}
            mode={mode}
            handleAnswerChange={this.handleDemographicAnswerChange}
          />

          <Divider className={classes.divider} />

          <Personality questions={personality} mode={mode} handleAnswerChange={this.handlePersonalityAnswerChange} />

          {mode === 'edit' && (
            <Box className={classes.actionBox}>
              <Button
                id="my-profile-cancel-button"
                color="primary"
                className={classes.actionButton}
                onClick={this.onCancel}
                aria-label={intl.formatMessage({ id: 'cancel' })}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <LoaderHOC apiKey={'save-answers'}>
                <Button
                  id="my-profile-save-button"
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={this.saveAnswers}
                  disabled={this.state.newAnswers.length === 0}
                  aria-label={intl.formatMessage({ id: 'saveChanges' })}
                >
                  <FormattedMessage id="saveChanges" />
                </Button>
              </LoaderHOC>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    userDetail: app.userDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(MyProfile)));
