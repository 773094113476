import { useState } from 'react';
import { reportSelfProgress } from '../../services/career-paths.service';

const useProgramStatusHook = (props) => {
  const [progressState, setProgressState] = useState(props.progressionState || '-1');

  const progressStateOptions = [
    { value: '-1', label: 'pleaseSelect' },
    { value: 'NOT_STARTED', label: 'notStarted' },
    { value: 'IN_PROGRESS', label: 'inProgress' },
    { value: 'COMPLETED', label: 'completed' },
  ];

  const handleProgressStateChange = (event) => {
    const oldValue = progressState;
    const newValue = event.target.value;
    if (oldValue !== newValue) {
      setProgressState(newValue);
      reportSelfProgress(props.programId, newValue);
      if (newValue === 'COMPLETED') {
        props.increaseDecreaseCompletedCount(1);
      } else if (oldValue === 'COMPLETED') {
        props.increaseDecreaseCompletedCount(-1);
      }
    }
  };

  return {
    progressState,
    progressStateOptions,
    handleProgressStateChange,
  };
};

export default useProgramStatusHook;
