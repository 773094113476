export default (theme) => ({
  headingWrapper: {
    display: 'inline-block',
    zIndex: 99,
    fontWeight: 700,
    position: 'absolute',
    background: 'white',
    borderRadius: '16px 16px 0px 0px',
    padding: '10px 15px 0px 15px',
    bottom: '-11%',
    left: 35,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    padding: '32px 50px 32px 50px',
    backgroundColor: 'white',
    borderRadius: '0px 0px 16px 16px',
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  description: {
    whiteSpace: 'break-spaces',
    color: theme.palette.greyScale[110],
  },
  bannerContainer: {
    height: 91,
    width: '100%',
    overflow: 'hidden',
    borderRadius: '16px 16px 0px 0px',
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  enrollBtnContainer: {
    display: 'flex',
    '& > button': {
      backgroundColor: theme.palette.aqua.extraDeep,
    },
  },
  unenrollButton: {
    backgroundColor: `${theme.palette.primary.white} !important`, //to override the current background color
  },
});
