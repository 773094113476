import React from "react";

const SmilingFaceIcon = props => {
  const { size, fill, opacity } = props;

  return (
    <svg style={{opacity: opacity}} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 55 55">
      <defs>
        <clipPath id="clip-Smiling_Face">
          <rect width="55" height="55"/>
        </clipPath>
      </defs>
      <g id="Smiling_Face" data-name="Smiling Face" clipPath="url(#clip-Smiling_Face)">
        <g id="face-smile-duotone" transform="translate(-127 -1621)">
          <path id="Path_92714" data-name="Path 92714" d="M0,27.5A27.5,27.5,0,1,1,27.5,55,27.5,27.5,0,0,1,0,27.5Zm17.628,7.466a1.721,1.721,0,0,0-2.6,2.256,16.405,16.405,0,0,0,24.943,0,1.721,1.721,0,1,0-2.6-2.256A12.986,12.986,0,0,1,27.4,39.531a12.857,12.857,0,0,1-9.775-4.565Zm1.321-16.06a3.438,3.438,0,1,0,3.438,3.438A3.434,3.434,0,0,0,18.949,18.906Zm17.188,6.875A3.438,3.438,0,1,0,32.7,22.344,3.441,3.441,0,0,0,36.137,25.781Z" transform="translate(127 1621)" fill={fill}/>
        </g>
      </g>
    </svg>
  );
};

SmilingFaceIcon.defaultProps = {
  size: 24,
  opacity: 1,
  fill: "inherit"
}

export default SmilingFaceIcon;
