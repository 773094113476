import * as store from "./";

const updatePlayStatus = status => {
  return {
    type: store.UDPATE_PLAY_STATUS,
    status
  };
};

export { updatePlayStatus };
