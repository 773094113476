import React from 'react';
import { Dialog, DialogContent, withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './being-free-player.style';
import { useIntl } from 'react-intl';

const BeingFreePlayer = (props) => {
  const { classes, isOpen, handleClose } = props;
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ elevation: 0 }}
      classes={{ paper: classes.root }}
    >
      <IconButton onClick={handleClose} className={classes.closeButton} aria-label={intl.formatMessage({ id: 'close' })}>
        <CloseIcon fontSize="large" className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.content}>
        <iframe
          title="Being Free Player"
          id="being-free-player-iframe"
          src="/being-free-player/index.html"
          className={classes.iframe}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(BeingFreePlayer);
