export default (theme) => ({
  mainBox: {
    width: '100%',
  },
  hotlineSection: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    color: '#0f2c54',
    marginLeft: '15px',
  },
  subtext: {
    marginTop: '2%',
    fontSize: '1rem',
    whiteSpace: 'pre-line',
    paddingBottom: 15,
    marginLeft: '15px',
  },
  voiceCallImg: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  inputField: {
    margin: theme.spacing(1),
    '& input': {
      borderRadius: '20px',
    },
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    borderRadius: '10px',
  },
  hotlineContacts: {
    alignItems: 'center',
  },
  InputLabel: {
    marginLeft: '5px',
  },
  tabCard: {
    width: '-webkit-fill-available',
  },
  dialButton: {
    borderRadius: '20px',
    color: theme.palette.primary.main,
    marginLeft: '92px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    width: '50%',
  },
  mainContainerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px',
  },
  formattedParagraph: {
    fontFamily: 'inherit',
    fontSize: '2rem',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    marginTop: '50px',
    whiteSpace: 'pre-line',
  },
  boldText: {
    textAlign: 'center',
    padding: '10px',
  },

  noContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4%',
  },
  sosIcon: {
    background: theme.palette.green.pale,
    borderRadius: '50%',
    color: theme.palette.primary.deep,
    width: '50px !important',
    height: '50px !important',
    padding: 10,
  },
  contactName: {
    color: theme.palette.primary.deep,
    fontWeight: 700,
  },
  contactRow: {
    marginLeft: '16px',
    width: '611px',
    marginBottom: 10,
    borderRadius: 10,
    boxShadow: 'none',
    border: `0.5px solid ${theme.palette.primary.greyed}`,
    marginRight: 24,
  },
});
