import { useState } from 'react';

const useNotificationBannerHook = () => {
  const [showNotification, setShowNotification] = useState(true);

  const hideNotification = () => {
    setShowNotification(false);
  };

  return {
    showNotification,
    hideNotification,
  };
};

export default useNotificationBannerHook;
