export default theme => ({
    root: {
        borderRadius: 12,
        background: "white",
        padding: 25,
        minHeight: 350,
        height: "100%"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    mainTitle: {
        ...theme.typography.t24,
        fontWeight: 700,
        textTransform: "capitalize"
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    description: {
        color: theme.palette.text.sub,
        fontSize: "1rem",
        '&:first-letter': {
            textTransform: "capitalize"
        }
    },
    divider: {
        width: "100%",
        margin: "20px 0px"
    },
    categoryBadge: {
        padding: "5px 10px",
        borderRadius: 5,
        background: "#E2E9EF"
    },
    categoryDesc: {
        color: theme.palette.text.sub,
        marginBottom: 20,
        fontWeight: "normal"
    }
});
  