import { useEffect, useState } from 'react';
import { getCareerPathDetails, pinCareerPath, unpinCareerPath } from '../../services/career-paths.service';

const useCareerPathDetailsHook = (props) => {
  const [details, setDetails] = useState({});
  const [loaderApiType, setLoaderApiType] = useState('paths/pin');
  const careerPathId = props.match.params.id;

  useEffect(() => {
    (async () => {
      try {
        const response = await getCareerPathDetails(careerPathId);
        if (response?.data) {
          const { payload } = response.data;
          setDetails(calculateProgress(payload) || {});
          if (payload?.pinned) {
            setLoaderApiType('paths/unpin');
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [props.selectedLocale, careerPathId]);

  const calculateProgress = (details) => {
    const { categories } = details;
    let completedPrograms = 0;
    if (categories?.length > 0) {
      categories.forEach((category) => {
        const { programs } = category;
        if (programs?.length > 0) {
          programs.forEach((program) => {
            if (program.progressionState === 'COMPLETED') {
              completedPrograms++;
            }
          });
        }
      });
      details.programsCompleted = completedPrograms;
    }
    return details;
  };

  const increaseDecreaseCompletedCount = (value) => {
    setDetails({ ...details, programsCompleted: details.programsCompleted + value });
  };

  const handlePinClick = async () => {
    try {
      if (details?.pinned) {
        await unpinCareerPath(careerPathId);
        setLoaderApiType('paths/pin');
        setDetails({ ...details, pinned: false });
      } else {
        await pinCareerPath(careerPathId);
        setLoaderApiType('paths/unpin');
        setDetails({ ...details, pinned: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const launchProgram = (id) => {
    props.history.push(`/programs/${id}/details`);
  };

  const launchCareerProfile = () => {
    if (details.careerId) {
      props.history.push(`/careers/${details.careerId}/profile`);
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  return {
    details,
    careerPathId,
    loaderApiType,
    launchCareerProfile,
    handlePinClick,
    launchProgram,
    increaseDecreaseCompletedCount,
    goBack,
  };
};

export default useCareerPathDetailsHook;
