import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { showHidePopup, store } from '../../../core';

let timerInterval = null;
function clearTimerInterval() {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
}

function SessionOut(props) {
  const [timer, setTimer] = React.useState(5);
  const intl = useIntl();

  React.useEffect(() => {
    timerInterval = setInterval(() => {
      if (!props.isOpen) {
        return;
      }
      console.log({ timerInitiated: timer });

      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        clearTimerInterval();
        if (props.onConfirm) {
          props.onConfirm();
        }
      }
    }, 1000);
    return () => {
      clearTimerInterval();
    };
  });

  const handleConfirm = () => {
    clearTimerInterval();
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  const handleClose = () => {
    clearTimerInterval();
    store.dispatch(showHidePopup({ isOpen: false }));
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id={props.title} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id={props.message} />
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id="messageSessionTimeout" values={{ timer }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.onClose ? (
            <Button onClick={handleClose} color="primary" aria-label={intl.formatMessage({ id: 'close' })}>
              <FormattedMessage id="close" />
            </Button>
          ) : null}
          {props.onConfirm ? (
            <Button onClick={handleConfirm} color="primary" aria-label={intl.formatMessage({ id: 'ok' })}>
              <FormattedMessage id="ok" />
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state.app;
};

export default connect(mapStateToProps)(SessionOut);
