import * as store from ".";

export function addPendingAPIKey(pendingAPIObj) {
  return { type: store.ADD_API_KEY, pendingAPIObj };
}

export function removePendingAPIKey(pendingAPIObj) {
  return { type: store.REMOVE_API_KEY, pendingAPIObj };
}

