import { ProgramConstants } from '../../../../../content/programs/services/programs.service';
import { Rest, UserService } from '../../../../../shared/services';

const useLearningModuleHook = (props) => {
  const _userService = new UserService();
  const { showProgress, showLaunchButton, progress } = props;

  const launchModule = async (id, name) => {
    if (!id) return;

    _userService.registerAnalyticEvent(UserService.GAEvents.Category.Pathways, 'Clicked', null, name);

    const url = await new Rest().getLaunchUrl(id);
    window.open(url.data, '_blank');

    await new Rest().userInteraction({
      resourceId: id,
      lastActivityDate: Date.now(),
      source: 'program-details',
      courseResourceId: id,
    });
  };

  const shouldShowProgressOrLaunch = () => {
    return showProgress || showLaunchButton;
  };

  const hasProgress = () => {
    return showProgress && ProgramConstants.LEARNING_MODULE_PROGRESS.NOT_STARTED !== progress;
  };

  return {
    launchModule,
    hasProgress,
    shouldShowProgressOrLaunch,
  };
};

export default useLearningModuleHook;
