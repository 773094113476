import React from 'react';
import { Provider } from 'react-redux';
import configureStore from 'redux-mock-store';
import { Testable } from '..';

const mockStore = configureStore([]);

const TestableWithStore = (props) => {
  const store = mockStore(props.store);
  return (
    <Provider store={store}>
      <Testable initialThemeWidth={props.initialThemeWidth}>{props.children}</Testable>
    </Provider>
  );
};

export default TestableWithStore;
