import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThumbnailCard } from '../../../../../shared/components';
import { Constant } from '../../../../../shared/services';
import { FormattedMessage, useIntl } from 'react-intl';
import useResumeBuilderTileHook from './useResumeBuilderTileHook';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';
import { ThumbnailCard as ThumbnailCardV2 } from '../../../../shared';

const ResumeBuilderTile = (props) => {
  const { showTile, launchResumeBuilder } = useResumeBuilderTileHook(props);
  const { unleashProps } = props;
  const intl = useIntl();

  if (!showTile()) {
    return null;
  }

  return (
    <>
      {unleashProps.isFlagEnabled() ? (
        <Grid item sm={12} md={4}>
          <ThumbnailCardV2
            title={intl.formatMessage({ id: 'resume-builder-tile.title' })}
            description={intl.formatMessage({ id: 'resume-builder-tile.description' })}
            thumbnailUrl={`${Constant.CDN_BASE_URL}/assets/thumbnails/resume-builder-thumbnail.webp`}
            trackingType={Constant.TRACKING_TYPES.SERVICE}
            trackingId={`launch-service-resume-builder`}
            onClick={launchResumeBuilder}
          />
        </Grid>
      ) : (
        <Grid item sm={6} md={6}>
          <ThumbnailCard
            handleLaunchClick={launchResumeBuilder}
            id="resume-builder-thumbnail-tile"
            name={<FormattedMessage id="resume-builder-tile.title" />}
            thumbnailUri={`${Constant.CDN_BASE_URL}/assets/thumbnails/resume-builder-thumbnail.webp`}
            description={<FormattedMessage id="resume-builder-tile.description" />}
            serviceUrl="/resume-builder"
            isRelativePath={true}
          />
        </Grid>
      )}
    </>
  );
};

ResumeBuilderTile.propTypes = {
  categoryId: PropTypes.number.isRequired,
  features: PropTypes.array.isRequired,
};

const mapStateToProps = ({ app }) => ({
  features: app.features,
});

export default connect(mapStateToProps)(withUnleash(ResumeBuilderTile, UnleashService.FLAGS.EXPLORE_PAGE_REDESIGN));
