import React from 'react';
import { withStyles, Box, Typography } from '@material-ui/core';

import styles from './personality-result.style';
import { FormattedMessage } from 'react-intl';
import { Constant } from '../../../../shared/services';

const getPersonalityAreaImage = (area) => {
  switch (area) {
    case 'REALISTIC':
      return 'https://content.connectedcorrections.com/assets/img/personality-realistic.svg';
    case 'INVESTIGATIVE':
      return 'https://content.connectedcorrections.com/assets/img/personality-investigative.svg';
    case 'ARTISTIC':
      return 'https://content.connectedcorrections.com/assets/img/personality-artistic.svg';
    case 'SOCIAL':
      return 'https://content.connectedcorrections.com/assets/img/personality-social.svg';
    case 'ENTERPRISING':
      return 'https://content.connectedcorrections.com/assets/img/personality-enterprising.svg';
    case 'CONVENTIONAL':
      return 'https://content.connectedcorrections.com/assets/img/personality-conventional.svg';
    default:
  }
};

const PersonalityResult = (props) => {
  const { classes, areaPlacement, final_result, showZoneInfo, recommendedJobZone } = props;

  return (
    <Box className={classes.root}>
      <Box className={classes.imageContainer}>
        <img
          id="personality-result-image"
          alt=""
          src={getPersonalityAreaImage(Constant.PERSONALITY_AREAS[final_result.area.toUpperCase()])}
          className={classes.image}
        />
        {areaPlacement === 'bottom-left' && (
          <Box className={[classes.areaContainer, classes.centeredText].join(' ')}>
            <Typography id="personality-result-title" className={classes.mainTitle}>
              <FormattedMessage id="personalityResultsTitle" />
            </Typography>
            <Typography id="personality-result-area" variant="h2" className={classes.personalityArea}>
              {final_result.area}
            </Typography>
          </Box>
        )}
      </Box>

      <Box className={classes.content}>
        {areaPlacement === 'top-right' && (
          <Box className={classes.areaContainer}>
            <Typography id="personality-result-title" className={[classes.mainTitle].join(' ')}>
              <FormattedMessage id="workplacePersonality" />
            </Typography>
            <Typography
              id="personality-result-area"
              variant="h2"
              className={[classes.personalityArea, classes.paddingBottom].join(' ')}
            >
              {final_result.area}
            </Typography>
          </Box>
        )}

        <Typography
          id="personality-result-description"
          variant="h4"
          color="secondary"
          className={classes.aboutYourPersonality}
        >
          <FormattedMessage id="aboutYourPersonality" />
        </Typography>

        <Typography gutterBottom>{final_result.description}</Typography>

        {showZoneInfo && recommendedJobZone && (
          <Box className={classes.zoneInfo}>
            <Typography className={classes.mainTitle}>
              <FormattedMessage id="jobZone" />
            </Typography>
            <Typography
              id="recommended-job-zone"
              variant="h2"
              className={[classes.personalityArea, classes.paddingBottom, classes[`zone${recommendedJobZone}Color`]].join(
                ' '
              )}
            >
              <FormattedMessage id="zone" /> {recommendedJobZone}
            </Typography>
            <Typography id="recommended-job-zone-description">
              <FormattedMessage id={`jobZone${recommendedJobZone}.subtitle`} />
            </Typography>
            <Typography id="recommended-job-zone-experience" className={classes.marginTop}>
              <FormattedMessage id={`jobZone${recommendedJobZone}.experience`} />
            </Typography>
            <Typography id="recommended-job-zone-education" className={classes.marginTop}>
              <FormattedMessage id={`jobZone${recommendedJobZone}.education`} />
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(PersonalityResult);
