export default (theme) => ({
  root: {
    borderRadius: 10,
    overflow: 'hidden',
    color: 'unset',
  },
  topBarContainer: {
    borderRadius: '10px 10px 0px 0px',
    margin: '-4px -4px 0px',
    background: 'var(--primary-deep)',
    padding: 5,
    position: 'relative',
  },
  topBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 35,
  },
  logoContainer: {
    marginRight: 30,
  },
  logo: {
    width: 150,
    height: 85,
  },
  modalTitle: {
    color: 'white',
    fontSize: 32,
    fontFamily: 'var(--font-main)',
    fontWeight: 800,
    textTransform: 'capitalize',
    marginBottom: '0.5rem',
  },
  closeButton: {
    position: 'absolute',
    top: '30px',
    right: '15px',
    height: 45,
    width: 45,
    color: 'white',
  },
  title: {
    padding: 30,
    textAlign: 'center',
    color: 'var(--primary-deep)',
    fontSize: 20,
    fontFamily: 'var(--font-main)',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  content: {
    padding: '0px 25px',
  },
  effectiveDate: {
    height: 16,
    fontFamily: 'Rubik',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '1.5px',
    marginBottom: '1rem',
    color: 'var(--primary-deep)',
  },
});
