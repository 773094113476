export default (theme) => ({
  root: {
    borderRadius: 12,
    background: 'white',
  },
  imageOverlay: {
    width: '100%',
    height: 150,
    borderRadius: '12px 12px 0px 0px',
  },
  titleWrapper: {
    padding: 25,
  },
  titleContainer: {
    padding: 20,
    marginTop: -80,
    width: '100%',
    background: 'white',
    borderRadius: '12px 12px 0px 0px',
  },
  title: {
    fontWeight: 700,
  },
  descriptionContainer: {
    padding: '0px 20px',
  },
  description: {
    color: theme.palette.text.sub,
    whiteSpace: 'break-spaces',
  },
  actionContainer: {
    padding: '20px 20px 0px 20px',
  },
  launchCareerButton: {
    '&$careerPathBtn': {
      ...theme.typography.t18,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      textTransform: 'capitalize',
      fontWeight: 700,
      padding: 0,
    },
  },
  careerPathBtn: {
    // need this for the class to show up
  },
  progressContainer: {
    padding: 45,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progressArea: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 250,
    },
  },
  progressLabelsArea: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 10,
  },
  progressLabel: {
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.palette.text.sub,
  },
  progressCount: {
    fontWeight: 700,
  },
  progressBarRoot: {
    height: 10,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  progressBarColorPrimary: {
    backgroundColor: 'white',
  },
  progressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  pinToHomeBtn: {
    ...theme.typography.t20,
    textTransform: 'capitalize',
    fontWeight: 700,
    color: theme.palette.text.primary,
    fill: theme.palette.primary.main,
  },
  pinnedBtn: {
    backgroundColor: theme.palette.primary.light,
  },
});
