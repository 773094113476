export default (theme) => ({
  root: {
    borderRadius: 12,
    background: 'white',
    padding: 25,
    height: '100%',
    width: '100%',
  },
  container: {
    '&:focus-within': {
      outline: 'none',
    },
  },
  mainTitle: {
    ...theme.typography.t24,
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  description: {
    marginTop: 20,
    fontWeight: 500,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  divider: {
    width: '100%',
    margin: '20px 0px',
  },
  question: {
    paddingTop: 10,
  },
  optionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: 20,
    borderRadius: 10,
    width: '100%',
    border: '1px solid #DFE6E9',
    textTransform: 'none',
  },
  selectedOption: {
    borderColor: theme.palette.primary.main,
    backgroundColor: '#DAEBED',
  },
  optionText: {
    fontWeight: 500,
  },
  radioBtn: {
    width: 24,
    height: 24,
    borderRadius: 12,
    border: '1px solid',
    borderColor: theme.palette.text.primary,
  },
  backBtn: {
    color: theme.palette.text.primary,
    border: '1px solid #D6E5ED',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 40,
  },
  nextBtn: {
    backgroundColor: theme.palette.primary.active,
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.primary.active,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progressArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0px 30px',
  },
  progressLabelsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  progressLabel: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  progressBarRoot: {
    height: 10,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.active,
  },
  progressBarColorPrimary: {
    backgroundColor: 'white',
  },
  progressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.active,
  },
});
