export default (theme) => ({
  contentAreaBtn: {
    borderRadius: 20,
    textTransform: 'capitalize',
    fontSize: theme.typography.t16.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
  },
  viewAllBtn: {
    padding: '5px 40px',
  },
  educationFilled: {
    backgroundColor: '#4E9D97',
    fill: 'white',
    color: 'white',
  },
  education: {
    color: '#4E9D97',
    fill: '#4E9D97',
  },
  rehabilitationFilled: {
    backgroundColor: theme.palette.yellow.main,
    fill: 'white',
    color: 'white',
  },
  rehabilitation: {
    color: theme.palette.yellow.main,
    fill: theme.palette.yellow.main,
  },
  workforceFilled: {
    backgroundColor: theme.palette.secondary.main,
    fill: 'white',
    color: 'white',
  },
  workforce: {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  },
  reentryFilled: {
    backgroundColor: theme.palette.orange.main,
    fill: 'white',
    color: 'white',
  },
  reentry: {
    color: theme.palette.orange.main,
    fill: theme.palette.orange.main,
  },
  enrichmentFilled: {
    backgroundColor: theme.palette.blue.main,
    fill: 'white',
    color: 'white',
  },
  enrichment: {
    color: theme.palette.blue.main,
    fill: theme.palette.blue.main,
  },
  disabledContentArea: {
    color: theme.palette.greyScale[100],
    fill: theme.palette.greyScale[100],
  },
});
