import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { StarsIcon } from '../../../../shared/icons';

const RemainingTokens = (props) => {
  const { balance } = props;
  return (
    <Box display="flex" alignItems={'center'} justifyContent={'flex-end'}>
      <StarsIcon /> &nbsp;
      <Typography id="token-count" variant="h4">
        {balance} <FormattedMessage id="tokens" />
      </Typography>
    </Box>
  );
};

const mapStateToProps = ({ app }) => ({
  balance: app.balance,
});

export default connect(mapStateToProps, null)(RemainingTokens);
