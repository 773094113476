import React from 'react';
import { Grid, Button } from '@material-ui/core';
import useStyles from './error.style';
import { useIntl } from 'react-intl';

export function Error(props) {
  const classes = useStyles();
  const intl = useIntl();

  if (props.isAccessDenied) {
    setTimeout(() => {
      props.handleLogout();
    });
    return null;
  }

  return (
    <Grid container item direction="column" alignItems="center" justify="center" className={classes.errorBox}>
      <h2>{props.errorCode}</h2>
      <p>{props.errorMessage}</p>
      <Button
        onClick={props.handleLogout}
        id="login-button"
        className={classes.confirmBtn}
        aria-label={intl.formatMessage({ id: 'logout' })}
      >
        {props.logInOutText}
      </Button>
    </Grid>
  );
}

export default Error;
