import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

const CallCenterScheduleGrid = ({ scheduleRecords, classes }) => {
  const renderTimeSlot = (slot) => (
    <Typography>
      {moment.unix(slot.start).format('hh:mm A')} &nbsp;-&nbsp; {moment.unix(slot.end).format('hh:mm A')}
    </Typography>
  );

  if (!scheduleRecords) {
    return null;
  }

  return (
    <Box className={classes.callCenterSection}>
      <Grid container spacing={2} className={classes.scheduleGrid}>
        {scheduleRecords.map(({ dayOfWeek, slots }) => (
          <Grid item xs={12} sm={6} key={dayOfWeek} className={classes.scheduleRow}>
            <Typography variant="body1" className={classes.dayOfWeek}>
              {moment(dayOfWeek, 'dddd').format('dddd').toUpperCase()}
            </Typography>
            {slots && slots.some((slot) => !slot.isClosed) ? (
              slots.map((slot) => (
                <Box key={`${dayOfWeek}-${slot.start}-${slot.end}`} className={classes.timeSlot}>
                  {slot.start && slot.end && renderTimeSlot(slot)}
                </Box>
              ))
            ) : (
              <Typography variant="body2">N/A</Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

CallCenterScheduleGrid.propTypes = {
  scheduleRecords: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string.isRequired,
      slots: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.number.isRequired,
          end: PropTypes.number.isRequired,
          isClosed: PropTypes.bool.isRequired,
        })
      ),
    })
  ),
  classes: PropTypes.object.isRequired,
};

export default CallCenterScheduleGrid;
