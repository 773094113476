import React from 'react';
import PropTypes from 'prop-types';
import Closed from '../closed/closed';
import useScheduleWrapperHook from './useScheduleWrapperHook';

const ScheduleWrapper = (props) => {
  const { featureName, title, subtitle, children } = props;
  const { isLoading, isClosed, getSchedule } = useScheduleWrapperHook(featureName);

  if (isLoading) {
    return null;
  }

  if (isClosed) {
    return <Closed align="center" mainText={title} subText={subtitle} schedule={getSchedule()} />;
  }

  return children;
};

ScheduleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  featureName: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ScheduleWrapper;
