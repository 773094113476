import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

export default class Copyright extends React.Component {
  state = {
    open: true
  };

  handleClose = () => {
    this.setState({
      open: false
    });
    this.props.history.push("/pathways");
  };

  showMarkup = () => {
    return {
      __html: `
            <p></p>
            <!-- [if gte mso 9]><xml>
             <o:DocumentProperties>
              <o:Author>SAustin@vlplawgroup.com</o:Author>
              <o:LastAuthor>Microsoft Office User</o:LastAuthor>
              <o:Revision>2</o:Revision>
              <o:TotalTime>1</o:TotalTime>
              <o:Created>2019-05-09T19:32:00Z</o:Created>
              <o:LastSaved>2019-05-09T19:32:00Z</o:LastSaved>
              <o:Pages>3</o:Pages>
              <o:Words>984</o:Words>
              <o:Characters>5615</o:Characters>
              <o:Lines>46</o:Lines>
              <o:Paragraphs>13</o:Paragraphs>
              <o:CharactersWithSpaces>6586</o:CharactersWithSpaces>
              <o:Version>16.00</o:Version>
             </o:DocumentProperties>
             <o:OfficeDocumentSettings>
              <o:AllowPNG/>
             </o:OfficeDocumentSettings>
            </xml><![endif]-->
            <p> </p>
            <!-- [if gte mso 9]><xml>
             <w:WordDocument>
              <w:SpellingState>Clean</w:SpellingState>
              <w:GrammarState>Clean</w:GrammarState>
              <w:TrackMoves>false</w:TrackMoves>
              <w:TrackFormatting/>
              <w:PunctuationKerning/>
              <w:ValidateAgainstSchemas/>
              <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
              <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
              <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
              <w:DoNotPromoteQF/>
              <w:LidThemeOther>EN-US</w:LidThemeOther>
              <w:LidThemeAsian>JA</w:LidThemeAsian>
              <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
              <w:Compatibility>
               <w:BreakWrappedTables/>
               <w:SnapToGridInCell/>
               <w:WrapTextWithPunct/>
               <w:UseAsianBreakRules/>
               <w:DontGrowAutofit/>
               <w:SplitPgBreakAndParaMark/>
               <w:EnableOpenTypeKerning/>
               <w:DontFlipMirrorIndents/>
               <w:OverrideTableStyleHps/>
               <w:UseFELayout/>
              </w:Compatibility>
              <w:DoNotOptimizeForBrowser/>
              <m:mathPr>
               <m:mathFont m:val="Cambria Math"/>
               <m:brkBin m:val="before"/>
               <m:brkBinSub m:val="&#45;-"/>
               <m:smallFrac m:val="off"/>
               <m:dispDef/>
               <m:lMargin m:val="0"/>
               <m:rMargin m:val="0"/>
               <m:defJc m:val="centerGroup"/>
               <m:wrapIndent m:val="1440"/>
               <m:intLim m:val="subSup"/>
               <m:naryLim m:val="undOvr"/>
              </m:mathPr></w:WordDocument>
            </xml><![endif]--><!-- [if gte mso 9]><xml>
             <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
              DefSemiHidden="false" DefQFormat="false" DefPriority="99"
              LatentStyleCount="376">
              <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
              <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
              <w:LsdException Locked="false" Priority="9" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 6"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 7"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 8"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index 9"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 1"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 2"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 3"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 4"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 5"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 6"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 7"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 8"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" Name="toc 9"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Normal Indent"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="footnote text"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="annotation text"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="header"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="footer"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="index heading"/>
              <w:LsdException Locked="false" Priority="35" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="caption"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="table of figures"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="envelope address"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="envelope return"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="footnote reference"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="annotation reference"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="line number"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="page number"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="endnote reference"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="endnote text"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="table of authorities"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="macro"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="toa heading"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Bullet"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Number"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Bullet 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Bullet 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Bullet 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Bullet 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Number 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Number 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Number 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Number 5"/>
              <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Closing"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Signature"/>
              <w:LsdException Locked="false" Priority="1" SemiHidden="true"
               UnhideWhenUsed="true" Name="Default Paragraph Font"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text Indent"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Continue"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Continue 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Continue 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Continue 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="List Continue 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Message Header"/>
              <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Salutation"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Date"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text First Indent"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text First Indent 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Note Heading"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text Indent 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Body Text Indent 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Block Text"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Hyperlink"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="FollowedHyperlink"/>
              <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
              <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Document Map"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Plain Text"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="E-mail Signature"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Top of Form"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Bottom of Form"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Normal (Web)"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Acronym"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Address"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Cite"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Code"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Definition"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Keyboard"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Preformatted"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Sample"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Typewriter"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="HTML Variable"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="annotation subject"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="No List"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Outline List 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Outline List 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Outline List 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Simple 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Simple 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Simple 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Classic 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Classic 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Classic 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Classic 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Colorful 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Colorful 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Colorful 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Columns 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Columns 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Columns 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Columns 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Columns 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 6"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 7"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Grid 8"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 4"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 5"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 6"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 7"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table List 8"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table 3D effects 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table 3D effects 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table 3D effects 3"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Contemporary"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Elegant"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Professional"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Subtle 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Subtle 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Web 1"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Table Web 2"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Balloon Text"/>
              <w:LsdException Locked="false" Priority="59" Name="Table Grid"/>
              <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
              <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
              <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
              <w:LsdException Locked="false" Priority="34" QFormat="true"
               Name="List Paragraph"/>
              <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
              <w:LsdException Locked="false" Priority="30" QFormat="true"
               Name="Intense Quote"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
              <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
              <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
              <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
              <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
              <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
              <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
              <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
              <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
              <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
              <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
              <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
              <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
              <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
              <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
              <w:LsdException Locked="false" Priority="19" QFormat="true"
               Name="Subtle Emphasis"/>
              <w:LsdException Locked="false" Priority="21" QFormat="true"
               Name="Intense Emphasis"/>
              <w:LsdException Locked="false" Priority="31" QFormat="true"
               Name="Subtle Reference"/>
              <w:LsdException Locked="false" Priority="32" QFormat="true"
               Name="Intense Reference"/>
              <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
              <w:LsdException Locked="false" Priority="37" SemiHidden="true"
               UnhideWhenUsed="true" Name="Bibliography"/>
              <w:LsdException Locked="false" Priority="39" SemiHidden="true"
               UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
              <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
              <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
              <w:LsdException Locked="false" Priority="46"
               Name="Grid Table 1 Light Accent 1"/>
              <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
              <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
              <w:LsdException Locked="false" Priority="51"
               Name="Grid Table 6 Colorful Accent 1"/>
              <w:LsdException Locked="false" Priority="52"
               Name="Grid Table 7 Colorful Accent 1"/>
              <w:LsdException Locked="false" Priority="46"
               Name="Grid Table 1 Light Accent 2"/>
              <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
              <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
              <w:LsdException Locked="false" Priority="51"
               Name="Grid Table 6 Colorful Accent 2"/>
              <w:LsdException Locked="false" Priority="52"
               Name="Grid Table 7 Colorful Accent 2"/>
              <w:LsdException Locked="false" Priority="46"
               Name="Grid Table 1 Light Accent 3"/>
              <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
              <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
              <w:LsdException Locked="false" Priority="51"
               Name="Grid Table 6 Colorful Accent 3"/>
              <w:LsdException Locked="false" Priority="52"
               Name="Grid Table 7 Colorful Accent 3"/>
              <w:LsdException Locked="false" Priority="46"
               Name="Grid Table 1 Light Accent 4"/>
              <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
              <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
              <w:LsdException Locked="false" Priority="51"
               Name="Grid Table 6 Colorful Accent 4"/>
              <w:LsdException Locked="false" Priority="52"
               Name="Grid Table 7 Colorful Accent 4"/>
              <w:LsdException Locked="false" Priority="46"
               Name="Grid Table 1 Light Accent 5"/>
              <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
              <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
              <w:LsdException Locked="false" Priority="51"
               Name="Grid Table 6 Colorful Accent 5"/>
              <w:LsdException Locked="false" Priority="52"
               Name="Grid Table 7 Colorful Accent 5"/>
              <w:LsdException Locked="false" Priority="46"
               Name="Grid Table 1 Light Accent 6"/>
              <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
              <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
              <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
              <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
              <w:LsdException Locked="false" Priority="51"
               Name="Grid Table 6 Colorful Accent 6"/>
              <w:LsdException Locked="false" Priority="52"
               Name="Grid Table 7 Colorful Accent 6"/>
              <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
              <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
              <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
              <w:LsdException Locked="false" Priority="46"
               Name="List Table 1 Light Accent 1"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
              <w:LsdException Locked="false" Priority="51"
               Name="List Table 6 Colorful Accent 1"/>
              <w:LsdException Locked="false" Priority="52"
               Name="List Table 7 Colorful Accent 1"/>
              <w:LsdException Locked="false" Priority="46"
               Name="List Table 1 Light Accent 2"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
              <w:LsdException Locked="false" Priority="51"
               Name="List Table 6 Colorful Accent 2"/>
              <w:LsdException Locked="false" Priority="52"
               Name="List Table 7 Colorful Accent 2"/>
              <w:LsdException Locked="false" Priority="46"
               Name="List Table 1 Light Accent 3"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
              <w:LsdException Locked="false" Priority="51"
               Name="List Table 6 Colorful Accent 3"/>
              <w:LsdException Locked="false" Priority="52"
               Name="List Table 7 Colorful Accent 3"/>
              <w:LsdException Locked="false" Priority="46"
               Name="List Table 1 Light Accent 4"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
              <w:LsdException Locked="false" Priority="51"
               Name="List Table 6 Colorful Accent 4"/>
              <w:LsdException Locked="false" Priority="52"
               Name="List Table 7 Colorful Accent 4"/>
              <w:LsdException Locked="false" Priority="46"
               Name="List Table 1 Light Accent 5"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
              <w:LsdException Locked="false" Priority="51"
               Name="List Table 6 Colorful Accent 5"/>
              <w:LsdException Locked="false" Priority="52"
               Name="List Table 7 Colorful Accent 5"/>
              <w:LsdException Locked="false" Priority="46"
               Name="List Table 1 Light Accent 6"/>
              <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
              <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
              <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
              <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
              <w:LsdException Locked="false" Priority="51"
               Name="List Table 6 Colorful Accent 6"/>
              <w:LsdException Locked="false" Priority="52"
               Name="List Table 7 Colorful Accent 6"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Mention"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Smart Hyperlink"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Hashtag"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Unresolved Mention"/>
              <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
               Name="Smart Link"/>
             </w:LatentStyles>
            </xml><![endif]-->
            <p></p>
            <!-- [if gte mso 10]>
            <style>
             /* Style Definitions */
             table.MsoNormalTable
                {mso-style-name:"Table Normal";
                mso-tstyle-rowband-size:0;
                mso-tstyle-colband-size:0;
                mso-style-noshow:yes;
                mso-style-priority:99;
                mso-style-parent:"";
                mso-padding-alt:0in 5.4pt 0in 5.4pt;
                mso-para-margin:0in;
                mso-para-margin-bottom:.0001pt;
                mso-pagination:widow-orphan;
                font-size:12.0pt;
                font-family:"Cambria",serif;
                mso-ascii-font-family:Cambria;
                mso-ascii-theme-font:minor-latin;
                mso-hansi-font-family:Cambria;
                mso-hansi-theme-font:minor-latin;}
            </style>
            <![endif]--><!-- [if gte mso 9]><xml>
             <o:shapedefaults v:ext="edit" spidmax="2049"/>
            </xml><![endif]--><!-- [if gte mso 9]><xml>
             <o:shapelayout v:ext="edit">
              <o:idmap v:ext="edit" data="1"/>
             </o:shapelayout></xml><![endif]-->
            <div class="WordSection1">
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">AMERICAN PRISON DATA SYSTEMS </span></p>
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">PROCEDURES FOR COPYRIGHT INFRINGEMENT NOTICE</span></p>
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;"><span style="mso-spacerun: yes;">&nbsp;</span>UNDER DIGITAL MILLENNIUM COPYRIGHT ACT SECTION 512*</span></p>
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;" align="center"><span style="font-family: 'Times New Roman',serif; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 14.4pt; background: white; margin: 0in 63.0pt .0001pt .5in;"><em style="mso-bidi-font-style: normal;"><span style="font-size: 9.0pt; font-family: 'Verdana',sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-bidi-font-family: 'Times New Roman'; color: black;">*Unless otherwise defined herein, all defined terms used herein are as defined in AMERICAN PRISON DATA SYSTEMS, PBC (&ldquo;APDS&rdquo;) Terms of Use Agreement to which these Procedures for Copyright Infringement Notice are linked and incorporated herein by reference. </span></em></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">If you are a copyright owner or the legal agent of a copyright owner, and you believe that any Authorized User&rsquo;s Contribution or any user-generated content or other materials provided through the APDS Services, including content on the APDS Website or transmitted through a Mobile Application on a mobile phone or mobile computing device application, infringes upon your copyright, you may submit a notification pursuant to the Digital Millennium Copyright Act (&lsquo;&lsquo;DMCA&rsquo;&rsquo;), a copy of which Act may be found at: </span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><a href="http://www.copyright.gov/legislation/dmca.pdf" target="_blank"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman';">http://www.copyright.gov/legislation/dmca.pdf</span></a><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">. </span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">Based on the Act, your copyright notice to APDS (&ldquo;DMCA Copyright Notice&rdquo;) should contain the items set forth below in an email to APDS&rsquo;s designated DMCA Copyright Agent at: </span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><a href="mailto:copyright@APDScorporate.com"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman';">copyright@APDScorporate.com</span></a></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">or via electronic mail or fax with confirmation of transmission or by registered U.S. Mail to the physical address set forth below:</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></strong></p>
            <p class="MsoNormal" style="text-align: center; text-indent: -112.2pt; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 148.2pt;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">Notice of Alleged Copyright Infringement </span></p>
            <p class="MsoNormal" style="text-align: center; text-indent: -112.2pt; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 148.2pt;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">Pursuant to DMCA &sect; 512 (17 U.S.C.S. &sect; 512 (1998))</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; text-indent: -112.2pt; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 148.2pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">1. Identify the material comprising your copyrighted work that you claim has been infringed; if multiple copyrighted works are covered by this DMCA Copyright Notice - you may attach a representative list of each copyrighted work that you claim has been infringed.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">2. Identify (i) the material that you claim is infringing (or is the subject of infringing activity) and that you are asking APDS to remove by this Notice or access to which you are asking APDS to disable, and a link, screen shot or other reasonably sufficient information to permit APDS to find the material on the APDS Services, including at a minimum, if applicable, the URL of the link shown on the Website or mobile application where such material may be found, and (ii) the reference or link to the material or activity that you claim to be infringing, that is to be removed or access to which is to be disabled, and reasonably sufficient information to permit us to locate that reference or link.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">3. Provide your contact information: mailing address, telephone number, and email address.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">4. Include both of the following statements as the last two paragraphs of the text of the Notice:</span></p>
            <ol>
            <li style="list-style-type: none;">
            <ol style="list-style-type: lower-alpha;">
            <li><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">"I hereby state that I have a good faith belief that the disputed use of the copyrighted material or reference or link to such material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)."<br /><br /></span></li>
            <li><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;"><span style="font-size: 12pt;">"I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive intellectual property right under the copyright that is allegedly infringed."</span><br /></span></li>
            </ol>
            </li>
            </ol>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">5. Provide your full legal name and your electronic or physical signature,</span><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; mso-bidi-theme-font: minor-bidi;"> as the</span><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;"> person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">Deliver this Notice, via email or by mail as noted above with all items completed, to the APDS designated DMCA Copyright Agent:</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; text-indent: .5in; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 0in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">APDS DMCA Copyright Agent</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; margin: 0in 193.5pt .0001pt 37.45pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">AMERICAN PRISON DATASYSTEMS, PBC </span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; margin: 0in 3.25in .0001pt 37.45pt;"><span style="font-size: 9.0pt; font-family: 'Verdana',sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-bidi-font-family: 'Times New Roman'; color: black;">65 West 36<sup>th</sup> Street, 2<sup>nd</sup> Floor</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; margin: 0in 3.25in .0001pt 37.45pt;"><span style="font-size: 9.0pt; font-family: 'Verdana',sans-serif; mso-fareast-font-family: 'Times New Roman'; mso-bidi-font-family: 'Times New Roman'; color: black;">New York, NY 10018</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">Email: </span><span class="MsoHyperlink"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman';">copyright@APDScorporate.com</span></span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">For clarity, only DMCA notices should go to the APDS DMCA Copyright Agent; any other feedback, comments, requests for technical support, and other communications should be directed to APDS user support services through:</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span class="MsoHyperlink"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman';">Support@apdscorporate.com</span></span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">You acknowledge that if you fail to comply with all the requirements of this Section, your DMCA Copyright Notice may not be valid.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: normal; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 0in;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">COUNTER-NOTICE</span></p>
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 0in;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">If you receive a notification that your Contribution or User-generated content provided through the APDS Services, including on the Website has been removed due to a claim of copyright infringement in a DMCA Copyright Notice, it means that the Contribution&rsquo;s content has been deleted from the APDS Services at the request of the content's owner. If your account receives too many copyright complaints, you may lose the ability to post new content on the APDS Services, and your account may be disabled completely.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">If you believe user-generated content, comprising a content Contribution you posted or submitted was removed in error, you have the option to file a counter-notice (&ldquo;Counter-Notice&rdquo;) by following the steps below. Upon receipt of a valid Counter-Notice, we will forward a copy to the person who filed the original DMCA Copyright Notice. If we do not receive notice within 10 business days that the submitter of the original claims in their DMCA Copyright Notice is seeking a court order to prevent further infringement of the content at issue, we will remove the claim and the DMCA Copyright Notice from your account's record, and we may, but are not obligated to, replace the content Contribution that was removed.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">Note: There are legal and financial consequences for fraudulent and/or bad faith submissions. Before submitting a Counter-Notice, be sure that you are the actual rights holder of the removed content or that you have a good faith belief that the material was removed in error, and understand the repercussions of submitting a false claim.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: center; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 0in;" align="center"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">How to File a Counter-Notice</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; tab-stops: 148.2pt; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 0in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoListParagraphCxSpFirst" style="mso-add-space: auto; text-indent: 0in; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-list: l7 level1 lfo9; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">1. Deliver your Counter-Notice via email with all items completed, to the APDS DMCA Copyright Agent:</span></p>
            <p class="MsoListParagraphCxSpMiddle" style="mso-add-space: auto; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoListParagraphCxSpMiddle" style="mso-add-space: auto; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;"><span style="mso-spacerun: yes;">&nbsp; </span></span><span class="MsoHyperlink"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; mso-bidi-theme-font: minor-bidi;">copyright@APDScorporate.com</span></span></p>
            <p class="MsoListParagraphCxSpLast" style="mso-add-space: auto; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 0in 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">&nbsp;</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt .5in;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">2. Include ALL the following:</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 49.5pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">a. Your name, address, and telephone number.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 49.5pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">b. DMCA ID printed at the bottom of the DMCA Copyright Notice email.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 49.5pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">c. Copy and paste the link in the DMCA Copyright Notice email, to provide the source address of the Contribution that was removed.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 49.5pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">d. A statement under penalty of perjury that you have a good faith belief that the Contribution was removed in error.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 49.5pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">e. A statement that you consent to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if your address is outside of the United States, for any judicial district in which APDS may be found, and that you will accept service of process from the person who provided the original complaint under DMCA Copyright Notice or an agent of such person.</span></p>
            <p class="MsoNormal" style="text-align: justify; text-justify: inter-ideograph; line-height: 12.0pt; mso-line-height-rule: exactly; mso-pagination: none; mso-layout-grid-align: none; punctuation-wrap: simple; text-autospace: none; mso-line-break-override: restrictions; margin: 4.95pt 64.1pt .0001pt 49.5pt;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; mso-ascii-theme-font: major-latin; mso-fareast-font-family: 'MS Mincho'; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: major-latin; mso-bidi-font-family: 'Times New Roman'; color: black;">f. A physical or electronic signature (for example, typing your full name).</span></p>
            `
    };
  };

  render() {
    return (
      <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
        <DialogContent>
          <div dangerouslySetInnerHTML={this.showMarkup()}></div>
        </DialogContent>
      </Dialog>
    );
  }
}
