import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './my-program.style';
import Program from '../../../programs/components/program/program';
import { Http } from '../../../../core';
import { withRouter } from 'react-router-dom';
import { Constant, Utility } from '../../../../shared';
import { LinkButton } from '@orijinworks/frontend-commons';

export class MyPrograms extends React.Component {
  _utilityService = new Utility();
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      stopInfiniteScroll: false,
      nextPage: 1,
      dataLoading: true,
      handleObserverCalled: false,
    };
    this.loaderRef = React.createRef();
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleObserver.bind(this));
    this.observer.observe(this.loaderRef);
    this.getMyProgramsList(true);
  }

  componentDidUpdate() {
    const loaderElem = this.loaderRef.getBoundingClientRect();
    if (window.innerHeight > loaderElem.y && !this.timerSet) {
      this.timerSet = true;
      const timer = setTimeout(() => {
        if (!this.state.handleObserverCalled) {
          this.getMyProgramsList();
        }
        this.timerSet = false;
        clearTimeout(timer);
      }, 3000);
    }
  }

  getMyProgramsList = async (firstPage) => {
    try {
      if (firstPage || !this.state.stopInfiniteScroll) {
        const pageNumber = firstPage ? 1 : this.state.nextPage;
        let path = `/programs/list?pageNumber=${pageNumber}&enrolledOnly=true`;
        if (pageNumber > 1) {
          path += '&noLoader=true';
        }
        const programsList = await Http.REQUEST.get(path);
        if (!programsList || !programsList.data || programsList.data.length === 0) {
          this.setState({
            stopInfiniteScroll: true,
            programs: pageNumber === 1 ? [] : this.state.programs,
            dataLoading: false,
          });
          return;
        }

        this.setState((prevState) => {
          return {
            ...prevState,
            programs: firstPage ? programsList.data : [...prevState.programs, ...programsList.data],
            nextPage: pageNumber + 1,
            dataLoading: false,
            stopInfiniteScroll: firstPage ? false : prevState.stopInfiniteScroll,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleObserver = (entities) => {
    let handleObserverCalled = this.state.handleObserverCalled;
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.getMyProgramsList();
      handleObserverCalled = true;
    }
    this.setState({ prevY: y, handleObserverCalled });
  };

  goToProgramDetails = (e, bundleId) => {
    e.stopPropagation();
    this.props.history.push(`/programs/${bundleId}/details`);
  };

  goToProgramsCatalog = () => {
    this.props.history.push('/programs');
  };

  render() {
    const { classes, intl } = this.props;
    const { stopInfiniteScroll, dataLoading } = this.state;
    const programs = this._utilityService.removeDuplicateObj(this.state.programs);

    return (
      <Box className={classes.root}>
        {/* BEGIN: TITLE */}
        <Box className={classes.header}>
          <Typography id="my-programs-title" variant="h3" className={classes.mainTitle}>
            <FormattedMessage id="myPrograms" />
          </Typography>
          {programs.length > 0 && (
            <LinkButton
              className={[classes.programCatalogBtn, classes.viewProgramCatalogBtn].join(' ')}
              onClick={this.goToProgramsCatalog}
              id="view-program-catalog-btn"
              tracking-type={Constant.TRACKING_TYPES.PROGRAM}
              tracking-id="view-program-catalog"
              variant="text"
              aria-label={intl.formatMessage({ id: 'viewProgramCatalog' })}
            >
              <FormattedMessage id="viewProgramCatalog" />
            </LinkButton>
          )}
        </Box>
        {/* END: TITLE */}

        {/* BEGIN: DESCRIPTION */}
        {programs.length === 0 && (
          <>
            <Box className={classes.description}>
              <Typography>
                <FormattedMessage id="noEnrolledPrograms" />
              </Typography>
            </Box>
            <LinkButton
              className={[classes.programCatalogBtn, classes.viewProgramCatalogBtn].join(' ')}
              onClick={this.goToProgramsCatalog}
              id="view-program-catalog-btn"
              tracking-type={Constant.TRACKING_TYPES.PROGRAM}
              tracking-id="view-program-catalog"
              variant="text"
              aria-label={intl.formatMessage({ id: 'viewProgramCatalog' })}
            >
              <FormattedMessage id="viewProgramCatalog" />
            </LinkButton>
          </>
        )}
        {/* END: DESCRIPTION */}

        {/* BEGIN: PROGRAMS LIST */}
        <Grid item sm={12} md={8} className={classes.programsContainer}>
          {programs.map((program) => (
            <Box style={{ padding: '25px 0' }} key={`${program.bundleId}`}>
              <Program
                title={program.bundleName}
                description={program.descriptionShort}
                image={program.thumbnailUriPath}
                altText={program.thumbnailAltText}
                buttonTitle={intl.formatMessage({ id: 'viewProgram' })}
                enrolled={false}
                onClick={(e) => {
                  this.goToProgramDetails(e, program.bundleId);
                }}
                programId={program.bundleId}
                providerLogo={program.resourceProvider?.thumbnailUriPath}
                providerLogoAltText={program.resourceProvider?.thumbnailAltText}
              />
            </Box>
          ))}
          <Box ref={(ref) => (this.loaderRef = ref)} className={classes.loaderContainer}>
            {!stopInfiniteScroll && !dataLoading && <CircularProgress />}
          </Box>
        </Grid>
        {/* END: PROGRAMS LIST */}
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(injectIntl(MyPrograms)));
