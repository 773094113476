export default (theme) => ({
  root: {
    borderRadius: 10,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 30,
    height: '100%',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'uppercase',
    fontFamily: 'Oswald',
    ...theme.typography.t60,
  },
  readOnlyButton: {
    pointerEvents: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 300,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.colors.babyBlue,
    boxShadow: 'none',
  },
  boldText: {
    fontWeight: 700,
  },
  flexCenteredContent: {
    display: 'flex',
    alignItems: 'center',
  },
  horizontalMargin10: {
    margin: '0px 20px',
  },
  marginRight5: {
    marginRight: 5,
  },
  sectionTitle: {
    ...theme.typography.t20,
    textTransform: 'capitalize',
    fontWeight: 700,
    marginTop: 20,
  },
  body: {
    padding: '50px 30px',
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 50,
  },
  closeBtn: {
    color: theme.palette.text.primary,
    border: '1px solid #D6E5ED',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    fontWeight: 500,
    width: 175,
    '&:hover': {
      backgroundColor: 'white',
    },
    marginRight: 20,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginLeft: 20,
    textTransform: 'capitalize',
    fontWeight: 500,
    width: 175,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconFill: {
    fill: theme.palette.text.primary,
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    flexDirection: 'column',
    textAlign: 'center',
  },
  modalButton: {
    textTransform: 'capitalize',
    marginTop: 20,
    textDecoration: 'underline',
  },
});
