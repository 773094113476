export default theme => ({
    root: {
        borderRadius: "15px",
        backgroundColor: theme.palette.primary.sub,
        width: "100%",
        padding: 40
    },
    formControlWrapper: {
        backgroundColor: "white",
        borderRadius: "5px",
        marginBottom: 20
    },
    actionBtn: {
        textTransform: "capitalize",
        fontWeight: 500
    },
    dropdownContainer: {
        paddingLeft: 15
    },
    selectAdornment: {
        marginRight: theme.spacing(2),
        cursor: "pointer"
    },
    dropdown: {
        fontWeight: 700
    }
});
  