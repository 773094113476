import { Typography, Box, CardContent, CardMedia, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './horizontal-card.style';
import { FormattedMessage, useIntl } from 'react-intl';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { Constant } from '../../services';
import { LinkButton } from '@orijinworks/frontend-commons';

const HorizontalCard = (props) => {
  const { classes } = props;
  const intl = useIntl();

  return (
    <Box
      onClick={() => {
        if (props.actionUrl) {
          props.onCardClick(props.actionUrl);
        }
      }}
      className={classes.root}
      tracking-type={Constant.TRACKING_TYPES.FEATURED_CONTENT}
      tracking-id={`launch-featured-content-${props.title}`}
      variant="text"
      aria-label={intl.formatMessage({ id: props.buttonTitle })}
      role="link"
    >
      {props.video ? (
        <Grid
          onClick={(e) => {
            props.openVideo();
            e.stopPropagation();
          }}
          item
          xs={5}
          sm={5}
          className={classes.thumbnailContainer}
          tracking-type={Constant.TRACKING_TYPES.FEATURED_CONTENT}
          tracking-id={`open-${props.title}-video`}
        >
          <CardMedia
            id="horizontal-card-image"
            component="img"
            alt=""
            className={[classes.thumbnail, classes.opacity].join(' ')}
            image={props.image}
            title={props.title}
          />
          <PlayCircleFilledIcon className={classes.playIcon} />
        </Grid>
      ) : (
        props.image && (
          <Grid item xs={5} sm={5} className={classes.thumbnailContainer}>
            <CardMedia
              id="horizontal-card-image"
              component="img"
              alt=""
              className={classes.thumbnail}
              image={props.image}
              title={props.title}
            />
          </Grid>
        )
      )}
      <Grid item xs={7} sm={7} className={classes.content}>
        <CardContent className={classes.cardContent}>
          <Typography id="horizontal-card-title" gutterBottom className={classes.cardTitle}>
            {props.title}
          </Typography>
          <Typography
            id="horizontal-card-desc"
            className={classes.description}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {props.description}
          </Typography>
        </CardContent>
        <Box className={classes.actionBox}>
          {props.actionUrl && (
            <Typography
              data-cy-name="feature-content-url-btn"
              className={[classes.actionBtn, classes.horizontalCardActionBtn].join(' ')}
              color="primary"
              id="horizontal-card-action-btn"
            >
              <FormattedMessage id={props.buttonTitle} />
            </Typography>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(HorizontalCard);
