import React from 'react';
import { Box, Avatar } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Unarchive as UnarchiveIcon, Archive as ArchiveIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import styles from './archive-unarchive-icon.style';
import useArchiveUnArchiveIconHook from './useArchiveUnArchiveIconHook';
const ArchiveUnArchiveIcon = ({ contactRequest, classes, unArchiveContacts, openModal }) => {
  const { isInactive, isBlockedOrRejected, isNotArchivable } = useArchiveUnArchiveIconHook(contactRequest);

  if (isNotArchivable || isBlockedOrRejected) {
    return null;
  }

  return (
    <Box className={classes.iconWrapper}>
      <Tooltip title={isInactive ? 'Unarchive' : 'Archive'}>
        <Avatar className={classes.archiveAvatar}>
          {isInactive ? (
            <UnarchiveIcon
              tooltip="UnArchive"
              className={classes.archiveIcon}
              color="primary"
              onClick={() => unArchiveContacts(contactRequest?.id)}
            />
          ) : (
            <ArchiveIcon
              tooltip="Archive"
              className={classes.archiveIcon}
              color="primary"
              onClick={() => openModal(`${contactRequest?.firstName} ${contactRequest?.lastName}`, contactRequest?.id)}
            />
          )}
        </Avatar>
      </Tooltip>
    </Box>
  );
};

export default withStyles(styles)(ArchiveUnArchiveIcon);
