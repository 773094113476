export default theme => ({
  reloadBtn: {
    color: theme.palette.primary.white,
    textTransform: "capitalize",
    marginLeft: 10
  },
  alertAction: {
    display: "none"
  },
  snackbar: {
    zIndex: 99999
  }
});
