export default theme => ({
  root: {
    width: 382,
    maxWidth: "100%",
    borderRadius: 4,
    background: "none"
  },
  dialogRoot: {
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: "none"
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "0px 20px",
    height: 56
  },
  dialogTitle: {
    ...theme.typography.medium,
    ...theme.typography.t20,
    color: theme.palette.primary.white,
    lineHeight: "26px",
    letterSpacing: "0.15px"
  },
  closeBtn: {
    color: theme.palette.primary.white,
    opacity: 0.75
  },
  cardContent: {
    backgroundColor: theme.palette.primary.white,
    padding: "25px 30px"
  },
  scheduleActive: {
    ...theme.typography.bold,
    position: "relative",
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      position: "absolute",
      left: -13,
      top: 0,
      zIndex: 1,
      bottom: -1,
      width: "100%",
      borderRadius: 30,
      backgroundColor: theme.palette.primary.grey
    }
  }
});
