import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './career-profile.style';
import { Box, Grid } from '@material-ui/core';
import {
  Attribution,
  CareerDetails,
  InfoCard,
  Personality,
  SimilarJobs,
  JobOutlook,
  SkillsAndTechnology,
} from '../../components';

import { CareersService } from '../../services/careers.service';
import { BackButton } from '../../../../shared';

export class CareerProfile extends React.Component {
  _service = new CareersService();
  constructor(props) {
    super(props);
    this.state = {
      careerDetails: {},
    };
    this._careerId = props.match.params.id;
  }

  componentDidMount() {
    this.getCareerDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedLocale !== prevProps.selectedLocale) {
      this.getCareerDetails();
    }
  }

  getCareerDetails = async () => {
    try {
      const data = await this._service.getCareerDetails(this._careerId);
      if (data && data.payload) {
        this.setState({ careerDetails: data.payload });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getJobZone = () => {
    const career = this.state.careerDetails;
    let zone = 1;
    if (career.education && career.education.job_zone) {
      zone = career.education.job_zone;
    }
    return zone;
  };

  getSalary = () => {
    const career = this.state.careerDetails;
    let pay = 0;
    if (career.job_outlook && career.job_outlook.salary && career.job_outlook.salary.annual_median) {
      pay = career.job_outlook.salary.annual_median;
    }
    return pay;
  };

  getTasks = () => {
    const career = this.state.careerDetails;
    let tasks = [];
    if (career.on_the_job && career.on_the_job.task && career.on_the_job.task.length > 0) {
      tasks = career.on_the_job.task;
    }
    return tasks;
  };

  getAlsoCalled = () => {
    const career = this.state.careerDetails;
    let titles = [];
    if (career.also_called && career.also_called.title && career.also_called.title.length > 0) {
      titles = career.also_called.title;
    }
    return titles;
  };

  getQualification = () => {
    const career = this.state.careerDetails;
    let qualification = '';
    if (
      career.education &&
      career.education.education_usually_needed &&
      career.education.education_usually_needed.category &&
      career.education.education_usually_needed.category.length > 0
    ) {
      const category = career.education.education_usually_needed.category;
      qualification = category[category.length - 1];
    }
    return qualification;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleSimilarJobClick = (e, id) => {
    e.stopPropagation();
    this.props.history.push(`/careers/${encodeURIComponent(id)}/profile`);
    this._careerId = id;
    this.getCareerDetails();
  };

  bookmarkCareer = async () => {
    try {
      const details = this.state.careerDetails;
      const response = await this._service.addBookmark(details.code);
      if (response) {
        details.in_bookmarks = true;
        this.setState({ careerDetails: details });
      }
    } catch (e) {
      console.log(e);
    }
  };

  removeBookmarkedCareer = async () => {
    try {
      const details = this.state.careerDetails;
      const response = await this._service.removeBookmark(details.code);
      if (response) {
        details.in_bookmarks = false;
        this.setState({ careerDetails: details });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      title,
      what_they_do,
      abilities,
      knowledge,
      skills,
      personality,
      explore_more,
      job_outlook,
      technology,
      in_bookmarks,
      code,
    } = this.state.careerDetails;
    return (
      <Box className={classes.root}>
        <BackButton onClick={this.goBack} />

        {/* BEGIN: PROFILE */}
        <Grid container sm={12} md={12} lg={12} spacing={3}>
          <Grid item sm={12} md={12} lg={8}>
            <CareerDetails
              title={title}
              description={what_they_do}
              jobZone={this.getJobZone()}
              salary={this.getSalary()}
              qualification={this.getQualification()}
              tasks={this.getTasks()}
              alsoCalled={this.getAlsoCalled()}
              isBookmarked={in_bookmarks}
              addBookmark={this.bookmarkCareer}
              removeBookmark={this.removeBookmarkedCareer}
              code={code}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <InfoCard title="abilities" data={abilities} />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <JobOutlook data={job_outlook} />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <InfoCard title="knowledge" data={knowledge} />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <Personality data={personality} />
          </Grid>
          <Grid container item sm={12} md={12} lg={12} style={{ flexDirection: 'row-reverse' }}>
            <Grid item sm={12} md={12} lg={4} className={classes.skillsWrapper}>
              <SkillsAndTechnology skills={skills} technologies={technology} />
            </Grid>
            <Grid item sm={12} md={12} lg={8} className={classes.similarJobsWrapper}>
              <SimilarJobs data={explore_more} onItemClick={this.handleSimilarJobClick} />
            </Grid>
          </Grid>
        </Grid>
        {/* END: PROFILE */}

        <Grid container sm={12} md={12} lg={12}>
          <Grid item sm={8} md={6} lg={4} className={classes.attributionContainer}>
            <Attribution />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerProfile));
