import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import styles from './featured-contents.styles';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { FormattedMessage } from 'react-intl';
import useFeaturedContentsHook from './useFeaturedContentsHook';
import { ContentCarousel } from '../../components';

/**
 * Component to display featured contents in the carousel
 *
 * @param {object} props - component props
 * @param {object} props.classes - classes that will be injected by withStyles HOC
 * @param {array} props.features - list of features
 * @param {string} props.username - username of the user
 * @param {object} props.history - history object from react-router-dom
 * @returns {React.Component} - FeaturedContents component
 */
const FeaturedContents = ({ classes, features, username, history }) => {
  const { isFeatureEnabled, contents, videoPlayerProps, handleLaunch, handleVideoPlayerOpen, handleVideoPlayerClose } =
    useFeaturedContentsHook({ features, username, history });

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <ModalVideo
        channel="custom"
        url={videoPlayerProps.url}
        autoplay
        isOpen={videoPlayerProps.isOpen}
        onClose={handleVideoPlayerClose}
      />

      <Typography id="featured-content-title" variant="h2" color="secondary" className={classes.title}>
        <FormattedMessage id="featuredContent" />
      </Typography>

      <Grid container spacing={2}>
        {contents.length > 0 && (
          <ContentCarousel
            contents={contents}
            handleContentClick={handleLaunch}
            handleVideoPlayerOpen={handleVideoPlayerOpen}
          />
        )}
      </Grid>
    </Box>
  );
};

FeaturedContents.propTypes = {
  /**
   * Classes that will be injected by withStyles HOC
   */
  classes: PropTypes.object.isRequired,
  /**
   * List of features
   */
  features: PropTypes.array.isRequired,
  /**
   * Username of the user
   */
  username: PropTypes.string,
  /**
   * History object from react-router-dom
   */
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeaturedContents);
