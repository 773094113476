import { useCallback, useState } from 'react';
import { validateFace } from '../../../../services/auth-guards.service';
import { AUTH_CONSTANTS } from '../../../../../auth/constants/auth-constants';

/**
 * Hook to handle the Biometric Authentication Registration.
 *
 * @returns {object}
 */
const useBiometricAuthRegistrationHook = () => {
  const [step, setStep] = useState(1);
  const [playVideo, setPlayVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesProcessed, setImagesProcessed] = useState([]);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  /**
   * Function to move to the next step.
   */
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  /**
   * Function to handle the video play.
   */
  const handleVideoPlay = () => {
    setPlayVideo(true);
  };

  /**
   * Function to handle the video close.
   */
  const handleVideoClose = () => {
    setPlayVideo(false);
  };

  /**
   * Function to show the introduction.
   *
   * @returns {boolean} - The flag to show the introduction
   */
  const showIntroduction = () => {
    return step === 1;
  };

  /**
   * Function to show the instructions.
   *
   * @returns {boolean} - The flag to show the instructions
   */
  const showInstructions = () => {
    return step === 2;
  };

  /**
   * Function to show the capture image.
   *
   * @returns {boolean} - The flag to show the capture image
   */
  const showCaptureImage = () => {
    return step === 3 && !registrationCompleted;
  };

  /**
   * Function to show the review images.
   *
   * @returns {boolean} - The flag to show the review images
   */
  const showReviewImages = () => {
    return step === 3 && registrationCompleted;
  };

  const getNextPosition = (position) => {
    const index = AUTH_CONSTANTS.FACE_POSITIONS_ARRAY.indexOf(position);
    let nextPosition = '';
    if (index < AUTH_CONSTANTS.FACE_POSITIONS_ARRAY.length - 1) {
      nextPosition = AUTH_CONSTANTS.FACE_POSITIONS_ARRAY[index + 1];
    }
    return nextPosition;
  };

  /**
   * Function to process the captured image.
   */
  const processCapturedImage = useCallback(async ({ image, position }, cb) => {
    try {
      setIsLoading(true);
      await validateFace({ image, position }, AUTH_CONSTANTS.AUTH_GUARDS_ACTION.REGISTER);
      const nextPosition = getNextPosition(position);
      cb({ nextPosition }, null);
    } catch (error) {
      cb(null, error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  /**
   * Function to handle the registration complete.
   *
   * @param {array} images - The images processed
   */
  const handleRegistrationComplete = (images) => {
    setImagesProcessed(images);
    setRegistrationCompleted(true);
  };

  return {
    playVideo,
    isLoading,
    imagesProcessed,
    nextStep,
    handleVideoPlay,
    handleVideoClose,
    showIntroduction,
    showInstructions,
    showCaptureImage,
    showReviewImages,
    processCapturedImage,
    handleRegistrationComplete,
  };
};

export default useBiometricAuthRegistrationHook;
