import React from 'react';

const RemoveCircleIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1803_1129)">
        <path
          d="M12 2.25C14.5859 2.25 17.0658 3.27723 18.8943 5.10571C20.7228 6.93419 21.75 9.41414 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75C9.41414 21.75 6.93419 20.7228 5.10571 18.8943C3.27723 17.0658 2.25 14.5859 2.25 12C2.25 9.41414 3.27723 6.93419 5.10571 5.10571C6.93419 3.27723 9.41414 2.25 12 2.25ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM8.625 10.875C8.00156 10.875 7.5 11.3766 7.5 12C7.5 12.6234 8.00156 13.125 8.625 13.125H15.375C15.9984 13.125 16.5 12.6234 16.5 12C16.5 11.3766 15.9984 10.875 15.375 10.875H8.625Z"
          fill={fill}
          fill-opacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1803_1129">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

RemoveCircleIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default RemoveCircleIcon;
