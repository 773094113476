import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';
import styles from './category-details.styles';
import { Constant, Utility } from '../../../../../shared/services';
import useCategoryDetailsHook from './useCategoryDetailsHook';
import { ThumbnailCard } from '../../../../shared';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExploreCarousel, ExploreTools, ResumeBuilderTile } from '..';
import { APDSPodcast } from '../../../../../content/explore/components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

/**
 * Component to render the category details.
 *
 * @param {object} props - The props of the component.
 * @returns {React.Component} The CategoryDetails component.
 */
const CategoryDetails = (props) => {
  const {
    classes,
    categoryId,
    categoryName,
    services,
    total,
    handleActiveCategory,
    handleServiceClick,
    willOpenLink,
    onShowAll,
  } = props;
  const { categoryRef, showThumbnailCards, showCarousel, showTools, showIconAsThumbnail, displayShowAll } =
    useCategoryDetailsHook({ categoryId, services, total, handleActiveCategory });
  const { iconWithProps } = Constant.SERVICE_CATEGORY[categoryId];
  const intl = useIntl();

  return (
    <Box ref={categoryRef} className={[classes.root, showCarousel() ? classes.filledBackground : ''].join(' ')}>
      <Box id={`service-category-${categoryId}`} className={classes.header}>
        {iconWithProps({ width: 32, height: 32, size: 32 })}
        <Typography id={`service-category-${categoryId}-title`} variant="h3">
          {categoryName}
        </Typography>
      </Box>

      {showThumbnailCards() && (
        <Grid container spacing={2}>
          <ResumeBuilderTile categoryId={categoryId} />
          {services.map((service) => (
            <Grid item sm={12} md={4} key={service.id}>
              {service.isAPDSPodcast ? (
                <APDSPodcast
                  handleLaunchClick={() => handleServiceClick(service)}
                  name={service.name}
                  description={service.description}
                  trackingType={Constant.TRACKING_TYPES.SERVICE}
                  trackingId={`launch-service-${service.id}`}
                />
              ) : (
                <ThumbnailCard
                  title={service.name}
                  subtitle={service.displayCredential ? intl.formatMessage({ id: 'sign-in-required' }) : ''}
                  description={service.description}
                  thumbnailUrl={service.thumbnailUri}
                  thumbnailType={showIconAsThumbnail() ? 'icon' : 'image'}
                  icon={service.icon}
                  trackingType={Constant.TRACKING_TYPES.SERVICE}
                  trackingId={`launch-service-${service.id}`}
                  onClick={() => handleServiceClick(service)}
                  accessibilityProps={willOpenLink(service) ? { role: 'link', rel: 'noopener noreferrer' } : {}}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}

      {showCarousel() && (
        <Grid container spacing={2}>
          <ExploreCarousel services={services} handleServiceClick={handleServiceClick} willOpenLink={willOpenLink} />
        </Grid>
      )}

      {showTools() && (
        <ExploreTools
          tools={Utility.sortByKey(services, 'position')}
          onItemClick={handleServiceClick}
          willOpenLink={willOpenLink}
        />
      )}

      {displayShowAll() && (
        <Box className={classes.showAllContainer}>
          <Button
            id={`service-category-${categoryId}-show-all`}
            className={classes.showAllButton}
            endIcon={<ChevronRightIcon />}
            size="large"
            onClick={onShowAll}
            aria-label={intl.formatMessage({ id: 'showAll' })}
          >
            <FormattedMessage id="showAll" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

CategoryDetails.propTypes = {
  /**
   * The classes that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The id of the category
   */
  categoryId: PropTypes.number.isRequired,
  /**
   * The name of the category
   */
  categoryName: PropTypes.string.isRequired,
  /**
   * The services to display in the category
   */
  services: PropTypes.array.isRequired,
  /**
   * The function to handle active category
   */
  handleActiveCategory: PropTypes.func.isRequired,
  /**
   * The function to handle service click
   */
  handleServiceClick: PropTypes.func.isRequired,
  /**
   * The function to check if the service will open a link
   */
  willOpenLink: PropTypes.func.isRequired,
  /**
   * The total number of services in the category
   */
  total: PropTypes.number.isRequired,
  /**
   * The function to show all services
   */
  onShowAll: PropTypes.func.isRequired,
};

export default withStyles(styles)(CategoryDetails);
