export default (theme) => ({
    card: {
        boxShadow: theme.palette.boxShadow,
        display: "flex",
        alignItems: "center",
        height: 200
    },
    contentPart: {
        padding: 20,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    visualPart: {
        background: theme.palette.primary.themeGreen,
        width: 200,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    subHeading: {
        margin: "10px 0px"
    },
    mainRecommendationWrapper: {
        width: "100%",
        marginTop: 50,
        cursor: "pointer"
    },
    mainTitle: {
        fontFamily: theme.typography.secondaryFont,
        ...theme.typography.t24
    },
    description: {
        fontSize: "1rem"
    },
    actionBtn: {
        ...theme.typography.t18,
        ...theme.typography.bold,
        maxWidth: 100,
        lineHeight: "16px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        color: theme.palette.primary.main
    },
});