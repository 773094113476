import React, { useState } from 'react';
import { BreadcrumbsContext } from '../breadcrumbs-context';

/**
 * Component to provide the breadcrumbs context
 *
 * @component
 * @param {Object} children - The children components
 */
const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>;
};

export default BreadcrumbsProvider;
