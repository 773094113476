import React from 'react';
import { withStyles, Box, Typography, Divider } from '@material-ui/core';

import styles from './skills-and-technology.style';
import { FormattedMessage } from 'react-intl';
import { MissingData } from '..';

const SkillsAndTechnology = (props) => {
  const { classes, skills, technologies } = props;

  const isDataAvailable = () => {
    return (skills && skills.length > 0) || (technologies && technologies.length > 0);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography id="skills-and-tech-title" className={classes.mainTitle} variant="h2">
          <FormattedMessage id="skillsAndTech" />
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      {isDataAvailable ? (
        <>
          {/* BEGIN: SKILLS */}
          {skills &&
            skills.length > 0 &&
            skills.map((item) => (
              <>
                <Typography gutterBottom variant="h3" color="secondary" className={classes.capitalize}>
                  {item.title.name}
                </Typography>
                {item.element && item.element.length > 0 && (
                  <ul>
                    {item.element.map((info) => (
                      <li key={info.id}>
                        <Typography className={classes.description}>{info.name}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ))}
          {/* END: SKILLS */}

          {/* BGEIN: TECHONOLOGIES */}
          {technologies && technologies.length > 0 && (
            <>
              <Typography gutterBottom variant="h4" color="secondary" className={classes.uppercase}>
                <FormattedMessage id="career.technology.subtitle" />
              </Typography>
              <ul>
                {technologies.map(
                  (technology) =>
                    technology.title && (
                      <li key={technology.unspsc}>
                        <Typography className={classes.description}>{technology.title.name}</Typography>
                      </li>
                    )
                )}
              </ul>
            </>
          )}
          {/* END: TECHONOLOGIES */}
        </>
      ) : (
        <MissingData />
      )}
    </Box>
  );
};

export default withStyles(styles)(SkillsAndTechnology);
