import React from 'react';

const EyeClosedIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-Hide_Eye">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Hide_Eye" data-name="Hide Eye" clipPath="url(#clip-Hide_Eye)">
        <path
          id="eye-slash-regular"
          d="M5.651,3.479A10.241,10.241,0,0,1,12,1.2a10.559,10.559,0,0,1,7.222,3.022,14.528,14.528,0,0,1,3.484,4.916,1.193,1.193,0,0,1,0,.922,14.868,14.868,0,0,1-3,4.44l3.945,3.09a.9.9,0,1,1-1.11,1.417L.345,1.609A.9.9,0,1,1,1.456.192ZM7.118,4.631l1.725,1.35a4.8,4.8,0,0,1,7.425,5.82l2.017,1.582A14.58,14.58,0,0,0,20.947,9.6,13.529,13.529,0,0,0,18,5.539a8.806,8.806,0,0,0-6-2.572A8.272,8.272,0,0,0,7.118,4.631Zm7.691,6.026A3.056,3.056,0,0,0,15,9.566a3.021,3.021,0,0,0-3-3c-.026,0-.049.034-.109.034A1.442,1.442,0,0,1,12,7.166a2.428,2.428,0,0,1-.247,1.095Zm.352,4.886,1.571,1.237A9.857,9.857,0,0,1,12,18a10.552,10.552,0,0,1-7.222-3.022,15.271,15.271,0,0,1-3.485-4.916,1.2,1.2,0,0,1,0-.922A14.259,14.259,0,0,1,3.116,6.056L4.53,7.17a12.036,12.036,0,0,0-1.476,2.4A13.021,13.021,0,0,0,6,13.661,8.778,8.778,0,0,0,12,16.2a7.977,7.977,0,0,0,3.161-.656ZM7.2,9.566c0-.075,0-.18.011-.285l2.1,1.657a2.868,2.868,0,0,0,2.01,1.552l2.107,1.691a6.164,6.164,0,0,1-1.466.217,4.829,4.829,0,0,1-4.8-4.834Z"
          transform="translate(0 2.4)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

EyeClosedIcon.defaultProps = {
  size: 25,
  fill: 'inherit',
};

export default EyeClosedIcon;
