import React, { forwardRef } from 'react';
import { Modal } from '@orijinworks/frontend-commons';
import { Typography } from '@material-ui/core';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import { Box } from '@material-ui/core';
const ArchiveUnArchiveContactModal = forwardRef(({ isOpen, onClose, contactName, onContinue }, ref) => {
  return (
    <Modal
      ref={ref}
      open={isOpen}
      showCrossIcon={true}
      onClose={onClose}
      actionBtnText="Archive Contact"
      cancelBtnText="Cancel"
      onContinue={onContinue}
      styles={{
        mainWrapperStyles: {
          height: '350px',
          width: '420px',
          minWidth: '420px',
          maxWidth: '420px',
          zIndex: 9999,
          backdropFilter: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }}
      modalType={{
        type: 'confirmation',
        status: 'danger',
      }}
      Icon={<ArchiveOutlinedIcon />}
    >
      <Box>
        <Typography variant="h5" gutterBottom>
          <b>Archive Contact {contactName} ?</b>
        </Typography>

        <Typography variant="body1" gutterBottom>
          The contact may need to go through the approval process to be restored.
        </Typography>
      </Box>
    </Modal>
  );
});

export default ArchiveUnArchiveContactModal;
