import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Drawer,
  IconButton,
  withStyles,
} from '@material-ui/core';
import React, { forwardRef } from 'react';
import styles from './filter.style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Typography } from '@orijinworks/frontend-commons';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useFilterHook } from './useFilterHook';
import { FormattedMessage } from 'react-intl';
import { ProgramConstants } from '../../services/programs.service';

const Filter = forwardRef((props, ref) => {
  const { isOpen, classes, toggleModal } = props;
  const { filters, onFilterChange } = useFilterHook(props, ref);

  return (
    <Drawer onClose={toggleModal} anchor={'right'} open={isOpen}>
      <Box>
        <IconButton color="primary" onClick={toggleModal} id="close-btn" className={classes.closeButton}>
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </Box>
      <Box className={classes.filterWrapper}>
        {filters.map((filter, parentIndex) => (
          <>
            {filter.type === ProgramConstants.SECTION_TYPE.BUTTONABLE && (
              <Box key={filter.titleTranslationKey}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h4">
                    <FormattedMessage id={filter.titleTranslationKey} />
                  </Typography>
                  <Button onClick={filter.buttonCallback} variant="outlined">
                    <FormattedMessage id={filter.buttonTranslationKeyText} />
                  </Button>
                </Box>
                {filter.filters.map((filterContent, index) => (
                  <Box key={filterContent.filterValue} display="flex" alignItems="center">
                    <Checkbox
                      id="filter-checkbox-btn"
                      checked={filterContent.checked}
                      onChange={() => onFilterChange(parentIndex, index)}
                      className={classes.checkBox}
                      color="primary"
                    />
                    <Typography>
                      <FormattedMessage id={filterContent.translationKey} />
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}

            {filter.type === ProgramConstants.SECTION_TYPE.ACCORDION && (
              <Accordion
                defaultExpanded
                key={filter.titleTranslationKey}
                classes={{
                  root: classes.accordionRoot,
                }}
              >
                <AccordionSummary
                  classes={{
                    root: classes.accordionSummaryRoot,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h4">
                    <FormattedMessage id={filter.titleTranslationKey} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  classes={{
                    root: classes.accordionDetails,
                  }}
                >
                  {filter.filters.map((filterContent, index) => (
                    <Box key={filterContent.filterValue} display="flex" alignItems="center">
                      <Checkbox
                        id="filter-checkbox-accordion"
                        onChange={() => onFilterChange(parentIndex, index)}
                        checked={filterContent.checked}
                        className={classes.checkBox}
                        color="primary"
                      />
                      {filterContent.imageUrl && (
                        <Box width="40px" margin="0px 5px" component="img" src={filterContent.imageUrl} />
                      )}
                      <Typography>
                        {filterContent.translationKey ? (
                          <FormattedMessage id={filterContent.translationKey} />
                        ) : (
                          filterContent.value
                        )}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </>
        ))}
      </Box>
    </Drawer>
  );
});

export default withStyles(styles)(Filter);
