export default (theme) => ({
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 4,
  },
  headerTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  headerImg: {
    maxWidth: 300,
    maxHeight: 250,
  },
  tabBar: {
    backgroundColor: 'white',
    width: 'fit-content',
    border: '1px solid rgba(0,0,0,0.23)',
  },
  tabIndicator: {
    display: 'none',
  },
  tabSelected: {
    backgroundColor: '#9ACAFF',
  },
  tabs: {
    minHeight: 35,
  },
  tab: {
    minHeight: 35,
  },
  searchBar: {
    backgroundColor: 'white',
    borderRadius: 10,
  },
  programsContainer: {
    margin: '10px 0px',
  },
  topBarContainer: {
    margin: '10px 12px',
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchBarWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBtn: {
    border: '1px solid rgba(0,0,0,0.26)',
    textTransform: 'capitalize',
    marginLeft: 15,
    paddingLeft: 35,
    paddingRight: 35,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noResult: {
    padding: 20,
  },
  divider: {
    background: theme.palette.greyScale[60],
    opacity: 1,
    height: 1,
    margin: '0.5rem 0',
    border: 0,
  },
  titleContainer: {
    padding: 30,
    paddingBottom: 0,
  },
  descriptionMargin: {
    marginBottom: '18px',
  },
  programsLength: {
    paddingLeft: 20,
  },
  ribbonIcon: {
    width: '15px !important',
    height: '15px !important',
    margin: '0px 4px',
  },
  sortSelect: {
    backgroundColor: theme.palette.navy.main,
    color: 'white',
    borderRadius: '100px',
  },
  sortMenu: {
    fontFamily: 'Inter',
    color: 'white',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.32px',
    '& > svg': {
      color: 'white',
      marginRight: '15px',
    },
    '& > div': {
      padding: '14px 42px 14px 28px !important',
    },
  },
  sortMenuRoot: {
    '& .MuiMenuItem-root': {
      color: theme.palette.cerulean.cerulean20,
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.32px',
      '&:hover': {
        backgroundColor: theme.palette.primary.pale,
      },
    },
  },
  dropdownItem: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: theme.palette.navy.main,
    lineHeight: '24px',
    letterSpacing: '0.32px',
    fontWeight: 400,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
      color: theme.palette.cerulean.cerulean20,
    },
  },
  filterBtn: {
    padding: '9px 20px !important',
    borderColor: theme.palette.navy.main + '!important',
    color: theme.palette.navy.main + '!important',
    marginLeft: 10 + 'px !important',
  },
  filterText: {
    fontWeight: 700,
    color: 'var(--grey--110)',
  },
  filterChip: {
    fontSize: 16,
    backgroundColor: 'white',
    padding: '4px 12px',
    borderRadius: 10,
    border: '1px solid #061E44',
    color: '#061E44',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    margin: '5px',
    letterSpacing: '0.32px',
  },
  filterRemoveBtn: {
    padding: 2,
    color: '#061E44',
  },
});
