import { Http } from '../../../core';
import { UserService, Utility } from '../../../shared';

export class AnnouncementServices {
  _utilityService = new Utility();
  /**
   * @name fetchAnnouncement
   * @desc Fetches Messages
   * @return {Promise}
   */
  fetchAnnouncement = ({ beforeId, limit, afterId, signal }) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(
        `/v2/announcements?noLoader=true${beforeId ? `&before_id=${beforeId}` : ''}${afterId ? `&after_id=${afterId}` : ''}${
          limit ? `&limit=${limit}` : ''
        }`,
        {
          cancelToken: signal,
        }
      )
        .then((_successLog) => {
          const { data } = _successLog;
          if (!data) {
            resolve([]);
            return;
          }

          resolve(this.preprocessAnnouncement((data.announcements || []).reverse()));
        })
        .catch((err) => {
          if (Http.axios.isCancel(err)) {
            console.log('Request Cancelled', err.message);
          }
        });
    });
  };

  /**
   * @name preprocessAnnouncement
   * @param {array} messages
   * @desc pre-processes messages.
   * @return {array}
   */
  preprocessAnnouncement = (messages) => {
    return messages.map((msg) => {
      const learnerTimezoneName = this._utilityService.getTimezoneName(
        UserService.userServerTimestamp.timeZone
      ).timezoneName;
      msg['createdDate'] = this._utilityService.parseTimeToNewZone(
        msg.createdDate,
        learnerTimezoneName,
        'MMM D YYYY hh:mm A'
      );
      if (!!msg['readDate']) {
        msg['readDate'] = this._utilityService.parseTimeToNewZone(msg.readDate, learnerTimezoneName, 'MMM D YYYY hh:mm A');
      }
      return msg;
    });
  };

  markAnnouncementRead = (recent_announcement_id) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.put(`/v2/announcements/${recent_announcement_id}?noLoader=true`)
        .then((_successLog) => {
          const { data } = _successLog;

          if (!data) {
            resolve([]);
            return;
          }

          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
