import * as store from ".";

const initialState = {
  pendingAPIs: {
    // "bookmarks/39-3091.00": true,
    // "bookmarks/47-2051.00": true
  }
};

export const pendingAPIReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.ADD_API_KEY: {
      return {
        ...state,
        pendingAPIs: { ...state.pendingAPIs, [action.pendingAPIObj.key]: true }
      };
    }
    case store.REMOVE_API_KEY: {
      const pendingAPIs = { ...state.pendingAPIs };
      delete pendingAPIs[action.pendingAPIObj.key];
      return {
        ...state,
        pendingAPIs
      };
    }

    default:
      return state;
  }
};
