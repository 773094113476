import moment from 'moment';

class DateTimeConstants {
  static TODAY = 'today';
  static YESTERDAY = 'yesterday';
}

/**
 * @name formatTime
 * @description if the given datetime is less than limit return the time from now,
 * else return the formatted time
 * @param {String} datetime
 * @param {String} format
 * @param {Number} limit
 * @param {String} unit - should be one of the momentjs units
 * @returns {String}
 */
const formatTime = (datetime, format = 'hh:mm A', limit = 90, unit = 'minutes') => {
  const givenDate = moment(datetime);
  const currentDate = moment();
  let time = givenDate.format(format);

  const diff = currentDate.diff(givenDate, unit);
  if (diff < limit) {
    time = givenDate.fromNow();
  }

  return time;
};

/**
 * @name formatDate
 * @description if the given datetime is today return 'today',
 * if the given datetime is yesterday return 'yesterday',
 * else return the formatted date
 * @param {String} datetime
 * @param {String} format
 * @returns {String}
 */
const formatDate = (datetime, format = 'MM/DD/YYYY') => {
  const givenDate = moment(datetime);
  const currentDate = moment();
  let date = givenDate.format(format);

  if (givenDate.isSame(currentDate, 'day')) {
    date = DateTimeConstants.TODAY;
  }

  if (givenDate.isSame(currentDate.clone().subtract(1, 'day'), 'day')) {
    date = DateTimeConstants.YESTERDAY;
  }

  return date;
};

/**
 * @name generateUniqueNumericId
 * @description generates unique numeric id based on the given datetime
 * @param {String} datetime
 * @returns {Number}
 */
const generateUniqueNumericId = (datetime) => {
  return moment(datetime).valueOf();
};

export { DateTimeConstants, formatTime, formatDate, generateUniqueNumericId };
