import { useEffect, useImperativeHandle, useState } from 'react';
import {
  filterData,
  getContentProviderFilter,
  getCourseDurationFilters,
  getFilterByFilters,
  getProgramCategoryFilters,
  ProgramConstants,
} from '../../services/programs.service';

export const useFilterHook = (props, ref) => {
  const [filters, setFilters] = useState([]);
  const { setPrograms, setProgramChangeMeta } = props;
  useImperativeHandle(
    ref,
    () => ({
      filters,
      getFilteredData,
      clearAllFilters,
      onFilterChange: onFilterChange,
    }),
    [filters]
  );

  useEffect(() => {
    if (props.isOpen && filters.length === 0) {
      setFilters([
        getFilterBy(props.data),
        getContentProvider(props.data),
        getProgramCategory(props.data),
        getCourseDuration(props.data),
      ]);
    }
  }, [props.data, props.isOpen]);

  /**
   * Reset's the filter state
   * @return void
   */
  const onClearAllHandler = () => {
    clearAllFilters();
    setProgramChangeMeta(Math.random());
  };

  /**
   * Removes all the filters by setting checked = false
   */
  const clearAllFilters = () => {
    setFilters((filters) => {
      return filters.map((filter) => {
        filter.filters = filter.filters.map((filterContent) => {
          filterContent.checked = false;
          return filterContent;
        });
        return filter;
      });
    });
  };
  /**
   * @param {object} programData Program API data
   * @desc Prepares filterBy section items
   * @return {object}
   */
  const getContentProvider = (programData) => {
    return {
      titleTranslationKey: 'contentProvider',
      type: ProgramConstants.SECTION_TYPE.ACCORDION,
      filters: getContentProviderFilter(programData),
    };
  };

  /**
   * @param {object} programData Program API data
   * @desc Prepares filterBy section items
   * @return {object}
   */
  const getProgramCategory = (programData) => {
    return {
      titleTranslationKey: 'programCategory',
      type: ProgramConstants.SECTION_TYPE.ACCORDION,
      filters: getProgramCategoryFilters(programData),
    };
  };

  /**
   * @param {object} programData Program API data
   * @desc Prepares filterBy section items
   * @return {object}
   */
  const getFilterBy = (programData) => {
    return {
      titleTranslationKey: 'filterBy',
      type: ProgramConstants.SECTION_TYPE.BUTTONABLE,
      buttonTranslationKeyText: 'clearAll',
      buttonCallback: onClearAllHandler,
      filters: getFilterByFilters(programData),
    };
  };

  /**
   * @param {object} programData Program API data
   * @desc Prepares course duration section items
   * @return {object}
   */
  const getCourseDuration = (programData) => {
    return {
      titleTranslationKey: 'courseDuration',
      type: ProgramConstants.SECTION_TYPE.ACCORDION,
      filters: getCourseDurationFilters(programData),
    };
  };

  /**
   *
   * @param {*} parentIndex
   * @param {*} mainIndex
   */
  const getFilteredData = (data = props.data) => {
    let useFilteredData = false;
    const filteredData = data.filter((item) => {
      let isPresent = true;
      filters.forEach((section) => {
        //ITERATING THROUGH EACH FILTER SECTION
        let isAvailableInSection = false; //CASE: IF AVAILABLE IN SECTION THEN MUST BE INCLUDED IN RESULT
        let isFilteredApplied = false; //CASE: IF NO FILTER IS SELECTED IN SECTION THEN SKIP THAT

        section.filters.forEach((filter) => {
          // ITERATING THROUGH EACH FILTER
          if (filter.checked) {
            useFilteredData = true;
            isFilteredApplied = true;
            isAvailableInSection = isAvailableInSection || filterData(filter, item);
          }
        });

        isPresent = isPresent && (isAvailableInSection || !isFilteredApplied);
      });

      return isPresent;
    });
    return useFilteredData ? filteredData : data;
  };

  const onFilterChange = (parentIndex, mainIndex, selectedFilter) => {
    setFilters((filters) => {
      filters[parentIndex].filters[mainIndex].checked = !filters[parentIndex].filters[mainIndex].checked;
      return filters;
    });
    setProgramChangeMeta(Math.random());
  };

  return {
    filters,
    onFilterChange,
  };
};
