import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Permission } from '../../../../core';
import { FormsDialog, UserService } from '../../../../shared';
import { FormService } from '../../services';
import { Tracker } from '../../../../core/services/tracker/tracker';

import * as appStore from '../../../../core/store/app';
import { DocumentTitle } from '../../../../v2/shared';

const Forms = React.lazy(() => import('../../components/forms/forms'));
const FormsFilter = React.lazy(() => import('../../components/form-filter/form-filter'));
const FormRequiredMessage = React.lazy(() => import('../../components/form-required-message/form-required-message'));

class FormList extends Component {
  isRequiredFormAPILoading = false;
  INTERVAL_REF = null;
  formService = new FormService();
  tracker = new Tracker();
  userService = new UserService();
  state = {
    activeFilters: [0, 1, 2, 3, 4, 5],
    collection: [],
    searchedCollection: [],
    searchText: '',
    activeView: 'courses',
    formsFetchedSuccessfully: false,
    openFormDetail: false,
    formData: null,
    userDetail: null,
    isRequiredFormExist: false,
  };

  isRequiredFormListFetched = false;
  isNotRequiredFormListFetched = false;

  componentWillUnmount() {
    const { isRequiredFormExistOnLogin } = this.props;
    if (isRequiredFormExistOnLogin) {
      this.props.history.push('/forms');
    }
    if (this.INTERVAL_REF) {
      clearInterval(this.INTERVAL_REF);
    }
  }

  componentDidMount = () => {
    const { isRequiredFormExist } = this.props;
    if (isRequiredFormExist === false) {
      this.getForms();
    }
  };

  componentWillReceiveProps(props) {
    const { userDetail, isRequiredFormExist } = props;
    if (isRequiredFormExist && !this.isRequiredFormListFetched) {
      this.getForms(true);
      this.isRequiredFormListFetched = true;
    }
    if (isRequiredFormExist === false && !this.isNotRequiredFormListFetched) {
      this.getForms();
      this.isNotRequiredFormListFetched = true;
    }
    this.setState({ ...this.state, userDetail, isRequiredFormExist });
  }

  /**
   * @name getForms
   * @return {void}
   * @http_call_for_forms_list_fetching
   */
  getForms = (isRequiredFormExist = false) => {
    this.formService
      .fetchUserForms(isRequiredFormExist)
      .then((_successLog) => {
        this.setState({
          ...this.state,
          formsFetchedSuccessfully: true,
          collection: _successLog,
        });
      })
      .catch((e) => null);
  };

  /**
   * @name openFormDetailModal
   * @param {Object} form selected form object
   * @return {void}
   */
  openFormDetailModal = (form) => {
    this.tracker.append(Tracker.RouteEnum.form.form + '/' + form.formName + '/' + form.formId);
    this.setState({ ...this.state, openFormDetail: true, formData: form });
  };

  /**
   * @name onCloseFormDetailModal
   * @return {void}
   */
  onCloseFormDetailModal = () => {
    this.tracker.append(Tracker.RouteEnum.form.form);
    this.checkRequiredForms();
    this.setState({ ...this.state, openFormDetail: false, formData: null });
  };

  /**
   * @name checkRequiredForms
   * @description this will check existing form if existing form exist then fetch count and remaining existing list
   * otherwise fetch all the forms and set isRequiredFormExist to false in store
   * @return {void}
   */
  checkRequiredForms = () => {
    if (!this.props.isRequiredFormExistOnLogin) {
      this.getForms();
      return;
    }
    if (!this.isRequiredFormAPILoading) {
      this.isRequiredFormAPILoading = true;
      this.userService.getRequiredFormsCount().then((_successLog) => {
        this.isRequiredFormAPILoading = false;
        const isRequiredFormExist = _successLog.pendingSubmissionCount > 0;
        this.props.setRequiredFormExistance(isRequiredFormExist);
        if (isRequiredFormExist) {
          this.getForms(true);
          return;
        }
        this.props.setRequiredFormExistOnLogin(false);
        this.props.history.push('/home');
      });
    }
  };

  /**
   * @name sortCollection
   * @param {string} sortType
   * @return {void}
   */
  sortCollection = (sortType) => {
    const sortedCollection = this.state.collection.sort((a, b) => {
      if (sortType === 'byDate') {
        return new Date(b.locationAssignedDate) - new Date(a.locationAssignedDate);
      }
      if (sortType === 'atoz') {
        return a.formName.toLowerCase() > b.formName.toLowerCase()
          ? 1
          : b.formName.toLowerCase() > a.formName.toLowerCase()
          ? -1
          : 0;
      }
      if (sortType === 'required') {
        return b.isRequired - a.isRequired;
      }
      if (sortType === 'reverseByDate') {
        return new Date(b.locationAssignedDate) - new Date(a.locationAssignedDate);
      }
      // DEFAULT RETURN
      return -1;
    });
    this.setState({ ...this.state, collection: sortedCollection });
  };

  /**
   * @name searchByFormName
   * @param {object} event
   * @return {void}
   */
  searchByFormName = (event) => {
    const searchedCollection = this.state.collection.filter((item) => {
      return item.formName.toLowerCase().includes(event.target.value.toLowerCase());
    });
    this.setState({
      ...this.state,
      searchedCollection,
      searchText: event.target.value,
    });
  };

  render() {
    return (
      <Suspense fallback={<CircularProgress />}>
        <DocumentTitle title="page.title.forms" />
        <Permission>
          {this.state.isRequiredFormExist ? <FormRequiredMessage /> : ''}
          <FormsDialog
            isDialogOpen={this.state.openFormDetail}
            formData={this.state.formData}
            onClose={this.onCloseFormDetailModal}
            userDetail={this.state.userDetail}
            checkRequiredForms={this.checkRequiredForms}
          />

          {/* BEGIN: Form FILTER */}
          <FormsFilter
            onSearch={this.searchByFormName}
            changeViewType={this.changeViewType}
            activeFilters={this.state.activeFilters}
            setFilters={this.setFilters}
            sortCollection={this.sortCollection}
          />
          {/* END: EXPLORE FILTER */}
          {/* BEGIN: CARD VIEW */}
          {this.state.searchText ? (
            <Forms data={this.state.searchedCollection} openFormDetail={this.openFormDetailModal} />
          ) : (
            <Forms data={this.state.collection} openFormDetail={this.openFormDetailModal} />
          )}
          {/* <Forms data={this.state.collection} openFormDetail={this.openFormDetailModal} /> */}

          {/* END: CARD VIEW */}
        </Permission>
      </Suspense>
    );
  }
}

/**
 * @name mapStateToProps
 * @param {object} userDetail
 * @desc Maps global redux state to props.
 * @return {object}
 */
const mapStateToProps = (state) => {
  return {
    userDetail: state.app.userDetail,
    isRequiredFormExist: state.app.isRequiredFormExist,
    isRequiredFormExistOnLogin: state.app.isRequiredFormExistOnLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRequiredFormExistance: (isRequiredFormExist) => dispatch(appStore.setRequiredFormExistance(isRequiredFormExist)),
    setRequiredFormExistOnLogin: (isRequiredFormExistOnLogin) =>
      dispatch(appStore.setRequiredFormExistOnLogin(isRequiredFormExistOnLogin)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormList);
