import { Grid, withStyles } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Closed, HasSchedule, NoSchedule, Schedule } from "../";
import { PlayMainService } from "../../services";
import styles from "./play-area-top.style";

const playAreaTop = props => {
  const { classes } = props;
  const _playMainService = new PlayMainService();

  return (
    <Grid container item className={classes.playAreaRoot}>
      {props.status.type === _playMainService.NO_SCHEDULE_DEFINED && (
        <Grid item md={12}>
          <NoSchedule
            tabName={props.tabName}
            redeem={props.redeem}
            status={props.status}
            updateRedeemState={props.updateRedeemState}
          />
        </Grid>
      )}

      {(props.status.type === _playMainService.NOT_IN_SCHEDULE ||
        props.status.type === _playMainService.ON) && (
        <>
          <Grid
            item
            xs={12}
            md={props.align || props.status.type === _playMainService.ON ? 7 : 12}
            className={[
              props.status.type === _playMainService.NOT_IN_SCHEDULE
                ? classes.padded
                : null
            ].join(" ")}
          >
            {props.status.type === _playMainService.NOT_IN_SCHEDULE && (
              <Closed
                noGap 
                mainText={
                  <FormattedMessage
                    id="currentlyClosed"
                    values={{ tabName: props.tabName }}
                  />
                }
                subText={<FormattedMessage id="currentlyClosedSub" />}
                schedule={props.schedule}
              />
            )}
            {props.status.type === _playMainService.ON && (
              <HasSchedule {...props} className={classes.hasScheduleWrapper} />
            )}
          </Grid>
          {props.status.type === _playMainService.ON && props.schedule && (
              <Grid item xs={12} md={5} className={classes.scheduleContainer}>
              <Schedule schedule={props.schedule} />
            </Grid>
         )}
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(playAreaTop);
