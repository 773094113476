import { useState } from 'react';
import { CAREER_COMPASS_CONSTANTS } from '../../services/career-compass.service';

/**
 * Custom hook for the Introduction Guide component
 *
 * @param {object} props - The component props
 * @param {object} props.history - The history object
 * @param {function} props.onContinue - The onContinue function
 * @param {object} carouselRef - The carousel reference
 * @returns {object} - The hook methods
 */
const useIntroductionGuideHook = ({ history, onContinue }, carouselRef) => {
  const SLIDES_DATA = [{ slide: 1 }, { slide: 2 }, { slide: 3 }, { slide: 4 }, { slide: 5 }];
  const [currentSlide, setCurrentSlide] = useState(0);

  /**
   * Function to check if the slide is active
   *
   * @param {number} slide - The slide number
   * @returns {boolean} - The slide active status
   */
  const isSlideActive = (slide) => {
    return slide === currentSlide;
  };

  /**
   * Function to check if the current slide is the first slide
   *
   * @returns {boolean} - The first slide status
   */
  const isFirstSlide = () => {
    return currentSlide === 0;
  };

  /**
   * Function to check if the current slide is the last slide
   *
   * @returns {boolean} - The last slide status
   */
  const isLastSlide = () => {
    return currentSlide === SLIDES_DATA.length - 1;
  };

  /**
   * Function to be called before the slide changes
   *
   * @param {number} nextSlide - The next slide number
   */
  const beforeSlideChange = (nextSlide) => {
    setCurrentSlide(nextSlide);
  };

  /**
   * Function to handle the slide change
   *
   * @param {number} newSlide - The new slide number
   */
  const handleSlideChange = (newSlide) => {
    carouselRef.current.goToSlide(newSlide);
  };

  /**
   * Function to defer the introduction guide.
   * It will be called when the user clicks on the 'Not Right Now' button.
   * It redirects to the home page.
   */
  const onDefer = () => {
    history.push('/home');
  };

  /**
   * Function to get started with the introduction guide.
   * It will be called when the user clicks on the 'Let's Get Started' button.
   */
  const onGetStarted = () => {
    onContinue(CAREER_COMPASS_CONSTANTS.STEP_STATE.COMPLETED);
  };

  return {
    SLIDES_DATA,
    currentSlide,
    isSlideActive,
    isFirstSlide,
    isLastSlide,
    beforeSlideChange,
    handleSlideChange,
    onDefer,
    onGetStarted,
  };
};

export default useIntroductionGuideHook;
