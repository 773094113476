import React from 'react';

const KeyIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-Key">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Key" clipPath="url(#clip-Key)">
        <path
          id="key-solid"
          d="M15.75,16.5a8.234,8.234,0,1,0-7.861-5.733L.328,18.328a1.124,1.124,0,0,0-.328.8v3.75A1.122,1.122,0,0,0,1.125,24h3.75A1.122,1.122,0,0,0,6,22.875V21H7.875A1.122,1.122,0,0,0,9,19.875V18h1.875a1.124,1.124,0,0,0,.8-.328l1.561-1.561A8.269,8.269,0,0,0,15.75,16.5Zm1.875-8.25A1.875,1.875,0,1,1,19.5,6.375,1.874,1.874,0,0,1,17.625,8.25Z"
          transform="translate(0 0)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

KeyIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default KeyIcon;
