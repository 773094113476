import { Http } from '../../../../core';

export class HomeService {
  /**
   * getUserAssessment
   * @desc Fetches user assessment from server.
   * @return {Promise}
   */
  getUserAssessment = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/assessment/')
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((_errorLog) => {
          console.log(_errorLog);
          reject(false);
        });
    });
  };

  /**
   * @name launchAssessment
   * @param {function} callback optional argument, if there's any callback that needs be called
   * it can be passed in this param.
   * @desc Launches assessment and redirects user to assessment URL.
   * @return {void}
   */
  launchAssessment = async (callback = null) => {
    try {
      const { data } = await Http.REQUEST.get('/assessment/create');
      if (data) {
        window.open(data);
        if (callback) {
          callback();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @name getRecommendedContent
   * @desc Fetches recommended contact from backend, evaluated
   * on the basis on user's survey.
   * @return {Promise}
   */
  getRecommendedContent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Http.REQUEST.get('/programs/recommended');
        if (data) {
          const response = {
            ...data.metadata,
            resource_key: data.bundleId,
            title: data.bundleName,
            description: data.descriptionShort,
          };
          const keys = Object.keys(response);
          const requiredKeys = ['title', 'description', 'resource_key'];
          let isResponseValid = true;
          requiredKeys.forEach((_requiredKey) => {
            if (keys.indexOf(_requiredKey) === -1) {
              isResponseValid = false;
            }
          });
          if (isResponseValid) {
            // RESOLVING DATA ONLY IF ITS VALID
            resolve(response);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      } catch (e) {
        console.log(e);
        resolve(null);
      }
    });
  };
}
