import { Typography, Box, Button, Card, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './icon.card.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { KeyIcon, RedirectIcon } from '../../icons';

const IconCard = (props) => {
  const { classes } = props;
  const intl = useIntl();

  return (
    <Box
      onClick={() => {
        props.handleLaunchClick();
      }}
      className={classes.root}
      tracking-type={props.trackingType}
      tracking-id={props.trackingId}
      id="icon-card-container"
      title={`Launch ${props.name}`}
      aria-label={`Launch ${props.name}`}
      role="link"
    >
      <Card data-cy-name="service-card" className={classes.card}>
        <Box className={classes.iconContainer}>
          <Icon fontSize="large" color="primary">
            {props.icon}
          </Icon>
        </Box>
        <Box display="flex" className={classes.cardLayout}>
          <Box className={classes.contentArea} component="div" display="flex" flexDirection="column">
            <Box marginBottom="10px" className={classes.centerContainer}>
              <Typography id="icon-card-title" variant="h3" color="secondary" className={classes.boxTitle}>
                {props.name} &nbsp;
                {props.displayCredential && <KeyIcon size={20} fill="#0F445A" />}
              </Typography>
            </Box>
            <Typography id="icon-card-desc" className={classes.boxDescription}>
              {props.description}
            </Typography>

            <Box className={classes.actionBox}>
              {props.serviceUrl && (
                <Typography
                  data-cy-name={`service-url-btn-${props.id}`}
                  className={[classes.actionBtn, 'service-url-btn'].join(' ')}
                  color="primary"
                  endIcon={!(props.isAudio || props.isVideo || props.isRelativePath) ? <RedirectIcon size={20} /> : null}
                  id="icon-card-btn"
                >
                  <FormattedMessage id="launch" />
                  {!(props.isAudio || props.isVideo || props.isRelativePath) && <RedirectIcon size={20} />}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(IconCard);
