import { Http } from "../../../core";

export class Rest {
  userInteraction = async userInteraction => {
    return await Http.REQUEST.post(
      "/resource/userInteraction",
      userInteraction
    );
  };

  getLaunchUrl = async courseId => {
    return await Http.REQUEST.get(`/resource/launch/${courseId}`);
  };
}
