import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HorizontalCard from '../../../../shared/components/horizontal-card/horizontal-card';
import styles from './featured-contents.style';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { UserService } from '../../../../shared/services';
import moment from 'moment';
import CustomDot from './custom-dot';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 50,
  },
};

const FeaturedContents = (props) => {
  const userService = new UserService();
  const { classes } = props;
  const FEATURED_CONTENTS = props.contents.sort((a, b) => a.slide - b.slide);
  const CarouselRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(FEATURED_CONTENTS[0]);
  const [VIDEO_START_TIME, setVideoStartTime] = React.useState(null);
  return (
    <Grid container item className={classes.sectionMargin} sm={12} direction="row">
      <ModalVideo
        channel="custom"
        url={currentSlide.videoUriPath}
        autoplay
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          const userTimeSpentInSecs = moment.duration(moment().diff(VIDEO_START_TIME)).asSeconds();
          userService.registerAnalyticEvent(currentSlide.title, 'watched', Math.floor(userTimeSpentInSecs), props.userName);
        }}
      />
      <Grid>
        <Typography id="featured-content-title" variant="h2" color="secondary" className={classes.carouselViewText}>
          <FormattedMessage id="featuredContent" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} md={12}>
          <Carousel
            id="featured-content-carousel"
            ref={CarouselRef}
            additionalTransfrom={0}
            arrows={false}
            autoPlay={false}
            autoPlaySpeed={5000}
            centerMode={false}
            partialVisible
            className={classes.carousel}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass={classes.carouselItem}
            keyBoardControl
            minimumTouchDrag={80}
            customButtonGroup={null}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
            customDot={<CustomDot />}
          >
            {FEATURED_CONTENTS.map(
              (data) =>
                data.type === 'TILE' && (
                  <HorizontalCard
                    key={data.slide}
                    title={data.title}
                    type={data.type}
                    description={data.description}
                    image={data.thumbnailUriPath}
                    video={data.videoUriPath}
                    actionUrl={data.launchUrl}
                    buttonTitle="launch"
                    onCardClick={props.callLaunchUrl}
                    openVideo={() => {
                      setOpen(true);
                      setCurrentSlide(data);
                      setVideoStartTime(moment());
                      userService.registerAnalyticEvent(data.title, 'Clicked', null, props.userName);
                    }}
                  />
                )
            )}
          </Carousel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FeaturedContents);
