export default (theme) => ({
  root: {
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 665,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  cardLayout: {
    width: '100%',
  },
  contentArea: {
    width: '100%',
    position: 'relative',
  },
});
