import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Radio, FormControlLabel, Collapse, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import styles from './program-selection.styles';
import { Constant } from '../../../../../shared';
import { Typography } from '@orijinworks/frontend-commons';
import useProgramSelectionHook from './useProgramSelectionHook';
import ProgramRecommendation from '../../components/program-recommendation/program-recommendation';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';
const ProgramSelection = ({ classes, currentStep, selectedLocale, unleashProps }) => {
  const {
    selectedProgram,
    handleProgramSelect,
    stepTitle,
    stepDescription,
    handleEnrollAndNavigate,
    programs,
    hasSelectedProgram,
    isFirstProgram,
  } = useProgramSelectionHook(currentStep, selectedLocale);

  return (
    <Box className={classes.root}>
      <img
        src={
          isFirstProgram
            ? `${Constant.CDN_BASE_URL}/assets/career-compass/program_one.png`
            : `${Constant.CDN_BASE_URL}/assets/career-compass/career-compass-walking-sign.png`
        }
        alt=""
        className={classes.image}
      />
      <Typography variant="h2" className={classes.title}>
        {stepTitle}
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        {stepDescription}
      </Typography>
      <Box className={classes.programList}>
        {programs.map((program) => (
          <Box
            key={program.id}
            className={`${classes.programOption} ${selectedProgram === program.id ? classes.selected : ''}`}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={selectedProgram === program.id}
                  onChange={() => handleProgramSelect(program.id)}
                  value={program.id}
                  name="program-selection"
                  color="primary"
                />
              }
              label={
                <Typography variant="h3" className={classes.programTitle}>
                  {program.title}
                </Typography>
              }
              className={classes.programHeader}
            />
            <Collapse in={selectedProgram === program.id}>
              <Box className={classes.programContent}>
                <Typography variant="body2" className={classes.programDescription}>
                  {program.description}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleEnrollAndNavigate(program.id, program.name)}
                  className={classes.selectButton}
                  aria-label="Select this program"
                  tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                  tracking-id={`select-program-${program.id}`}
                >
                  <FormattedMessage
                    id={
                      hasSelectedProgram
                        ? 'career-compass.program-selection.selected-button'
                        : 'career-compass.program-selection.select-button'
                    }
                  />
                </Button>
              </Box>
            </Collapse>
          </Box>
        ))}

        {unleashProps.isFlagEnabled() && (
          <>
            <Divider />
            <ProgramRecommendation selectedLocale={selectedLocale} />
          </>
        )}
      </Box>
    </Box>
  );
};

ProgramSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  currentStep: PropTypes.object,
  selectedLocale: PropTypes.string.isRequired,
};

export default withStyles(styles)(withUnleash(ProgramSelection, UnleashService.FLAGS.PROGRAM_RECOMMENDATION));
