import Drawer from '@material-ui/core/Drawer';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Box, Divider, Tooltip, withStyles, Grid, IconButton, Typography, Badge } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { connect } from 'react-redux';
import { Utility } from '../../../shared/services';
import useStyles from './side-menu.style';
import {
  ChatIcon,
  Constant,
  DashboardIcon,
  EntertainmentIcon,
  ExploreIcon,
  FileIcon,
  ListIcon,
  MessageIcon,
  SkillsIcon,
  TrophyIcon,
  CallIcon,
} from '../../../shared';
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.primary.lipstick,
    color: theme.palette.primary.white,
  },
}))(Badge);

function SideBar(props) {
  const utilityService = new Utility();
  const { drawer, history, balance, userDetail } = props;
  const classes = useStyles();
  const messagingStatus = utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.MESSAGING_PERSON);
  const messagingFNFStatus = utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.FNF_MESSAGING);
  const intl = useIntl();

  const DRAWER_ITEMS = [
    {
      text: <FormattedMessage id="home" />,
      label: intl.formatMessage({ id: 'goToHomepage' }),
      icon: <DashboardIcon />,
      path: '/home',
      isVisible: true,
      status: true,
      active: function () {
        return [
          '/home',
          '/bookmarks',
          '/transcript',
          '/interest-profiler',
          '/pdq-assessment/intro',
          '/pdq-assessment/results',
        ].some((item) => getActiveLinkItem(item));
      },
      cyName: 'menu-btn-home',
      trackingType: Constant.TRACKING_TYPES.HOMEPAGE,
      trackingId: 'view-homepage',
    },
    {
      text: <FormattedMessage id="programCatalog" />,
      label: intl.formatMessage({ id: 'goToProgramCatalog' }),
      icon: <ListIcon />,
      path: '/programs',
      isVisible: true,
      status: true,
      active: function () {
        return getActiveLinkItem('/programs') || getActiveLinkItem('/programs/:id/details');
      },
      cyName: 'menu-btn-pathways',
      trackingType: Constant.TRACKING_TYPES.PROGRAM,
      trackingId: 'view-program-catalog',
    },
    {
      text: <FormattedMessage id="careerPaths" />,
      label: intl.formatMessage({ id: 'goToCareerPaths' }),
      icon: <MapOutlinedIcon />,
      path: '/career-paths',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.CAREER_PATH),
      active: function () {
        return getActiveLinkItem('/career-paths');
      },
      cyName: 'menu-btn-career-paths',
      trackingType: Constant.TRACKING_TYPES.CAREER_PATHS,
      trackingId: 'view-career-paths',
    },
    {
      text: <FormattedMessage id="careerDirectory" />,
      label: intl.formatMessage({ id: 'goToCareerDirectory' }),
      icon: <SkillsIcon />,
      path: '/careers',
      isVisible: true,
      status: true,
      active: function () {
        return getActiveLinkItem('/careers') || getActiveLinkItem('/careers/:id/details');
      },
      cyName: 'menu-btn-pathways',
      trackingType: Constant.TRACKING_TYPES.CAREER_DIRECTORY,
      trackingId: 'view-careers',
    },
    {
      text: <FormattedMessage id="explore" />,
      label: intl.formatMessage({ id: 'goToExplore' }),
      icon: <ExploreIcon />,
      path: '/explore',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.EXPLORE),
      active: function () {
        return ['/explore', '/resume-builder', '/resume-preview'].some((item) => getActiveLinkItem(item));
      },
      cyName: 'menu-btn-explore',
      trackingType: Constant.TRACKING_TYPES.SERVICE,
      trackingId: 'view-services',
    },
    {
      text: <FormattedMessage id="voiceCall" />,
      label: intl.formatMessage({ id: 'goToVoiceCalls' }),
      icon: <CallIcon />,
      path: '/voice-call',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.VOICE_CALLS),
      active: function () {
        return getActiveLinkItem('/voice-call');
      },
      cyName: 'menu-btn-voice-call',
      trackingType: Constant.TRACKING_TYPES.SERVICE,
      trackingId: 'view-voice-calls',
    },
    {
      text: <FormattedMessage id="FnFMessaging" />,
      label: intl.formatMessage({ id: 'goToFnFMessaging' }),
      icon: messagingFNFStatus ? (
        <StyledBadge badgeContent={props.fnfUnreadCount || 0}>
          <MessageIcon />
        </StyledBadge>
      ) : (
        <MessageIcon />
      ),
      path: '/friends-family',
      isVisible: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.FNF_MESSAGING),
      status: true,
      active: function () {
        return getActiveLinkItem('/friends-family');
      },
      cyName: 'menu-btn-fnf',
      trackingType: Constant.TRACKING_TYPES.FRIENDS_AND_FAMILY,
      trackingId: 'view-fnf-messaging',
    },
    {
      text: <FormattedMessage id="messaging" />,
      label: intl.formatMessage({ id: 'goToStaffMessaging' }),
      icon: messagingStatus ? (
        <StyledBadge badgeContent={(props.unreadMessagesCount || 0) + (props.announcementCount || 0)}>
          <ChatIcon />
        </StyledBadge>
      ) : (
        <ChatIcon />
      ),
      path: '/messaging',
      isVisible:
        utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.MESSAGING) &&
        utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.MESSAGING_PERSON),
      status: true,
      active: function () {
        return getActiveLinkItem('/messaging');
      },
      cyName: 'menu-btn-fnf',
      trackingType: Constant.TRACKING_TYPES.STAFF_MESSAGING,
      trackingId: 'view-staff-messaging',
    },
    {
      text: <FormattedMessage id="entertainment" />,
      label: intl.formatMessage({ id: 'goToEntertainment' }),
      icon: <EntertainmentIcon />,
      path: '/entertain',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.PLAY),
      active: function () {
        return getActiveLinkItem('/entertain');
      },
      cyName: 'menu-btn-play',
      trackingType: Constant.TRACKING_TYPES.ENTERTAINMENT,
      trackingId: 'view-entertainment-center',
    },
    {
      text: <FormattedMessage id="achievements" />,
      label: intl.formatMessage({ id: 'goToAchievements' }),
      icon: <TrophyIcon />,
      path: '/achievements',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.ACHIEVEMENTS),
      active: function () {
        return getActiveLinkItem('/achievements');
      },
      cyName: 'menu-btn-achievements',
      trackingType: Constant.TRACKING_TYPES.ACHIEVEMENT,
      trackingId: 'view-achievements',
    },
    {
      text: <FormattedMessage id="forms" />,
      label: intl.formatMessage({ id: 'goToForms' }),
      icon: <FileIcon />,
      path: '/forms',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.FORMS),
      active: function () {
        return getActiveLinkItem('/forms');
      },
      cyName: 'menu-btn-forms',
      trackingType: Constant.TRACKING_TYPES.FORM,
      trackingId: 'view-forms',
    },
  ];

  const getActiveLinkItem = (path) => {
    return window.location.pathname.includes(path);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    if (drawer.open) {
      drawer.handleDrawerToggle();
    }
    history.push(path);
  };

  const drawerViewV2 = (openState, setOpen) => (
    <Box display="flex" height="100%" flexDirection="column">
      <Box className={classes.drawerWrapperV2}>
        <Box className={classes.topArea}>
          <ListItem
            className={classes.textWithIconContainer}
            onClick={(e) => handleClick(e, '/my-profile')}
            button
            aria-label={intl.formatMessage({ id: 'goToMyProfile' })}
          >
            <ListItemIcon>
              <AccountCircleIcon
                fontSize="large"
                className={[
                  classes.gutterRight,
                  getActiveLinkItem('/my-profile') ? classes.myProfileIconActive : classes.myProfileIcon,
                ].join(' ')}
              />
            </ListItemIcon>
            <Box>
              <Typography className={classes.userName}> {userDetail.name || ''} </Typography>
              {utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.INCENTIVE_ENGINE) && (
                <Box className={classes.tokenBox}>
                  <Box component="span" className={classes.tokenIndicator} />
                  <Typography className={classes.tokenText}>{balance || 0} Tokens</Typography>
                </Box>
              )}
            </Box>
          </ListItem>
        </Box>

        <Divider className={classes.divider} />

        <Grid container item className={classes.drawerContent}>
          <List className={classes.drawerContentList} id="drawer-list">
            {DRAWER_ITEMS.filter((item) => item.status).map(
              (item) =>
                item.isVisible && (
                  <ListItem
                    onClick={(e) => handleClick(e, item.path)}
                    data-cy-name={item.cyName}
                    key={utilityService.generateUniqueKey()}
                    disabled={!item.status}
                    button
                    className={[classes.drawerContentListItem, item.active() ? classes.drawerContentActive : null].join(' ')}
                    tracking-type={item.trackingType}
                    tracking-id={item.trackingId}
                    aria-label={item.label}
                  >
                    <ListItemIcon className={classes.drawerContentIcon}>
                      <Tooltip title={item.text}>{item.icon}</Tooltip>
                    </ListItemIcon>
                    <Typography className={classes.drawerContentText}>{item.text}</Typography>
                  </ListItem>
                )
            )}
          </List>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <div className={classes.root}>
      {utilityService.isModuleActivated('home') ? (
        <Box className={classes.bgOverlayHome} component="div" />
      ) : (
        utilityService.isModuleActivated('careers') && <Box className={classes.bgOverlayCareers} component="div" />
      )}

      <Box
        className={[
          classes.expandCollapseBtnContainer,
          drawer.open ? classes.collapseIconPosition : classes.expandIconPosition,
        ].join(' ')}
      >
        <IconButton
          id="expand-collapse-icon-btn"
          onClick={() => drawer.handleDrawerToggle()}
          className={classes.expandCollapseBtn}
          aria-label={intl.formatMessage({ id: drawer.open ? 'collapseSidebar' : 'expandSidebar' })}
        >
          {drawer.open ? (
            <ChevronLeftIcon className={classes.menuMainIcon} />
          ) : (
            <ChevronRightIcon className={classes.menuMainIcon} />
          )}
        </IconButton>
      </Box>

      {/* <Box className={classes.topWrapper} component="div">
        <Box className={classes.menuBtn}>
          <IconButton
            style={{
              color: utilityService.isModuleActivated("home") ? "white" : null
            }}
            onClick={() => drawer.handleDrawerToggle(true)}>
            <StyledBadge
              badgeContent={
                (props.fnfUnreadCount || 0)
              }
            >
              <MenuIcon className={classes.menuMainIcon} />
            </StyledBadge>
          </IconButton>
        </Box>
      </Box> */}

      <Box className={[drawer.open ? classes.drawer : classes.drawerClose].join(' ')} aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          classes={{
            paper: [classes.drawerPaper, drawer.open ? classes.drawerOpen : classes.drawerClose].join(' '),
          }}
          open={drawer.open}
          onClose={drawer.handleDrawerToggle}
        >
          {drawerViewV2(drawer.open, drawer.handleDrawerToggle)}
        </Drawer>
      </Box>
    </div>
  );
}

const mapStateToProps = ({ play, app, locale }) => {
  return {
    selectedLocale: locale.locale,
    play,
    unreadMessagesCount: app.unreadMessagesCount,
    announcementCount: app.announcementCount,
    fnfUnreadCount: app.friendsAndFamily.unreadData.totalUnreadCount,
    userDetail: app.userDetail,
  };
};

export default connect(mapStateToProps)(SideBar);
