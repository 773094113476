import { useCallback, useEffect, useState } from 'react';

/**
 * Constant for the initial display count of the not started items
 */
const INITIAL_DISPLAY_COUNT = 4;

/**
 * Custom hook to handle the program content and categorize it
 *
 * @param {Array} content - The content to categorize
 * @returns {object} - The categorized content with helper functions
 */
const useProgramContentHook = ({ content }) => {
  const [items, setItems] = useState({
    completed: [],
    inProgress: [],
    notStarted: [],
  });
  const [showAllItems, setShowAllItems] = useState(false);

  /**
   * Function to check if the item has content progress
   *
   * @param {object} item - The item to check
   * @returns {boolean} - True if the item has content progress, false otherwise
   */
  const hasContentProgress = useCallback((item) => {
    const hasCompleted = Boolean(item.contentProgress?.completed);
    const hasTotal = Boolean(item.contentProgress?.total);
    return hasCompleted && hasTotal;
  }, []);

  const categorizeContent = useCallback(
    (item) => {
      if (!hasContentProgress(item)) return 'notStarted';

      const { completed, total } = item.contentProgress;
      if (completed === total) return 'completed';
      if (completed > 0) return 'inProgress';

      return 'notStarted';
    },
    [hasContentProgress]
  );

  /**
   * Effect to categorize the content into completed, in progress and not started
   */
  useEffect(() => {
    if (!Array.isArray(content)) {
      console.error('Invalid content provided to useProgramContentHook');
      return;
    }

    const result = content.reduce(
      (acc, item) => {
        const category = categorizeContent(item);
        acc[category].push(item);
        return acc;
      },
      { completed: [], inProgress: [], notStarted: [] }
    );
    if (result.inProgress.length === 0 && result.notStarted.length > 0) {
      result.inProgress.push(result.notStarted.shift());
    }

    setItems(result);
  }, [content, categorizeContent]);

  /**
   * Function to check if the item progress is started
   *
   * @param {object} item - The item to check
   * @returns {boolean} - True if the item progress is started, false otherwise
   */
  const isProgressStarted = (item) => {
    return hasContentProgress(item) && item.contentProgress.completed > 0;
  };

  /**
   * Function to check if there are in progress items
   *
   * @returns {boolean} - True if there are in progress items, false otherwise
   */
  const hasInProgressItems = () => {
    const inProgress = content.filter(isProgressStarted);
    return inProgress.length > 0;
  };

  /**
   * Function to check if the "View More" button should be shown
   *
   * @returns {boolean} - True if the "View More" button should be shown, false otherwise
   */
  const shouldShowViewMore = () => {
    return items.notStarted.length > INITIAL_DISPLAY_COUNT;
  };

  /**
   * Function to get the slice of the not started items.
   * If initial is true, it will return the initial display count, otherwise it will return the rest
   *
   * @param {boolean} initial - Whether to get the initial slice or the rest
   * @returns {Array} - The slice of the not started items
   */
  const getNotStartedItemsSlice = (initial = true) => {
    if (initial) {
      return items.notStarted.slice(0, INITIAL_DISPLAY_COUNT);
    } else {
      return items.notStarted.slice(INITIAL_DISPLAY_COUNT);
    }
  };

  /**
   * Function to toggle the show all items state
   */
  const toggleShowAllItems = () => {
    setShowAllItems((prevState) => !prevState);
  };

  return {
    completedItems: items.completed,
    inProgressItems: items.inProgress,
    notStartedItems: items.notStarted,
    showAllItems,
    hasInProgressItems,
    shouldShowViewMore,
    getNotStartedItemsSlice,
    toggleShowAllItems,
  };
};

export default useProgramContentHook;
