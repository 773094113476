import { useState, useEffect } from 'react';
import { fetchProgramRecommendations } from '../../services/career-compass.service';

const useProgramRecommendationHook = (selectedLocale) => {
  const DEFAULT_DISPLAY_COUNT = 3; // Constant for the default display count
  const [showAllItems, setShowAllItems] = useState(false);
  const [programRecommendations, setProgramRecommendations] = useState([]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const recommendations = await fetchProgramRecommendations();
        setProgramRecommendations(recommendations);
      } catch (error) {
        console.error('Error fetching program recommendations:', error);
      }
    };

    fetchRecommendations();
  }, [selectedLocale]);

  const toggleShowAllItems = () => {
    setShowAllItems((prev) => !prev);
  };

  const getDisplayedRecommendations = () => {
    return showAllItems ? programRecommendations : programRecommendations.slice(0, DEFAULT_DISPLAY_COUNT);
  };

  const shouldShowViewMore = () => {
    return programRecommendations.length > DEFAULT_DISPLAY_COUNT;
  };

  const shouldDisplayNoRecommendations = () => {
    return programRecommendations.length === 0;
  };

  return {
    programRecommendations,
    getDisplayedRecommendations,
    shouldShowViewMore,
    shouldDisplayNoRecommendations,
    showAllItems,
    toggleShowAllItems,
  };
};

export default useProgramRecommendationHook;
