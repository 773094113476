import { useEffect, useState, useCallback } from 'react';
import { getVoiceProfile } from '../../services';
import { Constant } from '../../../../shared';

export const useVoiceIdRegistrationHook = () => {
  const [activePrompt, setActivePrompt] = useState(0);
  const [profileStatus, setProfileStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const PROMPTS = [
    'Describe your favorite meal.',
    'Talk about a memorable moment in your life.',
    'Share what you enjoy doing in your free time.',
    'Mention a book or story you like.',
    'Explain a skill or talent you have.',
  ];

  const handleNextPrompt = useCallback(() => {
    setActivePrompt((prevPrompt) => (prevPrompt + 1) % PROMPTS.length);
  }, [PROMPTS.length]);

  const handlePreviousPrompt = useCallback(() => {
    setActivePrompt((prevPrompt) => (prevPrompt - 1 + PROMPTS.length) % PROMPTS.length);
  }, [PROMPTS.length]);

  const getLearnerVoiceProfile = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getVoiceProfile();
      if (response.status === 200 && response.data) {
        setProfileStatus(response.data.profileStatus);
      } else {
        setProfileStatus(Constant.VOICE_PROFILE_ID_STATUS.RERECORD_AUDIO);
      }
    } catch (error) {
      console.error('Error fetching voice profile:', error);
      setError('Failed to fetch voice profile. Please try again.');
      setProfileStatus(Constant.VOICE_PROFILE_ID_STATUS.RERECORD_AUDIO);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getLearnerVoiceProfile();
  }, [getLearnerVoiceProfile]);

  return {
    activePrompt,
    handleNextPrompt,
    handlePreviousPrompt,
    PROMPTS,
    profileStatus,
    setProfileStatus,
    loading,
    error,
    getLearnerVoiceProfile,
  };
};

export default useVoiceIdRegistrationHook;
