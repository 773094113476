export default (theme) => ({
  carousel: {
    width: '100%',
    padding: '24px 0px 48px 0px',
  },
  carouselItem: {
    display: 'flex',
    padding: '0 20px',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  dot: {
    display: 'inline-block',
    width: 24,
    minWidth: 24,
    height: 24,
    borderRadius: '100%',
    opacity: 1,
    transition: 'background .5s',
    padding: 0,
    margin: 0,
    marginRight: 6,
    outline: 0,
    background: theme.palette.greyScale[50],
  },
  activeDot: {
    background: theme.palette.aqua.deep,
    '&:hover': {
      background: theme.palette.aqua.deep,
    },
  },
});
