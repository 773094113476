export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    border: `1px solid ${'var(--primary-deep)'}`,
    margin: '24px',
  },
  content: {
    alignItems: 'left',
  },
  title: {
    color: theme.palette.navy.main,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.32px',
  },
  subtitle: {
    color: 'black',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.28px',
  },
  button: {
    textAlign: 'left',
    width: '100%',
    justifyContent: 'space-between',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  endIcon: {
    minWidth: 'auto',
    width: 40,
    height: 40,
    padding: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette.navy.main,
    color: theme.palette.common.white,
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
});
