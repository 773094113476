export default (theme) => ({
  chip: {
    textTransform: 'uppercase',
    padding: 10,
    margin: 'auto',
    fontWeight: 700,
    border: '1px solid',
    height: 40,
    borderRadius: 20,
  },
  notStarted: {
    backgroundColor: theme.palette.colors.inputBackground,
    color: theme.palette.text.sub,
    fill: theme.palette.text.sub,
    borderColor: theme.palette.text.sub,
  },
  inProgress: {
    backgroundColor: theme.palette.colors.paleYellow,
    color: theme.palette.text.primary,
    fill: theme.palette.primary.active,
    borderColor: theme.palette.primary.active,
  },
  completed: {
    backgroundColor: theme.palette.colors.primaryLight,
    color: theme.palette.text.primary,
    fill: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
});
