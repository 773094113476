export default (theme) => ({
  scoresContainer: {
    border: `1px solid ${theme.palette.primary.light}`,
    background: theme.palette.colors.lightGray,
    borderRadius: 10,
    padding: '10px 20px',
    width: '100%',
  },
  scoreProgressArea: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px',
  },
  progressLabelsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  progressLabel: {
    ...theme.typography.t14,
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  progressBarRoot: {
    height: 10,
    borderRadius: 5,
    backgroundColor: 'white',
  },
});
