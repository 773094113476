import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, withStyles } from '@material-ui/core';

import styles from './view.style';
import CloseIcon from '@material-ui/icons/Close';
import { CareerItem, CareersIntro, FiltersArea } from '../../components/';
import { LoaderHOC } from '../../../../../shared/components';
import { Constant } from '../../../../../shared';
import PersonalityResult from '../../../../../content/interest-profiler/components/personality-result/personality-result';
import Attribution from '../../../../../content/careers/components/attribution/attribution';
import { CAREER_DIRECTORY_FILTERS } from '../../../../../content/careers/containers/career-directory/constants';
import { useDocumentTitleHook } from '../../../../shared';

const View = (props) => {
  const { classes, parent } = props;
  const {
    careers,
    searchValue,
    selectedZone,
    total,
    pageSize,
    page,
    ipResults,
    showPersonalityDetails,
    categories,
    alphabeticalGroups,
    selectedCategory,
  } = parent.state;
  const intl = useIntl();

  useDocumentTitleHook('page.title.career-directory');

  return (
    <Box className={classes.root}>
      <Dialog
        id="ip-results-dialog"
        open={showPersonalityDetails}
        onClose={parent.closePersonalityDetailsDialog}
        classes={{ paper: classes.dialogPaper }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography className={classes.title}>
            <FormattedMessage id="interest-profiler-results.title" />
          </Typography>
          <IconButton
            onClick={parent.closePersonalityDetailsDialog}
            className={classes.closeDialogBtn}
            aria-label={intl.formatMessage({ id: 'close' })}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {ipResults?.final_result && <PersonalityResult areaPlacement="bottom-left" {...ipResults} />}
        </DialogContent>
      </Dialog>

      <Grid sm={12} md={12} className={classes.fullWidth}>
        <CareersIntro
          history={parent.props.history}
          ipTaken={!!parent.props.interestProfiler.answersString}
          ipResults={ipResults}
          openPersonalityDetailsDialog={parent.openPersonalityDetailsDialog}
          mainFilter={parent.state.mainFilter}
          setMainFilter={parent.setMainFilter}
          hideSubtext={true}
        />
        <FiltersArea
          selectedZone={selectedZone}
          onZoneChange={parent.handleZoneFilter}
          searchValue={searchValue}
          handleSearchChange={parent.handleSearch}
          onSearch={parent.onSearch}
          onClearFilters={parent.onClearFilters}
          totalCount={total}
          selectedView={parent.state.selectedView}
          handleViewChange={parent.handleViewChange}
          mainFilter={parent.state.mainFilter}
          onGroupChange={parent.handleGroupFilter}
          selectedGroup={parent.state.selectedGroup}
          onCategoryChange={parent.onCategoryChange}
          categories={categories}
          alphabeticalGroups={alphabeticalGroups}
          selectedCategory={selectedCategory}
          handleFilterChange={parent.setMainFilter}
          ipTaken={!!parent.props.interestProfiler.answersString}
        />
      </Grid>

      <LoaderHOC
        apiKey={parent.state.mainFilter === CAREER_DIRECTORY_FILTERS.ALL ? parent.state.selectedGroup : selectedZone}
        type={Constant.ROUTES.CAREERS}
        size={50}
        style={{ marginTop: '30px' }}
      >
        {careers.length > 0 ? (
          <>
            <Box id="career-list" className={classes.dataContainer}>
              <Box ref={parent.scrollRef} />
              {careers.map((career) => (
                <CareerItem
                  key={career.code}
                  title={career.title}
                  subtitle={career.what_they_do}
                  zone={parent.getJobZone(career)}
                  {...parent.getMedianPay(career)}
                  onItemClick={(e) => {
                    parent.onItemClick(e, career.code);
                  }}
                  isBookmarked={career.in_bookmarks}
                  code={career.code}
                  careerFit={career.fit}
                  mainFilter={parent.state.mainFilter}
                />
              ))}
            </Box>

            <Grid container className={classes.footer}>
              <Grid item sm={12} md={12} lg={4} />
              <Grid item sm={12} md={12} lg={4} className={classes.paginationContainer}>
                {total > pageSize && (
                  <Pagination
                    id="career-list-pagination"
                    count={Math.ceil(total / pageSize)}
                    page={page + 1}
                    siblingCount={0}
                    onChange={parent.handlePageChange}
                    shape="rounded"
                    color="secondary"
                    size="large"
                  />
                )}
              </Grid>
              <Grid container item sm={12} md={12} lg={4}>
                <Grid item sm={8} md={6} lg={12} className={classes.attributionContainer}>
                  <Attribution />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box id="no-data" className={[classes.dataContainer, classes.noData].join(' ')}>
            <FormattedMessage id="career-directory.no-data-msg" />
          </Box>
        )}
      </LoaderHOC>
    </Box>
  );
};
export default withStyles(styles)(injectIntl(View));
