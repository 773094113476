import { useState } from 'react';

const useArchiveModalHook = ({
  classes,
  contacts,
  maxContactQuota,
  consumedContactQuota,
  open,
  onArchive,
  unleashProps,
}) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleContactSelection = (contactId) => (event) => {
    const newSelected = [...selectedContacts];
    const selectedIndex = newSelected.indexOf(contactId);

    if (event.target.checked) {
      if (selectedIndex === -1) {
        newSelected.push(contactId);
      }
    } else {
      if (selectedIndex !== -1) {
        newSelected.splice(selectedIndex, 1);
      }
    }

    setSelectedContacts(newSelected);
  };

  const handleArchive = () => {
    onArchive(selectedContacts);
  };

  return {
    selectedContacts,
    handleContactSelection,
    handleArchive,
  };
};

export default useArchiveModalHook;
