import { Http, setPDQAssessmentStatus, store, toggleToast } from '../../../core';
import { Constant } from '../../../shared';

export class PDQAssessmentService {
  /**
   * @name getLearnosityInitOptions
   * @desc get init options for learnosity app
   * @return {Promise}
   */
  getLearnosityInitOptions = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(
        `/services/learnosity/v1/assessments/init-options/${Constant.ASSESSMENTS.PDQ.ACTIVITY_TEMPLATE_ID}/`
      )
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name submitLearnosityAssessment
   * @desc notify backend that assessment is submitted
   * @return {Promise}
   */
  submitLearnosityAssessment = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.put(
        `/services/learnosity/v1/assessments/sessions/responses/${Constant.ASSESSMENTS.PDQ.ACTIVITY_TEMPLATE_ID}/pdq-locator/submissions`
      )
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getPDQScores
   * @desc retrieve PDQ assessment scores
   * @return {Promise}
   */
  getPDQScores = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(
        `/services/learnosity/v1/assessments/scores/${Constant.ASSESSMENTS.PDQ.ACTIVITY_TEMPLATE_ID}/pdq-locator?noLoader=true`
      )
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getPDQAssessmentStatus
   * @desc retrieve PDQ assessment status
   * @return {Promise}
   */
  getPDQAssessmentStatus = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(
        `/services/learnosity/v1/assessments/statuses/${Constant.ASSESSMENTS.PDQ.ACTIVITY_TEMPLATE_ID}/pdq-locator?noLoader=true`
      )
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name launchPDQAssessment
   * @desc launch PDQ assessment
   */
  launchPDQAssessment = async (cb) => {
    try {
      const { data: assessmentData } = await this.getPDQAssessmentStatus();
      if (assessmentData && assessmentData.payload) {
        const { assessmentStatus } = assessmentData.payload;
        if ([Constant.PDQ_ENUM.COMPLETED, Constant.PDQ_ENUM.SUBMITTED].includes(assessmentStatus)) {
          store.dispatch(setPDQAssessmentStatus(assessmentStatus));
          if (cb) cb();
          return;
        }
        window.open('/learnosity/assessment', '_blank');
      }
    } catch (e) {
      console.log(e);
    }
  };
}
