import React from 'react';
import { withStyles, Box, Typography, Grid, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './pinned-career-paths.style';
import usePinnedCareerPathsHook from './usePinnedCareerPathHook';
import { CareerPathsItem } from '../../../career-paths/components';
import { Constant } from '../../../../shared';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const PinnedCareerPaths = (props) => {
  const { classes } = props;
  const { careerPaths, goToDetailsPage, goToCareerPathsPage } = usePinnedCareerPathsHook(props);
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerTextBox}>
          <Typography id="pinned-career-path-title" variant="h2" gutterBottom className={classes.title}>
            <FormattedMessage id="careerPaths" />
          </Typography>
          <Typography id="pinned-career-path-desc" gutterBottom className={classes.description}>
            <FormattedMessage id="pinnedCareerPaths.description" />
          </Typography>
        </Box>
        {careerPaths?.length > 0 && (
          <Box className={classes.headerActionBox}>
            <Button
              id="view-all-career-paths-btn"
              color="secondary"
              size="large"
              className={classes.actionBtn}
              onClick={goToCareerPathsPage}
              tracking-type={Constant.TRACKING_TYPES.CAREER_PATHS}
              tracking-id="view-career-paths"
              endIcon={<ChevronRightIcon />}
              aria-label={intl.formatMessage({ id: 'viewAllCareerPaths' })}
            >
              <FormattedMessage id="viewAllCareerPaths" />
            </Button>
          </Box>
        )}
      </Box>

      {careerPaths?.length > 0 ? (
        <Grid id="pinned-career-paths-container" container spacing={3} className={classes.mainContainer}>
          {careerPaths.map((careerPath) => (
            <Grid item sm={6} md={6} key={careerPath.id}>
              <CareerPathsItem
                id={careerPath.id}
                title={careerPath.title}
                description={careerPath.descriptionShort}
                thumbnailUri={careerPath.thumbnailUriPath}
                pinned={careerPath.pinned}
                handleClick={goToDetailsPage}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Button
          id="view-career-paths-btn"
          variant="contained"
          color="primary"
          size="large"
          className={classes.actionBtn}
          onClick={goToCareerPathsPage}
          tracking-type={Constant.TRACKING_TYPES.CAREER_PATHS}
          tracking-id="view-career-paths"
          aria-label={intl.formatMessage({ id: 'viewCareerPaths' })}
          role="link"
        >
          <FormattedMessage id="viewCareerPaths" />
        </Button>
      )}
    </Box>
  );
};

export default withStyles(styles)(PinnedCareerPaths);
