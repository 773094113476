import React from 'react';
import { Box, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import styles from './conversation-header.style';
import { ScaleUnbalancedIcon, ShieldCheckedIcon } from '../../../../shared/icons';
import { connect } from 'react-redux';
import useConversationHeaderHook from './useConversationHeaderHook';

const ConversationHeader = ({ classes, showIcon, showSubtitle, ...props }) => {
  const { getTitle, getSubtitle, isAttorney } = useConversationHeaderHook(props);

  return (
    <Box className={classes.root}>
      <ListItem className={classes.header}>
        {showIcon && (
          <ListItemIcon>
            <Box className={classes.iconBox}>{isAttorney() ? <ScaleUnbalancedIcon /> : <ShieldCheckedIcon />}</Box>
          </ListItemIcon>
        )}
        <ListItemText classes={{ primary: classes.content }}>
          <Typography id="conversation-header-title" variant="h3" className={[classes.title, classes.truncate].join(' ')}>
            {getTitle()}
          </Typography>
          {showSubtitle && (
            <Typography id="conversation-header-subtitle" className={[classes.text, classes.truncate].join(' ')}>
              {getSubtitle()}
            </Typography>
          )}
        </ListItemText>
      </ListItem>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  staffMessaging: state.staffMessaging,
});

export default connect(mapStateToProps)(withStyles(styles)(ConversationHeader));
