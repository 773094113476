import { Button, Box, Typography, Accordion, AccordionSummary, AccordionDetails, Icon, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './pdq-assessment-completed.style';
import { Constant } from '../../../../shared/services';
import { PDQScores } from '..';

const PDQAssessmentCompleted = (props) => {
  const { classes } = props;
  return (
    <Box>
      <Accordion elevation={0} defaultExpanded className={classes.transparentBackground}>
        <AccordionSummary
          expandIcon={
            <Icon fontSize="large" color="secondary" className="material-icons-outlined">
              expand_circle_down
            </Icon>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.zeroPadding}
        >
          <Typography id="pdq-completed-title" variant="h3" color="secondary" className={classes.accordionTitle}>
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.zeroPadding}>
          <PDQScores />
        </AccordionDetails>
      </Accordion>
      <Box className={classes.actionBox}>
        <Button
          id="view-pdq-assement-results"
          onClick={props.showResults}
          color="primary"
          className={classes.actionButton}
          variant="contained"
          tracking-type={Constant.TRACKING_TYPES.PDQ}
          tracking-id="view-pdq-assement-results"
          aria-label={props.action1Text}
        >
          {props.action1Text}
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(PDQAssessmentCompleted);
