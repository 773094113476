import { useEffect, useMemo, useState } from 'react';
import { getResumeContent } from '../../services/resume-builder.service';

const useResumePreviewHook = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [resumeContent, setResumeContent] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchResumeContent = async () => {
      try {
        const response = await getResumeContent();
        setResumeContent(response);
        setPageNumber(1);
      } catch (error) {
        console.error('Error fetching resume content:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResumeContent();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const onNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const onPreviousPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const resumeData = useMemo(() => ({ data: resumeContent }), [resumeContent]);

  const goBack = () => {
    props.history.push('/resume-builder');
  };

  return {
    isLoading,
    error,
    resumeData,
    pageNumber,
    totalPages,
    goBack,
    onDocumentLoadSuccess,
    onNextPage,
    onPreviousPage,
  };
};

export default useResumePreviewHook;
