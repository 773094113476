import * as store from './';

const setPDQScores = (data) => {
  return {
    type: store.SET_PDQ_SCORES,
    data,
  };
};

const setPDQAssessmentStatus = (status) => {
  return {
    type: store.SET_PDQ_ASSESSMENT_STATUS,
    status,
  };
};

export { setPDQScores, setPDQAssessmentStatus };
