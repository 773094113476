import moment from "moment";

export const getScore = ({ possibleScore, achievedScore, grade }) => {
  if (possibleScore) {
    return `Score ${(achievedScore / possibleScore) * 100}%`;
  }

  if (achievedScore) {
    return `Score ${achievedScore}`;
  }

  if (grade) {
    return `Grade ${grade}`;
  }

  return "N/A";
};

export const getLastAcessedDate = obj => {
  if (obj.completed) {
    return obj.completedDate ? moment(obj.completedDate).format("LL") : "";
  }
  return obj.lastAccessed ? moment(obj.lastAccessed).format("LL") : "";
};
