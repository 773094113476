import React from 'react';
import { withStyles, Box, Button, Typography, Divider, Grid } from '@material-ui/core';

import styles from './similar-jobs.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { MissingData } from '..';

const SimilarJobs = (props) => {
  const { classes, data, onItemClick } = props;
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h2" id={`similar-jobs-title`} className={classes.capitalize}>
          <FormattedMessage id="similarJobs" />
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      {data ? (
        <Grid container spacing={4} sm={12} md={12} className={classes.jobsContainer}>
          {data.careers &&
            data.careers.career &&
            data.careers.career.length > 0 &&
            data.careers.career.map((job) => (
              <Grid key={job.code} item sm={4} md={4} spacing={4}>
                <Button
                  onClick={(e) => {
                    onItemClick(e, job.code);
                  }}
                  aria-label={intl.formatMessage({ id: 'viewCareerProfile' })}
                  role="link"
                >
                  <Typography color="primary" className={classes.jobTitle}>
                    {job.title}
                  </Typography>
                </Button>
              </Grid>
            ))}
        </Grid>
      ) : (
        <MissingData />
      )}
    </Box>
  );
};

export default withStyles(styles)(SimilarJobs);
