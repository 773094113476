import { Http } from '../../../core';
import { Constant, Utility } from '../../../shared/services';

export class CareersService {
  ROUTES = Constant.ROUTES;
  _utilityService = new Utility();

  /**
   * @name getCareerDirectory
   * @param {number} from
   * @param {number} size
   * @param {string} keyword
   * @param {string} careerClusterCode
   * @param {string} alphabeticalGroup
   * @desc Fetches list of careers based upon pagination
   * params.
   * @return {Promise}
   */
  getCareerDirectory = (from, size, keyword, careerClusterCode, alphabeticalGroup) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.post(
        `/services/career-directory/v2/${this.ROUTES.CAREERS}/query?noLoader=true`,
        {
          from,
          size,
          keyword,
          careerClusterCode,
          alphabeticalGroup,
        },
        {
          pendingApiKey: this._utilityService.getAPIKey(alphabeticalGroup, this.ROUTES.CAREERS),
        }
      )
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getMatchingCareers
   * @param {number} from
   * @param {number} size
   * @param {string} keyword
   * @param {number} jobZone
   * @param {string} careerClusterCode
   * @param {string} answers
   * @desc Fetches list of matching careers based upon pagination
   * params.
   * @return {Promise}
   */
  getMatchingCareers = (from, size, keyword, jobZone, careerClusterCode, answers) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.post(
        `/services/interest-profiler/v2/matching-careers/query?noLoader=true`,
        {
          from,
          size,
          keyword,
          jobZone: parseInt(jobZone),
          careerClusterCode,
          answers,
        },
        {
          pendingApiKey: this._utilityService.getAPIKey(jobZone, this.ROUTES.CAREERS),
        }
      )
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getCareerDetails
   * @param {string} id
   * @desc Fetches career details (abilities, knowledge, personality, sills etc)
   * using the career id.
   * @return {Promise}
   */
  getCareerDetails = (id) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/career-directory/v1/${this.ROUTES.CAREERS}/${id}/`)
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name addBookmark
   * @param {string} id
   * @desc bookmark career using the career id.
   * @return {Promise}
   */
  addBookmark = (id) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.put(
        `/services/career-directory/v1/${this.ROUTES.BOOKMARKS}?noLoader=true`,
        {
          careerId: id,
        },
        {
          pendingApiKey: this._utilityService.getAPIKey(id),
        }
      )
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name removeBookmark
   * @param {string} id
   * @desc remove bookmarked career using the career id.
   * @return {Promise}
   */
  removeBookmark = (id) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.delete(`/services/career-directory/v1/${this.ROUTES.BOOKMARKS}/${id}/?noLoader=true`, {
        pendingApiKey: this._utilityService.getAPIKey(id),
      })
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getCareerDirectory
   * @param {number} from
   * @param {number} size
   * @param {string} keyword
   * @param {number} jobZone
   * @desc Fetches list of careers based upon paginated
   * params.
   * @return {Promise}
   */
  getBookmarkedCareers = (from, size) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.post(`/services/career-directory/v1/${this.ROUTES.CAREERS}/bookmarks?noLoader=true`, {
        from,
        size,
      })
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getCareerClusters
   * @desc Fetches list of career clusters.
   * @return {Promise}
   */
  getCareerClusters = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/career-directory/v1/${this.ROUTES.CAREERS}/career-clusters`)
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name getAlphabeticalGroups
   * @desc Fetches list of career (alphabetical) groups.
   * @return {Promise}
   */
  getAlphabeticalGroups = () => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/career-directory/v1/${this.ROUTES.CAREERS}/alphabetical-groups`)
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
