export default theme => ({
    root: {},
    backBtnContainer: {
        marginBottom: 25,
        display: "flex",
        justifyContent: "flex-end"
    },
    backBtn: {
        textDecoration: "underline"
    },
    attributionContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 25
    },
    skillsWrapper: {
        [theme.breakpoints.up("lg")]: {
            paddingLeft: theme.spacing(3)
        },
        [theme.breakpoints.down("md")]: {
            paddingBottom: theme.spacing(3)
        }
    },
    similarJobsWrapper: {
        [theme.breakpoints.up("lg")]: {
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down("md")]: {
            paddingBottom: theme.spacing(2)
        }
    }
});
  