import React from 'react';
import { Box, CardMedia, Typography, withStyles } from '@material-ui/core';
import styles from './explore-header.styles';
import { FormattedMessage } from 'react-intl';

/**
 * Component to render the Explore page header.
 *
 * @param {object} classes - The classes that will be injected by withStyles.
 * @returns
 */
const ExploreHeader = ({ classes }) => (
  <Box className={classes.root}>
    <Box className={classes.header}>
      <Box className={classes.textArea}>
        <Typography id="explore-header-title" variant="h2">
          <FormattedMessage id="explore.header.title" />
        </Typography>
        <Typography id="explore-header-description" variant="body1">
          <FormattedMessage
            id="explore.header.desc"
            values={{
              span: (...chunks) => <span>{chunks}</span>,
              br: <br />,
            }}
          />
        </Typography>
      </Box>
      <CardMedia
        component="img"
        className={classes.image}
        alt=""
        src="https://content.connectedcorrections.com/assets/img/Explore-Page.png"
      />
    </Box>
  </Box>
);

export default withStyles(styles)(ExploreHeader);
