import React from 'react';

const SpaIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 41.493 32.272">
      <path
        id="spa-solid"
        d="M14.969,47.87A21.006,21.006,0,0,0,2.161,43.526H0a20.746,20.746,0,1,0,41.493,0H39.332A21.061,21.061,0,0,0,20.746,54.677a20.184,20.184,0,0,0-1.376-2.233,21.19,21.19,0,0,0-4.394-4.574ZM20.746,32a25.9,25.9,0,0,0-6.987,12.3,23.434,23.434,0,0,1,6.987,6.123A23.434,23.434,0,0,1,27.734,44.3,25.9,25.9,0,0,0,20.746,32Z"
        transform="translate(0 -32)"
        fill={fill}
      />
    </svg>
  );
};

SpaIcon.defaultProps = {
  width: '41.493',
  height: '32.272',
  fill: 'inherit',
};

export default SpaIcon;
