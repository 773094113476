import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Snackbar, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './single-launch-modules.style';
import { LearningModule } from '..';
import { RedirectIcon } from '../../../../shared/icons';
import { Constant } from '../../../../shared/services';
import { ProgramConstants, isEnrolled } from '../../services/programs.service';
import useLearningModuleHook from '../learning-module/useLearningModuleHook';
import MuiAlert from '@material-ui/lab/Alert';

const SingleLaunchModules = (props) => {
  const { classes, modules, rootModule, enrollmentStatus, templateType } = props;
  const { launchModule } = useLearningModuleHook();
  const [listEnd, setListEnd] = useState(false);
  const [overflowing, setOverflowing] = useState(false);
  const listRef = useRef();
  const [clickedList, setClickedList] = useState(false);
  const intl = useIntl();

  const handleListClicked = () => {
    setClickedList(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setListEnd(true);
    } else {
      setListEnd(false);
    }
  };
  useEffect(() => {
    const { scrollHeight, clientHeight } = listRef.current;
    const isOverflowing = scrollHeight > clientHeight;
    if (isOverflowing) {
      setOverflowing(true);
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.overviewHeader}>
        <Box className={classes.headingWrapper}>
          <Typography id="single-launch-program-overview-title" color="secondary" variant="h3" className={classes.boldText}>
            <FormattedMessage id="programOverview" />
          </Typography>
          {isEnrolled(enrollmentStatus) &&
            (clickedList ? (
              <MuiAlert severity="info" elevation={6} variant="outlined">
                <Typography color="secondary" className={classes.description}>
                  <FormattedMessage id="programClickedWarningMessage" />
                </Typography>
              </MuiAlert>
            ) : (
              <Typography color="secondary" className={classes.description}>
                <FormattedMessage id="programEnrolledOverviewMessage" />
              </Typography>
            ))}
        </Box>
        {isEnrolled(enrollmentStatus) && (
          <Box>
            <Button
              id="single-launch-program-launch-btn"
              className={classes.launchBtn}
              color="primary"
              onClick={() => {
                launchModule(rootModule?.id, rootModule?.name);
              }}
              tracking-type={Constant.TRACKING_TYPES.COURSE}
              tracking-id={`launch-course-`}
              endIcon={<RedirectIcon size={20} fill="white" />}
              variant="contained"
              aria-label={intl.formatMessage({ id: 'launchProgram' })}
            >
              <FormattedMessage id="launchProgram" />
            </Button>
          </Box>
        )}
      </Box>

      <Divider />

      <Grid
        className={
          isEnrolled(enrollmentStatus)
            ? classes.modulesContainerEnrolled
            : listEnd || !overflowing
            ? classes.modulesContainerEnd
            : classes.modulesContainer
        }
        ref={listRef}
        onScroll={handleScroll}
      >
        {(modules ?? []).map((module, index) => (
          <LearningModule
            key={module.id}
            id={module.id}
            index={index}
            name={module.name}
            progress={module.progress}
            module={templateType === ProgramConstants.TEMPLATE_TYPES.TEMPLATE_SINGLE_COURSE ? 'unit' : 'course'}
            isEnrolled={isEnrolled(enrollmentStatus)}
            showLaunchButton={false}
            handleListClicked={handleListClicked}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(SingleLaunchModules);
