import { Box, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './top-banner.style';

const TopBanner = (props) => {
  const { classes } = props;
  return <Box className={classes.root}>{props.children}</Box>;
};

export default withStyles(styles)(TopBanner);
