import { useDocumentTitleHook } from '../../hooks';

// TODO: Remove this component when all class components are converted to functional components.

/**
 * @name DocumentTitle
 * @desc A functional component to set the document title for class components.
 * @param {String} title - should be a key from the locale file. e.g. 'page.title.home'
 * @returns {null}
 */
const DocumentTitle = ({ title }) => {
  useDocumentTitleHook(title);
  return null;
};

export default DocumentTitle;
