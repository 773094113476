import React from "react";
import { 
    withStyles, 
    Box,
    Typography
} from "@material-ui/core";

import styles from "./missing-data.style";
import { FormattedMessage } from "react-intl";
import { MissingDataIcon } from "../../../../shared";

const MissingData = props => {
  const { classes } = props;

  return (
		<Box className={classes.root}>
			<Box className={classes.content}>
				<Box className={classes.iconContainer}>
					<MissingDataIcon size={75} />
				</Box>
				<Typography color="secondary">
					<FormattedMessage id="career.profile.missing.data.msg" />
				</Typography>
			</Box>
		</Box>
  );
};

export default withStyles(styles)(MissingData);
