import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, List, ListItem, Typography, withStyles } from '@material-ui/core';
import styles from './career-outline.style';
import { FormattedMessage } from 'react-intl';
import { PillButton } from '../../../../../frontend-commons';
import { Constant, Utility } from '../../../../../shared/services';
import { BookmarkIcon, BookmarkRibbonIcon } from '../../../../../shared/icons';
import { LoaderHOC } from '../../../../../shared/components';
import { CareerFit, CareerVideo } from '..';

const CareerOutline = ({
  classes,
  id,
  title,
  zone,
  description,
  isBookmarked,
  salary,
  qualification,
  tasks,
  alsoCalled,
  careerFit,
  onPlayVideo,
  onAddBookmark,
  onRemoveBookmark,
}) => (
  <Grid item sm={12} md={8} className={classes.root}>
    <Box className={classes.container}>
      <Box className={classes.bgOverlay} />

      {isBookmarked && (
        <Box className={classes.bookmarkBanner}>
          <BookmarkRibbonIcon width={50} height={50} />
        </Box>
      )}

      <Box className={classes.details}>
        {/* TITLE AREA */}
        <Box className={classes.titleContainer}>
          <Typography id="career-outline-title" variant="h3">
            {title}
          </Typography>
          <Box className={[classes.zoneBadge, classes[`zoneColor${zone}`]].join(' ')}>
            <Typography id="career-outline-zone" className={classes.careerZone}>
              <FormattedMessage id="zone" /> {zone}
            </Typography>
          </Box>
        </Box>

        {/* DESCRIPTION AREA */}
        <Box className={classes.descriptionContainer}>
          <Typography id="career-outline-description" variant="body1" className={classes.description}>
            {description}
          </Typography>
        </Box>

        {/* HANDLE BOOKMARK */}
        <Box className={classes.bookmarkContainer}>
          <PillButton
            id="career-outline-bookmark-button"
            size="large"
            variant="contained"
            className={classes.bookmarkButton}
            startIcon={
              <LoaderHOC apiKey={id}>
                <BookmarkIcon size={16} />
              </LoaderHOC>
            }
            onClick={(e) => {
              e.stopPropagation();
              isBookmarked ? onRemoveBookmark(id) : onAddBookmark(id);
            }}
            tracking-type={Constant.TRACKING_TYPES.CAREER_BOOKMARK}
            tracking-id={isBookmarked ? `remove-bookmark-${id}` : `bookmark-career-${id}`}
          >
            {isBookmarked ? <FormattedMessage id="career.bookmark.remove" /> : <FormattedMessage id="career.bookmark.add" />}
          </PillButton>
        </Box>

        {/* SALARY INFO */}
        <Box className={classes.salaryInfo}>
          {!Utility.isNullOrEmpty(salary) && (
            <Box className={classes.salarySection}>
              <Typography variant="h6" className={classes.label}>
                <FormattedMessage id="salary" />
              </Typography>
              <Typography id="career-outline-salary" variant="h4" className={classes.salaryValue}>
                ${salary}
              </Typography>
            </Box>
          )}
          {!Utility.isNullOrEmpty(qualification) && (
            <Box className={classes.salarySection}>
              <Typography variant="h6" className={classes.label}>
                <FormattedMessage id="requiredDegree" />
              </Typography>
              <Typography
                id="career-outline-qualification"
                variant="h4"
                className={[classes.salaryValue, classes.capitalize].join(' ')}
              >
                {qualification}
              </Typography>
            </Box>
          )}
          {!Utility.isNullOrEmpty(careerFit) && (
            <Box className={classes.salarySection}>
              <Typography variant="h6" className={classes.label}>
                <FormattedMessage id="personalityMatch" />
              </Typography>
              <CareerFit careerFit={careerFit} />
            </Box>
          )}
        </Box>

        {/* JOB RESPONSIBILITIES */}
        {!Utility.isNullOrEmpty(tasks) && (
          <Box className={classes.tasksContainer}>
            <Typography variant="h6" className={classes.label}>
              <FormattedMessage id="topJobResponsibilities" />
            </Typography>
            <List id="career-outline-responsibilities" dense className={classes.list}>
              {tasks.map((task) => (
                <ListItem key={task.id} dense className={classes.listItem}>
                  {task.name}
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* ALSO CALLED */}
        {!Utility.isNullOrEmpty(alsoCalled) && (
          <Box className={classes.alsoCalledContainer}>
            <Typography variant="h6" className={classes.label}>
              <FormattedMessage id="alsoCalled" />
            </Typography>
            <List id="career-outline-also-called" dense className={classes.list}>
              {alsoCalled.map((item) => (
                <ListItem key={item.id} dense className={classes.listItem}>
                  {item.name}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
      <CareerVideo careerId={id} handleClick={onPlayVideo} />
    </Box>
  </Grid>
);

CareerOutline.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  zone: PropTypes.number,
  description: PropTypes.string,
  isBookmarked: PropTypes.bool,
  salary: PropTypes.string,
  qualification: PropTypes.string,
  tasks: PropTypes.array,
  alsoCalled: PropTypes.array,
  careerFit: PropTypes.string,
  onPlayVideo: PropTypes.func.isRequired,
  onAddBookmark: PropTypes.func.isRequired,
  onRemoveBookmark: PropTypes.func.isRequired,
};

export default withStyles(styles)(CareerOutline);
