import React, { useEffect, useState } from 'react';
import { withStyles, Box, Typography, Button } from '@material-ui/core';

import styles from './splash-screen.style';

const SplashScreen = (props) => {
  const { classes, onNext, onPrev, showPrev, prevText, nextText, imageUrl, title, description, page } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [imageUrl]);

  const onImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.imageContainer}>
        <img id="splash-screen-img" alt="" src={imageUrl} className={classes.image} onLoad={onImageLoad} />
      </Box>
      {onImageLoad && (
        <Box className={classes.contentContainer}>
          <Box className={classes.content}>
            <Typography id="splash-screen-title" variant="h2" className={classes.mainTitle}>
              {title}
            </Typography>

            <Typography id="splash-screen-desc" className={classes.description}>
              {description}
            </Typography>

            <Box id="splash-screen-ellipsis" className={classes.ellipsisContainer}>
              {[1, 2, 3, 4].map((item) => (
                <Box key={`ellipse-${item}`} className={item === page ? classes.filledEllipse : classes.emptyEllipse} />
              ))}
            </Box>
          </Box>
          <Box className={classes.actionContainer}>
            <Button
              id="splash-screen-prev-btn"
              variant="outlined"
              color="secondary"
              size="large"
              className={classes.prevButton}
              onClick={onPrev}
              style={{ visibility: showPrev ? '' : 'hidden' }}
              aria-label={prevText}
            >
              {prevText}
            </Button>
            <Button
              id="splash-screen-next-btn"
              variant="contained"
              color="primary"
              size="large"
              className={classes.nextButton}
              onClick={onNext}
              aria-label={nextText}
            >
              {nextText}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(SplashScreen);
