import React from 'react';

const CallIcon = (props) => {
  const { size } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g clip-path="url(#clip0_56123_19600)">
        <path d="M13.125 0C19.1297 0 24 4.87031 24 10.875C24 11.4984 23.4984 12 22.875 12C22.2516 12 21.75 11.4984 21.75 10.875C21.75 6.1125 17.8875 2.25 13.125 2.25C12.5016 2.25 12 1.74844 12 1.125C12 0.501562 12.5016 0 13.125 0ZM13.5 9C13.8978 9 14.2794 9.15804 14.5607 9.43934C14.842 9.72064 15 10.1022 15 10.5C15 10.8978 14.842 11.2794 14.5607 11.5607C14.2794 11.842 13.8978 12 13.5 12C13.1022 12 12.7206 11.842 12.4393 11.5607C12.158 11.2794 12 10.8978 12 10.5C12 10.1022 12.158 9.72064 12.4393 9.43934C12.7206 9.15804 13.1022 9 13.5 9ZM12 5.625C12 5.00156 12.5016 4.5 13.125 4.5C16.6453 4.5 19.5 7.35469 19.5 10.875C19.5 11.4984 18.9984 12 18.375 12C17.7516 12 17.25 11.4984 17.25 10.875C17.25 8.59688 15.4031 6.75 13.125 6.75C12.5016 6.75 12 6.24844 12 5.625ZM15.4219 13.4391C15.9516 12.7922 16.8422 12.5719 17.6109 12.9047L22.8609 15.1547C23.6859 15.5062 24.1453 16.3969 23.9578 17.2687L22.8328 22.5187C22.6453 23.3813 21.8813 24 21 24C20.7141 24 20.4281 23.9953 20.1469 23.9813H20.1422C19.6734 23.9625 19.2141 23.9297 18.7547 23.8781C8.2125 22.7625 0 13.8375 0 3C0 2.11406 0.61875 1.35 1.48125 1.16719L6.73125 0.0421875C7.60781 -0.145313 8.49375 0.314062 8.84531 1.13906L11.0953 6.38906C11.4234 7.15781 11.2078 8.04844 10.5609 8.57812L8.65781 10.1344C9.90938 12.2906 11.7094 14.0906 13.8656 15.3422L15.4219 13.4391ZM21.6937 17.1L16.9875 15.0844L15.6094 16.7625C14.9109 17.6156 13.6969 17.8359 12.7406 17.2828C10.2422 15.8344 8.16094 13.7531 6.7125 11.2547C6.15937 10.2984 6.37969 9.08437 7.23281 8.38594L8.91562 7.00781L6.9 2.30156L2.25469 3.30469C2.41406 13.4156 10.5844 21.5859 20.6953 21.75L21.6937 17.1Z" />
      </g>
      <defs>
        <clipPath id="clip0_56123_19600">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
CallIcon.defaultProps = {
  size: 24,
};
export default CallIcon;
