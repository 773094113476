import React from 'react';
import { Dialog, DialogContent, Box, withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import styles from './audio.modal.style';
import { useIntl } from 'react-intl';

const AudioModal = (props) => {
  const { classes, isOpen, handleClose, title, thumbnailUrl, audioUrl } = props;
  const intl = useIntl();

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <IconButton className={classes.closeButton} onClick={handleClose} aria-label={intl.formatMessage({ id: 'close' })}>
        <CloseIcon fontSize="large" />
      </IconButton>
      <DialogContent className={classes.content}>
        <img id="apds-audio-player-thumbnail" alt={title} src={thumbnailUrl} className={classes.thumbnail} />
        <Box className={classes.playerContainer}>
          <AudioPlayer autoPlay src={audioUrl} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AudioModal);
