import { Http } from "../../../../core";

export class FormService {
  /**
   * @name fetchUserForms
   * @return {Promise}
   */
  fetchUserForms = isRequiredFormExist => {
    let url = "/form/user";
    if (isRequiredFormExist) {
      url = url + "?required_forms=true";
    }
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(url).then(_successLog => {
        const { data } = _successLog;

        if (!data) {
          resolve({});
          return;
        }

        resolve(data);
      });
    });
  };
}
