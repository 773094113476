import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import styles from './explore-filter.styles';
import { Constant } from '../../../../../shared/services';
import { useIntl } from 'react-intl';

/**
 * Component to render the Explore page filters.
 *
 * @param {object} props - The props of the component.
 * @returns {React.Component} The ExploreFilter component.
 */
const ExploreFilter = ({ classes, categories, isActiveCategory, onCategoryClick }) => {
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      {categories.map((category) => {
        category = { ...Constant.SERVICE_CATEGORY[category.id], ...category };
        return (
          <ListItem
            key={category.id}
            id={`explore-filter-${category.id}`}
            button
            onClick={() => {
              onCategoryClick(category.id, true);
            }}
            className={[classes.filterItem, isActiveCategory(category.id) ? classes.activeFilter : ''].join(' ')}
            tracking-type={Constant.TRACKING_TYPES.SERVICE}
            tracking-id={`scroll-to-service-category-${category.id}`}
            aria-label={intl.formatMessage({ id: 'scrollToCategoryServices' }, { category: category.name })}
          >
            <ListItemIcon className={classes.icon}>
              {category.iconWithProps({ width: 16, height: 16, size: 16 })}
            </ListItemIcon>
            <ListItemText primary={<Typography className={classes.filterItemText}>{category.name}</Typography>} />
          </ListItem>
        );
      })}
    </Box>
  );
};

ExploreFilter.propTypes = {
  /**
   * The classes that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The categories to display
   */
  categories: PropTypes.array.isRequired,
  /**
   * The function to check if the category is active
   */
  isActiveCategory: PropTypes.func.isRequired,
  /**
   * The function to call when a category is clicked
   */
  onCategoryClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExploreFilter);
