import React from 'react';

const RedirectIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24.005">
      <g id="Group_2916" data-name="Group 2916" transform="translate(-688.613 -929.317)">
        <path
          id="arrow-up-right-from-square-regular"
          d="M14.253,1.125A1.123,1.123,0,0,0,15.378,2.25h4.787L9.705,12.706a1.124,1.124,0,1,0,1.589,1.589L21.75,3.84V8.627a1.125,1.125,0,1,0,2.25,0v-7.5A1.123,1.123,0,0,0,22.875,0h-7.5A1.123,1.123,0,0,0,14.253,1.125ZM3.376,1.5A3.374,3.374,0,0,0,0,4.876V20.629A3.374,3.374,0,0,0,3.376,24H19.129A3.374,3.374,0,0,0,22.5,20.629v-6a1.125,1.125,0,1,0-2.25,0v6a1.123,1.123,0,0,1-1.125,1.125H3.376A1.123,1.123,0,0,1,2.25,20.629V4.876A1.123,1.123,0,0,1,3.376,3.751h6a1.125,1.125,0,0,0,0-2.25Z"
          transform="translate(688.613 929.317)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

RedirectIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default RedirectIcon;
