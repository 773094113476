export default (theme) => ({
  content: {
    width: '100%',
  },
  title: {
    ...theme.typography.t20,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: 30,
  },
  helperText: {
    fontSize: '1rem',
    marginLeft: 0,
  },
  gutterTop: {
    marginTop: 30,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38) !important',
  },
  scaleContainer: {
    display: 'flex',
    marginTop: 10,
  },
  scaleOption: {
    borderColor: theme.palette.colors.borderGrey.dark,
    border: '2px solid',
    ...theme.typography.t20,
    fontWeight: 700,
    minWidth: 45,
    minHeight: 45,
    width: 45,
    height: 45,
    backgroundColor: theme.palette.colors.inputBackground,
  },
  activeScaleOption: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}40`,
  },
});
