import React from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Box, Divider, Tooltip, Grid, IconButton, Typography, withStyles, Badge } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import useStyles from './side-menu.style';
import useSideMenuHook from './useSideMenuHook';
import {
  CallIconV2,
  ChatIconV2,
  DashboardIcon,
  EntertainmentIcon,
  ExploreIcon,
  FileIcon,
  ListIcon,
  MessageIcon,
  SkillsIcon,
} from '../../../../shared';
import { Utility, Constant } from '../../../../shared/services';
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.primary.lipstick,
    color: theme.palette.primary.white,
  },
}))(Badge);

function SideBar(props) {
  const { announcementCount, drawer, features, fnfUnreadCount, unreadMessagesCount, userDetail } = props;
  const { getUsername, getActiveLinkItem, handleClick } = useSideMenuHook(props);
  const utilityService = new Utility();
  const classes = useStyles();
  const intl = useIntl();

  const messagingStatus = utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.MESSAGING_PERSON);
  const messagingFNFStatus = utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.FNF_MESSAGING);

  const DRAWER_ITEMS = [
    {
      text: <FormattedMessage id="home" />,
      label: intl.formatMessage({ id: 'goToHomepage' }),
      icon: <DashboardIcon />,
      path: '/home',
      isVisible: true,
      status: true,
      active: function () {
        return [
          '/home',
          '/bookmarks',
          '/transcript',
          '/interest-profiler',
          '/pdq-assessment/intro',
          '/pdq-assessment/results',
          '/career-compass',
        ].some((item) => getActiveLinkItem(item));
      },
      cyName: 'menu-btn-home',
      trackingType: Constant.TRACKING_TYPES.HOMEPAGE,
      trackingId: 'view-homepage',
    },
    {
      text: <FormattedMessage id="programCatalog" />,
      label: intl.formatMessage({ id: 'goToProgramCatalog' }),
      icon: <ListIcon />,
      path: '/programs',
      isVisible: true,
      status: true,
      active: function () {
        return getActiveLinkItem('/programs') || getActiveLinkItem('/programs/:id/details');
      },
      cyName: 'menu-btn-pathways',
      trackingType: Constant.TRACKING_TYPES.PROGRAM,
      trackingId: 'view-program-catalog',
    },
    {
      text: <FormattedMessage id="careerPaths" />,
      label: intl.formatMessage({ id: 'goToCareerPaths' }),
      icon: <MapOutlinedIcon />,
      path: '/career-paths',
      isVisible: true,
      status: utilityService.checkStatusExists(props.features, Constant.FEATURES_ENUM.CAREER_PATH),
      active: function () {
        return getActiveLinkItem('/career-paths');
      },
      cyName: 'menu-btn-career-paths',
      trackingType: Constant.TRACKING_TYPES.CAREER_PATHS,
      trackingId: 'view-career-paths',
    },
    {
      text: <FormattedMessage id="careerDirectory" />,
      label: intl.formatMessage({ id: 'goToCareerDirectory' }),
      icon: <SkillsIcon />,
      path: '/careers',
      isVisible: true,
      status: true,
      active: function () {
        return getActiveLinkItem('/careers') || getActiveLinkItem('/careers/:id/details');
      },
      cyName: 'menu-btn-pathways',
      trackingType: Constant.TRACKING_TYPES.CAREER_DIRECTORY,
      trackingId: 'view-careers',
    },
    {
      text: <FormattedMessage id="explore" />,
      label: intl.formatMessage({ id: 'goToExplore' }),
      icon: <ExploreIcon />,
      path: '/explore',
      isVisible: true,
      status: utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.EXPLORE),
      active: function () {
        return getActiveLinkItem('/explore');
      },
      cyName: 'menu-btn-explore',
      trackingType: Constant.TRACKING_TYPES.SERVICE,
      trackingId: 'view-services',
    },
    {
      text: <FormattedMessage id="voiceCall" />,
      label: intl.formatMessage({ id: 'goToVoiceCalls' }),
      icon: <CallIconV2 />,
      path: '/voice-call',
      isVisible: true,
      status: utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.VOICE_CALLS),
      active: function () {
        return getActiveLinkItem('/voice-call');
      },
      cyName: 'menu-btn-voice-call',
      trackingType: Constant.TRACKING_TYPES.SERVICE,
      trackingId: 'view-voice-calls',
    },
    {
      text: <FormattedMessage id="FnFMessaging" />,
      label: intl.formatMessage({ id: 'goToFnFMessaging' }),
      icon: messagingFNFStatus ? (
        <StyledBadge badgeContent={fnfUnreadCount || 0}>
          <MessageIcon />
        </StyledBadge>
      ) : (
        <MessageIcon />
      ),
      path: '/friends-family',
      isVisible: utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.FNF_MESSAGING),
      status: true,
      active: function () {
        return getActiveLinkItem('/friends-family');
      },
      cyName: 'menu-btn-fnf',
      trackingType: Constant.TRACKING_TYPES.FRIENDS_AND_FAMILY,
      trackingId: 'view-fnf-messaging',
    },
    {
      text: <FormattedMessage id="messaging" />,
      label: intl.formatMessage({ id: 'goToStaffMessaging' }),
      icon: messagingStatus ? (
        <StyledBadge
          badgeContent={
            (unreadMessagesCount > 0 ? unreadMessagesCount : 0) + (announcementCount > 0 ? announcementCount : 0)
          }
        >
          <ChatIconV2 />
        </StyledBadge>
      ) : (
        <ChatIconV2 />
      ),
      path: '/messaging',
      isVisible:
        utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.MESSAGING) &&
        utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.MESSAGING_PERSON),
      status: true,
      active: function () {
        return getActiveLinkItem('/messaging');
      },
      cyName: 'menu-btn-fnf',
      trackingType: Constant.TRACKING_TYPES.STAFF_MESSAGING,
      trackingId: 'view-staff-messaging',
    },
    {
      text: <FormattedMessage id="entertainment" />,
      label: intl.formatMessage({ id: 'goToEntertainment' }),
      icon: <EntertainmentIcon />,
      path: '/entertain',
      isVisible: true,
      status: utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.PLAY),
      active: function () {
        return getActiveLinkItem('/entertain');
      },
      cyName: 'menu-btn-play',
      trackingType: Constant.TRACKING_TYPES.ENTERTAINMENT,
      trackingId: 'view-entertainment-center',
    },
    {
      text: <FormattedMessage id="forms" />,
      label: intl.formatMessage({ id: 'goToForms' }),
      icon: <FileIcon />,
      path: '/forms',
      isVisible: true,
      status: utilityService.checkStatusExists(features, Constant.FEATURES_ENUM.FORMS),
      active: function () {
        return getActiveLinkItem('/forms');
      },
      cyName: 'menu-btn-forms',
      trackingType: Constant.TRACKING_TYPES.FORM,
      trackingId: 'view-forms',
    },
  ];

  const drawerView = (openState, setOpen) => (
    <Box display="flex" height="100%" flexDirection="column">
      <Box className={classes.drawerWrapperV2}>
        <Box className={classes.topArea}>
          <ListItem
            id="my-profile"
            className={classes.textWithIconContainer}
            onClick={(e) => handleClick(e, '/my-profile')}
            button
            aria-label={intl.formatMessage({ id: 'goToMyProfile' })}
            role="link"
          >
            <ListItemIcon>
              <AccountCircleIcon
                fontSize="large"
                className={[
                  classes.gutterRight,
                  getActiveLinkItem('/my-profile') ? classes.myProfileIconActive : classes.myProfileIcon,
                ].join(' ')}
              />
            </ListItemIcon>
            <Box>
              <Typography className={classes.userName}> {getUsername()} </Typography>
            </Box>
          </ListItem>
        </Box>

        <Divider className={classes.divider} />

        <Grid container item className={classes.drawerContent}>
          <List className={classes.drawerContentList} id="drawer-list">
            {DRAWER_ITEMS.filter((item) => item.status).map(
              (item) =>
                item.isVisible && (
                  <ListItem
                    onClick={(e) => handleClick(e, item.path)}
                    data-cy-name={item.cyName}
                    key={utilityService.generateUniqueKey()}
                    disabled={!item.status}
                    button
                    className={[classes.drawerContentListItem, item.active() ? classes.drawerContentActive : null].join(' ')}
                    tracking-type={item.trackingType}
                    tracking-id={item.trackingId}
                    aria-label={item.label}
                    role="link"
                  >
                    <ListItemIcon className={classes.drawerContentIcon}>
                      <Tooltip title={item.text}>{item.icon}</Tooltip>
                    </ListItemIcon>
                    <Typography className={classes.drawerContentText}>{item.text}</Typography>
                  </ListItem>
                )
            )}
          </List>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <div className={classes.root}>
      {utilityService.isModuleActivated('home') ? (
        <Box className={classes.bgOverlayHome} component="div" />
      ) : (
        utilityService.isModuleActivated('careers') && <Box className={classes.bgOverlayCareers} component="div" />
      )}

      <Box
        className={[
          classes.expandCollapseBtnContainer,
          drawer.open ? classes.collapseIconPosition : classes.expandIconPosition,
        ].join(' ')}
      >
        <IconButton
          id="expand-collapse-icon-btn"
          onClick={() => drawer.handleDrawerToggle()}
          className={classes.expandCollapseBtn}
          aria-label={intl.formatMessage({ id: drawer.open ? 'collapseSidebar' : 'expandSidebar' })}
        >
          {drawer.open ? (
            <ChevronLeftIcon className={classes.menuMainIcon} />
          ) : (
            <ChevronRightIcon className={classes.menuMainIcon} />
          )}
        </IconButton>
      </Box>

      <Box className={[drawer.open ? classes.drawer : classes.drawerClose].join(' ')} aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          classes={{
            paper: [classes.drawerPaper, drawer.open ? classes.drawerOpen : classes.drawerClose].join(' '),
          }}
          open={drawer.open}
          onClose={drawer.handleDrawerToggle}
        >
          {drawerView(drawer.open, drawer.handleDrawerToggle)}
        </Drawer>
      </Box>
    </div>
  );
}

const mapStateToProps = ({ app }) => {
  return {
    unreadMessagesCount: app.unreadMessagesCount,
    announcementCount: app.announcementCount,
    fnfUnreadCount: app.friendsAndFamily.unreadData.totalUnreadCount,
    userDetail: app.userDetail,
  };
};

export default connect(mapStateToProps)(SideBar);
