import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './voice-id-submitted.style';
import { Button } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
const VoiceIdSubmitted = ({ classes, TAB_VALUES, handletabActive }) => {
  return (
    <>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <Box className={classes.imageSection}>
            <img src="assets/img/success_illustration.png" alt="Voice ID Submitted" loading="eager" />
          </Box>

          <Box className={classes.textSection}>
            <Typography variant="h2" className={classes.heading}>
              <FormattedMessage id="voiceIdSubmitted.heading" />
            </Typography>
            <Typography variant="body2" className={classes.description}>
              <FormattedMessage id="voiceIdSubmitted.description" />
            </Typography>
            <Box className={classes.buttonSection}>
              <Button
                data-testid="make-a-call"
                aria-label="Make a call"
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                onClick={(event) => handletabActive(event, TAB_VALUES.Contacts)}
              >
                <Typography className={classes.btnText}>
                  <FormattedMessage id="voiceIdSubmitted.makeACall" />
                </Typography>
              </Button>
              <Button
                data-testid="add-a-contact"
                aria-label="Add a contact"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                onClick={(event) => handletabActive(event, TAB_VALUES.Connections)}
              >
                <Typography className={classes.btnText}>
                  <FormattedMessage id="voiceIdSubmitted.addAContact" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(VoiceIdSubmitted);
