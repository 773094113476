import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Constant } from '../../../../../shared/services';
import { TERMS_OF_SERVICE_TRANSLATIONS } from './terms-of-service.helper';
import styles from './terms-of-service.styles';
import { CheckCircleIcon, CloseIcon } from '@orijinworks/frontend-commons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import useTermsOfServiceHook from './useTermsOfServiceHook';
import PrivacyPolicy from '../privacy-policy/privacy-policy';
import { useDocumentTitleHook } from '../../../../shared';

/**
 * Component to display the terms of service modal.
 *
 * @component
 * @param {Object} props - the props object
 */
const TermsOfService = (props) => {
  const { classes, selectedLanguage } = props;
  const {
    open,
    openPrivacyPolicy,
    accepted,
    handlePrivacyPolicyModalClose,
    handleLanguageChange,
    isLanguageSelected,
    handleChange,
    handleClose,
    handleComplete,
  } = useTermsOfServiceHook(props);
  useDocumentTitleHook('page.title.terms-and-services');

  const TRANSLATIONS = TERMS_OF_SERVICE_TRANSLATIONS[selectedLanguage];

  return (
    <>
      <PrivacyPolicy open={openPrivacyPolicy} selectedLanguage={selectedLanguage} onClose={handlePrivacyPolicyModalClose} />

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="Terms of Service"
        classes={{ paper: classes.root }}
      >
        {/* BEGIN: HEADER */}
        <Box className={classes.topBarContainer}>
          <Box className={classes.topBarWrapper}>
            <Box className={classes.logoContainer}>
              <img
                id="terms-of-service-logo"
                className={classes.logo}
                src={`${Constant.CDN_BASE_URL}/assets/logo/Orijin-former-whitelogo.png`}
                alt="Orijin"
              />
            </Box>
            <Box width="100%">
              <Typography
                id="terms-of-service-modal-title"
                variant="h1"
                className={classes.modalTitle}
                dangerouslySetInnerHTML={{ __html: TRANSLATIONS.modalTitle() }}
              />
              <Typography
                id="terms-of-service-effective-date"
                className={classes.effectiveDate}
                dangerouslySetInnerHTML={{ __html: TRANSLATIONS.effectiveDate() }}
              />
            </Box>
          </Box>
          <IconButton id="terms-of-service-close-icon" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        {/* END: HEADER */}

        {/* BEGIN: CONTENT */}
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <List className={classes.languageList}>
              <ListItem
                id="terms-of-service-select-english"
                button
                className={[
                  classes.languageItem,
                  isLanguageSelected(Constant.LANGUAGES.ENGLISH.code) ? classes.languageActive : '',
                ].join(' ')}
                onClick={() => {
                  handleLanguageChange(Constant.LANGUAGES.ENGLISH.code);
                }}
              >
                {Constant.LANGUAGES.ENGLISH.name}
              </ListItem>
              <ListItem
                id="terms-of-service-select-spanish"
                button
                className={[
                  classes.languageItem,
                  isLanguageSelected(Constant.LANGUAGES.SPANISH.code) ? classes.languageActive : '',
                ].join(' ')}
                onClick={() => {
                  handleLanguageChange(Constant.LANGUAGES.SPANISH.code);
                }}
              >
                {Constant.LANGUAGES.SPANISH.name}
              </ListItem>
            </List>
          </Box>
          <Typography
            id="terms-of-service-title"
            variant="h5"
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: TRANSLATIONS.title() }}
          />
          <Box
            id="terms-of-service-content"
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: TRANSLATIONS.content() }}
          />
        </DialogContent>
        {/* END: CONTENT */}

        {/* BEGIN: FOOTER */}
        <Box className={classes.footer}>
          <Button
            id="terms-of-service-continue-button"
            aria-label="Continue"
            className={[classes.submitButton, accepted ? classes.submitButtonEnabled : ''].join(' ')}
            onClick={handleComplete}
            disabled={!accepted}
          >
            <FormattedMessage id="continue" />
          </Button>
          <FormControlLabel
            classes={{ label: classes.checkboxLabel }}
            control={
              <Checkbox
                id="terms-of-service-checkbox"
                checked={accepted}
                onChange={handleChange}
                name="terms-of-service-checkbox"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon className={classes.checkedIcon} />}
              />
            }
            label={<FormattedMessage id="terms-of-service.checkbox.label" />}
          />
        </Box>
        {/* END: FOOTER */}
      </Dialog>
    </>
  );
};

TermsOfService.propTypes = {
  /**
   * The classes object for styling.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The selected language from the redux store.
   */
  selectedLanguage: PropTypes.string.isRequired,
  /**
   * The dispatch function from redux.
   */
  dispatch: PropTypes.func.isRequired,
  /**
   * The function to call when the terms of service auth guard is complete.
   */
  onComplete: PropTypes.func.isRequired,
  /**
   * The function to call when the terms of service auth guard is closed.
   */
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ locale }) => ({
  selectedLanguage: locale.locale,
});

export default connect(mapStateToProps)(withStyles(styles)(TermsOfService));
