import React from 'react';

const AttentionIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 155 155"
      role="img"
      aria-label="Attention"
    >
      <defs>
        <clipPath id="clip-Attention_">
          <rect width="155" height="155" />
        </clipPath>
      </defs>
      <g id="Attention_" data-name="Attention " clip-path="url(#clip-Attention_)">
        <path
          id="diamond-exclamation-solid"
          d="M68.988,3.545a12.142,12.142,0,0,1,17.149,0L151.58,68.988a12.142,12.142,0,0,1,0,17.149L86.137,151.58a12.142,12.142,0,0,1-17.149,0L3.545,86.137a12.142,12.142,0,0,1,0-17.149L68.988,3.545Zm8.574,35.236a7.254,7.254,0,0,0-7.271,7.271V79.986a7.271,7.271,0,0,0,14.543,0V46.053A7.254,7.254,0,0,0,77.562,38.781Zm9.7,67.867a9.7,9.7,0,1,0-9.7,9.7A9.685,9.685,0,0,0,87.258,106.648Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

AttentionIcon.defaultProps = {
  size: 155,
  fill: '#0F2C54',
};

export default AttentionIcon;
