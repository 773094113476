export default theme => ({
  sideBar: {
    overflowY: "auto",
    height: "100%",
    width: "100%",
    borderRight: `1.2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black
  },
  sideBarContainer: {
    display: "flex",
    padding: "0px 20px",
    height: 62,
    alignItems: "center",
    borderTop: `1.2px solid ${theme.palette.divider}`,
    cursor: "pointer",
    color: theme.palette.primary.textGrey,
    "&:last-of-type": {
      borderBottom: `1.2px solid ${theme.palette.divider}`
    },
    "& span": {
      fill: theme.palette.primary.textGrey
    },
    "& svg": {
      fill: theme.palette.primary.textGrey
    }
  },

  sideBarItem: {
    fontSize: 16,
    marginLeft: 15,
    marginBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  newMessage: {
    display: "flex",
    fontSize: 16,
    color: theme.palette.primary.white,
    cursor: "pointer",
    height: 60,
    alignItems: "center",
    padding: "0px 20px"
  },
  newMessageIcon: {
    marginRight: "15px",
    cursor: "pointer"
  },
  messageTag: {
    paddingLeft: "7px",
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  activeClass: {
    display: "flex",
    padding: "0px 20px",
    height: 60,
    alignItems: "center",
    cursor: "pointer",
    borderRight: `4px solid ${theme.palette.primary.themeGreen}`,
    position: "relative",
    "& span": {
      fill: theme.palette.primary.black
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(90deg, rgba(1, 113, 237, 0.3) 5.3%, rgba(1, 113, 237, 0.1) 100%)",
      opacity: 0.4,
      transform: "matrix(-1, 0, 0, 1, 0, 0)"
    }
  },
  icon: {
    color: theme.palette.primary.main,
    marginLeft: 11
  },
  expandCollapse: {
    display: "inline-grid",
    marginRight: 8
  },
  sidebarTopBar: {
    height: 60
  },

  badge: {
    height: 6,
    width: 6,
    borderRadius: "100%"
  },
  badgeColor: {
    background: "red"
  },
  countStyling: {
    width: "8%",
    color: "#717070"
  }
});
