import React, { Component, Suspense } from 'react';
import { withStyles, Typography, CircularProgress, Collapse, Button } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import styles from './index.styles';

import { FormattedMessage } from 'react-intl';
import { Constant } from '../../../../shared';
import Alert from '@material-ui/lab/Alert';
import JusticeScalesBlue from '../../../../shared/icons/justice-scales-blue/justice-scales-blue.js';
import { connect } from 'react-redux';

const MessagesContainer = React.lazy(() => import('../messages-container'));
const SendEditor = React.lazy(() => import('../send-editor'));
const SendTo = React.lazy(() => import('../send-to'));

class MessagesViewer extends Component {
  attorneyBlackBarTimeOut = null;
  constructor(props) {
    super(props);
    this.state = {
      isShowBlackBarMessage: true,
      isMessageSending: false,
      limitError: true,
      showMessageMonitoredWarning: true,
      disableMessSendBox: false,
    };
  }

  componentDidMount() {
    this.hideBlackBarMessage();
  }

  componentWillReceiveProps(nextProps) {
    const nextPropsSelectedContact = nextProps.selectedContact;
    const currentPropsSelectedContact = this.props.selectedContact;
    if (
      nextPropsSelectedContact &&
      currentPropsSelectedContact &&
      nextPropsSelectedContact.contactId !== currentPropsSelectedContact.contactId
    ) {
      this.setState({ isShowBlackBarMessage: true });
      this.hideBlackBarMessage();
    }
    if (
      nextProps.newMessage &&
      nextPropsSelectedContact &&
      currentPropsSelectedContact &&
      nextPropsSelectedContact.contactId !== currentPropsSelectedContact.contactId
    ) {
      this.setState({ disableMessSendBox: false });
    }
  }

  setMessageSending = (isMessageSending) =>
    this.setState({
      isMessageSending,
    });

  setLimitError = (limitError) =>
    this.setState({
      limitError,
    });

  hideBlackBarMessage = () => {
    if (this.attorneyBlackBarTimeOut != null) {
      clearTimeout(this.attorneyBlackBarTimeOut);
    }
    this.attorneyBlackBarTimeOut = setTimeout(() => {
      this.setState({ ...this.state, isShowBlackBarMessage: false });
    }, Constant.BLACK_BAR_TIMEOUT);
  };

  showBlackBarMessage = () => {
    this.setState({
      ...this.state,
      isShowBlackBarMessage: true,
    });
    this.hideBlackBarMessage();
  };

  onSelectContact = async (selectedContact) => {
    const selectedContactRoleType = selectedContact && selectedContact.roleTypes ? selectedContact.roleTypes.split(',') : [];
    if (selectedContact && (selectedContact.role === 'Attorney' || selectedContactRoleType.includes('Attorney'))) {
      this.setState({ ...this.state, showBlackBarMessage: true });
      this.hideBlackBarMessage();
    }
    this.props.onSelectedContact(selectedContact);
  };

  hideMessageMonitoredWarning = () => {
    this.setState({ showMessageMonitoredWarning: false });
  };

  getLastMessagesByCount = (messages = []) => {
    const { unreadMessageCountLimit } = this.props.selectedContact;
    if (messages && messages.length >= unreadMessageCountLimit) {
      const { userDetail = {} } = this.props.app;
      const disableMessSendBox = messages
        .filter((message) => message.senderPersonId !== parseInt(userDetail.userId) || message.recordStatus)
        .every((lastMessages) => lastMessages.senderPersonId === parseInt(userDetail.userId));

      this.setState({ disableMessSendBox });
    } else {
      this.setState({ disableMessSendBox: false });
    }
  };

  render() {
    const {
      classes,
      selectedContact,
      onSend,
      characterLimit,
      newMessage,
      onSearch,
      newSelectedContact,
      announcement,
      contacts,
      showErrorMsg,
    } = this.props;
    const { isMessageSending, limitError } = this.state;

    if (!selectedContact) return null;
    let newSelectedRoleTypes =
      newSelectedContact && newSelectedContact.roleTypes ? newSelectedContact.roleTypes.split(',') : [];
    let selectedContactRoleTypes = selectedContact && selectedContact.roleTypes ? selectedContact.roleTypes.split(',') : [];

    return (
      <div className={classes.chatAreaContainer}>
        <div
          className={classes.chatTopBar}
          style={{
            display: typeof selectedContact.contactName !== 'string' ? 'none' : null,
          }}
        >
          <div className={classes.chatInfo}>
            {selectedContact.isAnnouncement ? (
              <RecordVoiceOverIcon className={classes.chatIcon} />
            ) : selectedContact.roleTypes === 'Attorney' || selectedContactRoleTypes.includes('Attorney') ? (
              <JusticeScalesBlue
                onClick={this.showBlackBarMessage}
                className={classes.attorneyIcon}
                style={{ marginRight: 17 }}
              />
            ) : (
              <QuestionAnswerIcon className={classes.chatIcon} />
            )}
            <Typography align="left" variant="h3" className={classes.userName}>
              {selectedContact.contactName}
            </Typography>
            {selectedContact.contactId ? (
              <Typography align="left" variant="span" className={classes.userInfo}>
                {selectedContact.roleName === 'Attorney' || selectedContactRoleTypes.includes('Attorney') ? (
                  <div className={classes.attorneyClientPrivileged}>
                    <FormattedMessage id="attorneyPrivileged" />
                    <JusticeScalesBlue style={{ width: 16, opacity: 2 }} className={classes.attorneyPriviledgeIcon} />
                  </div>
                ) : (
                  `#${selectedContact.contactId}`
                )}
              </Typography>
            ) : (
              ''
            )}
          </div>
        </div>
        {!(
          (selectedContact.roleName &&
            (selectedContact.roleName === 'Attorney' || selectedContactRoleTypes.includes('Attorney'))) ||
          (newSelectedContact && (newSelectedContact.role === 'Attorney' || newSelectedRoleTypes.includes('Attorney')))
        ) &&
          !selectedContact.isAnnouncement && (
            <Collapse in={this.state.showMessageMonitoredWarning}>
              <Alert
                severity="error"
                action={
                  <Button color="inherit" size="small" onClick={this.hideMessageMonitoredWarning}>
                    Dismiss
                  </Button>
                }
              >
                All messages that do not fall under attorney-client privilege are being monitored by program staff.
              </Alert>
            </Collapse>
          )}
        <Suspense fallback={<CircularProgress />}>
          {newMessage ? (
            <>
              <SendTo
                onSearch={onSearch}
                onSelectedContact={(selectedContact) => {
                  this.onSelectContact(selectedContact);
                }}
                contacts={contacts}
              />
              {selectedContact.contactId !== 0 ? (
                <MessagesContainer
                  selectedContact={selectedContact}
                  announcement={announcement}
                  setMessageSending={this.setMessageSending}
                  setLimitError={this.setLimitError}
                  isMessageSending={isMessageSending}
                  getLastMessagesByCount={this.getLastMessagesByCount}
                  disableMessSendBox={this.state.disableMessSendBox}
                />
              ) : (
                <div className={classes.placeholder} />
              )}
            </>
          ) : (
            <MessagesContainer
              selectedContact={selectedContact}
              announcement={announcement}
              setMessageSending={this.setMessageSending}
              setLimitError={this.setLimitError}
              isMessageSending={isMessageSending}
              getLastMessagesByCount={this.getLastMessagesByCount}
              disableMessSendBox={this.state.disableMessSendBox}
            />
          )}

          {(newMessage || selectedContact.messageRelationshipStatus) && !selectedContact.isAnnouncement && (
            <SendEditor
              newMessage={newMessage}
              onSend={onSend}
              showErrorMsg={showErrorMsg}
              key={this.state.disableMessSendBox}
              characterLimit={characterLimit}
              unreadMessageCountLimit={this.props.selectedContact.unreadMessageCountLimit}
              setMessageSending={this.setMessageSending}
              isMessageSending={isMessageSending}
              limitError={limitError}
              setLimitError={this.setLimitError}
              contactId={newMessage ? newSelectedContact : selectedContact.contactId}
              disableMessSendBox={this.state.disableMessSendBox}
            />
          )}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { app };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessagesViewer));
