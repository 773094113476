import React from 'react';
import { withStyles, Box, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { FormattedMessage, useIntl } from 'react-intl';

import styles from './back-button.style';
import withUnleash from '../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../core/services/unleash/unleash';

const BackButton = (props) => {
  const { classes, onClick, unleashProps } = props;
  const intl = useIntl();

  if (unleashProps.isFlagEnabled()) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Button
        id="back-btn"
        className={classes.backBtn}
        startIcon={<ArrowBackIosIcon />}
        onClick={onClick}
        color="primary"
        aria-label={intl.formatMessage({ id: 'goBack' })}
      >
        <FormattedMessage id="goBack" />
      </Button>
    </Box>
  );
};

export default withUnleash(withStyles(styles)(BackButton), UnleashService.FLAGS.BREADCRUMB_NAVIGATION);
