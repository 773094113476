export default (theme) => ({
  root: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  sectionTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.greyScale[110],
  },
  modulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  viewMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewMoreButton: {
    textTransform: 'capitalize',
  },
});
