export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  title: {
    textTransform: 'capitalize',
  },
  ratingBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 5,
    border: '1px solid #E2E9EF',
    margin: '5px 0px',
    cursor: 'pointer',
    padding: '15px 30px',
    fill: theme.palette.primary.sub,
    justifyContent: 'flex-start',
  },
  ratingText: {
    textTransform: 'capitalize',
    marginLeft: 10,
    fontWeight: 700,
  },
  readOnly: {
    pointerEvents: 'none',
  },
  activeRating: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#1B8A881A',
    fill: theme.palette.primary.main,
  },
});
