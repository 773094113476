export default (theme) => ({
  overviewHeader: {
    paddingTop: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headingWrapper: {
    display: 'inline-block',
    zIndex: 99,
    fontWeight: 700,
  },
  modulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  divider: {
    margin: '24px 0px',
  },
});
