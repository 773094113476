import { Http } from '../../../core';

/**
 * @name getCareerPaths
 * @desc retrieve career paths
 * @param {boolean} pinnedOnly
 * @return {Promise}
 */
const getCareerPaths = (pinnedOnly = false) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get(`/services/career-path/v1/paths?pinned-only=${pinnedOnly}`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name getCareerPathDetails
 * @desc retrieve career path details
 * @param {string} id
 * @return {Promise}
 */
const getCareerPathDetails = (id) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get(`/services/career-path/v1/paths/${id}/details`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name pinCareerPath
 * @desc mark career path as pinned
 * @param {string} id
 * @return {Promise}
 */
const pinCareerPath = (id) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.post(
      `/services/career-path/v1/paths/${id}/pin?noLoader=true`,
      {},
      {
        pendingApiKey: `paths/pin/${id}`,
      }
    )
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name unpinCareerPath
 * @desc remove career path as pinned
 * @param {string} id
 * @return {Promise}
 */
const unpinCareerPath = (id) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.delete(`/services/career-path/v1/paths/${id}/pin?noLoader=true`, {
      pendingApiKey: `paths/unpin/${id}`,
    })
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name reportSelfProgress
 * @desc set progress state of program
 * @return {Promise}
 */
const reportSelfProgress = (programId, progressState) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.post(`/services/career-path/v1/programs/${programId}/progress?noLoader=true`, {
      state: progressState,
    })
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getCareerPaths, getCareerPathDetails, pinCareerPath, unpinCareerPath, reportSelfProgress };
