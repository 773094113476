import { ContactRequestConstants } from '../../services';

const useArchiveUnArchiveIconHook = (contactRequest) => {
  const isInactive =
    contactRequest.status.toUpperCase() === ContactRequestConstants.CONTACT_REQUEST_STATUSES.INACTIVE.toUpperCase();

  const nonArchivableRelationshipTypes = [
    ...Object.values(ContactRequestConstants.PRIVATE_CONTACTS_RELATIONSHIP_TYPES),
    'PROBATION_PAROLE_OFFICER',
  ];

  const isNotArchivable = nonArchivableRelationshipTypes.includes(contactRequest.relationshipType);

  const isBlockedOrRejected =
    contactRequest.status.toUpperCase() === ContactRequestConstants.CONTACT_REQUEST_STATUSES.BLOCKED.toUpperCase() ||
    contactRequest.status.toUpperCase() === ContactRequestConstants.CONTACT_REQUEST_STATUSES.REJECTED.toUpperCase();

  return {
    isInactive,
    isBlockedOrRejected,
    isNotArchivable,
  };
};

export default useArchiveUnArchiveIconHook;
