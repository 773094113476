import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './program-outline.style';
import { PillButton } from '../../../../../frontend-commons';
import { Constant } from '../../../../../shared/services';
import { FormattedMessage } from 'react-intl';

const ProgramOutline = ({ classes, ...rest }) => {
  const { programId, thumbnailUri, name, description, showActionButton, isEnrolled, onEnroll, onUnenroll } = rest;

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.bannerContainer}>
          <img id="_program-banner-image" src={thumbnailUri} alt="" role="presentation" className={classes.bannerImage} />
        </Box>
        <Box className={classes.headingWrapper}>
          <Typography id="_program-details-title" variant="h3">
            {name}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.detailsContainer}>
        <Box>
          <Typography id="_program-details-description" variant="body1" className={classes.description}>
            {description}
          </Typography>
        </Box>

        {showActionButton && (
          <Box className={classes.enrollBtnContainer}>
            {isEnrolled ? (
              <PillButton
                id="_program-details-unenroll-button"
                variant="outlined"
                color="secondary"
                className={classes.unenrollButton}
                onClick={onUnenroll}
                tracking-type={Constant.TRACKING_TYPES.PROGRAM}
                tracking-id={`open-unenroll-dialog-${programId}`}
              >
                <FormattedMessage id="unenroll" />
              </PillButton>
            ) : (
              <PillButton
                id="_program-details-enroll-button"
                variant="contained"
                onClick={onEnroll}
                tracking-type={Constant.TRACKING_TYPES.PROGRAM}
                tracking-id={`enroll-program-${programId}`}
              >
                <FormattedMessage id="enroll" />
              </PillButton>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

ProgramOutline.propTypes = {
  programId: PropTypes.string.isRequired,
  thumbnailUri: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showActionButton: PropTypes.bool.isRequired,
  isEnrolled: PropTypes.bool.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onUnenroll: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProgramOutline);
