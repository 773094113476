import { Box, Card, Grid, Button, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Schedule } from '../../../content/play/components';
import Moment from 'react-moment';
import momentTimeZone from 'moment-timezone';

import styles from './closed.style';
import { BlockIcon } from '../../icons';

function getNextActiveSlot(schedule) {
  schedule = [].concat.apply([], schedule);
  const nextActiveSlots = schedule.filter((item) => !!item.nextActiveSlot);
  if (nextActiveSlots.length > 0) {
    return nextActiveSlots.reverse()[0].nextActiveSlot;
  } else {
    return null;
  }
}

const Closed = (props) => {
  const { classes } = props;
  const nextActiveSlot = getNextActiveSlot(props.schedule);
  const intl = useIntl();

  return (
    <Grid
      container
      item
      classes={{
        root: [classes.root, props.align === 'center' ? classes.center : null].join(' '),
      }}
    >
      {/* BEGIN: BODY */}
      <Grid
        container
        className={[classes.closeBody, props.noGap ? classes.noGap : null].join(' ')}
        direction="column"
        item
        data-cy-name="close-main-and-sub-text"
      >
        <Box style={!props.align ? { display: 'flex' } : {}}>
          <Card
            data-cy-name="service-card"
            className={classes.card}
            style={!props.align ? { width: '60%', boxShadow: 'none' } : {}}
          >
            <BlockIcon size={75} />

            {/* BEGIN: HEADER */}
            <Typography id="main-text" data-cy-name="closed-main-text" variant="h2" className={classes.mainText}>
              {props.mainText}
            </Typography>
            <Typography id="sub-text" variant="h4" data-cy-name="closed-sub-text" className={classes.subText}>
              {props.subText}
            </Typography>
            {nextActiveSlot && (
              <Grid style={{ alignItems: 'normal', justifyContent: 'normal' }} data-cy-name="week-schedule" container item>
                <Grid item xs={12} sm={6} className={classes.scheduleWrapper}>
                  {/* BEGIN: DAY */}
                  <Box className={classes.dayWrapper}>
                    <Box className={classes.chipContainer}>
                      <Typography variant="h3" color="secondary" id="next-active-day-name-container" className={classes.day}>
                        {nextActiveSlot.day.toLowerCase()}
                      </Typography>
                    </Box>
                    <Box className={classes.chipContainer}>
                      <Typography id="next-active-slot-container" className={classes.daySchedule}>
                        <Moment unix tz={momentTimeZone.tz.guess()} format="hh:mmA">
                          {nextActiveSlot.slot.start}
                        </Moment>
                        &nbsp; - &nbsp;
                        <Moment unix format="hh:mmA">
                          {nextActiveSlot.slot.end}
                        </Moment>
                      </Typography>
                    </Box>
                  </Box>
                  {/* END: DAY */}
                </Grid>
              </Grid>
            )}
            {props.align && props.schedule && (
              <>
                <Typography id="full-schedule" variant="h3" data-cy-name="full-schedule" className={classes.bodySubHeading}>
                  <FormattedMessage id="fullSchedule" />
                </Typography>

                {/* END: HEADER */}
                {/* BEGIN: SCHEDULE */}

                <Box component="div" className={classes.scheduleContent}>
                  <Schedule schedule={props.schedule} />
                </Box>
              </>
            )}
            {/* END: SCHEDULE */}
            {/* BEGIN: FOOTER */}
            {props.handleLogout && (
              <Grid container className={classes.closeFooter} direction="column" item>
                <Button
                  onClick={props.handleLogout}
                  className={[classes.signoutBtn, classes.playScheduleBtn].join(' ')}
                  aria-label={intl.formatMessage({ id: 'menuSignout' })}
                >
                  <FormattedMessage id="menuSignout" />
                </Button>
              </Grid>
            )}
            {/* END: FOOTER */}
          </Card>
          {!props.align && props.schedule && (
            <Box component="div" className={classes.scheduleContent} style={{ width: '40%', padding: '0 16px' }}>
              <Typography
                id="full-schedule"
                data-cy-name="full-schedule"
                variant="h3"
                className={classes.bodySubHeading}
                style={{ margin: '0 0 5px' }}
              >
                <FormattedMessage id="fullSchedule" />
              </Typography>
              <Schedule schedule={props.schedule} />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Closed);
