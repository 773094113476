export default theme => ({
  sectionMargin: {
    marginTop: 55,
    marginBottom: 20
  },
  carouselViewText: {
    marginBottom: 20,
    fontFamily: theme.typography.secondaryFont,
    ...theme.typography.bold,
    letterSpacing: "-0.03em"
  },
  carousel: {
    width: "100%",
    padding: "20px 0"
  },
  carouselItem: {
    display: "flex",
    padding: "0 10px",
    marginBottom: 30
  }
});
