import React from 'react';
import { withStyles, Box, Typography, Divider } from '@material-ui/core';

import styles from './personality.style';
import { FormattedMessage } from 'react-intl';
import { MissingData } from '..';

const Personality = (props) => {
  const { classes, data } = props;

  return (
    <Box className={classes.root}>
      <Box>
        <Typography id={`personality-title`} className={classes.capitalize} variant="h2">
          <FormattedMessage id="personality" />
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      {data ? (
        <>
          {data.top_interest && (
            <Box>
              <Typography id={`top-interest`} className={classes.topInterest}>
                {data.top_interest.description}
              </Typography>
            </Box>
          )}

          <Typography id="personality-desc" gutterBottom variant="h3" color="secondary" className={classes.capitalize}>
            <FormattedMessage id="theyDoWellAt" />:
          </Typography>
          {data.work_styles && data.work_styles.element && data.work_styles.element.length > 0 && (
            <ul>
              {data.work_styles.element.map((info) => (
                <li key={info.id} className={classes.description}>
                  <Typography>{info.name}</Typography>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <MissingData />
      )}
    </Box>
  );
};

export default withStyles(styles)(Personality);
