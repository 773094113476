export default theme => ({
  warningIcon: {
    color: theme.palette.primary.scarlet,
    fontSize: 40
  },
  title: {
    ...theme.typography.bold,
    ...theme.typography.t48,
    color: theme.palette.primary.battleshipGrey,
    marginLeft: 10
  },
  mainText: {
    marginTop: 20,
    fontWeight: 700
  },
  subText: {
    margin: "10px 0 5px 0",
    fontWeight: 600
  },
  bodySubHeading: {
    margin: "50px 0 5px 0",
    fontWeight: 700
  },
  center: {
    "& div": {
      justifyContent: "center",
      alignItems: "center"
    }
  },

  closeBody: {
    marginTop: 30
  },
  closeFooter: {
    marginTop: 25
  },
  noGap: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  },
  playScheduleBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    width: 282,
    maxWidth: "100%",
    height: 48,
    borderRadius: 28,
    ...theme.typography.bold,
    ...theme.typography.t18,
    letterSpacing: "1.61px",
    lineHeight: "16px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  card: {
    borderRadius: "10px",
    padding: "25px"
  },
  signoutBtn: {
    backgroundColor: "#3ED79E",
    color: "#1F1F1F"
  },
  scheduleWrapper: {
    "& > div:not(:first-child)": {
      marginTop: 15
    }
  },
  chipContainer: {
    color: theme.palette.text.sub,
    fontWeight: 500
  },
  day: {
    textTransform: "capitalize",
    fontWeight: 700
  },
  daySchedule: {
    ...theme.typography.t16,
    color: "inherit",
    position: "relative",
    zIndex: 2,
    fontWeight: "inherit"
  }
});
