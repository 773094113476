import { useEffect } from 'react';
import { useIntl } from 'react-intl';

/**
 * @name useDocumentTitleHook
 * @desc A custom hook to set the document title.
 * @param {String} title - should be a key from the locale file. e.g. 'page.title.home'
 * @returns {void}
 */
const useDocumentTitleHook = (title) => {
  const intl = useIntl();
  title = intl.formatMessage({ id: title });

  useEffect(() => {
    document.title = title;
  }, [title]);
};

export default useDocumentTitleHook;
