import React from "react";
import { 
    withStyles, 
    Box, 
    FormControl,
    OutlinedInput,
    InputAdornment,
    Button,
    Grid,
    Select,
    MenuItem
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

import styles from "./search-and-filter.style";

const SearchAndFilter = props => {
  const { 
    classes, 
    searchValue,
    handleSearch,
    buttonTitle,
    showDropdown,
    dropdownProps
} = props;

  return (
    <Box className={classes.root}>
        <Grid container>
            
            {/* BEGIN: SEARCH BAR */}
            <Grid item sm={showDropdown ? 9 : 12} md={showDropdown ? 10 : 12}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControlWrapper}
                >
                    <OutlinedInput
                        id="search-bar"
                        value={searchValue}
                        onChange={handleSearch}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon color="primary" />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            {/* END: SEARCH BAR */}

            {/* BEGIN: DROPDOWN */}
            {
                showDropdown &&
                <Grid item sm={3} md={2} className={classes.dropdownContainer}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formControlWrapper}
                    >
                        <Select
                            id="search-and-filter-dropdown"
                            value={dropdownProps.selectedValue}
                            onChange={dropdownProps.onChange}
                            className={classes.dropdown}
                            endAdornment={
                                dropdownProps.selectedValue !== -1 &&
                                <InputAdornment position="end" className={classes.selectAdornment}>
                                    <CancelIcon onClick={dropdownProps.onClear} />
                                </InputAdornment>
                            }
                        >
                            {
                                dropdownProps.items && dropdownProps.items.length > 0 &&
                                dropdownProps.items.map((item, index) => (
                                    <MenuItem 
                                        value={item.value} 
                                        key={`${item.value}-${index}`}
                                        disabled={item.isPlaceholder}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
            {/* END: DROPDOWN */}

        </Grid>

        {/* BEGIN: ACTION BUTTON */}
        <Button 
            fullWidth
            size="large"
            variant="contained" 
            color="primary"
            className={classes.actionBtn}
        >
            {buttonTitle}
        </Button>
        {/* END: ACTION BUTTON */}

    </Box>
  );
};

export default withStyles(styles)(SearchAndFilter);
