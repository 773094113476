import { useState } from 'react';
import { setLocale } from '../../../../../core';

/**
 * Hook to handle the header for the AuthGuards.
 *
 * @param {string} selectedLanguage - The selected language
 * @param {function} dispatch - The dispatch function
 * @returns {object}
 */
const useAuthGuardsHeaderHook = ({ selectedLanguage, dispatch }) => {
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);

  /**
   * Function to check if the language is selected.
   *
   * @param {String} language - The language to check
   * @returns {Boolean} The flag to check if the language is selected
   */
  const isLanguageSelected = (language) => {
    return selectedLanguage === language;
  };

  /**
   * Function to handle the menu open.
   */
  const handleMenuOpen = () => {
    setOpenLanguageMenu(true);
  };

  /**
   * Function to handle the menu close.
   */
  const handleMenuClose = () => {
    setOpenLanguageMenu(false);
  };

  /**
   * Function to handle the language select.
   *
   * @param {Object} event - The event object
   * @param {String} language - The language to select
   */
  const handleLanguageSelect = (event, language) => {
    event.preventDefault();

    if (!language || isLanguageSelected(language)) {
      return;
    }

    dispatch(setLocale({ locale: language }));
    handleMenuClose();
  };

  return { openLanguageMenu, isLanguageSelected, handleMenuOpen, handleMenuClose, handleLanguageSelect };
};

export default useAuthGuardsHeaderHook;
