import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Permission } from '../../../../core';
import { Constant, Utility } from '../../../../shared';
import { ContentAreaFilters } from '../../../../shared/components';
import { AchievementsDashboardService } from '../../services';
import styles from './achievements-dashboard.style';
import { DocumentTitle } from '../../../../v2/shared';

const AchievementCards = React.lazy(() => import('../../components/achievements-cards/achievements-cards'));
const AchievementInfographic = React.lazy(() =>
  import('../../components/achievements-infographic/achievements-infographic')
);
const AchievementLists = React.lazy(() => import('../../components/achievements-lists/achievements-lists'));
const AchievementsFilter = React.lazy(() => import('../../components/achievements-filter/achievements-filter'));
const AchievementTitle = React.lazy(() => import('../../components/achievements-title/achievements-title'));

export class AchievementsDashboard extends React.Component {
  _dashboard = new AchievementsDashboardService();
  _utility = new Utility();
  state = {
    selectedContentAreas: this._utility.getInitiallyActivateContentArea(),
    daysCount: 7,
    isCompletedViewCollapsed: true,
    infographic: {
      data: null,
    },
    achievement: {
      data: {
        hours: 0,
        tokens: 0,
        tasks: 0,
      },
    },
    courseCompleted: {
      data: null,
    },
    bundles: {
      data: null,
    },
  };

  componentDidMount() {
    this.getFilteredData();
  }

  /**
   * @name getFilteredData
   * @desc A single wrapper method responsible to fetch infographic data
   * as well as other data from service.
   * @return {void}
   */
  getFilteredData = () => {
    // FETCHING INFOGRAPHIC DATA
    this.getInfographicData();

    // FETCHING COURSE COMPLETED
    this.getCourseCompleted();

    // FETCHING ACHIEVEMENTS META INFORMATION
    this.getAchievementMeta();

    // FETCHING BUNDLES DATA
    this.getBundlesData();
  };

  /**
   * @name getBundlesData
   * @desc Retrives bundle events data of user from service.
   * @return {void}
   */
  getBundlesData = () => {
    const { daysCount, selectedContentAreas, bundles } = this.state;

    this._dashboard
      .getBundlesData(daysCount, selectedContentAreas)
      .then((_successLog) => {
        bundles.data = _successLog;
        this.setState({
          bundles,
        });
      })
      .catch((e) => null);
  };

  /**
   * @name getAchievementMeta
   * @desc Retrives hours, tasks completed, tokens earned data from service.
   * @return {void}
   */
  getAchievementMeta = () => {
    const { daysCount, selectedContentAreas, achievement } = this.state;

    this._dashboard
      .getAchievementMeta(daysCount, selectedContentAreas)
      .then((_successLog) => {
        achievement.data = _successLog;
        this.setState({
          achievement,
        });
      })
      .catch((e) => null);
  };

  /**
   * @name getCourseCompleted
   * @desc Retrives completed items by user from service.
   * @return {void}
   */
  getCourseCompleted = () => {
    const { daysCount, selectedContentAreas, courseCompleted } = this.state;

    this._dashboard
      .getCourseCompleted(daysCount, selectedContentAreas)
      .then((_successLog) => {
        courseCompleted.data = _successLog;
        this.setState({
          courseCompleted,
        });
      })
      .catch((e) => null);
  };

  /**
   * @name getInfographicData
   * @desc Based upon state data; infographic data is extracted from
   * service.
   * @return {void}
   */
  getInfographicData = () => {
    const { daysCount, selectedContentAreas, infographic } = this.state;
    this.setState({
      infographic,
    });

    this._dashboard
      .getInfographicData(daysCount, selectedContentAreas)
      .then((_successLog) => {
        infographic.data = _successLog;
        this.setState({
          infographic,
        });
      })
      .catch((e) => null);
  };

  /**
   * @name toggleCompletedView
   * @desc Toggles completed view.
   * @return {void}
   */
  toggleCompletedView = () => {
    const { isCompletedViewCollapsed } = this.state;
    this.setState({
      isCompletedViewCollapsed: !isCompletedViewCollapsed,
    });
  };

  /**
   * @name isActiveFilter
   * @param {number} id ID of filter
   * @desc Check is filter is active or not.
   * @return {boolean}
   */
  isActiveFilter = (id) => {
    return this.state.selectedContentAreas.indexOf(id) > -1;
  };

  /**
   * @name setDayCount
   * @param {number} count
   * @desc Sets selected day count for filtering process.
   * @return {void}
   */
  setDayCount = (daysCount) => {
    // GETTING FILTERED DATA
    // UPON CHANGING FILTER STATE
    this.setState(
      {
        daysCount,
      },
      this.getFilteredData
    );
  };

  /**
   * @name setFilters
   * @param {string} type
   * @param {number} filterId Only requeired in specific case
   * @desc Sets filters based on selection.
   * @return {void}
   */
  setFilters = (type, filterId) => {
    let { selectedContentAreas } = this.state;
    const filterableContentAreas = this._utility.getFilterableContentAreas(
      Object.keys(Constant.CONTENT_AREA).map((contentId) => parseInt(contentId))
    );
    switch (type) {
      case 'all':
        if (selectedContentAreas.length >= filterableContentAreas.length) {
          // THEN EMPTY ITS ARRAY
          selectedContentAreas = [];
        } else {
          // OTHERWISE FILL ALL
          // CONTENT AREAS
          selectedContentAreas = filterableContentAreas;
        }
        break;

      case 'specific':
        const indexOfSelectedContentArea = selectedContentAreas.indexOf(filterId);
        if (indexOfSelectedContentArea > -1) {
          selectedContentAreas.splice(indexOfSelectedContentArea, 1);
        } else {
          selectedContentAreas.push(filterId);
        }
        break;

      default:
        return;
    }

    // FETCHING FILTERED DATA
    // UPON CHANGING FILTER STATE
    this.setState(
      {
        selectedContentAreas,
      },
      this.getFilteredData
    );
  };

  render() {
    const { classes } = this.props;
    const { isCompletedViewCollapsed, selectedContentAreas } = this.state;
    return (
      <>
        <DocumentTitle title="page.title.achievements" />
        <Permission>
          <Suspense fallback={<CircularProgress />}>
            <Grid
              container
              item
              className={[classes.mainWrapper, isCompletedViewCollapsed ? null : classes.bottomThreshold].join(' ')}
            >
              {/* BEGIN: ACHIEVEMENTS FILTER  */}
              <AchievementsFilter {...this.state} setDayCount={this.setDayCount} setFilters={this.setFilters} />
              {/* END: ACHIEVEMENTS FILTER */}
              {/* BEGIN: CONTENT AREA FILTERS */}
              <Grid container className={classes.sectionGap}>
                <ContentAreaFilters
                  setFilters={this.setFilters}
                  isActiveFilter={this.isActiveFilter}
                  selectedFilters={selectedContentAreas}
                  hideViewAllBtn={true}
                />
              </Grid>
              {/* END: CONTENT AREA FILTERS */}
              {/* BEGIN: TITLE */}
              <Grid container className={classes.achievementTitleWrapper}>
                {this.state.selectedContentAreas.length >= 5 && this.state.daysCount === 7 && (
                  <AchievementTitle {...this.state} userDetail={this.props.userDetail} />
                )}
              </Grid>
              {/* END: TITLE */}
              {/* BEGIN: infographic */}
              <Grid container className={classes.sectionGap}>
                <AchievementInfographic {...this.state} />
              </Grid>
              {/* END: infographic */}
              {/* BEGIN: CARDS */}
              <Grid container className={classes.sectionSmallGap}>
                <AchievementCards {...this.state} {...this.props} toggleCompletedView={this.toggleCompletedView} />
              </Grid>
              {/* END: CARDS */}
              {/* BEGIN: LISTS */}
              <Grid container className={classes.sectionSmallGap}>
                <AchievementLists {...this.state} />
              </Grid>
              {/* END: LISTS */}
            </Grid>
          </Suspense>
        </Permission>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(AchievementsDashboard)));
