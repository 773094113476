export default (theme) => ({
  root: {
    borderRadius: 12,
    background: 'white',
    padding: 25,
    minHeight: 350,
    height: '100%',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  description: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  divider: {
    width: '100%',
    margin: '20px 0px',
  },
  jobsContainer: {
    margin: '30px 0px',
  },
  jobTitle: {
    ...theme.typography.t16,
    textDecoration: 'none',
    textTransform: 'capitalize',
    cursor: 'pointer',
    fontWeight: '700',
  },
});
