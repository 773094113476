import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, CircularProgress, Divider, Grid, LinearProgress, Typography } from '@material-ui/core';

import * as InterestProfilerStore from '../../../../core/store/interest-profiler';
import { Attribution } from '../../../careers/components';
import { Instructions, QuestionItem, SameAnswersModal, JobZoneQuestionnaire, PersonalityResult } from '../../components';
import styles from './questionnaire.style';
import { InterestProfilerService } from '../../services/interest-profiler.service';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { CheckmarkIcon, Constant } from '../../../../shared';
import { DocumentTitle } from '../../../../v2/shared';

export class Questionnaire extends React.Component {
  _service = new InterestProfilerService();
  constructor(props) {
    super(props);
    this.state = {
      getStarted: false,
      questions: [],
      answerOptions: [],
      answers: Array.apply(null, Array(30)).map(() => {}),
      sameAnswerCount: 0,
      lastSelectedAnswer: '',
      openSameAnswersModal: false,
      page: 1,
      paging: 1,
      totalCount: 30,
      showJobZoneLoader: false,
      showJobZoneQuestionnaire: false,
      showSuccessLoader: false,
      ipResults: {},
      showIPResults: false,
    };
    this.questionBoxRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedLocale !== prevProps.selectedLocale) {
      if (this.state.getStarted) {
        this.getQuestionnaire();
      }
    }
  }

  getQuestionnaire = async () => {
    try {
      const data = await this._service.getQuestionnaire();
      this.setState({
        questions: data.questions,
        answerOptions: data.answer_options,
      });
    } catch (e) {
      console.log(e);
    }
  };

  saveQuestionnaireResponse = async () => {
    try {
      const answersString = this.state.answers.join('');
      this.props.setInterestProfilerAnswersString(answersString);
      const response = await this._service.saveQuestionnaireResponse(answersString);
      if (response) {
        this.setState({ showJobZoneLoader: true }, () => {
          setTimeout(() => {
            this.setState({ showJobZoneQuestionnaire: true, showJobZoneLoader: false });
          }, 3000);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  fetchInterestProfilerResults = () => {
    const answersString = this.state.answers.join('');
    this._service
      .getInterestProfilerResults(answersString)
      .then((data) => {
        this.setState({
          ipResults: data,
          showJobZoneLoader: false,
          showJobZoneQuestionnaire: false,
          showSuccessLoader: false,
          showIPResults: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setAnswer = (questionIndex, answer) => {
    let { questions, answers, sameAnswerCount, lastSelectedAnswer } = this.state;
    const index = questions.findIndex((item) => item.index === questionIndex);
    if (index >= 0) {
      answers[index] = answer;
      if (lastSelectedAnswer === answer) {
        sameAnswerCount += 1;
      } else {
        sameAnswerCount = 1;
      }
      lastSelectedAnswer = answer;
    }
    this.setState({
      answers,
      sameAnswerCount,
      lastSelectedAnswer,
      openSameAnswersModal: sameAnswerCount >= 5,
    });
  };

  getSelectedAnswer = (questionIndex) => {
    let selectedAnswer = '';
    let { questions, answers } = this.state;
    const index = questions.findIndex((item) => item.index === questionIndex);
    if (index >= 0) {
      selectedAnswer = answers[index];
    }
    return selectedAnswer;
  };

  onGetStarted = () => {
    this.setState(
      {
        getStarted: true,
        answers: Array.apply(null, Array(this.state.totalCount)).map(() => {}),
        sameAnswerCount: 0,
        lastSelectedAnswer: '',
      },
      () => {
        if (this.state.questions && this.state.questions.length === 0) {
          this.getQuestionnaire();
        }
      }
    );
  };

  onBackBtn = () => {
    if (this.state.page === 1) {
      this.setState({ getStarted: false });
    } else {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }
  };

  onNextBtn = () => {
    if (this.state.page === this.state.totalCount / this.state.paging) {
      this.saveQuestionnaireResponse();
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.questionBoxRef.current.focus();
      }, 0);
      this.setState((prevState) => {
        return { page: prevState.page + 1 };
      });
    }
  };

  isNextBtnDisbaled = () => {
    const { answers, page, paging } = this.state;
    const currentPageAnswers = answers.slice(paging * (page - 1), page * paging);
    return !currentPageAnswers.every((item) => !!item);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  closeSameAnswersModal = () => {
    this.setState({ openSameAnswersModal: false });
  };

  onSaveJobZoneQuestionnaire = () => {
    this.setState(
      {
        showSuccessLoader: true,
        showJobZoneQuestionnaire: false,
        showJobZoneLoader: false,
      },
      () => {
        setTimeout(() => {
          this.fetchInterestProfilerResults();
        }, 3000);
      }
    );
  };

  goToCareerDirectory = () => {
    this.props.history.push('/careers');
  };

  render() {
    const { classes, intl } = this.props;
    const {
      getStarted,
      questions,
      answers,
      answerOptions,
      page,
      paging,
      totalCount,
      openSameAnswersModal,
      showJobZoneLoader,
      showJobZoneQuestionnaire,
      showSuccessLoader,
      showIPResults,
      ipResults,
    } = this.state;
    const answersCount = answers.filter((item) => !!item).length;

    return (
      <Box className={classes.root}>
        <DocumentTitle title="page.title.interest-profiler" />
        <SameAnswersModal isOpen={openSameAnswersModal} onClose={this.closeSameAnswersModal} />

        {showIPResults && ipResults.final_result ? (
          <Box className={classes.personalityContainer}>
            <Box className={classes.personalityHeader}>
              <Typography className={classes.personalityResultsTitle}>
                <FormattedMessage id="interest-profiler-results.title" />
              </Typography>
            </Box>
            <Box className={classes.personalityContent}>
              <PersonalityResult
                areaPlacement="top-right"
                {...ipResults}
                showZoneInfo={true}
                recommendedJobZone={this.props.interestProfiler.recommendedJobZone}
              />

              <Divider className={classes.divider} />

              <Box className={classes.personalityActionContainer}>
                <Box className={classes.personalityActionContent}>
                  <Typography variant="h4" gutterBottom className={classes.personalityActionTitle}>
                    <FormattedMessage id="careerMatches" />
                  </Typography>
                  <Typography className={classes.personalityDescription}>
                    <FormattedMessage id="careerMatchesDescription" />
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.personalityActionBtn}
                    onClick={this.goToCareerDirectory}
                    aria-label={intl.formatMessage({ id: 'startBrowsing' })}
                  >
                    <FormattedMessage id="startBrowsing" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : showJobZoneQuestionnaire ? (
          <>
            <Grid sm={12} md={8} className={classes.jobZoneHeader}>
              <Typography gutterBottom variant="h2" className={classes.bold}>
                <FormattedMessage id="job-zone-header.title" />
              </Typography>
              <Typography className={classes.semiBold}>
                <FormattedMessage
                  id="job-zone-header.description"
                  values={{
                    p: (...chunks) => <p>{chunks}</p>,
                  }}
                />
              </Typography>
            </Grid>
            <Divider className={classes.divider} />
            <Box className={classes.jobZoneContainer}>
              <Box className={classes.jobZoneQuestionnaireContent}>
                <JobZoneQuestionnaire onSave={this.onSaveJobZoneQuestionnaire} />
              </Box>
            </Box>
          </>
        ) : showJobZoneLoader ? (
          <Box className={classes.jobZoneContainer}>
            <Box className={classes.jobZoneLoaderContent}>
              <CircularProgress className={classes.jobZoneLoader} color="secondary" />
              <Typography gutterBottom variant="h2">
                <FormattedMessage id="job-zone-loader.title" />
              </Typography>
              <Typography>
                <FormattedMessage id="job-zone-loader.description" />
              </Typography>
            </Box>
          </Box>
        ) : showSuccessLoader ? (
          <Box className={classes.jobZoneContainer}>
            <Box className={classes.jobZoneLoaderContent}>
              <Box className={classes.successIcon}>
                <CheckmarkIcon size={48} />
              </Box>
              <Typography gutterBottom variant="h2">
                <FormattedMessage id="questionnaire-success.title" />
              </Typography>
              <Typography>
                <FormattedMessage id="questionnaire-success.description" />
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            {getStarted ? (
              <Box ref={this.questionBoxRef} tabIndex={0} className={classes.questionBox}>
                {questions.length > 0 &&
                  questions.slice(paging * (page - 1), page * paging).map((item) => (
                    <QuestionItem
                      key={item.index}
                      answerOptions={answerOptions}
                      onAnswerSelect={(answer) => {
                        this.setAnswer(item.index, answer);
                      }}
                      selectedValue={this.getSelectedAnswer(item.index)}
                      {...item}
                    />
                  ))}

                <Divider className={classes.divider} />

                <Box className={classes.actionBox}>
                  <Button
                    id="ip-questionnaire-close-btn"
                    size="large"
                    variant="contained"
                    className={classes.backBtn}
                    onClick={this.onBackBtn}
                    startIcon={<ArrowBack fontSize="large" />}
                    aria-label={intl.formatMessage({ id: 'goBack' })}
                  >
                    <FormattedMessage id="goBack" />
                  </Button>

                  <Button
                    id="ip-questionnaire-next-btn"
                    size="large"
                    variant="contained"
                    className={classes.nextBtn}
                    onClick={this.onNextBtn}
                    disabled={this.isNextBtnDisbaled()}
                    endIcon={<ArrowForward fontSize="large" />}
                    tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
                    tracking-id={page === totalCount / paging ? 'submit-interest-profiler' : ''}
                    aria-label={intl.formatMessage({ id: page === totalCount / paging ? 'submit' : 'nextQuestion' })}
                  >
                    <FormattedMessage id={page === totalCount / paging ? 'submit' : 'nextQuestion'} />
                  </Button>
                </Box>
              </Box>
            ) : (
              <Instructions onClose={this.goBack} onContinue={this.onGetStarted} userDetail={this.props.app.userDetail} />
            )}

            <Box className={classes.footer}>
              {getStarted && (
                <Box className={classes.progressArea}>
                  <LinearProgress
                    id="ip-questionnaire-progress"
                    variant="determinate"
                    value={Math.floor((answersCount * 100) / totalCount)}
                    classes={{
                      root: classes.progressBarRoot,
                      colorPrimary: classes.progressBarColorPrimary,
                      bar: classes.progressBar,
                    }}
                    aria-label={intl.formatMessage({ id: 'questionnaireProgress' })}
                  />

                  <Box className={classes.progressLabelsContainer}>
                    <Typography className={classes.progressLabel}>
                      <FormattedMessage
                        id="mofNQuestions"
                        values={{
                          m: answersCount,
                          n: totalCount,
                        }}
                      />
                    </Typography>
                  </Box>
                </Box>
              )}
              <Grid container sm={12} md={12} lg={12}>
                <Grid item sm={8} md={6} lg={4} className={classes.attributionContainer}>
                  <Attribution />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

const mapStateToProps = ({ app, locale, interestProfiler }) => {
  return {
    selectedLocale: locale.locale,
    app,
    interestProfiler,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInterestProfilerAnswersString: (string) => dispatch(InterestProfilerStore.setAnswersString(string)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(Questionnaire)));
