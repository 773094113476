import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import styles from './questionnaire-results.style';
import { InterestProfilerService } from '../../services/interest-profiler.service';
import { FiltersArea, CareerItem, Attribution } from '../../../careers/components';
import { CareersService } from '../../../careers/services/careers.service';
import { Pagination } from '@material-ui/lab';

export class QuestionnaireResults extends React.Component {
  _service = new InterestProfilerService();
  _careerService = new CareersService();
  constructor(props) {
    super(props);
    this.state = {
      resultsFetched: false,
      page: 0,
      pageSize: 50,
      perPage: 5,
      totalCareers: 0,
      selectedZone: props.interestProfiler.recommendedJobZone || 3,
      careers: [],
      personalityData: {},
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.interestProfiler.answersString) {
      this.getQuestionnaireResults();
      this.setState({ resultsFetched: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.interestProfiler.answersString && !this.state.resultsFetched) {
      this.getQuestionnaireResults();
      this.setState({ resultsFetched: true });
    }
    if (this.props.selectedLocale !== prevProps.selectedLocale) {
      this.getQuestionnaireResults();
    }
    if (this.props.interestProfiler.recommendedJobZone !== prevProps.interestProfiler.recommendedJobZone) {
      this.setState(
        {
          selectedZone: this.props.interestProfiler.recommendedJobZone,
          page: 0,
        },
        () => {
          this.getMatchingCareers();
        }
      );
    }
  }

  getQuestionnaireResults = () => {
    Promise.all([
      this._service.getInterestProfilerResults(this.props.interestProfiler.answersString),
      this._service.getMatchingCareers(
        this.props.interestProfiler.answersString,
        this.state.selectedZone,
        1,
        this.state.pageSize
      ),
    ])
      .then((results) => {
        const personalityData = results[0];
        const careersData = results[1];
        this.setState({
          resultsFetched: true,
          personalityData,
          careers: careersData.careers,
          totalCareers: careersData.total,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getMatchingCareers = async () => {
    try {
      const careersData = await this._service.getMatchingCareers(
        this.props.interestProfiler.answersString,
        this.state.selectedZone,
        1,
        this.state.pageSize
      );
      if (careersData && careersData.careers) {
        this.setState({
          careers: careersData.careers,
          totalCareers: careersData.total,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  goToHome = () => {
    this.props.history.push('/home');
  };

  getJobZone = (career) => {
    let zone = 1;
    if (career.education && career.education.job_zone) {
      zone = career.education.job_zone;
    }
    return zone;
  };

  getMedianPay = (career) => {
    let data = {};
    if (career.job_outlook && career.job_outlook.salary) {
      if (career.job_outlook.salary.annual_median) {
        data = {
          medianPay: career.job_outlook.salary.annual_median,
          medianPayLabel: 'annualMedian',
        };
      } else if (career.job_outlook.salary.annual_median_over) {
        data = {
          medianPay: career.job_outlook.salary.annual_median_over,
          medianPayLabel: 'annualMedian',
        };
      } else if (career.job_outlook.salary.hourly_median) {
        data = {
          medianPay: career.job_outlook.salary.hourly_median,
          medianPayLabel: 'hourlyMedian',
        };
      } else {
        data = {
          medianPay: career.job_outlook.salary.hourly_median_over || 30,
          medianPayLabel: 'hourlyMedian',
        };
      }
    }
    return data;
  };

  onItemClick = (e, id) => {
    e.stopPropagation();
    this.props.history.push(`/careers/${encodeURIComponent(id)}/profile`);
  };

  bookmarkCareer = async (id) => {
    try {
      const response = await this._careerService.addBookmark(id);
      if (response) {
        const careers = this.state.careers;
        const index = careers.findIndex((item) => item.code === id);
        if (index >= 0) {
          careers[index].in_bookmarks = true;
        }
        this.setState({ careers });
      }
    } catch (e) {
      console.log(e);
    }
  };

  removeBookmarkedCareer = async (id) => {
    try {
      const response = await this._careerService.removeBookmark(id);
      if (response) {
        const careers = this.state.careers;
        const index = careers.findIndex((item) => item.code === id);
        if (index >= 0) {
          careers[index].in_bookmarks = false;
        }
        this.setState({ careers });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handlePageChange = (e, value) => {
    this.setState({ page: value - 1 }, () => {
      this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  handleZoneChange = (value) => {
    this.setState({ selectedZone: value, page: 0 }, () => {
      this.getMatchingCareers();
    });
  };

  render() {
    const { classes, intl } = this.props;
    const { careers, totalCareers, perPage, page } = this.state;

    return (
      <Box className={classes.root}>
        {/* BEGIN: HEADER */}
        <Box className={classes.header}>
          <Typography id="ip-results-title" variant="h2" className={classes.headerTitle}>
            <FormattedMessage id="questionnaireResults" />
          </Typography>
          <Button
            id="ip-results-close-btn"
            size="large"
            className={classes.closeBtn}
            onClick={this.goToHome}
            aria-label={intl.formatMessage({ id: 'close' })}
          >
            <FormattedMessage id="close" />
          </Button>
        </Box>
        {/* END: HEADER */}

        {careers.length > 0 ? (
          <>
            <Box className={classes.careersContainer}>
              <Box ref={this.scrollRef} />
              {careers.slice(perPage * page, (page + 1) * perPage).map((career) => (
                <CareerItem
                  key={career.code}
                  title={career.title}
                  subtitle={career.what_they_do}
                  zone={this.getJobZone(career)}
                  {...this.getMedianPay(career)}
                  showZoneBadge={true}
                  showMedianPay={true}
                  onItemClick={(e) => {
                    this.onItemClick(e, career.code);
                  }}
                  isBookmarked={career.in_bookmarks}
                  addBookmark={() => {
                    this.bookmarkCareer(career.code);
                  }}
                  removeBookmark={() => {
                    this.removeBookmarkedCareer(career.code);
                  }}
                  code={career.code}
                />
              ))}
            </Box>
            <Box className={classes.paginationContainer}>
              {totalCareers > perPage && (
                <Pagination
                  count={Math.ceil(totalCareers / perPage)}
                  page={page + 1}
                  siblingCount={0}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  color="secondary"
                  size="large"
                />
              )}
            </Box>
          </>
        ) : (
          <Box className={classes.careersContainer}>
            <FormattedMessage id="noDataAvailable" />
          </Box>
        )}

        <Grid container sm={12} md={12} lg={12}>
          <Grid item sm={8} md={6} lg={4} className={classes.attributionContainer}>
            <Attribution />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = ({ app, locale, interestProfiler }) => {
  return {
    selectedLocale: locale.locale,
    app,
    interestProfiler,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(QuestionnaireResults)));
