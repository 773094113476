import React, { useEffect, useRef, useState } from 'react';
import {
  withStyles,
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Select,
  List,
  ListItem,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReorderIcon from '@material-ui/icons/Reorder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './filters-area.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { ZoneDetails } from '..';
import { Constant } from '../../../../shared/services';
import { CAREER_DIRECTORY_FILTERS } from '../../containers/career-directory/constants';

const FiltersArea = (props) => {
  const {
    classes,
    selectedZone,
    onZoneChange,
    searchValue,
    handleSearchChange,
    onSearch,
    totalCount,
    selectedView,
    mainFilter,
    onGroupChange,
    selectedGroup,
    categories,
    selectedCategory,
    onCategoryChange,
    alphabeticalGroups,
    onClearFilters,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const categoryFilterRef = useRef(null);
  const intl = useIntl();
  const minCharForSearch = 3;

  const handleCategoryChange = (event) => {
    onCategoryChange(event.target.value.code);
  };

  const clearFilters = () => {
    onClearFilters();
  };

  useEffect(() => {
    const selected = categories.filter((c) => c.code === selectedCategory);
    if (selected.length > 0) {
      setSelectedCategoryValue(selected[0]);
    }
  }, [selectedCategory, categories]);

  return (
    <Box>
      <Box className={classes.mainFilters}>
        <ZoneDetails
          isOpen={showDialog}
          selectedZone={selectedZone}
          onClose={() => {
            setShowDialog(false);
          }}
        />

        {/* BEGIN: TITLE */}
        <Box>
          <Typography variant="h3" id="career-filters-title" className={classes.mainTitle}>
            <FormattedMessage id="career-filters.title" />
          </Typography>
        </Box>

        <Divider className={classes.divider} />
        {/* END: TITLE */}

        <Box className={classes.content}>
          <Grid className={classes.filterArea} sm={12}>
            {/* BEGIN: SEARCH BAR */}
            <Box className={classes.centerContainer}>
              <Box width="100%">
                <FormControl variant="outlined" fullWidth className={classes.searchBarWrapper}>
                  <OutlinedInput
                    id="search-careers"
                    autoFocus={searchValue !== ''}
                    placeholder="Search careers..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    labelWidth={0}
                    fullWidth
                    className={classes.searchBar}
                    classes={{
                      notchedOutline: classes.noBorder,
                    }}
                    inputProps={{
                      id: 'search-careers-input',
                      'aria-label': intl.formatMessage({ id: 'searchCareers' }),
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize="medium" color="primary" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <Button
                  id="search-careers-button"
                  className={classes.searchBtn}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={onSearch}
                  disabled={searchValue.length < minCharForSearch}
                  tracking-type={Constant.TRACKING_TYPES.CAREER_DIRECTORY}
                  tracking-id="search-careers"
                  aria-label={intl.formatMessage({ id: 'search' })}
                >
                  <FormattedMessage id="search" />
                </Button>
              </Box>
            </Box>
            {/* END: SEARCH BAR */}

            <Box className={classes.marginTop20}>
              <Typography variant="h4" gutterBottom className={classes.categoryFilterLabel}>
                <FormattedMessage id="careerCategories" />
              </Typography>
              <Box ref={categoryFilterRef} className={classes.centerContainer}>
                {/* BEGIN: CATEGORY FILTER */}
                <Box className={classes.categoryFilter}>
                  <FormControl>
                    <Select
                      value={selectedCategoryValue}
                      onChange={handleCategoryChange}
                      className={classes.categorySelect}
                      classes={{
                        selectMenu: classes.selectOption,
                        root: classes.selectRoot,
                      }}
                      renderValue={(value) => (
                        <Typography className={classes.categorySelectLabel}>
                          {value ? value.title : 'Select Category'}
                        </Typography>
                      )}
                      MenuProps={{
                        classes: {
                          paper: classes.selectMenu,
                        },
                      }}
                      onFocus={() => {
                        categoryFilterRef.current.focus();
                      }}
                      displayEmpty
                      IconComponent={ExpandMoreIcon}
                      disableUnderline
                      inputProps={{ 'aria-label': intl.formatMessage({ id: 'selectCategory' }) }}
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.code} value={category}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button
                    size="large"
                    onClick={clearFilters}
                    className={classes.clearFiltersBtn}
                    aria-label={intl.formatMessage({ id: 'clearFilters' })}
                  >
                    <FormattedMessage id="clearFilters" />
                  </Button>
                </Box>
                {/* END: CATEGORY FILTER */}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.otherFilters}>
        <Box className={classes.otherFiltersContent}>
          <Typography className={classes.totalCount}>
            <FormattedMessage id="resultsInBrackets" values={{ count: totalCount }} />
          </Typography>
          <List className={classes.list}>
            {mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT
              ? Constant.CAREER_ZONES.map((zone) => (
                  <ListItem
                    key={zone.value}
                    button
                    onClick={() => {
                      onZoneChange(zone.value);
                    }}
                    className={[classes.zoneItem, selectedZone === zone.value ? classes.activeZone : ''].join(' ')}
                    aria-label={intl.formatMessage({ id: 'filertByZone' }, { zone: zone.value })}
                  >
                    <FormattedMessage id="zone" /> {zone.value}
                  </ListItem>
                ))
              : alphabeticalGroups.map((group) => (
                  <ListItem
                    key={group.group}
                    button
                    onClick={() => {
                      onGroupChange(group.group);
                    }}
                    className={[classes.zoneItem, selectedGroup === group.group ? classes.activeZone : ''].join(' ')}
                    aria-label={intl.formatMessage({ id: 'filertByAlphabeticalGroup' }, { group: group.group })}
                  >
                    {group.group}
                  </ListItem>
                ))}
            <ListItem
              button
              id="career-zone-info-btn"
              onClick={() => {
                setShowDialog(true);
              }}
              className={classes.zoneInfoBtn}
              aria-label={intl.formatMessage({ id: 'zoneInfo' })}
            >
              <InfoOutlinedIcon />
              <FormattedMessage id={mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT ? 'info' : 'zoneInfo'} />
            </ListItem>
          </List>
        </Box>
        <Box className={classes.viewContent}>
          <List className={classes.list}>
            <ListItem
              button
              data-cy-name="careers-compact-view"
              id="careers-compact-view"
              onClick={() => {
                props.handleViewChange('compact');
              }}
              className={[classes.viewItem, selectedView === 'compact' ? classes.activeView : ''].join(' ')}
              aria-label={intl.formatMessage({ id: 'compactView' })}
            >
              <FilterListIcon />
              <FormattedMessage id="compactView" />
            </ListItem>
            <ListItem
              button
              data-cy-name="careers-compact-view"
              id="careers-compact-view"
              onClick={() => {
                props.handleViewChange('detailed');
              }}
              className={[classes.viewItem, selectedView === 'detailed' ? classes.activeView : ''].join(' ')}
              aria-label={intl.formatMessage({ id: 'detailedView' })}
            >
              <ReorderIcon />
              <FormattedMessage id="detailedView" />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FiltersArea);
