import React, { Suspense } from 'react';
import { Grid, withStyles, Paper, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import styles from './messaging.style';
import { Permission } from '../../../../core';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { MessagingService } from './../../services/';
import withUnleash from '../../../../core/components/unleash/withUnleash';

const Sidebar = React.lazy(() => import('../../components/message-sidebar'));
const MessagesViewer = React.lazy(() => import('../../components/messages-viewer'));

class Messaging extends React.Component {
  constructor() {
    super();
    this.state = {
      contacts: [],
      selectedContact: null,
      newSelectedContact: null,
      location: {
        locationId: null,
      },
      newMessage: false,
      isAnnouncement: false,
      characterLimit: {
        messagingMinCharacterLimit: 30,
        messagingMaxCharacterLimit: 32000,
        messagingMaxRowLimit: 10,
      },
      threadType: '',
      showErrorMsg: false,
      errorMsg: '',
    };
  }
  _service = new MessagingService();

  setSelectedContact = (contact) => {
    this.setState({
      newSelectedContact: null,
      selectedContact: contact,
      newMessage: false,
      isAnnouncement: false,
    });
  };

  setContacts = (contacts) => {
    this.setState({
      contacts,
    });
  };

  setSelectedContactForNew = (contact) => {
    this.setState((prevState) => ({
      newSelectedContact: contact,
      selectedContact: contact || prevState.selectedContact,
    }));
  };

  getCharacterLimit = () => {
    this._service
      .fetchCharacterLimit()
      .then((_successLog) => {
        this.setState({ characterLimit: _successLog });
      })
      .catch((_errorLog) => {
        console.log(_errorLog);
      });
  };

  componentDidMount() {
    this.getCharacterLimit();
  }

  onSend = async (text) => {
    try {
      const sendResponse = await this._service.postMessage({
        body: text,
        to_person_key: this.state.newMessage
          ? this.state.newSelectedContact.contactId
          : this.state.selectedContact.contactId,
        to_location_key: this.state.location.locationId,
        is_announcement: false,
      });

      const contacts = await this._service.fetchContacts(this.props.unleashProps.isFlagEnabled());
      const selectedContact = contacts.find((contact) => contact.contactId === sendResponse.recipientId);
      if (selectedContact) {
        this.setSelectedContact(selectedContact);
      }
      return sendResponse;
    } catch (err) {
      switch (err.response.status) {
        case 429:
          this.setState({
            showErrorMsg: true,
            errorMsg: <FormattedMessage id="messageLimitError" />,
          });
          break;
        default:
          this.setState({
            showErrorMsg: true,
            errorMsg: <FormattedMessage id="sendMessageFailed" />,
          });
          break;
      }
      return null;
    }
  };

  onSearch = async (key) => {
    return await this._service.fetchSearchLearner(key, this.state.location.locationId);
  };

  onDirectMessage = () => {
    this.setState({
      newMessage: true,
      selectedContact: {
        contactId: 0,
        contactName: <FormattedMessage id="newMessage" />,
      },
      newSelectedContact: null,
    });
  };

  onAnnouncement = () => {
    this.setState({
      newMessage: false,
      selectedContact: {
        isAnnouncement: true,
        contactName: <FormattedMessage id="announcement" />,
      },
    });
  };

  onMessageTab = () => {
    this.setState({
      newMessage: false,
      selectedContact: {
        contactId: 0,
        contactName: <FormattedMessage id="directMessages" />,
      },
    });
  };

  handleCloseErrorMsg = () => {
    this.setState({
      showErrorMsg: false,
      errorMsg: '',
    });
  };

  onThreadSelect = (type) => {
    this.setState({
      threadType: type,
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedContact, newSelectedContact, contacts } = this.state;
    return (
      <Permission {...this.props}>
        <Suspense fallback={<CircularProgress />}>
          <Snackbar
            open={this.state.showErrorMsg}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={this.handleCloseErrorMsg}
          >
            <MuiAlert severity="error" elevation={6} variant="filled">
              {this.state.errorMsg}
            </MuiAlert>
          </Snackbar>
          <Grid container className={classes.container}>
            <Paper elevation={1} className={classes.chatContainer}>
              <Grid item sm={4} md={3}>
                <Sidebar
                  location={this.state.location}
                  onSelect={this.setSelectedContact}
                  selectedContact={selectedContact}
                  onThreadSelect={this.onThreadSelect}
                  onDirectMessage={this.onDirectMessage}
                  onMessageTab={this.onMessageTab}
                  onAnnouncement={this.onAnnouncement}
                  contacts={contacts}
                  setContacts={this.setContacts}
                  isV2FlagEnabled={this.props.unleashProps.isFlagEnabled()}
                />
              </Grid>
              <Grid item sm={8} md={9}>
                <MessagesViewer
                  selectedContact={selectedContact}
                  newSelectedContact={newSelectedContact}
                  onSend={this.onSend}
                  showErrorMsg={this.state.showErrorMsg}
                  characterLimit={this.state.characterLimit}
                  newMessage={this.state.newMessage}
                  onSearch={this.onSearch}
                  onSelectedContact={this.setSelectedContactForNew}
                  contacts={contacts}
                />
              </Grid>
            </Paper>
          </Grid>
        </Suspense>
      </Permission>
    );
  }
}

export default withStyles(styles)(withUnleash(Messaging, UnleashService.FLAGS.USE_V2_THREADS));
