export default (theme) => ({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: '10px',
  },
  card: {
    borderRadius: '10px',
  },
  cardLayout: {
    width: '100%',
    minHeight: 370,
    [theme.breakpoints.down('sm')]: {
      minHeight: 420,
    },
  },
  contentArea: {
    padding: 20,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
    background: 'url(https://content.connectedcorrections.com/assets/img/apds-podcast-bg.svg) no-repeat center center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.aqua.extraDeep,
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  boxTitle: {
    ...theme.typography.t24,
    letterSpacing: '0px',
    lineHeight: '32px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    fontWeight: 700,
  },
  boxDescription: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    overflow: 'hidden',
  },
  actionBtn: {
    marginTop: 20,
    ...theme.typography.bold,
    textTransform: 'capitalize',
  },
});
