import React from 'react';
const { useTheme } = require('@material-ui/core');
const { PillButton } = require('@orijinworks/frontend-commons');

const PillShapedButton = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <PillButton theme={theme} {...props}>
      {children}
    </PillButton>
  );
};

export default PillShapedButton;
