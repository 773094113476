export default theme => ({
  logoText: {
    color: theme.palette.primary.white,
    fontWeight: theme.typography.medium.fontWeight,
    position: "absolute",
    left: -5,
    backgroundColor: theme.palette.primary.main,
    top: 13,
    fontSize: theme.typography.t12.fontSize
  },
  logo: {
    margin: "0 auto",
    maxWidth: "100%",
    display: "block"
  }
});
