export default theme => ({
  chatTopBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "17px 20px 10px 40px",
    borderBottom: "1.2px solid rgba(0,0,0,0.12)"
  },
  chatInfo: {
    display: "flex",
    alignItems: "center"
  },
  chatIcon: {
    color: "#737982",
    marginRight: 18,
    marginBottom: 9
  },
  chatAreaContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  attornyIcon: {
    color: "#737982",
    marginRight: 18,
    marginBottom: 9,
    cursor: "pointer"
  },
  attorneyPriviledgeIcon: {
    fontSize: 15,
    marginLeft: 6
  },
  userName: {
    display: "inline",
    marginRight: 10,
    color: "rgba(0, 0, 0, 0.86)"
  },
  snackBarStyling: {
    display: "flex",
    flex: 1,
    width: "98%",
    position: "absolute",
    top: -13,
    marginLeft: 10,
    marginRight: 10,
    zIndex: 5
  },
  snackRoot: {
    left: 515,
    top: 137,
    display: "flex"
  },
  userInfo: {
    color: "#737982",
    fontSize: 14
  },
  detailWrapper: {
    display: "flex",
    alignItems: "center"
  },
  detailText: {
    color: "#5d75d5",
    fontSize: 16,
    textTransform: "uppercase",
    marginLeft: 8
  },
  attorneyClientPrivileged: {
    display: "flex",
    opacity: 0.7
  },
  close: {
    padding: 0
  },
  placeholder: {
    flex: 1
  },
  attorneyMessage: {
    flex: 1
  }
});
