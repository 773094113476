import { useEffect } from 'react';
import { useFlag, useFlagsStatus, useUnleashClient, useUnleashContext, useVariant } from '@unleash/proxy-client-react';
import { fetchDeviceGroup, fetchUserIp } from '../../../v2/core/auth/utils';

/**
 * @name useUnleashHook
 * @param {string} flagName
 * @param {string} userName
 * @param {string} userId
 * @param {object} selectedLocation
 * @returns {object} - { isFlagEnabled, getClient, getVariant, getFlagsStatus, getUnleashContextSetter }
 */

const useUnleashHook = (flagName, userName, userId = 0) => {
  const updateContext = useUnleashContext();
  const enabled = useFlag(flagName);
  const unleashClient = useUnleashClient();
  const variant = useVariant(flagName);
  const { flagsReady, flagsError } = useFlagsStatus();

  useEffect(() => {
    const setUnleashContext = async () => {
      try {
        const promises = [fetchUserIp(), fetchDeviceGroup()];
        const [ipAddress, { deviceGroup, deviceName }] = await Promise.all(promises);
        await updateContext({
          ipAddress,
          userId: parseInt(userId),
          username: userName,
          deviceGroupName: deviceGroup?.name,
          deviceGroupPath: deviceGroup?.path,
          deviceName,
        });
      } catch (err) {
        console.error(err);
      }
    };

    setUnleashContext();
  }, []);

  const isFlagEnabled = () => enabled;
  const getVariant = () => variant;
  const getClient = () => unleashClient;
  const getFlagsStatus = () => ({ flagsReady, flagsError });
  const getUnleashContextSetter = () => updateContext;

  return {
    isFlagEnabled,
    getClient,
    getVariant,
    getFlagsStatus,
    getUnleashContextSetter,
  };
};

export default useUnleashHook;
