import React from "react";

const JusticeScalesBlue = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    {...props}
  >
    <defs>
      <filter id="x3y07fb0la">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path id="s40jd8qyfb" d="M0 0L23 0 23 24 0 24z" />
    </defs>
    <g fill="#21458c" fill-rule="evenodd" transform="translate(-1)">
      <g transform="translate(1)">
        <mask id="zur2hr2ayc" fill="#fff">
          <use href="#s40jd8qyfb" />
        </mask>
        <path
          fill="#21458C"
          d="M17.62 8.003l1.41 4.4h-2.82l1.41-4.4zm-8.346-2.42c0-.953.779-1.723 1.743-1.723.965 0 1.744.77 1.744 1.723 0 .954-.78 1.724-1.744 1.724s-1.743-.77-1.743-1.724zm-3.45 6.82h-2.82l1.41-4.4 1.41 4.4zm14.764 0l-2.04-6.343c.11-.147.185-.293.185-.477 0-.403-.334-.733-.742-.733h-3.858c-.297-1.173-1.224-2.09-2.374-2.347v-.77c0-.403-.334-.733-.742-.733s-.742.33-.742.733v.77c-1.187.294-2.114 1.21-2.374 2.347H4.043c-.408 0-.742.33-.742.733 0 .184.075.367.186.477l-2.04 6.343H.742c-.408 0-.742.33-.742.734C0 15.52 1.966 17.5 4.414 17.5c2.449 0 4.415-1.943 4.415-4.363 0-.404-.334-.734-.742-.734h-.705L5.416 6.317h2.448c.297 1.173 1.224 2.09 2.374 2.346v11.31H5.416c-.408 0-.742.33-.742.733v1.56c0 .404.334.734.742.734H16.58c.408 0 .742-.33.742-.733v-1.524c0-.403-.334-.733-.742-.733H11.76V8.7c1.187-.293 2.114-1.21 2.374-2.347h2.448l-1.966 6.087h-.704c-.409 0-.742.33-.742.733 0 2.384 1.966 4.364 4.414 4.364 2.448 0 4.414-1.944 4.414-4.364.037-.44-.297-.77-.705-.77h-.704z"
          mask="url(#zur2hr2ayc)"
        />
      </g>
    </g>
  </svg>
);

export default JusticeScalesBlue;
