import * as store from './';

const initialState = {
  activeCommunicationMode: 'MESSAGES',
  contacts: [],
  selectedContact: null,
  selectedThread: null,
  forceReloadMessageThreads: false,
  markSelectedThreadAsRead: false,
  fetchNewMessages: false,
  unreadMessagesCountLimitReached: false,
};

export const staffMessagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.SET_ACTIVE_COMMUNICATION_MODE:
      return {
        ...state,
        activeCommunicationMode: action.mode,
      };
    case store.SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
      };
    case store.SET_SELECTED_THREAD:
      return {
        ...state,
        selectedThread: action.thread,
      };
    case store.SET_SELECTED_CONTACT:
      return {
        ...state,
        selectedContact: action.contact,
      };
    case store.SET_FORCE_RELOAD_MESSAGE_THREADS:
      return {
        ...state,
        forceReloadMessageThreads: action.forceReload,
      };
    case store.SET_MARK_SELECTED_THREAD_AS_READ:
      return {
        ...state,
        markSelectedThreadAsRead: action.markAsRead,
      };
    case store.SET_FETCH_NEW_MESSAGES:
      return {
        ...state,
        fetchNewMessages: action.fetchNewMessages,
      };
    case store.SET_UNREAD_MESSAGES_COUNT_LIMIT_REACHED:
      return {
        ...state,
        unreadMessagesCountLimitReached: action.limitReached,
      };
    default:
      return state;
  }
};
