import React from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashUserProvider } from './';

/**
 * @name UnleashProvider
 * @param {*} unleashParams
 * @desc This is the higher order component responsible to
 * check the feature flag & turn it on / off respectively.
 * @return {JSX} component
 */
const UnleashProvider = ({ flagName, enabledRenderFn = () => null, disabledRenderFn = () => null }) => {
  const renderedJSX = useFlag(flagName) ? enabledRenderFn() : disabledRenderFn();

  return <UnleashUserProvider>{renderedJSX}</UnleashUserProvider>;
};

export default UnleashProvider;
