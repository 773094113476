export default (theme) => ({
  moduleContainer: {
    margin: '15px 0px',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '25px 50px',
    alignItems: 'center',
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  },
  moduleItem: {
    display: 'flex',
  },
  moduleNameContainer: {
    flex: 1,
  },
  moduleText: {
    color: theme.palette.primary.active,
    textTransform: 'uppercase',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  launchBtn: {
    width: 100,
    textTransform: 'capitalize',
    fill: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  disableButton: {
    pointerEvents: 'none',
    color: theme.palette.text.sub,
    fill: theme.palette.text.sub,
  },
});
