export default (theme) => ({
  filterWrapper: {
    width: 350,
    padding: '10px 20px',
  },
  accordionRoot: {
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
  },
  checkBox: {
    color: theme.palette.blue?.primary + ' !important',
  },
  accordionSummaryRoot: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      color: theme.palette.blue?.primary + ' !important',
    },
  },
  accordionDetails: {
    padding: 0,
    flexDirection: 'column',
  },
});
