export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    '& > svg': {
      transform: 'translateY(-2px)',
    },
  },
  text: {
    textTransform: 'uppercase',
  },
  bestFit: {
    color: theme.palette.orange.dark,
  },
  greatFit: {
    color: theme.palette.green.main,
  },
  goodFit: {
    color: theme.palette.yellow.main,
  },
});
