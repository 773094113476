export default (theme) => ({
  root: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    borderRadius: 12,
  },
  activeModule: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.pale,
  },
  disabledModule: {
    border: `1px solid ${theme.palette.greyScale[70]}`,
    backgroundColor: theme.palette.greyScale[20],
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    flex: '1 0 0',
  },
  title: {
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.greyScale[110],
  },
  subtitle: {
    color: theme.palette.primary.jetBlack,
  },
});
