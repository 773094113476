import { marginBottom, marginLeft } from 'styled-system';

const styles = (theme) => ({
  modalContent: {
    textAlign: 'left',
    marginLeft: '10px',
  },
  modalTitle: {
    color: 'var(--Navy---navy-main)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.32px',
  },
  modalDescription: {
    color: 'var(--Greyscale---grey-110, #333)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: ' 0.32px',
  },

  callCenterSection: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '39px',
  },
  callCenterTitle: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  scheduleGrid: {
    padding: '0px 32px',
  },
  dayOfWeek: {
    color: 'var(--orijin-cerulean-main, #0096CA)',
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '1.8px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  timeSlot: {
    color: 'var(--Greyscale---grey-100, #4F5154)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontHeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.32px',
  },
  scheduleRow: {
    marginBottom: '20px',
  },
});

export default styles;
