import { useEffect, useState } from 'react';
import { ContactRequestConstants, endOutBoundCall } from '../../services';

const useCallingModalHook = (props, meetingManager) => {
  const [isMute, setisMute] = useState(false);
  const handleCallingModalClose = () => {
    props.setisCallingModalOpen(false);
  };
  useEffect(() => {
    if (!props.isCallingModalOpen) {
      props.setCallStatus(ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CALLING);
      setisMute(false);
    }
  }, [props.isCallingModalOpen]);
  const onEndCallClick = async () => {
    const response = endOutBoundCall(props.transactionId, props.meetingId);
    const isStopped = meetingManager.meetingSession?.audioVideo?.stop();
    await meetingManager.leave();

    props.setisCallingModalOpen(false);
  };
  const onMuteUnmuteCall = () => {
    const isMuted = meetingManager.meetingSession?.audioVideo?.realtimeIsLocalAudioMuted();
    if (isMuted) {
      const muted = meetingManager.meetingSession?.audioVideo?.realtimeUnmuteLocalAudio();
      setisMute(false);
    } else {
      const unmuted = meetingManager.meetingSession?.audioVideo?.realtimeMuteLocalAudio();
      setisMute(true);
    }
  };

  return {
    onEndCallClick,
    handleCallingModalClose,
    onMuteUnmuteCall,
    isMute,
  };
};
export default useCallingModalHook;
