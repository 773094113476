export default () => ({
  root: {
    marginTop: 55,
    marginBottom: 20,
    width: '100%',
  },
  title: {
    marginBottom: 20,
    fontWeight: 700,
  },
});
