import React from 'react';
import { Box, Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import { connect } from 'react-redux';

import useBookmarksListHook from './useBookmarksListHook';
import styles from './bookmarks-list.style';
import { BackButton, Constant } from '../../../../../shared';
import { CareerItem } from '../../../../../content/careers/components';
import { useDocumentTitleHook } from '../../../../shared';

const Bookmarks = (props) => {
  const { classes } = props;
  const {
    careers,
    totalCareers,
    pageSize,
    currentPage,
    scrollRef,
    getJobZone,
    onCareerClick,
    handlePageChange,
    goToCareerDirectory,
    goBack,
  } = useBookmarksListHook(props);
  useDocumentTitleHook('page.title.bookmarks');

  return (
    <Box>
      <BackButton onClick={goBack} />

      <Grid sm={6} className={classes.textContainer}>
        <Typography id="bookmarks-description" className={classes.text}>
          <FormattedMessage id="bookmarks.text.description" />
        </Typography>
      </Grid>

      <Divider className={classes.divider} />

      <Box>
        <Typography id="bookmarked-careers-title" variant="h3" color="secondary" gutterBottom className={classes.mainTitle}>
          <FormattedMessage id="careers" />
        </Typography>
        {careers.length > 0 ? (
          <Box width="100%" marginTop="30px">
            <Box ref={scrollRef} />
            {careers.map((career) => (
              <CareerItem
                key={career.code}
                title={career.title}
                subtitle={career.what_they_do}
                zone={getJobZone(career)}
                showZoneBadge={true}
                showMedianPay={false}
                onItemClick={(e) => {
                  onCareerClick(e, career.code);
                }}
                isBookmarked={true}
                code={career.code}
              />
            ))}
          </Box>
        ) : (
          <>
            <Grid sm={6} className={classes.textContainer}>
              <Typography id="bookmarks-no-careers-title" className={classes.text}>
                <FormattedMessage id="bookmarks.text.noCareers" />
              </Typography>
            </Grid>
            <Button
              id="bookmarks-go-to-career-directory-btn"
              color="secondary"
              className={classes.actionBtn}
              onClick={goToCareerDirectory}
              tracking-type={Constant.TRACKING_TYPES.CAREER_DIRECTORY}
              tracking-id="view-careers"
            >
              <FormattedMessage id="goToCareerDirectory" />.
            </Button>
          </>
        )}
      </Box>

      <Grid container className={classes.footer}>
        <Grid item sm={12} md={12} lg={4} />
        <Grid item sm={12} md={12} lg={4} className={classes.paginationContainer}>
          {totalCareers > pageSize && (
            <Pagination
              count={Math.ceil(totalCareers / pageSize)}
              page={currentPage + 1}
              siblingCount={0}
              onChange={handlePageChange}
              shape="rounded"
              color="secondary"
              size="large"
            />
          )}
        </Grid>
        <Grid container item sm={12} md={12} lg={4} />
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ locale }) => {
  return {
    selectedLocale: locale.locale,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Bookmarks));
