export default theme => ({
	root: {},
	textContainer: {
		maxWidth: 485
	},
	text: {
		color: theme.palette.text.sub
	},
	mainTitle: {
		textTransform: "uppercase",
		fontWeight: 700
	},
	divider: {
		width: "100%",
		margin: "25px 0px"
	},
	paginationContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		bottom: 0,
		margin: "30px 0px"
	},
	footer: {
		display: "flex",
		flexDirection: "row-reverse"
	},
	actionBtn: {
		...theme.typography.t18,
		marginTop: 10,
		paddingLeft: 0,
		textTransform: "capitalize",
		textDecoration: "underline"
	}
});
  