import React from 'react';
import { Box, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import styles from './compose-message-header.style';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import useComposeMessageHeaderHook from './useComposeMessageHeaderHook';
import { Autocomplete } from '@material-ui/lab';

const ComposeMessageHeader = (props) => {
  const { classes } = props;
  const { contacts, selectedContact, handleContactChange } = useComposeMessageHeaderHook(props);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography id="compose-message-header-title" variant="h4" className={classes.headerText}>
          <FormattedMessage id="newMessage" />
        </Typography>
      </Box>
      <Box className={classes.contactsContainer}>
        <Grid container className={classes.contactsWrapper}>
          <Grid item sm={6} className={classes.centerContainer}>
            <Typography id="compose-message-header-to" variant="h4" className={classes.text}>
              <FormattedMessage id="to" />:
            </Typography>
            <Autocomplete
              id="compose-message-header-contacts"
              size="small"
              fullWidth
              options={contacts}
              getOptionLabel={(option) => option.contactName}
              renderInput={(params) => (
                <TextField {...params} label={<FormattedMessage id="selectContact" />} variant="outlined" />
              )}
              value={selectedContact}
              onChange={handleContactChange}
              noOptionsText={<FormattedMessage id="noContacts" />}
              openOnFocus
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  staffMessaging: state.staffMessaging,
});

export default connect(mapStateToProps)(withStyles(styles)(ComposeMessageHeader));
