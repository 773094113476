import React from 'react';
import { Box, Typography, withStyles, IconButton } from '@material-ui/core';
import ContactRelationshipIcons from '../contact-relationship-icons/contact-relationship-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './private-contacts-calling.style';
import { Utility } from '../../../../shared/services';
import { DialIcon, CallClosedIcon } from '../../../../shared';

const relationshipTypeLabel = {
  GENERAL_PRIVILEGED_CONTACT: 'GENERAL PRIVILEGED CONTACT',
  ATTORNEY: 'ATTORNEY',
  MEDICAL: 'MEDICAL',
};

const PrivateContactsCalling = (props) => {
  const { classes, isClosed } = props;
  const intl = useIntl();

  return (
    <>
      <Typography id="private-contacts-heading" variant="h3" className={classes.approvedContactHeader}>
        <FormattedMessage id="privlieged-contacts-heading" />
      </Typography>

      {props.privateContacts?.map((contact, index) => (
        <Box id={`private-contacts-${index}`} key={index} className={classes.contactContainer}>
          <Box>{contact?.relationshipType && <ContactRelationshipIcons relationshipType={contact.relationshipType} />}</Box>
          <Box className={classes.contactInfoContainer}>
            <Typography id={`name-${index}`} variant="h5" className={classes.contactName}>
              {Utility.getFullName(contact.firstName, contact.lastName)}
            </Typography>
            <Typography id={`relationshipType-${index}`} variant="overline" className={classes.contactRelationship}>
              {relationshipTypeLabel[contact.relationshipType]}
            </Typography>
            <Typography id={`contactNumber-${index}`} variant="body" className={classes.contactNumber}>
              Contact: {contact.phoneNumber}
            </Typography>
          </Box>
          <IconButton
            id={`callButton-${index}`}
            className={classes.dialIconButton}
            onClick={() => props.onCallClick(contact)}
            disabled={isClosed}
          >
            {props.isClosed ? <CallClosedIcon /> : <DialIcon fill="#0F48B8" />}
          </IconButton>
        </Box>
      ))}
    </>
  );
};

export default withStyles(styles)(PrivateContactsCalling);
