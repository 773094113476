import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './select-next-program.styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FormattedMessage, useIntl } from 'react-intl';
import { Constant } from '../../../../../shared';

const SelectNextProgram = ({ classes, onContinue }) => {
  const intl = useIntl();
  return (
    <Box className={classes.root}>
      <Button
        color="secondary"
        className={classes.button}
        tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
        tracking-id="career-compass-select-next-program-cta"
        aria-label={intl.formatMessage({ id: 'career-compass.program-select-next.title' })}
        role="link"
        endIcon={<ChevronRightIcon />}
        classes={{ endIcon: classes.endIcon }}
        data-testid="career-compass-select-next-program-cta"
        onClick={onContinue}
      >
        <Box className={classes.content}>
          <Typography data-testid="career-compass-select-next-program-title" variant="h6" className={classes.title}>
            <FormattedMessage id="career-compass.program-select-next.title" />
          </Typography>
          <Typography data-testid="career-compass-select-next-program-subtitle" variant="body2" className={classes.subtitle}>
            <FormattedMessage id="career-compass.program-select-next.subtitle" />
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

SelectNextProgram.propTypes = {
  classes: PropTypes.object.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(SelectNextProgram);
