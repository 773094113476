import { Http } from '../../../../core';
import { Constant } from '../../../../shared';

/**
 * @name getCareerDirectory
 * @param {number} from
 * @param {number} size
 * @param {string} keyword
 * @param {number} jobZone
 * @desc Fetches list of careers based upon paginated
 * params.
 * @return {Promise}
 */
const getBookmarkedCareers = (from, size) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.post(`/services/career-directory/v1/${Constant.ROUTES.CAREERS}/bookmarks?noLoader=true`, {
      from,
      size,
    })
      .then((_successLog) => {
        resolve(_successLog.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getBookmarkedCareers };
