import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import styles from './loader.style';

class Loader extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress size={100} thickness={2} aria-label="loading" />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

export default connect(mapStateToProps)(withStyles(styles)(Loader));
