import React, { Suspense } from 'react';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { useDocumentTitleHook } from '../../../../v2/shared';
import { CircularProgress } from '@material-ui/core';

const ExploreListV1 = React.lazy(() => import('./explore-list-v1'));
const ExploreListV2 = React.lazy(() => import('../../../../v2/content').then((module) => ({ default: module.ExploreList })));

const ExploreList = (props) => {
  const { unleashProps } = props;
  useDocumentTitleHook('page.title.explore');

  return (
    <Suspense fallback={<CircularProgress />}>
      {unleashProps.isFlagEnabled() ? <ExploreListV2 {...props} /> : <ExploreListV1 {...props} />}
    </Suspense>
  );
};

export default withUnleash(ExploreList, UnleashService.FLAGS.EXPLORE_PAGE_REDESIGN);
