import React from 'react';

const NoiseCancellationIcon = (props) => {
  const { width, height } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 10.0002H9.33339C9.33339 10.3668 9.03339 10.6668 8.66672 10.6668C8.38005 10.6668 8.12672 10.4868 8.03339 10.2135C7.93339 9.92016 7.76672 9.4935 7.41339 9.14016L6.50672 8.2335C6.18672 7.9135 6.00005 7.46016 6.00005 7.00016C6.00005 6.08016 6.74672 5.3335 7.66672 5.3335C8.47339 5.3335 9.14672 5.90683 9.30005 6.66683H10.6467C10.4801 5.16683 9.20672 4.00016 7.66672 4.00016C6.01339 4.00016 4.66672 5.34683 4.66672 7.00016C4.66672 7.8135 4.99339 8.60683 5.56672 9.18016L6.47339 10.0868C6.58672 10.2002 6.68005 10.3802 6.76672 10.6335C7.04005 11.4468 7.80672 12.0002 8.66672 12.0002C9.76672 12.0002 10.6667 11.1002 10.6667 10.0002Z"
        fill="#0F48B8"
      />
      <path
        d="M9.00005 9.3335C9.55234 9.3335 10.0001 8.88578 10.0001 8.3335C10.0001 7.78121 9.55234 7.3335 9.00005 7.3335C8.44777 7.3335 8.00005 7.78121 8.00005 8.3335C8.00005 8.88578 8.44777 9.3335 9.00005 9.3335Z"
        fill="#0F48B8"
      />
      <path
        d="M2.40005 4.38683L3.45339 5.22683C3.68672 4.84683 3.96672 4.4935 4.28005 4.18016L3.23339 3.34683C2.92005 3.66016 2.64672 4.0135 2.40005 4.38683Z"
        fill="#0F48B8"
      />
      <path
        d="M6.30672 2.94683L5.72672 1.74016C5.30672 1.8935 4.90005 2.08683 4.52672 2.32016L5.10672 3.52683C5.48005 3.28683 5.88005 3.0935 6.30672 2.94683Z"
        fill="#0F48B8"
      />
      <path
        d="M2.99339 6.1735L1.68672 5.8735C1.54672 6.2935 1.44672 6.72683 1.39339 7.1735L2.70005 7.4735C2.74005 7.02016 2.84672 6.58683 2.99339 6.1735Z"
        fill="#0F48B8"
      />
      <path
        d="M13.6001 4.38683C13.3601 4.0135 13.0801 3.66016 12.7667 3.34683L11.7134 4.18683C12.0334 4.50016 12.3067 4.84683 12.5401 5.2335L13.6001 4.38683Z"
        fill="#0F48B8"
      />
      <path
        d="M2.69339 8.52683L1.38672 8.82683C1.44005 9.2735 1.54005 9.7135 1.68005 10.1268L2.99339 9.82683C2.84672 9.4135 2.74005 8.98016 2.69339 8.52683Z"
        fill="#0F48B8"
      />
      <path
        d="M11.4734 2.32016C11.0934 2.08683 10.6934 1.8935 10.2734 1.74016L9.69339 2.94683C10.1201 3.0935 10.5201 3.28683 10.8934 3.52683L11.4734 2.32016Z"
        fill="#0F48B8"
      />
      <path
        d="M8.66672 2.7135V1.36683C8.44672 1.34683 8.22672 1.3335 8.00005 1.3335C7.77339 1.3335 7.55339 1.34683 7.33339 1.36683V2.7135C7.55339 2.68683 7.77339 2.66683 8.00005 2.66683C8.22672 2.66683 8.44672 2.68683 8.66672 2.7135Z"
        fill="#0F48B8"
      />
      <path
        d="M7.33339 13.2868V14.6335C7.55339 14.6535 7.77339 14.6668 8.00005 14.6668C8.22672 14.6668 8.44672 14.6535 8.66672 14.6335V13.2868C8.44672 13.3135 8.22672 13.3335 8.00005 13.3335C7.77339 13.3335 7.55339 13.3135 7.33339 13.2868Z"
        fill="#0F48B8"
      />
      <path
        d="M13.0067 9.82683L14.3201 10.1268C14.4601 9.70683 14.5601 9.2735 14.6134 8.82683L13.3067 8.52683C13.2601 8.98016 13.1534 9.4135 13.0067 9.82683Z"
        fill="#0F48B8"
      />
      <path
        d="M13.3067 7.4735L14.6134 7.1735C14.5601 6.72683 14.4601 6.28683 14.3201 5.8735L13.0067 6.1735C13.1534 6.58683 13.2601 7.02016 13.3067 7.4735Z"
        fill="#0F48B8"
      />
      <path
        d="M11.7201 11.8202L12.7734 12.6602C13.0867 12.3402 13.3601 11.9935 13.6067 11.6202L12.5534 10.7802C12.3134 11.1535 12.0334 11.5068 11.7201 11.8202Z"
        fill="#0F48B8"
      />
      <path
        d="M4.52672 13.6802C4.90672 13.9135 5.30672 14.1068 5.72672 14.2602L6.30672 13.0535C5.88005 12.9068 5.48005 12.7135 5.10672 12.4735L4.52672 13.6802Z"
        fill="#0F48B8"
      />
      <path
        d="M9.69339 13.0535L10.2734 14.2602C10.6934 14.1068 11.1001 13.9135 11.4734 13.6802L10.8934 12.4735C10.5201 12.7135 10.1201 12.9068 9.69339 13.0535Z"
        fill="#0F48B8"
      />
      <path
        d="M2.40005 11.6135C2.64005 11.9868 2.92005 12.3402 3.23339 12.6535L4.28672 11.8135C3.96672 11.5002 3.69339 11.1535 3.46005 10.7668L2.40005 11.6135Z"
        fill="#0F48B8"
      />
    </svg>
  );
};
NoiseCancellationIcon.defaultProps = {
  width: '18px',
  height: '20px',
};

export default NoiseCancellationIcon;
