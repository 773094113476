import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './text-separator.style';

const TextSeparator = ({ classes, text }) => {
  return (
    <Box className={classes.root}>
      <Box className={classes.line} />
      <Typography id="text-separator-text" variant="body2" className={classes.text}>
        {text}
      </Typography>
      <Box className={classes.line} />
    </Box>
  );
};

TextSeparator.propTypes = {
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(TextSeparator);
