import React from 'react';
import { withStyles, Box } from '@material-ui/core';
import styles from './career-path-details.style';
import useCareerPathDetailsHook from './useCareerPathDetailsHook';
import { connect } from 'react-redux';
import { BackButton } from '../../../../shared';
import { CareerPathCategory, CareerPathOutline } from '../../components';
import { useDocumentTitleHook } from '../../../../v2/shared';

const CareerPathDetails = (props) => {
  const { classes } = props;
  useDocumentTitleHook('page.title.career-path-details');
  const { details, careerPathId, loaderApiType, launchCareerProfile, handlePinClick, launchProgram, goBack } =
    useCareerPathDetailsHook(props);

  return (
    <Box className={classes.root}>
      <BackButton onClick={goBack} />

      <CareerPathOutline
        careerPathId={careerPathId}
        title={details.title || ''}
        description={details.descriptionLong || ''}
        thumbnailUriPath={details.thumbnailUriPath || ''}
        pinned={Boolean(details.pinned)}
        programsCompleted={details.programsCompleted || 0}
        programsTotal={details.programCount || 0}
        loaderApiType={loaderApiType}
        launchCareerProfile={launchCareerProfile}
        handlePinClick={handlePinClick}
      />

      {details.categories?.length > 0 && (
        <Box className={classes.categoriesContainer}>
          {details.categories.map((category) => (
            <CareerPathCategory key={category.id} {...category} launchProgram={launchProgram} />
          ))}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({ locale }) => {
  return {
    selectedLocale: locale.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerPathDetails));
