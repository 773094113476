import { Box, Button, Chip, Grid, Typography, withStyles } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import momentTimeZone from 'moment-timezone';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { connect } from 'react-redux';

import * as earnTokenStore from '../../../core/store/earn-tokens';
import { Constant, Utility } from '../../services';
import styles from './notification-dropdown.style';

const NotificationDropdown = (props) => {
  const _utility = new Utility();
  const [isOpen, setOpen] = React.useState(false);
  const [ref, setRef] = React.useState(null);
  const [btnRef, setBtnRef] = React.useState(null);
  const [earnTokenBtn, setEarnTokenBtnRef] = React.useState(null);
  const { classes, cyId } = props;
  const intl = useIntl();

  React.useLayoutEffect(() => {
    if (ref) {
      const windowHandler = (event) => {
        if (isOpen) {
          setOpen(false);
        }
      };

      const earnTokenBtnHandler = (event) => {
        props.toggleEarnTokenDialog({
          showDialogTitle: true,
          serviceId: null,
        });
        setOpen(false);
        event.stopPropagation();
      };

      const elementHandler = (event) => {
        event.stopPropagation();
      };

      const btnHandler = (event) => {
        event.stopPropagation();
        setOpen(!isOpen);
      };

      btnRef.addEventListener('click', btnHandler);
      ref.addEventListener('click', elementHandler);
      if (earnTokenBtn) {
        // CONDITION IN MIDDLE DUE TO EXECUTION LEVEL
        earnTokenBtn.addEventListener('click', earnTokenBtnHandler);
      }
      window.addEventListener('click', windowHandler);

      return () => {
        window.removeEventListener('click', windowHandler);
        ref.removeEventListener('click', elementHandler);
        if (earnTokenBtn) {
          // CONDITION IN MIDDLE DUE TO EXECUTION LEVEL
          earnTokenBtn.removeEventListener('click', earnTokenBtnHandler);
        }
        btnRef.removeEventListener('click', btnHandler);
      };
    }
  });
  return (
    <Grid
      container
      item
      classes={{
        root: classes.root,
      }}
    >
      <Grid item xs={12}>
        <Button
          ref={(ref) => setBtnRef(ref)}
          data-cy-name={`open-${cyId}`}
          color="primary"
          className={[classes.dropDownBtn, props.className, isOpen ? classes.activeBtn : null].join(' ')}
        >
          {props.children}
        </Button>
      </Grid>
      <Grid ref={(ref) => setRef(ref)} item xs={12}>
        <Box
          data-cy-name={cyId}
          component="div"
          display="flex"
          style={{
            height: props.height,
            maxHeight: props.maxHeight,
          }}
          className={[classes.listView, props.className, isOpen ? null : classes.collapseView].join(' ')}
          aria-hidden={!isOpen && 'true'}
        >
          <Grid alignItems="center" justify="space-between" container item className={classes.cardHeadingWrapper}>
            <Box component="div">
              <Typography id="completed-tasks-text" className={[classes.cardHeading].join(' ')}>
                {props.title}
              </Typography>
            </Box>
            {props.isEarnTokenBtnVisible && (
              <Box component="div">
                <Button
                  data-cy-name="earn-token-btn"
                  color="primary"
                  ref={(ref) => setEarnTokenBtnRef(ref)}
                  className={[classes.earnTokenBtn].join(' ')}
                  aria-label={intl.formatMessage({ id: 'earnTokensTitle' })}
                >
                  <FormattedMessage id="earnTokensTitle" />
                </Button>
              </Box>
            )}
          </Grid>
          <Grid container item className={['grey-scrollbar', classes.listContainer, classes.contentGap].join(' ')}>
            {/* BEGIN: ROW */}
            {props.collection.map((row, index) => (
              <Grid key={index} container item className={[classes.listItem, 'course-completed-item'].join(' ')}>
                <Grid container item xs={4} alignItems="center" className={classes.iconWrapper}>
                  <Typography
                    className={[
                      _utility.isTokenAddOrSubtract(row.transactionTypeName) === 'add' ? classes.success : classes.danger,
                    ].join(' ')}
                  >
                    {_utility.isTokenAddOrSubtract(row.transactionTypeName) === 'add' ? '+' : '-'}
                    {row.tokenAmount}
                  </Typography>
                  <StarsIcon className={classes.icon} fontSize="small" />
                </Grid>
                <Grid container item xs={8}>
                  {row.initiatorPersonKey && (
                    <Box component="div" width="100%" display="flex" flexDirection="column">
                      <Typography className={classes.notifTitle}>{row.initiatorPersonName}</Typography>
                      <Typography className={classes.notifSubText}>{row.note}</Typography>
                    </Box>
                  )}
                  {row.featureName && !row.resourceKey && (
                    <Chip
                      style={Constant.CONTENT_AREA[3] ? Constant.CONTENT_AREA[3].chip : null}
                      className={[classes.chip, classes.workChip].join(' ')}
                      label={row.featureName}
                    />
                  )}

                  {row.resourceKey && (
                    <Chip
                      style={
                        Constant.CONTENT_AREA[row.contentAreaKey] ? Constant.CONTENT_AREA[row.contentAreaKey].chip : null
                      }
                      className={[classes.chip, classes.workChip].join(' ')}
                      label={row.resourceName}
                    />
                  )}

                  <Typography className={classes.listItemDateText}>
                    <Moment tz={momentTimeZone.tz.guess()} format="MMM D, YYYY">
                      {row.createdDate}
                    </Moment>
                    &nbsp; at &nbsp;
                    <Moment tz={momentTimeZone.tz.guess()} format="hh:mmA">
                      {row.createdDate}
                    </Moment>
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {/* END: ROW */}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEarnTokenDialog: (props) => dispatch(earnTokenStore.toggleDialog(props)),
  };
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(NotificationDropdown));
