import React from 'react';
import { MuiThemeProvider, ThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { theme as oldTheme } from '../../../../core';
import { theme as newTheme } from '../../services';

const ThemeWrapper = ({ children, useNewTheme }) => {
  if (useNewTheme) {
    return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
  }

  return <MuiThemeProvider theme={new oldTheme()}>{children}</MuiThemeProvider>;
};

const mapStateToProps = ({ app }) => ({
  useNewTheme: app.useNewTheme,
});

export default connect(mapStateToProps)(ThemeWrapper);
