import { useCallback, useEffect, useRef, useState } from 'react';

import { getBookmarkedCareers } from '../../services/bookmarks.service';

const useBookmarksListHook = (props) => {
  const { selectedLocale, history } = props;
  const [careers, setCareers] = useState([]);
  const [totalCareers, setTotalCareers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;
  const scrollRef = useRef();

  const retriveBookmarkedCareers = useCallback(async () => {
    try {
      const data = await getBookmarkedCareers(currentPage * pageSize, pageSize);
      if (data?.payload) {
        const { careers: bookmarkedCareers, total: totalCount } = data.payload;
        if (bookmarkedCareers?.length >= 0) {
          setCareers(bookmarkedCareers);
          setTotalCareers(totalCount);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [currentPage]);

  useEffect(() => {
    retriveBookmarkedCareers();
  }, [selectedLocale, retriveBookmarkedCareers]);

  const handlePageChange = (e, value) => {
    setCurrentPage(value - 1);
  };

  const getJobZone = (career) => {
    let zone = 1;
    if (career?.education?.job_zone) {
      zone = career.education.job_zone;
    }
    return zone;
  };

  const goBack = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  const goToCareerDirectory = (e) => {
    e.stopPropagation();
    history.push('/careers');
  };

  const onCareerClick = (e, id) => {
    e.stopPropagation();
    history.push(`/careers/${encodeURIComponent(id)}/profile`);
  };

  return {
    careers,
    totalCareers,
    pageSize,
    currentPage,
    scrollRef,
    handlePageChange,
    getJobZone,
    goBack,
    goToCareerDirectory,
    onCareerClick,
  };
};

export default useBookmarksListHook;
