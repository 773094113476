export default (theme) => ({
  root: {
    background: 'white',
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    maxWidth: 300,
    maxHeight: 250,
    marginBottom: 20,
  },
  areaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  centeredText: {
    textAlign: 'center',
  },
  content: {
    paddingLeft: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  aboutYourPersonality: {
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  mainTitle: {
    fontWeight: 500,
    textTransform: 'capitalize',
    fontSize: 18,
  },
  description: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  personalityArea: {
    color: theme.palette.primary.active,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  paddingBottom: {
    paddingBottom: 20,
  },
  zoneInfo: {
    marginTop: 30,
  },
  marginTop: {
    marginTop: 10,
  },
  zone1Color: {
    color: theme.palette.jobZone.colors.zone1,
  },
  zone2Color: {
    color: theme.palette.jobZone.colors.zone2,
  },
  zone3Color: {
    color: theme.palette.jobZone.colors.zone3,
  },
  zone4Color: {
    color: theme.palette.jobZone.colors.zone4,
  },
  zone5Color: {
    color: theme.palette.jobZone.colors.zone5,
  },
});
