import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import styles from './learnosity-assessment.style';
import { PDQAssessmentService } from '../../services/pdq-assessment.service';
import { Constant } from '../../../../shared';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';

export class LearnosityAssessment extends React.Component {
  _service = new PDQAssessmentService();
  constructor(props) {
    super(props);
    this.state = {
      openSuccessModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener('message', async (event) => {
      if (event.data && event.data.type === 'pdq-assessment-submitted') {
        await this._service.submitLearnosityAssessment();
        this.setState({ openSuccessModal: true });
      }
    });
  }

  initializeLearnosity = async () => {
    try {
      const options = await this._service.getLearnosityInitOptions();

      const iframe = document.getElementById('learnosity_assessment');
      iframe.contentWindow.postMessage({ options: options.data.payload, type: 'pdq-init-options' }, '*');
    } catch (e) {
      console.log(e);
    }
  };

  handleSuccessModalClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      this.setState({ openSuccessModal: false });
    }
  };

  closeWindow = () => {
    window.close();
  };

  redirectToHomepage = () => {
    this.props.history.push('/home');
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <>
        <Dialog open fullScreen>
          <iframe
            id="learnosity_assessment"
            title="Learnosity Assessment"
            className={classes.root}
            src={Constant.ASSESSMENTS.PDQ.ASSESSMENT_URL}
            onLoad={this.initializeLearnosity}
          />
        </Dialog>

        <Dialog open={this.state.openSuccessModal} onClose={this.handleSuccessModalClose}>
          <Box className={classes.modalContent}>
            <img
              src="https://content.connectedcorrections.com/assets/thumbnails/pdq/Success.png"
              alt="Success"
              className={classes.modalImage}
            />
            <Typography variant="h2" gutterBottom className={classes.modalTitle}>
              <FormattedMessage id="success" />
            </Typography>
            <Typography gutterBottom>
              <FormattedMessage id="pdq-assessment.submitted.message" />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.closeWindow}
              className={classes.modalButton}
              aria-label={intl.formatMessage({ id: 'close' })}
            >
              <FormattedMessage id="close" />
            </Button>
          </Box>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ app }) => app;

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(LearnosityAssessment)));
