export default (theme) => ({
  root: {
    borderRadius: 12,
    padding: 25,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  whiteBg: {
    background: 'white',
  },
  peachBg: {
    background: theme.palette.primary.peach,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    position: 'relative',
  },
  mainImage: {
    maxWidth: 350,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 250,
    },
    maxHeight: 250,
  },
  mainTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  subtitle: {
    fontWeight: 700,
    marginBottom: 10,
    marginTop: 10,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  bold: {
    fontWeight: 700,
  },
  description: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  actionBtn: {
    width: 225,
    textTransform: 'capitalize',
    fontWeight: 700,
    marginTop: 10,
  },
  greyedIcon: {
    fill: theme.palette.text.sub,
  },
  iconContainer: {
    position: 'absolute',
    top: '45%',
    left: '45%',
  },
  personalityArea: {
    color: theme.palette.yellow.deep,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginLeft: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  retakeInterestProfiler: {
    fontWeight: 700,
    marginLeft: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  topBottomMargin: {
    marginBottom: 10,
    marginTop: 10,
  },
  filterArea: {
    display: 'flex',
    marginTop: 10,
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    width: 150,
    border: '1px solid',
    borderColor: theme.palette.colors.borderGrey.light,
    borderRadius: 3,
    padding: 10,
    background: 'white',
    cursor: 'pointer',
  },
  filterText: {
    fontWeight: 700,
    textTransform: 'capitalize',
    marginLeft: 10,
  },
  icon: {
    fill: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioBtn: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.colors.borderGrey.dark,
  },
  careersIntro: {
    marginTop: 30,
  },
});
