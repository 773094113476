import { Card, Grid, Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { Games, Radio, Videos } from '../';
import { PlayMainService } from '../../services/';
import styles from './play-tabs.style';
import { Tracker } from '../../../../core/services/tracker/tracker';

let cacheActiveTabId = null;
const videoTabId = 0,
  gameTabId = 1,
  radioTabId = 2;

const getActiveTab = (status) => {
  const playService = new PlayMainService();
  let activeTabId = null;
  if (status.videos.type !== playService.OFF) {
    activeTabId = videoTabId;
  } else if (status.games.type !== playService.OFF) {
    activeTabId = gameTabId;
  } else if (status.radio.type !== playService.OFF) {
    activeTabId = radioTabId;
  }
  return activeTabId;
};

const getNewTabId = (status) => {
  const playService = new PlayMainService();
  if (cacheActiveTabId === videoTabId && status.videos.type === playService.OFF) {
    if (status.games.type !== playService.OFF) {
      return gameTabId;
    } else if (status.radio.type !== playService.OFF) {
      return radioTabId;
    }
  } else if (cacheActiveTabId === gameTabId && status.games.type === playService.OFF) {
    if (status.videos.type !== playService.OFF) {
      return videoTabId;
    } else if (status.radio.type !== playService.OFF) {
      return radioTabId;
    }
  } else if (cacheActiveTabId === radioTabId && status.radio.type === playService.OFF) {
    if (status.videos.type !== playService.OFF) {
      return videoTabId;
    } else if (status.games.type !== playService.OFF) {
      return gameTabId;
    }
  }
  return null;
};

const navigateTab = (tabId, history) => {
  if (tabId === videoTabId) {
    history.push(Tracker.RouteEnum.entertain.video);
  } else if (tabId === gameTabId) {
    history.push(Tracker.RouteEnum.entertain.game);
  } else if (tabId === radioTabId) {
    history.push(Tracker.RouteEnum.entertain.radio);
  }
};

const navigatePreloadTab = (activeTabID, history) => {
  const currentPathname = window.location.pathname;
  if (activeTabID === videoTabId && currentPathname !== Tracker.RouteEnum.entertain.video) {
    history.push(Tracker.RouteEnum.entertain.video);
  } else if (activeTabID === gameTabId && currentPathname !== Tracker.RouteEnum.entertain.game) {
    history.push(Tracker.RouteEnum.entertain.game);
  } else if (activeTabID === radioTabId && currentPathname !== Tracker.RouteEnum.entertain.radio) {
    history.push(Tracker.RouteEnum.entertain.radio);
  }
};

const PlayTabs = (props) => {
  const playService = new PlayMainService();
  const [activeTab, setActiveTab] = React.useState(getActiveTab(props.status));
  React.useLayoutEffect(() => {
    navigatePreloadTab(activeTab, props.history);
    if (activeTab === null && getActiveTab(props.status) !== null) {
      setActiveTab(getActiveTab(props.status));
    }
  }, [setActiveTab, activeTab, props.status, props.history]);

  // SETTING CACHED TAB ID
  if (cacheActiveTabId !== activeTab) {
    cacheActiveTabId = activeTab;
  }

  // IF THERE'S NEW TAB AVAILABLE
  // THEN SETTING IT AS ACTIVE ALSO
  if (getNewTabId(props.status) !== null && getNewTabId(props.status) !== activeTab) {
    setActiveTab(getNewTabId(props.status));
  }
  // NOTE: USED getNewTabId - METHOD DUE TO JEST LIMITATION

  const { classes } = props;

  const noTabAvailable =
    props.status.videos.type === playService.OFF &&
    props.status.games.type === playService.OFF &&
    props.status.radio.type === playService.OFF;
  if (noTabAvailable) {
    return null;
  }
  return (
    <Grid data-cy-name="play-content-container" container item>
      <Card className={classes.playRoot}>
        <Paper>
          <Tabs
            className={classes.playTabs}
            variant="fullWidth"
            onChange={(event, newVal) => {
              setActiveTab(newVal);
              navigateTab(newVal, props.history);
            }}
            value={activeTab}
            classes={{
              indicator: classes.indicator,
            }}
            aria-label="Play tabs"
          >
            {props.status.videos.type !== playService.OFF && (
              <Tab
                classes={{
                  root: classes.playTabRoot,
                  selected: classes.playTabActive,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon,
                }}
                icon={<PlayCircleOutlineIcon />}
                label={<FormattedMessage id="videos" />}
                value={videoTabId}
                index={videoTabId}
                tabIndex={0}
                data-cy-name="video-tab"
              />
            )}
            {props.status.games.type !== playService.OFF && (
              <Tab
                classes={{
                  root: classes.playTabRoot,
                  selected: classes.playTabActive,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon,
                }}
                icon={<VideogameAssetIcon />}
                label={<FormattedMessage id="games" />}
                value={gameTabId}
                index={gameTabId}
                tabIndex={0}
                data-cy-name="games-tab"
              />
            )}
            {props.status.radio.type !== playService.OFF && (
              <Tab
                classes={{
                  root: classes.playTabRoot,
                  selected: classes.playTabActive,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon,
                }}
                icon={<MusicNoteIcon />}
                label={<FormattedMessage id="radio" />}
                value={radioTabId}
                index={radioTabId}
                tabIndex={0}
                data-cy-name="radio-tab"
              />
            )}
          </Tabs>
        </Paper>
        <Grid className={[classes.cardContent, 'grey-scrollbar'].join(' ')}>
          {activeTab === videoTabId && (
            <Videos
              playSchedule={props.playSchedule}
              updatePlayStatus={props.updatePlayStatus}
              redeem={props.redeem.video}
              status={props.status}
              schedule={props.videoSchedule}
              updateRedeemState={props.updateRedeemState}
              attributes={props.attributes}
            />
          )}
          {activeTab === gameTabId && (
            <Games
              playSchedule={props.playSchedule}
              updatePlayStatus={props.updatePlayStatus}
              redeem={props.redeem.game}
              status={props.status}
              schedule={props.gameSchedule}
              updateRedeemState={props.updateRedeemState}
              attributes={props.attributes}
            />
          )}
          {activeTab === radioTabId && (
            <Radio
              playSchedule={props.playSchedule}
              updatePlayStatus={props.updatePlayStatus}
              redeem={props.redeem.radio}
              status={props.status}
              schedule={props.radioSchedule}
              updateRedeemState={props.updateRedeemState}
              attributes={props.attributes}
            />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(PlayTabs));
