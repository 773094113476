export default (theme) => ({
  root: {
    paddingBottom: 0,
  },
  iconBox: {
    fill: theme.palette.secondary.main,
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    position: 'relative',
  },
  content: {
    width: 'calc(100% - 90px)',
  },
  title: {
    ...theme.typography.t22,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  truncate: {
    '-webkit-line-clamp': 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-word',
  },
  previewText: {
    color: theme.palette.text.sub,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  threadItem: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: 15,
    cursor: 'pointer',
  },
  threadSelected: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.colors.aliceBlue,
    },
  },
  dateContainer: {
    textAlign: 'right',
    maxWidth: 75,
  },
  redDot: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.red,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 75,
  },
});
