import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

/**
 * Component to render the custom dot for the carousel
 *
 * @param {function} onClick - The function to call when the dot is clicked
 * @param {boolean} active - The active state of the dot
 * @param {object} classes - The classes that will be injected by withStyles
 * @param {number} index - The index of the dot
 * @param {object} rest - The rest of the props
 * @returns {React.Component} The CustomDot component
 */
const CustomDot = ({ onClick, ...rest }) => {
  const { active, classes, index } = rest;
  return (
    <Button
      aria-label={`Slide ${index + 1}`}
      className={[classes.dot, active ? classes.activeDot : ''].join(' ')}
      onClick={onClick}
    />
  );
};

CustomDot.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func,
};

export default CustomDot;
