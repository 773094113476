import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useDocumentTitleHook } from '../../../../shared';
import { BiometricAuthRegistration } from './components';
import CaptureImage from './components/capture-image/capture-image';
import useBiometricAuthenticationHook from './useBiometricAuthenticationHook';
import { FormattedMessage } from 'react-intl';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Loader } from '@orijinworks/frontend-commons';

/**
 * Component to handle the Biometric Authentication.
 *
 * @component
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const BiometricAuthentication = (props) => {
  useDocumentTitleHook('page.title.biometric-authentication');
  const { isLoading, shouldRegister, shouldAuthenticate, authenticate } = useBiometricAuthenticationHook(props);

  return (
    <Box>
      <Loader isLoading={isLoading} />
      {shouldRegister() && <BiometricAuthRegistration username={props.username} onComplete={props.onComplete} />}
      {shouldAuthenticate() && (
        <CaptureImage
          instruction={<FormattedMessage id="biometricAuthentication" />}
          startButtonText={<CameraAltIcon fontSize="large" />}
          onProcessImage={authenticate}
        />
      )}
    </Box>
  );
};

BiometricAuthentication.propTypes = {
  /**
   * The username
   */
  username: PropTypes.string.isRequired,
  /**
   * The action to perform. Either 'register' or 'authenticate'
   */
  action: PropTypes.string.isRequired,
  /**
   * The onComplete function
   */
  onComplete: PropTypes.func.isRequired,
  /**
   * The onError function
   */
  onError: PropTypes.func.isRequired,
};

export default BiometricAuthentication;
