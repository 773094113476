import { useState, useEffect } from 'react';
import { getAttributes } from '../../services';
const useVoiceCallHook = (props) => {
  useEffect(() => {
    checkIfVoiceIDRegisterationIsRequired();
  }, []);
  const TAB_VALUES = {
    Contacts: 'Contacts',
    Connections: 'Connections',
    CallHistory: 'Call History',
    VoiceIdRegistration: 'Voice ID',
  };

  const [tabActive, setTabActive] = useState(TAB_VALUES.Contacts);
  const [voiceIDRegistrationRequired, setVoiceIDRegistrationRequired] = useState(false);
  const handletabActive = (event, newTabValue) => {
    setTabActive(newTabValue);
  };

  const checkIfVoiceIDRegisterationIsRequired = () => {
    getAttributes().then((response) => {
      if (response.data) {
        const attribue = response.data.find((attribute) => attribute.attributeName === 'Voice_ID_Registration');
        if (attribue) {
          setVoiceIDRegistrationRequired(attribue.attributeValue === 'true');
        }
      }
    });
  };
  return {
    handletabActive,
    tabActive,
    TAB_VALUES,
    voiceIDRegistrationRequired,
    checkIfVoiceIDRegisterationIsRequired,
  };
};

export default useVoiceCallHook;
