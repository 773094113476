import React from 'react';
import { withStyles, Box, Typography, Button } from '@material-ui/core';

import styles from './careers-intro.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Constant } from '../../../../shared/services';
import { CheckmarkIcon, LockIcon } from '../../../../shared';
import { CAREER_DIRECTORY_FILTERS } from '../../containers/career-directory/constants';

const CareersIntro = (props) => {
  const { classes, ipTaken, ipResults, setMainFilter, mainFilter } = props;
  const intl = useIntl();

  const handleFilterChange = (value) => {
    setMainFilter(value);
  };

  const goToInterestProfiler = () => {
    props.history.push('/interest-profiler');
  };

  return (
    <Box className={[classes.root, ipTaken ? classes.peachBg : classes.whiteBg].join(' ')}>
      <Box className={classes.content}>
        <Typography id="careers-intro-title" variant="h2" gutterBottom className={classes.mainTitle}>
          <FormattedMessage id={ipTaken ? 'interest-profiler-results.title' : 'careers-intro.title'} />
        </Typography>

        {ipTaken && ipResults.final_result && (
          <Box className={classes.centerContainer}>
            <Typography variant="h4" color="secondary" className={[classes.subtitle, classes.capitalize].join(' ')}>
              <FormattedMessage id="personalityResultsTitle" />
            </Typography>
            <Button
              onClick={props.openPersonalityDetailsDialog}
              aria-label={intl.formatMessage({ id: 'viewPersonalityDetails' })}
            >
              <Typography variant="h4" className={classes.personalityArea}>
                {ipResults.final_result.area}
              </Typography>
            </Button>
          </Box>
        )}

        <Typography id="careers-intro-description" className={classes.description}>
          <FormattedMessage id={ipTaken ? 'interest-profiler-results.description' : 'careers-intro.description'} />
        </Typography>

        {ipTaken ? (
          <>
            <Box className={[classes.centerContainer, classes.topBottomMargin].join(' ')}>
              <Typography color="secondary" className={classes.bold}>
                <FormattedMessage id="notFeelingTheResults" />
              </Typography>
              <Button
                onClick={goToInterestProfiler}
                tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
                tracking-id="retake-interest-profiler"
                aria-label={intl.formatMessage({ id: 'retakeInterestProfiler' })}
              >
                <Typography color="secondary" className={classes.retakeInterestProfiler}>
                  <FormattedMessage id="retakeInterestProfiler" />
                </Typography>
              </Button>
            </Box>
            <Box className={classes.filterArea}>
              <Button
                onClick={() => {
                  handleFilterChange(CAREER_DIRECTORY_FILTERS.ALL);
                }}
                className={classes.filterBox}
                aria-label={intl.formatMessage({ id: 'showAllCareers' })}
              >
                {mainFilter === CAREER_DIRECTORY_FILTERS.ALL ? (
                  <Box className={classes.icon}>
                    <CheckmarkIcon size={18} />
                  </Box>
                ) : (
                  <Box className={classes.radioBtn} />
                )}
                <Typography variant="h4" className={classes.filterText}>
                  <FormattedMessage id="showAll" />
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  handleFilterChange(CAREER_DIRECTORY_FILTERS.BEST_FIT);
                }}
                className={classes.filterBox}
                aria-label={intl.formatMessage({ id: 'showBestFitCareers' })}
              >
                {mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT ? (
                  <Box className={classes.icon}>
                    <CheckmarkIcon size={18} />
                  </Box>
                ) : (
                  <Box className={classes.radioBtn} />
                )}
                <Typography variant="h4" className={classes.filterText}>
                  <FormattedMessage id="myBestFit" />
                </Typography>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography
              id="careers-intro-subtitle"
              variant="h3"
              color="secondary"
              className={[classes.subtitle, classes.capitalize, classes.careersIntro].join(' ')}
            >
              <FormattedMessage id="careers-intro.subtitle" />
            </Typography>

            <Button
              id="careers-intro-action-btn"
              variant="contained"
              color="primary"
              className={classes.actionBtn}
              size="large"
              onClick={goToInterestProfiler}
              tracking-type={Constant.TRACKING_TYPES.INTEREST_PROFILER}
              tracking-id="start-interest-profiler"
              aria-label={intl.formatMessage({ id: 'startQuestionnaire' })}
            >
              <FormattedMessage id="startQuestionnaire" />
            </Button>
          </>
        )}
      </Box>

      <Box className={classes.imageContainer}>
        <img
          id="careers-intro-image"
          className={classes.mainImage}
          alt="Interest Profiler Results"
          src={
            ipTaken
              ? 'https://content.connectedcorrections.com/assets/img/IP_result_colored.png'
              : 'https://content.connectedcorrections.com/assets/img/IP_result_greyed_out.png'
          }
        />
        {!ipTaken && (
          <Box className={[classes.iconContainer, classes.greyedIcon].join(' ')}>
            <LockIcon width={45} height={40} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(CareersIntro);
