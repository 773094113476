import React from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, CardMedia, Icon, withStyles } from '@material-ui/core';
import styles from './thumbnail-card.styles';
import { Typography } from '@orijinworks/frontend-commons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

/**
 * Component that renders a thumbnail card.
 *
 * @component
 * @param {object} props - The props of the thumbnail card.
 * @returns {React.Component} The ThumbnailCard component.
 */
const ThumbnailCard = (props) => {
  const {
    classes,
    title,
    subtitle,
    description,
    thumbnailUrl,
    thumbnailType,
    icon,
    autoChangeOrientation,
    orientation,
    showArrow,
    accessibilityProps,
    trackingType,
    trackingId,
    onClick,
  } = props;
  const isVertical = orientation === 'vertical';

  return (
    <ButtonBase
      id="thumbnail-card-root"
      title={`Launch ${title}`}
      aria-label={`Launch ${title}`}
      tracking-type={trackingType}
      tracking-id={trackingId}
      onClick={onClick}
      focusRipple
      className={[
        classes.root,
        autoChangeOrientation ? classes.rootAutoOrientation : isVertical ? classes.rootVertical : '',
      ].join(' ')}
      {...accessibilityProps}
    >
      {thumbnailType === 'icon' ? (
        <Box
          id="thumbnail-card-icon-container"
          className={
            autoChangeOrientation
              ? classes.iconContainerAutoOrientation
              : isVertical
              ? classes.iconContainerVertical
              : classes.iconContainerHorizontal
          }
        >
          <Box className={classes.iconBox}>
            {icon && (
              <Icon id="thumbnail-card-icon" fontSize="large" className={classes.icon}>
                {icon}
              </Icon>
            )}
          </Box>
        </Box>
      ) : (
        ['image', 'video'].includes(thumbnailType) && (
          <Box
            id="thumbnail-card-thumbnail-container"
            className={
              autoChangeOrientation
                ? classes.thumbnailContainerAutoOrientation
                : isVertical
                ? classes.thumbnailContainerVertical
                : classes.thumbnailContainerHorizontal
            }
          >
            {thumbnailUrl && (
              <CardMedia
                id="thumbnail-card-thumbnail"
                component="img"
                alt=""
                src={thumbnailUrl}
                className={
                  autoChangeOrientation
                    ? classes.thumbnailAutoOrientation
                    : isVertical
                    ? classes.thumbnailVertical
                    : classes.thumbnailHorizontal
                }
              />
            )}

            {thumbnailType === 'video' && <PlayCircleFilledIcon className={classes.playIcon} />}
          </Box>
        )
      )}
      <Box
        id="thumbnail-card-content"
        className={[
          classes.content,
          autoChangeOrientation
            ? classes.contentAutoOrientation
            : isVertical
            ? classes.contentVertical
            : classes.contentHorizontal,
        ].join(' ')}
      >
        <Box className={classes.textArea}>
          <Box className={classes.titleContainer}>
            <Typography id="thumbnail-card-title" variant="h4" className={classes.title}>
              {title}
            </Typography>
            {subtitle && (
              <Typography id="thumbnail-card-subtitle" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {description && (
            <Typography id="thumbnail-card-description" variant="body1" className={classes.description}>
              {description}
            </Typography>
          )}
        </Box>
        {showArrow && (
          <Box
            id="thumbnail-card-action-area"
            className={[
              classes.actionArea,
              autoChangeOrientation ? classes.actionAreaAutoOrientation : isVertical ? classes.actionAreaVertical : '',
            ].join(' ')}
          >
            <ChevronRightIcon fontSize="large" className={classes.icon} />
          </Box>
        )}
      </Box>
    </ButtonBase>
  );
};

ThumbnailCard.defaultProps = {
  thumbnailType: 'image',
  autoChangeOrientation: true,
  showArrow: true,
};

ThumbnailCard.propTypes = {
  /**
   * The classes injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The title of the thumbnail card.
   */
  title: PropTypes.string.isRequired,
  /**
   * The subtitle of the thumbnail card.
   */
  subtitle: PropTypes.string,
  /**
   * The description of the thumbnail card.
   */
  description: PropTypes.string,
  /**
   * The URL of the thumbnail. This is only used when the thumbnail type is 'image' or 'video'.
   */
  thumbnailUrl: PropTypes.string,
  /**
   * The type of the thumbnail.
   */
  thumbnailType: PropTypes.oneOf(['image', 'video', 'icon']),
  /**
   * The icon. This is only used when the thumbnail type is 'icon'.
   */
  icon: PropTypes.string,
  /**
   * Whether the orientation of the thumbnail card should change based on the screen size.
   */
  autoChangeOrientation: PropTypes.bool,
  /**
   * The orientation of the thumbnail card. This is only used when `autoChangeOrientation` is false.
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Whether to show the arrow.
   */
  showArrow: PropTypes.bool,
  /**
   * The accessibility props of the thumbnail card.
   */
  accessibilityProps: PropTypes.object,
  /**
   * The tracking type for pendo.
   */
  trackingType: PropTypes.string.isRequired,
  /**
   * The tracking ID for pendo.
   */
  trackingId: PropTypes.string.isRequired,
  /**
   * The function to call when the thumbnail card is clicked.
   */
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ThumbnailCard);
