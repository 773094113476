import { Box, Button, Card, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { KeyIcon, RedirectIcon } from '../../icons';

import { Constant } from '../../services';
import styles from './thumbnail-card.style';

const ThumbnailCard = (props) => {
  const { classes } = props;
  const intl = useIntl();

  return (
    <Box
      onClick={() => {
        props.handleLaunchClick();
      }}
      className={classes.thumbnailLink}
      rel="noopener noreferrer"
      data-cy-name={`explore-card-${props.id}`}
      tracking-type={Constant.TRACKING_TYPES.SERVICE}
      tracking-id={`launch-service-${props.id}`}
      title={`Launch ${props.name}`}
      aria-label={`Launch ${props.name}`}
      role="link"
    >
      <Card data-cy-name="service-card" className={classes.card}>
        <Box
          className={classes.thumbnailImage}
          style={{
            background: props.thumbnailUri ? `url("${props.thumbnailUri}") center / cover` : '',
          }}
        />
        <Box display="flex" className={classes.cardLayout}>
          <Box className={classes.contentArea} component="div" display="flex" flexDirection="column">
            <Box marginBottom="10px" className={classes.centerContainer}>
              <Typography id="service-name" variant="h3" color="secondary" className={classes.boxTitle}>
                {props.name} &nbsp;
                {props.displayCredential && <KeyIcon size={20} fill="#0F445A" />}
              </Typography>
            </Box>
            <Typography id="service-description" className={classes.boxDescription}>
              {props.description}
            </Typography>

            <Box className={classes.actionBox}>
              {props.serviceUrl && (
                <Typography
                  data-cy-name={`service-url-btn-${props.id}`}
                  className={[classes.actionBtn, classes.serviceUrlBtn, 'service-url-btn'].join(' ')}
                  color="primary"
                >
                  <FormattedMessage id="launch" />
                  {!(props.isAudio || props.isVideo || props.isRelativePath) && <RedirectIcon size={20} />}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(ThumbnailCard);
