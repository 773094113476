export default (theme) => ({
  filterWrapper: {
    display: 'flex',
    borderBottom: '2px solid',
    borderColor: '#DFE6E9 !important',
    paddingBottom: 0,
  },
  filterItem: {
    width: 'initial',
    height: 40,
    fontWeight: 'bold',
    fontSize: 18,
    marginRight: 10,
    marginBottom: -2,
  },
  activeFilter: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderBottom: '2px solid',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderRadius: 5,
    },
  },
});
