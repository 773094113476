export default (theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
    backgroundColor: theme.palette.primary.white,
    height: '100%',
    borderRadius: 5,
  },
  thumbnailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontFamily: theme.typography.secondaryFont,
    ...theme.typography.t24,
    fontWeight: 700,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    margin: '20px 20px 10px',
    padding: 0,
    fontFamily: theme.typography.secondaryFont,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
  },
  actionBtn: {
    borderRadius: 100,
    padding: '12px 20px',
    '&$horizontalCardActionBtn': {
      ...theme.typography.t18,
      ...theme.typography.bold,
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'none',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
    },
  },
  horizontalCardActionBtn: {
    // need this for the class to show up
  },
  actionBox: {
    marginLeft: '20px',
    marginBottom: 10,
    width: 'fit-content',
  },
  playIcon: {
    backgroundColor: 'white',
    borderRadius: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main1,
    fontSize: '5rem',
  },
  opacity: {
    opacity: 0.6,
  },
  description: {
    fontSize: '1rem',
  },
  cardLink: {
    '& #horizontal-card-title': {
      color: theme.palette.text.primary,
    },
  },
});
