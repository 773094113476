import React from 'react';

const FlameSelectedIcon = (props) => {
  const { width, height, innerFill, outerFill, style } = props;

  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 32">
      <defs>
        <clipPath id="clip-Flame_-_Selected">
          <rect width="28" height="32" />
        </clipPath>
      </defs>
      <g id="Flame_-_Selected" data-name="Flame - Selected" clipPath="url(#clip-Flame_-_Selected)">
        <g id="fire-duotone" transform="translate(0 0.263)">
          <path
            id="Path_92722"
            data-name="Path 92722"
            d="M108.9,174.375a8.35,8.35,0,0,1-4.785,1.494c-4.471,0-8.114-2.959-8.114-7.761,0-2.395,1.5-4.5,4.509-8.108.434.5,6.129,7.773,6.129,7.773l3.634-4.146c.256.418.488.838.7,1.234A7.391,7.391,0,0,1,108.9,174.375Z"
            transform="translate(-90.049 -150.082)"
            fill={innerFill}
          />
          <path
            id="Path_92723"
            data-name="Path 92723"
            d="M20.052,3.177a37.172,37.172,0,0,0-3.477,3.71A48.312,48.312,0,0,0,10.414,0C4.324,5.649,0,13.017,0,17.455A14.086,14.086,0,0,0,13.885,31.737,14.086,14.086,0,0,0,27.77,17.455C27.77,14.158,24.546,7.345,20.052,3.177Zm-1.2,21.116a8.35,8.35,0,0,1-4.785,1.494c-4.471,0-8.114-2.959-8.114-7.761,0-2.395,1.5-4.5,4.509-8.108.434.5,6.129,7.773,6.129,7.773l3.634-4.146c.256.418.488.838.7,1.234A7.391,7.391,0,0,1,18.85,24.292Z"
            fill={outerFill}
          />
        </g>
      </g>
    </svg>
  );
};

FlameSelectedIcon.defaultProps = {
  width: 28,
  height: 32,
  innerFill: '#e3a634',
  outerFill: '#e37134',
  style: {},
};

export default FlameSelectedIcon;
