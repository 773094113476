import React from 'react';
import { withStyles, Switch, Box, Typography } from '@material-ui/core';
import styles from './toggle-switch.style';
import useToggleSwitchHook from './useToggleSwitchHook';

const ToggleSwitch = ({ classes, ...props }) => {
  const { containerRef, handleSwitchFocus, handleKeyPress } = useToggleSwitchHook(props);
  return (
    <Box ref={containerRef} className={classes.container}>
      <Typography
        id="switch-label"
        className={[classes.label, props.checked ? classes.labelChecked : classes.labelUnchecked].join(' ')}
      >
        {props.checked ? 'ON' : 'OFF'}
      </Typography>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
        onKeyDown={handleKeyPress}
        onFocus={handleSwitchFocus}
        aria-labelledby="switch-label"
      />
    </Box>
  );
};

export default withStyles(styles)(ToggleSwitch);
