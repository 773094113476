import { isRoleAttorney } from '../../services/staff-messaging.service';

const useConversationHeaderHook = (props) => {
  const { staffMessaging, isActiveModeAnnouncements } = props;
  const { selectedThread } = staffMessaging;

  const getTitle = () => {
    let title = selectedThread?.title || '';
    if (isActiveModeAnnouncements()) {
      title = `From: ${selectedThread?.sender || ''}`;
    }
    return title;
  };

  const getSubtitle = () => {
    let subtitle = '';
    if (isActiveModeAnnouncements()) {
      subtitle = `To: ${selectedThread?.recipient || ''}`;
    }
    return subtitle;
  };

  const isAttorney = () => {
    return isRoleAttorney(selectedThread?.roleName, selectedThread?.roleType);
  };

  return {
    getTitle,
    getSubtitle,
    isAttorney,
  };
};

export default useConversationHeaderHook;
