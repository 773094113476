export default theme => ({
  contentWrapper: {
    width: "71vw",
    overflow: "auto",
    whiteSpace: "nowrap",
    "& > div": {
      display: "inline-block"
    }
  }
});
