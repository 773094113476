import { useEffect, useState } from 'react';
import { getOptionsData, getResumeData, saveResumeData } from '../../services/resume-builder.service';

const useResumeBuilderHook = (props) => {
  const { userDetail } = props;
  const [resumeData, setResumeData] = useState(null);
  const [statesOptions, setStatesOptions] = useState([]);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const initialData = {
        personalInfo: {
          firstName: '',
          lastName: '',
          location: { city: '', state: '' },
          email: '',
          phoneNumber: '',
          aboutMeSummary: '',
        },
        workExperiences: [],
        achievements: [],
        educations: [],
        skills: [],
      };

      const optionsData = await getOptionsData();
      const resumeData = await getResumeData();

      setStatesOptions(optionsData.states);
      setDegreeOptions(optionsData.degrees);
      setSkillsOptions(optionsData.skills);
      setResumeData(resumeData || { ...initialData });
    } catch (error) {
      console.log(error);
    }
  };

  const onSave = async (data, callback) => {
    try {
      await saveResumeData(data, userDetail);
      callback();
    } catch (error) {
      console.log(error);
      callback('Failed to save resume data');
    }
  };

  const onPreview = () => {
    props.history.push('/resume-preview');
  };

  return {
    resumeData,
    statesOptions,
    degreeOptions,
    skillsOptions,
    onSave,
    onPreview,
  };
};

export default useResumeBuilderHook;
