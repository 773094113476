export default (theme) => ({
  root: {
    backgroundColor: 'white',
    marginTop: 50,
    borderRadius: 10,
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  },
  overviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px 50px 25px 50px',
  },
  headingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    zIndex: 99,
    fontWeight: 700,
  },
  modulesContainerEnrolled: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
  },
  modulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
    maskImage: `linear-gradient(to bottom, black 30%, transparent 120%)`,
    WebkitMaskImage: `linear-gradient(to bottom, black 30%, transparent 120%)`,
    overflow: 'auto',
    maxHeight: '800px',
  },
  modulesContainerEnd: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
    overflow: 'auto',
    maxHeight: '800px',
  },
  launchBtn: {
    textTransform: 'capitalize',
    fill: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  boldText: {
    fontWeight: 700,
  },
  description: {
    whiteSpace: 'break-spaces',
  },
});
