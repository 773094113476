export default (theme) => ({
  content: {
    padding: '0px 50px 30px 50px',
  },
  title: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  description: {
    color: theme.palette.text.sub,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  serviceName: {
    paddingTop: 20,
  },
  label: {
    textTransform: 'capitalize',
  },
  launchButton: {
    textTransform: 'capitalize',
    width: 200,
    fontWeight: 700,
    marginTop: 20,
  },
  inputBox: {
    padding: '5px 0px',
  },
  inputRoot: {
    border: `1px solid ${theme.palette.primary.light} !important`,
    backgroundColor: `${theme.palette.primary.background} !important`,
    minWidth: 375,
    height: 65,
    borderRadius: 6,
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  input: {
    padding: '10px 20px',
    color: `${theme.palette.text.primary} !important`,
    fontSize: `18px !important`,
    fontWeight: 700,
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 150,
  },
});
