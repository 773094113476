import React from "react";

const AnnouncementIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        d="M18.75 7.01562C19.4766 7.35937 20 8.28515 20 9.37499C20 10.4648 19.4766 11.3906 18.75 11.7344V17.5C18.75 18.0039 18.4453 18.4609 17.9766 18.6562C17.5117 18.8476 16.9727 18.7422 16.6172 18.3828L14.9102 16.6445C13.0352 14.7695 10.4922 13.75 7.83984 13.75H7.5V18.75C7.5 19.4414 6.94141 20 6.25 20H3.75C3.05977 20 2.5 19.4414 2.5 18.75V13.75C1.11914 13.75 0 12.6289 0 11.25V7.49999C0 6.12109 1.11914 4.99999 2.5 4.99999H7.83984C10.4922 4.99999 13.0352 3.94531 14.9102 2.07109L16.6172 0.366128C16.9727 0.00864785 17.5117 -0.0982857 17.9766 0.0951908C18.4453 0.288667 18.75 0.744527 18.75 1.25V7.01562ZM7.83984 7.49999H7.5V11.25H7.83984C10.957 11.25 13.9531 12.4141 16.25 14.5039V4.24609C13.9531 6.33593 10.957 7.49999 7.83984 7.49999Z"
        fill="inherit"
      />
    </svg>
  );
};

AnnouncementIcon.defaultProps = {
  width: "50",
  height: "20",
  fill: "inherit"
};

export default AnnouncementIcon;
