export default (theme) => ({
  accordionTitle: {
    ...theme.typography.bold,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    textTransform: 'capitalize',
  },
  zeroPadding: {
    padding: 0,
  },
  transparentBackground: {
    background: 'transparent',
  },
  actionButton: {
    lineHeight: '28px',
    letterSpacing: '1px',
    textTransform: 'capitalize',
    paddingLeft: 35,
    paddingRight: 35,
  },
  actionBox: {
    marginTop: 20,
  },
});
