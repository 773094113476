export default (theme) => ({
  root: {
    padding: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
  messageContainer: {
    margin: '15px 0px',
    display: 'flex',
  },
  alignContentLeft: {
    justifyContent: 'flex-start',
  },
  alignContentRight: {
    justifyContent: 'flex-end',
  },
  messageWrapper: {
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    padding: 10,
    borderRadius: 5,
    position: 'relative',
    textTransform: 'none',
  },
  receivedMessage: {
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.black,
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '-5px',
      borderBottom: `20px solid ${theme.palette.primary.background}`,
      borderLeft: '15px solid transparent',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.background,
    },
  },
  sentMessage: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: '-5px',
      borderBottom: `20px solid ${theme.palette.primary.main}`,
      borderRight: '15px solid transparent',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  datetime: {
    color: theme.palette.text.secondary,
  },
  alignSelfRight: {
    alignSelf: 'flex-end',
  },
  alignSelfLeft: {
    alignSelf: 'flex-start',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 75,
  },
  messageBox: {
    display: 'flex',
    alignItems: 'center',
  },
  directionRowReverse: {
    flexDirection: 'row-reverse',
  },
  showHideBox: {
    background: theme.palette.colors.orijinPurple,
    borderRadius: '50%',
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: 5,
  },
  marginLeft: {
    marginLeft: 5,
  },
  toggleShowHideButton: {
    fill: theme.palette.primary.white,
  },
});
