import { Http } from '../../../core';
import { Constant, Utility } from '../../../shared/services';

export class MyProfileService {
  _utilityService = new Utility();

  /**
   * @name getQuestions
   * @desc retrieve my profile questions with available options
   * @return {Promise}
   */
  getQuestions = (status) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.get(`/services/learner-profile/v1/questions?with-answers=true`)
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @name saveAnswers
   * @param {Array} answers
   * @desc save my profile answers
   * @return {Promise}
   */
  saveAnswers = (answers) => {
    return new Promise((resolve, reject) => {
      Http.HYREUS_REQUEST.post(`/services/learner-profile/v1/answers`, answers, {
        pendingApiKey: this._utilityService.getAPIKey(Constant.ROUTES.MY_PROFILE, 'save-answers'),
      })
        .then((_successLog) => {
          resolve(_successLog);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
