export default (theme) => ({
  listItem: {
    border: `0.5px solid ${theme.palette.blue.greyed} !important`,
    borderRadius: '10px !important',
    padding: '12px 16px 12px 16px !important',
    marginBottom: '16px !important',
  },
  checkbox: {
    color: `${theme.palette.blue.primary} !important`,
  },

  modalBody: {
    padding: '10px 10px 10px 10px !important',
  },

  contactScrollSection: {
    maxHeight: '440px',
    overflowY: 'auto',
    padding: '10px 10px 10px 10px !important',
  },

  cancelBtn: {
    display: 'none',
  },

  pendingIcon: {
    color: 'yellow',
  },
  approvedIcon: {
    color: 'green',
  },
  approved: {
    border: 'solid 1px green',
    backgroundColor: '#C0F6C4',
  },
  pending: {
    border: 'solid 1px yellow',
    backgroundColor: 'lightyellow',
  },
  icon: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
  },

  paperBox: {
    display: 'flex',
  },
});
